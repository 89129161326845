import type { SubmissionStatus, SubmissionType } from '../../hooks/useGuidedWaxupAccessoryModal';
import { PracticeFullScreenDialog } from '@orthly/dentin';
import { LoadBlocker, RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, Text, styled, Divider, Icon, InfoIcon, PauseIcon } from '@orthly/ui-primitives';
import React from 'react';

const StyledRootActionDialog = styled(RootActionDialog)({
    '.MuiPaper-root': {
        maxWidth: '796px',
    },
});

const Card = styled('div')({
    backgroundColor: FlossPalette.TAN,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: `16px`,
});

const CardContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: `16px`,
});

const TopCardArea = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    paddingLeft: '3px',
});

const BottomCardArea = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const InnerContentWrapper = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: isMobile ? 'space-between' : 'unset',
}));

const Content = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: '24px',
    padding: '24px',
    justifyContent: isMobile ? 'space-between' : 'unset',
}));

const BottomButtons = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    display: 'flex',
    flexDirection: isMobile ? 'column-reverse' : 'row',
    justifyContent: 'flex-end',
    gap: '8px',
}));

const Banner = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
    padding: '0 8px',
    alignItems: 'center',
    justifyContent: 'center',
});

const BannerText = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const MobileWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const MobileHeader = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    minHeight: '70px',
    backgroundColor: FlossPalette.TAN,
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    position: 'relative',
    padding: '12px 16px',
});

const MobileCloseButton = styled(Button)({
    background: FlossPalette.TAN,
    height: '48px',
    width: '48px',
});

interface GuidedWaxupConfirmationModalProps {
    openModal: () => void;
    openCalendlyModal: () => void;
    onModalClose: () => void;
    setSubmissionStatus: (status: SubmissionStatus) => void;
    submissionsStatus: SubmissionStatus;
    submissionType: SubmissionType;
    /**
     * While this field could be ascertained within the component, we pass it in
     * to make it easier to test variants in Storybook and other areas.
     */
    isMobile: boolean;
}

const InnerContent: React.VFC<
    Pick<
        GuidedWaxupConfirmationModalProps,
        'openCalendlyModal' | 'onModalClose' | 'isMobile' | 'submissionsStatus' | 'setSubmissionStatus'
    >
> = ({ openCalendlyModal, onModalClose, isMobile, submissionsStatus, setSubmissionStatus }) => {
    return (
        <InnerContentWrapper isMobile={isMobile}>
            <Content isMobile={isMobile}>
                {submissionsStatus === 'success' ? (
                    <Card>
                        <CardContent>
                            <TopCardArea>
                                <Text variant={'body1'} medium>
                                    Do you want to schedule more video calls with a lab technician?
                                </Text>
                            </TopCardArea>
                            <Divider />
                            <BottomCardArea>
                                <Text variant={'body2'}>
                                    Plan ahead and schedule more video calls to pair with an expert lab technician at a
                                    time that works for you. You can schedule a conversation with a lab technician up to
                                    three months in advance to review designs, discuss questions, or collaboratively
                                    plan your next complex case.
                                </Text>
                            </BottomCardArea>
                        </CardContent>
                    </Card>
                ) : (
                    <LoadBlocker blocking={true} />
                )}

                <BottomButtons isMobile={isMobile}>
                    <Button variant={'secondary'} onClick={onModalClose} fullWidth={isMobile}>
                        Close
                    </Button>
                    {submissionsStatus === 'success' && (
                        <Button
                            variant={'primary'}
                            onClick={() => {
                                openCalendlyModal();
                                setSubmissionStatus('hasntYetSubmitted');
                            }}
                            fullWidth={isMobile}
                        >
                            Schedule another call
                        </Button>
                    )}
                </BottomButtons>
            </Content>

            {submissionsStatus === 'success' && (
                <Banner>
                    <BannerText>
                        <InfoIcon style={{ marginRight: `8px`, color: FlossPalette.SECONDARY_FOREGROUND }} />
                        <Text variant={'body2'} color={'SECONDARY_FOREGROUND'} medium>
                            Booking an appointment won’t pause existing orders; manage holds on the order page
                        </Text>
                    </BannerText>
                    <Button
                        variant={'ghost'}
                        onClick={() => {
                            window.open(
                                'https://help.meetdandy.com/hc/en-us/articles/31417186153229-How-to-Pause-and-Resume-Orders',
                                '_blank',
                            );
                        }}
                    >
                        Pause order <PauseIcon />
                    </Button>
                </Banner>
            )}
        </InnerContentWrapper>
    );
};

export const GuidedWaxupConfirmationModal: React.VFC<GuidedWaxupConfirmationModalProps> = ({
    openCalendlyModal,
    onModalClose,
    isMobile,
    submissionsStatus,
    setSubmissionStatus,
    submissionType,
}) => {
    if (isMobile) {
        return (
            <>
                <PracticeFullScreenDialog open>
                    <MobileWrapper>
                        <MobileHeader>
                            <div>
                                {submissionsStatus === 'isSubmitting' && <Text variant={'h6'}>Submitting...</Text>}
                                {submissionsStatus === 'success' && (
                                    <>
                                        <Text variant={'h6'}>
                                            {submissionType === 'labTechMeeting'
                                                ? 'Your Live Lab Tech Meeting was scheduled'
                                                : 'Your Live Design Review was scheduled'}
                                        </Text>
                                        <Text variant={'body2'} color={'GRAY'}>
                                            A calendar invitation has been sent to your email
                                        </Text>
                                    </>
                                )}
                            </div>
                            <MobileCloseButton variant={'secondary'} onClick={onModalClose}>
                                <Icon icon={'XIcon'} />
                            </MobileCloseButton>
                        </MobileHeader>
                        <InnerContent
                            openCalendlyModal={openCalendlyModal}
                            onModalClose={onModalClose}
                            isMobile={isMobile}
                            submissionsStatus={submissionsStatus}
                            setSubmissionStatus={setSubmissionStatus}
                        />
                    </MobileWrapper>
                </PracticeFullScreenDialog>
            </>
        );
    }

    return (
        <StyledRootActionDialog
            open
            setOpen={() => {}}
            CustomButton={() => null}
            loading={false}
            buttonText={''}
            maxWidth={'md'}
            contentProps={{ style: { padding: 0 } }}
            title={
                <>
                    {submissionsStatus === 'isSubmitting' && <Text variant={'h4'}>Submitting...</Text>}
                    {submissionsStatus === 'success' && (
                        <>
                            <Text variant={'h4'}>
                                {submissionType === 'labTechMeeting'
                                    ? 'Your Live Lab Tech Meeting was scheduled'
                                    : 'Your Live Design Review was scheduled'}
                            </Text>
                            <Text variant={'h5'} color={'GRAY'}>
                                A calendar invitation has been sent to your email
                            </Text>
                        </>
                    )}
                </>
            }
            showCloseButton
            onClose={submissionsStatus === 'isSubmitting' ? () => {} : onModalClose}
            content={
                <>
                    <InnerContent
                        openCalendlyModal={openCalendlyModal}
                        onModalClose={onModalClose}
                        isMobile={isMobile}
                        submissionsStatus={submissionsStatus}
                        setSubmissionStatus={setSubmissionStatus}
                    />
                </>
            }
        />
    );
};
