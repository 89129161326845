import { ldrOptionsModalSelectionAtom } from '../../state/GuidedWaxup.atoms';
import { useIsOrderEligibleForVideoDesignReview } from './GuidedWaxupScheduleLiveCall.hooks.graphql';
import { GuidedWaxupSelectDesignReviewOptionHelpContent } from './GuidedWaxupSelectDesignReviewOptionModalComponents/GuidedWaxupSelectDesignReviewOptionHelpContent';
import { GuidedWaxupSelectDesignReviewOptionModalContent } from './GuidedWaxupSelectDesignReviewOptionModalComponents/GuidedWaxupSelectDesignReviewOptionModalContent';
import { GuidedWaxupSelectDesignReviewOptionModalFooter } from './GuidedWaxupSelectDesignReviewOptionModalComponents/GuidedWaxupSelectDesignReviewOptionModalFooter';
import { GuidedWaxupSelectDesignReviewOptionModalHeader } from './GuidedWaxupSelectDesignReviewOptionModalComponents/GuidedWaxupSelectDesignReviewOptionModalHeader';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeFullScreenDialog } from '@orthly/dentin';
import { useSession } from '@orthly/session-client';
import { LoadBlocker, RootActionDialog } from '@orthly/ui';
import { Box, styled } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import React from 'react';

const staffSignedUpForVdrAtom = atomWithStorage<string[]>('staffSignedUpForVdrList', []);

export const enum DesignReviewOption {
    CONTINUE_WITHOUT_REVIEW = 'Reject Design As Is',
    REQUEST_LDR = 'Reject Design & Reserve Consultation',
    REQUEST_VDR = 'Reject Design & Request Video Review',
}

const StyledRootActionDialog = styled(RootActionDialog)({
    '.MuiPaper-root': {
        maxWidth: '796px',
    },
    '.MuiGrid-root': {
        padding: '0px',
    },
    '.MuiDialogContent-root': {
        // needed to make help tab headers sticky
        height: '100%',
        overflowY: 'unset',
    },
});

const DesignReviewOptionsContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    [theme.breakpoints.down('sm')]: {
        gap: '24px',
    },
}));

const DesignReviewOptionsContentText = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
        gap: '24px',
    },

    position: 'relative',
}));

const tabs = [
    { name: 'Video Design Review', value: DesignReviewOption.REQUEST_VDR },
    { name: 'Live Design Review', value: DesignReviewOption.REQUEST_LDR },
];

interface InnerContentProps extends GuidedWaxupSelectDesignReviewOptionModalProps {
    orderId: string;
    selectedDesignReviewOption: DesignReviewOption;
    setSelectedDesignReviewOption: (option: DesignReviewOption) => void;
    showHelpInfo: boolean;
    setShowHelpInfo: (show: boolean) => void;
    currentHelpTab: string;
    setCurrentHelpTab: (option: string) => void;
    closeHelpInfo: () => void;
    handleClose: () => void;
    shouldRecommendLdr: boolean;
}

/**
 * The modal content - either a list of design review options or a help screen describing the options
 */
const InnerContent: React.VFC<InnerContentProps> = ({
    orderId,
    revisionId,
    onModalClose,
    isMobile,
    selectedDesignReviewOption,
    setSelectedDesignReviewOption,
    showHelpInfo,
    setShowHelpInfo,
    currentHelpTab,
    setCurrentHelpTab,
    closeHelpInfo,
    ddpReadinessDate,
    submit,
    handleClose,
    shouldRecommendLdr,
}) => {
    const session = useSession();
    // use local storage to track whether the user has signed up for early access to VDR
    // TODO: remove later after VDR release
    const [signedUpStaff, setSignedUpStaff] = useAtom(staffSignedUpForVdrAtom);

    const signUpForVdr = () => {
        if (session?.user_id) {
            BrowserAnalyticsClientFactory.Instance?.track(
                'Practice - Portal - Live Design Review - Signed Up For VDR',
                {
                    $groups: { order: orderId },
                    revisionId: revisionId,
                },
            );

            const newSignedUpStaff = [...signedUpStaff, session?.user_id];
            setSignedUpStaff(newSignedUpStaff);
        }
    };

    const handleSubmit = (option: DesignReviewOption) => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Portal - Live Design Review - Rejected Design From Options Modal',
            {
                $groups: { order: orderId },
                revisionId: revisionId,
                selectedOption: selectedDesignReviewOption,
            },
        );
        return submit(option);
    };

    return showHelpInfo ? (
        <GuidedWaxupSelectDesignReviewOptionHelpContent
            signUpForVdr={signUpForVdr}
            isSignedUpForVdr={signedUpStaff?.includes(session?.user_id || '')}
            isMobile={isMobile}
            currentHelpTab={currentHelpTab}
            setCurrentHelpTab={setCurrentHelpTab}
            onClose={closeHelpInfo}
            tabs={tabs}
        />
    ) : (
        <DesignReviewOptionsContentWrapper>
            <DesignReviewOptionsContentText>
                <GuidedWaxupSelectDesignReviewOptionModalHeader
                    isMobile={isMobile}
                    onClose={handleClose}
                    selectedDesignReviewOption={selectedDesignReviewOption}
                />
                <GuidedWaxupSelectDesignReviewOptionModalContent
                    selectedDesignReviewOption={selectedDesignReviewOption}
                    setSelectedDesignReviewOption={setSelectedDesignReviewOption}
                    setShowHelpInfo={setShowHelpInfo}
                    signUpForVdr={signUpForVdr}
                    isSignedUpForVdr={signedUpStaff?.includes(session?.user_id || '')}
                    isMobile={isMobile}
                    setCurrentHelpTab={setCurrentHelpTab}
                    ddpReadinessDate={ddpReadinessDate}
                    orderId={orderId}
                    shouldRecommendLdr={shouldRecommendLdr}
                />
            </DesignReviewOptionsContentText>
            <GuidedWaxupSelectDesignReviewOptionModalFooter
                onClose={onModalClose}
                selectedDesignReviewOption={selectedDesignReviewOption}
                submit={handleSubmit}
                isMobile={isMobile}
            />
        </DesignReviewOptionsContentWrapper>
    );
};

interface GuidedWaxupSelectDesignReviewOptionModalProps {
    orderId: string;
    revisionId: string;
    onModalClose: () => void;
    openCalendlyModal: () => void;
    openModal: () => void;
    isMobile: boolean;
    numRejections: number;
    isRefab: boolean;
    ddpReadinessDate: string | null;
    loading: boolean;
    submit: (option: DesignReviewOption) => Promise<void>;
}

export const GuidedWaxupSelectDesignReviewOptionModal: React.VFC<
    GuidedWaxupSelectDesignReviewOptionModalProps
> = props => {
    const { isMobile, onModalClose, openModal, numRejections, isRefab, orderId, revisionId, loading } = props;

    const isVDREnabled = useIsOrderEligibleForVideoDesignReview({ id: orderId });

    const shouldRecommendLdr = numRejections > 0 || isRefab;
    const defaultOption = shouldRecommendLdr
        ? DesignReviewOption.REQUEST_LDR
        : DesignReviewOption.CONTINUE_WITHOUT_REVIEW;

    // selected option is stored in jotai
    const [selectedDesignReviewOption, setSelectedDesignReviewOption] = useAtom(ldrOptionsModalSelectionAtom);

    // the info pane covers the entire modal when the user clicks the HelpIcon
    const [showHelpInfo, setShowHelpInfo] = React.useState<boolean>(false);
    const [currentHelpTab, setCurrentHelpTab] = React.useState<string>(selectedDesignReviewOption || '');

    React.useEffect(() => {
        // Set initial atom value on mount
        if (selectedDesignReviewOption === null) {
            setSelectedDesignReviewOption(defaultOption);
        }

        // Reset atom to null on unmount
        return () => {
            setSelectedDesignReviewOption(null);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const closeHelpInfo = () => {
        setShowHelpInfo(false);
        openModal();
    };

    const handleClose = () => {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Live Design Review - Closed Options Modal', {
            $groups: { order: orderId },
            revisionId: revisionId,
        });
        onModalClose();
    };

    React.useEffect(() => {
        const availableOptions = [DesignReviewOption.CONTINUE_WITHOUT_REVIEW, DesignReviewOption.REQUEST_LDR];
        if (isVDREnabled) {
            availableOptions.push(DesignReviewOption.REQUEST_VDR);
        }
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Live Design Review - Opened Options Modal', {
            $groups: { order: orderId },
            revisionId: revisionId,
            availableOptions: availableOptions,
        });
    }, [isVDREnabled, orderId, revisionId]);

    if (isMobile) {
        return (
            <PracticeFullScreenDialog open>
                <LoadBlocker blocking={loading}>
                    <InnerContent
                        {...props}
                        selectedDesignReviewOption={selectedDesignReviewOption ?? defaultOption}
                        setSelectedDesignReviewOption={setSelectedDesignReviewOption}
                        showHelpInfo={showHelpInfo}
                        setShowHelpInfo={setShowHelpInfo}
                        currentHelpTab={currentHelpTab}
                        setCurrentHelpTab={setCurrentHelpTab}
                        closeHelpInfo={closeHelpInfo}
                        handleClose={handleClose}
                        shouldRecommendLdr={shouldRecommendLdr}
                    />
                </LoadBlocker>
            </PracticeFullScreenDialog>
        );
    }

    return (
        <StyledRootActionDialog
            fullHeight={true}
            loading={loading}
            open
            showCloseButton
            setOpen={open => {
                if (!open) {
                    onModalClose();
                } else {
                    openModal();
                }
            }}
            title={null}
            content={
                <InnerContent
                    {...props}
                    selectedDesignReviewOption={selectedDesignReviewOption ?? defaultOption}
                    setSelectedDesignReviewOption={setSelectedDesignReviewOption}
                    showHelpInfo={showHelpInfo}
                    setShowHelpInfo={setShowHelpInfo}
                    currentHelpTab={currentHelpTab}
                    setCurrentHelpTab={setCurrentHelpTab}
                    closeHelpInfo={closeHelpInfo}
                    handleClose={handleClose}
                    shouldRecommendLdr={shouldRecommendLdr}
                />
            }
            CustomButton={() => null}
            onClose={() => {
                if (showHelpInfo) {
                    closeHelpInfo();
                } else {
                    handleClose();
                }
            }}
        />
    );
};
