/* eslint-disable @typescript-eslint/no-use-before-define */
import { ToolNames } from '../components/ToolList/ToolList';
import { ApplyShade } from '../utils/ApplyShade';
import type { Brush } from '../utils/Brush';
import { DefaultBrushes } from '../utils/Brush';
import { updateTextureFromLayers, type CanvasLayer, type CanvasLayers } from '../utils/CanvasLayers';
import { Gradient } from '../utils/Gradient';
import InitEmptyTextureInternal from '../utils/PainterTextures';
import type { PainterTrackballControls } from '../utils/PainterTrackballControls';
import type { ShadeData } from '../utils/ShadeData';
import { pickShade } from '../utils/ShadeValues';
import { unwrapUVs } from '../utils/UVUnwrap';
import { selectedRestorativeStateAtom, selectedRestorativeToothNumberAtom, toothRefAtom } from './designsStore';
import { debugUVMapAtom } from './settings';
import type { Getter } from 'jotai';
import { atom, createStore } from 'jotai';
import type { Mesh, MeshPhysicalMaterial, Scene, Texture, WebGLRenderer } from 'three';
import { Vector3 } from 'three';

export const designIdAtom = atom<string | null>(null);
export const orderIdAtom = atom<string | null>(null);

export const fullScreenAtom = atom<boolean>(false);
export const drawerOpenAtom = atom<boolean>(true);
export const showTextureWindowAtom = atom<boolean>(false);
export const automaticAtom = atom<boolean>(false);
export const shadeCompleteAtom = atom<boolean>(false);

export const selectedCanvasLayerAtom = atom<CanvasLayer | null>(null);
export const canvasLayersAtom = atom<CanvasLayers, [CanvasLayers], void>(
    get => get(selectedRestorativeStateAtom)?.canvasLayers ?? {},
    (get, set, update) => {
        set(selectedRestorativeStateAtom, { canvasLayers: update });
        const selectedId = get(selectedCanvasLayerAtom)?.id;
        if (selectedId && update[selectedId]) {
            set(selectedCanvasLayerAtom, update[selectedId]);
        } else {
            set(selectedCanvasLayerAtom, null);
        }
        const texture = get(textureRefAtom);
        if (texture) {
            updateTextureFromLayers(update, texture);
        }
    },
);

export const selectedToolAtom = atom<ToolNames | null, [ToolNames | null], void>(
    null,
    (_get, set, update: ToolNames | null) => {
        set(selectedToolAtom, update);
        if (update === ToolNames.Paint) {
            set(textureNameAtom, TextureKeys.map);
            // For future use
            // } else if (update === ToolNames.Glaze) {
            //     set(textureNameAtom, TextureKeys.clearcoatMap);
        } else {
            set(textureNameAtom, undefined);
        }
    },
);

// Texture Painting States
export const textureCanvasAtom = atom<HTMLCanvasElement | null>(null);

export const rendererRefAtom = atom<WebGLRenderer | null>(null);
export const sceneRefAtom = atom<Scene | null>(null);
export const controlsRefAtom = atom<PainterTrackballControls | null>(null);

export const downloadCompleteAtom = atom<boolean>(true);

const updateToothGradient = (get: Getter) => {
    const tooth = get(toothRefAtom);
    const gradientVec = get(gradientVecAtom);
    const shadeData = get(shadeDataAtom);
    const controlPoints = get(controlPointsAtom);
    updateToothRef(tooth, gradientVec, shadeData, controlPoints);
};

export const gradientVecAtom = atom<Vector3, Vector3[], void>(
    get => {
        return get(selectedRestorativeStateAtom)?.gradientDirection ?? new Vector3(0, 1, 0);
    },
    (get, set, update) => {
        set(selectedRestorativeStateAtom, { gradientDirection: update });
        updateToothGradient(get);
    },
);

export const cameraControlsDisabledAtom = atom<boolean>(false);

const updateToothRef = (tooth: Mesh | null, gradientVec: Vector3, shadeData: ShadeData, controlPoints: number[]) => {
    if (tooth && shadeData.length > 0) {
        const colors = shadeData.map(shade => shade.color);
        const gradientFunction = Gradient(colors, controlPoints);

        ApplyShade(tooth, gradientVec, gradientFunction);
        unwrapUVs(tooth, gradientVec);
    }
};

const adjustControlPoints = (controlPoints: number[], expectedCount: number): number[] => {
    const currentCount = controlPoints.length;
    if (currentCount === 0) {
        return Array(expectedCount)
            .fill(0)
            .map((_, i) => (i / (expectedCount - 1)) * 100);
    }
    if (expectedCount < 2) {
        return [];
    }

    const scale = (expectedCount - 1) / (currentCount - 1);
    const newControlPoints = Array(expectedCount)
        .fill(0)
        .map((_, i) => {
            const index = i / scale;
            const lowerIndex = Math.floor(index);
            const upperIndex = Math.ceil(index);
            if (lowerIndex === upperIndex) {
                return controlPoints[lowerIndex];
            }
            const lowerValue = controlPoints[lowerIndex] ?? 0;
            const upperValue = controlPoints[upperIndex] ?? 1;
            return lowerValue + (upperValue - lowerValue) * (index - lowerIndex);
        })
        .filter(point => point !== undefined);

    return newControlPoints;
};

export const shadeDataAtom = atom<ShadeData, [ShadeData], void>(
    get => get(selectedRestorativeStateAtom)?.shadeData ?? pickShade('A1'),
    (get, set, update: ShadeData) => {
        set(selectedRestorativeStateAtom, { shadeData: update });

        const expectedControlPointCount = update.length < 2 ? 0 : 4 + (update.length - 2) * 3;
        let controlPoints = get(controlPointsAtom);

        if (controlPoints.length !== expectedControlPointCount) {
            controlPoints = adjustControlPoints(controlPoints, expectedControlPointCount);
            set(controlPointsAtom, controlPoints);
        }

        updateToothGradient(get);
    },
);

export const controlPointsAtom = atom<number[], [number[]], void>(
    get => get(selectedRestorativeStateAtom)?.gradientControlPoints ?? [],
    (get, set, update: number[]) => {
        set(selectedRestorativeStateAtom, { gradientControlPoints: update });
        updateToothGradient(get);
    },
);

export const enum TextureKeys {
    map = 'map',
    clearcoatMap = 'clearcoatMap',
}

export const textureNameAtom = atom<TextureKeys | undefined, [TextureKeys | undefined], void>(
    undefined,
    (get, set, update: TextureKeys | undefined) => {
        if (update) {
            const tooth = store.get(toothRefAtom);
            const debugUVMap = store.get(debugUVMapAtom);
            if (tooth && tooth?.material) {
                const canvas = InitEmptyTextureInternal(tooth, update, debugUVMap);
                if (canvas) {
                    set(textureCanvasAtom, canvas);
                    // Force updating textureRefAtom by reading it
                    get(textureRefAtom);
                }
            }
        }
        // Force updating textureRefAtom by reading it
        get(textureRefAtom);
        set(textureNameAtom, update);
    },
);

export const textureRefAtom = atom<Texture | null>(get => {
    const tooth = get(toothRefAtom);
    const texName = get(textureNameAtom);
    // Assumes tooth.material has a property keyed by texName
    if (tooth && tooth.material && !Array.isArray(tooth.material)) {
        const material = tooth.material as MeshPhysicalMaterial;

        // Type-safe approach to access material properties
        if (texName === TextureKeys.map && material.map) {
            return material.map;
        } else if (texName === TextureKeys.clearcoatMap && material.clearcoatMap) {
            return material.clearcoatMap;
        }
    }
    return null;
});

export const store: ReturnType<typeof createStore> = createStore();

export const brushesAtom = atom<Brush[]>(DefaultBrushes);

export const selectedBrushIndexAtom = atom<number>(0);

export function clearStore() {
    store.set(sceneRefAtom, null);
    store.set(selectedRestorativeToothNumberAtom, 0);
}
