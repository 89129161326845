import LiveDesignReviewSVG from '../../../../../../../assets/images/LiveDesignReview.svg';
import VideoDesignReviewSVG from '../../../../../../../assets/images/VideoDesignReview.svg';
import { TextPill } from '../../../../../TextPill';
import { DesignReviewOption } from '../GuidedWaxupSelectDesignReviewOptionModal';
import { CloseButtonMobile } from './GuidedWaxupSelectDesignReviewOptionModalCloseButton';
import { Box, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const HeaderLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        position: 'sticky',
        top: '0px',
        background: FlossPalette.WHITE,
        gap: '24px',
        zIndex: '1',
    },
}));

const HeaderTitle = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'column',
        zIndex: '1',
        gap: '12px',
    },
}));

const DesignReviewCollaborationImg = styled('img')({
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '200px',
});

const HeaderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '70px',
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        position: 'relative',
        padding: '16px 24px 16px 24px',
    },
}));

interface GuidedWaxupSelectDesignReviewOptionModalHeaderProps {
    onClose?: () => void;
    isMobile: boolean;
    selectedDesignReviewOption: DesignReviewOption;
}

export const GuidedWaxupSelectDesignReviewOptionModalHeader: React.VFC<
    GuidedWaxupSelectDesignReviewOptionModalHeaderProps
> = ({ onClose, isMobile, selectedDesignReviewOption }) => {
    const titleVariant = isMobile ? 'h6' : 'h4';
    const shouldShowImg = !isMobile && selectedDesignReviewOption !== DesignReviewOption.CONTINUE_WITHOUT_REVIEW;

    return (
        <HeaderLayout>
            <HeaderWrapper>
                {shouldShowImg && (
                    <DesignReviewCollaborationImg
                        src={
                            selectedDesignReviewOption === DesignReviewOption.REQUEST_LDR
                                ? LiveDesignReviewSVG
                                : VideoDesignReviewSVG
                        }
                    />
                )}
                <HeaderTitle>
                    <Text variant={titleVariant} medium>
                        {`Connect with a ${isMobile ? 'Lab Tech' : 'Lab Technician'}`}
                    </Text>
                    <TextPill>{'Free early access'}</TextPill>
                </HeaderTitle>
                {isMobile && <CloseButtonMobile onClose={onClose} />}
            </HeaderWrapper>
        </HeaderLayout>
    );
};
