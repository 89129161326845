// We import a CSS file because we need to set a specific class that the react-calendly modal uses,
// while the react-calendly package doesn't expose any way to provide custom styles.
import '../../../../../../assets/styles/GuidedWaxupScheduleLiveCall.css';
import { CalendlyModal } from '../../../../Calendly';
import { useLiveLabTechMeetingUrl } from '../../hooks/useLiveLabTechMeetingUrl.graphql';
import type { CalendlyPrefill } from './GuidedWaxupScheduleLiveCall.hooks.graphql';
import React from 'react';

interface GuidedWaxupScheduleLiveLabTechMeetingCalendlyModalProps {
    onModalClose: () => void;
    calendlyPrefill?: CalendlyPrefill;
    submit: (eventId: string, rejectionNotes?: string) => Promise<void>;
}

export const GuidedWaxupScheduleLiveLabTechMeetingCalendlyModal: React.VFC<
    GuidedWaxupScheduleLiveLabTechMeetingCalendlyModalProps
> = ({ onModalClose, submit, calendlyPrefill }) => {
    const calendlyUrl = useLiveLabTechMeetingUrl();

    return calendlyUrl ? (
        <CalendlyModal
            url={calendlyUrl}
            prefill={calendlyPrefill}
            onModalClose={onModalClose}
            onEventScheduled={async event => {
                const eventId = event.data.payload.event.uri.split('/').at(-1) ?? '';
                await submit(eventId);
            }}
        />
    ) : null;
};
