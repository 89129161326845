import type { LoginRootScreen } from '@orthly/veneer';
import { LoginRoot } from '@orthly/veneer';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import type { Location } from 'react-router-dom-v5-compat';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';

export const AdminLoginRoute = '/login';

const UpdatePasswordRoute = 'new-password';

type PreviousLocationState = { previous?: Location };

export const AdminLoginRedirect: React.VFC = () => {
    const location = useLocation();
    const state: PreviousLocationState = { previous: location };
    return <Redirect to={{ state, pathname: AdminLoginRoute }} />;
};

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AdminLogin: React.FC<RouteComponentProps<any, any, PreviousLocationState | undefined>> = ({
    location,
}) => {
    const navigate = useNavigate();
    const onLoggedIn = React.useCallback(() => {
        navigate(location.state?.previous ?? { pathname: '/orders' }, { replace: true });
    }, [location.state?.previous, navigate]);

    const googleLoginClientId = process.env.ADMIN_PORTAL_WEB_APPLICATION_OAUTH_CLIENT_ID;

    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const initialScreen: LoginRootScreen = location.pathname.includes('/reset')
        ? 'reset'
        : location.pathname.includes(UpdatePasswordRoute)
          ? 'change_password'
          : 'login';
    return (
        <LoginRoot initialScreen={initialScreen} onLoggedIn={onLoggedIn} googleLoginClientId={googleLoginClientId} />
    );
};
