import { useUsersAction } from '../state/Users.actions';
import { useUsersSelector } from '../state/Users.context';
import type { UserRole } from '../state/Users.types';
import { useMutation } from '@apollo/client';
import type { FetchResult } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type {
    LabsGqlAddInternalStaffMemberMutation,
    LabsGqlAddPracticeStaffMemberMutation,
    LabsGqlAddLabStaffMemberMutation,
    LabsGqlUpdateInternalStaffMemberRolesMutation,
    LabsGqlUpdatePracticeStaffMemberRolesMutation,
    LabsGqlUpdateLabStaffMemberRolesMutation,
    LabsGqlUpdateExternalStaffMemberRolesMutation,
    LabsGqlRemoveStaffMemberMutationVariables,
    LabsGqlReactivateStaffMemberMutationVariables,
    LabsGqlAddExternalStaffMemberMutation,
    LabsGqlAddParentStaffMemberMutation,
    LabsGqlUpdateParentStaffMemberRolesMutation,
    LabsGqlCreateDoctorPreferencesMutationVariables,
    LabsGqlDeleteDoctorPreferencesMutationVariables,
} from '@orthly/graphql-operations';
import {
    useAddInternalStaffMemberMutation,
    useAddLabStaffMemberMutation,
    useAddPracticeStaffMemberMutation,
    useUpdateInternalStaffMemberRolesMutation,
    useUpdatePracticeStaffMemberRolesMutation,
    useUpdateLabStaffMemberRolesMutation,
    useRemoveStaffMemberMutation,
    useReactivateStaffMemberMutation,
    useAddExternalStaffMemberMutation,
    useUpdateExternalStaffMemberRolesMutation,
    useAddParentStaffMemberMutation,
    useUpdateParentStaffMemberRolesMutation,
    useCreateDoctorPreferencesMutation,
    useDeleteDoctorPreferencesMutation,
} from '@orthly/graphql-react';
import type {
    LabsGqlStaffRoleInternal,
    LabsGqlStaffRoleLab,
    LabsGqlStaffRolePractice,
    LabsGqlStaffRoleExternal,
    LabsGqlStaffRoleParent,
} from '@orthly/graphql-schema';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import type { LabsGqlStaffRole } from '@orthly/graphql-schema';
import type { IOrganizationType, StaffRole } from '@orthly/retainer-common';
import { extractRolesOfType } from '@orthly/retainer-common';
import { useChangeSubmissionFn } from '@orthly/ui';

type DeleteStaffMemberVars = LabsGqlRemoveStaffMemberMutationVariables['data'];
type ReactivateStaffMemberVars = LabsGqlReactivateStaffMemberMutationVariables['data'];
type UpsertStaffMemberVars = { user_id: string; role: UserRole };
type AddStaffMemberResult = FetchResult<
    | LabsGqlAddInternalStaffMemberMutation
    | LabsGqlAddPracticeStaffMemberMutation
    | LabsGqlAddLabStaffMemberMutation
    | LabsGqlAddExternalStaffMemberMutation
    | LabsGqlAddParentStaffMemberMutation
>;
type UpdateStaffMemberResult = FetchResult<
    | LabsGqlUpdateInternalStaffMemberRolesMutation
    | LabsGqlUpdateLabStaffMemberRolesMutation
    | LabsGqlUpdatePracticeStaffMemberRolesMutation
    | LabsGqlUpdateExternalStaffMemberRolesMutation
    | LabsGqlUpdateParentStaffMemberRolesMutation
>;

type GqlOrgRoles<T extends IOrganizationType> = T extends 'lab'
    ? LabsGqlStaffRoleLab
    : T extends 'practice'
      ? LabsGqlStaffRolePractice
      : T extends 'internal'
        ? LabsGqlStaffRoleInternal
        : T extends 'external'
          ? LabsGqlStaffRoleExternal
          : T extends 'parent'
            ? LabsGqlStaffRoleParent
            : never;

export function extractGqlRoles<T extends IOrganizationType>(roles: StaffRole[], orgType: T): GqlOrgRoles<T>[] {
    const narrowedRoles = extractRolesOfType(roles, orgType);
    return narrowedRoles as GqlOrgRoles<T>[];
}

export const useDeleteStaffMember = () => {
    const [submitMtn] = useRemoveStaffMemberMutation();
    const mtnSubmitter = (data: DeleteStaffMemberVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [DeleteStaffMemberVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully removed user from organization!', {}],
    });

    return { submit, submitting };
};

export const useAddStaffMember = () => {
    const [submitInternalMtn] = useAddInternalStaffMemberMutation();
    const [submitPracticeMtn] = useAddPracticeStaffMemberMutation();
    const [submitLabMtn] = useAddLabStaffMemberMutation();
    const [submitExternalMtn] = useAddExternalStaffMemberMutation();
    const [submitParentMtn] = useAddParentStaffMemberMutation();

    const onRoleSaved = useUsersAction('ROLE_SAVED');
    const mtnSubmitter = (data: UpsertStaffMemberVars) => {
        const { role, user_id } = data;
        const { organization_id, type, roles } = role;

        if (!organization_id) {
            throw new Error('Select an organization');
        }

        if (!type || !roles.length) {
            throw new Error('Invalid data');
        }

        switch (type) {
            case LabsGqlOrganizationType.Internal:
                return submitInternalMtn({
                    variables: { data: { user_id, organization_id, roles: extractGqlRoles(roles, 'internal') } },
                });
            case LabsGqlOrganizationType.Lab:
                return submitLabMtn({
                    variables: { data: { user_id, organization_id, roles: extractGqlRoles(roles, 'lab') } },
                });
            case LabsGqlOrganizationType.Practice:
                return submitPracticeMtn({
                    variables: { data: { user_id, organization_id, roles: extractGqlRoles(roles, 'practice') } },
                });
            case LabsGqlOrganizationType.External:
                return submitExternalMtn({
                    variables: { data: { user_id, organization_id, roles: extractGqlRoles(roles, 'external') } },
                });
            case LabsGqlOrganizationType.Parent:
                return submitParentMtn({
                    variables: { data: { user_id, organization_id, roles: extractGqlRoles(roles, 'parent') } },
                });
            default:
                throw new Error('Unknown organization type');
        }
    };
    const { submit, submitting } = useChangeSubmissionFn<AddStaffMemberResult, [UpsertStaffMemberVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully added user to organization!', {}],
        onSuccess: result => {
            const { data } = result;

            if (!data) {
                return;
            }

            const org =
                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                'addPracticeStaffMember' in data
                    ? data.addPracticeStaffMember
                    : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                      // eslint-disable-next-line no-nested-ternary
                      'addInternalStaffMember' in data
                      ? data.addInternalStaffMember
                      : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                        // eslint-disable-next-line no-nested-ternary
                        'addLabStaffMember' in data
                        ? data.addLabStaffMember
                        : 'addExternalStaffMember' in data
                          ? data.addExternalStaffMember
                          : data.addParentStaffMember;

            if (org) {
                onRoleSaved({ org });
            }
        },
    });

    return { submit, submitting };
};

export const useUpdateStaffMember = () => {
    const [submitInternalMtn] = useUpdateInternalStaffMemberRolesMutation();
    const [submitPracticeMtn] = useUpdatePracticeStaffMemberRolesMutation();
    const [submitLabMtn] = useUpdateLabStaffMemberRolesMutation();
    const [submitExternalMtn] = useUpdateExternalStaffMemberRolesMutation();
    const [submitParentMtn] = useUpdateParentStaffMemberRolesMutation();
    const onRoleSaved = useUsersAction('ROLE_SAVED');

    const mtnSubmitter = (data: UpsertStaffMemberVars) => {
        const { role } = data;
        const { organization_id, type, roles, member_id } = role;

        if (!type || !organization_id || !roles.length || !member_id) {
            throw new Error('Invalid data');
        }

        switch (type) {
            case LabsGqlOrganizationType.Internal:
                return submitInternalMtn({
                    variables: { data: { member_id, organization_id, roles: extractGqlRoles(roles, 'internal') } },
                });
            case LabsGqlOrganizationType.Lab:
                return submitLabMtn({
                    variables: { data: { member_id, organization_id, roles: extractGqlRoles(roles, 'lab') } },
                });
            case LabsGqlOrganizationType.Practice:
                return submitPracticeMtn({
                    variables: { data: { member_id, organization_id, roles: extractGqlRoles(roles, 'practice') } },
                });
            case LabsGqlOrganizationType.External:
                return submitExternalMtn({
                    variables: { data: { member_id, organization_id, roles: extractGqlRoles(roles, 'external') } },
                });
            case LabsGqlOrganizationType.Parent:
                return submitParentMtn({
                    variables: { data: { member_id, organization_id, roles: extractGqlRoles(roles, 'parent') } },
                });
            default:
                throw new Error('Unknown organization type');
        }
    };
    const { submit, submitting } = useChangeSubmissionFn<UpdateStaffMemberResult, [UpsertStaffMemberVars]>(
        mtnSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => ['Successfully updated user roles in organization!', {}],
            onSuccess: result => {
                const { data } = result;

                if (!data) {
                    return;
                }

                const org =
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    'updateInternalStaffMemberRoles' in data
                        ? data.updateInternalStaffMemberRoles
                        : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                          // eslint-disable-next-line no-nested-ternary
                          'updatePracticeStaffMemberRoles' in data
                          ? data.updatePracticeStaffMemberRoles
                          : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                            // eslint-disable-next-line no-nested-ternary
                            'updateLabStaffMemberRoles' in data
                            ? data.updateLabStaffMemberRoles
                            : 'updateExternalStaffMemberRoles' in data
                              ? data.updateExternalStaffMemberRoles
                              : data.updateParentStaffMemberRoles;

                if (org) {
                    onRoleSaved({ org });
                }
            },
        },
    );

    return { submit, submitting };
};

export const useReactivateStaffMember = () => {
    const onRoleSaved = useUsersAction('ROLE_SAVED');

    const [submitMtn] = useReactivateStaffMemberMutation();
    const mtnSubmitter = (data: ReactivateStaffMemberVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [ReactivateStaffMemberVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully reactivated staff member!', {}],
        onSuccess: result => {
            const { data } = result;

            if (!data) {
                return;
            }

            const org = data.reactivateOrganizationStaffMember;

            if (org) {
                onRoleSaved({ org });
            }
        },
    });

    return { submit, submitting };
};

export const useCreateDrPref = () => {
    const onRoleSaved = useUsersAction('ROLE_SAVED');
    const user = useUsersSelector(s => s.user);

    const [submitMtn] = useCreateDoctorPreferencesMutation();
    const mtnSubmitter = ({ data }: LabsGqlCreateDoctorPreferencesMutationVariables) => {
        const { name, partner_id, roles, contact_email, contact_phone, contact_phone_call_number } = data;

        return submitMtn({
            variables: {
                data: {
                    partner_id,
                    name,
                    roles,
                    contact_email,
                    contact_phone,
                    contact_phone_call_number,
                },
            },
        });
    };
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully added user to organization!', {}],
        onSuccess: result => {
            const { data } = result;

            if (!data) {
                return;
            }

            const drPref = data.createDoctorPreferences;

            if (drPref && drPref.staff_member_id && user) {
                onRoleSaved({
                    org: {
                        id: drPref.partner_id,
                        staff: [
                            {
                                user_id: user.id,
                                // Double type assertions are unsafe and should be avoided.
                                // eslint-disable-next-line @orthly/no-unknown-or-any-cast
                                roles: drPref.roles as unknown as LabsGqlStaffRole[],
                                id: drPref.staff_member_id ?? null,
                                deactivated_at: null,
                            },
                        ],
                    },
                });
            }
        },
    });

    return { submit, submitting };
};

const ReactivateDoctorPreferences_Mutation = graphql(`
    mutation ReactivateDoctorPreferences_Mutation($data: ReactivateDoctorPreferencesCommand!) {
        reactivateDoctorPreferences(data: $data) {
            id
            partner_id
            roles
            staff_member_id
        }
    }
`);

export const useReactivateDrPref = () => {
    const onRoleSaved = useUsersAction('ROLE_SAVED');
    const [reactivateDoctorPreferences] = useMutation(ReactivateDoctorPreferences_Mutation);
    const user = useUsersSelector(s => s.user);

    const { submit, submitting } = useChangeSubmissionFn(reactivateDoctorPreferences, {
        successMessage: () => ['Successfully reactivated staff member!', {}],
        onSuccess: result => {
            const { data } = result;

            if (!data) {
                return;
            }

            const drPref = data.reactivateDoctorPreferences;

            if (drPref && drPref.staff_member_id && user) {
                onRoleSaved({
                    org: {
                        id: drPref.partner_id,
                        staff: [
                            {
                                user_id: user.id,
                                // Double type assertions are unsafe and should be avoided.
                                // eslint-disable-next-line @orthly/no-unknown-or-any-cast
                                roles: drPref.roles as unknown as LabsGqlStaffRole[],
                                id: drPref.staff_member_id ?? null,
                                deactivated_at: null,
                            },
                        ],
                    },
                });
            }
        },
    });

    return { submit, submitting };
};

export const useDeleteDrPref = () => {
    const [submitMtn] = useDeleteDoctorPreferencesMutation();
    const mtnSubmitter = (variables: LabsGqlDeleteDoctorPreferencesMutationVariables) => submitMtn({ variables });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully removed user from organization!', {}],
    });

    return { submit, submitting };
};
