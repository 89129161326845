import { Text, IconButton, KeyboardArrowLeft, KeyboardArrowRight } from '@orthly/ui-primitives';
import { styled } from '@orthly/ui-primitives';
import React from 'react';

const PaginationWrapper = styled('div')({
    padding: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

interface TicketTablePaginationProps {
    page: number;
    setPage: (page: number) => void;
}

export const TicketTablePagination: React.VFC<TicketTablePaginationProps> = ({ page, setPage }) => {
    return (
        <PaginationWrapper>
            <IconButton onClick={() => setPage(page - 1)} disabled={page <= 1}>
                <KeyboardArrowLeft />
            </IconButton>
            <Text medium variant={'body1'}>
                {page}
            </Text>
            <IconButton onClick={() => setPage(page + 1)}>
                <KeyboardArrowRight />
            </IconButton>
        </PaginationWrapper>
    );
};
