import type { AutomationBuilderState } from './AutomationBuilder.types';
import React from 'react';
import type { ReactReduxContextValue, TypedUseSelectorHook } from 'react-redux';
import { createDispatchHook, createSelectorHook } from 'react-redux';

export const AutomationBuilderReduxContext = React.createContext<ReactReduxContextValue>(
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as ReactReduxContextValue<any, any>,
);

export const useAutomationBuilderDispatch = createDispatchHook(AutomationBuilderReduxContext);

export const useAutomationBuilderSelector: TypedUseSelectorHook<AutomationBuilderState> =
    createSelectorHook(AutomationBuilderReduxContext);
