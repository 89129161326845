import { PrintTable } from '../../../../components/PrintTable';
import { getOrderTotalAmountDue } from '../../OrderPricing.utils';
import type { OrderPrice } from '../../types';
import { useRowsWithPartnerName } from './ImportBillingCreditsHooks.graphql';
import type { CreditImportCsvRow, EditableKey } from './types';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { dayjsExt as dayjs } from '@orthly/runtime-utils';
import { SimpleDatePicker, SimpleSelect, SimpleTextField } from '@orthly/ui';
import { DeleteIcon, IconButton, EditIcon, InputAdornment, Tooltip, CheckIcon } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import type { Dictionary } from 'lodash';
import moment from 'moment';
import React from 'react';

export interface ImportBillingCreditsRowDisplayProps {
    inputRows: CreditImportCsvRow[];
    onRemoveRow: (index: string) => void;
    onEditRow: <K extends EditableKey>(practiceId: string, property: K, value: CreditImportCsvRow[K]) => void;
    creditCategoryOptions: { label: string; value: string }[];
    ordersById: Dictionary<LabsGqlLabOrderFragment>;
    orderPricesByOrderId: Dictionary<Pick<OrderPrice, 'order_id' | 'total_price_cents'>[]>;
}

const ErrorCell: React.FC<{
    row: Omit<CreditImportCsvRow, 'row_id'>;
    order?: LabsGqlLabOrderFragment;
    orderPrices?: Pick<OrderPrice, 'order_id' | 'total_price_cents'>[];
}> = ({ row, order, orderPrices }) => {
    const { value: enableReadFromOrderPriceEntity } = useFeatureFlag('enableReadFromOrderPriceEntity');
    const orderTotalAmountDue = getOrderTotalAmountDue({
        order,
        orderPrices: orderPrices ?? [],
        enableReadFromOrderPriceEntity,
    });
    if (row.amount_cents <= 0) {
        return <span style={{ color: 'red' }}>{`Must be > $0`}</span>;
    } else if (order && row.practice_id !== order.partner_id) {
        return <span style={{ color: 'red' }}>{`Order ID doesn't exist for practice`}</span>;
    } else if (order && row.amount_cents > orderTotalAmountDue) {
        return <span style={{ color: 'red' }}>{`Credit must be <= order amount`}</span>;
    }
    return null;
};

export const ImportBillingCreditsRowDisplay: React.FC<ImportBillingCreditsRowDisplayProps> = props => {
    const { inputRows, onEditRow, onRemoveRow, creditCategoryOptions, ordersById, orderPricesByOrderId } = props;
    const displayRows = useRowsWithPartnerName(inputRows);
    const [editingRow, setEditingRow] = React.useState<string | null>(null);

    const isEditing = (row_id: string): boolean => row_id === editingRow;
    const formatDate = (date: string | null): string => (date ? moment(date).format('MM/DD/YYYY') : '');

    // TODO: reduce complexity of this component
    /* eslint-disable sonarjs/cognitive-complexity */
    return (
        <PrintTable
            rows={displayRows.map(({ row_id, ...r }) => ({
                row_id,
                name: r.name,
                order_id: !isEditing(row_id) ? (
                    r.order_id
                ) : (
                    <SimpleTextField
                        onChange={v => onEditRow<'order_id'>(row_id, 'order_id', v ?? null)}
                        value={r.order_id ?? ''}
                        label={'Order Id'}
                    />
                ),
                invoice_date: !isEditing(row_id) ? (
                    formatDate(r.invoice_to_apply_date)
                ) : (
                    <SimpleDatePicker
                        label={'Invoice To Apply Date'}
                        sx={{ width: '100%' }}
                        value={r.invoice_to_apply_date ? new Date(r.invoice_to_apply_date) : null}
                        onChange={date => {
                            const validDate = date && dayjs(date).isValid() ? dayjs(date).toJSON() : null;
                            onEditRow<'invoice_to_apply_date'>(row_id, 'invoice_to_apply_date', validDate);
                        }}
                    />
                ),
                amount: !isEditing(row_id) ? (
                    (r.amount_cents / 100).toFixed(2)
                ) : (
                    <SimpleTextField
                        onChange={raw => {
                            const newValue = !isNaN(parseFloat(raw)) ? parseFloat(raw) : 0;
                            onEditRow<'amount_cents'>(row_id, 'amount_cents', newValue * 100);
                        }}
                        value={(r.amount_cents / 100).toFixed(2)}
                        label={'Amount ($)'}
                        TextFieldProps={{
                            InputProps: { startAdornment: <InputAdornment position={'start'}>$</InputAdornment> },
                        }}
                    />
                ),
                internal_notes: !isEditing(row_id) ? (
                    r.description ?? '-'
                ) : (
                    <SimpleTextField
                        key={r.description ?? '-'}
                        onChange={v => onEditRow<'description'>(row_id, 'description', v ?? null)}
                        value={r.description ?? ''}
                        label={'Internal Notes'}
                    />
                ),
                expiration: !isEditing(row_id) ? (
                    formatDate(r.expiration_date)
                ) : (
                    <SimpleDatePicker
                        label={'Expiration Date'}
                        sx={{ width: '100%' }}
                        value={r.expiration_date ? new Date(r.expiration_date) : null}
                        onChange={date => {
                            const validDate = date && moment(date).isValid() ? moment(date).toJSON() : null;
                            onEditRow<'expiration_date'>(row_id, 'expiration_date', validDate);
                        }}
                        minDate={moment
                            .max(moment().endOf('day'), moment().endOf('month').subtract(1, 'day').endOf('day'))
                            .toDate()}
                    />
                ),
                credit_category: !isEditing(row_id) ? (
                    creditCategoryOptions.find(cco => cco.value === r.credit_category)?.label || r.credit_category
                ) : (
                    <SimpleSelect
                        label={'Category'}
                        options={creditCategoryOptions}
                        value={r?.credit_category}
                        onChange={value => onEditRow<'credit_category'>(row_id, 'credit_category', value)}
                    />
                ),
                error: (
                    <ErrorCell
                        row={r}
                        order={ordersById[r.order_id ?? '']}
                        orderPrices={orderPricesByOrderId[r.order_id ?? '']}
                    />
                ),
                edit: (
                    <Tooltip title={isEditing(row_id) ? 'Finish Editing' : 'Edit'}>
                        <IconButton
                            size={'small'}
                            onClick={() => (isEditing(row_id) ? setEditingRow(null) : setEditingRow(row_id))}
                            tabIndex={-1}
                        >
                            {isEditing(row_id) ? <CheckIcon color={'primary'} /> : <EditIcon color={'primary'} />}
                        </IconButton>
                    </Tooltip>
                ),
                remove: (
                    <Tooltip title={'Remove'}>
                        <IconButton size={'small'} onClick={() => onRemoveRow(row_id)} tabIndex={-1}>
                            <DeleteIcon color={'error'} />
                        </IconButton>
                    </Tooltip>
                ),
            }))}
            title={'Pending import'}
            rootStyle={{ width: '100%' }}
        />
    );
};

export default ImportBillingCreditsRowDisplay;
