import { useCapacityRuleFormFields } from './useCapacityRuleFormFields.graphql';
import { type CapacityStationDtoFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlCreateCapacityStationRuleArgs } from '@orthly/graphql-schema';
import { LabsGqlWorkOrderTypeEnum } from '@orthly/graphql-schema';
import { LoadBlocker, QuickForm } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

type CapacityStationRuleFormFields = Pick<
    LabsGqlCreateCapacityStationRuleArgs,
    | 'name'
    | 'capacity_station_id'
    | 'work_order_type'
    | 'matching_logic_json'
    | 'default_unit_limit'
    | 'lab_product_codes'
    | 'design_station_codes'
    | 'work_order_matching_logic_json'
>;

const INITIAL_VALUES: CapacityStationRuleFormFields = {
    name: ``,
    capacity_station_id: ``,
    work_order_type: LabsGqlWorkOrderTypeEnum.Design,
    default_unit_limit: 0,
};

interface CapacityStationRuleFormProps {
    loading: boolean;
    stations: CapacityStationDtoFragment[];
    onSubmit: (result: CapacityStationRuleFormFields) => void;
    initialValues?: CapacityStationRuleFormFields;
}

export const CapacityStationRuleForm: React.VFC<CapacityStationRuleFormProps> = ({
    loading,
    stations,
    onSubmit,
    initialValues,
}) => {
    const { onChange, loading: fieldsLoading, fields } = useCapacityRuleFormFields(initialValues ?? INITIAL_VALUES);

    return (
        <LoadBlocker blocking={fieldsLoading || loading}>
            <QuickForm<CapacityStationRuleFormFields>
                onSubmit={onSubmit}
                initialValues={initialValues ?? INITIAL_VALUES}
                onChange={onChange}
                fields={{
                    capacity_station_id: {
                        label: 'Station',
                        type: 'select',
                        options: _.sortBy(stations, m => m.name.trim().toLowerCase()).map(({ id, name }) => ({
                            value: id,
                            label: name,
                        })),
                    },
                    work_order_type: {
                        label: 'Work order type',
                        type: 'select',
                        options: Object.values(LabsGqlWorkOrderTypeEnum),
                    },
                    ...fields,
                }}
            />
        </LoadBlocker>
    );
};
