import { useAdminUiSelector } from '../../redux/ui/ui.state';
import type { OpsScreen } from '../../redux/ui/ui.types';
import { OpsScreenEnum, OpsScreens } from '../../redux/ui/ui.types';
import { AnalyticsClient } from '../../utils/analyticsClient';
import { PartnerDataProvider } from '../../utils/usePartners';
import { AccountsRoot } from '../Accounts/AccountsRoot';
import { AdminLoginRedirect } from '../Auth/AdminLogin';
import { AutomationsRoot } from '../Automations/AutomationsRoot';
import { AdminBillingRoot } from '../Billing/AdminBillingRoot.graphql';
import { ChairsideScansRoot } from '../ChairsideScans/ChairsideScansRoot';
import { DesignAutoAssignmentRoot } from '../DesignAutoAssignment/DesignAutoAssignmentRoot';
import { DesignAutoAssignmentBasePath } from '../DesignAutoAssignment/shared';
import { DesignQaRoot } from '../DesignQa/DesignQaRoot.graphql';
import { DesignTrainingRoot } from '../DesignTraining/DesignTrainingRoot.graphql';
import { ConfigScreenRoot } from '../Fields/ConfigScreenRoot';
import { InboxRoot } from '../Inbox/InboxRoot';
import { ManufacturersRoot } from '../Manufacturers/ManufacturersRoot';
import { OrdersRoot } from '../Orders/OrdersRoot';
import { OrdersListViewEntriesCacheProvider } from '../Orders/components/OrdersListView/useOrdersListViewEntries.graphql';
import { PalateRoot } from '../Palate/PalateRoot';
import { PricingRoot } from '../Pricing/PricingRoot';
import { RefabFlowOpsRoot } from '../RefabFlowOpsRoot/RefabFlowOpsRoot';
import { RoutingRoot } from '../Routing/RoutingRoot';
import { Team3dRoot } from '../Team3d/Team3dRoot';
import { ThreeshapePluginRoot } from '../ThreeshapePlugin/ThreeshapePluginRoot';
import { TicketsRoot } from '../Tickets/TicketsRoot';
import { ToolsRoot } from '../Tools/ToolsRoot';
import { UsersRoot } from '../Users/UsersRoot';
import { OpsNavigation } from './OpsNavigation';
import { OpsScreenSessionGuard } from './ScreenSessionGuards';
import { useApolloClient } from '@apollo/client';
import { HotKeyProvider } from '@orthly/dentin';
import { clearApolloCache } from '@orthly/graphql-react';
import { RetainerTokenStorage } from '@orthly/retainer-common';
import { SessionGuard, SessionGuardUtils, useSession } from '@orthly/session-client';
import type { RequireChildrenProps } from '@orthly/ui';
import { ErrorPage } from '@orthly/ui';
import { styled, Text, Button, Grid } from '@orthly/ui-primitives';
import { AppContainer, FeatureFlagOverridesEditor, UnboxingSlipRoute, ChromeGuard } from '@orthly/veneer';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LogarithmicScale,
    Title,
    Tooltip,
} from 'chart.js';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, LogarithmicScale, BarElement, Title, Tooltip, Legend);

const ENABLE_FEATURE_FLAG_OVERRIDES = process.env.ENABLE_FEATURE_FLAG_OVERRIDE;

const ScreenComponentMap: { [K in OpsScreen]-?: React.ComponentType } = {
    tickets: TicketsRoot,
    orders: OrdersRoot,
    automations: AutomationsRoot,
    accounts: AccountsRoot,
    billing: AdminBillingRoot,
    config: ConfigScreenRoot,
    tasks: InboxRoot,
    labs: ManufacturersRoot,
    pricing: PricingRoot,
    routing: RoutingRoot,
    users: UsersRoot,
    palate: PalateRoot,
    chairside_scans: ChairsideScansRoot,
    refab_flowops: RefabFlowOpsRoot,
    design_qa: DesignQaRoot,
    training: DesignTrainingRoot,
    team_3d: Team3dRoot,
    tools: ToolsRoot,
};

const StyleGuideRoot = React.lazy(() => import('../playgrounds/StyleGuide/StyleGuideRoot'));
const GraphqlPlayground = React.lazy(() => import('../playgrounds/GraphqlPlayground/GraphqlPlaygroundRoute'));

const CenteredText = styled(Text)({
    display: 'grid',
    height: '100vh',
    width: '100vw',
    placeContent: 'center',
});

const SessionGuardedScreen: React.VFC<{ screen: OpsScreen }> = ({ screen }) => {
    const Component = ScreenComponentMap[screen];

    return (
        <OpsScreenSessionGuard screen={screen}>
            <Component />
        </OpsScreenSessionGuard>
    );
};

const LogoutButton: React.FC = () => {
    const client = useApolloClient();
    const onClick = React.useCallback(() => {
        clearApolloCache(client).catch(console.error);
        RetainerTokenStorage.removeToken();
    }, [client]);
    return (
        <Grid container style={{ paddingTop: 16 }}>
            <Button variant={'primary'} fullWidth onClick={onClick}>
                Logout
            </Button>
        </Grid>
    );
};

const AppSession: React.FC<RequireChildrenProps> = props => {
    return (
        <SessionGuard test={SessionGuardUtils.sessionExists} fallback={<AdminLoginRedirect />}>
            <SessionGuard
                test={SessionGuardUtils.hasOrgType(['internal'])}
                fallback={
                    <div style={{ width: '100%', height: '100vh' }}>
                        <ErrorPage
                            message={'Unauthorized'}
                            text={"Hey, you're not allowed in here!"}
                            iconName={'PanTool'}
                        >
                            <LogoutButton />
                        </ErrorPage>
                    </div>
                }
            >
                {props.children}
            </SessionGuard>
        </SessionGuard>
    );
};

export const App: React.FC = () => {
    const session = useSession();
    React.useEffect(() => {
        if (session) {
            AnalyticsClient.identify(session.user_id, {
                firstName: session.user.first_name,
                lastName: session.user.last_name,
                email: session.user.email || undefined,
                internalUserRoles: session.roles,
            });
        }
    }, [session]);

    const activeScreen = useAdminUiSelector(s => s.screen);
    return (
        <AppContainer app={'admin'}>
            <React.Suspense fallback={<CenteredText variant={'body1'}>Loading Routes</CenteredText>}>
                <AppSession>
                    <PartnerDataProvider>
                        <OrdersListViewEntriesCacheProvider>
                            <HotKeyProvider>
                                <ChromeGuard>
                                    {activeScreen !== 'palate' && <OpsNavigation />}
                                    <Switch>
                                        {OpsScreens.map((screen: OpsScreen) => (
                                            <Route key={screen} path={`/${screen}`}>
                                                <SessionGuardedScreen screen={screen} />
                                            </Route>
                                        ))}

                                        <Route path={'/style-guide'} component={StyleGuideRoot} />
                                        <Route path={'/graphql-playground'} component={GraphqlPlayground} />

                                        <Route path={'/UnboxingSlip*'}>
                                            <UnboxingSlipRoute />
                                        </Route>
                                        <Route path={'/designer_3shape_plugin'}>
                                            <ThreeshapePluginRoot />
                                        </Route>
                                        <Route
                                            path={DesignAutoAssignmentBasePath}
                                            component={DesignAutoAssignmentRoot}
                                        />
                                        {ENABLE_FEATURE_FLAG_OVERRIDES && (
                                            <Route path={'/flags'} component={FeatureFlagOverridesEditor} />
                                        )}
                                        <Route
                                            exact
                                            path={'/inbox*'}
                                            render={() => <Redirect to={`/${OpsScreenEnum.tasks}*`} />}
                                        />
                                        <Route
                                            exact
                                            path={'*'}
                                            render={() => <Redirect to={`/${OpsScreenEnum.orders}`} />}
                                        />
                                    </Switch>
                                </ChromeGuard>
                            </HotKeyProvider>
                        </OrdersListViewEntriesCacheProvider>
                    </PartnerDataProvider>
                </AppSession>
            </React.Suspense>
        </AppContainer>
    );
};
