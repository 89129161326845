/* eslint-disable max-lines */
import type {
    LabsGqlAlignerItemInput,
    LabsGqlBridgeItemInput,
    LabsGqlCrownItemInput,
    LabsGqlDentureItemInput,
    LabsGqlImplantBridgeItemInput,
    LabsGqlImplantItemInput,
    LabsGqlImplantToothGroupInput,
    LabsGqlInlayItem,
    LabsGqlLabOrderItemV2InputBySku,
    LabsGqlModelItemInput,
    LabsGqlOrderItemArchUnitInput,
    LabsGqlOrderItemLinkRelationship,
    LabsGqlOrderItemMultiToothUnitInput,
    LabsGqlOrderItemSurgicalGuideTreatmentPlanReviewType,
    LabsGqlOrderItemSurgicalGuideFinalRestorativeType,
    LabsGqlOrderItemSingleToothUnitInput,
    LabsGqlOrderItemUnitMetadataInput,
    LabsGqlOtherItemInput,
    LabsGqlPartialDentureItemInput,
    LabsGqlSleepApneaItemInput,
    LabsGqlSurgicalGuideItemInput,
    LabsGqlRemovableItemInput,
    LabsGqlOrderItemSurgicalGuideType,
    LabsGqlVeneerItemInput,
    LabsGqlUnknownItemInput,
    LabsGqlDentureType,
    LabsGqlWaxupItemInput,
    LabsGqlICustomFieldSubmissionInput,
    LabsGqlOrderItemSurgicalGuidePlannedProcedureType,
    LabsGqlOrderItemSleepApneaType,
    LabsGqlTmjItemInput,
    LabsGqlOrderItemTmjType,
    LabsGqlOrderItemTmjApplianceMaterial,
    LabsGqlOrderItemTmjTeethHeightCoverage,
    LabsGqlOrderItemTmjColor,
    LabsGqlOrderItemTmjTeethCoverage,
    LabsGqlOrderItemLinkGenericManufacturer,
    LabsGqlOrderItemRemovableClaspInput,
    LabsGqlOrderItemPartialDentureClaspInput,
    LabsGqlOrderItemPartialDentureCoverageType,
    LabsGqlOrderItemPartialDentureClaspType,
    LabsGqlOrderItemPartialDentureClaspColor,
    LabsGqlOrderItemPartialDentureProductionType,
    LabsGqlOrderItemPartialDentureGenericClaspType,
    LabsGqlDenturesProductionType,
    LabsGqlDenturesFabricationMethod,
    LabsGqlDenturesFestooningLevel,
    LabsGqlDenturesHandleDiastemaOption,
    LabsGqlDenturesBiteAdjustmentType,
    LabsGqlDenturesMidlineCorrectionType,
    LabsGqlSmileStyleChoice,
    LabsGqlAlignerTreatmentArea,
    LabsGqlAlignerPlan,
    LabsGqlBiteConcernOptions,
    LabsGqlCrowdingResolutionOption,
    LabsGqlDenturesTryInDesignType,
    LabsGqlDentureMouthScanMethod,
} from '../../graphql-types.generated';
import { LabsGqlOrderItemModelType, LabsGqlOrderItemArch } from '../../graphql-types.generated';
import type {
    ICartItemV2DTO,
    ICartItemV2OfType,
    ICartCrownItem,
    ICartItemSingleToothUnit,
    ICartVeneerItem,
    ICartInlayItem,
    ICartItemMultiToothUnit,
    ICartPartialDentureItem,
    ICartImplantToothGroup,
    ICartItemUnitMetadata,
    ICartImplantItem,
    ICartImplantBridgeItem,
    ICartBridgeItem,
    ICartModelItem,
    ICartOtherItem,
    ICartRemovableItem,
    ICartItemArchUnit,
    ICartAlignerItem,
    ICartDentureItem,
    ICartWaxupItem,
    ICustomFieldSubmission,
    ICartSurgicalGuideItem,
    ICartSleepApneaItem,
    ICartTMJItem,
} from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import _ from 'lodash';

type ExtractMaybeType<T> = T extends null ? never : T;

const NA_MATERIAL = 'N/A';

// unfortunately bc item interfaces and their gql input counterparts are not intercompatible from either to the other,
// we have these validation functions that return a "converted" item rather than a boolean indicating `item is GqlType`

// all variables are specifically extracted and returned to ensure no extra properties remain in the graphql query
// because, for example, if a checkout item is passed in, then using ...item retains things like item_index that aren't valid for the input
// when updating this class, no variable should be spread except for the return value from other functions within the class

// All enum casts are safe as they're only done when the GQL type is directly created from the shared enum type

export class OrderItemV2InputUtils {
    static convertPreferenceInput(prefs: ICustomFieldSubmission[]): LabsGqlICustomFieldSubmissionInput[] {
        return prefs.map(pref =>
            _.pick(pref, [
                'field_id',
                'display_name',
                'value',
                'price_cents',
                'override_price_cents',
                'override_price_reason',
            ]),
        );
    }

    // helper function that returns a filter fn to make types easier
    private static filterFn<SKU extends LabOrderItemSKUType>(sku: SKU) {
        return (item: ICartItemV2DTO): item is ICartItemV2OfType<SKU> => CartItemV2Utils.itemIsType(item, sku);
    }

    private static validateUnitMetadataInput(unit: ICartItemUnitMetadata<string>): LabsGqlOrderItemUnitMetadataInput {
        const { unit_type, material } = unit;
        if (!material) {
            throw new Error(`Expected unit ${unit_type} to have a material`);
        }

        return { unit_type, material };
    }

    private static validateSingleToothUnitInput(
        unit: ICartItemSingleToothUnit<string>,
    ): LabsGqlOrderItemSingleToothUnitInput {
        const { unn, precious_metal_pennyweight } = unit;

        return { ...OrderItemV2InputUtils.validateUnitMetadataInput(unit), unn, precious_metal_pennyweight };
    }

    private static validateMultiToothUnitInput(
        unit: ICartItemMultiToothUnit<string>,
    ): LabsGqlOrderItemMultiToothUnitInput {
        const { unns } = unit;

        return { ...OrderItemV2InputUtils.validateUnitMetadataInput(unit), unns };
    }

    private static validateArchUnitInput(unit: ICartItemArchUnit<string>): LabsGqlOrderItemArchUnitInput {
        const { arch } = unit;

        return {
            ...OrderItemV2InputUtils.validateUnitMetadataInput(unit),
            // arch is not currently set properly for removeables so we default to dual
            // it's also not used on the server side either, so we set it hear to appease the types
            // until we update checkout to set this property rather than using the legacy custom fields
            // aligners checkout also has it's own custom property for arch
            arch: arch ? (arch as string as LabsGqlOrderItemArch) : LabsGqlOrderItemArch.Dual,
        };
    }

    private static validateImplantGroupInput(unit: ICartImplantToothGroup): LabsGqlImplantToothGroupInput {
        const { unn, implant_metadata } = unit;
        const { manufacturer, system, connection_size, relationship, scanbody_id } = implant_metadata;
        if (!manufacturer || !system || !connection_size || !relationship) {
            throw new Error('Expected implant metadata to have all properties completed');
        }

        return {
            unn,
            abutment: OrderItemV2InputUtils.validateUnitMetadataInput(unit.abutment),
            crown: OrderItemV2InputUtils.validateUnitMetadataInput(unit.crown),
            // Properties are explicitly enumerated so no extra properties are passed through which would cause graphql to break
            implant_metadata: {
                manufacturer,
                system,
                connection_size,
                scanbody_id,
                authentic: implant_metadata.authentic,
                abutment_part_manufacturer: implant_metadata.abutment_part_manufacturer,
                non_preferred_part_reason: implant_metadata.non_preferred_part_reason,
                part_id: implant_metadata.part_id,
                generic_manufacturer:
                    implant_metadata.generic_manufacturer as string as LabsGqlOrderItemLinkGenericManufacturer,
                relationship: relationship as string as LabsGqlOrderItemLinkRelationship,
            },
        };
    }

    // we use unknown item input as the return type since it only inerits from the base with no additional properties
    private static validateBaseItem(item: ICartItemV2DTO): LabsGqlUnknownItemInput {
        const { id, item_notes, attachments, shades, original_item_id } = item;
        return {
            id,
            item_notes,
            original_item_id,
            attachments: attachments.map(a => _.pick(a, ['filepath', 'custom_attachment_id'])),
            shades: shades.map(s => _.pick(s, ['name', 'value'])),
            preference_fields: OrderItemV2InputUtils.convertPreferenceInput(item.preference_fields),
        };
    }

    // crowns, veneers, and inlays all have the same input shape so we can combine them here
    // they're each explicitly listed for improved readability
    // and for easy updates if they change and diverge from one another
    private static validateSingleUnitItemInput(
        item: ICartCrownItem | ICartVeneerItem | ICartInlayItem,
    ): LabsGqlCrownItemInput | LabsGqlVeneerItemInput | LabsGqlInlayItem {
        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            unit: OrderItemV2InputUtils.validateSingleToothUnitInput(item.unit),
        };
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    private static validateAlignerItemInput(item: ICartAlignerItem): LabsGqlAlignerItemInput {
        const { aligner_treatment_metadata } = item;
        // aligners don't currently use the material field so it shouldn't be set on the cart item
        // so we set it to N/A if not provided since it's required
        const material = item.unit.material || NA_MATERIAL;
        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            unit: OrderItemV2InputUtils.validateArchUnitInput({ ...item.unit, material }),
            aligner_treatment_metadata: aligner_treatment_metadata
                ? {
                      ..._.pick(aligner_treatment_metadata, [
                          'movement_restricted_teeth',
                          'attachment_restricted_teeth',
                          'extraction_teeth',
                          'interproximal_space_sizes',
                          'proposed_step_limit',
                          'trays_count',
                      ]),
                      treatment_area: aligner_treatment_metadata.treatment_area
                          ? (aligner_treatment_metadata.treatment_area as string as LabsGqlAlignerTreatmentArea)
                          : aligner_treatment_metadata.treatment_area,
                      aligner_plan: aligner_treatment_metadata.aligner_plan
                          ? (aligner_treatment_metadata.aligner_plan as string as LabsGqlAlignerPlan)
                          : aligner_treatment_metadata.aligner_plan,
                      bite_concerns: aligner_treatment_metadata.bite_concerns
                          ? {
                                improve_overjet: aligner_treatment_metadata.bite_concerns.improve_overjet
                                    ? (aligner_treatment_metadata.bite_concerns
                                          .improve_overjet as string as LabsGqlBiteConcernOptions)
                                    : aligner_treatment_metadata.bite_concerns.improve_overjet,
                                improve_overbite: aligner_treatment_metadata.bite_concerns.improve_overbite
                                    ? (aligner_treatment_metadata.bite_concerns
                                          .improve_overbite as string as LabsGqlBiteConcernOptions)
                                    : aligner_treatment_metadata.bite_concerns.improve_overbite,
                                improve_midline: aligner_treatment_metadata.bite_concerns.improve_midline
                                    ? (aligner_treatment_metadata.bite_concerns
                                          .improve_midline as string as LabsGqlBiteConcernOptions)
                                    : aligner_treatment_metadata.bite_concerns.improve_midline,
                            }
                          : aligner_treatment_metadata.bite_concerns,
                      crowding_resolution: aligner_treatment_metadata.crowding_resolution
                          ? {
                                expansion: aligner_treatment_metadata.crowding_resolution.expansion
                                    ? (aligner_treatment_metadata.crowding_resolution
                                          .expansion as string as LabsGqlCrowdingResolutionOption)
                                    : aligner_treatment_metadata.crowding_resolution.expansion,
                                proclination: aligner_treatment_metadata.crowding_resolution.proclination
                                    ? (aligner_treatment_metadata.crowding_resolution
                                          .proclination as string as LabsGqlCrowdingResolutionOption)
                                    : aligner_treatment_metadata.crowding_resolution.proclination,
                                ipr: aligner_treatment_metadata.crowding_resolution.ipr
                                    ? (aligner_treatment_metadata.crowding_resolution
                                          .ipr as string as LabsGqlCrowdingResolutionOption)
                                    : aligner_treatment_metadata.crowding_resolution.ipr,
                            }
                          : aligner_treatment_metadata.crowding_resolution,
                  }
                : aligner_treatment_metadata,
        };
    }

    private static validateDentureItemInput(item: ICartDentureItem): LabsGqlDentureItemInput {
        const { denture_type, denture_production_metadata } = item;
        if (!denture_type) {
            throw new Error('Expected denture item to have a denture type');
        }

        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            unit: OrderItemV2InputUtils.validateArchUnitInput(item.unit),
            denture_type: denture_type as string as LabsGqlDentureType,
            denture_production_type: item.denture_production_type
                ? (item.denture_production_type as string as LabsGqlDenturesProductionType)
                : item.denture_production_type,
            denture_fabrication_method: item.denture_fabrication_method
                ? (item.denture_fabrication_method as string as LabsGqlDenturesFabricationMethod)
                : item.denture_fabrication_method,
            denture_mouth_scan_method: item.denture_mouth_scan_method
                ? (item.denture_mouth_scan_method as string as LabsGqlDentureMouthScanMethod)
                : item.denture_mouth_scan_method,
            denture_production_metadata: denture_production_metadata
                ? {
                      ..._.pick(denture_production_metadata, [
                          'quantity',
                          'correct_occlusal_scheme',
                          'add_post_dam',
                          'add_stippling',
                          'implant_support',
                          'copy_metadata',
                          'add_metal_framework',
                          'add_metal_mesh',
                          'add_softliner',
                          'cusil_gaskets_teeth',
                      ]),
                      smile_style: denture_production_metadata.smile_style
                          ? (denture_production_metadata.smile_style as string as LabsGqlSmileStyleChoice)
                          : denture_production_metadata.smile_style,
                      festooning_level: denture_production_metadata.festooning_level
                          ? (denture_production_metadata.festooning_level as string as LabsGqlDenturesFestooningLevel)
                          : denture_production_metadata.festooning_level,
                      handle_diastema: denture_production_metadata.handle_diastema
                          ? (denture_production_metadata.handle_diastema as string as LabsGqlDenturesHandleDiastemaOption)
                          : denture_production_metadata.handle_diastema,
                      bite_adjustment: denture_production_metadata.bite_adjustment
                          ? {
                                adjustment_type: denture_production_metadata.bite_adjustment
                                    .adjustment_type as string as LabsGqlDenturesBiteAdjustmentType,
                                adjustment_distance_mm:
                                    denture_production_metadata.bite_adjustment.adjustment_distance_mm,
                            }
                          : denture_production_metadata.bite_adjustment,
                      midline_correction: denture_production_metadata.midline_correction
                          ? {
                                correction_type: denture_production_metadata.midline_correction
                                    .correction_type as string as LabsGqlDenturesMidlineCorrectionType,
                                correction_distance_mm:
                                    denture_production_metadata.midline_correction.correction_distance_mm,
                            }
                          : denture_production_metadata.midline_correction,
                      try_in_design: denture_production_metadata.try_in_design
                          ? (denture_production_metadata.try_in_design as string as LabsGqlDenturesTryInDesignType)
                          : denture_production_metadata.try_in_design,
                  }
                : denture_production_metadata,
        };
    }

    private static validatePartialDentureItemInput(item: ICartPartialDentureItem): LabsGqlPartialDentureItemInput {
        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            unit: OrderItemV2InputUtils.validateMultiToothUnitInput(item.unit),
            partial_production_type: item.partial_production_type
                ? (item.partial_production_type as string as LabsGqlOrderItemPartialDentureProductionType)
                : item.partial_production_type,
            coverage_type: item.coverage_type
                ? (item.coverage_type as string as LabsGqlOrderItemPartialDentureCoverageType)
                : item.coverage_type,
            // Double type assertions are unsafe and should be avoided.
            // eslint-disable-next-line @orthly/no-unknown-or-any-cast
            clasps: item.clasps ? (item.clasps as unknown as LabsGqlOrderItemPartialDentureClaspInput[]) : item.clasps,
            clasp_type: item.clasp_type
                ? (item.clasp_type as string as LabsGqlOrderItemPartialDentureClaspType)
                : item.clasp_type,
            partial_production_metadata: item.partial_production_metadata
                ? {
                      ..._.pick(item.partial_production_metadata, [
                          'is_replacement',
                          'digital_extractions',
                          'clasp_locations',
                          'clasp_shade',
                          'clasp_notes',
                      ]),
                      clasp_generic_type: item.partial_production_metadata.clasp_generic_type
                          ? (item.partial_production_metadata
                                .clasp_generic_type as string as LabsGqlOrderItemPartialDentureGenericClaspType)
                          : item.partial_production_metadata.clasp_generic_type,
                      clasp_color: item.partial_production_metadata.clasp_color
                          ? (item.partial_production_metadata
                                .clasp_color as string as LabsGqlOrderItemPartialDentureClaspColor)
                          : item.partial_production_metadata.clasp_color,
                  }
                : item.partial_production_metadata,
        };
    }

    private static validateImplantItemInput(item: ICartImplantItem): LabsGqlImplantItemInput {
        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            unit: OrderItemV2InputUtils.validateImplantGroupInput(item.unit),
        };
    }

    private static validateImplantBridgeItemInput(item: ICartImplantBridgeItem): LabsGqlImplantBridgeItemInput {
        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            implants: item.implants.map(OrderItemV2InputUtils.validateImplantGroupInput),
            restoratives: item.restoratives.map(OrderItemV2InputUtils.validateSingleToothUnitInput),
        };
    }

    private static validateBridgeItemInput(item: ICartBridgeItem): LabsGqlBridgeItemInput {
        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            units: item.units.map(OrderItemV2InputUtils.validateSingleToothUnitInput),
        };
    }

    private static validateRemovableItemInput(item: ICartRemovableItem): LabsGqlRemovableItemInput {
        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            unit: OrderItemV2InputUtils.validateArchUnitInput(item.unit),
            // Double type assertions are unsafe and should be avoided.
            // eslint-disable-next-line @orthly/no-unknown-or-any-cast
            clasps: item.clasps ? (item.clasps as unknown as LabsGqlOrderItemRemovableClaspInput[]) : item.clasps,
        };
    }

    private static validateModelItemInput(item: ICartModelItem): LabsGqlModelItemInput {
        const { unit_type } = item;
        // if there's no model type set and the item is not a 'Model' model (eg. an 'Implant Model') then we default to dual full
        const model_type =
            item.model_type ?? unit_type === 'Model' ? item.model_type : LabsGqlOrderItemModelType.DualFullArch;
        if (!model_type) {
            throw new Error('Expected model item to have a model type');
        }

        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            unit_type,
            model_type: model_type as string as LabsGqlOrderItemModelType,
            selected_teeth: item.selected_teeth,
        };
    }

    private static validateSurgicalGuideItemInput(item: ICartSurgicalGuideItem): LabsGqlSurgicalGuideItemInput {
        const { unns, unit_type, implant_metadata } = item.unit;
        const {
            planned_procedures,
            treatment_plan_review_type,
            patient_cbct_appointment_date,
            final_restorative_type,
        } = item.treatment_plan_metadata;

        if (!item.surgical_guide_type) {
            throw new Error('Expected surgical guide item to have a surgical_guide_type');
        }

        if (!implant_metadata.manufacturer || !implant_metadata.system || !implant_metadata.drill_kit) {
            throw new Error(`Expected surgical guide item to have a manufacturer, system, and drill kit`);
        }

        if (!final_restorative_type || !treatment_plan_review_type) {
            throw new Error('Expected surgical guide item to have a final restorative type and review type');
        }

        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            surgical_guide_type: item.surgical_guide_type as string as LabsGqlOrderItemSurgicalGuideType,
            unit: {
                unns,
                unit_type,
                implant_metadata: {
                    manufacturer: implant_metadata.manufacturer,
                    system: implant_metadata.system,
                    drill_kit: implant_metadata.drill_kit,
                    desired_size: implant_metadata.desired_size,
                },
            },
            treatment_plan_metadata: {
                patient_cbct_appointment_date,
                planned_procedures:
                    (planned_procedures as string[] as LabsGqlOrderItemSurgicalGuidePlannedProcedureType[]) ?? [],
                treatment_plan_review_type:
                    treatment_plan_review_type as string as LabsGqlOrderItemSurgicalGuideTreatmentPlanReviewType,
                final_restorative_type:
                    final_restorative_type as string as LabsGqlOrderItemSurgicalGuideFinalRestorativeType,
            },
        };
    }

    private static validateSleepApneaItemInput(item: ICartSleepApneaItem): LabsGqlSleepApneaItemInput {
        if (!item.sleep_apnea_type) {
            throw new Error('Expected sleep apnea item to have a type');
        }

        const {
            should_cover_last_molar_distal,
            should_include_anterior_discluding_ramp,
            should_have_posterior_support_pads,
        } = item.manufacturing_metadata;
        if (_.isNil(should_cover_last_molar_distal) || _.isNil(should_include_anterior_discluding_ramp)) {
            throw new Error('Expected all required manufacturing metadata properties to be completed');
        }

        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            sleep_apnea_type: item.sleep_apnea_type as string as LabsGqlOrderItemSleepApneaType,
            manufacturing_metadata: {
                should_cover_last_molar_distal,
                should_include_anterior_discluding_ramp,
                should_have_posterior_support_pads,
            },
        };
    }

    private static validateTMJItemInput(item: ICartTMJItem): LabsGqlTmjItemInput {
        if (!item.tmj_type) {
            throw new Error('Expected tmj item to have a valid type');
        }

        // default the material to the type if it's not set
        const material = item.unit.material ?? item.tmj_type;
        const {
            appliance_material,
            arch_separation_distance_mm,
            teeth_height_coverage,
            splint_material_thickness_mm,
            color,
            lingual_wire,
            teeth_coverage,
        } = item.manufacturing_metadata;

        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            type: item.tmj_type as string as LabsGqlOrderItemTmjType,
            unit: OrderItemV2InputUtils.validateArchUnitInput({ ...item.unit, material }),
            manufacturing_metadata: {
                arch_separation_distance_mm,
                splint_material_thickness_mm,
                lingual_wire,
                appliance_material: appliance_material
                    ? (appliance_material as string as LabsGqlOrderItemTmjApplianceMaterial)
                    : appliance_material,
                teeth_height_coverage: teeth_height_coverage
                    ? (teeth_height_coverage as string as LabsGqlOrderItemTmjTeethHeightCoverage)
                    : teeth_height_coverage,
                color: color ? (color as string as LabsGqlOrderItemTmjColor) : color,
                teeth_coverage: teeth_coverage
                    ? (teeth_coverage as string as LabsGqlOrderItemTmjTeethCoverage)
                    : teeth_coverage,
            },
        };
    }

    private static validateOtherItemInput(item: ICartOtherItem): LabsGqlOtherItemInput {
        // basically every "other" item does not set a material so we just default to n/a when not set
        const material = !item.unit.material ? NA_MATERIAL : item.unit.material;

        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            unit: OrderItemV2InputUtils.validateUnitMetadataInput({ ...item.unit, material }),
        };
    }

    private static validateWaxupItemInput(item: ICartWaxupItem): LabsGqlWaxupItemInput {
        const { unit_type, selected_teeth } = item;

        return {
            ...OrderItemV2InputUtils.validateBaseItem(item),
            unit_type,
            selected_teeth,
        };
    }

    static getOrderItemV2InputBySKUOrFail(items: ICartItemV2DTO[]): LabsGqlLabOrderItemV2InputBySku {
        const itemsBySKU = _.groupBy(items, i => i.sku);

        const getInputItems = <
            Property extends keyof LabsGqlLabOrderItemV2InputBySku,
            SKU extends LabOrderItemSKUType = LabOrderItemSKUType,
            Output extends ExtractMaybeType<
                Required<LabsGqlLabOrderItemV2InputBySku>[Property]
            >[number] = ExtractMaybeType<Required<LabsGqlLabOrderItemV2InputBySku>[Property]>[number],
        >(
            sku: SKU,
            cb: (item: ICartItemV2OfType<SKU>) => Output,
        ): Output[] | undefined => {
            return itemsBySKU[sku]?.filter(OrderItemV2InputUtils.filterFn(sku)).map(cb);
        };

        return {
            aligner_items: getInputItems(LabOrderItemSKUType.Aligners, OrderItemV2InputUtils.validateAlignerItemInput),
            denture_items: getInputItems(LabOrderItemSKUType.Denture, OrderItemV2InputUtils.validateDentureItemInput),
            partial_denture_items: getInputItems(
                LabOrderItemSKUType.Partial,
                OrderItemV2InputUtils.validatePartialDentureItemInput,
            ),
            crown_items: getInputItems(LabOrderItemSKUType.Crown, OrderItemV2InputUtils.validateSingleUnitItemInput),
            implant_items: getInputItems(LabOrderItemSKUType.Implant, OrderItemV2InputUtils.validateImplantItemInput),
            implant_bridge_items: getInputItems(
                LabOrderItemSKUType.ImplantBridge,
                OrderItemV2InputUtils.validateImplantBridgeItemInput,
            ),
            bridge_items: getInputItems(LabOrderItemSKUType.Bridge, OrderItemV2InputUtils.validateBridgeItemInput),
            removable_items: getInputItems(
                LabOrderItemSKUType.Removeable,
                OrderItemV2InputUtils.validateRemovableItemInput,
            ),
            model_items: getInputItems(LabOrderItemSKUType.Model, OrderItemV2InputUtils.validateModelItemInput),
            other_items: getInputItems(LabOrderItemSKUType.Other, OrderItemV2InputUtils.validateOtherItemInput),
            waxup_items: getInputItems(LabOrderItemSKUType.Waxup, OrderItemV2InputUtils.validateWaxupItemInput),
            veneer_items: getInputItems(LabOrderItemSKUType.Veneer, OrderItemV2InputUtils.validateSingleUnitItemInput),
            inlay_items: getInputItems(LabOrderItemSKUType.Inlay, OrderItemV2InputUtils.validateSingleUnitItemInput),
            surgical_guide_items: getInputItems(
                LabOrderItemSKUType.SurgicalGuide,
                OrderItemV2InputUtils.validateSurgicalGuideItemInput,
            ),
            sleep_apnea_items: getInputItems(
                LabOrderItemSKUType.SleepApnea,
                OrderItemV2InputUtils.validateSleepApneaItemInput,
            ),
            tmj_items: getInputItems(LabOrderItemSKUType.TMJ, OrderItemV2InputUtils.validateTMJItemInput),
            unknown_items: getInputItems(LabOrderItemSKUType.Unknown, OrderItemV2InputUtils.validateBaseItem),
        };
    }

    // returns null if items are invalid
    static getOrderItemV2InputBySKU(items: ICartItemV2DTO[]): LabsGqlLabOrderItemV2InputBySku | null {
        try {
            return OrderItemV2InputUtils.getOrderItemV2InputBySKUOrFail(items);
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            console.error('Failed to get order item input by SKU on', items, 'due to', e);
            return null;
        }
    }
}
