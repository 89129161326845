import { RESTORATIVE_GROUP } from '../components/DesignLoader/DesignLoader';
import type { Object3D, Scene } from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';

const exporter = new GLTFExporter();

export type PainterData = {
    name: string;
    data: Blob;
};

function parseModel(model: Object3D): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        exporter.parse(
            model,
            result => {
                if (result instanceof ArrayBuffer) {
                    resolve(result);
                } else {
                    reject(new Error('Unexpected result type'));
                }
            },
            error => {
                reject(error);
            },
            { binary: true, includeCustomExtensions: true },
        );
    });
}

export async function handleGLBExport(scene: Scene, download?: boolean): Promise<PainterData[]> {
    const restoratives = scene.getObjectByName(RESTORATIVE_GROUP);
    const blobs: PainterData[] = [];

    if (!restoratives) {
        console.warn('No restoratives found in the scene.');
        return blobs;
    }

    const exportPromises = restoratives.children.map(async restorative => {
        try {
            const result = await parseModel(restorative);
            const blob = new Blob([result], { type: 'application/octet-stream' });

            if (download) {
                console.log('restorative', restorative);
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${restorative.name}.glb`;
                link.click();
            }

            blobs.push({
                data: blob,
                name: restorative.name,
            });
        } catch (error) {
            console.error('Export failed:', error);
        }
    });

    await Promise.all(exportPromises);
    return blobs;
}
