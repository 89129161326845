import { ToolNames } from '../components/ToolList/ToolList';
import { selectedRestorativeStateAtom, toothRefAtom } from '../store/designsStore';
import { gradientVecAtom, store, cameraControlsDisabledAtom, selectedToolAtom } from '../store/store';
import { atomToObservable } from '../utils/AtomToObservable';
import { FlossPalette } from '@orthly/ui-primitives';
import type { Subscription } from 'rxjs';
import { fromEvent } from 'rxjs';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil, filter, switchMap, tap, distinctUntilChanged, startWith } from 'rxjs/operators';
import type { Scene, Camera, WebGLRenderer, Intersection } from 'three';
import { Vector3, SphereGeometry, MeshBasicMaterial, Mesh, ArrowHelper, Vector2, Box3, Raycaster, Group } from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

function createSphere(): Mesh {
    const geometry = new SphereGeometry(1, 32, 32);
    const material = new MeshBasicMaterial({
        transparent: true,
        opacity: 0.1,
        color: 0x888888,
    });
    return new Mesh(geometry, material);
}

function createArrow(): ArrowHelper {
    const direction = new Vector3(0, 1, 0);
    const origin = new Vector3(0, 0, 0);
    return new ArrowHelper(direction, origin, 1, FlossPalette.STAR_GRASS, 0.2, 0.1);
}

function createHandle(): Mesh {
    const geometry = new SphereGeometry(2, 16, 16);
    const material = new MeshBasicMaterial({
        transparent: true,
        opacity: 0.0,
    });
    return new Mesh(geometry, material);
}

function createInsertionAxis(axis: Vector3): Line2 {
    const geometry = new LineGeometry();
    geometry.setPositions([0, 0, 0, ...axis.toArray()]);
    const material = new LineMaterial({
        color: 0x000000,
        linewidth: 3,
        polygonOffset: true,
        polygonOffsetFactor: 1,
        polygonOffsetUnits: 1,
    });
    const line = new Line2(geometry, material);
    line.name = 'Insertion Axis';
    line.userData = { direction: axis };
    return line;
}

function updateInsertionAxis(axisLine: Line2, axis: Vector3) {
    const normalizedDirection = axis.clone().normalize();
    const startPoint = [0, 0, 0];
    const endPoint = normalizedDirection.toArray();
    const newPositions = [...startPoint, ...endPoint];
    axisLine.userData = { direction: axis };
    axisLine.geometry.setPositions(newPositions);
}

function checkAxisIntersection(raycaster: Raycaster, axisLine: Line2): Vector3 | undefined {
    const axisIntersects = raycaster.intersectObject(axisLine);

    if (axisIntersects.length > 0 && axisIntersects[0]?.object instanceof Line2) {
        const intersectedLine = axisIntersects[0].object as Line2;
        return intersectedLine.userData.direction ?? new Vector3(0, 1, 0);
    }

    return undefined;
}

const yVec = new Vector3(0, 1, 0);

export function GradientDirectionControl(scene: Scene, camera: Camera, renderer: WebGLRenderer) {
    const container = new Group();
    const sphere = createSphere();
    const arrow = createArrow();
    const handle = createHandle();
    const raycaster = new Raycaster();
    const insertionAxis = createInsertionAxis(yVec);
    let isInScene = false;
    let isDragging = false;
    const destroy = new Subject<void>();

    container.add(sphere, arrow, handle, insertionAxis);

    function setGradientDirection(direction: Vector3, length: number) {
        arrow.setDirection(direction);
        handle.position.copy(direction.clone().multiplyScalar(length));
        store.set(gradientVecAtom, direction.clone());
    }

    function updatePosition(targetMesh: Mesh) {
        if (!targetMesh) {
            return;
        }

        const boundingBox = new Box3().setFromObject(targetMesh);
        const center = boundingBox.getCenter(new Vector3());
        const size = boundingBox.getSize(new Vector3());
        const maxDimension = Math.max(size.x, size.y, size.z);

        container.position.copy(center);

        sphere.scale.setScalar(maxDimension * 1.2);

        arrow.setLength(maxDimension * 1.2);

        const direction = store.get(gradientVecAtom);
        const handlePosition = direction.clone().multiplyScalar(maxDimension * 1.2);
        handle.position.copy(handlePosition);

        insertionAxis.scale.setScalar(maxDimension * 0.8);
    }

    function addToScene() {
        scene.add(container);
        const restorativeState = store.get(selectedRestorativeStateAtom);
        updateInsertionAxis(insertionAxis, restorativeState?.insertionAxis ?? yVec);
        setGradientDirection(restorativeState?.gradientDirection ?? yVec, sphere.scale.x);
        isInScene = true;
    }

    function removeFromScene() {
        scene.remove(container);
        isInScene = false;
    }

    const selectedTool = atomToObservable(selectedToolAtom);
    const targetMesh = atomToObservable(toothRefAtom);

    const enabled = combineLatest([selectedTool, targetMesh]).pipe(
        takeUntil(destroy),
        map(([selectedTool, targetMesh]) => selectedTool === ToolNames.Gradient && targetMesh !== null),
        distinctUntilChanged(),
        startWith(false),
        tap(enabled => {
            if (enabled) {
                const targetMesh = store.get(toothRefAtom);
                if (targetMesh) {
                    updatePosition(targetMesh);
                    if (!isInScene) {
                        addToScene();
                    }
                }
            } else {
                if (isInScene) {
                    removeFromScene();
                }
                renderer.domElement.style.cursor = 'default';
            }
        }),
    );

    const canvas = renderer.domElement;
    const mouseDown = fromEvent<MouseEvent>(canvas, 'mousedown', { capture: true });
    const mouseMove = fromEvent<MouseEvent>(canvas, 'mousemove', { capture: true });
    const mouseUp = fromEvent<MouseEvent>(canvas, 'mouseup', { capture: true });

    // Subscription variables, declared outside the enabled.subscribe scope
    let hoverCursorSubscription: Subscription | undefined;
    let dragSubscription: Subscription | undefined;

    enabled.subscribe((enabled: boolean) => {
        if (enabled) {
            hoverCursorSubscription = mouseMove
                .pipe(
                    takeUntil(destroy),
                    filter(() => isInScene && !isDragging),
                    tap(event => {
                        const rect = canvas.getBoundingClientRect();
                        const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
                        const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

                        raycaster.setFromCamera(new Vector2(x, y), camera);
                        const handleIntersects = raycaster.intersectObject(handle);
                        const axisIntersects = raycaster.intersectObject(insertionAxis);

                        if (handleIntersects.length > 0 || axisIntersects.length > 0) {
                            event.stopPropagation();
                            event.preventDefault();
                            canvas.style.cursor = 'pointer';
                        } else {
                            canvas.style.cursor = 'default';
                        }
                    }),
                )
                .subscribe();

            dragSubscription = mouseDown
                .pipe(
                    filter(() => isInScene),
                    map(event => {
                        const rect = canvas.getBoundingClientRect();
                        const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
                        const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

                        raycaster.setFromCamera(new Vector2(x, y), camera);

                        const handleIntersects = raycaster.intersectObject(handle);
                        if (handleIntersects.length > 0) {
                            return handleIntersects[0];
                        }

                        const direction = checkAxisIntersection(raycaster, insertionAxis);

                        if (direction) {
                            setGradientDirection(direction, sphere.scale.x);
                            return null;
                        }

                        return null;
                    }),
                    filter(intersection => !!intersection),
                    switchMap(() => {
                        isDragging = true;
                        canvas.style.cursor = 'grabbing';
                        store.set(cameraControlsDisabledAtom, true);

                        return mouseMove.pipe(
                            takeUntil(
                                mouseUp.pipe(
                                    tap(() => {
                                        isDragging = false;
                                        canvas.style.cursor = 'pointer';
                                        store.set(cameraControlsDisabledAtom, false);
                                    }),
                                ),
                            ),
                            tap(event => {
                                event.stopPropagation();
                                event.preventDefault();
                            }),
                            map(event => {
                                const rect = canvas.getBoundingClientRect();
                                const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
                                const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

                                raycaster.setFromCamera(new Vector2(x, y), camera);
                                return raycaster.intersectObject(sphere)[0] as Intersection;
                            }),
                            filter(intersection => !!intersection && !!intersection.point),
                        );
                    }),
                )
                .subscribe(intersection => {
                    if (intersection && intersection.point) {
                        const localPoint = container.worldToLocal(intersection.point.clone());
                        const direction = localPoint.normalize();
                        setGradientDirection(direction, sphere.scale.x);
                    }
                });
        } else {
            hoverCursorSubscription?.unsubscribe();
            dragSubscription?.unsubscribe();
        }
    });

    function dispose() {
        destroy.next();
        destroy.complete();
        if (isInScene) {
            removeFromScene();
        }
        renderer.domElement.style.cursor = 'default';
    }

    return dispose;
}
