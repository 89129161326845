import { selectedRestorativeToothNumberAtom } from '../../store/designsStore';
import type { SetAtom } from '../../types/SetAtom';
import type { SceneModelAppearance } from '@orthly/dentin';
import {
    FlossPalette,
    Icon,
    IconToggleButton,
    ListItemButton,
    ListItemText,
    styled,
    Text,
} from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

type ModelControllerProps = {
    label: string;
    appearance: { visible: boolean; transparent: boolean; colorize?: boolean };
    setAppearance?: SetAtom<SceneModelAppearance[], void>;
    toothNumber?: number;
    key?: string;
    setRestorativesAppearance?: (sceneModelAppearance: SceneModelAppearance) => void;
};

const ListItemStyled = styled(ListItemButton)(({ selected }) => ({
    padding: '8px',
    cursor: 'pointer',
    backgroundColor: selected ? '#e0f7fa' : 'transparent',
    border: selected ? '1px solid #00796b' : '1px solid transparent',
    borderRadius: '4px',
    '&:hover': {
        backgroundColor: '#f1f1f1',
    },
}));

const LabelStyled = styled(Text)({
    paddingLeft: 16,
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
});

const IconStyled = styled(Icon)({
    height: 16,
    width: 16,
});

const IconButtonStyled = styled(IconToggleButton)({
    backgroundColor: FlossPalette.TAN,
    borderRadius: 10,
    height: 20,
    width: 20,
    margin: `0px 4px`,
    padding: 0,
    color: FlossPalette.BLACK,
    '&.active': {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
    '@media (hover: none)': {
        '&:hover': {
            // Match the non-hover border.
            border: '1px solid transparent',
        },
    },
});

const ColorizeIconButtonStyled = styled(IconButtonStyled)({
    '& svg': {
        filter: 'saturate(0)',
        opacity: 0.5,
    },

    '&.active svg': {
        filter: 'none',
        opacity: 1,
    },
});

export const ModelController: React.FC<ModelControllerProps> = ({
    label,
    toothNumber,
    key,
    appearance,
    setAppearance,
    setRestorativesAppearance,
}) => {
    const { visible, transparent, colorize } = appearance;
    const visibilityTooltip = visible ? 'Hide' : 'Show';
    const transparencyTooltip = transparent ? 'Set opaque' : 'Set transparent';
    const colorizeTooltip = colorize ? 'Disable color' : 'Enable color';
    const visibilityIcon = visible ? 'EyeOpenIcon' : 'EyeStrikethroughIcon';
    const [selectedRestorativeNumber, setSelectedRestorativeNumber] = useAtom(selectedRestorativeToothNumberAtom);

    const toggleVisible = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAppearance?.({ ...appearance, visible: !appearance.visible });
        setRestorativesAppearance?.({ ...appearance, visible: !appearance.visible });
    };

    const toggleTransparent = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAppearance?.({ ...appearance, transparent: !appearance.transparent });
        setRestorativesAppearance?.({ ...appearance, transparent: !appearance.transparent });
    };

    const toggleColorize = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAppearance?.({ ...appearance, colorize: !appearance.colorize });
        setRestorativesAppearance?.({ ...appearance, colorize: !appearance.colorize });
    };

    return (
        <ListItemStyled
            key={key ?? label}
            selected={toothNumber === selectedRestorativeNumber}
            onClick={() => {
                if (toothNumber === selectedRestorativeNumber) {
                    setSelectedRestorativeNumber(0);
                } else if (toothNumber) {
                    setSelectedRestorativeNumber(toothNumber);
                }
            }}
        >
            <ListItemText>
                <LabelStyled variant={'body2'}>{label}</LabelStyled>
            </ListItemText>
            {colorize !== undefined && toggleColorize !== undefined && (
                <ColorizeIconButtonStyled active={colorize} onClick={toggleColorize} tooltip={colorizeTooltip}>
                    <IconStyled icon={'ModelTextureIcon'} viewBox={'0 0 24 24'} />
                </ColorizeIconButtonStyled>
            )}
            <IconButtonStyled active={transparent} onClick={toggleTransparent} tooltip={transparencyTooltip}>
                <IconStyled icon={'RestorativesTransparentIcon'} viewBox={'0 0 24 24'} />
            </IconButtonStyled>
            <IconButtonStyled active={visible} onClick={toggleVisible} tooltip={visibilityTooltip}>
                <IconStyled icon={visibilityIcon} viewBox={'0 0 24 24'} />
            </IconButtonStyled>
        </ListItemStyled>
    );
};
