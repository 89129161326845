import type { RestorativeState } from '../../store/designsStore';
import { restorativesStateAtom, type DesignModels } from '../../store/designsStore';
import { sceneRefAtom } from '../../store/store';
import { ApplyShade } from '../../utils/ApplyShade';
import { Gradient } from '../../utils/Gradient';
import { prepStl } from '../../utils/PrepStl';
import { DEFAULT_CONTROL_POINTS, formatShadeData } from '../../utils/ShadeValues';
import { unwrapUVs } from '../../utils/UVUnwrap';
import type { VeneerOrderDetailItemsTableSalesOrderItemFullDisplayInfo_FragmentFragment } from '@orthly/graphql-inline-react';
import { useAtom, useAtomValue } from 'jotai';
import React from 'react';
import { BufferAttribute, BufferGeometry, Float32BufferAttribute, Group, Vector3 } from 'three';

interface DesignLoaderProps {
    models: DesignModels;
    fullDisplayInfo?: VeneerOrderDetailItemsTableSalesOrderItemFullDisplayInfo_FragmentFragment;
}

export const RESTORATIVE_GROUP = 'restoratives';

export const DesignLoader: React.FC<DesignLoaderProps> = ({ models, fullDisplayInfo }) => {
    const sceneRef = useAtomValue(sceneRefAtom);
    const [restorativesState, setRestorativesState] = useAtom(restorativesStateAtom);
    const [restorativesLoaded, setRestorativesLoaded] = React.useState<boolean>(false);
    const shades = fullDisplayInfo?.shade_details;

    React.useEffect(() => {
        // update restoratives state
        if (models?.restorativeModels?.length && shades) {
            const restorativesNewState = new Map<number, RestorativeState>();
            models.restorativeModels.forEach(model => {
                const insertionAxis = new Vector3(model.insertionAxis.x, model.insertionAxis.y, model.insertionAxis.z);
                const restorativeState: RestorativeState = {
                    toothNumber: model.toothNumber,
                    insertionAxis,
                    gradientDirection: insertionAxis.clone(),
                    shadeData: formatShadeData(fullDisplayInfo),
                    gradientControlPoints: DEFAULT_CONTROL_POINTS,
                    canvasLayers: {},
                };
                restorativesNewState.set(model.toothNumber, restorativeState);
            });
            setRestorativesState(restorativesNewState);
        }
    }, [models, shades, restorativesLoaded, setRestorativesState, fullDisplayInfo]);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    React.useEffect(() => {
        if (restorativesLoaded) {
            return;
        }
        // update restoratives state
        if (models?.restorativeModels?.length > 0 && sceneRef) {
            const restorativeGroup = new Group();
            restorativeGroup.name = RESTORATIVE_GROUP;
            models.restorativeModels.forEach(model => {
                //LEGACY THREE version compatibility
                const newGeometry = new BufferGeometry();
                const oldGeometry = model.geometry;

                // Copy position attribute
                if (oldGeometry.attributes.position) {
                    newGeometry.setAttribute(
                        'position',
                        new Float32BufferAttribute(Array.from(oldGeometry.attributes.position.array), 3),
                    );
                }

                // Copy normal attribute if exists
                if (oldGeometry.attributes.normal) {
                    newGeometry.setAttribute(
                        'normal',
                        new Float32BufferAttribute(Array.from(oldGeometry.attributes.normal.array), 3),
                    );
                }

                // Copy index if exists
                if (oldGeometry.index) {
                    newGeometry.setIndex(new BufferAttribute(new Uint32Array(Array.from(oldGeometry.index.array)), 1));
                }

                const mesh = prepStl(newGeometry);
                mesh.name = model.toothNumber.toString();
                mesh.geometry.computeBoundingBox();
                const initialRestorativeState = restorativesState.get(model.toothNumber);
                if (!initialRestorativeState) {
                    return;
                }
                mesh.name = model.toothNumber.toString();
                const colors = initialRestorativeState.shadeData.map(shade => shade.color);
                const gradientFunction = Gradient(colors, initialRestorativeState.gradientControlPoints);
                ApplyShade(mesh, initialRestorativeState?.insertionAxis, gradientFunction);
                unwrapUVs(mesh, initialRestorativeState?.insertionAxis);
                restorativeGroup.add(mesh);
            });
            sceneRef.add(restorativeGroup);
            setRestorativesLoaded(true);
        }

        if (models?.scanModels && sceneRef) {
            if (models.scanModels.upperJaw) {
                const newGeometry = new BufferGeometry();
                const oldGeometry = models.scanModels.upperJaw.geometry;

                // Copy position attribute
                if (oldGeometry.attributes.position) {
                    newGeometry.setAttribute(
                        'position',
                        new Float32BufferAttribute(Array.from(oldGeometry.attributes.position.array), 3),
                    );
                }

                // Copy normal attribute
                if (oldGeometry.attributes.normal) {
                    newGeometry.setAttribute(
                        'normal',
                        new Float32BufferAttribute(Array.from(oldGeometry.attributes.normal.array), 3),
                    );
                }

                // Copy UV attribute
                if (oldGeometry.attributes.uv) {
                    newGeometry.setAttribute(
                        'uv',
                        new Float32BufferAttribute(Array.from(oldGeometry.attributes.uv.array), 2),
                    );
                }
                // Copy index if exists
                if (oldGeometry.index) {
                    newGeometry.setIndex(new BufferAttribute(new Uint32Array(Array.from(oldGeometry.index.array)), 1));
                }

                // uppperMesh = models.scanModels.upperJaw.toMesh(createPrePrepMaterial)
                const upperMesh = prepStl(newGeometry);
                upperMesh.name = 'upperJaw';
                sceneRef.add(upperMesh);
            }
            if (models.scanModels.lowerJaw) {
                const newGeometry = new BufferGeometry();
                const oldGeometry = models.scanModels.lowerJaw.geometry;

                // Copy position attribute
                if (oldGeometry.attributes.position) {
                    newGeometry.setAttribute(
                        'position',
                        new Float32BufferAttribute(Array.from(oldGeometry.attributes.position.array), 3),
                    );
                }

                // Copy normal attribute
                if (oldGeometry.attributes.normal) {
                    newGeometry.setAttribute(
                        'normal',
                        new Float32BufferAttribute(Array.from(oldGeometry.attributes.normal.array), 3),
                    );
                }

                // Copy UV attribute
                if (oldGeometry.attributes.uv) {
                    newGeometry.setAttribute(
                        'uv',
                        new Float32BufferAttribute(Array.from(oldGeometry.attributes.uv.array), 2),
                    );
                }
                // Copy index if exists
                if (oldGeometry.index) {
                    newGeometry.setIndex(new BufferAttribute(new Uint32Array(Array.from(oldGeometry.index.array)), 1));
                }

                // lowerMesh = models.scanModels.lowerJaw.toMesh(createPrePrepMaterial)
                const lowerMesh = prepStl(newGeometry);
                lowerMesh.name = 'lowerJaw';
                sceneRef.add(lowerMesh);
            }
        }
    }, [restorativesState, restorativesLoaded, sceneRef, setRestorativesLoaded, models]);

    return null;
};
