import { Box, FlossPalette, Icon, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const VdrEarlyAccessButtonLayout = styled(Box, { shouldForwardProp: p => p !== 'isSignedUp' })(
    ({ isSignedUp }: { isSignedUp: boolean }) => ({
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        pointerEvents: isSignedUp ? 'none' : 'auto',
        opacity: isSignedUp ? '.4' : '1',
    }),
);

interface VdrEarlyAccessButtonProps {
    isSignedUpForVdr?: boolean;
    signUpForVdr?: () => void;
}

/**
 * Temporary component to gauge doctor interest in Video Design Review
 */
export const VdrEarlyAccessButton: React.VFC<VdrEarlyAccessButtonProps> = ({ isSignedUpForVdr, signUpForVdr }) => {
    return (
        <VdrEarlyAccessButtonLayout isSignedUp={!!isSignedUpForVdr} onClick={signUpForVdr}>
            <Text style={{ cursor: 'pointer' }} variant={'body2'} medium color={'PRIMARY_FOREGROUND'}>
                Sign up for early access!
            </Text>
            <Icon
                icon={isSignedUpForVdr ? 'CheckIcon' : 'PlusIcon'}
                style={{ color: FlossPalette.PRIMARY_FOREGROUND }}
            />
        </VdrEarlyAccessButtonLayout>
    );
};
