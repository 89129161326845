/* eslint-disable max-lines, @typescript-eslint/no-use-before-define */
import type { LabOrderItemConditionEditorValue } from './LabOrderItemConditionEditor';
import {
    editorValueToLabOrderItemCondition,
    LabOrderItemConditionEditor,
    labOrderItemConditionToEditorValue,
} from './LabOrderItemConditionEditor';
import { PracticeIdConditionEditor } from './PracticeIdConditionEditor';
import type { LabsGqlTagFragment } from '@orthly/graphql-operations';
import { useBasicDoctorPreferencesQuery, useTagsQuery, useListLabOrgs } from '@orthly/graphql-react';
import type { LabsGqlLabOrderCondition } from '@orthly/graphql-schema';
import { LabsGqlLabOrderConditionType, LabsGqlTaggableEntityType } from '@orthly/graphql-schema';
import type { CustomQFComponentProps } from '@orthly/ui';
import {
    LoadBlocker,
    SimpleSelect,
    SimpleTextField,
    SimpleToggle,
    SimpleMultiSelect,
    SimpleCheckbox,
    SimpleInput,
} from '@orthly/ui';
import { FlossPalette, Text, Grid, IconButton, AddIcon, DeleteIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export type LabOrderConditionEditorValue =
    | {
          type: LabsGqlLabOrderConditionType.Or | LabsGqlLabOrderConditionType.And;
          list: LabOrderConditionEditorValue[];
      }
    | { type: LabsGqlLabOrderConditionType.Not; condition: LabOrderConditionEditorValue }
    | { type: LabsGqlLabOrderConditionType.Doctor; id: string }
    | { type: LabsGqlLabOrderConditionType.PracticeTags; tags: string[] }
    | { type: LabsGqlLabOrderConditionType.InternalDesign; internal_design: boolean }
    | { type: LabsGqlLabOrderConditionType.Manufacturer; id: string }
    | {
          type: LabsGqlLabOrderConditionType.AllItems | LabsGqlLabOrderConditionType.AnyItem;
          condition: LabOrderItemConditionEditorValue;
      }
    | { type: LabsGqlLabOrderConditionType.MinUnitCount; min_unit_count: number }
    | { type: LabsGqlLabOrderConditionType.MaxUnitCount; max_unit_count: number }
    | { type: LabsGqlLabOrderConditionType.MinRefabCount; min_refab_count: number }
    | { type: LabsGqlLabOrderConditionType.PracticeId; practiceIds: string[] }
    | { type: '' };

const WorkOrderMatchingConditionEditorTypes = [
    LabsGqlLabOrderConditionType.Or,
    LabsGqlLabOrderConditionType.And,
    LabsGqlLabOrderConditionType.Not,
    LabsGqlLabOrderConditionType.AllItems,
    LabsGqlLabOrderConditionType.AnyItem,
    LabsGqlLabOrderConditionType.MinUnitCount,
    LabsGqlLabOrderConditionType.MaxUnitCount,
    LabsGqlLabOrderConditionType.MinRefabCount,
    LabsGqlLabOrderConditionType.PracticeTags,
    LabsGqlLabOrderConditionType.PracticeId,
];

export const labOrderConditionToEditorValue = (cond: LabsGqlLabOrderCondition): LabOrderConditionEditorValue => {
    if (cond.Or) {
        return {
            type: LabsGqlLabOrderConditionType.Or,
            list: cond.Or.map(labOrderConditionToEditorValue),
        };
    }
    if (cond.And) {
        return {
            type: LabsGqlLabOrderConditionType.And,
            list: cond.And.map(labOrderConditionToEditorValue),
        };
    }
    if (cond.Not) {
        return { type: LabsGqlLabOrderConditionType.Not, condition: labOrderConditionToEditorValue(cond.Not) };
    }
    if (cond.Manufacturer) {
        return { type: LabsGqlLabOrderConditionType.Manufacturer, id: cond.Manufacturer.id };
    }
    if (cond.Doctor) {
        return { type: LabsGqlLabOrderConditionType.Doctor, id: cond.Doctor.id };
    }
    if (cond.PracticeTags) {
        return { type: LabsGqlLabOrderConditionType.PracticeTags, tags: cond.PracticeTags };
    }
    if (cond.PracticeId) {
        return { type: LabsGqlLabOrderConditionType.PracticeId, practiceIds: cond.PracticeId };
    }
    if (typeof cond.InternalDesign === `boolean`) {
        return { type: LabsGqlLabOrderConditionType.InternalDesign, internal_design: cond.InternalDesign };
    }
    if (cond.AllItems) {
        return {
            type: LabsGqlLabOrderConditionType.AllItems,
            condition: labOrderItemConditionToEditorValue(cond.AllItems),
        };
    }
    if (cond.AnyItem) {
        return {
            type: LabsGqlLabOrderConditionType.AnyItem,
            condition: labOrderItemConditionToEditorValue(cond.AnyItem),
        };
    }
    if (typeof cond.MinUnitCount === `number`) {
        return {
            type: LabsGqlLabOrderConditionType.MinUnitCount,
            min_unit_count: cond.MinUnitCount,
        };
    }
    if (typeof cond.MaxUnitCount === `number`) {
        return {
            type: LabsGqlLabOrderConditionType.MaxUnitCount,
            max_unit_count: cond.MaxUnitCount,
        };
    }
    if (typeof cond.MinRefabCount === `number`) {
        return {
            type: LabsGqlLabOrderConditionType.MinRefabCount,
            min_refab_count: cond.MinRefabCount,
        };
    }
    return { type: '' };
};

export const editorValueToLabOrderCondition = (val: LabOrderConditionEditorValue): LabsGqlLabOrderCondition => {
    switch (val.type) {
        case LabsGqlLabOrderConditionType.Or:
        case LabsGqlLabOrderConditionType.And:
            return { [val.type]: val.list.map(editorValueToLabOrderCondition) };
        case LabsGqlLabOrderConditionType.Not:
            return { [val.type]: editorValueToLabOrderCondition(val.condition) };
        case LabsGqlLabOrderConditionType.Manufacturer:
        case LabsGqlLabOrderConditionType.Doctor:
            return { [val.type]: { id: val.id } };
        case LabsGqlLabOrderConditionType.PracticeTags:
            return { [val.type]: val.tags };
        case LabsGqlLabOrderConditionType.PracticeId:
            return { [val.type]: val.practiceIds };
        case LabsGqlLabOrderConditionType.InternalDesign:
            return { [val.type]: val.internal_design };
        case LabsGqlLabOrderConditionType.AllItems:
        case LabsGqlLabOrderConditionType.AnyItem:
            return { [val.type]: editorValueToLabOrderItemCondition(val.condition) };
        case LabsGqlLabOrderConditionType.MinUnitCount:
            return { [val.type]: val.min_unit_count };
        case LabsGqlLabOrderConditionType.MaxUnitCount:
            return { [val.type]: val.max_unit_count };
        case LabsGqlLabOrderConditionType.MinRefabCount:
            return { [val.type]: val.min_refab_count };
        default:
            return {};
    }
};

const emptyEditorValueForConditionType = (
    type: LabsGqlLabOrderConditionType | string | undefined,
): LabOrderConditionEditorValue => {
    switch (type) {
        case LabsGqlLabOrderConditionType.Or:
        case LabsGqlLabOrderConditionType.And:
            return { type, list: [] };
        case LabsGqlLabOrderConditionType.Not:
            return { type, condition: { type: '' } };
        case LabsGqlLabOrderConditionType.Manufacturer:
        case LabsGqlLabOrderConditionType.Doctor:
            return { type, id: `` };
        case LabsGqlLabOrderConditionType.PracticeTags:
            return { type, tags: [] };
        case LabsGqlLabOrderConditionType.InternalDesign:
            return { type, internal_design: true };
        case LabsGqlLabOrderConditionType.AllItems:
        case LabsGqlLabOrderConditionType.AnyItem:
            return { type, condition: { type: `` } };
        case LabsGqlLabOrderConditionType.MinUnitCount:
            return { type, min_unit_count: 0 };
        case LabsGqlLabOrderConditionType.MaxUnitCount:
            return { type, max_unit_count: 0 };
        case LabsGqlLabOrderConditionType.MinRefabCount:
            return { type, min_refab_count: 1 };
        case LabsGqlLabOrderConditionType.PracticeId:
            return { type, practiceIds: [] };
        default:
            return { type: '' };
    }
};

export type LabOrderConditionEditorProps<V extends LabOrderConditionEditorValue = LabOrderConditionEditorValue> = {
    omitManufacturerOption?: boolean;
    omitMinRefabCountOption?: boolean;
    onChange: (value: V) => void;
    value: V;
};

type LabOrderConditionEditorCombinatorProps = LabOrderConditionEditorProps<
    Extract<
        LabOrderConditionEditorValue,
        {
            type: LabsGqlLabOrderConditionType.Or | LabsGqlLabOrderConditionType.And;
        }
    >
>;

const LabOrderConditionEditorCombinator = ({
    value,
    onChange,
    omitManufacturerOption,
    omitMinRefabCountOption,
}: LabOrderConditionEditorCombinatorProps) => {
    const setAtIndex = (index: number, val: LabOrderConditionEditorValue) =>
        onChange({
            type: value.type,
            list: [...value.list.slice(0, index), val, ...value.list.slice(index + 1)],
        });
    const deleteAtIndex = (index: number) =>
        onChange({
            type: value.type,
            list: [...value.list.slice(0, index), ...value.list.slice(index + 1)],
        });
    const addItem = () => {
        onChange({
            type: value.type,
            list: [...value.list, { type: '' }],
        });
    };
    return (
        <Grid container style={{ paddingLeft: '1em', paddingTop: '0.5em' }} spacing={1}>
            {value.list.map((alt, idx) => (
                <Grid key={`${idx}-${alt.type}`} item xs={12} container alignItems={'center'}>
                    <Grid item xs={1}>
                        <IconButton onClick={() => deleteAtIndex(idx)}>
                            <DeleteIcon style={{ color: FlossPalette.ATTENTION }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <LabOrderConditionEditor
                            omitManufacturerOption={omitManufacturerOption}
                            omitMinRefabCountOption={omitMinRefabCountOption}
                            value={alt}
                            onChange={val => setAtIndex(idx, val)}
                        />
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12} container alignItems={'center'}>
                <Grid item xs={1}>
                    <IconButton onClick={() => addItem()}>
                        <AddIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={11}>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        Add a new condition
                    </Text>
                </Grid>
            </Grid>
        </Grid>
    );
};

const LabOrderConditionEditorNegated: React.VFC<
    LabOrderConditionEditorProps<Extract<LabOrderConditionEditorValue, { type: LabsGqlLabOrderConditionType.Not }>>
> = ({ value, onChange, omitManufacturerOption, omitMinRefabCountOption }) => {
    return (
        <Grid container style={{ paddingLeft: '1em' }}>
            <Grid item xs={12}>
                <LabOrderConditionEditor
                    omitManufacturerOption={omitManufacturerOption}
                    omitMinRefabCountOption={omitMinRefabCountOption}
                    value={value.condition}
                    onChange={condition => onChange({ condition, type: value.type })}
                />
            </Grid>
        </Grid>
    );
};

const LabOrderConditionEditorManufacturer: React.VFC<
    LabOrderConditionEditorProps<
        Extract<LabOrderConditionEditorValue, { type: LabsGqlLabOrderConditionType.Manufacturer }>
    >
> = ({ value, onChange }) => {
    const { data, loading } = useListLabOrgs({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <LoadBlocker blocking={loading}>
                    <SimpleSelect
                        label={'Manufacturer'}
                        SelectProps={{ fullWidth: true, variant: 'standard' }}
                        options={_.sortBy(data?.listOrganizations ?? [], m => m.name.trim().toLowerCase()).map(
                            ({ id, name }) => ({
                                value: id,
                                label: name,
                            }),
                        )}
                        value={value.id}
                        onChange={id => id && onChange({ ...value, id })}
                    />
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

const LabOrderConditionEditorDoctor: React.VFC<
    LabOrderConditionEditorProps<Extract<LabOrderConditionEditorValue, { type: LabsGqlLabOrderConditionType.Doctor }>>
> = ({ value, onChange }) => {
    const { data, loading } = useBasicDoctorPreferencesQuery({ fetchPolicy: 'no-cache' });

    const doctorOptions = React.useMemo(() => {
        if (!data?.preferences) {
            return [];
        }

        const options = data.preferences.map(({ id, name, practice_name }) => ({
            value: id,
            label: practice_name ? `${practice_name}: ${name}` : name,
        }));

        return _.sortBy(options, option => option.label.trim().toLowerCase());
    }, [data]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <LoadBlocker blocking={loading}>
                    <SimpleSelect
                        label={'Doctor'}
                        SelectProps={{ fullWidth: true, variant: 'standard' }}
                        options={doctorOptions}
                        value={value.id}
                        onChange={id => id && onChange({ ...value, id })}
                    />
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

const LabOrderConditionEditorItem: React.VFC<
    LabOrderConditionEditorProps<
        Extract<
            LabOrderConditionEditorValue,
            { type: LabsGqlLabOrderConditionType.AllItems | LabsGqlLabOrderConditionType.AnyItem }
        >
    >
> = ({ value: { type, condition }, onChange }) => (
    <LabOrderItemConditionEditor
        value={condition}
        onChange={React.useCallback(value => onChange({ type, condition: value }), [type, onChange])}
    />
);

const LabOrderConditionEditorPracticeTags: React.VFC<
    LabOrderConditionEditorProps<
        Extract<LabOrderConditionEditorValue, { type: LabsGqlLabOrderConditionType.PracticeTags }>
    >
> = ({ value, onChange }) => {
    const { data: { tags } = { tags: [] as LabsGqlTagFragment[] }, loading } = useTagsQuery({
        variables: { entity_type: LabsGqlTaggableEntityType.Practice, id: null, entity_id: null },
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <LoadBlocker blocking={loading}>
                    <SimpleMultiSelect
                        label={'Tags'}
                        SelectProps={{ fullWidth: true, variant: 'standard' }}
                        options={_.sortBy(tags, t => t.name.trim().toLowerCase()).map(({ id, name }) => ({
                            value: id,
                            label: name,
                        }))}
                        value={value.tags}
                        onChange={values =>
                            onChange({ type: LabsGqlLabOrderConditionType.PracticeTags, tags: values ?? [] })
                        }
                    />
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

const LabOrderConditionEditorInternalDesign: React.VFC<
    LabOrderConditionEditorProps<
        Extract<LabOrderConditionEditorValue, { type: LabsGqlLabOrderConditionType.InternalDesign }>
    >
> = ({ value, onChange }) => (
    <Grid container>
        <Grid item xs={12}>
            <SimpleCheckbox
                label={`Internal design`}
                checked={value.internal_design}
                setChecked={value =>
                    onChange({
                        type: LabsGqlLabOrderConditionType.InternalDesign,
                        internal_design: value,
                    })
                }
            />
        </Grid>
    </Grid>
);

const LabOrderConditionEditorMinUnitCount: React.VFC<
    LabOrderConditionEditorProps<
        Extract<LabOrderConditionEditorValue, { type: LabsGqlLabOrderConditionType.MinUnitCount }>
    >
> = ({ value, onChange }) => (
    <Grid container>
        <Grid item xs={12}>
            <SimpleInput
                label={`Min Unit Count`}
                TextFieldProps={{ fullWidth: true, type: 'number' }}
                value={`${value.min_unit_count}`}
                onChange={value =>
                    value &&
                    onChange({
                        type: LabsGqlLabOrderConditionType.MinUnitCount,
                        min_unit_count: parseInt(value),
                    })
                }
            />
        </Grid>
    </Grid>
);

const LabOrderConditionEditorMaxUnitCount: React.VFC<
    LabOrderConditionEditorProps<
        Extract<LabOrderConditionEditorValue, { type: LabsGqlLabOrderConditionType.MaxUnitCount }>
    >
> = ({ value, onChange }) => (
    <Grid container>
        <Grid item xs={12}>
            <SimpleInput
                label={`Max Unit Count`}
                TextFieldProps={{ fullWidth: true, type: 'number' }}
                value={`${value.max_unit_count}`}
                onChange={value =>
                    value &&
                    onChange({
                        type: LabsGqlLabOrderConditionType.MaxUnitCount,
                        max_unit_count: parseInt(value),
                    })
                }
            />
        </Grid>
    </Grid>
);

const LabOrderConditionEditorMinRefabCount: React.VFC<
    LabOrderConditionEditorProps<
        Extract<LabOrderConditionEditorValue, { type: LabsGqlLabOrderConditionType.MinRefabCount }>
    >
> = ({ value, onChange }) => (
    <Grid container>
        <Grid item xs={12}>
            <SimpleInput
                label={`Min Refab Count`}
                TextFieldProps={{ fullWidth: true, type: 'number' }}
                value={`${value.min_refab_count}`}
                onChange={value =>
                    value &&
                    onChange({
                        type: LabsGqlLabOrderConditionType.MinRefabCount,
                        min_refab_count: parseInt(value),
                    })
                }
            />
        </Grid>
    </Grid>
);

const LabOrderConditionEditorFields = ({
    value,
    onChange,
    omitManufacturerOption,
    omitMinRefabCountOption,
}: LabOrderConditionEditorProps) => {
    switch (value.type) {
        case LabsGqlLabOrderConditionType.Or:
        case LabsGqlLabOrderConditionType.And:
            return (
                <LabOrderConditionEditorCombinator
                    {...{ value, onChange, omitManufacturerOption, omitMinRefabCountOption }}
                />
            );
        case LabsGqlLabOrderConditionType.Not:
            return (
                <LabOrderConditionEditorNegated
                    {...{ value, onChange, omitManufacturerOption, omitMinRefabCountOption }}
                />
            );
        case LabsGqlLabOrderConditionType.AllItems:
        case LabsGqlLabOrderConditionType.AnyItem:
            return <LabOrderConditionEditorItem {...{ value, onChange }} />;
        case LabsGqlLabOrderConditionType.Manufacturer:
            return <LabOrderConditionEditorManufacturer {...{ value, onChange }} />;
        case LabsGqlLabOrderConditionType.Doctor:
            return <LabOrderConditionEditorDoctor {...{ value, onChange }} />;
        case LabsGqlLabOrderConditionType.PracticeTags:
            return <LabOrderConditionEditorPracticeTags {...{ value, onChange }} />;
        case LabsGqlLabOrderConditionType.InternalDesign:
            return <LabOrderConditionEditorInternalDesign {...{ value, onChange }} />;
        case LabsGqlLabOrderConditionType.MinUnitCount:
            return <LabOrderConditionEditorMinUnitCount {...{ value, onChange }} />;
        case LabsGqlLabOrderConditionType.MaxUnitCount:
            return <LabOrderConditionEditorMaxUnitCount {...{ value, onChange }} />;
        case LabsGqlLabOrderConditionType.MinRefabCount:
            return <LabOrderConditionEditorMinRefabCount {...{ value, onChange }} />;
        case LabsGqlLabOrderConditionType.PracticeId:
            return <PracticeIdConditionEditor {...{ value, onChange }} />;
        default:
            return null;
    }
};

export const LabOrderConditionEditor = (props: LabOrderConditionEditorProps) => {
    const setType = (typ: LabsGqlLabOrderConditionType | string | undefined) =>
        props.onChange(emptyEditorValueForConditionType(typ));
    return (
        <Grid container>
            <Grid item xs={12}>
                <SimpleSelect
                    SelectProps={{ fullWidth: true, variant: 'standard' }}
                    label={'Order Condition'}
                    onChange={setType}
                    value={props.value.type}
                    options={[
                        { value: '', label: 'Choose...' },
                        ...Object.values(LabsGqlLabOrderConditionType)
                            .filter(
                                value =>
                                    !props.omitManufacturerOption ||
                                    value !== LabsGqlLabOrderConditionType.Manufacturer,
                            )
                            .filter(
                                value =>
                                    !props.omitMinRefabCountOption ||
                                    value !== LabsGqlLabOrderConditionType.MinRefabCount,
                            )
                            .map(value => ({ value })),
                    ]}
                />
            </Grid>
            <Grid item xs={12}>
                <LabOrderConditionEditorFields {...props} />
            </Grid>
        </Grid>
    );
};

const labOrderConditionJSONToEditorValue = (conditionJSON: string): LabOrderConditionEditorValue => {
    try {
        const parsed = JSON.parse(conditionJSON);
        return labOrderConditionToEditorValue(parsed);
        // EPDPLT-4736: Using any is unsafe and should be avoided.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
        console.error(e);
        return { type: '' };
    }
};

export const LabOrderConditionField = (
    props: CustomQFComponentProps<{}> & { omitManufacturerOption?: boolean; omitMinRefabCountOptions?: boolean },
) => {
    const { field, form } = props;
    const rawFieldValue: string = field.value || '';
    const fieldValue: LabOrderConditionEditorValue =
        !field.value || field.value === 'null' ? { type: '' } : labOrderConditionJSONToEditorValue(field.value);
    const [useTextEditor, setUseTextEditor] = React.useState(false);
    return (
        <Grid container>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
                <SimpleToggle label={'Edit as JSON'} checked={useTextEditor} onChange={setUseTextEditor} />
            </Grid>
            {useTextEditor ? (
                <Grid item xs={12}>
                    <SimpleTextField
                        label={'Condition JSON'}
                        TextFieldProps={{ multiline: true, rows: 10 }}
                        onChange={val => form.setFieldValue(field.name, val ?? '', true)}
                        value={rawFieldValue}
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <LabOrderConditionEditor
                        omitManufacturerOption={props.omitManufacturerOption}
                        omitMinRefabCountOption={props.omitMinRefabCountOptions}
                        value={fieldValue}
                        onChange={val => {
                            form.setFieldTouched(field.name, true);
                            form.setFieldValue(field.name, JSON.stringify(editorValueToLabOrderCondition(val)), true);
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export const WorkOrderConditionEditor = (props: LabOrderConditionEditorProps) => {
    const setType = (typ: LabsGqlLabOrderConditionType | string | undefined) =>
        props.onChange(emptyEditorValueForConditionType(typ));
    return (
        <Grid container>
            <Grid item xs={12}>
                <SimpleSelect
                    SelectProps={{ fullWidth: true, variant: 'standard' }}
                    label={'Work Order Condition'}
                    onChange={setType}
                    value={props.value.type}
                    options={[
                        { value: '', label: 'Choose...' },
                        ...Object.values(LabsGqlLabOrderConditionType)
                            .filter(value => Object.values(WorkOrderMatchingConditionEditorTypes).includes(value))
                            .map(value => ({ value })),
                    ]}
                />
            </Grid>
            <Grid item xs={12}>
                <LabOrderConditionEditorFields {...props} />
            </Grid>
        </Grid>
    );
};

export const WorkOrderConditionField = (props: CustomQFComponentProps<{}>) => {
    const { field, form } = props;
    const rawFieldValue: string = field.value || '';
    const fieldValue: LabOrderConditionEditorValue =
        !field.value || field.value === 'null' ? { type: '' } : labOrderConditionJSONToEditorValue(field.value);
    const [useTextEditor, setUseTextEditor] = React.useState(false);
    return (
        <Grid container>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
                <SimpleToggle label={'Edit as JSON'} checked={useTextEditor} onChange={setUseTextEditor} />
            </Grid>
            {useTextEditor ? (
                <Grid item xs={12}>
                    <SimpleTextField
                        label={'Work Order Condition JSON'}
                        TextFieldProps={{ multiline: true, rows: 10 }}
                        onChange={val => form.setFieldValue(field.name, val ?? null, true)}
                        value={rawFieldValue}
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <WorkOrderConditionEditor
                        value={fieldValue}
                        onChange={val => {
                            form.setFieldTouched(field.name, true);
                            form.setFieldValue(
                                field.name,
                                val.type === '' ? null : JSON.stringify(editorValueToLabOrderCondition(val)),
                                true,
                            );
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};
