import { Assert } from '@orthly/runtime-utils';
import { SimpleSelect } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const OPEN_STATUSES = ['Open', 'Closed', '(ALL)'] as const;
type OpenStatus = (typeof OPEN_STATUSES)[number];
// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isOpenStatus = (x: any): x is OpenStatus => OPEN_STATUSES.includes(x);

export const SelectOpenStatus: React.FC<{
    isOpen?: boolean;
    setIsOpen: (status: boolean | undefined) => void;
}> = props => {
    let value: OpenStatus;

    switch (props.isOpen) {
        case true:
            value = 'Open';
            break;
        case false:
            value = 'Closed';
            break;
        case undefined:
            value = '(ALL)';
            break;
        default:
            Assert.unreachable(props.isOpen);
    }

    return (
        <div style={{ borderRight: `1px solid ${FlossPalette.DARK_TAN}`, minWidth: '120px' }}>
            <SimpleSelect
                label={'Status'}
                value={value}
                backgroundColor={'white'}
                options={OPEN_STATUSES.map(value => ({ value }))}
                onChange={newValue => {
                    if (isOpenStatus(newValue)) {
                        switch (newValue) {
                            case 'Open':
                                props.setIsOpen(true);
                                break;
                            case 'Closed':
                                props.setIsOpen(false);
                                break;
                            case '(ALL)':
                                props.setIsOpen(undefined);
                                break;
                            default:
                                Assert.unreachable(newValue);
                        }
                    }
                }}
            />
        </div>
    );
};
