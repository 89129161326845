import { styled } from '@orthly/ui-primitives';
import React from 'react';

const HR = styled('hr')({
    width: '90%',
    opacity: 0.5,
});

const HorizontalPanelDivider: React.FC = () => {
    return <HR />;
};

export default HorizontalPanelDivider;
