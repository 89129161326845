import { useOrdersOverviewAction } from '../../screens/Orders/state/OrdersOverview.actions';
import { OrderListItemCellText } from './OrderListItemCellText';
import { stylesFactory, FlossPaletteUtils, Text, Grid } from '@orthly/ui-primitives';
import { PatientOrderCount } from '@orthly/veneer';
import cx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    titleText: {
        fontWeight: 500,
        width: 'auto',
        cursor: 'pointer',
        padding: '0 8px 0 0',
    },
    titleTextClickable: {
        padding: '4px 8px',
        marginLeft: -8,
        '&:hover': { background: FlossPaletteUtils.toRgba('STAR_GRASS', 0.15) },
    },
}));

interface OrderListItemTitleProps {
    patient_name: string;
    patient_order_count: number;
    subtitle?: string;
    disableSearchOnClick?: boolean;
    flagContent?: React.ReactNode;
}

export const OrderListItemTitle: React.FC<OrderListItemTitleProps> = props => {
    const classes = useStyles();
    const { patient_name, patient_order_count, subtitle, disableSearchOnClick, flagContent } = props;
    const setSearch = useOrdersOverviewAction('SEARCH_FOR_PATIENT');
    return (
        <>
            <Grid container wrap={'nowrap'} alignItems={'center'} style={{ position: 'relative' }}>
                <Text
                    onClick={e => {
                        if (!disableSearchOnClick) {
                            e.stopPropagation();
                            setSearch(patient_name);
                        }
                    }}
                    variant={'body2'}
                    color={'BLACK'}
                    className={cx(classes.titleText, !disableSearchOnClick && classes.titleTextClickable)}
                >
                    {patient_name}
                </Text>
                {patient_order_count > 1 && <PatientOrderCount patient_order_count={patient_order_count} />}
            </Grid>
            {!!subtitle && <OrderListItemCellText variant={'subtitle'} text={subtitle} />}
            {flagContent && (
                <Grid container style={{ paddingTop: 4 }} alignContent={'flex-start'}>
                    {flagContent}
                </Grid>
            )}
        </>
    );
};
