import { ActionCard } from '@orthly/ui';
import { Collapse, Button } from '@orthly/ui-primitives';
import React from 'react';

interface ArchiveAndRestoreProps {
    objectName: string;
    isArchived: boolean;
    disabled: boolean;
    hidePrompt: boolean;
    promptText: string;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRestore: () => any;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDelete: () => any;
}

export const ArchiveAndRestore: React.FC<ArchiveAndRestoreProps> = ({
    objectName,
    isArchived,
    disabled,
    hidePrompt,
    promptText,
    onRestore,
    onDelete,
}) => {
    const [isDeletePromptOpen, setDeletePromptOpen] = React.useState(false);

    if (isArchived) {
        return (
            <Button
                variant={'primary'}
                fullWidth={true}
                disabled={disabled}
                onClick={onRestore}
                startIcon={'ReturnIcon'}
            >
                Restore {objectName}
            </Button>
        );
    }
    return (
        <>
            <Button
                variant={isDeletePromptOpen ? 'alert' : 'primary'}
                fullWidth={true}
                disabled={disabled}
                onClick={() => {
                    if (isDeletePromptOpen || hidePrompt) {
                        onDelete();
                    } else {
                        setDeletePromptOpen(true);
                    }
                }}
                startIcon={'ArchiveIcon'}
            >
                Archive {objectName}
            </Button>

            <Collapse in={isDeletePromptOpen}>
                <div style={{ marginTop: 18 }}>
                    <ActionCard
                        variant={'alert'}
                        title={`Are you sure you want to archive this ${objectName}?`}
                        subtitle={promptText}
                        style={{ flexWrap: 'wrap' }}
                        otherActions={
                            <div style={{ paddingTop: 18, textAlign: 'center', width: '100%' }}>
                                <Button
                                    variant={'secondary'}
                                    disabled={disabled}
                                    onClick={() => setDeletePromptOpen(false)}
                                >
                                    Cancel
                                </Button>
                            </div>
                        }
                    />
                </div>
            </Collapse>
        </>
    );
};
