/* eslint-disable no-nested-ternary */
import { NavigationTitleBoundary } from '../../components/NavigationTitleBoundary';
import { OrderDetailSidebar } from '../../components/OrderDetailSidebar/OrderDetailSidebar';
import { useOpsSidebarVisible } from '../../redux/ui/ui.selectors';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { useCurrentOrderDetailId } from '../../utils/router/useOpenOrderDetail';
import { useLoadSaveSearchFromUrl, useSavedSearches } from '../../utils/useSavedSearches';
import { OrdersListViewOrderIdsProvider } from './components/OrdersListView/useOrdersListViewOrderIds';
import { OrdersSubNavigation } from './components/OrdersSubnav/OrdersSubNavigation';
import { OrderDetailRoot } from './screens/OrderDetail/OrderDetailRoot.graphql';
import { OrdersListView } from './screens/OrdersListView/OrdersListView';
import { UnsubmittedScansRoot } from './screens/UnsubmittedScans/UnsubmittedScansRoot';
import { useOrdersOverviewState } from './state/OrdersOverview.selectors';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';
import { Switch } from 'react-router-dom';
import { CompatRoute, Navigate, useParams } from 'react-router-dom-v5-compat';

const OrdersRouter: React.FC = () => {
    const screen = useOrdersOverviewState(s => s.screen);
    if (screen === 'scans') {
        return <UnsubmittedScansRoot />;
    }
    return <OrdersListView />;
};

const OrderDetailRoute: React.VFC = () => {
    const params = useParams() as { orderId: string };
    return <OrderDetailRoot idOrOrderNumber={params.orderId} />;
};

const basePath = RouterUtils.pathForScreen('orders');

export const OrdersRoot: React.FC = () => {
    const onDetailPage = !!useCurrentOrderDetailId();
    const sidebarOpen = useOpsSidebarVisible();
    useSavedSearches('orders');
    useLoadSaveSearchFromUrl();

    return (
        <NavigationTitleBoundary title={'Orders'}>
            <OrdersListViewOrderIdsProvider>
                {!onDetailPage ? <OrdersSubNavigation /> : sidebarOpen ? <OrderDetailSidebar /> : null}
                <Switch>
                    <CompatRoute exact path={`${basePath}/:orderId`} component={OrderDetailRoute} />

                    <CompatRoute path={basePath}>
                        <Grid container style={{ overflow: 'auto', maxHeight: '100vh' }} alignItems={'flex-start'}>
                            <OrdersRouter />
                        </Grid>
                    </CompatRoute>
                    <Navigate to={basePath} />
                </Switch>
            </OrdersListViewOrderIdsProvider>
        </NavigationTitleBoundary>
    );
};
