import { AddRefabReasonRule } from './components/AddRefabReasonRule';
import { CategoryAutocomplete } from './components/CategoryAutocomplete';
import { DisplayRulesTable } from './components/DisplayRulesTable';
import type {
    LabsGqlCreateRefabReasonMutationVariables,
    LabsGqlRefabReasonFragment,
    LabsGqlUpdateRefabReasonMutationVariables,
} from '@orthly/graphql-operations';
import {
    useCreateRefabReasonMutation,
    useDeleteRefabReasonRuleMutation,
    useRefabReasonCategoriesQuery,
    useRefabReasonsQuery,
    useUpdateRefabReasonMutation,
} from '@orthly/graphql-react';
import { LabsGqlDisplayRuleTypeEnum, LabsGqlRefabFault } from '@orthly/graphql-schema';
import { MUITable } from '@orthly/mui-table';
import { LoadBlocker, QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Button, Grid, Tab, Tabs } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

function useCategorySuggestions() {
    const { data, refetch } = useRefabReasonCategoriesQuery();
    return {
        refetch,
        suggestions: _.sortBy(data?.getRefabCategoriesAndCounts.map(c => ({ value: c.category })) ?? [], e =>
            e.value.toLowerCase(),
        ),
    };
}

type UpdateVars = LabsGqlUpdateRefabReasonMutationVariables['data'];

interface EditRefabReasonProps {
    reason: LabsGqlRefabReasonFragment;
    onSubmit?: () => void;
}

const EditRefabReason: React.FC<EditRefabReasonProps> = props => {
    const { reason } = props;
    const { suggestions, refetch: refetchCategories } = useCategorySuggestions();
    const [submitMtn] = useUpdateRefabReasonMutation();
    const mtnSubmitter = (data: UpdateVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [UpdateVars]>(mtnSubmitter, {
        closeOnComplete: true,
        onSuccess: refetchCategories,
        successMessage: () => ['Refab Reason updated!', {}],
    });
    return (
        <QuickForm<Partial<Omit<UpdateVars, 'id'>>>
            disabled={submitting}
            fields={{
                name: { type: 'text' },
                category: {
                    type: 'custom',
                    optional: true,
                    validation: z.string().min(3),
                    component: formProps => <CategoryAutocomplete suggestions={suggestions} {...formProps} />,
                },
                default_fault: {
                    type: 'select',
                    options: Object.keys(LabsGqlRefabFault),
                    optional: true,
                },
                internal_only: { type: 'boolean' },
            }}
            initialValues={{
                name: reason.name,
                category: reason.category,
                default_fault: reason.default_fault,
                internal_only: reason.internal_only,
            }}
            dirtySubmitOnly={true}
            onSubmit={async result => {
                // Prevents spurious empty string submission for the fault enum
                const default_fault = result.default_fault || null;
                await submit({ ...result, default_fault, reason_id: reason.id });
            }}
        />
    );
};

interface RefabReasonDetailPanelProps {
    reason: LabsGqlRefabReasonFragment;
    existingReasons: LabsGqlRefabReasonFragment[];
    refetch: () => Promise<unknown>;
}

const RefabReasonDetailPanel: React.FC<RefabReasonDetailPanelProps> = props => {
    const [tab, setTab] = React.useState<'edit' | 'displayRules' | 'returnRules'>('edit');
    const [submitMtn, { loading }] = useUpdateRefabReasonMutation();
    const [deleteRule, { loading: deleteLoading }] = useDeleteRefabReasonRuleMutation();
    const summaryForRules = (
        ruleType: LabsGqlDisplayRuleTypeEnum.DisplayRules | LabsGqlDisplayRuleTypeEnum.ReturnRules,
    ) => (
        <DisplayRulesTable
            elementName={props.reason.name}
            rules={props.reason[ruleType]}
            ruleType={ruleType}
            deleteLoading={deleteLoading}
            deleteRule={rule_id => {
                void deleteRule({ variables: { data: { rule_id, reason_id: props.reason.id, rule_type: ruleType } } });
            }}
            rulesPassWhenEmpty={ruleType === LabsGqlDisplayRuleTypeEnum.DisplayRules}
        />
    );
    return (
        <Grid container style={{ padding: 0, maxWidth: 'calc(100vw - 73px)' }}>
            <Grid container item xs={10}>
                <Tabs
                    indicatorColor={'secondary'}
                    textColor={'inherit'}
                    value={tab}
                    onChange={(_, value) => setTab(value)}
                >
                    <Tab value={'edit'} label={'Edit'} />
                    <Tab value={'displayRules'} label={'Display Rules'} />
                    <Tab value={'returnRules'} label={'Return Rules'} />
                </Tabs>
                <Grid container style={{ padding: 10 }}>
                    {tab === 'edit' && <EditRefabReason reason={props.reason} onSubmit={props.refetch} />}
                    {tab === 'displayRules' && summaryForRules(LabsGqlDisplayRuleTypeEnum.DisplayRules)}
                    {tab === 'returnRules' && summaryForRules(LabsGqlDisplayRuleTypeEnum.ReturnRules)}
                </Grid>
            </Grid>
            <Grid container item xs={2} direction={'column'} style={{ padding: 10 }} wrap={'nowrap'}>
                <LoadBlocker blocking={loading} ContainerProps={{ style: { height: 'fit-content' } }}>
                    <Button
                        fullWidth
                        variant={'contained'}
                        onClick={() => {
                            submitMtn({
                                variables: {
                                    data: {
                                        reason_id: props.reason.id,
                                        archived: !props.reason.archived,
                                    },
                                },
                            }).catch(console.error);
                        }}
                    >
                        {props.reason.archived ? 'Unarchive Reason' : 'Archive Reason'}
                    </Button>
                </LoadBlocker>
                <Grid container>
                    <AddRefabReasonRule
                        reason={props.reason}
                        rule_type={LabsGqlDisplayRuleTypeEnum.DisplayRules}
                        existingReasons={props.existingReasons}
                    />
                </Grid>
                <Grid container>
                    <AddRefabReasonRule
                        reason={props.reason}
                        rule_type={LabsGqlDisplayRuleTypeEnum.ReturnRules}
                        existingReasons={props.existingReasons}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

interface CreateRefabReasonProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onSuccess: () => Promise<unknown>;
}

type Vars = LabsGqlCreateRefabReasonMutationVariables['data'];

const CreateRefabReason: React.FC<CreateRefabReasonProps> = props => {
    const { suggestions, refetch: refetchCategories } = useCategorySuggestions();
    const [submitMtn] = useCreateRefabReasonMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Refab Reason created!', {}],
        onSuccess: async () => {
            void refetchCategories();
            await props.onSuccess();
        },
    });

    return (
        <RootActionDialog
            title={'Create Refabrication Reason'}
            open={props.open}
            setOpen={props.setOpen}
            loading={submitting}
            buttonText={'Create Refab Reason'}
            CustomButton={() => null}
            content={
                <QuickForm<Omit<Vars, 'display_rules' | 'return_rules'>>
                    fields={{
                        name: { type: 'text' },
                        category: {
                            type: 'custom',
                            optional: false,
                            validation: z.string().min(3),
                            component: formProps => <CategoryAutocomplete suggestions={suggestions} {...formProps} />,
                        },
                        default_fault: {
                            type: 'select',
                            options: Object.keys(LabsGqlRefabFault),
                            optional: true,
                        },
                        internal_only: {
                            type: 'boolean',
                        },
                    }}
                    initialValues={{}}
                    onSubmit={async formResult => {
                        // Prevents spurious empty string submission for the fault enum
                        const default_fault = formResult.default_fault || undefined;
                        const command: Vars = {
                            ...formResult,
                            default_fault,
                            display_rules: [],
                            return_rules: [],
                        };
                        await submit(command);
                    }}
                />
            }
        />
    );
};

export const RefabReasonsConfigForm: React.FC = () => {
    const { data, refetch } = useRefabReasonsQuery();
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    const allReasons = data?.listRefabReasons || [];
    return (
        <Grid container>
            <MUITable<LabsGqlRefabReasonFragment>
                title={'Refab Reasons'}
                data={allReasons}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                DetailPanel={detailProps => (
                    <RefabReasonDetailPanel reason={detailProps.data} existingReasons={allReasons} refetch={refetch} />
                )}
                actions={{
                    global: [
                        { icon: 'refresh', position: 'toolbar', onClick: () => refetch().catch(console.error) },
                        { icon: 'add', position: 'toolbar', onClick: () => setCreateOpen(true), tooltip: 'Create' },
                    ],
                }}
                rowOptions={{ rowHover: true }}
                eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                columns={[
                    { name: 'name', render: 'name' },
                    {
                        name: 'Category',
                        render: row => row.category ?? <i>(None)</i>,
                    },
                    {
                        name: 'Default Fault',
                        render: row => row.default_fault ?? <i>(None)</i>,
                    },
                    { name: 'Internal Only?', render: 'internal_only', type: 'boolean' },
                    {
                        name: 'Has Display Rules',
                        render: row => row.display_rules.length > 0,
                        type: 'boolean',
                    },
                    {
                        name: 'Has Return Rules',
                        render: row => row.return_rules.length > 0,
                        type: 'boolean',
                    },
                    {
                        name: 'Archived',
                        render: 'archived',
                        type: 'boolean',
                        filterOptions: { defaultValues: ['false'], exact: false, type: 'dropdown' },
                    },
                    { name: 'Last Updated', render: 'updated_at', type: 'datetime' },
                ]}
            />
            <CreateRefabReason
                open={createOpen}
                setOpen={setCreateOpen}
                onSuccess={async () => {
                    await refetch();
                    setCreateOpen(false);
                }}
            />
        </Grid>
    );
};
