import UV from '../../public/uv.png';
import { TextureKeys } from '../store/store';
import type MeshPainterMaterial from './PainterMaterial';
import { DEFAULT_CLEARCOAT } from './PrepStl';
import { TEXTURE_SIZE } from './TextureLayer';
import type { Mesh, MeshPhysicalMaterial } from 'three';
import { CanvasTexture } from 'three';

export function initDiffuseCanvas(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = 'rgba(255, 255, 255, 1)'; // White with full alpha
    ctx.fillRect(0, 0, TEXTURE_SIZE, TEXTURE_SIZE);
}

function initClearcoatCanvas(ctx: CanvasRenderingContext2D, material: MeshPhysicalMaterial) {
    ctx.fillStyle = 'rgba(0, 0, 0, 1)'; // Black with full alpha
    ctx.fillRect(0, 0, TEXTURE_SIZE, TEXTURE_SIZE);
    material.clearcoat = DEFAULT_CLEARCOAT;
}

export function initDebug(ctx: CanvasRenderingContext2D, cb: () => void) {
    const img = new Image();
    img.src = UV;
    img.onload = () => {
        ctx.drawImage(img, 0, 0, TEXTURE_SIZE, TEXTURE_SIZE);
        cb();
    };
}

export function initCanvas() {
    const canvas = document.createElement('canvas');
    canvas.width = TEXTURE_SIZE;
    canvas.height = TEXTURE_SIZE;
    return canvas;
}

function InitEmptyTextureInternal(tooth: Mesh, key: TextureKeys, debug?: boolean) {
    const material: MeshPainterMaterial = tooth.material as MeshPainterMaterial;
    if (!material[key]) {
        const canvas = initCanvas();

        const ctx = canvas.getContext('2d');
        if (ctx) {
            // Ensure the canvas is clear
            if (key === TextureKeys.map) {
                if (debug) {
                    initDebug(ctx, () => {
                        material.needsUpdate = true;
                    });
                } else {
                    initDiffuseCanvas(ctx);
                }
            }
            if (key === TextureKeys.clearcoatMap) {
                initClearcoatCanvas(ctx, material);
            }
        }

        const newTexture = new CanvasTexture(canvas);
        material[key] = newTexture;
        material.needsUpdate = true;

        return canvas;
    } else {
        return material[key].source.data;
    }
}

export default InitEmptyTextureInternal;
