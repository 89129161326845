import type { LabsGqlGetDesignStaffTeamsQuery } from '@orthly/graphql-operations';
import { useCreateDesignStaffTeamMutation } from '@orthly/graphql-react';
import { QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Button, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type CreateDesignStaffTeamDialogProps = {
    designerStaffTeams: LabsGqlGetDesignStaffTeamsQuery | undefined;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetchTeamsCallback: () => Promise<any>;
};

export const CreateDesignStaffTeamDialog: React.FC<CreateDesignStaffTeamDialogProps> = ({
    designerStaffTeams,
    refetchTeamsCallback,
}) => {
    const teams = designerStaffTeams?.getDesignStaffTeams ?? [];
    const [open, setOpen] = React.useState<boolean>(false);
    const [teamExists, setTeamExists] = React.useState<boolean>(false);
    const { submit, submitting } = useRootActionCommand(useCreateDesignStaffTeamMutation(), {
        successMessage: 'Design Staff Team Created!',
        onSuccess: async () => {
            await refetchTeamsCallback();
            setOpen(false);
        },
    });

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Add New Team'}
            CustomButton={({ onClick }) => (
                <Button startIcon={'AddIcon'} onClick={onClick} variant={'primary'}>
                    Add new team
                </Button>
            )}
            content={
                <>
                    {teamExists && (
                        <Text color={'WARNING'} variant={'body1'}>
                            Team name already exists
                        </Text>
                    )}
                    <QuickForm<{ name: string }>
                        fields={{
                            name: {
                                type: 'text',
                                label: 'New Team Name',
                            },
                        }}
                        onChange={value => {
                            setTeamExists(teams.some(t => _.isEqual(_.toLower(t.name), _.toLower(value.name))));
                        }}
                        disabled={teamExists}
                        initialValues={{ name: '' }}
                        onSubmit={async ({ name }) => {
                            if (teamExists) {
                                return;
                            }
                            await submit({
                                name,
                            });
                        }}
                        dirtySubmitOnly
                    />
                </>
            }
        />
    );
};
