import { usePartnersData } from '../../utils/usePartners';
import type { ScanbodyRequestRow } from './ScanbodyOrdersTable.types';
import { LinkScanbodyRequestFulfillmentAction } from './actions/LinkScanbodyRequestFulfillmentAction';
import { UpdateScanbodyOrderAction } from './actions/UpdateScanbodyOrderAction';
import { getUniquePrioritySortedScanbodyList } from './utils';
import type {
    LabsGqlScanbodyFragment,
    LabsGqlImplantTypeFragment,
    LabsGqlScanbodyRequestTinyFragment,
} from '@orthly/graphql-operations';
import {
    useGetScanbodiesQuery,
    useDeleteScanbodyRequestMutation,
    useGetImplantTypesQuery,
    useGetScanbodyRequestShipmentQuery,
    useGetScanbodyRequestsInLastNDaysQuery,
} from '@orthly/graphql-react';
import type { MUITableColumn } from '@orthly/mui-table';
import { MUITable } from '@orthly/mui-table';
import { ShippingUtils } from '@orthly/shared-types';
import {
    DownloadIcon,
    useChangeSubmissionFn,
    LoadBlocker,
    TrashIcon,
    RootActionDialog,
    QuickForm,
    SimpleSelect,
} from '@orthly/ui';
import { Button, FlossPalette, Text, Grid, IconButton, Tooltip, EditIcon } from '@orthly/ui-primitives';
import { useFirebaseFileDownload } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment/moment';
import React from 'react';

const SurgicalReportDownload: React.FC<{ surgical_report_file?: string }> = props => {
    const { surgical_report_file } = props;
    const triggerReportDownload = useFirebaseFileDownload(surgical_report_file || '');
    if (!surgical_report_file) {
        return null;
    }
    return (
        <Grid container style={{ justifyContent: 'center' }}>
            <IconButton onClick={() => triggerReportDownload.execute()}>
                <DownloadIcon />
            </IconButton>
        </Grid>
    );
};

interface UpdateFulfillmentIDCellProps {
    fulfillment_order_id?: string;
    scanbody_order_id: string;
    linked_scanbodies: LabsGqlScanbodyFragment[];
    refetch: () => void;
    unknownImplantType: boolean;
}

const UpdateFulfillmentIDCell: React.FC<UpdateFulfillmentIDCellProps> = props => {
    const { fulfillment_order_id, linked_scanbodies } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <Grid container style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <IconButton onClick={() => setOpen(true)}>
                <EditIcon />
            </IconButton>
            {fulfillment_order_id && (
                <Text variant={'body2'} style={{ marginLeft: 8 }}>
                    {fulfillment_order_id}
                </Text>
            )}
            <LinkScanbodyRequestFulfillmentAction
                open={open}
                setOpen={setOpen}
                scanbody_order_id={props.scanbody_order_id}
                fulfillment_order_id={fulfillment_order_id}
                linked_scanbodies={linked_scanbodies}
                refetch={props.refetch}
                unknownImplantType={props.unknownImplantType}
            />
        </Grid>
    );
};

const ShipmentInfoDialog: React.FC<{ scanbody_request_id: string }> = props => {
    const { scanbody_request_id } = props;
    const [open, setOpen] = React.useState(false);
    const { data, loading } = useGetScanbodyRequestShipmentQuery({
        variables: { scanbody_request_id },
        skip: !open,
    });

    const tracking_info = data?.getScanbodyRequestById?.shipment ?? null;

    return (
        <RootActionDialog
            loading={loading}
            open={open}
            setOpen={setOpen}
            dialogProps={{ fullWidth: true }}
            title={'Scan body Order Shipping Information (View Only)'}
            content={
                <Grid container>
                    {tracking_info && (
                        <QuickForm<{ tracking_number?: string; carrier?: string }>
                            initialValues={{
                                tracking_number: tracking_info.tracking_number,
                                carrier: ShippingUtils.getCarrierDisplayText(tracking_info.carrier),
                            }}
                            fields={{
                                tracking_number: {
                                    type: 'text',
                                    optional: true,
                                    fieldProps: { InputProps: { readOnly: true } },
                                },
                                carrier: {
                                    type: 'text',
                                    optional: true,
                                    fieldProps: { InputProps: { readOnly: true } },
                                },
                            }}
                            submitButtonProps={{ children: 'Track Shipment' }}
                            onSubmit={() => {
                                window.open(
                                    ShippingUtils.getTrackingLinkForCarrier(
                                        tracking_info.carrier,
                                        tracking_info.tracking_number,
                                    ),
                                    '_blank',
                                );
                            }}
                        />
                    )}
                </Grid>
            }
            CustomButton={({ onClick }) => (
                <Button variant={'ghost'} onClick={onClick}>
                    View
                </Button>
            )}
        />
    );
};

interface DeleteScanbodyOrderButtonProps {
    scanbody_order_id: string;
    onSubmit: () => void;
}

const DeleteScanbodyOrderButton: React.FC<DeleteScanbodyOrderButtonProps> = props => {
    const { scanbody_order_id, onSubmit } = props;
    const [deleteMutation] = useDeleteScanbodyRequestMutation();
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, any>(data => deleteMutation({ variables: { data } }), {
        successMessage: () => [`Deleted Scan body Request`, {}],
        onSuccess: onSubmit,
    });

    return (
        <Tooltip title={'Delete'}>
            <IconButton
                onClick={() =>
                    window.confirm('Are you sure you want to delete this scan body request?') &&
                    submit({ id: scanbody_order_id })
                }
                style={{ color: FlossPalette.ATTENTION }}
            >
                <LoadBlocker blocking={submitting}>
                    <TrashIcon />
                </LoadBlocker>
            </IconButton>
        </Tooltip>
    );
};

const ScanbodyRequestsRootColumns = (refetch: () => void): MUITableColumn<ScanbodyRequestRow>[] => [
    { name: 'Practice', field: 'practice.name', render: r => r.practice.name, filterOptions: { type: 'multiselect' } },
    { name: 'Address', render: 'address', filterOptions: { type: 'multiselect' } },
    {
        name: 'Appointment Date',
        type: 'datetime' as 'datetime',
        field: 'appointment_date',
        render: row => <span>{moment(row.appointment_date).format(`MM/DD/YY`)}</span>,
        defaultSort: 'desc',
    },
    {
        name: 'Requested Date',
        type: 'datetime' as 'datetime',
        field: 'created_at',
        render: row => <span>{moment(row.created_at).format(`MM/DD/YY`)}</span>,
        defaultSort: 'desc',
    },
    {
        name: 'Manufacturer',
        render: 'manufacturer',
        filterOptions: { type: 'multiselect' },
    },
    {
        name: 'System',
        render: 'system',
        filterOptions: { type: 'multiselect' },
    },
    {
        name: 'Platform Size',
        render: 'connection',
        filterOptions: { type: 'multiselect' },
    },
    {
        name: 'Surgical Report',
        render: row => <SurgicalReportDownload surgical_report_file={row.surgical_report_file ?? undefined} />,
    },
    {
        name: 'Linked Scan bodies',
        render: row => (
            <>
                {getUniquePrioritySortedScanbodyList(row).map(({ id, manufacturer, name }) => (
                    <Grid key={id}>
                        {manufacturer} {name}
                    </Grid>
                ))}
            </>
        ),
    },
    {
        name: 'Part Brand',
        render: row => <>{_.startCase(row.abutment_type ?? undefined)}</>,
    },
    {
        name: 'Requested Scan body',
        render: row => (
            <>
                {row.requested_scanbody?.manufacturer} {row.requested_scanbody?.name}
            </>
        ),
    },
    {
        name: 'Doctor',
        render: row => row.requesting_doctor_name,
    },
    {
        name: 'Patient',
        render: row => `${row.patient?.first_name} ${row.patient?.last_name}`,
    },
    {
        name: 'Fulfillment Order ID',
        filterOptions: { defaultValues: ['false'] },
        type: 'boolean',
        field: 'fulfillment_order_id',
        render: row =>
            !row.deleted_at && (
                <UpdateFulfillmentIDCell
                    fulfillment_order_id={row.fulfillment_order_id ?? undefined}
                    scanbody_order_id={row.id}
                    unknownImplantType={!row.implant_type}
                    linked_scanbodies={getUniquePrioritySortedScanbodyList(row)}
                    refetch={refetch}
                />
            ),
    },
    {
        name: 'Shipping',
        field: 'shipment_id',
        type: 'boolean',
        render: row => row.shipment_id && <ShipmentInfoDialog scanbody_request_id={row.id} />,
        filterOptions: { defaultValues: ['false'] },
    },
    {
        name: 'Archived',
        render: 'deleted_at',
        type: 'boolean',
        filterOptions: { defaultValues: ['false'] },
    },
    {
        name: 'Actions',
        sort: false,
        render: row =>
            !row.deleted_at && (
                <>
                    <UpdateScanbodyOrderAction scanbodyRequest={row} onSubmit={refetch} />
                    {!row.fulfillment_order_id && (
                        <DeleteScanbodyOrderButton scanbody_order_id={row.id} onSubmit={refetch} />
                    )}
                </>
            ),
    },
];

function findImplantTypeForRequest(
    request: LabsGqlScanbodyRequestTinyFragment,
    allImplantTypes: LabsGqlImplantTypeFragment[],
) {
    // Keys that we will use to compare if an implant type matches a given request
    const implantCheckKeys: (keyof LabsGqlImplantTypeFragment & keyof LabsGqlScanbodyRequestTinyFragment)[] = [
        'manufacturer',
        'system',
        'connection',
    ];

    return allImplantTypes.find(implant => implantCheckKeys.every(key => request[key] === implant[key]));
}

/*
 * Because of the volume of data, this function does a recomposition of the view instead of asking the database to do it
 * In the future, MUI Table should probably handle server side pagination, but for now we just grab all of the requests
 * individually as cheaply as possible, and then rejoin them in the logical way.
 */
function useScanbodyRequestRows(dateRange?: string) {
    // Scanbody request objects, with the smallest possible subset of data
    const {
        data: scanbodyRequestsData,
        loading: scanbodyRequestsLoading,
        refetch: refetchScanbodyRequests,
    } = useGetScanbodyRequestsInLastNDaysQuery({
        variables: dateRange ? { date_range: dateRange } : undefined,
    });

    // All of our practices in the system, we have to bulk fetch all because we don't know which ones have scanbodies yet.
    const { data: practicesData, loading: practicesLoading, refetch: refetchPractices } = usePartnersData();

    // All of the scanbodies, this table will be fairly consistent in size as we scale.
    const {
        data: scanbodiesData,
        loading: scanbodiesLoading,
        refetch: refetchScanbodies,
    } = useGetScanbodiesQuery({
        variables: { withDeleted: true },
    });

    // All of the implant types, this table will be fairly consistent in size as we scale.
    const { data: implantTypesData, refetch: refetchImplantTypes } = useGetImplantTypesQuery({
        variables: { withDeleted: false },
    });

    // Compute the state of all requests
    const rows = React.useMemo<ScanbodyRequestRow[]>(() => {
        const requests = scanbodyRequestsData?.getScanbodyRequestsInLastNDays ?? [];
        const allImplantTypes = implantTypesData?.getImplantTypes ?? [];

        // Simple performance optimizations so we can query the individual objects by the string key without having to search
        const practicesById = _.keyBy(practicesData?.listPracticeNames, practice => practice.id);
        const scanbodiesById = _.keyBy(scanbodiesData?.getScanbodies, scanbody => scanbody.id);

        // Now we iterate through each request and add in the neccesary data to get a complete fragment
        const results = requests.map<ScanbodyRequestRow | undefined>(request => {
            const practice = practicesById[request.practice_id];
            const implant_type = findImplantTypeForRequest(request, allImplantTypes) ?? null;

            // This basically can never happen, but we check for type safety reasons.
            if (!practice) {
                return undefined;
            }

            return {
                ...request,
                practice,
                implant_type,
                scanbody: request.scanbody_id ? scanbodiesById[request.scanbody_id] ?? null : null,
                requested_scanbody: request.requested_scanbody_id
                    ? scanbodiesById[request.requested_scanbody_id] ?? null
                    : null,
            };
        });

        return _.compact(results);
    }, [scanbodyRequestsData, practicesData, scanbodiesData, implantTypesData]);

    const loading = scanbodyRequestsLoading || practicesLoading || scanbodiesLoading;

    // And finally, we compute our refetch function, which triggers every query to refetch
    // We decide to refetch all in case e.g. a new practice was created who then created a scanbody request, which
    // would otherwise result in invalid data in our form.
    const refetch = React.useCallback(() => {
        void refetchScanbodyRequests();
        void refetchPractices();
        void refetchScanbodies();
        void refetchImplantTypes();
    }, [refetchScanbodyRequests, refetchPractices, refetchScanbodies, refetchImplantTypes]);

    return { rows, refetch, loading };
}

interface DateRangeSelectorProps {
    selectedValue?: string;
    onChange: (value?: string) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ selectedValue, onChange }) => {
    return (
        <div style={{ width: '200px', marginLeft: '20px', textAlign: 'left' }} data-testid={'date-range-selector'}>
            <SimpleSelect
                value={selectedValue}
                options={[{ value: '30 Days' }, { value: '3 Months' }, { value: '6 Months' }, { value: '1 Year' }]}
                onChange={onChange}
                label={'Showing orders for the past'}
                InputLabelProps={{ style: { backgroundColor: 'transparent' } }}
                SelectProps={{
                    style: {
                        backgroundColor: 'transparent',
                    },
                    variant: 'standard',
                }}
            />
        </div>
    );
};

export const ScanbodyOrdersTable: React.FC = () => {
    const [ordersDateRange, setOrdersDateRange] = React.useState<string | undefined>('30 Days');
    const { rows, loading, refetch } = useScanbodyRequestRows(ordersDateRange);

    return (
        <LoadBlocker blocking={loading}>
            <MUITable<ScanbodyRequestRow>
                title={'Scan body Orders'}
                columns={ScanbodyRequestsRootColumns(refetch)}
                toolbarOptions={{
                    CustomRight: () => (
                        <DateRangeSelector
                            selectedValue={ordersDateRange}
                            onChange={value => setOrdersDateRange(value)}
                        />
                    ),
                }}
                data={rows}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [{ tooltip: 'Refresh', icon: 'refresh', position: 'toolbar', onClick: refetch }],
                }}
            />
        </LoadBlocker>
    );
};
