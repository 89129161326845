import { useCapacityRuleFormFields } from './useCapacityRuleFormFields.graphql';
import type { LabsGqlUpdateCapacityStationRuleArgs } from '@orthly/graphql-schema';
import { LoadBlocker, QuickForm } from '@orthly/ui';
import React from 'react';

type EditCapacityStationRuleFormFields = Pick<
    LabsGqlUpdateCapacityStationRuleArgs,
    | 'name'
    | 'lab_product_codes'
    | 'design_station_codes'
    | 'matching_logic_json'
    | 'default_unit_limit'
    | 'work_order_matching_logic_json'
>;

interface CapacityStationRuleFormProps {
    onSubmit: (result: EditCapacityStationRuleFormFields) => void;
    initialValues: EditCapacityStationRuleFormFields;
}

export const EditCapacityStationRuleForm: React.VFC<CapacityStationRuleFormProps> = ({ onSubmit, initialValues }) => {
    const { onChange, loading: fieldsLoading, fields } = useCapacityRuleFormFields(initialValues);

    return (
        <LoadBlocker blocking={fieldsLoading}>
            <QuickForm<EditCapacityStationRuleFormFields>
                onSubmit={onSubmit}
                dirtySubmitOnly
                initialValues={initialValues}
                onChange={onChange}
                fields={fields}
            />
        </LoadBlocker>
    );
};
