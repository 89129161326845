import type { ScanbodyRequestRow } from '../ScanbodyOrdersTable.types';
import { LabsUtilsBase } from '@orthly/dentin';
import { useUpdateScanbodyRequestMutation, useAddressesForPartner } from '@orthly/graphql-react';
import type { LabsGqlUpdateScanbodyRequestCommand } from '@orthly/graphql-schema';
import type { CustomQFComponentProps } from '@orthly/ui';
import { useChangeSubmissionFn, RootActionDialog, QuickForm } from '@orthly/ui';
import { FlossPalette, Grid, IconButton, Tooltip, EditIcon } from '@orthly/ui-primitives';
import { AutocompleteImplantSystemInput } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

function getInitialValues(scanbodyRequest: ScanbodyRequestRow): LabsGqlUpdateScanbodyRequestCommand {
    return _.pick(scanbodyRequest, ['id', 'appointment_date', 'manufacturer', 'system', 'connection', 'address']);
}

const ImplantSystemInputField: React.FC<CustomQFComponentProps> = props => {
    const { form } = props;

    return (
        <AutocompleteImplantSystemInput
            linkMeta={_.pick(form.values, ['manufacturer', 'system', 'connection'])}
            updateField={form.setFieldValue}
            vertical
        />
    );
};

interface UpdateScanbodyOrderFormProps {
    scanbodyRequest: ScanbodyRequestRow;
    onSubmit: (result: LabsGqlUpdateScanbodyRequestCommand) => void;
}

const UpdateScanbodyOrderForm: React.FC<UpdateScanbodyOrderFormProps> = ({ scanbodyRequest, onSubmit }) => {
    const initialValues = React.useMemo(() => getInitialValues(scanbodyRequest), [scanbodyRequest]);
    const { addresses } = useAddressesForPartner(scanbodyRequest.practice.id);

    return (
        <Grid container>
            <QuickForm<LabsGqlUpdateScanbodyRequestCommand>
                initialValues={initialValues}
                resetOnInitialValueChange
                dirtySubmitOnly
                onSubmit={onSubmit}
                submitButtonProps={{
                    children: 'Update Scan body Request',
                    style: { marginTop: 16 },
                }}
                fields={{
                    id: { type: 'text', hidden: true },
                    manufacturer: {
                        type: 'custom',
                        component: ImplantSystemInputField,
                        validation: z.string(),
                    },
                    // system and connection are hidden as they're handled
                    // in the above implant system input along with manufacturer
                    system: { type: 'text', hidden: true },
                    connection: { type: 'text', hidden: true },
                    appointment_date: {
                        type: 'date',
                        label: 'Appointment Date',
                        fieldProps: {
                            fullWidth: true,
                        },
                    },
                    address: {
                        type: 'select',
                        label: 'Address',
                        options: addresses.map(value => ({ value: LabsUtilsBase.addressToString(value) })),
                    },
                }}
            />
        </Grid>
    );
};

interface UpdateScanbodyOrderDialogProps {
    scanbodyRequest: ScanbodyRequestRow;
    onSubmit: () => void;
}

export const UpdateScanbodyOrderAction: React.FC<UpdateScanbodyOrderDialogProps> = ({ scanbodyRequest, onSubmit }) => {
    const [open, setOpen] = React.useState(false);
    const [submitMutation] = useUpdateScanbodyRequestMutation();
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, any>(data => submitMutation({ variables: { data } }), {
        successMessage: () => [`Updated Scan body Request Successfully!`, {}],
        onSuccess: () => {
            setOpen(false);
            onSubmit();
        },
    });

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            dialogProps={{ fullWidth: true }}
            title={'Update Scan body Order'}
            content={<UpdateScanbodyOrderForm scanbodyRequest={scanbodyRequest} onSubmit={submit} />}
            buttonText={''}
            CustomButton={({ onClick }) => (
                <Tooltip title={'Update'}>
                    <IconButton onClick={onClick} style={{ color: FlossPalette.STAR_GRASS }}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )}
        />
    );
};
