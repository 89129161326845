import { RightSidebarContent } from './RightSidebarContent.graphql';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { OrderDetailImage } from '@orthly/veneer';
import React from 'react';

const PainterRightSidebarSalesOrder_Fragment = graphql(`
    fragment PainterRightSidebarSalesOrder_Fragment on SalesOrderDTO {
        ...PainterRightSidebarContentSalesOrder_Fragment
        items_full_display_info_with_common {
            ...VeneerOrderDetailItemsAndPreferencesSalesOrderItemFullDisplayInfoWithCommon_Fragment
        }
    }
`);

interface RightSidebarProps {
    onClose: () => void;
    salesOrder: FragmentType<typeof PainterRightSidebarSalesOrder_Fragment>;
    imageSources?: OrderDetailImage[];
}

export const RightSidebar: React.VFC<RightSidebarProps> = props => {
    const isReviewComplete = false;

    return isReviewComplete ? null : (
        <RightSidebarContent
            {...props}
            salesOrder={getFragmentData(PainterRightSidebarSalesOrder_Fragment, props.salesOrder)}
        />
    );
};
