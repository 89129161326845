import { CapacityStationTable } from './CapacityStationTables/CapacityStationTable';
import { EditCapacityStationDialog } from './CreateDialogs/EditCapacityStationDialog';
import { useMutation, useQuery } from '@apollo/client';
import type { CapacityStationDtoFragment, CapacityStationRuleDtoFragment } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import { useListLabOrgs } from '@orthly/graphql-react';
import { DANDY_INTERNAL_ID } from '@orthly/retainer-common';
import { ActionCard, useRootActionCommand } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const CapacityStations_Fragment = graphql(`
    fragment CapacityStationDTO on CapacityStationDTO {
        id
        created_at
        updated_at
        organization_id
        name
        unit_type
    }
`);

const CreateCapacityStation_Mutation = graphql(`
    mutation CreateCapacityStation_Mutation(
        $name: String!
        $organization_id: String!
        $unit_type: CapacityLimitUnitType!
    ) {
        createCapacityStation(name: $name, organization_id: $organization_id, unit_type: $unit_type) {
            ...CapacityStationDTO
        }
    }
`);

const AllCapacityStations_Query = graphql(`
    query AllCapacityStations {
        getAllCapacityStations {
            ...CapacityStationDTO
        }
    }
`);

export type StationRow = CapacityStationDtoFragment & { organization_name?: string };
export type RuleRow = CapacityStationRuleDtoFragment & { station_name?: string };

export interface EditCapacityDialogProps {
    setOpen: (open: boolean) => void;
    submitting: boolean;
    open: boolean;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submit: (args: any) => Promise<any>;
}

export const CapacityStationsRoot: React.FC = () => {
    const { data: { listOrganizations: labs = [] } = {}, loading: labsLoading } = useListLabOrgs({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const allLabsAndDDC = React.useMemo(() => [...labs, { id: DANDY_INTERNAL_ID, name: 'DDC' }], [labs]);

    const {
        loading: loadingStations,
        data: stations,
        error: stationsError,
        refetch: refetchStations,
    } = useQuery(AllCapacityStations_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const stationsResult = getFragmentData(CapacityStations_Fragment, stations?.getAllCapacityStations);

    const createCapacityStation = useMutation(CreateCapacityStation_Mutation);
    const {
        submit: submitCapacityStation,
        submitting: submittingCapacityStation,
        open: openAddStationDialog,
        setOpen: setOpenAddStationDialog,
    } = useRootActionCommand(createCapacityStation, {
        onSuccess: async () => {
            await refetchStations();
            setOpenAddStationDialog(false);
        },
        successMessage: 'Capacity Station created!',
    });

    const stationRows: StationRow[] = React.useMemo(
        () =>
            (stationsResult ?? []).map(station => ({
                ...station,
                organization_name: allLabsAndDDC?.find(({ id }) => id === station.organization_id)?.name ?? undefined,
            })),
        [stationsResult, allLabsAndDDC],
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <CapacityStationTable
                    stationRows={stationRows}
                    loadingStations={loadingStations}
                    setOpenAddStationDialog={setOpenAddStationDialog}
                    refetchStations={refetchStations}
                />
                <EditCapacityStationDialog
                    setOpen={setOpenAddStationDialog}
                    submitting={submittingCapacityStation}
                    open={openAddStationDialog}
                    labs={allLabsAndDDC}
                    labsLoading={labsLoading}
                    submit={submitCapacityStation}
                />
            </Grid>
            {stationsError && (
                <ActionCard title={'Error retrieving capacity stations'} subtitle={''} variant={'alert'} />
            )}
        </Grid>
    );
};
