// src/components/CanvasLayerList/CanvasLayerList.tsx
import { canvasLayersAtom, selectedCanvasLayerAtom } from '../../store/store';
import { CharacterizationType, createCanvasLayer, type CanvasLayer } from '../../utils/CanvasLayers';
import HorizontalPanelDivider from '../HorizontalPanelDivider/HorizontalPanelDivider';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
    DeleteIcon,
    DragHandle,
    IconButton,
    List,
    ListItemText,
    VisibilityOutlinedIcon,
    VisibilityOffOutlinedIcon,
    styled,
    Button,
    Select,
    MenuItem,
    ListItemButton,
    Box,
} from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

const CanvasLayerListRoot = styled('div')({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    height: '300px',
    position: 'relative',
});

const ListContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
});

const LayerItem = styled(ListItemButton)(({ selected }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px 0px 8px',
    backgroundColor: selected ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
    borderRadius: '4px',
    marginBottom: '4px',
    '&:hover': {
        backgroundColor: selected ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.04)',
    },
}));

const LayerActions = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const DragHandleWrapper = styled('div')({
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
});

const AddLayerButton = styled(Button)({
    margin: '8px',
    padding: '12px',
    flex: 1,
});

interface SortableLayerItemProps {
    id: string;
    layer: CanvasLayer;
    selected: boolean;
    onSelect: (id: string) => void;
    onDelete: (id: string) => void;
    onToggleVisibility: (id: string) => void;
}

const SortableLayerItem: React.FC<SortableLayerItemProps> = ({
    id,
    layer,
    selected,
    onSelect,
    onDelete,
    onToggleVisibility,
}) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
              transition: isDragging ? 'none' : transition,
          }
        : undefined;

    return (
        <LayerItem ref={setNodeRef} style={style} selected={selected} onClick={() => onSelect(id)}>
            <DragHandleWrapper {...attributes} {...listeners}>
                <DragHandle />
            </DragHandleWrapper>
            <img width={60} height={60} src={layer.canvas.toDataURL()} alt={layer.label ?? layer.id.slice(0, 4)} />
            <ListItemText primary={layer.characterizationType} />
            <LayerActions>
                <IconButton
                    onClick={e => {
                        e.stopPropagation();
                        onToggleVisibility(id);
                    }}
                >
                    {layer.visible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
                <IconButton
                    onClick={e => {
                        e.stopPropagation();
                        onDelete(id);
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </LayerActions>
        </LayerItem>
    );
};

const CanvasLayerList: React.FC = () => {
    const [canvasLayers, setCanvasLayers] = useAtom(canvasLayersAtom);
    const [selectedLayer, setSelectedLayer] = useAtom(selectedCanvasLayerAtom);
    const [characterizationType, setCharacterizationType] = React.useState<CharacterizationType>(
        CharacterizationType.Shade,
    );

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    // Sort layers by their sort property
    const sortedLayers = React.useMemo(() => {
        return Object.entries(canvasLayers)
            .sort(([, a], [, b]) => a.sort - b.sort)
            .map(([id]) => id);
    }, [canvasLayers]);

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            const oldIndex = sortedLayers.indexOf(active.id as string);
            const newIndex = sortedLayers.indexOf(over.id as string);

            const newOrder = arrayMove(sortedLayers, oldIndex, newIndex);

            // Update the sort values based on the new order
            const updatedLayers = { ...canvasLayers };
            newOrder.forEach((id, index) => {
                if (updatedLayers[id]) {
                    updatedLayers[id] = { ...updatedLayers[id], sort: index };
                }
            });

            setCanvasLayers(updatedLayers);
        }
    };

    const handleAddLayer = () => {
        const newLayer = createCanvasLayer({
            ...canvasLayers,
        });
        // Set the characterization type based on the selected value
        newLayer.characterizationType = characterizationType;

        setCanvasLayers({
            ...canvasLayers,
            [newLayer.id]: newLayer,
        });
    };

    const handleSelectLayer = (id: string) => {
        setSelectedLayer(canvasLayers[id] || null);
    };

    const handleDeleteLayer = (id: string) => {
        const updatedLayers = { ...canvasLayers };
        delete updatedLayers[id];

        // If the deleted layer was selected, clear the selection
        if (selectedLayer && id === Object.entries(canvasLayers).find(([, layer]) => layer === selectedLayer)?.[0]) {
            setSelectedLayer(null);
        }

        setCanvasLayers(updatedLayers);
    };

    const handleToggleVisibility = (id: string) => {
        const layer = canvasLayers[id];
        if (layer) {
            setCanvasLayers({
                ...canvasLayers,
                [id]: {
                    ...layer,
                    visible: !layer.visible,
                },
            });
        }
    };

    React.useEffect(() => {
        return () => {
            setSelectedLayer(null);
        };
    }, [setSelectedLayer]);

    return (
        <CanvasLayerListRoot>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                <Select
                    sx={{ width: '140px', padding: '10px' }}
                    label={'Type'}
                    value={characterizationType}
                    onChange={e => setCharacterizationType(e.target.value as CharacterizationType)}
                >
                    {Object.values(CharacterizationType).map(type => (
                        <MenuItem key={type} value={type}>
                            {type}
                        </MenuItem>
                    ))}
                </Select>
                <AddLayerButton variant={'contained'} onClick={handleAddLayer}>
                    Add Layer
                </AddLayerButton>
            </Box>

            <ListContainer>
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={sortedLayers} strategy={verticalListSortingStrategy}>
                        <List>
                            {sortedLayers.map(id => {
                                if (canvasLayers[id]) {
                                    return (
                                        <SortableLayerItem
                                            key={id}
                                            id={id}
                                            layer={canvasLayers[id]}
                                            selected={selectedLayer === canvasLayers[id]}
                                            onSelect={handleSelectLayer}
                                            onDelete={handleDeleteLayer}
                                            onToggleVisibility={handleToggleVisibility}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </List>
                    </SortableContext>
                </DndContext>
            </ListContainer>
            <HorizontalPanelDivider />
        </CanvasLayerListRoot>
    );
};

export default CanvasLayerList;
