import type { LabsGqlDeleteUnusedScanFileMutationVariables, LabsGqlScanFileFragment } from '@orthly/graphql-operations';
import { useDeleteUnusedScanFileMutation } from '@orthly/graphql-react';
import { RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Button, Grid, IconButton, Tooltip, DeleteIcon } from '@orthly/ui-primitives';
import React from 'react';

export const DeleteUnusedScan: React.FC<{
    scan: LabsGqlScanFileFragment;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetchScans: () => Promise<any>;
}> = props => {
    type Vars = LabsGqlDeleteUnusedScanFileMutationVariables['data'];
    const { refetchScans } = props;
    const [submitMtn] = useDeleteUnusedScanFileMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Scan File successfully deleted!', {}],
        onSuccess: () => refetchScans(),
    });
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Delete an Unused Scan File'}
            content={
                <Grid container style={{ padding: 10 }}>
                    <Grid item xs={12}>
                        Are you sure you want to{' '}
                        <b>
                            <i>permanently</i>
                        </b>{' '}
                        delete this Scan File? This action cannot be undone.
                    </Grid>
                    <Grid item xs={12} container justifyContent={'flex-end'}>
                        <Button variant={'primary'} onClick={() => submit({ scan_export_id: props.scan.id })}>
                            Yes, Delete
                        </Button>
                        &nbsp;
                        <Button variant={'secondary'} onClick={() => setOpen(false)}>
                            No, Cancel
                        </Button>
                    </Grid>
                </Grid>
            }
            style={{ width: 'auto', display: 'flex', flexBasis: 'auto' }}
            buttonText={'Add Scan File'}
            CustomButton={buttonProps => {
                return (
                    <IconButton onClick={buttonProps.onClick}>
                        <Tooltip title={'Delete Scan'}>
                            <DeleteIcon />
                        </Tooltip>
                    </IconButton>
                );
            }}
        />
    );
};
