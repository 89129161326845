import type { MarginLinesMap } from '../PainterRoot';
import type { CanvasLayers } from '../utils/CanvasLayers';
import type { ShadeData } from '../utils/ShadeData';
import { pickShade } from '../utils/ShadeValues';
import { controlsRefAtom, sceneRefAtom, selectedToolAtom } from './store';
import type { DesignFinishingRestorativeModel, TexturedModel } from '@orthly/dentin';
import type { Setter } from 'jotai';
import { atom } from 'jotai';
import type { Mesh } from 'three';
import { Vector3 } from 'three';

export interface ScanModels {
    upperJaw: TexturedModel;
    lowerJaw: TexturedModel;
    prePrepUpperJaw?: TexturedModel;
    prePrepLowerJaw?: TexturedModel;
}
export interface DesignModels {
    restorativeModels: DesignFinishingRestorativeModel[];
    scanModels: Partial<ScanModels>;
    marginLines: MarginLinesMap;
}

export const restorativeModelsAtom = atom<DesignModels['restorativeModels']>([]);

export interface RestorativeState {
    toothNumber: number;
    insertionAxis: Vector3;
    gradientDirection?: Vector3;
    shadeData: ShadeData;
    gradientControlPoints: number[];
    canvasLayers: CanvasLayers;
}

export const restorativesStateAtom = atom(new Map<number, RestorativeState>());

const resetSelection = (set: Setter) => {
    set(selectedToolAtom, null);
};

export const toothRefAtom = atom<Mesh | null, [Mesh | null], void>(null, (_get, set, update: Mesh | null) => {
    set(toothRefAtom, update);
    resetSelection(set);
});

export const selectedRestorativeToothNumberAtom = atom<number, [number], void>(0, (get, set, update: number) => {
    const scene = get(sceneRefAtom);
    const tooth = scene?.getObjectByName(update.toString()) as Mesh;
    const controls = get(controlsRefAtom);
    if (controls) {
        if (update === 0) {
            controls.target.set(0, 0, 0);
            set(toothRefAtom, null);
        } else if (tooth) {
            tooth?.geometry?.boundingBox?.getCenter(controls.target);
            set(toothRefAtom, tooth);
        }
    }
    set(selectedRestorativeToothNumberAtom, update);
});

export const selectedRestorativeStateAtom = atom<RestorativeState | undefined, [Partial<RestorativeState>], void>(
    get => {
        const toothNum = get(selectedRestorativeToothNumberAtom);
        const map = get(restorativesStateAtom);
        return map.get(toothNum);
    },
    (get, set, updated: Partial<RestorativeState>) => {
        const toothNum = get(selectedRestorativeToothNumberAtom);
        const map = new Map(get(restorativesStateAtom));
        const prev = map.get(toothNum) || {
            toothNumber: toothNum,
            insertionAxis: new Vector3(),
            gradientDirection: new Vector3(),
            shadeData: pickShade('A1'),
            gradientControlPoints: [],
            canvasLayers: {},
        };
        const updatedState = { ...prev, ...updated };
        map.set(toothNum, updatedState);
        set(restorativesStateAtom, map);
    },
);
