import { InlineEditButton } from '../../../components/InlineEditButton';
import type {
    LabsGqlCreateOrganizationPriceConfigMutationVariables,
    LabsGqlDeleteOrganizationPriceConfigMutationVariables,
    LabsGqlLabPricePartnerPricesResultFragment,
    LabsGqlUpdateOrganizationPriceConfigMutationVariables,
} from '@orthly/graphql-operations';
import {
    useCreateOrganizationPriceConfigMutation,
    useDeleteOrganizationPriceConfigMutation,
    useUpdateOrganizationPriceConfigMutation,
} from '@orthly/graphql-react';
import type { LabsGqlOrganizationTypeForPricing } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, TrashCanIcon, useChangeSubmissionFn } from '@orthly/ui';
import { Button, Grid, IconButton, Tooltip } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { z } from 'zod';

interface CreateOrganizationPriceConfigActionProps {
    organization_id: string;
    organization_type: LabsGqlOrganizationTypeForPricing;
    prices: LabsGqlLabPricePartnerPricesResultFragment[];
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onComplete: () => Promise<any>;
}

interface UpdateOrganizationPriceConfigActionProps {
    id: string;
    price_cents: number;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onComplete: () => Promise<any>;
}

interface DeleteOrganizationPriceConfigProps {
    id: string;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onComplete: () => Promise<any>;
}

type CreateConfigVars = LabsGqlCreateOrganizationPriceConfigMutationVariables['data'];
type UpdateConfigVars = LabsGqlUpdateOrganizationPriceConfigMutationVariables['data'];
type DeleteConfigVars = LabsGqlDeleteOrganizationPriceConfigMutationVariables['data'];

/**
 * Returns the dialog for creating an organization's price configuration.
 */
export const CreateOrganizationPriceConfigAction: React.FC<CreateOrganizationPriceConfigActionProps> = props => {
    const [submitMtn] = useCreateOrganizationPriceConfigMutation();
    const { prices, organization_id, organization_type } = props;
    const mtnSubmitter = (data: CreateConfigVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [CreateConfigVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Price created!', {}],
        onSuccess: async () => {
            await props.onComplete();
        },
    });
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Add price'}
            content={
                <QuickForm<CreateConfigVars>
                    fields={{
                        organization_id: { type: 'text', hidden: true },
                        organization_type: { type: 'text', hidden: true },
                        price_id: {
                            label: 'Product Catalog Item',
                            type: 'select',
                            options: prices.map(price => ({
                                value: price.id,
                                label: price.name,
                            })),
                        },
                        price_cents: {
                            label: 'Price',
                            type: 'number',
                            validation: z.number().min(0, 'Price must not be negative'),
                            fieldProps: { InputProps: { startAdornment: '$' } },
                        },
                    }}
                    initialValues={{
                        organization_id: organization_id,
                        organization_type: organization_type,
                    }}
                    onSubmit={async fields => {
                        fields.price_cents = Math.round(fields.price_cents * 100);
                        await submit(fields);
                    }}
                />
            }
            buttonText={'Create Price'}
            buttonProps={{ style: { maxWidth: 250 } }}
        />
    );
};

/**
 * Returns the dialog for updating the price of an existing organization price configuration.
 */
export const UpdateOrganizationPriceConfigAction: React.FC<UpdateOrganizationPriceConfigActionProps> = props => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const [submitMtn] = useUpdateOrganizationPriceConfigMutation();
    const { id, price_cents } = props;
    const mtnSubmitter = (data: UpdateConfigVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [UpdateConfigVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Price updated!', {}],
        onSuccess: async () => {
            await props.onComplete();
        },
    });

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Update price'}
            CustomButton={({ onClick }) => (
                <InlineEditButton label={`\$${(price_cents / 100).toFixed(2)}`} onClick={onClick} />
            )}
            content={
                <QuickForm<UpdateConfigVars>
                    fields={{
                        id: { type: 'text', hidden: true },
                        price_cents: {
                            label: 'Price',
                            type: 'number',
                            validation: z.number().min(0, 'Price must not be negative'),
                            fieldProps: { InputProps: { startAdornment: '$' } },
                        },
                    }}
                    initialValues={{
                        id: id,
                        price_cents: price_cents / 100,
                    }}
                    onSubmit={async fields => {
                        fields.price_cents = Math.round(fields.price_cents * 100);
                        await submit(fields);
                    }}
                />
            }
            buttonText={'Update Price'}
            buttonProps={{ disabled: disableContractEdits, style: { maxWidth: 250 } }}
        />
    );
};

/**
 * Returns the "Are you sure" dialog when deleting an organization price configuration.
 */
export const DeleteOrganizationPriceConfigAction: React.FC<DeleteOrganizationPriceConfigProps> = props => {
    const { id, onComplete } = props;
    const [submitMtn] = useDeleteOrganizationPriceConfigMutation();
    const mtnSubmitter = (data: DeleteConfigVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [DeleteConfigVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Price successfully deleted!', {}],
        onSuccess: async () => {
            await onComplete();
        },
    });

    return (
        <RootActionDialog
            showCloseButton
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Delete Price'}
            content={
                <Grid container style={{ padding: 10 }}>
                    <Grid item xs={12}>
                        Are you sure you want to delete this price?
                    </Grid>
                    <Grid item xs={12} container justifyContent={'flex-end'}>
                        <Button variant={'primary'} onClick={() => submit({ id })}>
                            Yes, Delete
                        </Button>
                        &nbsp;
                        <Button variant={'secondary'} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            }
            style={{ padding: 0, flexBasis: 0 }}
            buttonText={'Delete Price'}
            buttonProps={{ fullWidth: false }}
            CustomButton={({ onClick }) => (
                <IconButton onClick={onClick}>
                    <Tooltip title={'Delete Price'}>
                        <TrashCanIcon />
                    </Tooltip>
                </IconButton>
            )}
        />
    );
};
