import {
    OrderCompareFactRow,
    OrderCompareSectionTitle,
    OrderCompareSectionWrapper,
} from './OrderCompareDisplayComponents';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlTaggableEntityType } from '@orthly/graphql-schema';
import { TagsAutocomplete } from '@orthly/veneer';
import React from 'react';

const AdminOrderCompareOrderSummarySalesOrder_Fragment = graphql(`
    fragment AdminOrderCompareOrderSummarySalesOrder_Fragment on SalesOrderDTO {
        id
        has_design_preview
        scan_export_id
        creation_return_id
    }
`);

interface OrderCompareOrderSummaryProps {
    salesOrder: FragmentType<typeof AdminOrderCompareOrderSummarySalesOrder_Fragment>;
    previousSalesOrder: FragmentType<typeof AdminOrderCompareOrderSummarySalesOrder_Fragment> | null;
}

export const OrderCompareOrderSummary: React.VFC<OrderCompareOrderSummaryProps> = ({
    salesOrder: salesOrderProp,
    previousSalesOrder: previousSalesOrderProp,
}) => {
    const salesOrder = getFragmentData(AdminOrderCompareOrderSummarySalesOrder_Fragment, salesOrderProp);
    const previousSalesOrder = getFragmentData(
        AdminOrderCompareOrderSummarySalesOrder_Fragment,
        previousSalesOrderProp,
    );

    return (
        <OrderCompareSectionWrapper rows={5}>
            <OrderCompareSectionTitle title={'Order Details'} />
            <OrderCompareFactRow title={'Design Preview'} value={salesOrder.has_design_preview ? 'Yes' : 'No'} />
            <OrderCompareFactRow
                title={'Re-scan'}
                value={
                    previousSalesOrder && previousSalesOrder.scan_export_id !== salesOrder.scan_export_id ? 'Yes' : 'No'
                }
            />
            <OrderCompareFactRow title={'Return'} value={salesOrder.creation_return_id ? 'Yes' : 'No'} />
            {/* Tags will not compare correctly since they're loaded async in the background
            This is a known issue since the level of effort to fix was not worth the benefit of it working */}
            <OrderCompareFactRow
                title={'Tags'}
                value={
                    <TagsAutocomplete
                        rootStyle={{ height: '12px', padding: 'none', margin: 'none', display: 'inline' }}
                        entityId={salesOrder.id}
                        entityType={LabsGqlTaggableEntityType.Order}
                        placeholder={'None'}
                    />
                }
            />
        </OrderCompareSectionWrapper>
    );
};
