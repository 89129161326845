import { Box, Text } from '@orthly/ui-primitives';
import React from 'react';

export const LandingPage3D: React.VFC = () => {
    return (
        <Box sx={{ padding: '24px' }}>
            <Text variant={'h4'}>3D Landing Page</Text>
            <Text variant={'body1'}>Tools for testing in production!</Text>
            <Text variant={'body1'}>Choose a tool:</Text>
            <ul>
                <li>
                    <a href={'/team_3d/dcm_viewer'}>DCM Viewer</a>
                </li>
                <li>
                    <a href={'/team_3d/scan_review'}>Scan Review Multi Model Viewer</a>
                </li>
                <li>
                    <a href={'/team_3d/scan_review_shade_matching'}>Scan Review Shade Matching Model Viewer</a>
                </li>
                <li>
                    <a href={'/team_3d/tooth_placement_viewer'}>Tooth Placement Viewer</a>
                </li>
                <li>
                    <a href={'/team_3d/design_viewer'}>Design Viewer</a>
                </li>
            </ul>
        </Box>
    );
};
