import { type DesignReviewOption } from '../components';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

/**
 * Used to track whether a video for a particular design revision has been played.
 * See `useHasVideoBeenPlayed` for an attendant usage.
 */
export const videoDesignReviewVideoPlayedAtom = atomWithStorage<{ [key: string]: boolean }>(
    'videoDesignReviewVideoPlayed', // Storage key
    {}, // Default value
);

export const ldrOptionsModalSelectionAtom = atom<DesignReviewOption | null>(null);
