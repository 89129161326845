import type { CapacityRuleFormFields } from '../../components/RoutingRule.util';
import { RoutingRuleUtils } from '../../components/RoutingRule.util';
import { useListLabOrgs } from '@orthly/graphql-react';
import { LabsGqlDayOfWeek, LabsGqlRoutingMetricsRange } from '@orthly/graphql-schema';
import { LoadBlocker, QuickForm } from '@orthly/ui';
import React from 'react';

const INITIAL_VALUES: CapacityRuleFormFields = {
    name: ``,
    manufacturer_id: ``,
    item_condition_json: `null`,
    order_condition_json: `null`,
    limit_range: LabsGqlRoutingMetricsRange.Since8Am,
    unit_limit: null,
    order_limit: null,
    condition_type: null,
    priority: -1,
    supports_design: true,
    supports_manufacturing: true,
    active_sla_days: Object.values(LabsGqlDayOfWeek),
};

export const CapacityRuleForm: React.FC<{
    loading: boolean;
    onSubmit: (result: Omit<CapacityRuleFormFields, 'condition_type'>) => void;
    initialValues?: CapacityRuleFormFields;
}> = ({ loading, onSubmit, initialValues }) => {
    const { data: { listOrganizations: labs = [] } = {}, loading: labsLoading } = useListLabOrgs({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const [formState, setFormState] = React.useState<CapacityRuleFormFields>(initialValues ?? INITIAL_VALUES);
    const formFields = React.useMemo(
        () =>
            RoutingRuleUtils.FormFieldsCapacity(
                formState.condition_type,
                formState.order_condition_json,
                formState.item_condition_json,
                labs,
            ),
        [formState.condition_type, formState.item_condition_json, formState.order_condition_json, labs],
    );
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formOnChange = React.useCallback((values: any) => setFormState(values), [setFormState]);
    const formOnSubmit = React.useCallback(
        ({
            name,
            limit_range,
            unit_limit,
            order_limit,
            condition_type,
            item_condition_json,
            order_condition_json,
            manufacturer_id,
            priority,
            supports_design,
            supports_manufacturing,
            active_sla_days,
        }) =>
            onSubmit({
                name,
                limit_range,
                unit_limit,
                order_limit,
                manufacturer_id,
                item_condition_json: condition_type === 'unit' ? item_condition_json : `null`,
                order_condition_json: condition_type === 'order' ? order_condition_json : `null`,
                priority: priority,
                supports_design: supports_design,
                supports_manufacturing: supports_manufacturing,
                active_sla_days: active_sla_days,
            }),
        [onSubmit],
    );

    return (
        <LoadBlocker blocking={loading || labsLoading}>
            <QuickForm<CapacityRuleFormFields>
                initialValues={formState}
                fields={formFields}
                onChange={formOnChange}
                onSubmit={formOnSubmit}
            />
        </LoadBlocker>
    );
};
