import { useSalesforceUserSyncMutation } from '@orthly/graphql-react';
import { SimpleInput } from '@orthly/ui';
import { Text, Button, Box } from '@orthly/ui-primitives';
import React from 'react';

interface SalesforceSyncProps {
    userId: string;
    salesforceContactId: string;
}

export const SalesforceSync: React.FC<SalesforceSyncProps> = props => {
    const { userId, salesforceContactId } = props;
    const [salesforceSyncClick] = useSalesforceUserSyncMutation();
    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '16px', marginY: '16px' }}>
            <Text variant={'h6'}>Salesforce Sync</Text>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px' }}>
                <SimpleInput
                    fullWidth
                    label={'Salesforce Contact ID'}
                    value={salesforceContactId}
                    disabled={true}
                    onChange={() => {}}
                />
                <Box sx={{ placeContent: 'center' }}>
                    <Button
                        onClick={() => {
                            void salesforceSyncClick({ variables: { id: userId } });
                        }}
                        variant={'primary'}
                    >
                        Force Sync
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
