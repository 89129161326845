import type { DesignRuleFormFields } from '../../components/RoutingRule.util';
import { RoutingRuleUtils } from '../../components/RoutingRule.util';
import { LabsGqlRoutingMetricsRange } from '@orthly/graphql-schema';
import { LoadBlocker, QuickForm } from '@orthly/ui';
import React from 'react';

const INITIAL_VALUES: DesignRuleFormFields = {
    name: ``,
    item_condition_json: `null`,
    order_condition_json: `null`,
    limit_range: LabsGqlRoutingMetricsRange.InFlight,
    unit_limit: null,
    order_limit: null,
    condition_type: null,
};

export const DesignRuleForm: React.FC<{
    loading: boolean;
    onSubmit: (result: Omit<DesignRuleFormFields, 'condition_type'>) => void;
    initialValues?: DesignRuleFormFields;
}> = ({ loading, onSubmit, initialValues }) => {
    const [formState, setFormState] = React.useState<DesignRuleFormFields>(initialValues ?? INITIAL_VALUES);
    const formFields = React.useMemo(
        () =>
            RoutingRuleUtils.FormFields(
                formState.condition_type,
                formState.order_condition_json,
                formState.item_condition_json,
            ),
        [formState],
    );
    return (
        <LoadBlocker blocking={loading}>
            <QuickForm<DesignRuleFormFields>
                initialValues={formState}
                fields={formFields}
                // EPDPLT-4736: Using any is unsafe and should be avoided.
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(values: any) => setFormState(values)}
                onSubmit={({ name, item_condition_json, order_condition_json, limit_range, unit_limit, order_limit }) =>
                    onSubmit({ name, item_condition_json, order_condition_json, limit_range, unit_limit, order_limit })
                }
            />
        </LoadBlocker>
    );
};
