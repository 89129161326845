import {
    DEFAULT_APPEARANCE,
    lowerJawAppearanceAtom,
    restorativesAppearanceAtom,
    upperJawAppearanceAtom,
} from '../../store/modelAppearance';
import HorizontalPanelDivider from '../HorizontalPanelDivider/HorizontalPanelDivider';
import ToolPanel from '../ToolPanel/ToolPanel';
import { ModelController } from './ModelController';
import { SectionHeader } from './SectionHeader';
import type { SceneModelAppearance } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { SidebarToggleButton, StackY } from '@orthly/ui';
import { Box, FlossPalette, List, styled } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

const PainterLeftSidebarSalesOrder_Fragment = graphql(`
    fragment PainterLeftSidebarSalesOrder_Fragment on SalesOrderDTO {
        items_display_info {
            item_id
            full_display_name
            unns
        }
    }
`);

interface LeftSidebarProps {
    salesOrder: FragmentType<typeof PainterLeftSidebarSalesOrder_Fragment>;
}

interface RestorativeControllerProps {
    toothNumber: number;
    appearance: SceneModelAppearance;
}

const RestorativeController: React.FC<RestorativeControllerProps> = ({ appearance, toothNumber }) => {
    const [restoratives, setRestoratives] = useAtom(restorativesAppearanceAtom);
    if (!appearance) {
        return null;
    }

    const handleRestorativeUpdate = (update: SceneModelAppearance) => {
        if (toothNumber) {
            setRestoratives(new Map(restoratives).set(toothNumber, update));
        }
    };

    return (
        <ModelController
            toothNumber={toothNumber}
            label={appearance?.label || ''}
            appearance={appearance}
            setAppearance={handleRestorativeUpdate}
            setRestorativesAppearance={handleRestorativeUpdate}
        />
    );
};
const PANEL_WIDTH = 260;

const Panel = styled(Box)<{ open: boolean }>(({ open }) => ({
    width: PANEL_WIDTH,
    height: '100vh',
    overflowY: 'auto',
    borderRight: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    flex: 'none',
    marginLeft: open ? 0 : -1 * (PANEL_WIDTH - 16),
    transition: 'margin 0.3s',
}));

export const LeftSidebar: React.FC<LeftSidebarProps> = ({ salesOrder: salesOrderProp }) => {
    const [open, setOpen] = React.useState<boolean>(true);
    const [upperJaw, setUpperJaw] = useAtom(upperJawAppearanceAtom);
    const [lowerJaw, setLowerJaw] = useAtom(lowerJawAppearanceAtom);
    const [restoratives, setRestoratives] = useAtom(restorativesAppearanceAtom);
    const salesOrder = getFragmentData(PainterLeftSidebarSalesOrder_Fragment, salesOrderProp);

    React.useEffect(() => {
        const restorativeItems = salesOrder.items_display_info.filter(item => item.unns.length);
        if (restorativeItems.length) {
            const initialRestoratives = new Map<number, SceneModelAppearance>();
            restorativeItems.forEach(item => {
                item.unns[0] &&
                    initialRestoratives.set(item.unns[0], { ...DEFAULT_APPEARANCE, label: item.full_display_name });
            });
            setRestoratives(initialRestoratives);
        }
    }, [salesOrder, setRestoratives]);

    return (
        <>
            <Panel open={open} bgcolor={FlossPalette.TAN}>
                <StackY sx={{ height: '100%', position: 'relative' }}>
                    <List dense>
                        <SectionHeader label={'Scans'} />
                        <ModelController label={'Upper jaw'} appearance={upperJaw} setAppearance={setUpperJaw} />
                        <ModelController label={'Lower jaw'} appearance={lowerJaw} setAppearance={setLowerJaw} />
                        <SectionHeader label={'Restoratives'} />
                        {Array.from(restoratives.entries()).map(([toothNumber, appearance]) => (
                            <RestorativeController
                                key={toothNumber}
                                appearance={appearance}
                                toothNumber={toothNumber}
                            />
                        ))}
                    </List>
                    <HorizontalPanelDivider />
                    <ToolPanel />
                </StackY>
            </Panel>
            <SidebarToggleButton open={open} setOpen={setOpen} variant={'left'} panelWidth={PANEL_WIDTH} />
        </>
    );
};
