import { selectedCanvasLayerAtom } from '../../../store/store';
import { CharacterizationType } from '../../../utils/CanvasLayers';
import BrushSelect from '../../BrushSelect/BrushSelect';
import CanvasLayerList from '../../CanvasLayerList/CanvasLayerList';
import OcclusalCharacterizationControl from '../../OcclusalCharacterizationControl/OcclusalCharacterizationControl';
import { styled } from '@orthly/ui-primitives';
import { useAtomValue } from 'jotai';
import React from 'react';

const PainterPanelRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
});

const PainterPanel: React.FC = () => {
    const layerSelected = useAtomValue(selectedCanvasLayerAtom);
    return (
        <PainterPanelRoot>
            <CanvasLayerList />
            {layerSelected && layerSelected.characterizationType === CharacterizationType.Shade && <BrushSelect />}
            {layerSelected && layerSelected.characterizationType === CharacterizationType.Occlusal && (
                <OcclusalCharacterizationControl />
            )}
        </PainterPanelRoot>
    );
};

export default PainterPanel;
