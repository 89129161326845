import { Assert } from '@orthly/runtime-utils';
import { SimpleSelect } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const ACTIVE_STATUSES = ['Active Now', 'Not Yet Active', '(ALL)'] as const;
type ActiveStatus = (typeof ACTIVE_STATUSES)[number];
// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isActiveStatus = (x: any): x is ActiveStatus => ACTIVE_STATUSES.includes(x);

export const SelectActiveStatus: React.FC<{
    activeStatus?: boolean;
    setActiveStatus: (status: boolean | undefined) => void;
}> = props => {
    let value: ActiveStatus;

    switch (props.activeStatus) {
        case true:
            value = 'Active Now';
            break;
        case false:
            value = 'Not Yet Active';
            break;
        case undefined:
            value = '(ALL)';
            break;
        default:
            Assert.unreachable(props.activeStatus);
    }

    return (
        <div style={{ borderRight: `1px solid ${FlossPalette.DARK_TAN}`, minWidth: '120px' }}>
            <SimpleSelect
                label={'Active'}
                value={value}
                backgroundColor={'white'}
                options={ACTIVE_STATUSES.map(value => ({ value }))}
                onChange={newValue => {
                    if (isActiveStatus(newValue)) {
                        switch (newValue) {
                            case 'Active Now':
                                props.setActiveStatus(true);
                                break;
                            case 'Not Yet Active':
                                props.setActiveStatus(false);
                                break;
                            case '(ALL)':
                                props.setActiveStatus(undefined);
                                break;
                            default:
                                Assert.unreachable(newValue);
                        }
                    }
                }}
            />
        </div>
    );
};
