import { useGetPaginatedOrganizations } from '../../../graphql/useGetPaginatedOrganizations.graphql';
import { CreatePracticeAction } from '../actions/CreatePracticeAction';
import { useGetAllOrganizationsCsv } from '../graphql/useGetAllOrganizationsCsv.graphql';
import type { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Text,
    Tooltip,
    IconButton,
    Icon,
    FlossPalette,
    InputAdornment,
    SearchIcon,
    FormControl,
    Select,
    MenuItem,
} from '@orthly/ui-primitives';
import { debounce } from 'lodash';
import React from 'react';

interface PaginatedOrgTableWrapperProps {
    title: string;
    queryFilters: {
        name?: string;
        id?: string;
        include_ancestors?: boolean;
        include_hierarchy?: boolean;
        type?: LabsGqlOrganizationType[];
    };
    csvFilters: { type: LabsGqlOrganizationType[] };
    onRowClick: (rowId: string) => void;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowMapping: any[];
}

export const PaginatedOrgTableWrapper: React.VFC<PaginatedOrgTableWrapperProps> = ({
    title,
    queryFilters,
    csvFilters,
    onRowClick,
    rowMapping,
}) => {
    const [search, setSearch] = React.useState('');
    const [filterBy, setFilterBy] = React.useState<'name' | 'id'>('name'); // State to manage filter type
    const [currentPage, setCurrentPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const {
        organizations: rows,
        totalCount: totalRows,
        loading,
        refetch,
    } = useGetPaginatedOrganizations(currentPage, rowsPerPage, {
        name: filterBy === 'name' ? search : undefined,
        id: filterBy === 'id' ? search : undefined,
        ...queryFilters,
    });

    React.useEffect(() => {
        const fetchData = async () => {
            await refetch();
        };

        void fetchData();
    }, [rowsPerPage, refetch]);

    const handleSearch = debounce(value => {
        setSearch(value);
        setCurrentPage(0);
    }, 500);

    const handleChangePage = (__event: unknown, newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to the first page
    };

    const { getCsv, loading: csvLoading } = useGetAllOrganizationsCsv();

    return (
        <Box sx={{ width: '100%', padding: '24px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text variant={'h5'}>{title}</Text>
                <Box sx={{ display: 'flex' }}>
                    <Tooltip title={'Export Organizations'}>
                        <IconButton
                            onClick={async () => {
                                await getCsv(csvFilters);
                            }}
                            disabled={csvLoading}
                        >
                            <Icon icon={'SaveAltIcon'} style={{ color: FlossPalette.STAR_GRASS }} />
                        </IconButton>
                    </Tooltip>
                    <CreatePracticeAction
                        onSubmit={async () => {
                            refetch;
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <TextField
                    sx={{ width: '600px' }}
                    variant={'outlined'}
                    onChange={e => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position={'start'}>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        style: {
                            padding: '0px 16px',
                            borderRadius: '8px 0 0 8px',
                            background: FlossPalette.TAN,
                        },
                    }}
                />
                <Box sx={{ width: '100px' }}>
                    <FormControl fullWidth>
                        <Select
                            style={{
                                borderRadius: '0 8px 8px 0',
                                paddingBottom: '23px',
                                height: '55px',
                            }}
                            value={filterBy}
                            onChange={e => {
                                setFilterBy(e.target.value as 'name' | 'id');
                            }}
                        >
                            <MenuItem value={'name'}>Name</MenuItem>
                            <MenuItem value={'id'}>ID</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        {rowMapping.map((row, index) => (
                            <TableCell key={index}>{row.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={3} align={'center'}>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                    {!loading &&
                        rows &&
                        rows.length > 0 &&
                        rows.map(row => (
                            <TableRow sx={{ cursor: 'pointer' }} key={row.id} onClick={() => onRowClick(row.id)}>
                                {rowMapping.map((rowMap, index) => (
                                    <TableCell key={index}>{rowMap.value(row)}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <TablePagination
                sx={{
                    display: 'flex',
                    '.MuiTablePagination-toolbar': {
                        width: '100%',
                    },
                }}
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRows ?? 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};
