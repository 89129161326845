import type { AutomationFilterCriteria } from '../../../../state/AutomationBuilder.types';
import { AutomationSelectField } from '../../components/AutomationBuilderFields';
import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlFilterListCombinator } from '@orthly/graphql-schema';
import { makeStyles, createStyles, Grid } from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            transition: 'width 200ms ease, opacity 200ms ease',
            alignItems: 'center',
        },
        rootVisible: {
            width: '100%',
            opacity: 1,
        },
        rootHidden: {
            // dont know why this is getting overrode by mui defaults
            padding: '0px !important',
            width: 0,
            opacity: 0,
        },
    }),
);

const LIST_ENTITY_COMBINATOR_OPTS = Object.values(LabsGqlFilterListCombinator).map(value => ({
    value,
    label:
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        value === LabsGqlFilterListCombinator.All ? 'Every' : value === LabsGqlFilterListCombinator.Any ? 'Any' : 'No',
}));

interface ListEntityCombinatorFieldProps {
    filterListEntityName?: string;
    onChange: (newValue: Partial<LabsGqlFilterCriteriaSubmissionInput>) => void;
    criteria: AutomationFilterCriteria;
}

export const ListEntityCombinatorField: React.FC<ListEntityCombinatorFieldProps> = props => {
    const classes = useStyles();
    const { criteria, onChange, filterListEntityName } = props;
    const hasListEntity = typeof filterListEntityName === 'string';
    return (
        <Grid
            container
            item
            xs={hasListEntity ? 2 : 'auto'}
            classes={{ root: cx(classes.root, hasListEntity ? classes.rootVisible : classes.rootHidden) }}
        >
            <AutomationSelectField
                value={criteria.list_combinator ?? ''}
                options={LIST_ENTITY_COMBINATOR_OPTS}
                onChange={value => {
                    // EPDPLT-4736: Using any is unsafe and should be avoided.
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    !!value && onChange({ list_combinator: value as any });
                }}
                label={''}
            />
        </Grid>
    );
};
