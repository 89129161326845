import { usePartnerAndLabNames } from '../../../utils/usePartnerAndLabNames';
import type { ChoiceData } from '../../../utils/useTicketActionHooks';
import { SelectActiveStatus } from './ticket_filters/SelectActiveStatus';
import { SelectIDs } from './ticket_filters/SelectIDs';
import { SelectOpenStatus } from './ticket_filters/SelectOpenStatus';
import type { LabsGqlTicketsFilters } from '@orthly/graphql-schema';
import { ToolbarContainer } from '@orthly/ui';
import { styled } from '@orthly/ui-primitives';
import React from 'react';

const ToolbarSelectorWrapper = styled('div')({
    padding: '0px',
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row nowrap',
    width: '100%',
});

interface FiltersInputProps {
    filters: LabsGqlTicketsFilters;
    setFilters: (filters: LabsGqlTicketsFilters) => void;
    choiceData?: ChoiceData;
}

export const TicketFiltersInput: React.VFC<FiltersInputProps> = props => {
    const { labNamesById } = usePartnerAndLabNames();
    const labOptions = React.useMemo(
        () => new Map(Object.entries(labNamesById).map(([id, name]) => [id, { display: name ?? '' }])),
        [labNamesById],
    );

    return (
        <ToolbarContainer sticky style={{ padding: '0px', display: 'flex', flexFlow: 'column nowrap' }}>
            <ToolbarSelectorWrapper>
                <SelectOpenStatus
                    isOpen={props.filters.is_open ?? undefined}
                    setIsOpen={is_open => props.setFilters({ ...props.filters, is_open })}
                />
                <SelectActiveStatus
                    activeStatus={props.filters.active_action_is_workable_now ?? undefined}
                    setActiveStatus={active_action_is_workable_now =>
                        props.setFilters({ ...props.filters, active_action_is_workable_now })
                    }
                />
                <SelectIDs
                    label={'Assigned User'}
                    options={props.choiceData?.users}
                    selectIDs={assigned_user_ids => props.setFilters({ ...props.filters, assigned_user_ids })}
                    selectedIDs={props.filters.assigned_user_ids ?? undefined}
                />
                <SelectIDs
                    label={'Order Tags'}
                    options={props.choiceData?.tags}
                    selectIDs={order_tag_ids => props.setFilters({ ...props.filters, order_tag_ids })}
                    selectedIDs={props.filters.order_tag_ids ?? undefined}
                />
                <SelectIDs
                    label={'Action Type'}
                    options={props.choiceData?.actionTypes}
                    selectIDs={action_type_ids => props.setFilters({ ...props.filters, action_type_ids })}
                    selectedIDs={props.filters.action_type_ids ?? undefined}
                />
                <SelectIDs
                    label={'Manufacturer'}
                    options={labOptions}
                    selectIDs={manufacturer_ids => props.setFilters({ ...props.filters, manufacturer_ids })}
                    selectedIDs={props.filters.manufacturer_ids ?? undefined}
                />
            </ToolbarSelectorWrapper>
        </ToolbarContainer>
    );
};
