import type { UserRole } from '../../state/Users.types';
import type { IteroInfoEditUserRoles } from '../EditUser.util';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrganizationSummaryFragment } from '@orthly/graphql-operations';
import { LabsGqlOrganizationType, LabsGqlSetDandyAsPreferredEnum } from '@orthly/graphql-schema';
import { StaffRoleInternal, StaffRoleLab, StaffRolePractice, StaffRoleParent } from '@orthly/retainer-common';
import _ from 'lodash';
import React from 'react';

export const valueToRole = (value: string) => {
    if (value === 'internal') {
        return LabsGqlOrganizationType.Internal;
    } else if (value === 'lab') {
        return LabsGqlOrganizationType.Lab;
    } else if (value === 'practice') {
        return LabsGqlOrganizationType.Practice;
    } else if (value === 'parent') {
        return LabsGqlOrganizationType.Parent;
    } else {
        return undefined;
    }
};
export const options = [
    { value: 'internal', label: 'Internal' },
    { value: 'lab', label: 'Lab' },
    { value: 'practice', label: 'Practice' },
    { value: 'parent', label: 'Parent' },
];

export const preferredLabOnboardingOptions: Array<{ value: LabsGqlSetDandyAsPreferredEnum; label: string }> = [
    { value: LabsGqlSetDandyAsPreferredEnum.Completed, label: 'Completed' },
    { value: LabsGqlSetDandyAsPreferredEnum.NeedHelp, label: 'Needs Help' },
    { value: LabsGqlSetDandyAsPreferredEnum.WillDoBeforeFirstPatient, label: 'Will Do Before First Patient' },
];

export const roleOptions = (role: UserRole) => {
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const enumToOptions = (e: any) =>
        Object.keys(e).map(value => ({ value, label: `${_.startCase(_.last(value.split('__')))}` }));
    switch (role.type) {
        case LabsGqlOrganizationType.Internal:
            return enumToOptions(StaffRoleInternal);
        case LabsGqlOrganizationType.Practice:
            return enumToOptions(StaffRolePractice);
        case LabsGqlOrganizationType.Lab:
            return enumToOptions(StaffRoleLab);
        case LabsGqlOrganizationType.Parent:
            return enumToOptions(StaffRoleParent);
        default:
            return [];
    }
};

export const orgOptions = (
    organizations: LabsGqlOrganizationSummaryFragment[] | undefined,
    role: UserRole,
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { value: any; label: any }[] => {
    return organizations?.filter(org => org.type === role.type).map(org => ({ value: org.id, label: org.name })) ?? [];
};

const UpsertIteroUserMutation = graphql(`
    mutation UpdateIteroUser($itero_doctor_id: String!, $should_immediately_place_order: Boolean!, $staff_id: String!) {
        updateOrCreateIteroUser(
            itero_doctor_id: $itero_doctor_id
            should_immediately_place_order: $should_immediately_place_order
            staff_id: $staff_id
        ) {
            id
        }
    }
`);

const UpsertIteroOnboardingMutation = graphql(`
    mutation UpdateOnboardingPreferences($input: UpdateStaffPreferencesInput!, $staff_member_id: String!) {
        updateStaffMemberOnboardingPreferencesById(input: $input, staff_member_id: $staff_member_id) {
            id
        }
    }
`);

type IteroFields = {
    iteroDoctorId: string;
    wantsPreferenceReview: boolean;
    setDandyAsPreferredLabStatus: LabsGqlSetDandyAsPreferredEnum | undefined;
    shouldImmediatelyPlaceOrder: boolean;
};

export const useIteroFields = (memberIdParam?: string, iteroInfo?: IteroInfoEditUserRoles) => {
    const initialIteroDoctorId = iteroInfo?.[memberIdParam ?? '']?.itero_doctor_id ?? '';
    const initialWantsPreferenceReview = iteroInfo?.[memberIdParam ?? '']?.wants_preference_review ?? false;
    const initialSetDandyAsPreferredLabStatus = iteroInfo?.[memberIdParam ?? '']?.set_dandy_as_preferred_lab_status;
    const initialShouldImmediatelyPlaceOrder =
        iteroInfo?.[memberIdParam ?? '']?.should_immediately_place_order ?? false;
    const hasOnboardingFields = iteroInfo?.[memberIdParam ?? '']?.has_onboarding_fields;

    const [iteroFieldsState, setIteroFieldsLocal] = React.useState<IteroFields>({} as IteroFields);

    const iteroFields = {
        iteroDoctorId: iteroFieldsState.iteroDoctorId ?? initialIteroDoctorId,
        wantsPreferenceReview: iteroFieldsState.wantsPreferenceReview ?? initialWantsPreferenceReview,
        setDandyAsPreferredLabStatus:
            iteroFieldsState.setDandyAsPreferredLabStatus ?? initialSetDandyAsPreferredLabStatus,
        shouldImmediatelyPlaceOrder: iteroFieldsState.shouldImmediatelyPlaceOrder ?? initialShouldImmediatelyPlaceOrder,
    };

    const [submitIteroUser, { loading: submittingIteroUser }] = useMutation(UpsertIteroUserMutation);
    const [submitIteroOnboarding, { loading: submittingIteroOnboarding }] = useMutation(UpsertIteroOnboardingMutation);

    // If a field has changed, we want to call the mutation related to that field.
    const submitIteroFields = async () => {
        if (
            memberIdParam &&
            (iteroFields.iteroDoctorId !== initialIteroDoctorId ||
                iteroFields.shouldImmediatelyPlaceOrder !== initialShouldImmediatelyPlaceOrder)
        ) {
            void submitIteroUser({
                variables: {
                    itero_doctor_id: iteroFields.iteroDoctorId,
                    should_immediately_place_order: iteroFields.shouldImmediatelyPlaceOrder,
                    staff_id: memberIdParam,
                },
            });
        }
        if (
            memberIdParam &&
            (iteroFields.wantsPreferenceReview !== initialWantsPreferenceReview ||
                iteroFields.setDandyAsPreferredLabStatus !== initialSetDandyAsPreferredLabStatus)
        ) {
            void submitIteroOnboarding({
                variables: {
                    input: {
                        wants_preference_review: iteroFields.wantsPreferenceReview,
                        set_dandy_as_preferred_lab_status: iteroFields.setDandyAsPreferredLabStatus,
                    },
                    staff_member_id: memberIdParam,
                },
            });
        }
    };

    return {
        hasOnboardingFields,
        iteroFields,
        setIteroFields: (key: string, value: string | boolean | LabsGqlSetDandyAsPreferredEnum) => {
            setIteroFieldsLocal(prev => ({ ...prev, [key]: value }));
        },
        submitIteroFields,
        submittingIteroMutations: submittingIteroUser || submittingIteroOnboarding,
    };
};
