/* eslint-disable max-lines */
import type {
    LabsGqlUnknownItemInput,
    LabsGqlICustomFieldSubmissionInput,
    LabsGqlCartItemV2InputBySku,
    LabsGqlCartImplantBridgeItemInput,
    LabsGqlCartImplantItemInput,
    LabsGqlCartImplantToothGroupInput,
} from '../../graphql-types.generated';
import type {
    ICartItemV2DTO,
    ICartItemV2OfType,
    ICartImplantToothGroup,
    ICartImplantItem,
    ICartImplantBridgeItem,
    ICustomFieldSubmission,
} from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import _ from 'lodash';

type ExtractMaybeType<T> = T extends null ? never : T;

// unfortunately bc item interfaces and their gql input counterparts are not intercompatible from either to the other,
// we have these validation functions that return a "converted" item rather than a boolean indicating `item is GqlType`

// all variables are specifically extracted and returned to ensure no extra properties remain in the graphql query
// because, for example, if a checkout item is passed in, then using ...item retains things like item_index that aren't valid for the input
// when updating CartItemV2InputUtils class, no variable should be spread except for the return value from other functions within the class

// All enum casts are safe as they're only done when the GQL type is directly created from the shared enum type

export class CartItemV2InputUtils {
    static convertPreferenceInput(prefs: ICustomFieldSubmission[]): LabsGqlICustomFieldSubmissionInput[] {
        return prefs.map(pref =>
            _.pick(pref, [
                'field_id',
                'display_name',
                'value',
                'price_cents',
                'override_price_cents',
                'override_price_reason',
            ]),
        );
    }

    // helper function that returns a filter fn to make types easier
    private static filterFn<SKU extends LabOrderItemSKUType>(sku: SKU) {
        return (item: ICartItemV2DTO): item is ICartItemV2OfType<SKU> => CartItemV2Utils.itemIsType(item, sku);
    }

    // we use unknown item input as the return type since it only inherits from the base with no additional properties
    private static validateBaseItem(item: ICartItemV2DTO): LabsGqlUnknownItemInput {
        const { item_notes, attachments, shades } = item;

        return {
            item_notes,
            attachments: attachments.map(a => _.pick(a, ['filepath', 'custom_attachment_id'])),
            shades: shades.map(s => _.pick(s, ['name', 'value'])),
            preference_fields: CartItemV2InputUtils.convertPreferenceInput(item.preference_fields),
        };
    }

    private static mapCartImplantGroupInput(unit: ICartImplantToothGroup): LabsGqlCartImplantToothGroupInput {
        return {
            ...unit,
            implant_metadata: _.pick(unit.implant_metadata, ['manufacturer', 'system', 'connection_size']),
        };
    }

    private static mapCartImplantItemInput(item: ICartImplantItem): LabsGqlCartImplantItemInput {
        return {
            ...CartItemV2InputUtils.validateBaseItem(item),
            unit: CartItemV2InputUtils.mapCartImplantGroupInput(item.unit),
        };
    }

    private static mapCartImplantBridgeItemInput(item: ICartImplantBridgeItem): LabsGqlCartImplantBridgeItemInput {
        return {
            ...CartItemV2InputUtils.validateBaseItem(item),
            implants: item.implants.map(CartItemV2InputUtils.mapCartImplantGroupInput),
            restoratives: item.restoratives,
        };
    }

    private static getCartItemV2InputBySKUOrFail(items: ICartItemV2DTO[]): LabsGqlCartItemV2InputBySku {
        const itemsBySKU = _.groupBy(items, i => i.sku);

        const getInputItems = <
            Property extends keyof LabsGqlCartItemV2InputBySku,
            SKU extends LabOrderItemSKUType = LabOrderItemSKUType,
            Output extends ExtractMaybeType<Required<LabsGqlCartItemV2InputBySku>[Property]>[number] = ExtractMaybeType<
                Required<LabsGqlCartItemV2InputBySku>[Property]
            >[number],
        >(
            sku: SKU,
            cb: (item: ICartItemV2OfType<SKU>) => Output,
        ): Output[] | undefined => {
            return itemsBySKU[sku]?.filter(CartItemV2InputUtils.filterFn(sku)).map(cb);
        };

        // even though we know the items are grouped properly,
        // we still have to use .filter on each one for the sake of ts
        return {
            implant_items: getInputItems(LabOrderItemSKUType.Implant, CartItemV2InputUtils.mapCartImplantItemInput),
            implant_bridge_items: getInputItems(
                LabOrderItemSKUType.ImplantBridge,
                CartItemV2InputUtils.mapCartImplantBridgeItemInput,
            ),
        };
    }

    static getCartItemV2InputBySKU(items: ICartItemV2DTO[]): LabsGqlCartItemV2InputBySku | null {
        try {
            return CartItemV2InputUtils.getCartItemV2InputBySKUOrFail(items);
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            console.error('Failed to get cart item input by SKU on', items, 'due to', e);
            return null;
        }
    }
}
