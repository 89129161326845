import { MouseLabel, UndercutHighlight, useHeatmapHighlight } from '../ModelViewer';
import { ScanReviewMarginMarkingTool } from './MarginMarking';
import type { ScanReviewAppState } from './ScanReviewApp.types';
import type { ScanReviewAppStateManager } from './ScanReviewAppStateManager';
import { ScanReviewPanel } from './ScanReviewPanel';
import { ScanReviewMode, ScanReviewPanelType, ScanReviewViewType } from './ScanReviewViewTypes';
import { ToothUtils } from '@orthly/items';
import { Jaw } from '@orthly/shared-types';
import { Box, styled, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const PanelContainer = styled(Box)({
    borderRadius: 8,
    margin: '4px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    backgroundColor: FlossPalette.WHITE,
    minHeight: 0,
    minWidth: 0,
});

const ScanReviewPanelWrapper: React.VFC<{
    panelType: ScanReviewPanelType;
    scanReviewAppState: ScanReviewAppState;
    scanReviewAppStateManager: ScanReviewAppStateManager;
}> = ({ panelType, scanReviewAppState, scanReviewAppStateManager }) => {
    const viewManager = scanReviewAppStateManager.viewManager.getViewManagerForPanelType(panelType);
    const marginMarkingController = scanReviewAppState.marginLineToolControllers.get(panelType);
    const marginLineLiveObjectsProvider = scanReviewAppState.marginLineLiveObjectProviders.get(panelType);
    const insertionAxisLiveObjectsProvider = scanReviewAppState.insertionAxisLiveObjectProviders.get(panelType);

    const direction = scanReviewAppState.currentInsertionAxis?.direction;
    const reversedInsertionAxis = React.useMemo(() => {
        return direction?.clone()?.negate();
    }, [direction]);

    const { updateTextRef, setHeatmapHighlight: setUndercutHighlight } = useHeatmapHighlight();

    const jaw =
        scanReviewAppState.currentInsertionAxis && ToothUtils.toothIsLower(scanReviewAppState.currentInsertionAxis.unn)
            ? Jaw.LOWER
            : Jaw.UPPER;

    const mesh =
        jaw && jaw === Jaw.LOWER
            ? scanReviewAppStateManager.scene.getPartialSceneForPanelType(panelType).lowerJaw?.scanMesh
            : scanReviewAppStateManager.scene.getPartialSceneForPanelType(panelType).upperJaw?.scanMesh;

    const undercutToolController = scanReviewAppState.undercutToolControllers.get(panelType);

    if (
        scanReviewAppState.mode === ScanReviewMode.MarginMarking &&
        marginLineLiveObjectsProvider &&
        marginMarkingController &&
        viewManager.controls !== null
    ) {
        return (
            <ScanReviewPanel viewManager={viewManager} key={`scan-review-${panelType}`}>
                {scanReviewAppState.insertionAxisIsVisible && insertionAxisLiveObjectsProvider && (
                    <primitive object={insertionAxisLiveObjectsProvider.group} />
                )}
                <primitive object={marginLineLiveObjectsProvider.group} />
                <ScanReviewMarginMarkingTool toolController={marginMarkingController} controls={viewManager.controls} />
            </ScanReviewPanel>
        );
    } else if (scanReviewAppState.mode === ScanReviewMode.Undercut && undercutToolController) {
        return (
            <>
                <ScanReviewPanel
                    viewManager={scanReviewAppStateManager.viewManager.getViewManagerForPanelType(panelType)}
                    key={'undercut-view'}
                >
                    <primitive object={undercutToolController.group} />;
                    {scanReviewAppState.marginIsVisible && marginLineLiveObjectsProvider && (
                        <primitive object={marginLineLiveObjectsProvider.group} />
                    )}
                    {mesh && undercutToolController && reversedInsertionAxis && (
                        <UndercutHighlight
                            targetModel={{ mesh }}
                            shadowingMeshes={[mesh]}
                            insertionAxis={reversedInsertionAxis}
                            onHighlight={setUndercutHighlight}
                            showOnlyEscapeDistance
                        />
                    )}
                </ScanReviewPanel>
                <MouseLabel forceUpdateRef={updateTextRef} />
            </>
        );
    }
    return (
        <ScanReviewPanel viewManager={viewManager} key={`scan-review-${panelType}`}>
            {scanReviewAppState.marginIsVisible && marginLineLiveObjectsProvider && (
                <primitive object={marginLineLiveObjectsProvider.group} />
            )}
            {scanReviewAppState.insertionAxisIsVisible && insertionAxisLiveObjectsProvider && (
                <primitive object={insertionAxisLiveObjectsProvider.group} />
            )}
        </ScanReviewPanel>
    );
};

const ScanReviewSingleView: React.VFC<{
    scanReviewAppState: ScanReviewAppState;
    scanReviewAppStateManager: ScanReviewAppStateManager;
}> = ({ scanReviewAppState, scanReviewAppStateManager }) => {
    return (
        <PanelContainer sx={{ gridColumn: '1/7', gridRow: '1/3' }}>
            <ScanReviewPanelWrapper
                panelType={ScanReviewPanelType.Front}
                scanReviewAppState={scanReviewAppState}
                scanReviewAppStateManager={scanReviewAppStateManager}
            />
        </PanelContainer>
    );
};

const ScanReviewSideBySideView: React.VFC<{
    scanReviewAppState: ScanReviewAppState;
    scanReviewAppStateManager: ScanReviewAppStateManager;
}> = ({ scanReviewAppState, scanReviewAppStateManager }) => {
    return (
        <>
            <PanelContainer sx={{ gridColumn: '1/4', gridRow: '1/3' }}>
                <ScanReviewPanelWrapper
                    panelType={ScanReviewPanelType.Upper}
                    scanReviewAppState={scanReviewAppState}
                    scanReviewAppStateManager={scanReviewAppStateManager}
                />
            </PanelContainer>
            <PanelContainer sx={{ gridColumn: '4/7', gridRow: '1/3' }}>
                <ScanReviewPanelWrapper
                    panelType={ScanReviewPanelType.Lower}
                    scanReviewAppState={scanReviewAppState}
                    scanReviewAppStateManager={scanReviewAppStateManager}
                />
            </PanelContainer>
        </>
    );
};

export const ScanReviewCompleteViewInner: React.VFC<{
    scanReviewAppState: ScanReviewAppState;
    scanReviewAppStateManager: ScanReviewAppStateManager;
}> = ({ scanReviewAppState, scanReviewAppStateManager }) => {
    return (
        <>
            <PanelContainer sx={{ gridColumn: '1/4', gridRow: '1/2' }}>
                <ScanReviewPanelWrapper
                    panelType={ScanReviewPanelType.Upper}
                    scanReviewAppState={scanReviewAppState}
                    scanReviewAppStateManager={scanReviewAppStateManager}
                />
            </PanelContainer>
            <PanelContainer sx={{ gridColumn: '4/7', gridRow: '1/2' }}>
                <ScanReviewPanelWrapper
                    panelType={ScanReviewPanelType.Lower}
                    scanReviewAppState={scanReviewAppState}
                    scanReviewAppStateManager={scanReviewAppStateManager}
                />
            </PanelContainer>
            <PanelContainer sx={{ gridColumn: '1/7', gridRow: '2/3' }}>
                <ScanReviewPanelWrapper
                    panelType={ScanReviewPanelType.Front}
                    scanReviewAppState={scanReviewAppState}
                    scanReviewAppStateManager={scanReviewAppStateManager}
                />
            </PanelContainer>
        </>
    );
};

export const ScanReviewCompleteView: React.VFC<{
    scanReviewAppState: ScanReviewAppState;
    scanReviewAppStateManager: ScanReviewAppStateManager;
}> = ({ scanReviewAppState, scanReviewAppStateManager }) => {
    const viewType = scanReviewAppState.viewType;
    return (
        <Box
            style={{
                flex: 'auto',
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gridTemplateRows: 'repeat(2, 1fr)',
                minHeight: 0,
                minWidth: 0,
                backgroundColor: FlossPalette.DARK_TAN,
            }}
        >
            {viewType === ScanReviewViewType.Single && (
                <ScanReviewSingleView
                    scanReviewAppState={scanReviewAppState}
                    scanReviewAppStateManager={scanReviewAppStateManager}
                />
            )}
            {viewType === ScanReviewViewType.SideBySide && (
                <ScanReviewSideBySideView
                    scanReviewAppState={scanReviewAppState}
                    scanReviewAppStateManager={scanReviewAppStateManager}
                />
            )}
            {viewType === ScanReviewViewType.Complete && (
                <ScanReviewCompleteViewInner
                    scanReviewAppState={scanReviewAppState}
                    scanReviewAppStateManager={scanReviewAppStateManager}
                />
            )}
        </Box>
    );
};
