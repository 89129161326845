import type { MorphPointsData } from '@orthly/forceps';
import React from 'react';
import * as THREE from 'three';

// get more vibrant and appealing colors
// https://gist.github.com/bendc/76c48ce53299e6078a76
const randomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const vibrantRandomColor = () => {
    const h = randomInt(0, 360);
    const s = randomInt(42, 98);
    const l = randomInt(40, 90);
    return `hsl(${h},${s}%,${l}%)`;
};

export const MorphPointsVisualizer: React.FC<{ morphPoints: MorphPointsData[] }> = ({ morphPoints }) => {
    const visualGroupRef = React.useRef<THREE.Group>();

    React.useEffect(() => {
        const objectsArray: THREE.Mesh[] = [];
        const visualGroup = visualGroupRef.current;
        morphPoints.forEach(pts => {
            const color = vibrantRandomColor();
            pts.points.forEach(pt => {
                const geometry = new THREE.SphereBufferGeometry(0.35, 32, 32);
                const material = new THREE.MeshBasicMaterial({ color: color });
                const sphere = new THREE.Mesh(geometry, material);
                sphere.position.set(pt.x, pt.y, pt.z);
                objectsArray.push(sphere);
                visualGroup?.add(sphere);
            });
        });
        return () => {
            objectsArray.forEach(obj => {
                visualGroup?.remove(obj);
                obj.geometry.dispose();
            });
        };
    }, [morphPoints]);

    return <group ref={visualGroupRef} />;
};
