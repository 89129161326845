import type {
    LabsGqlThreeshapeDmeDtoFragment,
    LabsGqlCreateThreeshapeDmeMutationVariables,
    LabsGqlUpdateThreeshapeDmeFilePathMutationVariables,
} from '@orthly/graphql-operations';
import {
    useListThreeshapeDmesQuery,
    useCreateThreeshapeDmeMutation,
    useArchiveThreeshapeDmeMutation,
    useUnarchiveThreeshapeDmeMutation,
    useUpdateThreeshapeDmeFilePathMutation,
} from '@orthly/graphql-react';
import { MUITable } from '@orthly/mui-table';
import { getFullStoragePath, DesignStorageConfigs } from '@orthly/shared-types';
import {
    useChangeSubmissionFn,
    RootActionDialog,
    QuickForm,
    OrthlyBrowserConfig,
    LoadBlocker,
    PencilOutlinedIcon,
    DownloadIcon,
} from '@orthly/ui';
import { FlossPalette, Button, Grid, IconButton, Tooltip, ToggleOffIcon, ToggleOnIcon } from '@orthly/ui-primitives';
import { FileUploaderBulk, useFirebaseFileDownload } from '@orthly/veneer';
import React from 'react';

type CreateDmeVars = LabsGqlCreateThreeshapeDmeMutationVariables['data'];

interface CreateThreeshapeDmeProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onSuccess: () => Promise<unknown>;
}

const CreateThreeshapeDme: React.FC<CreateThreeshapeDmeProps> = ({ onSuccess, open, setOpen }) => {
    const [uploadedPath, setUploadedPath] = React.useState<string | undefined>(undefined);

    const [submitMtn] = useCreateThreeshapeDmeMutation();
    const mtnSubmitter = (data: CreateDmeVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [CreateDmeVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Threeshape DME created!', {}],
        onSuccess: () => {
            setOpen(false);
            void onSuccess();
        },
    });

    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, DesignStorageConfigs.threeshapeDME);

    return (
        <RootActionDialog
            title={'Create Threeshape DME'}
            open={open}
            setOpen={setOpen}
            loading={submitting}
            buttonText={'Create Threeshape DME'}
            CustomButton={() => null}
            content={
                <>
                    <FileUploaderBulk
                        autoSubmit
                        elevation={0}
                        storagePathConfig={storagePathConfig}
                        paperStyle={{ padding: '0 0 8px' }}
                        onComplete={results => {
                            const path = results[0]?.uploadedPath;
                            if (path) {
                                setUploadedPath(path);
                            }
                        }}
                        onReset={() => {
                            setUploadedPath(undefined);
                        }}
                        prependTimestampToFilename={true}
                    />

                    <QuickForm<Omit<CreateDmeVars, 'file_path'>>
                        fields={{
                            name: { type: 'text' },
                        }}
                        disabled={!uploadedPath}
                        initialValues={{}}
                        onSubmit={async formResult => {
                            if (!uploadedPath) {
                                return;
                            }

                            await submit({
                                name: formResult.name,
                                file_path: uploadedPath,
                            });
                        }}
                    />
                </>
            }
        />
    );
};

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useArchive(dme_id: string, refetch: () => Promise<any>) {
    const [submitMtn] = useArchiveThreeshapeDmeMutation();
    const mtnSubmitter = () => submitMtn({ variables: { data: { dme_id } } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Threeshape DME archived!', {}],
        onSuccess: () => refetch(),
    });

    return { submit, submitting };
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useUnarchive(dme_id: string, refetch: () => Promise<any>) {
    const [submitMtn] = useUnarchiveThreeshapeDmeMutation();
    const mtnSubmitter = () => submitMtn({ variables: { data: { dme_id } } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Threeshape DME unarchived!', {}],
        onSuccess: () => refetch(),
    });

    return { submit, submitting };
}

interface ThreeshapeDmesArchiveToggleProps {
    dme: LabsGqlThreeshapeDmeDtoFragment;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetch: () => Promise<any>;
}

const ThreeshapeDmesArchiveToggle: React.VFC<ThreeshapeDmesArchiveToggleProps> = ({ dme, refetch }) => {
    const { submit: archiveSubmit, submitting: archiveSubmitting } = useArchive(dme.id, refetch);
    const { submit: unarchiveSubmit, submitting: unarchiveSubmitting } = useUnarchive(dme.id, refetch);
    const isLoading = archiveSubmitting || unarchiveSubmitting;

    return (
        <Tooltip title={dme.is_archived ? `Unarchive DME` : 'Archive DME'}>
            <IconButton
                onClick={dme.is_archived ? unarchiveSubmit : archiveSubmit}
                disabled={isLoading}
                style={{ color: dme.is_archived ? FlossPalette.DARK_TAN : FlossPalette.STAR_GRASS }}
            >
                <LoadBlocker blocking={isLoading} loader={`circular`}>
                    {dme.is_archived ? <ToggleOffIcon /> : <ToggleOnIcon />}
                </LoadBlocker>
            </IconButton>
        </Tooltip>
    );
};

interface ThreeshapeDmeDownloadProps {
    dme: Pick<LabsGqlThreeshapeDmeDtoFragment, 'file_path' | 'name'>;
}

const ThreeshapeDmeDownload: React.VFC<ThreeshapeDmeDownloadProps> = ({ dme }) => {
    // TODO: EPDPLT-4635 - Firebase Storage Migration
    const download = useFirebaseFileDownload(dme.file_path, `${dme.name}`);

    return (
        <Tooltip title={'Download DME'}>
            <IconButton onClick={() => download.execute()}>
                <DownloadIcon />
            </IconButton>
        </Tooltip>
    );
};

type UpdateDmePathVars = LabsGqlUpdateThreeshapeDmeFilePathMutationVariables['data'];

interface ThreeshapeDmeEditFilePathProps {
    dmeId: string;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetch: () => Promise<any>;
}

const ThreeshapeDmeEditFilePath: React.VFC<ThreeshapeDmeEditFilePathProps> = ({ dmeId, refetch }) => {
    const [uploadedPath, setUploadedPath] = React.useState<string | undefined>(undefined);
    const [open, setOpen] = React.useState<boolean>(false);

    const [submitMtn] = useUpdateThreeshapeDmeFilePathMutation();
    const mtnSubmitter = (data: UpdateDmePathVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [UpdateDmePathVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Threeshape DME updated!', {}],
        onSuccess: () => {
            setOpen(false);
            void refetch();
        },
    });

    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, DesignStorageConfigs.threeshapeDME);

    return (
        <RootActionDialog
            title={'Update Threeshape DME'}
            open={open}
            setOpen={setOpen}
            loading={submitting}
            CustomButton={() => (
                <Tooltip title={'Edit DME'}>
                    <IconButton onClick={() => setOpen(true)}>
                        <PencilOutlinedIcon />
                    </IconButton>
                </Tooltip>
            )}
            content={
                <>
                    <FileUploaderBulk
                        autoSubmit
                        elevation={0}
                        storagePathConfig={storagePathConfig}
                        paperStyle={{ padding: '0 0 8px' }}
                        onComplete={results => {
                            const path = results[0]?.uploadedPath;
                            if (path) {
                                setUploadedPath(path);
                            }
                        }}
                        onReset={() => {
                            setUploadedPath(undefined);
                        }}
                        prependTimestampToFilename={true}
                    />
                    <Button
                        variant={'primary'}
                        onClick={() => {
                            if (!uploadedPath) {
                                return;
                            }

                            void submit({ dme_id: dmeId, file_path: uploadedPath });
                        }}
                        disabled={submitting || !uploadedPath}
                        fullWidth
                    >
                        Update DME
                    </Button>
                </>
            }
        />
    );
};

export const ThreeshapeDmesRoot: React.VFC = () => {
    const { data, refetch } = useListThreeshapeDmesQuery();
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    const allReasons = data?.listThreeshapeDmes || [];
    return (
        <Grid container>
            <MUITable<LabsGqlThreeshapeDmeDtoFragment>
                title={'Threeshape DMEs'}
                data={allReasons}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [
                        { icon: 'refresh', position: 'toolbar', onClick: () => refetch().catch(console.error) },
                        { icon: 'add', position: 'toolbar', onClick: () => setCreateOpen(true), tooltip: 'Create' },
                    ],
                }}
                rowOptions={{ rowHover: true }}
                eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                columns={[
                    { name: 'Name', render: r => r.name },
                    { name: 'Current Version', render: r => r.current_version },
                    {
                        name: 'archived',
                        render: r => r.is_archived,
                        type: 'boolean',
                        filterOptions: { defaultValues: ['false'], exact: false, type: 'dropdown' },
                    },
                    { name: 'Created At', render: 'created_at', type: 'datetime' },
                    {
                        name: `Actions`,
                        render: dme => (
                            <>
                                <ThreeshapeDmesArchiveToggle dme={dme} refetch={refetch} />
                                <ThreeshapeDmeEditFilePath dmeId={dme.id} refetch={refetch} />
                                <ThreeshapeDmeDownload dme={dme} />
                            </>
                        ),
                    },
                ]}
            />
            <CreateThreeshapeDme open={createOpen} setOpen={setCreateOpen} onSuccess={() => refetch()} />
        </Grid>
    );
};
