import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction } from '../state/GuidedWaxupState';
import { getSortedPresetConfigForOrder } from './useGuidedWaxupPresets.utils';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import React from 'react';

export const useGuidedWaxupPresets = (
    order: Pick<LabsGqlOrder, 'id' | 'items_v2'>,
    design: Pick<OrderDesignPreviewDesign_FragmentFragment, 'id' | 'doctor_review'> | null | undefined,
) => {
    const { id: designRevisionId, doctor_review: selectedReview } = design ?? {};

    const { value: organizeGuidedWaxupPresetsBySKU = false } = useFeatureFlag('organizeGuidedWaxupPresetsBySKU');
    const { setSelectedTab, selectedDesignRevisionAlreadyReviewed, guidedVideoWalkthroughUrl } =
        useGuidedWaxupContext();
    const overrideState = useGuidedWaxupAction('OVERRIDE_STATE');
    const presets = React.useMemo(
        () =>
            getSortedPresetConfigForOrder(
                order,
                selectedReview,
                organizeGuidedWaxupPresetsBySKU,
                guidedVideoWalkthroughUrl,
            ),
        [order, selectedReview, organizeGuidedWaxupPresetsBySKU, guidedVideoWalkthroughUrl],
    );

    React.useEffect(() => {
        overrideState({
            designRevisionId,
            presets: Object.fromEntries([
                ...(selectedReview?.annotations?.length
                    ? selectedReview.annotations.map(annotation => [
                          annotation.preset_type,
                          {
                              ...annotation,
                              structured_notes: annotation?.structured_notes?.map(({ category }) => category) ?? [],
                          },
                      ])
                    : presets.map(presetType => [presetType, {}])),
            ]),
        });
    }, [presets, designRevisionId, overrideState, selectedReview]);

    React.useEffect(() => {
        if (!design) {
            return;
        }

        const initialSelection = selectedDesignRevisionAlreadyReviewed
            ? LabsGqlGuidedWaxupPresetType.GeneralView
            : presets[0];

        if (initialSelection) {
            setSelectedTab(initialSelection);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [design, presets.length, selectedDesignRevisionAlreadyReviewed]);
};
