import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

export const GetNotificationLogsWithEvents_Query = graphql(`
    query GetNotificationLogsWithEvents(
        $page: Float!
        $rows_per_page: Float!
        $type: String!
        $doctor_preferences_id: String
    ) {
        getNotificationLogsWithEvents(
            page: $page
            rows_per_page: $rows_per_page
            type: $type
            doctor_preferences_id: $doctor_preferences_id
        ) {
            entities {
                id
                name
                type
                category
                status
                events
                notification_log_created_at
                recipients
            }
            totalCount
        }
    }
`);

export const useGetNotificationLogsWithEvents = (
    page: number,
    rows_per_page: number,
    activeTab: string,
    doctorPreferencesId?: string,
) => {
    const { data, loading, error, refetch } = useQuery(GetNotificationLogsWithEvents_Query, {
        variables: { page, rows_per_page, doctor_preferences_id: doctorPreferencesId, type: activeTab },
    });

    const returnData = data?.getNotificationLogsWithEvents;

    return {
        entities: returnData?.entities,
        totalCount: returnData?.totalCount,
        loading,
        error: error?.message,
        refetch,
    };
};
