import { GradientDirectionControl } from '../../Controls/GradientDirection';
import { MeshPainterControl } from '../../Controls/MeshPainter';
import { controlsRefAtom, rendererRefAtom, sceneRefAtom, store } from '../../store/store';
import { cameraControlsDisabledAtom } from '../../store/store';
import { PainterTrackballControls } from '../../utils/PainterTrackballControls';
import { applyD65Illumination } from '../../utils/UseLights';
import { styled } from '@orthly/ui-primitives';
import React from 'react';
import { Scene, PerspectiveCamera, WebGLRenderer } from 'three';

const THREERoot: React.FC = React.memo(() => {
    const mountRef = React.useRef<HTMLDivElement>(null);

    const Root = styled('div')({
        flex: 1,
        height: '100vh',
        overflow: 'hidden',
    });

    React.useEffect(() => {
        const mount = mountRef.current;
        if (!mount) {
            return;
        }

        console.log('init Scene');

        // Scene setup
        const scene = new Scene();
        store.set(sceneRefAtom, scene);
        const camera = new PerspectiveCamera(75, mount.clientWidth / mount.clientHeight, 0.1, 1000);
        const renderer = new WebGLRenderer({ alpha: true, antialias: true });
        store.set(rendererRefAtom, renderer);

        // scene.add(new AxesHelper(50));

        const canvas = renderer.domElement;
        canvas.style.objectFit = 'contain';
        canvas.style.width = '100%';
        canvas.style.height = '100%';
        canvas.style.display = 'block'; // prevents small gap below the canvas
        mount.appendChild(canvas);
        const disposeGradientDirectionControl = GradientDirectionControl(scene, camera, renderer);
        const disposeMeshPainterControls = MeshPainterControl(scene, camera, renderer);

        // scene.rotation.x = - Math.PI / 2;

        const controls = new PainterTrackballControls(camera, renderer.domElement);
        controls.update();
        store.set(controlsRefAtom, controls);

        applyD65Illumination(scene);
        // Render on controls change
        const onChange = () => {
            renderer.render(scene, camera);
        };
        controls.addEventListener('change', onChange);

        // Subscribe to cameraControlsDisabledAtom
        const unsubscribe = store.sub(cameraControlsDisabledAtom, () => {
            const isDisabled = store.get(cameraControlsDisabledAtom);
            if (isDisabled && controls.enabled) {
                controls.disable();
                controls.state = -1;
            }
            if (!isDisabled && !controls.enabled) {
                controls.enable();
            }
        });

        camera.position.z = 40;

        // Animation loop
        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
            controls.update();
        }

        animate();

        // Handle resize with ResizeObserver
        const resizeObserver = new ResizeObserver(() => {
            camera.aspect = mount.clientWidth / mount.clientHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(mount.clientWidth, mount.clientHeight, false);
            renderer.render(scene, camera);
            controls.handleResize();
        });
        resizeObserver.observe(mount);

        renderer.render(scene, camera);

        // Cleanup on unmount
        return () => {
            unsubscribe();
            resizeObserver.disconnect();
            disposeGradientDirectionControl();
            disposeMeshPainterControls();
            controls.removeEventListener('change', onChange);
            mount.removeChild(renderer.domElement);
        };
    }, []);

    return <Root ref={mountRef} id={`threeRoot${Date.now()}`} />;
});

export default THREERoot;
