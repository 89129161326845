import type {
    LabsGqlCreatePartnerMutationVariables,
    LabsGqlOrganizationWithDescendantsFragment,
    LabsGqlUserDtoFragment,
} from '@orthly/graphql-operations';
import {
    useGetOrgPrimaryAdminQuery,
    useListRetainerUsersQuery,
    useCreatePartnerMutation,
    useListOrgSummariesQuery,
} from '@orthly/graphql-react';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import {
    LoadBlocker,
    SimpleSelect,
    SimpleInput,
    SimplePhoneInput,
    useChangeSubmissionFn,
    RootActionDialog,
} from '@orthly/ui';
import { Button, Grid, InputAdornment } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type CreatePracticeVars = Omit<
    LabsGqlCreatePartnerMutationVariables['data'],
    'password' | 'confirm_password' | 'doctor_names' | 'prices'
>;

interface CreatePracticeFormProps {
    parentOrg?: LabsGqlOrganizationWithDescendantsFragment;
    submit: (vars: CreatePracticeVars) => Promise<void>;
}

interface CreatePracticeFormState {
    parentOrgId?: string;
    organizationName?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    salesforceAccountId?: string;
    pandadocContractId?: string;
    minimumCommitment: number;
}

function formIsComplete(state: CreatePracticeFormState): boolean {
    return (
        !!state.organizationName &&
        !!state.email &&
        !!state.firstName &&
        !!state.lastName &&
        !!state.phoneNumber &&
        !!state.salesforceAccountId &&
        !!state.pandadocContractId
    );
}

const CreatePracticeForm: React.FC<CreatePracticeFormProps> = props => {
    const { parentOrg, submit } = props;
    const { data: parentOrgsData, loading: parentOrgsLoading } = useListOrgSummariesQuery({
        variables: { filter: { type: LabsGqlOrganizationType.Parent } },
    });
    const { data: usersData } = useListRetainerUsersQuery();
    const parentOrgs = parentOrgsData?.listOrganizationSummaries ?? [];
    const usersByPhoneNumber: Record<string, LabsGqlUserDtoFragment[]> = _.groupBy(
        usersData?.listUsers ?? [],
        u => u.phone_number,
    );
    const [formState, setFormState] = React.useState<CreatePracticeFormState>({
        parentOrgId: parentOrg?.id,
        minimumCommitment: 1000,
    });
    const { loading: primaryAdminLoading, refetch: refetchPrimaryAdmin } = useGetOrgPrimaryAdminQuery({
        variables: { id: formState.parentOrgId ?? '' },
        skip: !formState.parentOrgId,
        onCompleted: data => {
            const { user: adminUser } = data.getOrgPrimaryAdmin;
            setFormState({
                ...formState,
                email: adminUser.email,
                firstName: adminUser.first_name,
                lastName: adminUser.last_name,
                phoneNumber: adminUser.phone_number ?? undefined,
            });
        },
    });
    const onSetParentOrgId = (value?: string): void => {
        setFormState({ ...formState, parentOrgId: value });
        if (value) {
            void refetchPrimaryAdmin({ id: value }).then(result => {
                const { user: adminUser } = result.data.getOrgPrimaryAdmin;
                setFormState({
                    ...formState,
                    email: adminUser.email,
                    firstName: adminUser.first_name,
                    lastName: adminUser.last_name,
                    phoneNumber: adminUser.phone_number ?? undefined,
                });
            });
        }
    };
    const onPhoneNumberChange = (value?: string): void => {
        if (typeof value === 'string') {
            const cleanedPhoneNumber = PhoneNumberUtils.cleanPhoneNumber(value);
            if (cleanedPhoneNumber) {
                const user = _.first(usersByPhoneNumber[cleanedPhoneNumber]);
                if (user) {
                    setFormState({
                        ...formState,
                        phoneNumber: value,
                        email: user.email,
                        firstName: user.first_name,
                        lastName: user.last_name,
                    });
                    return;
                }
            }
        }
        setFormState({ ...formState, phoneNumber: value });
    };
    const onSubmit = async (): Promise<void> => {
        const {
            parentOrgId,
            organizationName,
            email,
            firstName,
            lastName,
            phoneNumber,
            minimumCommitment,
            salesforceAccountId,
            pandadocContractId,
        } = formState;
        await submit({
            parent_id: parentOrgId,
            legal_business_name: organizationName ?? '',
            email: email ?? '',
            first_name: firstName ?? '',
            last_name: lastName ?? '',
            phone_number: phoneNumber ?? '',
            salesforce_account_id: salesforceAccountId ?? '',
            pandadoc_contract_id: pandadocContractId ?? '',
            labs_monthly_prepay_amt_dollars: minimumCommitment,
        });
    };
    return (
        <LoadBlocker blocking={parentOrgsLoading || primaryAdminLoading}>
            <Grid container>
                <Grid container style={{ padding: '12px 0' }} direction={'column'}>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleSelect
                            label={'Add to organization (optional)'}
                            placeholder={'None'}
                            value={formState.parentOrgId}
                            onChange={onSetParentOrgId}
                            options={parentOrgs.map(org => ({
                                value: org.id,
                                label: org.name,
                            }))}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Organization name'}
                            value={formState.organizationName}
                            onChange={value => setFormState({ ...formState, organizationName: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimplePhoneInput
                            fullWidth
                            label={'Phone number'}
                            value={formState.phoneNumber}
                            onChange={onPhoneNumberChange}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Email'}
                            value={formState.email}
                            onChange={value => setFormState({ ...formState, email: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'First name'}
                            value={formState.firstName}
                            onChange={value => setFormState({ ...formState, firstName: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Last name'}
                            value={formState.lastName}
                            onChange={value => setFormState({ ...formState, lastName: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Minimum commitment'}
                            value={`${formState.minimumCommitment}`}
                            TextFieldProps={{
                                type: 'number',
                                inputProps: { min: 0 },
                                InputProps: { startAdornment: <InputAdornment position={'start'}>$</InputAdornment> },
                            }}
                            onChange={value => {
                                const parsed = parseFloat(value ?? '0');
                                setFormState({
                                    ...formState,
                                    minimumCommitment: Math.max(0, isNaN(parsed) ? 0 : parsed),
                                });
                            }}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Salesforce ID'}
                            value={formState.salesforceAccountId}
                            onChange={value => setFormState({ ...formState, salesforceAccountId: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Pandadoc Contract ID'}
                            value={formState.pandadocContractId}
                            onChange={value => setFormState({ ...formState, pandadocContractId: value })}
                        />
                    </Grid>
                </Grid>
                <Button fullWidth variant={'primary'} onClick={onSubmit} disabled={!formIsComplete(formState)}>
                    Submit
                </Button>
            </Grid>
        </LoadBlocker>
    );
};

interface CreatePracticeActionProps {
    parentOrg?: LabsGqlOrganizationWithDescendantsFragment;
    onSubmit: () => Promise<void>;
}

export const CreatePracticeAction: React.FC<CreatePracticeActionProps> = props => {
    const { parentOrg, onSubmit } = props;
    const [submitMtn] = useCreatePartnerMutation();
    const mtnSubmitter = (data: CreatePracticeVars) =>
        submitMtn({
            variables: { data: { ...data, doctor_names: [], prices: [], password: null, confirm_password: null } },
        });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [CreatePracticeVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Practice successfully created', {}],
    });
    return (
        <RootActionDialog
            loading={submitting}
            title={'Add new practice'}
            buttonText={'+ Add'}
            setOpen={setOpen}
            open={open}
            buttonProps={{ style: { maxWidth: 175 } }}
            content={
                <CreatePracticeForm
                    parentOrg={parentOrg}
                    submit={async (vars: CreatePracticeVars) => {
                        await submit(vars);
                        await onSubmit();
                    }}
                />
            }
        />
    );
};
