import { Text } from '@orthly/ui-primitives';
import React from 'react';

export const NotesSection: React.VFC<{ notes: string | null | undefined }> = ({ notes }) => {
    return (
        <Text variant={'caption'} sx={{ whiteSpace: 'pre-line' }}>
            {notes || 'Notes not provided'}
        </Text>
    );
};
