import {
    OrderListItemPartnerCell,
    OrderListItemItemsLabCell,
} from '../../../../components/OrderListItem/OrderListItemCells';
import { OrderListItemFlags } from '../../../../components/OrderListItem/OrderListItemFlags.graphql';
import { OrderListItemLayout, OrderListItemLoading } from '../../../../components/OrderListItem/OrderListItemLayout';
import { OrderListItemTitle } from '../../../../components/OrderListItem/OrderListItemTitle';
import { useOpenOrderDetailOps } from '../../../../utils/router/useOpenOrderDetail';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { OrderFormatUtils } from '@orthly/shared-types';
import { OrderDetailTrackerV2 } from '@orthly/veneer';
import { format } from 'date-fns';
import React from 'react';

const AdminOrdersListViewListItem_Fragment = graphql(`
    fragment AdminOrdersListViewListItem_Fragment on OrderAdminListViewEntry {
        id
        patient_name
        patient_order_count
        doctor_name
        items_description
        is_on_hold
        design_due_date
        order_tracker_entries(timezone_offset_minutes: $timezone_offset_minutes) {
            active
            alert
            infoTooltip
            style
            subtitle
            subtitleAttachment
            title
            infoTooltip
            style
        }
        flags {
            ...AdminOrderListItemFlag_Fragment
        }
    }
`);

interface OrdersListViewListItemContentProps {
    order: FragmentType<typeof AdminOrdersListViewListItem_Fragment>;
    practiceName: string;
    manufacturerName: string;
}

const OrdersListViewListItemContent: React.VFC<OrdersListViewListItemContentProps> = ({
    order,
    practiceName,
    manufacturerName,
}) => {
    const {
        id,
        patient_name,
        patient_order_count,
        doctor_name,
        items_description,
        is_on_hold,
        design_due_date,
        order_tracker_entries,
        flags,
    } = getFragmentData(AdminOrdersListViewListItem_Fragment, order);

    const openOrder = useOpenOrderDetailOps();

    return (
        <OrderListItemLayout
            onClick={e => openOrder(id, e)}
            rowTitle={`Order Id: ${id}`}
            title={
                <OrderListItemTitle
                    patient_name={patient_name}
                    patient_order_count={patient_order_count}
                    subtitle={
                        design_due_date ? `Design due ${format(new Date(design_due_date), 'MMM dd, ha')}` : undefined
                    }
                    flagContent={<OrderListItemFlags flags={flags} />}
                />
            }
            middleCellOne={
                <OrderListItemPartnerCell
                    partner_name={practiceName}
                    doctor_name={OrderFormatUtils.getDisplayedStaffMemberName(doctor_name)}
                />
            }
            middleCellTwo={
                <OrderListItemItemsLabCell manufacturer_name={manufacturerName} products={items_description} />
            }
            trackerCell={
                <OrderDetailTrackerV2
                    orderTrackerEntries={order_tracker_entries}
                    isPractice={false}
                    isLab={false}
                    showDesignPreviewETA={false}
                    isOnHold={is_on_hold}
                />
            }
        />
    );
};

interface OrdersListViewListItemProps {
    listItem?: OrdersListViewListItemContentProps;
}

export const OrdersListViewListItem: React.VFC<OrdersListViewListItemProps> = React.memo<OrdersListViewListItemProps>(
    props =>
        props.listItem ? <OrdersListViewListItemContent {...props.listItem} /> : <OrderListItemLoading columns={4} />,
);
