/* eslint-disable max-lines */
import { LabsGqlDesignOrderNoteCategory, LabsGqlGuidedWaxupPresetType } from '../../graphql-types.generated';
import type { LabsGqlDesignOrderDtoNote } from '../../graphql-types.generated';

// DESIGN_ORDER_NOTE_CATEGORY_LABELS lists human-readable labels for
// every category of structured design note
export const DESIGN_ORDER_NOTE_CATEGORY_LABELS: Record<LabsGqlDesignOrderNoteCategory, string> = {
    [LabsGqlDesignOrderNoteCategory.GingivalEmbrasure]: 'Gingival embrasure',
    [LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpen]: 'Need to open',
    [LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenMesial]: 'Mesial',
    [LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenDistal]: 'Distal',
    [LabsGqlDesignOrderNoteCategory.GingivalEmbrasureClose]:
        'Need to close (We will attempt to close the embrasures as much as possible without impeding your ability to seat this crown)',
    [LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseMesial]: 'Mesial',
    [LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseDistal]: 'Distal',
    [LabsGqlDesignOrderNoteCategory.IncisalEdgeDesign]: 'Incisal edge design',
    [LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignIncreaseLength]: 'Increase length',
    [LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignDecreaseLength]: 'Decrease Length',
    [LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShape]: 'Adjust incisal shape',
    [LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeRounded]: 'Make rounded',
    [LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapePointy]: 'Make pointy',
    [LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeSquared]: 'Make squared',
    [LabsGqlDesignOrderNoteCategory.Esthetics]: 'Esthetics / symmetry',
    [LabsGqlDesignOrderNoteCategory.EstheticsSoftenLineAngles]: 'Soften line angles',
    [LabsGqlDesignOrderNoteCategory.EstheticsEnhanceLineAngles]: 'Enhance line angles',
    [LabsGqlDesignOrderNoteCategory.EstheticsOther]: 'Other',
    [LabsGqlDesignOrderNoteCategory.MarginBuccal]: 'Buccal',
    [LabsGqlDesignOrderNoteCategory.MarginBuccalExtend]: 'Extend the margin',
    [LabsGqlDesignOrderNoteCategory.MarginBuccalAdjustOverextended]: 'Adjust overextended margin',
    [LabsGqlDesignOrderNoteCategory.MarginMesial]: 'Mesial',
    [LabsGqlDesignOrderNoteCategory.MarginMesialExtend]: 'Extend the margin',
    [LabsGqlDesignOrderNoteCategory.MarginMesialAdjustOverextended]: 'Adjust overextended margin',
    [LabsGqlDesignOrderNoteCategory.MarginDistal]: 'Distal',
    [LabsGqlDesignOrderNoteCategory.MarginDistalExtend]: 'Extend the margin',
    [LabsGqlDesignOrderNoteCategory.MarginDistalAdjustOverextended]: 'Adjust overextended margin',
    [LabsGqlDesignOrderNoteCategory.MarginLingual]: 'Lingual',
    [LabsGqlDesignOrderNoteCategory.MarginLingualExtend]: 'Extend the margin',
    [LabsGqlDesignOrderNoteCategory.MarginLingualAdjustOverextended]: 'Adjust overextended margin',
    [LabsGqlDesignOrderNoteCategory.Facial]: 'Facial',
    [LabsGqlDesignOrderNoteCategory.FacialReduceOvercontour]: 'Reduce overcontour (too bulky)',
    [LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourCervical]: 'Cervical',
    [LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourIncisal]: 'Incisal',
    [LabsGqlDesignOrderNoteCategory.FacialFillOutContour]: 'Fill out contour (too flat/thin)',
    [LabsGqlDesignOrderNoteCategory.FacialFillOutContourCervical]: 'Cervical',
    [LabsGqlDesignOrderNoteCategory.FacialFillOutContourIncisal]: 'Incisal',
    [LabsGqlDesignOrderNoteCategory.FacialMakeMorePronounced]: 'Make more pronounced',
    [LabsGqlDesignOrderNoteCategory.FacialMakeLessPronounced]: 'Make less pronounced',
    [LabsGqlDesignOrderNoteCategory.Lingual]: 'Lingual',
    [LabsGqlDesignOrderNoteCategory.LingualReduceOvercontour]: 'Reduce overcontour (too bulky)',
    [LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourCingulum]: 'Cingulum',
    [LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourIncisal]: 'Incisal',
    [LabsGqlDesignOrderNoteCategory.LingualFillOutContour]: 'Fill out contour (too flat/thin)',
    [LabsGqlDesignOrderNoteCategory.LingualFillOutContourCingulum]: 'Cingulum',
    [LabsGqlDesignOrderNoteCategory.LingualFillOutContourIncisal]: 'Incisal',
    [LabsGqlDesignOrderNoteCategory.Buccal]: 'Buccal',
    [LabsGqlDesignOrderNoteCategory.BuccalReduceOvercontour]: 'Reduce overcontour (too bulky)',
    [LabsGqlDesignOrderNoteCategory.BuccalFillOutContour]: 'Fill out contour (too flat/thin)',
    [LabsGqlDesignOrderNoteCategory.Mesial]: 'Mesial',
    [LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeHigher]: 'Make marginal ridge higher',
    [LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeLower]: 'Make marginal ridge lower',
    [LabsGqlDesignOrderNoteCategory.MesialMakeContactBroader]: 'Make contact more broad (buccal to lingual)',
    [LabsGqlDesignOrderNoteCategory.MesialMakeContactLessBroad]: 'Make contact less broad (buccal to lingual)',
    [LabsGqlDesignOrderNoteCategory.MesialMakeContactTaller]: 'Make contact taller (occlusal to gingival)',
    [LabsGqlDesignOrderNoteCategory.MesialMakeContactLessTall]: 'Make contact less tall (occlusal to gingival)',
    [LabsGqlDesignOrderNoteCategory.Distal]: 'Distal',
    [LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeHigher]: 'Make marginal ridge higher',
    [LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeLower]: 'Make marginal ridge lower',
    [LabsGqlDesignOrderNoteCategory.DistalMakeContactBroader]: 'Make contact more broad (buccal to lingual)',
    [LabsGqlDesignOrderNoteCategory.DistalMakeContactLessBroad]: 'Make contact less broad (buccal to lingual)',
    [LabsGqlDesignOrderNoteCategory.DistalMakeContactTaller]: 'Make contact taller (occlusal to gingival)',
    [LabsGqlDesignOrderNoteCategory.DistalMakeContactLessTall]: 'Make contact less tall (occlusal to gingival)',
    [LabsGqlDesignOrderNoteCategory.OcclusalAnatomyLessAggressive]: 'Make occlusal anatomy less aggressive',
    [LabsGqlDesignOrderNoteCategory.CorrectCuspPlacement]: 'Correct cusp placement',
    [LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementBuccal]: 'Buccal',
    [LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementLingual]: 'Lingual',
    [LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusion]:
        "Bring cusps into occlusion (within Dr's occlusal clearance preference)",
    [LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionBuccal]: 'Buccal',
    [LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionLingual]: 'Lingual',
};

//type CategoryTree = Record<LabsGqlDesignOrderNoteCategory, CategoryTree>
type CategoryTree = {
    category: LabsGqlDesignOrderNoteCategory;
    children?: CategoryTree[];
};

// DESIGN_ORDER_NOTE_CATEGORY_TREES documents the hierarchy of
// structured design note categories
export const DESIGN_ORDER_NOTE_CATEGORY_TREES: CategoryTree[] = [
    {
        category: LabsGqlDesignOrderNoteCategory.GingivalEmbrasure,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpen,
                children: [
                    {
                        category: LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenMesial,
                    },
                    {
                        category: LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenDistal,
                    },
                ],
            },
            {
                category: LabsGqlDesignOrderNoteCategory.GingivalEmbrasureClose,
                children: [
                    {
                        category: LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseMesial,
                    },
                    {
                        category: LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseDistal,
                    },
                ],
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.IncisalEdgeDesign,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignIncreaseLength,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignDecreaseLength,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShape,
                children: [
                    {
                        category: LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeRounded,
                    },
                    {
                        category: LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapePointy,
                    },
                    {
                        category: LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeSquared,
                    },
                ],
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.Esthetics,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.EstheticsSoftenLineAngles,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.EstheticsEnhanceLineAngles,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.EstheticsOther,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.MarginBuccal,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.MarginBuccalExtend,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.MarginBuccalAdjustOverextended,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.MarginLingual,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.MarginLingualExtend,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.MarginLingualAdjustOverextended,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.MarginMesial,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.MarginMesialExtend,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.MarginMesialAdjustOverextended,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.MarginDistal,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.MarginDistalExtend,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.MarginDistalAdjustOverextended,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.Facial,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.FacialReduceOvercontour,
                children: [
                    {
                        category: LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourCervical,
                    },
                    {
                        category: LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourIncisal,
                    },
                ],
            },
            {
                category: LabsGqlDesignOrderNoteCategory.FacialFillOutContour,
                children: [
                    {
                        category: LabsGqlDesignOrderNoteCategory.FacialFillOutContourCervical,
                    },
                    {
                        category: LabsGqlDesignOrderNoteCategory.FacialFillOutContourIncisal,
                    },
                ],
            },
            {
                category: LabsGqlDesignOrderNoteCategory.FacialMakeMorePronounced,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.FacialMakeLessPronounced,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.Lingual,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.LingualReduceOvercontour,
                children: [
                    {
                        category: LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourCingulum,
                    },
                    {
                        category: LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourIncisal,
                    },
                ],
            },
            {
                category: LabsGqlDesignOrderNoteCategory.LingualFillOutContour,
                children: [
                    {
                        category: LabsGqlDesignOrderNoteCategory.LingualFillOutContourCingulum,
                    },
                    {
                        category: LabsGqlDesignOrderNoteCategory.LingualFillOutContourIncisal,
                    },
                ],
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.Buccal,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.BuccalReduceOvercontour,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.BuccalFillOutContour,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.Mesial,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeHigher,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeLower,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.MesialMakeContactBroader,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.MesialMakeContactLessBroad,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.MesialMakeContactTaller,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.MesialMakeContactLessTall,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.Distal,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeHigher,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeLower,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.DistalMakeContactBroader,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.DistalMakeContactLessBroad,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.DistalMakeContactTaller,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.DistalMakeContactLessTall,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.OcclusalAnatomyLessAggressive,
    },
    {
        category: LabsGqlDesignOrderNoteCategory.CorrectCuspPlacement,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementBuccal,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementLingual,
            },
        ],
    },
    {
        category: LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusion,
        children: [
            {
                category: LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionBuccal,
            },
            {
                category: LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionLingual,
            },
        ],
    },
];

// DESIGN_ORDER_PRESET_CATEGORIES lists the note categories we present
// for each possible design preset.  Note that only the leaf nodes need
// to be listed here, we will automatically include their parents
export const DESIGN_ORDER_PRESET_CATEGORIES: Record<LabsGqlGuidedWaxupPresetType, LabsGqlDesignOrderNoteCategory[]> = {
    [LabsGqlGuidedWaxupPresetType.AnteriorContour]: [
        LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourCervical,
        LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourIncisal,
        LabsGqlDesignOrderNoteCategory.FacialFillOutContourCervical,
        LabsGqlDesignOrderNoteCategory.FacialFillOutContourIncisal,
        LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourCingulum,
        LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourIncisal,
        LabsGqlDesignOrderNoteCategory.LingualFillOutContourCingulum,
        LabsGqlDesignOrderNoteCategory.LingualFillOutContourIncisal,
    ],
    [LabsGqlGuidedWaxupPresetType.ContactDesign]: [
        LabsGqlDesignOrderNoteCategory.MesialMakeContactBroader,
        LabsGqlDesignOrderNoteCategory.MesialMakeContactLessBroad,
        LabsGqlDesignOrderNoteCategory.MesialMakeContactTaller,
        LabsGqlDesignOrderNoteCategory.MesialMakeContactLessTall,
        LabsGqlDesignOrderNoteCategory.DistalMakeContactBroader,
        LabsGqlDesignOrderNoteCategory.DistalMakeContactLessBroad,
        LabsGqlDesignOrderNoteCategory.DistalMakeContactTaller,
        LabsGqlDesignOrderNoteCategory.DistalMakeContactLessTall,
    ],
    [LabsGqlGuidedWaxupPresetType.ContourView]: [],
    [LabsGqlGuidedWaxupPresetType.FacialAnatomy]: [
        LabsGqlDesignOrderNoteCategory.FacialMakeMorePronounced,
        LabsGqlDesignOrderNoteCategory.FacialMakeLessPronounced,
    ],
    [LabsGqlGuidedWaxupPresetType.GeneralView]: [],
    [LabsGqlGuidedWaxupPresetType.MarginalRidge]: [
        LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeHigher,
        LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeLower,
        LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeHigher,
        LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeLower,
    ],
    [LabsGqlGuidedWaxupPresetType.MarginView]: [
        LabsGqlDesignOrderNoteCategory.MarginBuccalExtend,
        LabsGqlDesignOrderNoteCategory.MarginBuccalAdjustOverextended,
        LabsGqlDesignOrderNoteCategory.MarginLingualExtend,
        LabsGqlDesignOrderNoteCategory.MarginLingualAdjustOverextended,
        LabsGqlDesignOrderNoteCategory.MarginMesialExtend,
        LabsGqlDesignOrderNoteCategory.MarginMesialAdjustOverextended,
        LabsGqlDesignOrderNoteCategory.MarginDistalExtend,
        LabsGqlDesignOrderNoteCategory.MarginDistalAdjustOverextended,
    ],
    [LabsGqlGuidedWaxupPresetType.OcclusalAnatomy]: [
        LabsGqlDesignOrderNoteCategory.OcclusalAnatomyLessAggressive,
        LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementBuccal,
        LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementLingual,
        LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionBuccal,
        LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionLingual,
    ],
    [LabsGqlGuidedWaxupPresetType.PosteriorContour]: [
        LabsGqlDesignOrderNoteCategory.BuccalReduceOvercontour,
        LabsGqlDesignOrderNoteCategory.BuccalFillOutContour,
        LabsGqlDesignOrderNoteCategory.LingualReduceOvercontour,
        LabsGqlDesignOrderNoteCategory.LingualFillOutContour,
    ],
    [LabsGqlGuidedWaxupPresetType.ToothDesign]: [
        LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenMesial,
        LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenDistal,
        LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseMesial,
        LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseDistal,
        LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignIncreaseLength,
        LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignDecreaseLength,
        LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeRounded,
        LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapePointy,
        LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeSquared,
        LabsGqlDesignOrderNoteCategory.EstheticsSoftenLineAngles,
        LabsGqlDesignOrderNoteCategory.EstheticsEnhanceLineAngles,
        LabsGqlDesignOrderNoteCategory.EstheticsOther,
    ],
    [LabsGqlGuidedWaxupPresetType.VideoWalkthrough]: [],
};

export type RenderedCategoryTree = {
    category: LabsGqlDesignOrderNoteCategory;
    label: string;
    children?: RenderedCategoryTree[];
};

function filteredTree(
    tree: CategoryTree,
    includedCategories: Set<LabsGqlDesignOrderNoteCategory>,
): RenderedCategoryTree | null {
    const filteredChildren = (tree.children || [])
        .map(t => filteredTree(t, includedCategories))
        .filter(t => t !== null);
    if (filteredChildren.length > 0 || includedCategories.has(tree.category)) {
        return {
            category: tree.category,
            label: DESIGN_ORDER_NOTE_CATEGORY_LABELS[tree.category],
            children: filteredChildren,
        };
    }
    return null;
}

export function getDesignOrderNoteCategoryTrees(notes: LabsGqlDesignOrderDtoNote[]): RenderedCategoryTree[] {
    const includedCategories: Set<LabsGqlDesignOrderNoteCategory> = new Set();
    for (const note of notes) {
        includedCategories.add(note.category);
    }

    return DESIGN_ORDER_NOTE_CATEGORY_TREES.map(t => filteredTree(t, includedCategories)).filter(t => t !== null);
}

export function getDesignOrderPresetCategoryTrees(preset: LabsGqlGuidedWaxupPresetType): RenderedCategoryTree[] {
    return DESIGN_ORDER_NOTE_CATEGORY_TREES.map(t =>
        filteredTree(t, new Set(DESIGN_ORDER_PRESET_CATEGORIES[preset])),
    ).filter(t => t !== null);
}
