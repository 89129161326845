import type { EditUserQueryQuery } from '@orthly/graphql-inline-react';
import type { LabsGqlSetDandyAsPreferredEnum } from '@orthly/graphql-schema';

type IteroUserInfo = {
    itero_doctor_id: string;
    staff_member_id: string;
    should_immediately_place_order: boolean;
    set_dandy_as_preferred_lab_status?: LabsGqlSetDandyAsPreferredEnum;
    wants_preference_review?: boolean;
    has_onboarding_fields?: boolean;
};

export type IteroInfoEditUserRoles = Record<string, IteroUserInfo>;

export const buildIteroInfo = (iteroData: EditUserQueryQuery | undefined) => {
    const iteroUserIdMapping: Record<string, IteroUserInfo> =
        iteroData?.getIteroUsersByStaffIds?.reduce(
            (acc, curr) => {
                acc[curr.staff_member_id] = {
                    ...curr,
                    has_onboarding_fields: false,
                };
                return acc;
            },
            {} as Record<string, IteroUserInfo>,
        ) || {};

    iteroData?.getStaffMembersOnboardingPreferences?.forEach(preference => {
        const onboardingPreference = {
            wants_preference_review: preference.wants_preference_review,
            set_dandy_as_preferred_lab_status: preference.set_dandy_as_preferred_lab_status,
            has_onboarding_fields: true,
        };
        if (iteroUserIdMapping[preference.staff_member_id]) {
            // @ts-expect-error - This is a safe operation
            iteroUserIdMapping[preference.staff_member_id] = {
                ...iteroUserIdMapping[preference.staff_member_id],
                ...onboardingPreference,
            };
        } else {
            // @ts-expect-error - This is a safe operation
            iteroUserIdMapping[preference.staff_member_id] = onboardingPreference;
        }
    });

    return iteroUserIdMapping;
};
