import { DesignDetailSidebar } from '../../../../../../../components/DesignDetailSidebar/DesignDetailSidebar';
import type { OrderDetailSidebarTabPieces } from '../../../OrderDetail.types';
import { OrderDetailPageFrame } from '../../../OrderDetailPageFrame.graphql';
import { OrderDetailPageSidebar } from '../../../OrderDetailPageSidebar';
import { useOrderDetailContext } from '../../../state/OrderDetailProvider.graphql';
import { useOrderChatSidebarParts } from '../../OrderDetailChat/OrderDetailChat';
import { BodyContainer, ToolbarActionsContainer } from './common';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import { PaintingStartBanner } from '@orthly/painter';
import { useQueryParam } from '@orthly/ui';
import { styled } from '@orthly/ui-primitives';
import { OrderDesignRevisions, useDesignOrderRevisionsLoader, useOrderDetailImages } from '@orthly/veneer';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

export const AdminPainterTabPageSalesOrder_Fragment = graphql(`
    fragment AdminPainterTabPageSalesOrder_Fragment on SalesOrderDTO {
        ...PainterPaintingStartBannerSalesOrder_Fragment
    }
`);

const StartBannerContainer = styled('div')(() => ({
    padding: 16,
    width: 360,
}));

export const PainterTabPage: React.FC = () => {
    const { order, salesOrder: salesOrderRaw, id: orderId, refetch } = useOrderDetailContext();
    const salesOrder = getFragmentData(AdminPainterTabPageSalesOrder_Fragment, salesOrderRaw);

    const orderDetailSidebarParts: OrderDetailSidebarTabPieces = {
        name: 'Details',
        sidebarBody: <DesignDetailSidebar order={order} refetchOrder={refetch} />,
    };

    const imageSources = useOrderDetailImages(order, false);
    const chatSidebarParts = useOrderChatSidebarParts();
    const navigate = useNavigate();
    const {
        slimDesignFragments,
        selectedDesignFragment: selectedDesign,
        loadAndSelectDesign,
    } = useDesignOrderRevisionsLoader(orderId);

    const [openVal, setOpenQueryParam] = useQueryParam('open');
    const initiallyOpen = openVal === 'true';

    const onCancel = () => {
        setOpenQueryParam(undefined);
    };

    const onSubmit = () => {
        setOpenQueryParam(undefined);
        navigate(`/orders/${orderId}`);
    };

    return (
        <OrderDetailPageFrame
            body={
                salesOrder && (
                    <BodyContainer container>
                        <StartBannerContainer>
                            {selectedDesign && order ? (
                                <PaintingStartBanner
                                    selectedDesign={selectedDesign}
                                    salesOrder={salesOrder}
                                    initiallyOpen={initiallyOpen}
                                    callbacks={{ onSubmit, onCancel }}
                                    imageSources={imageSources}
                                    orderId={orderId}
                                    order={order}
                                />
                            ) : (
                                <div>This order does not have any designs.</div>
                            )}
                        </StartBannerContainer>
                    </BodyContainer>
                )
            }
            sidebar={
                <OrderDetailPageSidebar
                    keepOffscreenTabsMounted
                    registerTabHotKeys={true}
                    tabs={[orderDetailSidebarParts, chatSidebarParts]}
                />
            }
            toolbarActions={
                <ToolbarActionsContainer container>
                    {order && (
                        <OrderDesignRevisions
                            order={order}
                            userRole={'psr'}
                            setSelectedDesignRevisionId={loadAndSelectDesign}
                            designRevisionFragments={slimDesignFragments}
                            selectedDesignRevisionId={selectedDesign?.id}
                        />
                    )}
                </ToolbarActionsContainer>
            }
        />
    );
};
