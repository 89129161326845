import { RoutingRuleUtils } from '../../components/RoutingRule.util';
import type { SubmitterParams } from '../../utils';
import { handleSuccess } from '../../utils';
import { CreateDesignRuleDialog } from './CreateDesignRuleDialog';
import { UpdateDesignRuleDialog } from './UpdateDesignRuleDialog';
import type { LabsGqlDesignRuleFragment } from '@orthly/graphql-operations';
import {
    useDesignRulesForManufacturerQuery,
    useDisableDesignRuleMutation,
    useEnableDesignRuleMutation,
} from '@orthly/graphql-react';
import type { LabsGqlDisableDesignRuleCommand, LabsGqlEnableDesignRuleCommand } from '@orthly/graphql-schema';
import MUITable from '@orthly/mui-table';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Grid, IconButton, Tooltip, EditIcon, ToggleOffIcon, ToggleOnIcon } from '@orthly/ui-primitives';
import React from 'react';

function useDisableDesignRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useDisableDesignRuleMutation();
    const mtnSubmitter = (data: LabsGqlDisableDesignRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Design Rule disabled!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

function useEnableDesignRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useEnableDesignRuleMutation();
    const mtnSubmitter = (data: LabsGqlEnableDesignRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Design Rule enabled!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RowActions: React.FC<{ row: LabsGqlDesignRuleFragment; refetch: () => Promise<any> }> = ({ row, refetch }) => {
    const [showEditDialog, setShowEditDialog] = React.useState(false);
    const { submit: disableRule, submitting: submittingDisable } = useDisableDesignRuleSubmitter({
        onComplete: () => refetch(),
    });
    const { submit: enableRule, submitting: submittingEnable } = useEnableDesignRuleSubmitter({
        onComplete: () => refetch(),
    });
    return (
        <>
            <Tooltip title={row.disabled ? 'Enable' : 'Disable'}>
                <IconButton
                    onClick={() => (row.disabled ? enableRule({ rule_id: row.id }) : disableRule({ rule_id: row.id }))}
                    disabled={submittingEnable || submittingDisable}
                    style={{ color: row.disabled ? FlossPalette.DARK_TAN : FlossPalette.STAR_GRASS }}
                >
                    <LoadBlocker blocking={submittingEnable || submittingDisable} loader={'circular'}>
                        {row.disabled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                    </LoadBlocker>
                </IconButton>
            </Tooltip>
            <Tooltip title={'Edit'}>
                <IconButton onClick={() => setShowEditDialog(true)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <UpdateDesignRuleDialog
                open={showEditDialog}
                close={() => setShowEditDialog(false)}
                rule={row}
                refetch={refetch}
            />
        </>
    );
};

export const DesignRuleList: React.VFC = () => {
    const {
        data: { designRulesForManufacturer: designRules = [] } = {},
        loading: loadingRules,
        refetch,
    } = useDesignRulesForManufacturerQuery({
        fetchPolicy: 'cache-and-network',
    });

    const [showAddDialog, setShowAddDialog] = React.useState(false);

    return (
        <Grid container>
            <Grid item xs={12}>
                <MUITable<LabsGqlDesignRuleFragment>
                    title={`Design Rules`}
                    data={designRules}
                    loading={loadingRules}
                    actions={{
                        global: [
                            { icon: 'add', position: 'toolbar', onClick: () => setShowAddDialog(true) },
                            { icon: 'refresh', position: 'toolbar', onClick: () => refetch().catch(console.error) },
                        ],
                    }}
                    displayOptions={{ download: true, filter: true, sort: true, filterValues: true }}
                    columns={[
                        ...RoutingRuleUtils.getDesignListColumns(),
                        {
                            title: 'Disabled?',
                            name: 'actions',
                            field: 'disabled',
                            render: row => <RowActions row={row} refetch={refetch} />,
                            filter: true,
                            sort: false,
                            customFilterFn: (values, row) => values.includes(`${row.disabled}`),
                            type: 'boolean',
                            filterOptions: {
                                defaultValues: ['false'],
                            },
                        },
                    ]}
                />
                <CreateDesignRuleDialog open={showAddDialog} close={() => setShowAddDialog(false)} refetch={refetch} />
            </Grid>
        </Grid>
    );
};
