import { getMarginTessellatedPoints, getMarginTessellatedPointsNew } from '../../ModelViewer/Margin.util';
import { TESSELLATION_RESOLUTION, type ScanReviewMarginLineEditor } from '../MarginMarking';
import type { ScanReviewOnInsertionAxisUpdateCallback } from '../ScanReview.utils';
import { isMarginLineInUndercut, type ScanReviewInsertionAxisLiveObjectProvider } from '../ScanReview.utils';
import type { ScanReviewInsertionAxis } from '../ScanReviewDesignTypes';
import type { ScanReviewPartialScene } from '../ScanReviewSceneTypes';
import type { ScanReviewViewManager } from '../ScanReviewViewTypes';
import { ToothUtils } from '@orthly/items';
import { Jaw } from '@orthly/shared-types';
import * as THREE from 'three';
import type { MeshBVH } from 'three-mesh-bvh';

export class ScanReviewUndercutToolController {
    get group() {
        return this.liveObjectsProvider.group;
    }

    static makeController(
        viewManager: ScanReviewViewManager,
        scene: ScanReviewPartialScene,
        insertionAxis: ScanReviewInsertionAxis,
        marginLineEditor?: ScanReviewMarginLineEditor,
        indexBVH?: MeshBVH,
        liveObjectsProvider?: ScanReviewInsertionAxisLiveObjectProvider,
        onInsertionAxisUpdate?: ScanReviewOnInsertionAxisUpdateCallback,
    ) {
        return (
            indexBVH &&
            marginLineEditor &&
            liveObjectsProvider &&
            new ScanReviewUndercutToolController(
                indexBVH,
                marginLineEditor,
                insertionAxis,
                viewManager,
                scene,
                liveObjectsProvider,
                onInsertionAxisUpdate,
            )
        );
    }

    constructor(
        private readonly indexBVH: MeshBVH,
        private readonly marginLineEditor: ScanReviewMarginLineEditor,
        private readonly insertionAxis: ScanReviewInsertionAxis,
        private readonly viewManager: ScanReviewViewManager,
        private readonly scene: ScanReviewPartialScene,
        private readonly liveObjectsProvider: ScanReviewInsertionAxisLiveObjectProvider,
        private readonly onInsertionAxisUpdate?: ScanReviewOnInsertionAxisUpdateCallback,
        private readonly useMarginNewBehavior: boolean = true,
    ) {
        const jaw = ToothUtils.toothIsLower(this.insertionAxis.unn) ? Jaw.LOWER : Jaw.UPPER;
        this.scene.updateUndercutDisplay(jaw, this.insertionAxis);
    }

    updateInsertionAxisFromView() {
        if (!this.viewManager.cameraRef.current) {
            return;
        }
        const cameraDirection = new THREE.Vector3();
        this.viewManager.cameraRef.current.getWorldDirection(cameraDirection);
        this.liveObjectsProvider.setInsertionAxisDirection(cameraDirection);
        this.insertionAxis.direction = cameraDirection;

        const jaw = ToothUtils.toothIsLower(this.insertionAxis.unn) ? Jaw.LOWER : Jaw.UPPER;
        this.scene.updateUndercutDisplay(jaw, this.insertionAxis);

        const { controlPoints, marginClosed } = this.marginLineEditor.currentEditedMarginLine;
        const tesselationResult = this.useMarginNewBehavior
            ? getMarginTessellatedPointsNew(controlPoints, marginClosed, this.indexBVH, TESSELLATION_RESOLUTION)
            : getMarginTessellatedPoints(controlPoints, marginClosed, this.indexBVH, TESSELLATION_RESOLUTION);

        const undercutMargin = isMarginLineInUndercut(tesselationResult, this.insertionAxis, this.indexBVH);
        this.onInsertionAxisUpdate?.(this.insertionAxis, undercutMargin);

        return cameraDirection;
    }
}
