import type { AdminState } from '../../../redux/redux.types';
import { UNSAVED_VIEW_ID } from '../../../redux/utils/SavedViewActionConstants';
import { SavedViewReducerUtils } from '../../../redux/utils/SavedViewReducerUtils';
import { RouterUtils } from '../../../utils/router/RouterUtils';
import { OrdersOverviewActions } from './OrdersOverview.actions';
import { isOrdersOverviewScreen } from './OrdersOverview.selectors';
import type {
    OrdersOverviewCustomViewState,
    OrdersOverviewCustomView,
    OrdersOverviewState,
    ActiveOrdersOverviewCustomView,
    OrdersOverviewHistory,
    OrdersOverviewScreen,
} from './OrdersOverview.types';
import { OrdersOverviewDefaultSort } from './OrdersOverviewConstants';
import { LabsGqlLabOrderStatus as OrderStatus, LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import type { ConstEnum } from '@orthly/runtime-utils';
import _ from 'lodash';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';
import { REHYDRATE } from 'redux-persist';

export const OrdersOverviewInitialState: OrdersOverviewState = {
    screen: OrderStatus.NeedsReview,
    savedViews: {},
    view: null,
    history: { custom: {} },
};

export function customOrdersScreenActive(state: OrdersOverviewState): state is OrdersOverviewCustomViewState {
    return state.screen === 'custom';
}

function getPriorScreen(state: OrdersOverviewState): OrdersOverviewScreen {
    if (customOrdersScreenActive(state)) {
        return state.view?.priorScreen ?? OrderStatus.NeedsReview;
    }
    return state.screen;
}

// this moves existing views saved in redux persist from storing a full filter to storing just criteria
const migrateView = <T extends OrdersOverviewCustomView>(view: T) => ({
    ...view,
    criteria: view.criteria ?? view.filter?.criteria,
});

function setCurrentCustomView(
    state: OrdersOverviewState,
    viewUpdate: Partial<OrdersOverviewCustomView> | null,
): OrdersOverviewState {
    if (!viewUpdate) {
        return state;
    }
    if (state.view) {
        return { ...state, view: { ...state.view, ...viewUpdate } };
    }
    return {
        ...state,
        screen: 'custom',
        view: {
            id: UNSAVED_VIEW_ID,
            title: viewUpdate.search ? 'Search results' : 'Unsaved view',
            priorScreen: getPriorScreen(state),
            visibility: LabsGqlSavedSearchVisibility.Private,
            sort: OrdersOverviewDefaultSort,
            ...viewUpdate,
        },
    };
}

function customViewFromUrl(): Partial<OrdersOverviewCustomView> | null {
    const criteria = RouterUtils.filterParamsFromUrl('orders');
    if (criteria.length > 0) {
        return { criteria };
    }
    return null;
}

function getInitialState(): OrdersOverviewState {
    const view = customViewFromUrl();
    return view ? setCurrentCustomView(OrdersOverviewInitialState, view) : OrdersOverviewInitialState;
}

function handleRehydrate(state: OrdersOverviewState, action: { payload?: AdminState }): OrdersOverviewState {
    const statePropertyKeys: ConstEnum<keyof OrdersOverviewState> = {
        editingViewId: 'editingViewId',
        savedViews: 'savedViews',
        history: 'history',
        screen: 'screen',
        timelineSidebarWidthPct: 'timelineSidebarWidthPct',
        view: 'view',
    };
    const rehydratedState = _.pick<Partial<OrdersOverviewState>>(
        action.payload?.ordersOverview ?? {},
        _.keys(statePropertyKeys),
    );
    const timelineSidebarWidthPct =
        action.payload?.ordersOverview?.timelineSidebarWidthPct ?? state.timelineSidebarWidthPct;
    const history: OrdersOverviewHistory = {
        ...state.history,
        ...rehydratedState.history,
        custom: { ...state.history.custom, ...rehydratedState.history?.custom },
    };
    const rehydratedViews =
        !!rehydratedState.savedViews && !Array.isArray(rehydratedState.savedViews) ? rehydratedState.savedViews : {};
    const baseNewState: OrdersOverviewState = {
        ...state,
        timelineSidebarWidthPct,
        history,
        savedViews: _.mapValues(rehydratedViews, migrateView),
    };
    const viewFromUrl = customViewFromUrl();
    if (viewFromUrl) {
        return setCurrentCustomView(baseNewState, viewFromUrl);
    }
    if (rehydratedState.screen === 'custom') {
        // invalid state, no view but the screen is set to custom
        if (!rehydratedState.view) {
            return baseNewState;
        }
        return { ...baseNewState, view: migrateView(rehydratedState.view), screen: 'custom' };
    }
    // if the screen is set and valid we use it
    if (rehydratedState.screen && isOrdersOverviewScreen(rehydratedState.screen)) {
        return { ...baseNewState, screen: rehydratedState.screen, view: null };
    }
    return baseNewState;
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ordersOverviewReducerBase = handleActions<OrdersOverviewState, any>(
    {
        [REHYDRATE]: handleRehydrate,
        ...OrdersOverviewActions.SET_SCREEN.reducer<OrdersOverviewState>((state, action) => {
            if (!isOrdersOverviewScreen(action.payload)) {
                const savedView = state.savedViews[action.payload];
                if (!savedView || _.isEqual(state.view, savedView)) {
                    return state;
                }
                const view: ActiveOrdersOverviewCustomView = { ...savedView, priorScreen: getPriorScreen(state) };
                return { ...state, view, screen: 'custom' };
            }
            return { ...state, view: null, screen: action.payload };
        }),
        ...OrdersOverviewActions.SET_CRITERIA.reducer<OrdersOverviewState>((state, { payload: criteria }) =>
            setCurrentCustomView(state, { criteria }),
        ),
        ...OrdersOverviewActions.SET_SEARCH.reducer<OrdersOverviewState>((state, { payload: search }) =>
            setCurrentCustomView(state, { search }),
        ),
        ...OrdersOverviewActions.SEARCH_FOR_PATIENT.reducer<OrdersOverviewState>((state, { payload: search }) =>
            // set the ID to the unsaved view id in case we are currently on a custom view
            setCurrentCustomView(state, { search, id: UNSAVED_VIEW_ID, title: search }),
        ),
        ...OrdersOverviewActions.SORT_KEY_CLICKED.reducer<OrdersOverviewState>((state, { payload: sortKey }) => {
            // if view exists, use existing asc, otherwise get the default
            const newView = state.view
                ? { sort: { ...state.view.sort, key: sortKey } }
                : { sort: { key: sortKey, asc: OrdersOverviewDefaultSort.asc } };
            return setCurrentCustomView(state, newView);
        }),
        ...OrdersOverviewActions.TOGGLE_SORT_DIR.reducer<OrdersOverviewState>(state => {
            const newView = state.view
                ? { sort: { ...state.view.sort, asc: !state.view.sort.asc } }
                : { sort: { key: OrdersOverviewDefaultSort.key, asc: !OrdersOverviewDefaultSort.asc } };
            return setCurrentCustomView(state, newView);
        }),
        ...OrdersOverviewActions.SEARCH_ON_BLUR.reducer<OrdersOverviewState>(state => {
            if (
                state.screen !== 'custom' ||
                (state.view?.search ?? '').length > 0 ||
                (state.view?.criteria ?? []).length > 0
            ) {
                return state;
            }
            return { ...state, screen: state.view?.priorScreen ?? 'all', view: null };
        }),
        ...OrdersOverviewActions.CLEAR_CRITERIA.reducer<OrdersOverviewState>(state => {
            if (state.screen !== 'custom') {
                return state;
            }
            if ((state.view?.search ?? '').length > 0) {
                return { ...state, view: { ...state.view, criteria: undefined } };
            }
            return { ...state, screen: state.view?.priorScreen ?? 'all', view: null };
        }),
        ...OrdersOverviewActions.SAVE_TIMELINE_WIDTH_PCT.reducer<OrdersOverviewState>((state, action) => ({
            ...state,
            timelineSidebarWidthPct: action.payload,
        })),
        ...OrdersOverviewActions.SAVE_VIEW.reducer<OrdersOverviewState>(
            (state, { payload: { id, title, visibility } }) => {
                // do not update state if new view was not added to DB
                if (!id) {
                    return state;
                }
                const savedViewMatch = state.savedViews[id];
                const currentView = (id === state.view?.id || state.view?.id === UNSAVED_VIEW_ID) && state.view;
                const view = currentView || savedViewMatch;
                if (!view) {
                    return state;
                }
                const viewToSave: OrdersOverviewCustomView = {
                    ...view,
                    title,
                    visibility: visibility ?? view.visibility,
                    favoritedCount: view.id === UNSAVED_VIEW_ID ? 1 : view.favoritedCount,
                    id: view.id === UNSAVED_VIEW_ID ? id : view.id,
                    // mark as created by user if it is a new view (from unsaved_view_id) or was already created by user
                    createdByUser: view.id === UNSAVED_VIEW_ID || view.createdByUser,
                };
                const newState: OrdersOverviewState = {
                    ...state,
                    savedViews: Object.assign({ ...state.savedViews }, { [viewToSave.id]: viewToSave }),
                };
                return setCurrentCustomView(newState, viewToSave);
            },
        ),
        ...OrdersOverviewActions.SET_EDITING_VIEW_ID.reducer<OrdersOverviewState>((state, action) => ({
            ...state,
            editingViewId: action.payload,
        })),
        ...OrdersOverviewActions.DELETE_VIEW.reducer<OrdersOverviewState>((state, action) => {
            const newState = { ...state, savedViews: _.omit(state.savedViews, action.payload) };
            // we are deleting the currently active view, update the current view to be unsaved
            if (state.view?.id === action.payload) {
                return setCurrentCustomView(newState, { id: UNSAVED_VIEW_ID, title: 'Unsaved view' });
            }
            return newState;
        }),
        ...OrdersOverviewActions.LOAD_VIEWS.reducer<OrdersOverviewState>((state, action) => {
            const newSavedViews = _.fromPairs(action.payload.map(view => [view.id, view]));
            const newState = { ...state, savedViews: newSavedViews };
            // if current view has been deleted in the db, update the current view to be unsaved
            if (state.view?.id) {
                // if current view has been deleted in the db, update the current view to be unsaved
                if (!_.keys(newSavedViews).includes(state.view.id)) {
                    return setCurrentCustomView(newState, { id: UNSAVED_VIEW_ID, title: 'Unsaved view' });
                }
                const viewUpdate = newSavedViews[state.view.id];
                return viewUpdate ? setCurrentCustomView(newState, viewUpdate) : newState;
            }
            return newState;
        }),
        ...OrdersOverviewActions.TOGGLE_FAVORITE_VIEW.reducer<OrdersOverviewState>((state, action) => {
            const { view, searchId } = action.payload;
            return SavedViewReducerUtils.toggleFavorite({ state, searchId, setCurrentCustomView, view });
        }),
        ...OrdersOverviewActions.COPY_VIEW.reducer<OrdersOverviewState>((state, { payload: rootView }) => {
            const duplicatedView: OrdersOverviewCustomView = {
                ...rootView,
                title: `${rootView.title} COPY`,
                id: UNSAVED_VIEW_ID,
                favoritedCount: 1,
                createdByUsername: undefined,
            };
            return setCurrentCustomView({ ...state, editingViewId: duplicatedView.id }, duplicatedView);
        }),
        ...OrdersOverviewActions.TOGGLE_VIEW_VISIBILITY.reducer<OrdersOverviewState>((state, action) => {
            return SavedViewReducerUtils.toggleViewVisibility<OrdersOverviewState>(
                state,
                action.payload.searchId,
                action.payload.newVisibility,
            );
        }),
    },
    getInitialState(),
);

// Record the last time a screen was seen
function historyReducer(state: OrdersOverviewState = OrdersOverviewInitialState): OrdersOverviewHistory {
    if (customOrdersScreenActive(state)) {
        // dont record history for unsaved views
        if (state.view.id === UNSAVED_VIEW_ID) {
            return state.history;
        }
        return { ...state.history, custom: { ...state.history.custom, [state.view.id]: new Date().valueOf() } };
    }
    return { ...state.history, [state.screen]: new Date().valueOf() };
}

export function ordersOverviewReducer(
    originalState: OrdersOverviewState | undefined,
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: Action<any>,
): OrdersOverviewState {
    const newState = ordersOverviewReducerBase(originalState, action);
    const screenChanged = originalState?.screen !== newState.screen || originalState.view?.id !== newState?.view?.id;
    return { ...newState, history: screenChanged ? historyReducer(originalState) : newState.history };
}
