import type { ICameraControls } from '../../ModelViewer';
import type { ScanReviewMarginMarkingToolController } from './ScanReviewMarginMarkingToolController';
import { useThree } from '@react-three/fiber';
import React from 'react';

export const ScanReviewMarginMarkingTool: React.VFC<{
    toolController: ScanReviewMarginMarkingToolController;
    controls: ICameraControls;
}> = ({ toolController, controls }) => {
    const { gl } = useThree();
    const canvas = gl.domElement;

    React.useEffect(() => {
        const handleDoubleClick = toolController.handleDoubleClick.bind(toolController);
        const handleClick = toolController.handleClick.bind(toolController);
        const handlePointerDown = toolController.handlePointerDown.bind(toolController);
        const handlePointerMove = toolController.handlePointerMove.bind(toolController);
        const handlePointerUp = toolController.handlePointerUp.bind(toolController);
        const handleKeyDown = toolController.handleKeyDown.bind(toolController);
        const handleKeyUp = toolController.handleKeyUp.bind(toolController);
        const handleResize = toolController.handleResize.bind(toolController);
        const updateVisuals = () => {
            toolController.updateVisuals();
        };

        canvas.addEventListener('dblclick', handleDoubleClick);
        canvas.addEventListener('click', handleClick);
        canvas?.addEventListener('pointerdown', handlePointerDown);
        canvas?.addEventListener('pointermove', handlePointerMove);
        canvas?.addEventListener('pointerup', handlePointerUp);
        canvas?.addEventListener('pointerleave', handlePointerUp);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        controls?.addEventListener('change', updateVisuals);
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(canvas);

        return () => {
            canvas.removeEventListener('dblclick', handleDoubleClick);
            canvas.removeEventListener('click', handleClick);
            canvas?.removeEventListener('pointerdown', handlePointerDown);
            canvas?.removeEventListener('pointermove', handlePointerMove);
            canvas?.removeEventListener('pointerup', handlePointerUp);
            canvas?.removeEventListener('pointerleave', handlePointerUp);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            controls?.removeEventListener('change', updateVisuals);
            resizeObserver.disconnect();
        };
    }, [canvas, toolController, controls]);
    return <></>;
};
