import { ConfigureLabPriceForm } from './ConfigureLabPriceForm';
import { useCreateLabPriceMutation } from '@orthly/graphql-react';
import type { LabsGqlCreateLabPriceCommand } from '@orthly/graphql-schema';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { RootActionDialog, SimpleSelect, useChangeSubmissionFn } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface CreateProductCatalogEntryFormProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onSuccess?: () => Promise<void>;
    disabled: boolean;
}

export const CreateProductCatalogEntryForm: React.FC<CreateProductCatalogEntryFormProps> = ({
    open,
    setOpen,
    onSuccess,
    disabled,
}) => {
    const [submitMtn] = useCreateLabPriceMutation();
    const mtnSubmitter = (data: LabsGqlCreateLabPriceCommand) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [LabsGqlCreateLabPriceCommand]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Product catalog entry created!', {}],
        onSuccess: async () => {
            if (onSuccess) {
                await onSuccess();
            }
        },
    });
    const [ruleType, setRuleType] = React.useState<LabsGqlLabPriceRuleType>(LabsGqlLabPriceRuleType.Unit);
    return (
        <RootActionDialog
            open={open}
            loading={submitting}
            title={'Create Product Catalog Entry'}
            setOpen={setOpen}
            buttonText={'Create Product Catalog Entry'}
            CustomButton={() => null}
            content={
                <Grid container>
                    <Grid container style={{ padding: '12px 0' }}>
                        <SimpleSelect
                            value={ruleType}
                            onChange={value => {
                                value && setRuleType(value as LabsGqlLabPriceRuleType);
                            }}
                            label={'Rule Type'}
                            options={Object.values(LabsGqlLabPriceRuleType)
                                .filter(o => o !== LabsGqlLabPriceRuleType.Unknown)
                                .map(o => ({ value: o, label: o }))}
                        />
                    </Grid>
                    <ConfigureLabPriceForm ruleType={ruleType} submit={submit} disabled={disabled} />
                </Grid>
            }
        />
    );
};
