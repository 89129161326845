import type { GuidedWaxupScheduleModal } from '../../hooks/useGuidedWaxupAccessoryModal';
import { GuidedWaxupAccessoryModals } from './GuidedWaxupAccessoryModals.graphql';
import {
    type CalendlyPrefill,
    useIsOrderEligibleForLiveDesignReview,
} from './GuidedWaxupScheduleLiveCall.hooks.graphql';
import { GuidedWaxupScheduleLiveCallInitialModal } from './GuidedWaxupScheduleLiveCallInitialModal';
import { useQuery } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { addBusinessDays } from '@orthly/date-fns-business-days';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlLiveDdpReviewRequestEntryPoint } from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui';
import dayjs from 'dayjs';
import React from 'react';

const GetLdrCapacityStats_Query = graphql(`
    query GetLdrCapacityStats_Query {
        getLdrCapacityStats {
            available_slots
            available_slot_count {
                time_window_4h
                time_window_8h
                time_window_24h
                time_window_48h
            }
        }
    }
`);

export const GuidedWaxupScheduleLiveCall: React.VFC<{
    order: Pick<
        LabsGqlSingleLabOrderFragment,
        'id' | 'fulfillment_workflow' | 'items_v2' | 'practice_dates' | 'original_order_id'
    >;
    rejections: number;
    revisionId: string;
    calendlyPrefill?: CalendlyPrefill;
    onScheduleCompletion?: () => void;
    buttonDisabled?: boolean;
    rejectionNotes?: string;
    entryPoint: LabsGqlLiveDdpReviewRequestEntryPoint;
}> = ({
    order,
    rejections,
    revisionId,
    onScheduleCompletion,
    calendlyPrefill,
    buttonDisabled,
    rejectionNotes,
    entryPoint,
}) => {
    const [modal, setModal] = React.useState<GuidedWaxupScheduleModal>('closed');

    // We normally allow 2 days past DDP due date for the customer to review the DDP before a BPO agent will auto approve it.
    // If the customer is pausing the order, that time is also paused.
    // Thus, the time they will have to approve the DDP _after_ the meeting is equal to how much time they have left _now_.
    // ie, if they have 1 day left at the time of placing the pause, they will have 1 day left when the pause is removed.
    const designPreviewETA = order.practice_dates.digital_design_preview_estimated_completion_date
        ? new Date(order.practice_dates.digital_design_preview_estimated_completion_date)
        : new Date();
    const reviewDue = addBusinessDays(designPreviewETA, 2);
    const numberOfDaysUntilAutoApproveDdp = dayjs(reviewDue).diff(dayjs(), 'day');

    const isMobile = useScreenIsMobileOrVerticalTablet();

    const isEnabled = useIsOrderEligibleForLiveDesignReview(order);

    const { data: ldrCapacityStatsData } = useQuery(GetLdrCapacityStats_Query, {
        skip: modal !== 'calendly',
    });

    React.useEffect(() => {
        if (isEnabled) {
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Live Design Review - Available', {
                $groups: {
                    order: order.id,
                },
                revisionId: revisionId,
                numRejections: rejections,
            });
        }
    }, [isEnabled, order.id, rejections, revisionId]);

    React.useEffect(() => {
        if (modal === 'calendly' && ldrCapacityStatsData?.getLdrCapacityStats) {
            const capacityStats = ldrCapacityStatsData.getLdrCapacityStats;
            BrowserAnalyticsClientFactory.Instance?.track(
                'Practice - Portal - Live Design Review - Opened Calendly Scheduler',
                {
                    $groups: {
                        order: order.id,
                    },
                    revisionId: revisionId,
                    capacityStats: capacityStats,
                },
            );
        }
    }, [modal, ldrCapacityStatsData?.getLdrCapacityStats, order.id, revisionId]);

    if (!isEnabled) {
        return null;
    }

    return (
        <>
            <GuidedWaxupScheduleLiveCallInitialModal
                open={modal === 'initial'}
                openModal={() => {
                    BrowserAnalyticsClientFactory.Instance?.track(
                        'Practice - Portal - Live Design Review - Initiated Scheduling Flow',
                        {
                            $groups: {
                                order: order.id,
                            },
                            revisionId: revisionId,
                        },
                    );
                    setModal('initial');
                }}
                openAbandomentModal={() => setModal('abandonment')}
                openCalendlyModal={() => setModal('calendly')}
                numberOfDaysUntilAutoApproveDdp={numberOfDaysUntilAutoApproveDdp}
                isMobile={isMobile}
                buttonDisabled={buttonDisabled}
            />
            <GuidedWaxupAccessoryModals
                orderId={order.id}
                revisionId={revisionId}
                isMobile={isMobile}
                setModal={setModal}
                modal={modal}
                calendlyPrefill={calendlyPrefill}
                onScheduleCompletion={onScheduleCompletion}
                numRejections={rejections}
                isRefab={!!order.original_order_id}
                ddpReadinessDate={order.practice_dates.digital_design_preview_estimated_completion_date}
                rejectionNotes={rejectionNotes}
                entryPoint={entryPoint}
            />
        </>
    );
};
