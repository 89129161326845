import { DesignLoader } from '../components/DesignLoader/DesignLoader';
import { LeftSidebar } from '../components/LeftSidebar/LeftSidebar.graphql';
import { RightSidebar } from '../components/RightSidebar/RightSidebar.graphql';
import THREERoot from '../components/THREERoot/THREERoot';
import type { DesignModels } from '../store/designsStore';
import { clearStore, designIdAtom } from '../store/store';
import type { PainterPaintingStartBannerSalesOrder_FragmentFragment } from '@orthly/graphql-inline-react';
import {
    getFragmentData,
    PainterRightSidebarSalesOrder_FragmentFragmentDoc,
    VeneerOrderDetailItemsAndPreferencesSalesOrderItemFullDisplayInfoWithCommon_FragmentFragmentDoc,
    VeneerOrderDetailItemsTableSalesOrderItemFullDisplayInfo_FragmentFragmentDoc,
} from '@orthly/graphql-inline-react';
import { styled } from '@orthly/ui';
import { Popover } from '@orthly/ui-primitives';
import type { OrderDetailImage } from '@orthly/veneer';
import { useSetAtom } from 'jotai';
import React from 'react';

export interface PainterRootProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    models?: DesignModels;
    designId?: string;
    salesOrder: PainterPaintingStartBannerSalesOrder_FragmentFragment;
    imageSources?: OrderDetailImage[];
}

const Layout = styled('div')({
    display: 'flex',
    width: '100%',
    position: 'relative',
});

export const PainterRoot: React.FC<PainterRootProps> = ({
    salesOrder: salesOrderProp,
    models,
    open,
    setOpen,
    imageSources,
    designId,
}) => {
    const salesOrder = getFragmentData(PainterRightSidebarSalesOrder_FragmentFragmentDoc, salesOrderProp);
    const setDesignId = useSetAtom(designIdAtom);

    if (designId) {
        setDesignId(designId);
    }

    const displayInfoWithCommon = getFragmentData(
        VeneerOrderDetailItemsAndPreferencesSalesOrderItemFullDisplayInfoWithCommon_FragmentFragmentDoc,
        salesOrder.items_full_display_info_with_common,
    );
    const fullDisplayInfo = getFragmentData(
        VeneerOrderDetailItemsTableSalesOrderItemFullDisplayInfo_FragmentFragmentDoc,
        displayInfoWithCommon.items_full_display_info,
    );

    React.useEffect(() => {
        return () => {
            clearStore();
        };
    }, []);

    return (
        <Popover
            anchorReference={'none'}
            open={open}
            PaperProps={{
                style: { width: '100vw', height: '100vh', maxWidth: '100vw', maxHeight: '100vh' },
            }}
        >
            <Layout>
                {models && <DesignLoader models={models} fullDisplayInfo={fullDisplayInfo[0]} />}
                {salesOrderProp && <LeftSidebar salesOrder={salesOrderProp} />}
                <THREERoot />
                {salesOrderProp && (
                    <RightSidebar
                        onClose={() => setOpen(false)}
                        salesOrder={salesOrderProp}
                        imageSources={imageSources}
                    />
                )}
            </Layout>
        </Popover>
    );
};
