/* eslint-disable */
/* NOTE: THIS IS AN AUTOGENERATED FILE, DO NOT EDIT */
/* orthlyweb graphql/types documentation: https://docs.dandyserv.net/orthlyweb/local-development/graphql/index.html */
import type { ToothNumber } from '@orthly/items';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** string | boolean | number | string[] */
  AutomationFieldValue: { input: string | boolean | number | string[]; output: string | boolean | number | string[]; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string; }
  /** Json object with type = string | boolean | number */
  MetafieldJSON: { input: string | boolean | number; output: string | boolean | number; }
  /** An integer between 1 and 32, corresponding to the UNN tooth numbering system */
  ToothNumber: { input: ToothNumber; output: ToothNumber; }
  join__FieldSet: { input: any; output: any; }
};

export type LabsGqlAbutmentPartDto = {
  __typename?: 'AbutmentPartDTO';
  /** Analog SKU that is recommended for this abutment part */
  analog_sku?: Maybe<Scalars['String']['output']>;
  archived_at?: Maybe<Scalars['DateTime']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** Diameter of the abutment part, in mm */
  diameter_mm?: Maybe<Scalars['Float']['output']>;
  /** Type of engagement of the abutment part (Engaging or Non-Engaging) */
  engagement: LabsGqlAbutmentPartEngagement;
  /** Gingival Height of the abutment part, in mm */
  gingival_height_mm?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  is_archived: Scalars['Boolean']['output'];
  /** Manufacturer of the abutment part (it could be different than the manufacturer of the implant system or scanbody) */
  manufacturer: Scalars['String']['output'];
  /** Material of the Abutment part (eg: Custom Titanium) */
  material: LabsGqlAbutmentPartMaterial;
  /** The SKU of the Screw that is associated with this abutment part */
  screw_sku?: Maybe<Scalars['String']['output']>;
  /** SKU of the abutment part (eg: IDL30-B15S, 37.017) */
  sku: Scalars['String']['output'];
  /** 3shape category, this information is needed by designers to select the right fields in 3shape in order to find the right abutment part */
  three_shape_category: Scalars['String']['output'];
  /** 3shape kit, this information is needed by designers to select the right fields in 3shape in order to find the right abutment part */
  three_shape_kit: Scalars['String']['output'];
  /** 3shape manufacturer, this information is needed by designers to select the right fields in 3shape in order to find the right abutment part */
  three_shape_manufacturer: Scalars['String']['output'];
  /** 3shape manufacturing process, this information is needed by designers to select the right fields in 3shape in order to find the right abutment part */
  three_shape_manufacturing_process: Scalars['String']['output'];
  /** 3shape material, this information is needed by designers to select the right fields in 3shape in order to find the right abutment part */
  three_shape_material: Scalars['String']['output'];
  /** 3shape system, this information is needed by designers to select the right fields in 3shape in order to find the right abutment part */
  three_shape_system: Scalars['String']['output'];
  /** Top cap height of the abutment part, in mm */
  top_cap_height_mm?: Maybe<Scalars['Float']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlAbutmentPartEngagement {
  Engaging = 'Engaging',
  NonEngaging = 'NonEngaging'
}

export enum LabsGqlAbutmentPartMaterial {
  AngledAbutment = 'AngledAbutment',
  CustomTitaniumAbutment = 'CustomTitaniumAbutment',
  CustomZirconiaHybridAbutment = 'CustomZirconiaHybridAbutment',
  TiBase = 'TiBase'
}

export type LabsGqlAbutmentPartScrewSkuDatum = {
  part_id: Scalars['String']['input'];
  screw_sku?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlAbutmentPartToImplantAndScanbodyDto = {
  __typename?: 'AbutmentPartToImplantAndScanbodyDTO';
  abutment_part: LabsGqlAbutmentPartDto;
  abutment_part_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  implant_to_scanbody_id: Scalars['String']['output'];
  /** Priority, as a number. Can be set to 10 (Preferred) or 0 (Non-Preferred). */
  priority: Scalars['Float']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlAcknowledgeManualEntryLmsOrderArgs = {
  labOrderId: Scalars['String']['input'];
  lmsOrderId?: InputMaybe<Scalars['Float']['input']>;
  lmsOrderIdText?: InputMaybe<Scalars['String']['input']>;
  overrideValidations?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlAcknowledgeManualEntryLmsOrderResponse = {
  __typename?: 'AcknowledgeManualEntryLmsOrderResponse';
  labOrderId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type LabsGqlActionCapabilityDto = {
  __typename?: 'ActionCapabilityDTO';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  is_archived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlActionCategoryDto = {
  __typename?: 'ActionCategoryDTO';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  is_archived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parent_id?: Maybe<Scalars['ID']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlActionStatus {
  Canceled = 'Canceled',
  Completed = 'Completed',
  Pending = 'Pending'
}

export type LabsGqlActionTimeConstraintBasicConfig = {
  __typename?: 'ActionTimeConstraintBasicConfig';
  type: LabsGqlActionTimeConstraintConfigType;
};

export type LabsGqlActionTimeConstraintConfig = LabsGqlActionTimeConstraintBasicConfig | LabsGqlActionTimeConstraintCustomConfig;

export enum LabsGqlActionTimeConstraintConfigType {
  Custom = 'Custom',
  DoctorsHours = 'DoctorsHours'
}

export type LabsGqlActionTimeConstraintCustomConfig = {
  __typename?: 'ActionTimeConstraintCustomConfig';
  activate_at?: Maybe<LabsGqlActionTimeConstraintCustomDateConfig>;
  due_at?: Maybe<LabsGqlActionTimeConstraintCustomDateConfig>;
  type: LabsGqlActionTimeConstraintConfigType;
};

export type LabsGqlActionTimeConstraintCustomDateConfig = LabsGqlActionTimeConstraintCustomDateDurationConfig | LabsGqlActionTimeConstraintCustomDateNextEncounterConfig;

export type LabsGqlActionTimeConstraintCustomDateDurationConfig = {
  __typename?: 'ActionTimeConstraintCustomDateDurationConfig';
  type: LabsGqlTimeConstraintCustomConfigType;
  value: Scalars['Float']['output'];
};

export type LabsGqlActionTimeConstraintCustomDateNextEncounterConfig = {
  __typename?: 'ActionTimeConstraintCustomDateNextEncounterConfig';
  minimum_duration: Scalars['Float']['output'];
  type: LabsGqlTimeConstraintCustomConfigType;
  value: Scalars['Float']['output'];
};

export type LabsGqlActionTypeActionTimes = {
  __typename?: 'ActionTypeActionTimes';
  activate_at?: Maybe<Scalars['DateTime']['output']>;
  due_at?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlActionTypeDto = {
  __typename?: 'ActionTypeDTO';
  capability_ids: Array<Scalars['String']['output']>;
  category_id: Scalars['ID']['output'];
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  is_archived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  time_constraint_config?: Maybe<LabsGqlActionTimeConstraintConfig>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlActionTypeFilters = {
  category_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  is_archived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlActionTypeWithCategory = {
  __typename?: 'ActionTypeWithCategory';
  capability_ids: Array<Scalars['String']['output']>;
  category_id: Scalars['ID']['output'];
  category_name: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  is_archived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  subcategory_id: Scalars['ID']['output'];
  subcategory_name: Scalars['String']['output'];
  time_constraint_config?: Maybe<LabsGqlActionTimeConstraintConfig>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlActivateContractEntityInput = {
  date_string: Scalars['String']['input'];
  practice_id: Scalars['String']['input'];
};

export type LabsGqlActivateOrganizationStaffMemberCommand = {
  activated_at?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type LabsGqlActiveDesignTaskListPayload = {
  __typename?: 'ActiveDesignTaskListPayload';
  design_download_url?: Maybe<Scalars['String']['output']>;
  order: LabsGqlLabOrderWithEvents;
  scans_download_url?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlActiveInternalDesignTaskSummary = {
  __typename?: 'ActiveInternalDesignTaskSummary';
  designStaffMatchingLogic: LabsGqlDesignStaffMatchingLogic;
  labOrderId: Scalars['String']['output'];
  matchStaleTime: Scalars['DateTime']['output'];
  requirements: Array<LabsGqlOrderDesignCapabilityDto>;
  taskType: LabsGqlDesignTaskType;
};

export type LabsGqlAddAbutmentPartToImplantScanbodyCommand = {
  abutment_part_id: Scalars['String']['input'];
  implant_to_scanbody_id: Scalars['String']['input'];
  implant_type_id: Scalars['String']['input'];
  priority: Scalars['Float']['input'];
};

export type LabsGqlAddCrmTicketTagsArgs = {
  tags: Array<Scalars['String']['input']>;
  ticket_id: Scalars['String']['input'];
};

export type LabsGqlAddEntityTagCommand = {
  entity_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};

export type LabsGqlAddExternalStaffMemberCommand = {
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRoleExternal>;
  user_id: Scalars['String']['input'];
};

export type LabsGqlAddInternalStaffMemberCommand = {
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRoleInternal>;
  user_id: Scalars['String']['input'];
};

export type LabsGqlAddLabOrderPhotosCommand = {
  order_id: Scalars['String']['input'];
  photos: Array<LabsGqlLabOrderSinglePhotoInput>;
};

export type LabsGqlAddLabOrderPhotosWithNotesCommand = {
  notes: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
  photos: Array<LabsGqlLabOrderSinglePhotoInput>;
};

export type LabsGqlAddLabSlipAlertCommand = {
  orderId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  type: LabsGqlLabSlipAlertType;
  visibility?: InputMaybe<Array<LabsGqlDoctorAlertLocation>>;
};

export type LabsGqlAddLabStaffMemberCommand = {
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRoleLab>;
  user_id: Scalars['String']['input'];
};

export type LabsGqlAddManualQualityControlItemCommand = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  description: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type LabsGqlAddNotesToLiveLabTechMeetingRequestArgs = {
  calendly_event_id: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
};

/** DEPRECATED: Use AddOrderItemsV2 instead */
export type LabsGqlAddOrderItemCommand = {
  item?: InputMaybe<LabsGqlLabOrderItemInput>;
  /** When provided only the first item found will be used. Clients should only ever intend to provide a single item. */
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
  orderId: Scalars['String']['input'];
  priceOverrideCents?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlAddOrderItemsV2Command = {
  items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
  orderId: Scalars['String']['input'];
};

export type LabsGqlAddParentStaffMemberCommand = {
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRoleParent>;
  user_id: Scalars['String']['input'];
};

export type LabsGqlAddPhoneToDrPrefAndUserArgs = {
  code: Scalars['String']['input'];
  mobile_phone_number: Scalars['String']['input'];
  preferences_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type LabsGqlAddPracticeStaffMemberCommand = {
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRolePractice>;
  user_id: Scalars['String']['input'];
};

export type LabsGqlAddPriceApplicationRuleCommand = {
  /** If null, applies for this unit type with any material type */
  material_type?: InputMaybe<Scalars['String']['input']>;
  price_id: Scalars['String']['input'];
  unit_type: Scalars['String']['input'];
};

export type LabsGqlAddReasonCodeRuleCommand = {
  reason_id: Scalars['String']['input'];
  rule: LabsGqlCustomFieldDisplayRuleInput;
  rule_type: LabsGqlDisplayRuleTypeEnum;
};

export type LabsGqlAddRefabReasonRuleCommand = {
  reason_id: Scalars['String']['input'];
  rule: LabsGqlCustomFieldDisplayRuleInput;
  rule_type: LabsGqlDisplayRuleTypeEnum;
};

export type LabsGqlAddRetainerToAlignerCaseCommand = {
  lab_order_id: Scalars['String']['input'];
  retainer: LabsGqlRetainerInput;
  scan_export_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlAgreeToCreditCardFeesChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'AgreeToCreditCardFeesChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value: Scalars['Boolean']['output'];
  old_value: Scalars['Boolean']['output'];
};

export enum LabsGqlAlignerArch {
  Dual = 'Dual',
  Lower = 'Lower',
  Upper = 'Upper'
}

export type LabsGqlAlignerCaseDto = {
  __typename?: 'AlignerCaseDTO';
  aligner_arch: LabsGqlAlignerArch;
  aligner_notes?: Maybe<Scalars['String']['output']>;
  aligner_treatment_area: LabsGqlAlignerTreatmentArea;
  attachment_restricted_teeth: Array<Scalars['ToothNumber']['output']>;
  bite_concerns?: Maybe<LabsGqlPendingAlignerCaseBiteConcerns>;
  concerns?: Maybe<LabsGqlPendingAlignerCaseConcerns>;
  created_at: Scalars['DateTime']['output'];
  crowding_resolution?: Maybe<LabsGqlPendingAlignerCaseCrowdingResolution>;
  dandy_plan?: Maybe<LabsGqlAlignerPlan>;
  design_software?: Maybe<LabsGqlAlignerDesignSoftware>;
  estimate: LabsGqlPendingAlignerCaseEstimate;
  extraction_teeth: Array<Scalars['ToothNumber']['output']>;
  id: Scalars['ID']['output'];
  interproximal_space_sizes: Array<LabsGqlPendingAlignerCaseInterproximalSpaceSize>;
  is_refinement: Scalars['Boolean']['output'];
  movement_restricted_teeth: Array<Scalars['ToothNumber']['output']>;
  order_id: Scalars['ID']['output'];
  photo_zip_path?: Maybe<Scalars['String']['output']>;
  photos: LabsGqlPendingAlignerCasePhotoMap;
  retainers: LabsGqlRetainersCollection;
  /** @deprecated Please use the design_order treatment plans system */
  softsmile_plan_versions?: Maybe<Array<LabsGqlSoftSmilePlanVersion>>;
  stl_zip_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use the design_order treatment plans system */
  treatment_plan?: Maybe<LabsGqlAlignerTreatmentPlan>;
  /** @deprecated Please use the design_order treatment plans system */
  treatment_plan_history: Array<LabsGqlAlignerTreatmentPlan>;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlAlignerCasePhotoType {
  FrontBite = 'FrontBite',
  FrontPortraitNoSmile = 'FrontPortraitNoSmile',
  FrontPortraitSmile = 'FrontPortraitSmile',
  LeftBite = 'LeftBite',
  MandibularOcclusion = 'MandibularOcclusion',
  MaxillaryOcclusion = 'MaxillaryOcclusion',
  PanoramicXray = 'PanoramicXray',
  RightBite = 'RightBite',
  SideView = 'SideView'
}

export type LabsGqlAlignerCasePhotoTypeClass = {
  __typename?: 'AlignerCasePhotoTypeClass';
  type: LabsGqlAlignerCasePhotoType;
};

export type LabsGqlAlignerCaseRetainersSummaryDto = {
  __typename?: 'AlignerCaseRetainersSummaryDTO';
  freeSetsRemaining: Scalars['Int']['output'];
  totalSetsOrdered: Scalars['Int']['output'];
};

export enum LabsGqlAlignerConcernSeverity {
  Mild = 'Mild',
  Moderate = 'Moderate',
  Severe = 'Severe'
}

export enum LabsGqlAlignerDesignSoftware {
  ArchForm = 'ArchForm',
  SoftSmile = 'SoftSmile'
}

export type LabsGqlAlignerItem = LabsGqlIOrderItemBase & {
  __typename?: 'AlignerItem';
  aligner_treatment_metadata?: Maybe<LabsGqlOrderItemAlignerTreatmentMetadata>;
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlOrderItemArchUnit;
};

export type LabsGqlAlignerItemInput = {
  aligner_treatment_metadata?: InputMaybe<LabsGqlOrderItemAlignerTreatmentMetadataInput>;
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlOrderItemArchUnitInput;
};

export enum LabsGqlAlignerPlan {
  Dandy20 = 'Dandy20',
  DandyMax = 'DandyMax',
  OnDemand = 'OnDemand'
}

export enum LabsGqlAlignerTreatmentArea {
  AnteriorOnly = 'AnteriorOnly',
  FullArch = 'FullArch'
}

export type LabsGqlAlignerTreatmentPlan = LabsGqlApprovedAlignerTreatmentPlan | LabsGqlConvertedTreatmentPlanRequest | LabsGqlFulfilledAlignerTreatmentPlan | LabsGqlOutstandingTreatmentPlanRequest | LabsGqlRejectedAlignerTreatmentPlan | LabsGqlSoftSmileDraftAlignerTreatmentPlan | LabsGqlUnapprovedAlignerTreatmentPlan | LabsGqlUnconvertedTreatmentPlanRequest;

export type LabsGqlAlignerTreatmentPlanStep = {
  __typename?: 'AlignerTreatmentPlanStep';
  ipr: Array<LabsGqlAlignerTreatmentPlanStepIpr>;
  lower_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use needs_attachments_upper and needs_attachments_lower on the treatment plan instead */
  needs_attachments: Scalars['Boolean']['output'];
  needs_ipr: Scalars['Boolean']['output'];
  step: Scalars['Int']['output'];
  upper_path?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlAlignerTreatmentPlanStepIpr = {
  __typename?: 'AlignerTreatmentPlanStepIPR';
  reduction_in_mm?: Maybe<Scalars['Float']['output']>;
  tooth: Scalars['String']['output'];
};

export type LabsGqlAlignerTreatmentPlanStepIprInput = {
  reduction_in_mm?: InputMaybe<Scalars['Float']['input']>;
  tooth: Scalars['String']['input'];
};

export type LabsGqlAlignerTreatmentPlanStepInput = {
  ipr: Array<LabsGqlAlignerTreatmentPlanStepIprInput>;
  lower_path?: InputMaybe<Scalars['String']['input']>;
  needs_attachments: Scalars['Boolean']['input'];
  step: Scalars['Int']['input'];
  upper_path?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlAllScannersResponse = {
  __typename?: 'AllScannersResponse';
  scanners: Array<LabsGqlScannerWithPartners>;
};

export type LabsGqlAppendTicketActionCommand = {
  action: LabsGqlTicketActionCreationInputData;
  after_action_id: Scalars['ID']['input'];
  cancel_pending: Scalars['Boolean']['input'];
  ticket_id: Scalars['ID']['input'];
};

export type LabsGqlApplyInvoiceCreditToInvoiceInput = {
  amount_cents: Scalars['Int']['input'];
  credit_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  invoice_id: Scalars['String']['input'];
  order_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlApplyPricePresetCommand = {
  partner_id: Scalars['String']['input'];
  preset_id: Scalars['String']['input'];
};

export type LabsGqlApproveAlignerFabricationCommand = {
  dandy_plan?: InputMaybe<LabsGqlAlignerPlan>;
  /** because plans don't have a reliable id, use the plan's created_at date instead */
  from_date?: InputMaybe<Scalars['DateTime']['input']>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlApprovedAlignerTreatmentPlan = LabsGqlIAlignerTreatmentPlan & {
  __typename?: 'ApprovedAlignerTreatmentPlan';
  /** Date the dentist approved for manufacturing */
  approved_at: Scalars['DateTime']['output'];
  dandy_plan?: Maybe<LabsGqlAlignerPlan>;
  duration_in_months: Scalars['Int']['output'];
  /** Denotes that the plan was modified by the doctor and will need review by a designer before fabrication. */
  modified_by_doctor?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use needs_attachments_upper and needs_attachments_lower on the treatment plan instead */
  needs_attachments: Scalars['Boolean']['output'];
  needs_attachments_lower?: Maybe<Scalars['Boolean']['output']>;
  needs_attachments_upper?: Maybe<Scalars['Boolean']['output']>;
  needs_ipr: Scalars['Boolean']['output'];
  plan_type: LabsGqlTreatmentPlanType;
  request: LabsGqlConvertedTreatmentPlanRequest;
  softsmile?: Maybe<LabsGqlSoftSmileData>;
  steps: Array<LabsGqlAlignerTreatmentPlanStep>;
};

export type LabsGqlArchiveAbutmentPartCommand = {
  abutment_part_id: Scalars['String']['input'];
};

export type LabsGqlArchiveOrderAutomationCommand = {
  archived: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export type LabsGqlArchiveProductCatalogEntryInput = {
  id: Scalars['ID']['input'];
  newState: Scalars['Boolean']['input'];
};

export type LabsGqlArchiveQcItemConfigCommand = {
  archived: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export type LabsGqlArchiveThreeshapeDmeCommand = {
  dme_id: Scalars['String']['input'];
};

export type LabsGqlAssignFulfillmentTaskCommand = {
  /** user to assign to the task */
  assigned_user_id?: InputMaybe<Scalars['String']['input']>;
  /**
   * User currently assigned to the task. This is meant to ensure the user re-assigning
   *         the task knows who the previously-assigned user was, and thus wasn't operating on stale data.
   */
  last_assigned_user_id?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  override_validation?: InputMaybe<Scalars['Boolean']['input']>;
  task_id: Scalars['String']['input'];
};

export type LabsGqlAssignmentCandidateOrder = {
  __typename?: 'AssignmentCandidateOrder';
  id: Scalars['String']['output'];
  priority: Scalars['Float']['output'];
  productLine: LabsGqlProductLine;
  taskType: LabsGqlDesignStaffTaskType;
};

export type LabsGqlAttachAlignerPreppedScansCommand = {
  lowerPath: Scalars['String']['input'];
  scanExportId: Scalars['String']['input'];
  upperPath: Scalars['String']['input'];
};

export type LabsGqlAttachDesignFilesCommand = {
  actioned_automate_id?: InputMaybe<Scalars['String']['input']>;
  design_file_path: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  override_validation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlAttachExternalAlignerFulfillmentCommand = {
  expected_arrival_date: Scalars['DateTime']['input'];
  order_id: Scalars['String']['input'];
  provider?: InputMaybe<LabsGqlExternalAlignerManufacturer>;
  provider_case_id: Scalars['String']['input'];
};

export type LabsGqlAttachPhotosUploadData = {
  doctor_name?: InputMaybe<Scalars['String']['input']>;
  order_number: Scalars['String']['input'];
  patient_name: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  practice_id: Scalars['String']['input'];
  upload_link: Scalars['String']['input'];
};

export type LabsGqlAttachQcPhotosCommand = {
  item_photos: Array<LabsGqlItemPhotosInput>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlAttachScanExportStlsCommand = {
  bitescan_paths: Array<Scalars['String']['input']>;
  lower_paths: Array<Scalars['String']['input']>;
  scan_export_id: Scalars['String']['input'];
  upper_paths: Array<Scalars['String']['input']>;
  zipped_stls_url: Scalars['String']['input'];
};

export type LabsGqlAttachTreatmentPlanRequestCommand = {
  expected_completion_date: Scalars['DateTime']['input'];
  order_id: Scalars['String']['input'];
  provider: LabsGqlTreatmentPlanProvider;
  provider_case_id: Scalars['String']['input'];
};

export type LabsGqlAutoAssignerDebuggerInfo = {
  __typename?: 'AutoAssignerDebuggerInfo';
  availableForActiveTask: Scalars['Boolean']['output'];
  focusAreas?: Maybe<Array<LabsGqlCapabilityMatch>>;
  matchesActiveTaskCapabilities: Scalars['Boolean']['output'];
  maxTasksAssigned: Scalars['Int']['output'];
  numTasksAssigned: Scalars['Int']['output'];
  taskTypeCapabilityMatch: Array<LabsGqlTaskTypeCapabilityMatch>;
};

export enum LabsGqlAutomateValidationError {
  InsertionAxisDiff = 'INSERTION_AXIS_DIFF',
  MarginLineDiff = 'MARGIN_LINE_DIFF',
  MarginLineFromMeshDiff = 'MARGIN_LINE_FROM_MESH_DIFF',
  MarginLineMeanDiff = 'MARGIN_LINE_MEAN_DIFF',
  MarginUndercut = 'MARGIN_UNDERCUT',
  NearMarginOffsetAngle = 'NEAR_MARGIN_OFFSET_ANGLE',
  NearMarginThickness = 'NEAR_MARGIN_THICKNESS',
  ValidationFailed = 'VALIDATION_FAILED'
}

export type LabsGqlAutomationActionConfig = {
  __typename?: 'AutomationActionConfig';
  action?: Maybe<LabsGqlAutomationActionDefinition>;
  action_id: Scalars['String']['output'];
  fields: Array<LabsGqlAutomationFieldSubmission>;
};

export type LabsGqlAutomationActionConfigInput = {
  action_id: Scalars['String']['input'];
  fields: Array<LabsGqlAutomationFieldSubmissionInput>;
};

export type LabsGqlAutomationActionDefinition = {
  __typename?: 'AutomationActionDefinition';
  description?: Maybe<Scalars['String']['output']>;
  fields: Array<LabsGqlAutomationField>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** Denotes that there is a test method available for the action */
  test_available: Scalars['Boolean']['output'];
};

export type LabsGqlAutomationField = LabsGqlAutomationFieldBoolean | LabsGqlAutomationFieldCopy | LabsGqlAutomationFieldDate | LabsGqlAutomationFieldMultiSelect | LabsGqlAutomationFieldNumber | LabsGqlAutomationFieldSelect | LabsGqlAutomationFieldText;

export type LabsGqlAutomationFieldBoolean = LabsGqlIAutomationField & {
  __typename?: 'AutomationFieldBoolean';
  default_value?: Maybe<Scalars['AutomationFieldValue']['output']>;
  helper_text?: Maybe<Scalars['String']['output']>;
  /** Unique id for identifying this field in submissions */
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  optional?: Maybe<Scalars['Boolean']['output']>;
  type: LabsGqlAutomationFieldType;
};

export type LabsGqlAutomationFieldCopy = LabsGqlIAutomationField & {
  __typename?: 'AutomationFieldCopy';
  default_value?: Maybe<Scalars['AutomationFieldValue']['output']>;
  /** The text (formatted in markdown) to display to the user */
  display_text: Scalars['String']['output'];
  helper_text?: Maybe<Scalars['String']['output']>;
  /** Unique id for identifying this field in submissions */
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  optional?: Maybe<Scalars['Boolean']['output']>;
  type: LabsGqlAutomationFieldType;
};

export type LabsGqlAutomationFieldDate = LabsGqlIAutomationField & {
  __typename?: 'AutomationFieldDate';
  default_value?: Maybe<Scalars['AutomationFieldValue']['output']>;
  helper_text?: Maybe<Scalars['String']['output']>;
  /** Unique id for identifying this field in submissions */
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  max?: Maybe<Scalars['DateTime']['output']>;
  min?: Maybe<Scalars['DateTime']['output']>;
  optional?: Maybe<Scalars['Boolean']['output']>;
  type: LabsGqlAutomationFieldType;
};

export type LabsGqlAutomationFieldMultiSelect = LabsGqlIAutomationField & {
  __typename?: 'AutomationFieldMultiSelect';
  default_value?: Maybe<Scalars['AutomationFieldValue']['output']>;
  helper_text?: Maybe<Scalars['String']['output']>;
  /** Unique id for identifying this field in submissions */
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  maxSelections?: Maybe<Scalars['Int']['output']>;
  minSelections?: Maybe<Scalars['Int']['output']>;
  optional?: Maybe<Scalars['Boolean']['output']>;
  options: Array<LabsGqlAutomationFieldOption>;
  type: LabsGqlAutomationFieldType;
};

export type LabsGqlAutomationFieldNumber = LabsGqlIAutomationField & {
  __typename?: 'AutomationFieldNumber';
  default_value?: Maybe<Scalars['AutomationFieldValue']['output']>;
  helper_text?: Maybe<Scalars['String']['output']>;
  /** Unique id for identifying this field in submissions */
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  optional?: Maybe<Scalars['Boolean']['output']>;
  type: LabsGqlAutomationFieldType;
};

export type LabsGqlAutomationFieldOption = {
  __typename?: 'AutomationFieldOption';
  displayIfMatches?: Maybe<Array<LabsGqlAutomationFieldOptionConditional>>;
  label?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type LabsGqlAutomationFieldOptionConditional = {
  __typename?: 'AutomationFieldOptionConditional';
  field: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type LabsGqlAutomationFieldSelect = LabsGqlIAutomationField & {
  __typename?: 'AutomationFieldSelect';
  default_value?: Maybe<Scalars['AutomationFieldValue']['output']>;
  helper_text?: Maybe<Scalars['String']['output']>;
  /** Unique id for identifying this field in submissions */
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  optional?: Maybe<Scalars['Boolean']['output']>;
  options: Array<LabsGqlAutomationFieldOption>;
  type: LabsGqlAutomationFieldType;
};

export type LabsGqlAutomationFieldSubmission = {
  __typename?: 'AutomationFieldSubmission';
  field_id: Scalars['String']['output'];
  value: Scalars['AutomationFieldValue']['output'];
};

export type LabsGqlAutomationFieldSubmissionInput = {
  field_id: Scalars['String']['input'];
  value: Scalars['AutomationFieldValue']['input'];
};

export type LabsGqlAutomationFieldText = LabsGqlIAutomationField & {
  __typename?: 'AutomationFieldText';
  default_value?: Maybe<Scalars['AutomationFieldValue']['output']>;
  helper_text?: Maybe<Scalars['String']['output']>;
  /** Unique id for identifying this field in submissions */
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  minimum_characters?: Maybe<Scalars['Int']['output']>;
  multiline?: Maybe<Scalars['Boolean']['output']>;
  optional?: Maybe<Scalars['Boolean']['output']>;
  type: LabsGqlAutomationFieldType;
};

export enum LabsGqlAutomationFieldType {
  Boolean = 'boolean',
  Copy = 'copy',
  Date = 'date',
  MultiSelect = 'multiSelect',
  Number = 'number',
  Select = 'select',
  Text = 'text'
}

export enum LabsGqlAutomationTriggerEventNamesEnum {
  AlignerCaseDesignSoftwareChanged = 'AlignerCaseDesignSoftwareChanged',
  AlignerCaseEdited = 'AlignerCaseEdited',
  AlignerFabricationApproved = 'AlignerFabricationApproved',
  AlignerPhotoZipConverted = 'AlignerPhotoZipConverted',
  AlignerStlZipConverted = 'AlignerStlZipConverted',
  AskedDrAboutOrderHold = 'AskedDrAboutOrderHold',
  AutoAssignedTaskTimedOut = 'AutoAssignedTaskTimedOut',
  CbctScanUrlSet = 'CbctScanUrlSet',
  ChatMessageCreated = 'ChatMessageCreated',
  CurrentDenturesFulfillmentUpdatedToCore = 'CurrentDenturesFulfillmentUpdatedToCore',
  CurrentDenturesFulfillmentUpdatedToWaxRim = 'CurrentDenturesFulfillmentUpdatedToWaxRim',
  CurrentRetainerUpdated = 'CurrentRetainerUpdated',
  DeliveryAddressChanged = 'DeliveryAddressChanged',
  DenturesFulfillmentAdded = 'DenturesFulfillmentAdded',
  DenturesFulfillmentCurrentCoreUpdated = 'DenturesFulfillmentCurrentCoreUpdated',
  DenturesFulfillmentCurrentWaxRimUpdated = 'DenturesFulfillmentCurrentWaxRimUpdated',
  DenturesFulfillmentFixupNewScanUpdated = 'DenturesFulfillmentFixupNewScanUpdated',
  DenturesFulfillmentFixupPlaced = 'DenturesFulfillmentFixupPlaced',
  DenturesFulfillmentTryInFollowupPlaced = 'DenturesFulfillmentTryInFollowupPlaced',
  DenturesFulfillmentTryInNewScanUpdated = 'DenturesFulfillmentTryInNewScanUpdated',
  DenturesFulfillmentTryInToggled = 'DenturesFulfillmentTryInToggled',
  DenturesFulfillmentWaxRimContinued = 'DenturesFulfillmentWaxRimContinued',
  DesignDownloadRecorded = 'DesignDownloadRecorded',
  DesignFileConversionCompleted = 'DesignFileConversionCompleted',
  DesignFilesAttached = 'DesignFilesAttached',
  DesignReduceOpposingSaved = 'DesignReduceOpposingSaved',
  DesignReview2Configured = 'DesignReview2Configured',
  DoctorPreferencesIdSet = 'DoctorPreferencesIDSet',
  DueDateChanged = 'DueDateChanged',
  ExternalAlignerFulfillmentCreated = 'ExternalAlignerFulfillmentCreated',
  FinishingDesignSkipped = 'FinishingDesignSkipped',
  FulfillmentCreated = 'FulfillmentCreated',
  FulfillmentDelivered = 'FulfillmentDelivered',
  FulfillmentShipped = 'FulfillmentShipped',
  FulfillmentShippedToDandy = 'FulfillmentShippedToDandy',
  FulfillmentTaskAssigned = 'FulfillmentTaskAssigned',
  FulfillmentTaskAutoExpirationUpdated = 'FulfillmentTaskAutoExpirationUpdated',
  FulfillmentTaskStarted = 'FulfillmentTaskStarted',
  InjectionMoldDesignTaskCompleted = 'InjectionMoldDesignTaskCompleted',
  IntegrationIdRemoved = 'IntegrationIdRemoved',
  IntegrationIdSet = 'IntegrationIdSet',
  IntegrationPanNumberUpdated = 'IntegrationPanNumberUpdated',
  IntegrationPriceUpdated = 'IntegrationPriceUpdated',
  InternalDesignTaskCompleted = 'InternalDesignTaskCompleted',
  LabOrderLockStatusChanged = 'LabOrderLockStatusChanged',
  LabOrderPhotosAdded = 'LabOrderPhotosAdded',
  LabOrderPhotosAddedWithNotes = 'LabOrderPhotosAddedWithNotes',
  LabOrderQcStationFailed = 'LabOrderQcStationFailed',
  LabOrderQcStationPassed = 'LabOrderQcStationPassed',
  LabSlipAlertsSaved = 'LabSlipAlertsSaved',
  LinkedToRefabrication = 'LinkedToRefabrication',
  ManufacturerAssignmentChanged = 'ManufacturerAssignmentChanged',
  ManufacturerDelayRequestAccepted = 'ManufacturerDelayRequestAccepted',
  ManufacturerDelayRequestCancelled = 'ManufacturerDelayRequestCancelled',
  ManufacturerDelayRequestEditedAndAccepted = 'ManufacturerDelayRequestEditedAndAccepted',
  ManufacturerDelayRequested = 'ManufacturerDelayRequested',
  ManufacturerDesignFileProcessed = 'ManufacturerDesignFileProcessed',
  MarkedAsRefabrication = 'MarkedAsRefabrication',
  ModelDesignTaskCompleted = 'ModelDesignTaskCompleted',
  NeedsRefabrication = 'NeedsRefabrication',
  NewScanAdded = 'NewScanAdded',
  NoteAdded = 'NoteAdded',
  NoteDeleted = 'NoteDeleted',
  NoteQualityControlToggled = 'NoteQualityControlToggled',
  NoteVisibilityChanged = 'NoteVisibilityChanged',
  OrderAddOnsUpdated = 'OrderAddOnsUpdated',
  OrderAutomateDesignTaskCancelled = 'OrderAutomateDesignTaskCancelled',
  OrderAutomateDesignTaskCompleted = 'OrderAutomateDesignTaskCompleted',
  OrderCancelled = 'OrderCancelled',
  OrderClinicalSupportNotesUpdated = 'OrderClinicalSupportNotesUpdated',
  OrderCreated = 'OrderCreated',
  OrderDesignAutomateIdUpdated = 'OrderDesignAutomateIdUpdated',
  OrderDesignAutomateReviewTaskCompleted = 'OrderDesignAutomateReviewTaskCompleted',
  OrderDesignPrepTaskCancelled = 'OrderDesignPrepTaskCancelled',
  OrderDesignPrepTaskCompleted = 'OrderDesignPrepTaskCompleted',
  OrderDesignRequirementsReconciled = 'OrderDesignRequirementsReconciled',
  OrderDesignReviewConfigurationSet = 'OrderDesignReviewConfigurationSet',
  OrderDesignReviewConfigurationSetV2 = 'OrderDesignReviewConfigurationSetV2',
  OrderDesignReviewTaskCompleted = 'OrderDesignReviewTaskCompleted',
  OrderDesignerTypeConfigured = 'OrderDesignerTypeConfigured',
  OrderDoctorNoteEdited = 'OrderDoctorNoteEdited',
  OrderExternalDesignVerificationCompleted = 'OrderExternalDesignVerificationCompleted',
  OrderFlaggedForReview = 'OrderFlaggedForReview',
  OrderHoldCreated = 'OrderHoldCreated',
  OrderHoldRemoved = 'OrderHoldRemoved',
  OrderHoldUpdated = 'OrderHoldUpdated',
  OrderItemAdded = 'OrderItemAdded',
  OrderItemDeleted = 'OrderItemDeleted',
  OrderItemPreferenceFieldsUpdated = 'OrderItemPreferenceFieldsUpdated',
  OrderItemUpdated = 'OrderItemUpdated',
  OrderItemsAddedV2 = 'OrderItemsAddedV2',
  OrderItemsUpdated = 'OrderItemsUpdated',
  OrderItemsUpdatedV2 = 'OrderItemsUpdatedV2',
  OrderManufacturerFabricationStateSet = 'OrderManufacturerFabricationStateSet',
  OrderMerged = 'OrderMerged',
  OrderPatientChanged = 'OrderPatientChanged',
  OrderPriceCalculated = 'OrderPriceCalculated',
  OrderRefabricationEdited = 'OrderRefabricationEdited',
  OrderRefabricationReasonsEdited = 'OrderRefabricationReasonsEdited',
  OrderRefabricationUnlinked = 'OrderRefabricationUnlinked',
  OrderRefinementRequested = 'OrderRefinementRequested',
  OrderReviewFlagResolved = 'OrderReviewFlagResolved',
  OrderSlaEdited = 'OrderSLAEdited',
  OrderSlaStartDateChanged = 'OrderSLAStartDateChanged',
  OrderScanApproved = 'OrderScanApproved',
  OrderScanRejected = 'OrderScanRejected',
  OrderScanRejectionResolved = 'OrderScanRejectionResolved',
  OrderScanReviewRequested = 'OrderScanReviewRequested',
  OrderSentToFloor = 'OrderSentToFloor',
  OrderSlaDelayed = 'OrderSlaDelayed',
  OrderSlaLeadTimesUpdated = 'OrderSlaLeadTimesUpdated',
  OrderSlaModified = 'OrderSlaModified',
  OrderSplitCountUpdated = 'OrderSplitCountUpdated',
  OrderWaxupConfigured = 'OrderWaxupConfigured',
  OrderWaxupReviewed = 'OrderWaxupReviewed',
  OverrideDentistAmountDue = 'OverrideDentistAmountDue',
  PackingSheetViewed = 'PackingSheetViewed',
  PartialsFulfillmentCurrentTypeChanged = 'PartialsFulfillmentCurrentTypeChanged',
  PartialsFulfillmentTryInFeedbackUpdated = 'PartialsFulfillmentTryInFeedbackUpdated',
  PartialsFulfillmentTryInReturnInTransit = 'PartialsFulfillmentTryInReturnInTransit',
  PartialsFulfillmentTryInReturnReceived = 'PartialsFulfillmentTryInReturnReceived',
  PartialsFulfillmentTryInReturnedForFinish = 'PartialsFulfillmentTryInReturnedForFinish',
  PartialsFulfillmentTryInReturnedForTryIn = 'PartialsFulfillmentTryInReturnedForTryIn',
  PartialsFulfillmentWaxRimFeedbackUpdated = 'PartialsFulfillmentWaxRimFeedbackUpdated',
  PartialsFulfillmentWaxRimReturnInTransit = 'PartialsFulfillmentWaxRimReturnInTransit',
  PartialsFulfillmentWaxRimReturnReceived = 'PartialsFulfillmentWaxRimReturnReceived',
  PartialsFulfillmentWaxRimReturnedForFinish = 'PartialsFulfillmentWaxRimReturnedForFinish',
  PartialsFulfillmentWaxRimReturnedForTryIn = 'PartialsFulfillmentWaxRimReturnedForTryIn',
  PortalDesign3ShapeInjected = 'PortalDesign3ShapeInjected',
  PortalDesignEditsSubmitted = 'PortalDesignEditsSubmitted',
  PortalShipShippingMethodChanged = 'PortalShipShippingMethodChanged',
  PracticeRespondedToOrderHold = 'PracticeRespondedToOrderHold',
  PracticeRespondedToRejectedScan = 'PracticeRespondedToRejectedScan',
  PremiumFlagToggled = 'PremiumFlagToggled',
  PrintableLabSlipViewed = 'PrintableLabSlipViewed',
  QcLiteSkipQc = 'QCLiteSkipQC',
  QcFlagSubmitted = 'QcFlagSubmitted',
  QcPhotosAttached = 'QcPhotosAttached',
  QualityControlItemCompleted = 'QualityControlItemCompleted',
  QualityControlItemIgnored = 'QualityControlItemIgnored',
  QualityControlManualItemAdded = 'QualityControlManualItemAdded',
  RejectedScanReplaced = 'RejectedScanReplaced',
  RetainerAddedToAlignerCase = 'RetainerAddedToAlignerCase',
  ReturnCancelledForOrder = 'ReturnCancelledForOrder',
  ReturnCreatedForOrder = 'ReturnCreatedForOrder',
  RushRequestAccepted = 'RushRequestAccepted',
  RushRequestCancelled = 'RushRequestCancelled',
  RushRequestRejected = 'RushRequestRejected',
  RushRequested = 'RushRequested',
  ScanFileStlConversionCompleted = 'ScanFileStlConversionCompleted',
  ScanIssuesReported = 'ScanIssuesReported',
  ScanRejectionSharedWithPractice = 'ScanRejectionSharedWithPractice',
  ScanValidated = 'ScanValidated',
  ShipmentRemoved = 'ShipmentRemoved',
  ShippingEstimateUpdated = 'ShippingEstimateUpdated',
  ShippingInfoUpdated = 'ShippingInfoUpdated',
  ShippingStatusChanged = 'ShippingStatusChanged',
  SmileStyleSet = 'SmileStyleSet',
  TaggedEntity = 'TaggedEntity',
  TreatmentPlanConvertedForManufacturer = 'TreatmentPlanConvertedForManufacturer',
  TreatmentPlanRejected = 'TreatmentPlanRejected',
  TreatmentPlanRequestConverted = 'TreatmentPlanRequestConverted',
  TreatmentPlanRequestFinalized = 'TreatmentPlanRequestFinalized',
  TreatmentPlanRequestUrlSet = 'TreatmentPlanRequestUrlSet',
  TreatmentPlanRequested = 'TreatmentPlanRequested',
  TreatmentPlanSaved = 'TreatmentPlanSaved',
  UntaggedEntity = 'UntaggedEntity',
  UpdateRefabCount = 'UpdateRefabCount',
  WaxupApproved = 'WaxupApproved',
  WaxupRevisionRequested = 'WaxupRevisionRequested'
}

/** A course that is available to the designer for enrollment. */
export type LabsGqlAvailableCourse = {
  __typename?: 'AvailableCourse';
  /** A slug that identifies this course */
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LabsGqlAvailableLdrSlotCount = {
  __typename?: 'AvailableLdrSlotCount';
  time_window_4h: Scalars['Int']['output'];
  time_window_8h: Scalars['Int']['output'];
  time_window_24h: Scalars['Int']['output'];
  time_window_48h: Scalars['Int']['output'];
};

export type LabsGqlBasicMarginInfo = {
  __typename?: 'BasicMarginInfo';
  /** The margin coordinates [x1, y1, z1, ..., xn, yn, zn] */
  coords?: Maybe<Array<Scalars['Float']['output']>>;
  scan_id: Scalars['String']['output'];
  unn: Scalars['Float']['output'];
};

/** The result of batching order shipments together and marking them packing completed */
export type LabsGqlBatchOrderShipmentsAndRecordPackingCompletedResult = {
  __typename?: 'BatchOrderShipmentsAndRecordPackingCompletedResult';
  batching_result: LabsGqlBatchOrderShipmentsResult;
  packing_success: Scalars['Boolean']['output'];
};

/** The result of batching order shipments together */
export type LabsGqlBatchOrderShipmentsResult = {
  __typename?: 'BatchOrderShipmentsResult';
  label_url: Scalars['String']['output'];
  service_level: Scalars['String']['output'];
  tracking_number: Scalars['String']['output'];
  /** Number of orders that had a tracking number changed */
  updated_order_count: Scalars['Int']['output'];
};

export type LabsGqlBatchShipCandidateOrderDto = {
  __typename?: 'BatchShipCandidateOrderDTO';
  lab_order_id: Scalars['String']['output'];
  label_url?: Maybe<Scalars['String']['output']>;
  mailing_address_id: Scalars['String']['output'];
  manufacturer_id: Scalars['String']['output'];
  order_number: Scalars['String']['output'];
  /** When the candidate was marked as "packing completed" in the batch shipping tool */
  packing_completed_at?: Maybe<Scalars['DateTime']['output']>;
  patient_name: Scalars['String']['output'];
  practice_id: Scalars['String']['output'];
  /** When the candidate was first scanned into the batch shipping tool */
  scanned_in_shipping_at?: Maybe<Scalars['DateTime']['output']>;
  /** The service level of the shipment */
  service_level: Scalars['String']['output'];
  stack_code?: Maybe<Scalars['String']['output']>;
  tracking_number?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlBatchShipCandidatesSearchResult = {
  __typename?: 'BatchShipCandidatesSearchResult';
  /** The label of the batch shelf on which this order should be placed */
  batch_shelf_label: Scalars['String']['output'];
  /** Count of orders expected to ship today that have not yet. If zero, either add to a batch or ship immediately */
  expected_to_ship_today_count: Scalars['Int']['output'];
  /** Count of orders expected to ship tomorrow that the order could be batched with. If zero, NOT a candidate for selective holdback */
  expected_to_ship_tomorrow_count: Scalars['Int']['output'];
  /** Whether to holdback the order until tomorrow, so that it can ship with other batch siblings. */
  holdback_until_tomorrow: Scalars['Boolean']['output'];
  search_result: LabsGqlBatchShipCandidateOrderDto;
  /** Shipments created today that the order could be batched with */
  shipped_today: Array<LabsGqlBatchShipCandidateOrderDto>;
};

export type LabsGqlBatchShipDecisionResult = {
  __typename?: 'BatchShipDecisionResult';
  /** The label of the batch shelf on which this order should be placed */
  batch_shelf_label: Scalars['String']['output'];
  /** Count of orders expected to ship today that have not yet. If zero, either add to a batch or ship immediately */
  expected_to_ship_today_count: Scalars['Int']['output'];
  /** Count of orders expected to ship tomorrow that the order could be batched with. If zero, NOT a candidate for selective holdback */
  expected_to_ship_tomorrow_count: Scalars['Int']['output'];
  /** Whether the order has been marked as packed */
  has_already_been_packed: Scalars['Boolean']['output'];
  /** Whether we expect to holdback the order until tomorrow (or later), so that it can ship with other batch siblings. */
  holdback_until_tomorrow: Scalars['Boolean']['output'];
  /** Whether the order is part of a split order */
  is_split_order: Scalars['Boolean']['output'];
  search_result: LabsGqlBatchShipCandidateOrderDto;
  /** Whether the order should be shipped alone, rather than placed on a batch shelf */
  ship_solo: Scalars['Boolean']['output'];
  /** Shipments created today that the order could be batched with */
  shipped_today: Array<LabsGqlBatchShipCandidateOrderDto>;
};

export type LabsGqlBatchShipManifestDto = {
  __typename?: 'BatchShipManifestDTO';
  batches: Array<LabsGqlBatchShipManifestRowDto>;
};

export type LabsGqlBatchShipManifestRowDto = {
  __typename?: 'BatchShipManifestRowDTO';
  /** The orderIds for the specific orders in this batch */
  orderIds: Array<Scalars['String']['output']>;
  /** The order numbers for the specific orders in this batch */
  orderNumbers: Array<Scalars['String']['output']>;
  practiceName: Scalars['String']['output'];
  /** The identifier for the shelf this batch is on, irrespective of any shelf subdivision */
  primaryShelf: Scalars['String']['output'];
  /** Deprecated */
  shelfLocation: Scalars['String']['output'];
  /** The shipping speed for this batch */
  shipSpeed: Scalars['String']['output'];
  /** The identifier for the shelf subdivision */
  subShelf: Scalars['String']['output'];
};

export type LabsGqlBiasRule = {
  __typename?: 'BiasRule';
  condition_json: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  disabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated bias rules are now lab filter rules only and do not use score */
  score?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['DateTime']['output'];
  /**
   * this should only be true for all new rules since we only use bias rules for lab filter rules now
   * @deprecated should be true for all new rules, will be removed once old rules with false are cleared out
   */
  veto: Scalars['Boolean']['output'];
};

export type LabsGqlBillingContactUser = {
  __typename?: 'BillingContactUser';
  active: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  type: LabsGqlBillingContactUserType;
};

export enum LabsGqlBillingContactUserType {
  DoctorPreferencesProfile = 'DOCTOR_PREFERENCES_PROFILE',
  RetainerStaffMember = 'RETAINER_STAFF_MEMBER'
}

export type LabsGqlBillingCreditCategory = {
  __typename?: 'BillingCreditCategory';
  archived: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlBillingEmailResult = {
  __typename?: 'BillingEmailResult';
  already_sent: Scalars['Int']['output'];
  did_not_send: Scalars['Int']['output'];
  failed_to_send: Scalars['Int']['output'];
  reasons_not_sent: Array<Scalars['String']['output']>;
  success: Scalars['Int']['output'];
};

export type LabsGqlBillingPrimaryContactEmail = {
  __typename?: 'BillingPrimaryContactEmail';
  organization_id: Scalars['String']['output'];
  primary_contact_email?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlBillingSummaryEmail = {
  __typename?: 'BillingSummaryEmail';
  html: Scalars['String']['output'];
  reason_cannot_send?: Maybe<Scalars['String']['output']>;
  sent_to_partner: Scalars['Boolean']['output'];
};

export type LabsGqlBillingUsageSplitChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'BillingUsageSplitChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value?: Maybe<LabsGqlPartnerPaymentSplitConfig>;
  old_value?: Maybe<LabsGqlPartnerPaymentSplitConfig>;
};

export enum LabsGqlBiteConcernOptions {
  Improve = 'Improve',
  ShowOutcome = 'ShowOutcome'
}

export type LabsGqlBridgeItem = LabsGqlIOrderItemBase & {
  __typename?: 'BridgeItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  units: Array<LabsGqlOrderItemSingleToothUnit>;
};

export type LabsGqlBridgeItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  units: Array<LabsGqlOrderItemSingleToothUnitInput>;
};

export type LabsGqlBulkCreateCreditsWorkflowInput = {
  credits: Array<LabsGqlCreateCreditInput>;
};

export type LabsGqlBulkCreateCreditsWorkflowResult = {
  __typename?: 'BulkCreateCreditsWorkflowResult';
  workflowId: Scalars['String']['output'];
};

export type LabsGqlBulkCreateScanbodyRequestCommand = {
  practice_id?: InputMaybe<Scalars['String']['input']>;
  scanbody_requests: Array<LabsGqlBulkScanbodyRequestEntry>;
};

export type LabsGqlBulkCreateUserResult = {
  __typename?: 'BulkCreateUserResult';
  bulk_results: Array<LabsGqlCreateUserResult>;
};

export type LabsGqlBulkCreateUsersCommand = {
  usersWithRoles: Array<LabsGqlCreateUserCommand>;
};

export type LabsGqlBulkDownloadResult = {
  __typename?: 'BulkDownloadResult';
  downloadUrl: Scalars['String']['output'];
};

export type LabsGqlBulkPriceAdjustmentOrderResult = {
  __typename?: 'BulkPriceAdjustmentOrderResult';
  message?: Maybe<Scalars['String']['output']>;
  order_id: Scalars['String']['output'];
};

export type LabsGqlBulkPriceUpsertRes = {
  __typename?: 'BulkPriceUpsertRes';
  /** array of error messages */
  errors: Array<Scalars['String']['output']>;
};

export type LabsGqlBulkRegisterInvoiceItemCommand = {
  rows: Array<LabsGqlBulkRegisterInvoiceItemRow>;
};

export type LabsGqlBulkRegisterInvoiceItemResult = {
  __typename?: 'BulkRegisterInvoiceItemResult';
  results: Array<LabsGqlPracticeBillingRecurringItemsDto>;
};

export type LabsGqlBulkRegisterInvoiceItemRow = {
  category: LabsGqlOneTimeChargeCategory;
  description: Scalars['String']['input'];
  practice_id: Scalars['String']['input'];
  price_cents: Scalars['Int']['input'];
};

export type LabsGqlBulkRemoveStaffMembersCommand = {
  organization_id: Scalars['String']['input'];
  removed_staff_ids: Array<Scalars['String']['input']>;
};

export type LabsGqlBulkScanbodyRequestEntry = {
  abutment_type?: InputMaybe<Scalars['String']['input']>;
  address: Scalars['String']['input'];
  appointment_date: Scalars['DateTime']['input'];
  case_readiness_id?: InputMaybe<Scalars['String']['input']>;
  connection: Scalars['String']['input'];
  connection_type?: InputMaybe<Scalars['String']['input']>;
  manufacturer: Scalars['String']['input'];
  requested_scanbody_id?: InputMaybe<Scalars['String']['input']>;
  requesting_doctor_id?: InputMaybe<Scalars['String']['input']>;
  surgical_report_file?: InputMaybe<Scalars['String']['input']>;
  system: Scalars['String']['input'];
};

export type LabsGqlBulkToggleWillBeChargedCcFeeCommand = {
  rows: Array<LabsGqlToggleWillBeChargedCcFeeEntry>;
};

export type LabsGqlBulkToggleWillBeChargedCcFeeErrorResult = {
  __typename?: 'BulkToggleWillBeChargedCCFeeErrorResult';
  error: Scalars['String']['output'];
  partner_id: Scalars['String']['output'];
};

export type LabsGqlBulkToggleWillBeChargedCcFeeResult = {
  __typename?: 'BulkToggleWillBeChargedCCFeeResult';
  errors: Array<LabsGqlBulkToggleWillBeChargedCcFeeErrorResult>;
  results: Array<LabsGqlIPartnerBilling>;
};

export type LabsGqlBulkUpdateResult = {
  __typename?: 'BulkUpdateResult';
  success: Scalars['Boolean']['output'];
};

export type LabsGqlBulkUploadPartnerPriceUpsert = {
  partner_id: Scalars['String']['input'];
  upserts: Array<LabsGqlBulkUploadPartnerPriceUpsertEntry>;
};

export type LabsGqlBulkUploadPartnerPriceUpsertEntry = {
  /** If set to null, the price will be removed */
  price_cents?: InputMaybe<Scalars['Int']['input']>;
  price_name: Scalars['String']['input'];
};

export type LabsGqlBulkUpsertLabPriceLabPrices = {
  upserts: Array<LabsGqlUpsertLabPriceLabPrices>;
};

export type LabsGqlBulkUpsertOrganizationPricesCommand = {
  upserts: Array<LabsGqlUpsertOrganizationPrices>;
};

export type LabsGqlCrmAttachment = {
  __typename?: 'CRMAttachment';
  content_type: Scalars['String']['output'];
  content_url: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  file_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  size_bytes: Scalars['Float']['output'];
};

export type LabsGqlCrmChatConversation = {
  __typename?: 'CRMChatConversation';
  id: Scalars['String']['output'];
  metadata: Array<LabsGqlCrmChatConversationMetadata>;
};

export type LabsGqlCrmChatConversationMetadata = {
  __typename?: 'CRMChatConversationMetadata';
  key: Scalars['String']['output'];
  value: Scalars['MetafieldJSON']['output'];
};

export type LabsGqlCrmChatConversationMetadataInput = {
  key: Scalars['String']['input'];
  value: Scalars['MetafieldJSON']['input'];
};

export type LabsGqlCrmChatFormContent = {
  block_chat_input?: InputMaybe<Scalars['Boolean']['input']>;
  fields?: InputMaybe<Array<LabsGqlCrmChatFormField>>;
};

export type LabsGqlCrmChatFormField = {
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  options?: InputMaybe<Array<LabsGqlCrmChatFormOption>>;
  type: LabsGqlCrmChatFormFieldType;
};

export enum LabsGqlCrmChatFormFieldType {
  Email = 'EMAIL',
  Select = 'SELECT',
  Text = 'TEXT'
}

export type LabsGqlCrmChatFormOption = {
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LabsGqlCrmChatMessage = {
  __typename?: 'CRMChatMessage';
  id: Scalars['String']['output'];
};

export type LabsGqlCrmChatTextContent = {
  text: Scalars['String']['input'];
};

export type LabsGqlCrmChatUser = {
  __typename?: 'CRMChatUser';
  external_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type LabsGqlCrmPractice = {
  __typename?: 'CRMPractice';
  has_completed_training: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  /** @deprecated Deprecated. Please use `nullable_loyalty_details` */
  loyalty_details: LabsGqlPracticeLoyaltyDetails;
  nullable_loyalty_details?: Maybe<LabsGqlPracticeLoyaltyDetails>;
  salesforce_account_id?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlCrmSupportTeam {
  ProductSupport = 'PRODUCT_SUPPORT',
  TestTeam = 'TEST_TEAM',
  Unknown = 'UNKNOWN'
}

export type LabsGqlCrmTicket = {
  __typename?: 'CRMTicket';
  assignee?: Maybe<LabsGqlCrmUser>;
  body?: Maybe<Scalars['String']['output']>;
  custom_fields: Array<LabsGqlCrmTicketCustomFieldValue>;
  id: Scalars['String']['output'];
  requester: LabsGqlCrmUser;
  status: LabsGqlCrmTicketStatus;
  submitter?: Maybe<LabsGqlCrmUser>;
  tags: Array<Scalars['String']['output']>;
  team?: Maybe<LabsGqlCrmSupportTeam>;
  ticket_type?: Maybe<LabsGqlCrmTicketForm>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlCrmTicketComment = {
  __typename?: 'CRMTicketComment';
  attachments: Array<LabsGqlCrmAttachment>;
  author: LabsGqlCrmUser;
  created_at: Scalars['DateTime']['output'];
  html_body: Scalars['String']['output'];
  id: Scalars['String']['output'];
  is_public: Scalars['Boolean']['output'];
  text_body: Scalars['String']['output'];
  via_api: Scalars['Boolean']['output'];
};

export type LabsGqlCrmTicketComments = {
  __typename?: 'CRMTicketComments';
  comments: Array<LabsGqlCrmTicketComment>;
  next_page?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlCrmTicketCustomField {
  ClosureReason = 'CLOSURE_REASON',
  DandyEventId = 'DANDY_EVENT_ID',
  LastSeen = 'LAST_SEEN',
  LiveCasePlanningRequestId = 'LIVE_CASE_PLANNING_REQUEST_ID',
  LiveLabTechMeetingRequestId = 'LIVE_LAB_TECH_MEETING_REQUEST_ID',
  OpsPortalTicketId = 'OPS_PORTAL_TICKET_ID',
  OrderId = 'ORDER_ID',
  PartnerId = 'PARTNER_ID',
  PrefId = 'PREF_ID',
  Unknown = 'UNKNOWN'
}

export type LabsGqlCrmTicketCustomFieldValue = {
  __typename?: 'CRMTicketCustomFieldValue';
  field: LabsGqlCrmTicketCustomField;
  value?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlCrmTicketCustomFieldValueInput = {
  field: LabsGqlCrmTicketCustomField;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum LabsGqlCrmTicketForm {
  Aligners = 'ALIGNERS',
  CrownBridge = 'CROWN_BRIDGE',
  Dentures = 'DENTURES',
  Implants = 'IMPLANTS',
  Removables = 'REMOVABLES',
  Unknown = 'UNKNOWN'
}

export enum LabsGqlCrmTicketStatus {
  Closed = 'CLOSED',
  Hold = 'HOLD',
  New = 'NEW',
  Open = 'OPEN',
  Pending = 'PENDING',
  Solved = 'SOLVED'
}

export type LabsGqlCrmUser = {
  __typename?: 'CRMUser';
  canonical_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  photo_url?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlCstGroupNames {
  Aligners = 'aligners',
  CrownBridge = 'crownBridge',
  Dentures = 'dentures',
  Implants = 'implants',
  Removables = 'removables'
}

/** A smile library. */
export type LabsGqlCadSmileLibraryDto = {
  __typename?: 'CadSmileLibraryDto';
  /** The archived status of the smile library. */
  archived: Scalars['Boolean']['output'];
  /** The id of the smile library. */
  id: Scalars['String']['output'];
  /** The name of the smile library. */
  name: Scalars['String']['output'];
  /** The revisions of the smile library. */
  revisions?: Maybe<Array<LabsGqlCadSmileLibraryRevisionDto>>;
};

/** A smile library revision. */
export type LabsGqlCadSmileLibraryRevisionDto = {
  __typename?: 'CadSmileLibraryRevisionDto';
  /** The date the revision was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The id of the smile library revision. */
  id: Scalars['String']['output'];
  /** The path to the preview image for the revision in GCS. */
  previewImagePath: Scalars['String']['output'];
  /** The tooth templates associated with this revision of the smile library. */
  templates?: Maybe<Array<LabsGqlCadSmileLibraryRevisionToothTemplateDto>>;
};

/** A smile library revision tooth template. */
export type LabsGqlCadSmileLibraryRevisionToothTemplateDto = {
  __typename?: 'CadSmileLibraryRevisionToothTemplateDto';
  /** The path to the tooth template. */
  dcmPath: Scalars['String']['output'];
  /** The id of the tooth template. */
  id: Scalars['String']['output'];
  /** The tooth number used by the template. */
  unn: Scalars['Float']['output'];
};

export type LabsGqlCadSmileLibraryToothTemplateDto = {
  dcmPath: Scalars['String']['input'];
  unn: Scalars['Float']['input'];
};

export type LabsGqlCalendlyEventDto = {
  __typename?: 'CalendlyEventDto';
  created_at: Scalars['DateTime']['output'];
  end_time: Scalars['DateTime']['output'];
  event_type_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meeting_notes?: Maybe<Scalars['String']['output']>;
  meeting_url: Scalars['String']['output'];
  previous_event_id?: Maybe<Scalars['ID']['output']>;
  reschedule_num: Scalars['Float']['output'];
  start_time: Scalars['DateTime']['output'];
  status: LabsGqlCalendlyEventStatus;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlCalendlyEventStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Rescheduled = 'RESCHEDULED'
}

export type LabsGqlCalendlyGetNextAvailableLdrTimeResponse = {
  __typename?: 'CalendlyGetNextAvailableLdrTimeResponse';
  invitees_remaining: Scalars['Float']['output'];
  scheduling_url: Scalars['String']['output'];
  start_time: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type LabsGqlCameraSettingsDto = {
  __typename?: 'CameraSettingsDto';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LabsGqlCancelAndResubmitCommand = {
  items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
  order_id: Scalars['String']['input'];
  updates: Array<LabsGqlSingleItemUpdateInput>;
};

export type LabsGqlCancelOrderBillingActionInput = {
  cancelOrderBillingActionType: LabsGqlCancelOrderBillingActionType;
  /** The invoice to credit, if `CancelOrderBillingActionType` is `credit` */
  creditInvoiceId?: InputMaybe<Scalars['String']['input']>;
};

export enum LabsGqlCancelOrderBillingActionType {
  Credit = 'credit',
  Refund = 'refund',
  SetAmountDueToZero = 'setAmountDueToZero'
}

export type LabsGqlCancelOrderReturnCommand = {
  cancellation_reason: Scalars['String']['input'];
  return_id: Scalars['String']['input'];
};

export type LabsGqlCanceledLabOrderResult = {
  __typename?: 'CanceledLabOrderResult';
  canceled_at?: Maybe<Scalars['DateTime']['output']>;
  fulfillment_number?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  manufacturer_id: Scalars['String']['output'];
  order_id: Scalars['String']['output'];
  /** A unique identifier short enough to be human readable */
  order_number: Scalars['String']['output'];
  patient_first_name: Scalars['String']['output'];
  patient_id: Scalars['String']['output'];
  patient_last_name: Scalars['String']['output'];
  /** If canceled, the percentage that was completed by the lab, as a number between 0-100 */
  percentage_completed?: Maybe<Scalars['Float']['output']>;
};

export enum LabsGqlCancellationReasonCode {
  AnotherLab = 'AnotherLab',
  AnotherLabOther = 'AnotherLabOther',
  AnotherLabProductQuality = 'AnotherLabProductQuality',
  AnotherLabServiceQuality = 'AnotherLabServiceQuality',
  AutoCancelationAgingCase = 'AutoCancelationAgingCase',
  CancelAndResubmit = 'CancelAndResubmit',
  DuplicateOrder = 'DuplicateOrder',
  OrderTooLate = 'OrderTooLate',
  Other = 'Other',
  PatientCancelled = 'PatientCancelled',
  PatientPostponed = 'PatientPostponed'
}

export type LabsGqlCapabilityMatch = {
  __typename?: 'CapabilityMatch';
  designerLevel?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  level: LabsGqlOrderDesignCapabilityLevel;
  passes: Scalars['Boolean']['output'];
  type: LabsGqlOrderDesignCapabilityType;
};

export enum LabsGqlCapacityLimitGranularity {
  Day = 'Day',
  Hour = 'Hour'
}

export enum LabsGqlCapacityLimitUnitType {
  Legacy = 'Legacy',
  Uta = 'UTA'
}

export type LabsGqlCapacityRule = {
  __typename?: 'CapacityRule';
  active_sla_days: Array<LabsGqlDayOfWeek>;
  created_at: Scalars['DateTime']['output'];
  disabled: Scalars['Boolean']['output'];
  fabrication_days_rule_set: Array<LabsGqlFabricationDaysRule>;
  id: Scalars['ID']['output'];
  item_condition_json: Scalars['String']['output'];
  /** @deprecated always uses the SLA day (11am-11am ET) now */
  limit_range: LabsGqlRoutingMetricsRange;
  manufacturer_id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order_condition_json: Scalars['String']['output'];
  order_limit?: Maybe<Scalars['Int']['output']>;
  /** Indicates which routing priority group this rule belongs to */
  priority: Scalars['Int']['output'];
  /** Indicates that this rule applies for design capacity */
  supports_design: Scalars['Boolean']['output'];
  /** Indicates that this rule applies for manufacturing capacity */
  supports_manufacturing: Scalars['Boolean']['output'];
  unit_limit?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlCapacityRuleLoadResult = {
  __typename?: 'CapacityRuleLoadResult';
  name: Scalars['String']['output'];
  orderCount: Scalars['Int']['output'];
  ruleId: Scalars['String']['output'];
  unitCount: Scalars['Int']['output'];
};

export type LabsGqlCapacityStationDto = {
  __typename?: 'CapacityStationDTO';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization_id: Scalars['String']['output'];
  unit_type: LabsGqlCapacityLimitUnitType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlCapacityStationPoolDto = {
  __typename?: 'CapacityStationPoolDTO';
  capacity_station_rule_id: Scalars['ID']['output'];
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  end_date: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  last_event_id: Scalars['Int']['output'];
  start_date: Scalars['DateTime']['output'];
  unit_limit: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlCapacityStationRuleDto = {
  __typename?: 'CapacityStationRuleDTO';
  capacity_station_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  default_unit_granularity: LabsGqlCapacityLimitGranularity;
  default_unit_limit: Scalars['Int']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  design_station_codes?: Maybe<Array<Scalars['String']['output']>>;
  disabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lab_product_codes?: Maybe<Array<Scalars['String']['output']>>;
  matching_logic_json?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  work_order_matching_logic_json?: Maybe<Scalars['String']['output']>;
  work_order_type: LabsGqlWorkOrderTypeEnum;
};

export type LabsGqlCapacityWindowDto = {
  __typename?: 'CapacityWindowDTO';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type LabsGqlCarestreamCase = {
  __typename?: 'CarestreamCase';
  biteRegistration: Scalars['Boolean']['output'];
  caseType: LabsGqlCarestreamCaseType;
  gumShade: LabsGqlCarestreamGumShade;
  mandibular: LabsGqlCarestreamProduct;
  maxillary: LabsGqlCarestreamProduct;
  occlusionClass: LabsGqlCarestreamOcclusion;
  toothShade: Scalars['String']['output'];
};

export enum LabsGqlCarestreamCaseType {
  Denture = 'Denture'
}

export type LabsGqlCarestreamFile = {
  __typename?: 'CarestreamFile';
  acquisitionDate?: Maybe<Scalars['DateTime']['output']>;
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Float']['output'];
  numberOfSlices?: Maybe<Scalars['Float']['output']>;
  type: LabsGqlCarestreamFileType;
};

export enum LabsGqlCarestreamFileType {
  Cbct = 'CBCT',
  Denture = 'Denture',
  Mesh = 'Mesh',
  OtherDicom = 'OtherDicom',
  OtherNotDicom = 'OtherNotDicom',
  Waxup = 'Waxup'
}

export enum LabsGqlCarestreamGender {
  Female = 'Female',
  Male = 'Male',
  OtherUnknown = 'OtherUnknown'
}

export enum LabsGqlCarestreamGumShade {
  Dark = 'Dark',
  Ethnic = 'Ethnic',
  Light = 'Light',
  LightRed = 'LightRed',
  Medium = 'Medium'
}

export enum LabsGqlCarestreamOcclusion {
  Class1 = 'Class1',
  Class2 = 'Class2',
  Class3 = 'Class3'
}

export type LabsGqlCarestreamOrderMetadata = {
  __typename?: 'CarestreamOrderMetadata';
  caseId: Scalars['String']['output'];
  caseMetadata: LabsGqlCarestreamCase;
  country: Scalars['String']['output'];
  filesMetadata: Array<LabsGqlCarestreamFile>;
  language: Scalars['String']['output'];
  loginHint: Scalars['String']['output'];
  patientBirthDate?: Maybe<Scalars['String']['output']>;
  patientFirstName: Scalars['String']['output'];
  patientGender?: Maybe<LabsGqlCarestreamGender>;
  patientId: Scalars['String']['output'];
  patientLastName: Scalars['String']['output'];
  spId: Scalars['Float']['output'];
};

export enum LabsGqlCarestreamProduct {
  Denture = 'Denture',
  Nothing = 'Nothing',
  Stone = 'Stone',
  Waxrim = 'Waxrim'
}

export type LabsGqlCartAlignerItem = LabsGqlICartItemBase & {
  __typename?: 'CartAlignerItem';
  aligner_treatment_metadata?: Maybe<LabsGqlOrderItemAlignerTreatmentMetadata>;
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartItemArchUnit;
};

export type LabsGqlCartAlignerItemInput = {
  aligner_treatment_metadata?: InputMaybe<LabsGqlOrderItemAlignerTreatmentMetadataInput>;
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlCartItemArchUnitInput;
};

export type LabsGqlCartBridgeItem = LabsGqlICartItemBase & {
  __typename?: 'CartBridgeItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  units: Array<LabsGqlCartItemSingleToothUnit>;
};

export type LabsGqlCartBridgeItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  units: Array<LabsGqlCartItemSingleToothUnitInput>;
};

export type LabsGqlCartCrownItem = LabsGqlICartItemBase & {
  __typename?: 'CartCrownItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartItemSingleToothUnit;
};

export type LabsGqlCartCrownItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlCartItemSingleToothUnitInput;
};

/** A delivery option for checkout. */
export type LabsGqlCartDeliveryOption = {
  __typename?: 'CartDeliveryOption';
  /** The estimated delivery date. */
  delivery_date: Scalars['DateTime']['output'];
  /**
   * The estimated design preview date.
   *
   * Only relevant if the order requires a waxup.
   */
  design_preview_eta?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Always null. For legacy feature that is no longer implemented. */
  expected_delivery_days?: Maybe<Scalars['Float']['output']>;
  /** The text describing the SLA modifier to display to the end-user. */
  info_text?: Maybe<Scalars['String']['output']>;
  /** The SLA modifier that affects the delivery date. */
  sla_modifier: LabsGqlCartSlaModifier;
};

export type LabsGqlCartDentureItem = LabsGqlICartItemBase & {
  __typename?: 'CartDentureItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  denture_fabrication_method?: Maybe<LabsGqlDenturesFabricationMethod>;
  denture_mouth_scan_method?: Maybe<LabsGqlDentureMouthScanMethod>;
  denture_production_metadata?: Maybe<LabsGqlOrderItemDentureProductionMetadata>;
  denture_production_type?: Maybe<LabsGqlDenturesProductionType>;
  denture_type?: Maybe<LabsGqlDentureType>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartItemArchUnit;
};

export type LabsGqlCartDentureItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  denture_fabrication_method?: InputMaybe<LabsGqlDenturesFabricationMethod>;
  denture_mouth_scan_method?: InputMaybe<LabsGqlDentureMouthScanMethod>;
  denture_production_metadata?: InputMaybe<LabsGqlOrderItemDentureProductionMetadataInput>;
  denture_production_type?: InputMaybe<LabsGqlDenturesProductionType>;
  denture_type?: InputMaybe<LabsGqlDentureType>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlCartItemArchUnitInput;
};

export type LabsGqlCartFieldDefaultPrice = {
  __typename?: 'CartFieldDefaultPrice';
  amount_cents: Scalars['Int']['output'];
  field_id: Scalars['String']['output'];
  field_price_rule_id: Scalars['String']['output'];
};

export type LabsGqlCartFieldDefaultPriceInput = {
  amount_cents: Scalars['Int']['input'];
  field_id: Scalars['String']['input'];
  field_price_rule_id: Scalars['String']['input'];
};

export type LabsGqlCartImplantBridgeItem = LabsGqlICartItemBase & {
  __typename?: 'CartImplantBridgeItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  implants: Array<LabsGqlCartImplantToothGroup>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  restoratives: Array<LabsGqlCartItemSingleToothUnit>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
};

export type LabsGqlCartImplantBridgeItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  implants: Array<LabsGqlCartImplantToothGroupInput>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  restoratives: Array<LabsGqlCartItemSingleToothUnitInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
};

export type LabsGqlCartImplantItem = LabsGqlICartItemBase & {
  __typename?: 'CartImplantItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartImplantToothGroup;
};

export type LabsGqlCartImplantItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlCartImplantToothGroupInput;
};

export type LabsGqlCartImplantToothGroup = {
  __typename?: 'CartImplantToothGroup';
  abutment: LabsGqlCartItemUnitMetadata;
  crown: LabsGqlCartItemUnitMetadata;
  implant_metadata: LabsGqlCartItemImplantMetadata;
  unn: Scalars['ToothNumber']['output'];
};

export type LabsGqlCartImplantToothGroupInput = {
  abutment: LabsGqlCartItemUnitMetadataInput;
  crown: LabsGqlCartItemUnitMetadataInput;
  implant_metadata: LabsGqlCartItemImplantMetadataInput;
  unn: Scalars['Int']['input'];
};

export type LabsGqlCartInlayItem = LabsGqlICartItemBase & {
  __typename?: 'CartInlayItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartItemSingleToothUnit;
};

export type LabsGqlCartInlayItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlCartItemSingleToothUnitInput;
};

export type LabsGqlCartItemArchUnit = {
  __typename?: 'CartItemArchUnit';
  arch?: Maybe<LabsGqlOrderItemArch>;
  material?: Maybe<Scalars['String']['output']>;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlCartItemArchUnitInput = {
  arch: LabsGqlOrderItemArch;
  material?: InputMaybe<Scalars['String']['input']>;
  unit_type: Scalars['String']['input'];
};

/** The criteria that must be set for a field to display */
export type LabsGqlCartItemFieldDisplayCriteria = {
  __typename?: 'CartItemFieldDisplayCriteria';
  link_relationship?: Maybe<Scalars['String']['output']>;
  material_type?: Maybe<Scalars['String']['output']>;
  teeth?: Maybe<Array<Scalars['String']['output']>>;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlCartItemImplantMetadata = {
  __typename?: 'CartItemImplantMetadata';
  abutment_part_manufacturer?: Maybe<Scalars['String']['output']>;
  /** Whether this implant item should use authentic/OEM parts. Set internally post-checkout. */
  authentic?: Maybe<Scalars['Boolean']['output']>;
  connection_size?: Maybe<Scalars['String']['output']>;
  /** If generic, the generic parts manufacturer. Set internally post-checkout. */
  generic_manufacturer?: Maybe<LabsGqlOrderItemLinkGenericManufacturer>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  non_preferred_part_reason?: Maybe<Scalars['String']['output']>;
  part_id?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<LabsGqlOrderItemLinkRelationship>;
  scanbody_id?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlCartItemImplantMetadataInput = {
  abutment_part_manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** Whether this implant item should use authentic/OEM parts. Set internally post-checkout. */
  authentic?: InputMaybe<Scalars['Boolean']['input']>;
  connection_size?: InputMaybe<Scalars['String']['input']>;
  /** If generic, the generic parts manufacturer. Set internally post-checkout. */
  generic_manufacturer?: InputMaybe<LabsGqlOrderItemLinkGenericManufacturer>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  non_preferred_part_reason?: InputMaybe<Scalars['String']['input']>;
  part_id?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<LabsGqlOrderItemLinkRelationship>;
  scanbody_id?: InputMaybe<Scalars['String']['input']>;
  system?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCartItemMaterialField = {
  __typename?: 'CartItemMaterialField';
  display_if_any_match: Array<LabsGqlCartItemFieldDisplayCriteria>;
  field: LabsGqlICustomFieldSelect;
};

/** Custom field definition + criteria for it to be displayed */
export type LabsGqlCartItemMetaField = {
  __typename?: 'CartItemMetaField';
  display_if_any_match: Array<LabsGqlCartItemFieldDisplayCriteria>;
  field: LabsGqlICustomField;
};

export type LabsGqlCartItemMultiToothUnit = {
  __typename?: 'CartItemMultiToothUnit';
  material?: Maybe<Scalars['String']['output']>;
  unit_type: Scalars['String']['output'];
  unns: Array<Scalars['ToothNumber']['output']>;
};

export type LabsGqlCartItemMultiToothUnitInput = {
  material?: InputMaybe<Scalars['String']['input']>;
  unit_type: Scalars['String']['input'];
  unns: Array<Scalars['Int']['input']>;
};

export type LabsGqlCartItemPricing = {
  __typename?: 'CartItemPricing';
  amount_due_cents?: Maybe<Scalars['Float']['output']>;
  override_amount_due_cents?: Maybe<Scalars['Float']['output']>;
};

export type LabsGqlCartItemSingleToothUnit = {
  __typename?: 'CartItemSingleToothUnit';
  material?: Maybe<Scalars['String']['output']>;
  /** The pennyweight of precious metal used to fabricate this item, if specified by the manufacturer. */
  precious_metal_pennyweight?: Maybe<Scalars['Float']['output']>;
  unit_type: Scalars['String']['output'];
  unn: Scalars['ToothNumber']['output'];
};

export type LabsGqlCartItemSingleToothUnitInput = {
  material?: InputMaybe<Scalars['String']['input']>;
  precious_metal_pennyweight?: InputMaybe<Scalars['Float']['input']>;
  unit_type: Scalars['String']['input'];
  unn: Scalars['Int']['input'];
};

export type LabsGqlCartItemSleepApneaManufacturingMetadata = {
  __typename?: 'CartItemSleepApneaManufacturingMetadata';
  should_cover_last_molar_distal?: Maybe<Scalars['Boolean']['output']>;
  should_have_posterior_support_pads?: Maybe<Scalars['Boolean']['output']>;
  should_include_anterior_discluding_ramp?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlCartItemSleepApneaManufacturingMetadataInput = {
  should_cover_last_molar_distal?: InputMaybe<Scalars['Boolean']['input']>;
  should_have_posterior_support_pads?: InputMaybe<Scalars['Boolean']['input']>;
  should_include_anterior_discluding_ramp?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlCartItemSurgicalGuidanceTreatmentPlanMetadata = {
  __typename?: 'CartItemSurgicalGuidanceTreatmentPlanMetadata';
  final_restorative_type?: Maybe<LabsGqlOrderItemSurgicalGuideFinalRestorativeType>;
  patient_cbct_appointment_date?: Maybe<Scalars['String']['output']>;
  planned_procedures?: Maybe<Array<LabsGqlOrderItemSurgicalGuidePlannedProcedureType>>;
  treatment_plan_review_type?: Maybe<LabsGqlOrderItemSurgicalGuideTreatmentPlanReviewType>;
};

export type LabsGqlCartItemSurgicalGuidanceTreatmentPlanMetadataInput = {
  final_restorative_type?: InputMaybe<LabsGqlOrderItemSurgicalGuideFinalRestorativeType>;
  patient_cbct_appointment_date?: InputMaybe<Scalars['String']['input']>;
  planned_procedures?: InputMaybe<Array<LabsGqlOrderItemSurgicalGuidePlannedProcedureType>>;
  treatment_plan_review_type?: InputMaybe<LabsGqlOrderItemSurgicalGuideTreatmentPlanReviewType>;
};

export type LabsGqlCartItemSurgicalGuideImplantMetadata = {
  __typename?: 'CartItemSurgicalGuideImplantMetadata';
  desired_size?: Maybe<Scalars['String']['output']>;
  drill_kit?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlCartItemSurgicalGuideImplantMetadataInput = {
  desired_size?: InputMaybe<Scalars['String']['input']>;
  drill_kit?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  system?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCartItemSurgicalGuideUnit = {
  __typename?: 'CartItemSurgicalGuideUnit';
  implant_metadata: LabsGqlCartItemSurgicalGuideImplantMetadata;
  unit_type: Scalars['String']['output'];
  unns: Array<Scalars['ToothNumber']['output']>;
};

export type LabsGqlCartItemSurgicalGuideUnitInput = {
  implant_metadata: LabsGqlCartItemSurgicalGuideImplantMetadataInput;
  unit_type: Scalars['String']['input'];
  unns: Array<Scalars['Int']['input']>;
};

export type LabsGqlCartItemTooth = {
  __typename?: 'CartItemTooth';
  amount_due_cents?: Maybe<Scalars['Int']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  material?: Maybe<Scalars['String']['output']>;
  material_fields: Array<LabsGqlCartItemMaterialField>;
  override_amount_due_cents?: Maybe<Scalars['Int']['output']>;
  override_amount_due_reason?: Maybe<Scalars['String']['output']>;
  /** The pennyweight of precious metal used to fabricate this item, if specified by the manufacturer. */
  precious_metal_pennyweight?: Maybe<Scalars['Float']['output']>;
  unit_type: Scalars['String']['output'];
  /** UNN tooth numbering from 1-32 */
  unn: Scalars['String']['output'];
};

export type LabsGqlCartItemUnitMetadata = {
  __typename?: 'CartItemUnitMetadata';
  material?: Maybe<Scalars['String']['output']>;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlCartItemUnitMetadataInput = {
  material?: InputMaybe<Scalars['String']['input']>;
  unit_type: Scalars['String']['input'];
};

export type LabsGqlCartItemV2 = LabsGqlCartAlignerItem | LabsGqlCartBridgeItem | LabsGqlCartCrownItem | LabsGqlCartDentureItem | LabsGqlCartImplantBridgeItem | LabsGqlCartImplantItem | LabsGqlCartInlayItem | LabsGqlCartModelItem | LabsGqlCartOtherItem | LabsGqlCartPartialDentureItem | LabsGqlCartRemovableItem | LabsGqlCartSleepApneaItem | LabsGqlCartSurgicalGuideItem | LabsGqlCartTmjItem | LabsGqlCartUnknownItem | LabsGqlCartVeneerItem | LabsGqlCartWaxupItem | LabsGqlCartWellnessScanItem;

export type LabsGqlCartItemV2InputBySku = {
  aligner_items?: InputMaybe<Array<LabsGqlCartAlignerItemInput>>;
  bridge_items?: InputMaybe<Array<LabsGqlCartBridgeItemInput>>;
  crown_items?: InputMaybe<Array<LabsGqlCartCrownItemInput>>;
  denture_items?: InputMaybe<Array<LabsGqlCartDentureItemInput>>;
  implant_bridge_items?: InputMaybe<Array<LabsGqlCartImplantBridgeItemInput>>;
  implant_items?: InputMaybe<Array<LabsGqlCartImplantItemInput>>;
  inlay_items?: InputMaybe<Array<LabsGqlCartInlayItemInput>>;
  model_items?: InputMaybe<Array<LabsGqlCartModelItemInput>>;
  other_items?: InputMaybe<Array<LabsGqlCartOtherItemInput>>;
  partial_denture_items?: InputMaybe<Array<LabsGqlCartPartialDentureItemInput>>;
  removable_items?: InputMaybe<Array<LabsGqlCartRemovableItemInput>>;
  sleep_apnea_items?: InputMaybe<Array<LabsGqlCartSleepApneaItemInput>>;
  surgical_guide_items?: InputMaybe<Array<LabsGqlCartSurgicalGuideItemInput>>;
  tmj_items?: InputMaybe<Array<LabsGqlCartTmjItemInput>>;
  unknown_items?: InputMaybe<Array<LabsGqlCartUnknownItemInput>>;
  veneer_items?: InputMaybe<Array<LabsGqlCartVeneerItemInput>>;
  waxup_items?: InputMaybe<Array<LabsGqlCartWaxupItemInput>>;
};

export type LabsGqlCartModelItem = LabsGqlICartItemBase & {
  __typename?: 'CartModelItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  model_type?: Maybe<LabsGqlOrderItemModelType>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  selected_teeth?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlCartModelItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<LabsGqlOrderItemModelType>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  selected_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit_type: Scalars['String']['input'];
};

export type LabsGqlCartOtherItem = LabsGqlICartItemBase & {
  __typename?: 'CartOtherItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartItemUnitMetadata;
};

export type LabsGqlCartOtherItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlCartItemUnitMetadataInput;
};

export type LabsGqlCartPartialDentureItem = LabsGqlICartItemBase & {
  __typename?: 'CartPartialDentureItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  /** @deprecated Use clasps */
  clasp_type?: Maybe<LabsGqlOrderItemPartialDentureClaspType>;
  clasps?: Maybe<Array<LabsGqlOrderItemPartialDentureClasp>>;
  coverage_type?: Maybe<LabsGqlOrderItemPartialDentureCoverageType>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  partial_production_metadata?: Maybe<LabsGqlOrderItemPartialDentureProductionMetadata>;
  partial_production_type?: Maybe<LabsGqlOrderItemPartialDentureProductionType>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartItemMultiToothUnit;
};

export type LabsGqlCartPartialDentureItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  clasp_type?: InputMaybe<LabsGqlOrderItemPartialDentureClaspType>;
  clasps?: InputMaybe<Array<LabsGqlOrderItemPartialDentureClaspInput>>;
  coverage_type?: InputMaybe<LabsGqlOrderItemPartialDentureCoverageType>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  partial_production_metadata?: InputMaybe<LabsGqlOrderItemPartialDentureProductionMetadataInput>;
  partial_production_type?: InputMaybe<LabsGqlOrderItemPartialDentureProductionType>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlCartItemMultiToothUnitInput;
};

export enum LabsGqlCartRecommendationV2 {
  NotRecommended = 'NotRecommended',
  Recommended = 'Recommended'
}

export type LabsGqlCartRemovableItem = LabsGqlICartItemBase & {
  __typename?: 'CartRemovableItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  clasps?: Maybe<Array<LabsGqlOrderItemRemovableClasp>>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartItemArchUnit;
};

export type LabsGqlCartRemovableItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  clasps?: InputMaybe<Array<LabsGqlOrderItemRemovableClaspInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlCartItemArchUnitInput;
};

export enum LabsGqlCartSlaModifier {
  NoRush = 'NoRush',
  Rush = 'Rush',
  Standard = 'Standard'
}

export type LabsGqlCartSleepApneaItem = LabsGqlICartItemBase & {
  __typename?: 'CartSleepApneaItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  manufacturing_metadata: LabsGqlCartItemSleepApneaManufacturingMetadata;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  sleep_apnea_type?: Maybe<LabsGqlOrderItemSleepApneaType>;
};

export type LabsGqlCartSleepApneaItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  manufacturing_metadata: LabsGqlCartItemSleepApneaManufacturingMetadataInput;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  sleep_apnea_type?: InputMaybe<LabsGqlOrderItemSleepApneaType>;
};

export type LabsGqlCartSurgicalGuideItem = LabsGqlICartItemBase & {
  __typename?: 'CartSurgicalGuideItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  surgical_guide_type?: Maybe<LabsGqlOrderItemSurgicalGuideType>;
  treatment_plan_metadata: LabsGqlCartItemSurgicalGuidanceTreatmentPlanMetadata;
  unit: LabsGqlCartItemSurgicalGuideUnit;
};

export type LabsGqlCartSurgicalGuideItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  surgical_guide_type?: InputMaybe<LabsGqlOrderItemSurgicalGuideType>;
  treatment_plan_metadata: LabsGqlCartItemSurgicalGuidanceTreatmentPlanMetadataInput;
  unit: LabsGqlCartItemSurgicalGuideUnitInput;
};

export type LabsGqlCartTmjItem = LabsGqlICartItemBase & {
  __typename?: 'CartTMJItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  manufacturing_metadata: LabsGqlOrderItemTmjManufacturingMetadata;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  tmj_type?: Maybe<LabsGqlOrderItemTmjType>;
  unit: LabsGqlCartItemArchUnit;
};

export type LabsGqlCartTmjItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  manufacturing_metadata: LabsGqlOrderItemTmjManufacturingMetadataInput;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  tmj_type?: InputMaybe<LabsGqlOrderItemTmjType>;
  unit: LabsGqlCartItemArchUnitInput;
};

export type LabsGqlCartUnknownItem = LabsGqlICartItemBase & {
  __typename?: 'CartUnknownItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
};

export type LabsGqlCartUnknownItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
};

export type LabsGqlCartVeneerItem = LabsGqlICartItemBase & {
  __typename?: 'CartVeneerItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartItemSingleToothUnit;
};

export type LabsGqlCartVeneerItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlCartItemSingleToothUnitInput;
};

export type LabsGqlCartWaxupItem = LabsGqlICartItemBase & {
  __typename?: 'CartWaxupItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  selected_teeth?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlCartWaxupItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  selected_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit_type: Scalars['String']['input'];
};

export type LabsGqlCartWellnessScanItem = LabsGqlICartItemBase & {
  __typename?: 'CartWellnessScanItem';
  attaching_scans_to_order_id?: Maybe<Scalars['String']['output']>;
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  lower_arch_denture_scan_type?: Maybe<LabsGqlOrderItemDentureScanType>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  prepped_teeth?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlCartItemArchUnit;
  upper_arch_denture_scan_type?: Maybe<LabsGqlOrderItemDentureScanType>;
};

export type LabsGqlCaseReadinessDto = {
  __typename?: 'CaseReadinessDTO';
  created_at: Scalars['DateTime']['output'];
  doctor_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items: Array<LabsGqlCartItemV2>;
  patient: LabsGqlPatientDto;
  patient_id: Scalars['String']['output'];
  practice_id: Scalars['String']['output'];
  scanbody_requests: Array<LabsGqlScanbodyRequestDto>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlCategoryDto = {
  __typename?: 'CategoryDTO';
  archived: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  kind: LabsGqlCategoryKind;
  label: Scalars['String']['output'];
  requires_further_info: Scalars['Boolean']['output'];
  subcategories?: Maybe<Array<LabsGqlSubcategoryDto>>;
  updated_at: Scalars['DateTime']['output'];
  visible_to_internal: Scalars['Boolean']['output'];
  visible_to_lab: Scalars['Boolean']['output'];
  visible_to_practice: Scalars['Boolean']['output'];
};

export enum LabsGqlCategoryKind {
  OrderSlaAdjustment = 'OrderSlaAdjustment'
}

export type LabsGqlChairsideSignedDownloadUrl = {
  __typename?: 'ChairsideSignedDownloadUrl';
  downloadUrl: Scalars['String']['output'];
};

export type LabsGqlChairsideUpload = {
  __typename?: 'ChairsideUpload';
  path: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type LabsGqlChangeDeliveryAddressCommand = {
  address_id: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type LabsGqlChangeDesignSoftwareCommand = {
  new_design_software: LabsGqlAlignerDesignSoftware;
  order_id: Scalars['String']['input'];
};

export type LabsGqlChangeManufacturerCommand = {
  disable_notification?: InputMaybe<Scalars['Boolean']['input']>;
  disable_sla_reset?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturerId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  /** Why are we changing lab */
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlChangePreferenceStaffMemberCommand = {
  preferences_id: Scalars['String']['input'];
  staff_member_id: Scalars['String']['input'];
};

export type LabsGqlChangePriceNameCommand = {
  name: Scalars['String']['input'];
  price_id: Scalars['String']['input'];
};

export enum LabsGqlChatEntityTypes {
  Order = 'Order'
}

export enum LabsGqlChatExternalSyncStatus {
  Pending = 'pending',
  Skipped = 'skipped',
  Success = 'success'
}

export type LabsGqlChatMessageDto = {
  __typename?: 'ChatMessageDTO';
  /** @deprecated use attachments instead */
  attachment_urls: Array<Scalars['String']['output']>;
  attachments: Array<LabsGqlTimelineAttachment>;
  created_at: Scalars['DateTime']['output'];
  created_by_staff_id: Scalars['String']['output'];
  creation_event_id: Scalars['Float']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  entity_id: Scalars['String']['output'];
  entity_type: LabsGqlChatEntityTypes;
  id: Scalars['ID']['output'];
  kustomer_sync: LabsGqlKustomerSyncStatusDto;
  seen_by_org_ids: Array<Scalars['String']['output']>;
  seen_by_staff_ids: Array<Scalars['String']['output']>;
  sender: LabsGqlStaffMemberWithRelations;
  text: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  /** @deprecated use visible_to_roles instead */
  visible_to_org_ids: Array<Scalars['String']['output']>;
  visible_to_roles: Array<LabsGqlStaffRoleWithAny>;
};

export type LabsGqlCheckCsScheduleArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  schedule_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCheckCsScheduleResult = {
  __typename?: 'CheckCsScheduleResult';
  holidays: Array<LabsGqlHolidaySchema>;
  is_cs_available: Scalars['Boolean']['output'];
  is_holiday: Scalars['Boolean']['output'];
  schedule: Array<LabsGqlIntervalSchema>;
};

export type LabsGqlCheckoutImagesUploadLinkData = {
  doctor_name?: InputMaybe<Scalars['String']['input']>;
  partner_id: Scalars['String']['input'];
  patient_name: Scalars['String']['input'];
  phone_number?: InputMaybe<Scalars['String']['input']>;
  product_name: Scalars['String']['input'];
  upload_link: Scalars['String']['input'];
};

export type LabsGqlClaimTicketActionCommand = {
  action_id: Scalars['ID']['input'];
  /** When null, assigns to the command caller for legacy compatibility. */
  assigned_user_id?: InputMaybe<Scalars['ID']['input']>;
  ticket_id: Scalars['ID']['input'];
};

export type LabsGqlClinicalSupportNotes = {
  __typename?: 'ClinicalSupportNotes';
  last_edited_by_staff_id?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlCloseTicketActionCommand = {
  action_id: Scalars['ID']['input'];
  next_action?: InputMaybe<LabsGqlTicketActionCreationInputData>;
  notes?: InputMaybe<Scalars['String']['input']>;
  resolution: LabsGqlTicketActionCompletionResolution;
  status: LabsGqlActionStatus;
  ticket_id: Scalars['ID']['input'];
};

export type LabsGqlCloseTicketCommand = {
  ticket_id: Scalars['ID']['input'];
};

export type LabsGqlCollectNotificationPreferencesCommand = {
  best_call_hour: Scalars['Float']['input'];
  communication_email?: InputMaybe<Scalars['Boolean']['input']>;
  communication_sms?: InputMaybe<Scalars['Boolean']['input']>;
  contact_email: Scalars['String']['input'];
  contact_phone: Scalars['String']['input'];
  contact_phone_call_number: Scalars['String']['input'];
  daily_digest_timing?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  design_review_alerts?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  doctor_id: Scalars['String']['input'];
  estimated_delivery_alerts?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  estimated_delivery_emails?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  notification_cadence?: InputMaybe<LabsGqlDoctorNotificationCadence>;
  notification_cadence_other_detail?: InputMaybe<Scalars['String']['input']>;
  notification_channel?: InputMaybe<LabsGqlDoctorNotificationChannel>;
  order_tracking_email?: InputMaybe<Scalars['Boolean']['input']>;
  order_tracking_sms?: InputMaybe<Scalars['Boolean']['input']>;
  review_required_email?: InputMaybe<Scalars['Boolean']['input']>;
  review_required_sms?: InputMaybe<Scalars['Boolean']['input']>;
  status_change_email?: InputMaybe<Scalars['Boolean']['input']>;
  status_change_sms?: InputMaybe<Scalars['Boolean']['input']>;
  timezone: Scalars['String']['input'];
  unsubmitted_scan_alert_delay_mins?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  unsubmitted_scans_daily_sms?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  waxup_daily_reminder_sms_timing?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  waxup_ready_for_review_email?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  who_receives_dr_notifications: LabsGqlWhoReceivesDoctorNotifications;
};

export type LabsGqlCompleteDesignPrepTaskCommand = {
  metafields?: InputMaybe<Array<LabsGqlDesignMetafieldSubmissionInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  prep_metadata_path?: InputMaybe<Scalars['String']['input']>;
  prepped_design_file_path: Scalars['String']['input'];
};

export type LabsGqlCompleteDesignReviewTaskCommand = {
  designTabOpenFullstoryLink?: InputMaybe<Scalars['String']['input']>;
  designTabOpenTime?: InputMaybe<Scalars['Float']['input']>;
  file_urls: Array<Scalars['String']['input']>;
  /** Provide guided_qc_results if using guided QC. One of metafields or guided_qc_results must be provided. */
  guided_qc_results?: InputMaybe<Array<LabsGqlDesignReviewGuidedQcResultEntryInput>>;
  /** Provide metafields if using non-guided QC. One of metafields or guided_qc_results must be provided. */
  metafields?: InputMaybe<Array<LabsGqlDesignMetafieldSubmissionInput>>;
  order_id: Scalars['String']['input'];
  /** Design will be rejected if this is supplied */
  rejection?: InputMaybe<LabsGqlDesignReviewRejectionSubmissionInput>;
  submitFullstoryLink?: InputMaybe<Scalars['String']['input']>;
  submitTime?: InputMaybe<Scalars['Float']['input']>;
};

export type LabsGqlCompleteExternalDesignVerificationTaskCommand = {
  order_id: Scalars['String']['input'];
};

export type LabsGqlCompleteInternalDesignTaskCommand = {
  completed_task_type?: InputMaybe<LabsGqlInternalDesignTaskType>;
  design_file_path?: InputMaybe<Scalars['String']['input']>;
  design_prep_feedback?: InputMaybe<Array<Scalars['String']['input']>>;
  device_stl_path?: InputMaybe<Scalars['String']['input']>;
  internal_notes?: InputMaybe<Scalars['String']['input']>;
  is_manual_upload: Scalars['Boolean']['input'];
  metafields?: InputMaybe<Array<LabsGqlDesignMetafieldSubmissionInput>>;
  model_lower_stl_path?: InputMaybe<Scalars['String']['input']>;
  model_upper_stl_path?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
  override_validation?: InputMaybe<Scalars['Boolean']['input']>;
  reduce_opposing?: InputMaybe<LabsGqlDesignReduceOpposingGuideInput>;
  removable_manufacturing_files?: InputMaybe<LabsGqlDesignOrderRemovableManufacturingFilesInput>;
  scan_arch_kind?: InputMaybe<LabsGqlScanArchKind>;
  scan_stump_shades?: InputMaybe<Array<LabsGqlScanToothShadeInput>>;
  skip_video_ddp?: InputMaybe<Scalars['Boolean']['input']>;
  variants?: InputMaybe<Array<LabsGqlDesignRevisionVariant>>;
  video_design_review_url?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCompleteModelDesignTaskCommand = {
  design_file_path: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  override_validation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlCompleteQualityControlItemCommand = {
  complete: Scalars['Boolean']['input'];
  item_id: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type LabsGqlCompleteTreatmentPlanConversionCommand = {
  /** Firebase paths of the individual CTMs/PLYs */
  individual_files: Array<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  /** Raw download URL */
  url: Scalars['String']['input'];
  /** The firebase path to the zip file of all CTMs/PLYs */
  zip_path: Scalars['String']['input'];
};

export type LabsGqlCompletedScanningCaseDto = {
  __typename?: 'CompletedScanningCaseDTO';
  created_at: Scalars['DateTime']['output'];
  doctor_preferences_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items_v2: Array<LabsGqlLabOrderItemV2>;
  partner_id: Scalars['String']['output'];
  patient_id: Scalars['String']['output'];
  scan_export_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

/** Used to set the design Double QC requirement. Primarily done via automations */
export type LabsGqlConfigureDesignReview2Command = {
  /** Why is the order getting double QC? Used for analytics only */
  experiment_group?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
  /** Automation or other source that caused the change */
  source: Scalars['String']['input'];
};

export type LabsGqlConfigureLabPriceLabPricesCommand = {
  lab_configs: Array<LabsGqlLabPriceConfigInput>;
  lab_price_id: Scalars['String']['input'];
};

export type LabsGqlConfigureLabPricePartnerPricesCommand = {
  lab_price_id: Scalars['String']['input'];
  partner_configs: Array<LabsGqlLabPricePartnerConfigInput>;
};

export type LabsGqlConfigureOrderDesignReviewCommand = {
  design_review_required: Scalars['Boolean']['input'];
  /** If true, the lab will be prevented from starting fabrication until design review finished */
  external_design_review_blocking?: InputMaybe<Scalars['Boolean']['input']>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlConfigureOrderDesignerTypeCommand = {
  automate_review_required?: InputMaybe<Scalars['Boolean']['input']>;
  injection_mold_required?: InputMaybe<Scalars['Boolean']['input']>;
  internal_design_prep_required?: InputMaybe<Scalars['Boolean']['input']>;
  internal_design_required?: InputMaybe<Scalars['Boolean']['input']>;
  order_id: Scalars['String']['input'];
  separate_model_design_step?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlConfigureOrderWaxupCommand = {
  doctor_requested?: InputMaybe<Scalars['Boolean']['input']>;
  order_id: Scalars['String']['input'];
  waxup_required: Scalars['Boolean']['input'];
};

export type LabsGqlConfirmOrderReturnReceivedCommand = {
  return_id: Scalars['String']['input'];
};

export enum LabsGqlContactTightnessEnum {
  Loose = 'loose',
  Medium = 'medium',
  Tight = 'tight'
}

/** DEPRECATED: Use PlaceLabOrder instead with previous_fulfillment_order_id. */
export type LabsGqlContinueDenturesFulfillmentWaxRimCommand = {
  add_post_dam?: InputMaybe<Scalars['Boolean']['input']>;
  add_stippling?: InputMaybe<Scalars['Boolean']['input']>;
  bite_adjustment?: InputMaybe<LabsGqlDenturesFulfillmentBiteAdjustmentInput>;
  copy_change_notes?: InputMaybe<Scalars['String']['input']>;
  correct_occlusal_scheme?: InputMaybe<Scalars['Boolean']['input']>;
  festooning_level?: InputMaybe<LabsGqlDenturesFestooningLevel>;
  handle_diastema?: InputMaybe<LabsGqlDenturesHandleDiastemaOption>;
  implant_support?: InputMaybe<LabsGqlDenturesFulfillmentImplantSupportInput>;
  is_try_in?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<LabsGqlLabOrderItemInput>>;
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
  lab_order_id: Scalars['String']['input'];
  lower_arch?: InputMaybe<LabsGqlDenturesFulfillmentCoreArchInput>;
  midline_correction?: InputMaybe<LabsGqlDenturesFulfillmentBiteMidlineCorrectionInput>;
  notes: Scalars['String']['input'];
  number_of_spares?: InputMaybe<Scalars['Int']['input']>;
  photos: Array<LabsGqlDenturesFulfillmentSinglePhotoInput>;
  scan_export_id: Scalars['String']['input'];
  smile_style: LabsGqlSmileStyleChoice;
  upper_arch?: InputMaybe<LabsGqlDenturesFulfillmentCoreArchInput>;
  waxup_requested?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlContractAndAssociatedPracticesDto = {
  __typename?: 'ContractAndAssociatedPracticesDTO';
  activeContractPracticeIds: Array<Scalars['String']['output']>;
  activeContractPractices: Array<LabsGqlOrganizationSummaryDto>;
  allAssociatedPracticeIds: Array<Scalars['String']['output']>;
  allAssociatedPractices: Array<LabsGqlOrganizationSummaryDto>;
  /** @deprecated Kept for backwards compatibility, but moving forward should use allAssociatedPracticeIds and activeContractPracticeIds */
  associatedPracticeIds: Array<Scalars['String']['output']>;
  /** @deprecated Kept for backwards compatibility, but moving forward should use allAssociatedPractices and activeContractPractices */
  associatedPractices: Array<LabsGqlOrganizationSummaryDto>;
  contract: LabsGqlPracticeContract;
  primaryPracticeId: Scalars['String']['output'];
};

/** Used to identify in Kustomer the action triggered from the control panel on the order detail page. */
export enum LabsGqlControlPanelActionType {
  AddFile = 'add_file',
  AddPhoto = 'add_photo',
  LeaveNote = 'leave_note'
}

export type LabsGqlConvertedTreatmentPlanRequest = LabsGqlICompletedTreatmentPlanRequest & LabsGqlITreatmentPlanRequest & {
  __typename?: 'ConvertedTreatmentPlanRequest';
  completed_at: Scalars['DateTime']['output'];
  created_at: Scalars['DateTime']['output'];
  expected_completion_date: Scalars['DateTime']['output'];
  /** The firebase path to the zip file of all CTMs/PLYs */
  full_zip: Scalars['String']['output'];
  /** ID in external providers system */
  id: Scalars['String']['output'];
  /** Firebase paths of the individual CTMs/PLYs */
  individual_files: Array<Scalars['String']['output']>;
  provider: LabsGqlTreatmentPlanProvider;
  /** The firebase path to the zip file of centered STLs for manufacturers */
  stl_zip_path?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type LabsGqlCreateAbutmentPartCommand = {
  analog_sku?: InputMaybe<Scalars['String']['input']>;
  diameter_mm?: InputMaybe<Scalars['Float']['input']>;
  engagement: LabsGqlAbutmentPartEngagement;
  gingival_height_mm?: InputMaybe<Scalars['Float']['input']>;
  manufacturer: Scalars['String']['input'];
  material: LabsGqlAbutmentPartMaterial;
  screw_sku?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  three_shape_category: Scalars['String']['input'];
  three_shape_kit: Scalars['String']['input'];
  three_shape_manufacturer: Scalars['String']['input'];
  three_shape_manufacturing_process: Scalars['String']['input'];
  three_shape_material: Scalars['String']['input'];
  three_shape_system: Scalars['String']['input'];
  top_cap_height_mm?: InputMaybe<Scalars['Float']['input']>;
};

export type LabsGqlCreateActionCapabilityCommand = {
  name: Scalars['String']['input'];
};

export type LabsGqlCreateActionCategoryCommand = {
  name: Scalars['String']['input'];
  parent_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateActionTypeCommand = {
  capability_ids: Array<Scalars['String']['input']>;
  category_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  time_constraint_config_json?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateAttributedCreditsInput = {
  credit_category_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  existing_invoice_id?: InputMaybe<Scalars['ID']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  invoice_item_attributions: Array<LabsGqlInvoiceItemAdjustmentAttributionInput>;
  invoice_to_apply_date?: InputMaybe<Scalars['DateTime']['input']>;
  order_attributions: Array<LabsGqlOrderAdjustmentAttributionInput>;
  order_item_attributions: Array<LabsGqlOrderItemAdjustmentAttributionInput>;
  organization_id: Scalars['ID']['input'];
};

export type LabsGqlCreateBiasRuleCommand = {
  condition_json: Scalars['String']['input'];
  name: Scalars['String']['input'];
  score?: InputMaybe<Scalars['Int']['input']>;
  veto: Scalars['Boolean']['input'];
};

export type LabsGqlCreateBillingCreditCategoryCommand = {
  name: Scalars['String']['input'];
};

export type LabsGqlCreateCrmChatMessageArgs = {
  conversation_id: Scalars['String']['input'];
  form_content?: InputMaybe<LabsGqlCrmChatFormContent>;
  text_content?: InputMaybe<LabsGqlCrmChatTextContent>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateCrmTicketArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  body?: InputMaybe<Scalars['String']['input']>;
  custom_fields?: InputMaybe<Array<LabsGqlCrmTicketCustomFieldValueInput>>;
  html_body?: InputMaybe<Scalars['String']['input']>;
  requester_email?: InputMaybe<Scalars['String']['input']>;
  requester_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LabsGqlCrmTicketStatus>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  team?: InputMaybe<LabsGqlCrmSupportTeam>;
  ticket_type?: InputMaybe<LabsGqlCrmTicketForm>;
  title: Scalars['String']['input'];
};

export type LabsGqlCreateCrmTicketCommentArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  author_id?: InputMaybe<Scalars['String']['input']>;
  html_body?: InputMaybe<Scalars['String']['input']>;
  text_body?: InputMaybe<Scalars['String']['input']>;
  ticket_id: Scalars['String']['input'];
};

export type LabsGqlCreateCrmUserArgs = {
  canonical_id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateCameraSettingsDto = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type LabsGqlCreateCapacityRuleCommand = {
  active_sla_days?: InputMaybe<Array<LabsGqlDayOfWeek>>;
  fabrication_days_rule_set?: InputMaybe<Array<LabsGqlFabricationDaysRuleInput>>;
  item_condition_json?: InputMaybe<Scalars['String']['input']>;
  limit_range: LabsGqlRoutingMetricsRange;
  manufacturer_id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order_condition_json?: InputMaybe<Scalars['String']['input']>;
  order_limit?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  supports_design?: InputMaybe<Scalars['Boolean']['input']>;
  supports_manufacturing?: InputMaybe<Scalars['Boolean']['input']>;
  unit_limit?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlCreateCapacityStationRuleArgs = {
  capacity_station_id: Scalars['String']['input'];
  default_unit_granularity: LabsGqlCapacityLimitGranularity;
  default_unit_limit: Scalars['Int']['input'];
  design_station_codes?: InputMaybe<Array<Scalars['String']['input']>>;
  lab_product_codes?: InputMaybe<Array<Scalars['String']['input']>>;
  matching_logic_json?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  work_order_matching_logic_json?: InputMaybe<Scalars['String']['input']>;
  work_order_type: LabsGqlWorkOrderTypeEnum;
};

export type LabsGqlCreateCaseReadinessCommand = {
  doctor_id: Scalars['String']['input'];
  items_v2_by_sku: LabsGqlCartItemV2InputBySku;
  patient_id: Scalars['String']['input'];
};

export type LabsGqlCreateCategoryCommand = {
  archived: Scalars['Boolean']['input'];
  kind: LabsGqlCategoryKind;
  label: Scalars['String']['input'];
  requires_further_info: Scalars['Boolean']['input'];
  subcategories: Array<LabsGqlCreateSubcategoryInput>;
  visible_to_internal: Scalars['Boolean']['input'];
  visible_to_lab: Scalars['Boolean']['input'];
  visible_to_practice: Scalars['Boolean']['input'];
};

export type LabsGqlCreateChairsideReviewSubmissionCommand = {
  case_id?: InputMaybe<Scalars['String']['input']>;
  doctor_id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  rating: LabsGqlReviewRating;
  tags: Array<LabsGqlReviewTagSubmissionInput>;
};

export type LabsGqlCreateChatMessageCommand = {
  action_type?: InputMaybe<LabsGqlControlPanelActionType>;
  attachment_urls: Array<Scalars['String']['input']>;
  entity_id: Scalars['String']['input'];
  entity_type: LabsGqlChatEntityTypes;
  should_send_notification?: InputMaybe<Scalars['Boolean']['input']>;
  staff_id?: InputMaybe<Scalars['String']['input']>;
  staff_user_id?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  visible_to_org_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  visible_to_roles: Array<LabsGqlStaffRoleWithAny>;
};

export type LabsGqlCreateCompletedScanningCaseCommand = {
  doctor_preferences_id: Scalars['String']['input'];
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
  patient_id: Scalars['String']['input'];
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlCreateCreditInput = {
  amountCents: Scalars['Int']['input'];
  creditCategoryId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceToApplyDate?: InputMaybe<Scalars['DateTime']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type LabsGqlCreateCrownProductCatalogEntryInput = {
  isAnterior: Scalars['Boolean']['input'];
  material: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LabsGqlCreateDesignQaEvaluationCommand = {
  assigned_user_id?: InputMaybe<Scalars['String']['input']>;
  design_file_path: Scalars['String']['input'];
  is_calibration?: InputMaybe<Scalars['Boolean']['input']>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlCreateDesignReviewFieldCommand = {
  applicable_order_types: Array<LabsGqlDesignReviewFieldFilterType>;
  category: Scalars['String']['input'];
  field: LabsGqlDesignReviewFormFieldInput;
  name: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
  types: Array<LabsGqlDesignReviewFieldType>;
};

export type LabsGqlCreateDesignRuleCommand = {
  item_condition_json: Scalars['String']['input'];
  limit_range: LabsGqlRoutingMetricsRange;
  name: Scalars['String']['input'];
  order_condition_json: Scalars['String']['input'];
  order_limit?: InputMaybe<Scalars['Int']['input']>;
  unit_limit?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlCreateDoctorPreferencesCommand = {
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  contact_phone_call_number?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  force?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  partner_id: Scalars['String']['input'];
  profile_photo?: InputMaybe<LabsGqlDoctorProfilePhotoDtoInput>;
  role?: InputMaybe<LabsGqlStaffRolePractice>;
  roles?: InputMaybe<Array<LabsGqlStaffRolePractice>>;
  staff_member_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateDoctorRequestCommand = {
  initiating_flow: LabsGqlDoctorRequestInitiatingFlow;
  options: Array<LabsGqlDoctorRequestOptionInput>;
  order_id: Scalars['String']['input'];
  photo_attachment_urls: Array<Scalars['String']['input']>;
  question_text: Scalars['String']['input'];
};

export type LabsGqlCreateEmptyVariantFeedbackInput = {
  billing_surcharge_item_id: Scalars['String']['input'];
  feedback_credit_cents?: InputMaybe<Scalars['Int']['input']>;
  lab_order_id: Scalars['String']['input'];
  lab_order_item_id: Scalars['String']['input'];
};

export type LabsGqlCreateEnableCcFeeForAllWorkflowArgs = {
  practiceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  queryBatchSize?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlCreateExternalOrgCommand = {
  display_name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  time_zone: Scalars['String']['input'];
};

export type LabsGqlCreateFabricationResult = {
  __typename?: 'CreateFabricationResult';
  id: Scalars['String']['output'];
};

export type LabsGqlCreateFulfillmentCommand = {
  estimated_ship_date?: InputMaybe<Scalars['DateTime']['input']>;
  orderId: Scalars['String']['input'];
  ordered_fabrication_states?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LabsGqlCreateImplantTypeCommand = {
  connection: Scalars['String']['input'];
  connection_type?: InputMaybe<Scalars['String']['input']>;
  manufacturer: Scalars['String']['input'];
  system: Scalars['String']['input'];
};

export type LabsGqlCreateInvoiceCreditInput = {
  amount_issued_cents: Scalars['Int']['input'];
  credit_category_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  existing_invoice_id?: InputMaybe<Scalars['ID']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  invoice_to_apply_date?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id: Scalars['ID']['input'];
};

export type LabsGqlCreateInvoiceInput = {
  organizationId: Scalars['String']['input'];
  targetCreationDate: Scalars['DateTime']['input'];
};

export type LabsGqlCreateLabCaseCompletionCommand = {
  lab_case_id: Scalars['String']['input'];
  percentage_completed?: InputMaybe<Scalars['Float']['input']>;
};

export type LabsGqlCreateLabCaseLedgerEntryCommand = {
  fulfillment_number?: InputMaybe<Scalars['Int']['input']>;
  lab_id: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
  prices: Array<LabsGqlLabPriceLedgerPriceInput>;
  ship_date: Scalars['DateTime']['input'];
};

export type LabsGqlCreateLabMetalCostCommand = {
  metal_cost: LabsGqlLabMetalCostCreateConfigInput;
};

export type LabsGqlCreateLabOrderIntegrationAttemptResponse = {
  __typename?: 'CreateLabOrderIntegrationAttemptResponse';
  errorMessage?: Maybe<Scalars['String']['output']>;
  orderId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type LabsGqlCreateLabPriceCommand = {
  configs?: InputMaybe<Array<LabsGqlLabPriceConfigInput>>;
  default_practice_price_cents?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  rule: LabsGqlLabPriceRuleInput;
};

export type LabsGqlCreateLabPriceLedgerEntryCommand = {
  fulfillment_number?: InputMaybe<Scalars['Int']['input']>;
  lab_id: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
  prices: Array<LabsGqlLabPriceLedgerPriceInput>;
};

export type LabsGqlCreateLabPriceLedgerPartnerEntryCommand = {
  fulfillment_number?: InputMaybe<Scalars['Int']['input']>;
  order_id: Scalars['String']['input'];
  partner_id: Scalars['String']['input'];
  prices: Array<LabsGqlLabPriceLedgerPriceInput>;
};

export type LabsGqlCreateLiveCasePlanningRequestArgs = {
  calendly_event_id: Scalars['ID']['input'];
  entry_point: LabsGqlLiveCasePlanningRequestEntryPoint;
  patient_id?: InputMaybe<Scalars['ID']['input']>;
  patient_name: Scalars['String']['input'];
  product_line: Scalars['String']['input'];
  topic_to_discuss: Scalars['String']['input'];
};

export type LabsGqlCreateLiveDdpReviewRequestArgs = {
  calendly_event_id: Scalars['ID']['input'];
  design_order_revision_id?: InputMaybe<Scalars['ID']['input']>;
  entry_point?: InputMaybe<LabsGqlLiveDdpReviewRequestEntryPoint>;
  order_id: Scalars['ID']['input'];
  practice_id: Scalars['ID']['input'];
  rejection_notes?: InputMaybe<Scalars['String']['input']>;
  staff_member_id?: InputMaybe<Scalars['ID']['input']>;
};

export type LabsGqlCreateLiveLabTechMeetingRequestArgs = {
  calendly_event_id: Scalars['ID']['input'];
  entry_point: LabsGqlLiveLabTechMeetingRequestEntryPoint;
  notes: Scalars['String']['input'];
};

export type LabsGqlCreateManufacturerCommand = {
  display_name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  time_zone: Scalars['String']['input'];
};

export type LabsGqlCreateManufacturerTruckTimePayload = {
  carrier: Scalars['String']['input'];
  days_of_week: Array<LabsGqlDayOfWeek>;
  label_print_cutoff_time: LabsGqlManufacturerTruckTimeEntityTimeInput;
  manufacturer_id: Scalars['String']['input'];
  pickup_time: LabsGqlManufacturerTruckTimeEntityTimeInput;
  supports_next_day: Scalars['Boolean']['input'];
};

export type LabsGqlCreateMetalMaterialCommand = {
  name: Scalars['String']['input'];
};

export type LabsGqlCreateNonDoctorPracticeStaffCommand = {
  kustomer_customer_id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  partner_id: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to create a new patient or find an existing one from before Chairside was updated to use remote entities. */
export type LabsGqlCreateOrFindChairsidePatientCommand = {
  birthday: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  last_name: Scalars['String']['input'];
  threeshape_patient_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateOrderAutomationCommand = {
  actions: Array<LabsGqlAutomationActionConfigInput>;
  description: Scalars['String']['input'];
  filter: LabsGqlFilterSubmissionInput;
  name: Scalars['String']['input'];
  triggered_by: Array<LabsGqlAutomationTriggerEventNamesEnum>;
};

export type LabsGqlCreateOrderHoldCommand = {
  expected_delay_days?: InputMaybe<Scalars['Int']['input']>;
  holdReason?: InputMaybe<Scalars['String']['input']>;
  hold_details?: InputMaybe<LabsGqlHoldDetails>;
  orderId: Scalars['String']['input'];
  show_hold_status_to_practice?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlCreateOrderItemVariantChargeCommand = {
  category: LabsGqlOrderItemVariantChargeCategory;
  description: Scalars['String']['input'];
  practice_id: Scalars['String']['input'];
  price_cents: Scalars['Float']['input'];
  salesorder_id: Scalars['String']['input'];
  salesorder_item_id: Scalars['String']['input'];
  when: Scalars['DateTime']['input'];
};

export type LabsGqlCreateOrderMetadataInput = {
  credit_amount_cents: Scalars['Int']['input'];
  order_id: Scalars['String']['input'];
  practice_id: Scalars['String']['input'];
};

export type LabsGqlCreateOrderMetadataOutput = {
  __typename?: 'CreateOrderMetadataOutput';
  metadata_id: Scalars['String']['output'];
};

export type LabsGqlCreateOrderPriceBackfillWorkflowArgs = {
  batchSize: Scalars['Int']['input'];
  sleepMs?: InputMaybe<Scalars['Int']['input']>;
  startCursor?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateOrderPriceBackfillWorkflowResult = {
  __typename?: 'CreateOrderPriceBackfillWorkflowResult';
  workflowId: Scalars['String']['output'];
};

export type LabsGqlCreateOrderPriceEntryCommand = {
  item_id: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
  override_total_price_cents_reason?: InputMaybe<Scalars['String']['input']>;
  price_cents: Scalars['Int']['input'];
  price_id: Scalars['String']['input'];
  price_name: Scalars['String']['input'];
  total_price_cents: Scalars['Int']['input'];
  unit_count: Scalars['Int']['input'];
  units: Array<LabsGqlOrderPriceUnitInfoInput>;
};

export type LabsGqlCreateOrganizationCommand = {
  admin_id?: InputMaybe<Scalars['String']['input']>;
  external_accounts?: InputMaybe<Array<LabsGqlExternalAccountRelationshipInput>>;
  legal_name: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parent_id?: InputMaybe<Scalars['String']['input']>;
  type: LabsGqlOrganizationType;
};

export type LabsGqlCreateOrganizationPriceConfigCommand = {
  from_preset?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  organization_type: LabsGqlOrganizationTypeForPricing;
  price_cents: Scalars['Float']['input'];
  price_id: Scalars['String']['input'];
};

export type LabsGqlCreatePartnerBillingCommand = {
  monthly_prepay_in_cents?: InputMaybe<Scalars['Int']['input']>;
  partner_id: Scalars['String']['input'];
};

export type LabsGqlCreatePasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  preferences_id: Scalars['String']['input'];
};

export type LabsGqlCreatePasswordResult = {
  __typename?: 'CreatePasswordResult';
  user_id: Scalars['String']['output'];
};

export type LabsGqlCreatePatientCommand = {
  birthday: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
};

export type LabsGqlCreatePendingAlignerCaseCommand = {
  aligner_arch?: InputMaybe<LabsGqlAlignerArch>;
  aligner_notes?: InputMaybe<Scalars['String']['input']>;
  aligner_treatment_area?: InputMaybe<LabsGqlAlignerTreatmentArea>;
  attachment_restricted_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  bite_concerns?: InputMaybe<LabsGqlPendingAlignerCaseBiteConcernsInput>;
  crowding_resolution?: InputMaybe<LabsGqlPendingAlignerCaseCrowdingResolutionInput>;
  doctor_name?: InputMaybe<Scalars['String']['input']>;
  extraction_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  interproximal_space_sizes?: InputMaybe<Array<LabsGqlCreatePendingAlignerCaseInterproximalSpaceSizeInput>>;
  movement_restricted_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  patient_name?: InputMaybe<Scalars['String']['input']>;
  photos: LabsGqlPendingAlignerCasePhotoMapInput;
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlCreatePendingAlignerCaseInterproximalSpaceSizeInput = {
  size: Scalars['Float']['input'];
  space: LabsGqlPendingAlignerCaseInterproximalSpaceShapeInput;
};

export type LabsGqlCreatePhotoUploadDto = {
  /** The camera settings of the photo upload. */
  cameraSettings: Array<LabsGqlCreateCameraSettingsDto>;
  /** The type of the photo upload. */
  photoType: Scalars['String']['input'];
  /** The processed image url of the photo upload. */
  processedImageUrl: Scalars['String']['input'];
  /** The raw image url of the photo upload. */
  rawImageUrl: Scalars['String']['input'];
  /** The id of the shade capture. */
  shadeCaptureId: Scalars['String']['input'];
  /** The tooth coordinates of the photo upload. */
  toothCoordinates: Array<LabsGqlCreateToothCoordinateDto>;
  /** The unns of the photo upload. */
  unns: Array<Scalars['Float']['input']>;
};

export type LabsGqlCreatePracticeRequestMarketingMaterialsCommand = {
  data: LabsGqlPracticeRequestMarketingMaterialsInput;
  organization_id: Scalars['String']['input'];
  sku: LabsGqlOrderItemSkuType;
};

export type LabsGqlCreatePracticeRequestScheduleTrainingCommand = {
  data: LabsGqlPracticeRequestScheduleTrainingInput;
  organization_id: Scalars['String']['input'];
  sku: LabsGqlOrderItemSkuType;
};

export type LabsGqlCreatePracticeSupportCommand = {
  pod_id?: InputMaybe<Scalars['String']['input']>;
  practice_id: Scalars['String']['input'];
  software_type?: InputMaybe<LabsGqlPracticeSoftwareType>;
};

export type LabsGqlCreatePriceCommand = {
  application_rules: Array<LabsGqlPriceApplicationRuleInput>;
  name: Scalars['String']['input'];
  partner_prices: Array<LabsGqlPartnerPriceOverrideInput>;
  salesforce_price_mapping?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreatePricePresetCommand = {
  cart_field_price_defaults?: InputMaybe<Array<LabsGqlCartFieldDefaultPriceInput>>;
  code: Scalars['String']['input'];
  price_defaults: Array<LabsGqlDefaultPriceInput>;
};

export type LabsGqlCreateProductStationUnitMappingArgs = {
  product_header: Scalars['String']['input'];
  station_id: Scalars['String']['input'];
  units: Scalars['Int']['input'];
};

export type LabsGqlCreateQcItemConfigCommand = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  description: Scalars['String']['input'];
  filters: Array<LabsGqlFilterSubmissionInput>;
  title: Scalars['String']['input'];
};

export type LabsGqlCreateQcIssueCommand = {
  filters?: InputMaybe<Array<LabsGqlFilterSubmissionInput>>;
  name: Scalars['String']['input'];
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  prompt: Scalars['String']['input'];
  sub_issues?: InputMaybe<Array<LabsGqlQcSubIssueInput>>;
};

export type LabsGqlCreateReasonCodeCommand = {
  additional_info_request: Array<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  default_fault?: InputMaybe<LabsGqlReasonCodeFault>;
  display_rules: Array<LabsGqlCustomFieldDisplayRuleInput>;
  form_display?: InputMaybe<LabsGqlFormDisplay>;
  group?: InputMaybe<LabsGqlReasonCodeGroup>;
  internal_only: Scalars['Boolean']['input'];
  return_rules: Array<LabsGqlCustomFieldDisplayRuleInput>;
  title: Scalars['String']['input'];
};

export type LabsGqlCreateRebuildAggregateByIdsWorkflowArgs = {
  aggregateIds: Array<Scalars['String']['input']>;
  aggregateName: Scalars['String']['input'];
  batchSize: Scalars['Float']['input'];
  sleepMs?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlCreateRebuildAggregateWorkflowArgs = {
  aggregateName: Scalars['String']['input'];
  batchSize: Scalars['Float']['input'];
  sleepMs?: InputMaybe<Scalars['Int']['input']>;
  startCursor?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateRebuildAggregateWorkflowResult = {
  __typename?: 'CreateRebuildAggregateWorkflowResult';
  workflowId: Scalars['String']['output'];
};

export type LabsGqlCreateRefabReasonCommand = {
  category?: InputMaybe<Scalars['String']['input']>;
  default_fault?: InputMaybe<LabsGqlRefabFault>;
  display_rules: Array<LabsGqlCustomFieldDisplayRuleInput>;
  internal_only?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  return_rules: Array<LabsGqlCustomFieldDisplayRuleInput>;
};

export type LabsGqlCreateReturnForRefabricationCommand = {
  annotations?: InputMaybe<Array<LabsGqlReviewSubmissionAnnotationInput>>;
  attachment_urls?: InputMaybe<Array<Scalars['String']['input']>>;
  attachments?: InputMaybe<Array<LabsGqlRefabOrderAttachments>>;
  avoid_followup_order?: InputMaybe<Scalars['Boolean']['input']>;
  /** IDs of the items that need refabrication. */
  itemIdsToRefab?: InputMaybe<Array<Scalars['String']['input']>>;
  orderId: Scalars['String']['input'];
  /** ID of a partner delivery address for they will ship from. Defaults to order delivery address */
  partner_address_id?: InputMaybe<Scalars['String']['input']>;
  practitioner_notes: Scalars['String']['input'];
  refab_item_reason_codes?: InputMaybe<Array<LabsGqlRefabItemInfo>>;
  refab_reasons?: InputMaybe<Array<LabsGqlOrderRefabReasonInput>>;
  scan_export_id?: InputMaybe<Scalars['String']['input']>;
  waxup_requested?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlCreateReturnForTryInCommand = {
  item_ids: Array<Scalars['String']['input']>;
  new_order_items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
  order_id: Scalars['String']['input'];
  try_in_feedback_details: Array<LabsGqlTryInFeedbackDetailInput>;
};

export type LabsGqlCreateReviewSubmissionV3Command = {
  annotations?: InputMaybe<Array<LabsGqlReviewSubmissionAnnotationInput>>;
  attachments?: InputMaybe<Array<LabsGqlRefabOrderAttachments>>;
  items: Array<LabsGqlReviewSubmissionV3ItemInput>;
  lab_order_id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateReviewTagCommand = {
  applicable_ratings: Array<LabsGqlReviewRating>;
  applicable_sections: Array<LabsGqlReviewSection>;
  category?: InputMaybe<Scalars['String']['input']>;
  display_rules: Array<LabsGqlCustomFieldDisplayRuleInput>;
  related_cart_field_ids: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type LabsGqlCreateRoutingTargetDistributionRuleCommand = {
  condition_json: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Float']['input']>;
  routing_target_distribution_items: Array<LabsGqlRoutingTargetDistributionItemInput>;
};

export type LabsGqlCreateSavedInboxSearchCommand = {
  name: Scalars['String']['input'];
  search: LabsGqlInboxCustomSearchInput;
  visibility: LabsGqlSavedSearchVisibility;
};

export type LabsGqlCreateSavedOrderSearchCommand = {
  name: Scalars['String']['input'];
  search: LabsGqlOrderCustomSearchInput;
  visibility: LabsGqlSavedSearchVisibility;
};

export type LabsGqlCreateSavedRoutingSearchCommand = {
  name: Scalars['String']['input'];
  search: LabsGqlRoutingCustomSearchInput;
  visibility: LabsGqlSavedSearchVisibility;
};

export type LabsGqlCreateScanFilePreUploadCommand = {
  created_date?: InputMaybe<Scalars['DateTime']['input']>;
  line_items: Array<LabsGqlScanExportInputLineItem>;
  patient_birthday?: InputMaybe<Scalars['DateTime']['input']>;
  patient_first_name: Scalars['String']['input'];
  patient_last_name: Scalars['String']['input'];
  threeshape_order_id: Scalars['String']['input'];
  threeshape_patient_id: Scalars['String']['input'];
};

export type LabsGqlCreateScanPreUploadRes = {
  __typename?: 'CreateScanPreUploadRes';
  already_has_order_submitted: Scalars['Boolean']['output'];
  created_scan_entity: LabsGqlScan_Export;
  filename: Scalars['String']['output'];
  overwrote_file: Scalars['Boolean']['output'];
  signed_upload_url: Scalars['String']['output'];
};

export type LabsGqlCreateScanbodyCommand = {
  manufacturer: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sku: Scalars['String']['input'];
};

export type LabsGqlCreateScanbodyRequestCommand = {
  abutment_type?: InputMaybe<Scalars['String']['input']>;
  address: Scalars['String']['input'];
  appointment_date: Scalars['DateTime']['input'];
  case_readiness_id?: InputMaybe<Scalars['String']['input']>;
  connection: Scalars['String']['input'];
  connection_type?: InputMaybe<Scalars['String']['input']>;
  manufacturer: Scalars['String']['input'];
  practice_id: Scalars['String']['input'];
  requested_scanbody_id?: InputMaybe<Scalars['String']['input']>;
  requesting_doctor_id?: InputMaybe<Scalars['String']['input']>;
  surgical_report_file?: InputMaybe<Scalars['String']['input']>;
  system: Scalars['String']['input'];
};

export type LabsGqlCreateScannerCommand = {
  assigned_practice_id?: InputMaybe<Scalars['String']['input']>;
  scanner_id: Scalars['String']['input'];
};

export type LabsGqlCreateShadeSuggestionDto = {
  /** The id of the case. */
  caseId: Scalars['String']['input'];
  /** The error message of the shade suggestion. */
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  /** The process time in milliseconds. */
  processTimeMs: Scalars['Float']['input'];
  /** The id of the shade capture. */
  shadeCaptureId: Scalars['String']['input'];
  /** The url of the suggestion generated photo. */
  suggestionGeneratedPhotoUrl?: InputMaybe<Scalars['String']['input']>;
  /** The tooth shade suggestions. */
  toothShadeSuggestions: Array<LabsGqlCreateToothShadeSuggestionDto>;
  /** The unns of the shade suggestion. */
  unns: Array<Scalars['Float']['input']>;
};

export type LabsGqlCreateShadeSuggestionWithPhotosDto = {
  /** The photos of the shade suggestion. */
  photoUploads: Array<LabsGqlCreatePhotoUploadDto>;
  /** The shade suggestion. */
  shadeSuggestion: LabsGqlCreateShadeSuggestionDto;
};

export type LabsGqlCreateShippingLabelCommand = {
  destination_type: LabsGqlPortalShipDestinationType;
  order_id: Scalars['String']['input'];
  override_address_validation?: InputMaybe<Scalars['Boolean']['input']>;
  shipping_speed: LabsGqlPortalShipShippingSpeed;
};

export type LabsGqlCreateSubcategoryInput = {
  archived: Scalars['Boolean']['input'];
  label: Scalars['String']['input'];
  requires_further_info: Scalars['Boolean']['input'];
};

export type LabsGqlCreateTagCommand = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  entity_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  entity_type: LabsGqlTaggableEntityType;
  name: Scalars['String']['input'];
};

export type LabsGqlCreateThreeshapeDmeCommand = {
  file_path: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LabsGqlCreateTicketCommand = {
  action: LabsGqlTicketActionCreationInputData;
  order_id: Scalars['String']['input'];
};

export type LabsGqlCreateTicketSavedSearchCommand = {
  name: Scalars['String']['input'];
  search: LabsGqlTicketFiltersInput;
  visibility: LabsGqlSavedSearchVisibility;
};

export type LabsGqlCreateToothCoordinateDto = {
  /** The x coordinate of the tooth. */
  x: Scalars['Float']['input'];
  /** The y coordinate of the tooth. */
  y: Scalars['Float']['input'];
};

export type LabsGqlCreateToothShadeSuggestionDto = {
  /** The shade of the tooth. */
  shade: Scalars['String']['input'];
  /** The shade type of the tooth. */
  shadeType: Scalars['String']['input'];
  /** The unn of the tooth. */
  unn: Scalars['Float']['input'];
};

export type LabsGqlCreateTrainingCommand = {
  start_date: Scalars['DateTime']['input'];
};

export type LabsGqlCreateTryInFeedbackCommand = {
  order_id: Scalars['String']['input'];
  try_in_feedback_details: Array<LabsGqlTryInFeedbackDetailInput>;
};

export type LabsGqlCreateUserCommand = {
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  is_mobile_verified?: InputMaybe<Scalars['Boolean']['input']>;
  last_name: Scalars['String']['input'];
  mobile_phone_number?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<LabsGqlStaffRole>>;
  salesforce_contact_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlCreateUserResult = {
  __typename?: 'CreateUserResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type LabsGqlCreateVideoDdpReviewRequestArgs = {
  design_order_revision_id: Scalars['ID']['input'];
  dr_opt_in?: InputMaybe<Scalars['Boolean']['input']>;
  order_id: Scalars['ID']['input'];
  practice_id: Scalars['ID']['input'];
};

export type LabsGqlCreateWorkflowStepDefinitionCommand = {
  name: Scalars['String']['input'];
  priority: Scalars['Float']['input'];
};

export type LabsGqlCreditCardFeePerInvoiceDto = {
  __typename?: 'CreditCardFeePerInvoiceDTO';
  amount_cents?: Maybe<Scalars['Int']['output']>;
  invoice_id: Scalars['ID']['output'];
};

export type LabsGqlCreditCardFeePerInvoiceInput = {
  amount_cents: Scalars['Int']['input'];
  invoice_id: Scalars['ID']['input'];
};

export type LabsGqlCreditCardSurchargeConfigDto = {
  __typename?: 'CreditCardSurchargeConfigDTO';
  type: LabsGqlPracticeBillingRecurringItemType;
};

export enum LabsGqlCrowdingResolutionOption {
  No = 'No',
  OnlyIfNeeded = 'OnlyIfNeeded'
}

export type LabsGqlCrownBridgeImplantPanLabelInfo = LabsGqlPanLabelInfo & {
  __typename?: 'CrownBridgeImplantPanLabelInfo';
  batchNumber?: Maybe<Scalars['String']['output']>;
  crownMaterial?: Maybe<Scalars['String']['output']>;
  items: Array<LabsGqlPanLabelItemInfo>;
  labOrderId: Scalars['String']['output'];
  labelTags: Array<Scalars['String']['output']>;
  labtracId?: Maybe<Scalars['String']['output']>;
  modelType?: Maybe<Scalars['String']['output']>;
  occlusalStaining?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  panCode: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  priority: LabsGqlManufacturingOrderPriority;
  puck?: Maybe<LabsGqlPuck>;
  shades?: Maybe<LabsGqlShadeUnion>;
  /** Only the date portion is relevant.  The time portion should be noon UTC. */
  shipByDate: Scalars['DateTime']['output'];
};

export type LabsGqlCrownItem = LabsGqlIOrderItemBase & {
  __typename?: 'CrownItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlOrderItemSingleToothUnit;
};

export type LabsGqlCrownItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlOrderItemSingleToothUnitInput;
};

export type LabsGqlCrownProductDetails = LabsGqlIProductDetailsBase & {
  __typename?: 'CrownProductDetails';
  catalogId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isAnterior: Scalars['Boolean']['output'];
  material: Scalars['String']['output'];
  shade?: Maybe<Scalars['String']['output']>;
  type: LabsGqlProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlCustomFieldDisplayRule = {
  __typename?: 'CustomFieldDisplayRule';
  applicable_values: Array<Scalars['String']['output']>;
  display_if_passes: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  /** Unique name for the rule on the custom field */
  name: Scalars['String']['output'];
  property: LabsGqlDisplayRuleOrderProperty;
};

export type LabsGqlCustomFieldDisplayRuleInput = {
  applicable_values: Array<Scalars['String']['input']>;
  display_if_passes: Scalars['Boolean']['input'];
  /** Unique name for the rule on the custom field */
  name: Scalars['String']['input'];
  property: LabsGqlDisplayRuleOrderProperty;
};

export enum LabsGqlCustomFieldElement {
  OrderItemMaterial = 'order_item_material',
  OrderItemMeta = 'order_item_meta'
}

export type LabsGqlCustomFieldPricingRule = {
  __typename?: 'CustomFieldPricingRule';
  default_price_cents?: Maybe<Scalars['Int']['output']>;
  field_value: Scalars['MetafieldJSON']['output'];
  id: Scalars['String']['output'];
  partner_prices: Array<LabsGqlPartnerPriceOverride>;
};

export type LabsGqlCustomFieldPricingRuleUpdate = {
  default_price_cents?: InputMaybe<Scalars['Int']['input']>;
  partner_prices: Array<LabsGqlPartnerPriceOverrideInput>;
  rule_id: Scalars['String']['input'];
};

export enum LabsGqlCustomFieldType {
  Boolean = 'boolean',
  Number = 'number',
  Select = 'select',
  Text = 'text'
}

export type LabsGqlCustomSearchParams = LabsGqlInboxCustomSearch | LabsGqlOrderCustomSearch | LabsGqlRoutingCustomSearch;

export enum LabsGqlCustomSearchType {
  Inbox = 'inbox',
  Order = 'order',
  Routing = 'routing'
}

export enum LabsGqlDayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type LabsGqlDefaultPrice = {
  __typename?: 'DefaultPrice';
  amount_cents: Scalars['Int']['output'];
  price_id: Scalars['String']['output'];
};

export type LabsGqlDefaultPriceInput = {
  amount_cents: Scalars['Int']['input'];
  price_id: Scalars['String']['input'];
};

export type LabsGqlDefaultPriceUpdateInput = {
  amount_cents?: InputMaybe<Scalars['Int']['input']>;
  price_id: Scalars['String']['input'];
};

export type LabsGqlDeleteChatMessageCommand = {
  chat_id: Scalars['String']['input'];
};

export type LabsGqlDeleteDoctorPreferencesCommand = {
  preferences_id: Scalars['String']['input'];
};

export type LabsGqlDeleteImplantTypeCommand = {
  implant_type_id: Scalars['String']['input'];
};

export type LabsGqlDeleteInvoiceCreditInput = {
  credit_id: Scalars['ID']['input'];
};

export type LabsGqlDeleteManufacturerTruckTimePayload = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteMetalMaterialCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteNonDoctorPracticeStaffCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteOrderAutomationCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteOrderItemCommand = {
  itemId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type LabsGqlDeleteOrderPriceEntryCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteOrganizationPriceConfigCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeletePartnerPriceConfigCommand = {
  partner_id: Scalars['String']['input'];
  price_id: Scalars['String']['input'];
};

export type LabsGqlDeletePracticeSupportCommand = {
  practice_id: Scalars['String']['input'];
};

export type LabsGqlDeletePriceCommand = {
  price_id: Scalars['String']['input'];
};

export type LabsGqlDeletePricePresetCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteReasonCodeRuleCommand = {
  reason_id: Scalars['String']['input'];
  rule_id: Scalars['String']['input'];
  rule_type: LabsGqlDisplayRuleTypeEnum;
};

export type LabsGqlDeleteRefabReasonRuleCommand = {
  reason_id: Scalars['String']['input'];
  rule_id: Scalars['String']['input'];
  rule_type: LabsGqlDisplayRuleTypeEnum;
};

export type LabsGqlDeleteRegisteredInvoiceItemCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteSavedSearchCommand = {
  search_id: Scalars['String']['input'];
};

export type LabsGqlDeleteScanExportCommand = {
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlDeleteScanbodyCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteScanbodyRequestCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteTagCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlDeleteTechniciansResponseDto = {
  __typename?: 'DeleteTechniciansResponseDto';
  /** The number of technicians that were deleted. */
  deletedCount: Scalars['Float']['output'];
  /** The number of technicians that were not found to delete. */
  missingCount: Scalars['Float']['output'];
};

export type LabsGqlDeleteTicketSavedSearchCommand = {
  search_id: Scalars['String']['input'];
};

export type LabsGqlDeliveryAddress = {
  __typename?: 'DeliveryAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postal_code: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street_one: Scalars['String']['output'];
  street_two?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDeliveryAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postal_code: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street_one: Scalars['String']['input'];
  street_two?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlDeliveryAddressValidationError = {
  __typename?: 'DeliveryAddressValidationError';
  error: Scalars['String']['output'];
};

export type LabsGqlDeliveryAddressValidationResult = LabsGqlDeliveryAddressValidationError | LabsGqlDeliveryAddressValidationSuccess;

export type LabsGqlDeliveryAddressValidationSuccess = {
  __typename?: 'DeliveryAddressValidationSuccess';
  validated_address: LabsGqlDeliveryAddress;
};

export type LabsGqlDentureItem = LabsGqlIOrderItemBase & {
  __typename?: 'DentureItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  denture_fabrication_method?: Maybe<LabsGqlDenturesFabricationMethod>;
  denture_mouth_scan_method?: Maybe<LabsGqlDentureMouthScanMethod>;
  denture_production_metadata?: Maybe<LabsGqlOrderItemDentureProductionMetadata>;
  denture_production_type?: Maybe<LabsGqlDenturesProductionType>;
  denture_type: LabsGqlDentureType;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlOrderItemArchUnit;
};

export type LabsGqlDentureItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  denture_fabrication_method?: InputMaybe<LabsGqlDenturesFabricationMethod>;
  denture_mouth_scan_method?: InputMaybe<LabsGqlDentureMouthScanMethod>;
  denture_production_metadata?: InputMaybe<LabsGqlOrderItemDentureProductionMetadataInput>;
  denture_production_type?: InputMaybe<LabsGqlDenturesProductionType>;
  denture_type: LabsGqlDentureType;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlOrderItemArchUnitInput;
};

export enum LabsGqlDentureMouthScanMethod {
  Impression = 'Impression',
  Intraoral = 'Intraoral',
  MailIn = 'MailIn'
}

export enum LabsGqlDentureType {
  CentricTray = 'CentricTray',
  Copy = 'Copy',
  Immediate = 'Immediate',
  NonDandyReline = 'NonDandyReline',
  Reline = 'Reline',
  WaxRim = 'WaxRim',
  WaxRimContinuation = 'WaxRimContinuation'
}

export enum LabsGqlDenturesArchType {
  Dual = 'Dual',
  Lower = 'Lower',
  Upper = 'Upper'
}

export enum LabsGqlDenturesBiteAdjustmentType {
  Close = 'Close',
  LeaveAsIs = 'LeaveAsIs',
  Open = 'Open'
}

export enum LabsGqlDenturesFabricationMethod {
  Milled = 'Milled',
  Printed = 'Printed'
}

export enum LabsGqlDenturesFestooningLevel {
  Heavy = 'Heavy',
  Medium = 'Medium',
  Soft = 'Soft'
}

export enum LabsGqlDenturesFixupReason {
  Aesthetic = 'Aesthetic',
  Structural = 'Structural'
}

export type LabsGqlDenturesFulfillmentBiteAdjustmentDto = {
  __typename?: 'DenturesFulfillmentBiteAdjustmentDTO';
  adjustment: LabsGqlDenturesBiteAdjustmentType;
  adjustment_distance?: Maybe<Scalars['Float']['output']>;
};

export type LabsGqlDenturesFulfillmentBiteAdjustmentInput = {
  adjustment: LabsGqlDenturesBiteAdjustmentType;
  adjustment_distance?: InputMaybe<Scalars['Float']['input']>;
};

export type LabsGqlDenturesFulfillmentBiteMidlineCorrectionInput = {
  distance?: InputMaybe<Scalars['Float']['input']>;
  type: LabsGqlDenturesMidlineCorrectionType;
};

export type LabsGqlDenturesFulfillmentCoreArchDto = {
  __typename?: 'DenturesFulfillmentCoreArchDTO';
  fabrication_method: Array<LabsGqlDenturesFabricationMethod>;
  /** For a copy denture, whether the doctor wants it to be an exact copy. If false, copy_change_notes should be provided on the fulfillment. */
  is_exact_copy?: Maybe<Scalars['Boolean']['output']>;
  shades: Array<LabsGqlLabOrderItemShade>;
  teeth_quality: Array<LabsGqlDenturesTeethQuality>;
  type: LabsGqlDenturesSingleFulfillmentArchType;
};

export type LabsGqlDenturesFulfillmentCoreArchInput = {
  fabrication_method: Array<LabsGqlDenturesFabricationMethod>;
  /** For a copy denture, whether the doctor wants it to be an exact copy. If false, copy_change_notes should be provided on the fulfillment. */
  is_exact_copy?: InputMaybe<Scalars['Boolean']['input']>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  teeth_quality: Array<LabsGqlDenturesTeethQuality>;
  type: LabsGqlDenturesSingleFulfillmentArchType;
};

export type LabsGqlDenturesFulfillmentCoreDto = {
  __typename?: 'DenturesFulfillmentCoreDTO';
  add_post_dam?: Maybe<Scalars['Boolean']['output']>;
  /** Add stippling to dentures? */
  add_stippling?: Maybe<Scalars['Boolean']['output']>;
  bite_adjustment?: Maybe<LabsGqlDenturesFulfillmentBiteAdjustmentDto>;
  /** For a copy denture that is not an exact copy, these notes specify how the denture should be changed. */
  copy_change_notes?: Maybe<Scalars['String']['output']>;
  correct_occlusal_scheme?: Maybe<Scalars['Boolean']['output']>;
  festooning_level?: Maybe<LabsGqlDenturesFestooningLevel>;
  /** If set to 'NoDiastema', then the patient does not have diastema. */
  handle_diastema?: Maybe<LabsGqlDenturesHandleDiastemaOption>;
  implant_support?: Maybe<LabsGqlDenturesFulfillmentImplantSupportDto>;
  is_try_in: Scalars['Boolean']['output'];
  lower_arch?: Maybe<LabsGqlDenturesFulfillmentCoreArchDto>;
  midline_correction?: Maybe<LabsGqlDenturesFulfillmentMidlineCorrectionDto>;
  notes: Scalars['String']['output'];
  number_of_spares?: Maybe<Scalars['Int']['output']>;
  photos: Array<LabsGqlDenturesFulfillmentSinglePhotoDto>;
  smile_style: LabsGqlSmileStyleChoice;
  type: LabsGqlDenturesSingleFulfillmentType;
  upper_arch?: Maybe<LabsGqlDenturesFulfillmentCoreArchDto>;
};

export type LabsGqlDenturesFulfillmentDto = {
  __typename?: 'DenturesFulfillmentDTO';
  current: LabsGqlDenturesSingleFulfillmentDto;
  history: Array<LabsGqlDenturesSingleFulfillmentDto>;
};

export type LabsGqlDenturesFulfillmentFixupAestheticIssuesMetadata = {
  __typename?: 'DenturesFulfillmentFixupAestheticIssuesMetadata';
  fixup_reason: LabsGqlDenturesFixupReason;
};

export type LabsGqlDenturesFulfillmentFixupArch = {
  __typename?: 'DenturesFulfillmentFixupArch';
  fixup_issues: Array<LabsGqlDenturesFulfillmentFixupIssueDto>;
  fixup_reasons: Array<LabsGqlDenturesFixupReason>;
};

export type LabsGqlDenturesFulfillmentFixupArchInput = {
  fixup_issues: Array<LabsGqlDenturesFulfillmentFixupIssueInput>;
  fixup_reasons: Array<LabsGqlDenturesFixupReason>;
};

export type LabsGqlDenturesFulfillmentFixupDto = {
  __typename?: 'DenturesFulfillmentFixupDTO';
  fixup_issues_metadata?: Maybe<LabsGqlDenturesFulfillmentFixupIssuesMetadata>;
  is_complete: Scalars['Boolean']['output'];
  is_try_in: Scalars['Boolean']['output'];
  lower_arch?: Maybe<LabsGqlDenturesFulfillmentFixupArch>;
  type: LabsGqlDenturesSingleFulfillmentType;
  upper_arch?: Maybe<LabsGqlDenturesFulfillmentFixupArch>;
};

export type LabsGqlDenturesFulfillmentFixupIssueDto = {
  __typename?: 'DenturesFulfillmentFixupIssueDTO';
  details: Scalars['String']['output'];
  photos: Array<LabsGqlDenturesFulfillmentSinglePhotoDto>;
  type: LabsGqlDenturesFulfillmentFixupIssueType;
};

export type LabsGqlDenturesFulfillmentFixupIssueInput = {
  details: Scalars['String']['input'];
  photos: Array<LabsGqlDenturesFulfillmentSinglePhotoInput>;
  type: LabsGqlDenturesFulfillmentFixupIssueType;
};

export enum LabsGqlDenturesFulfillmentFixupIssueType {
  Length = 'Length',
  Midline = 'Midline',
  Other = 'Other',
  Retention = 'Retention',
  TissueShade = 'TissueShade',
  ToothShade = 'ToothShade',
  Vdo = 'VDO'
}

export type LabsGqlDenturesFulfillmentFixupIssuesMetadata = LabsGqlDenturesFulfillmentFixupAestheticIssuesMetadata | LabsGqlDenturesFulfillmentFixupStructuralIssuesMetadata;

export type LabsGqlDenturesFulfillmentFixupStructuralIssuesMetadata = {
  __typename?: 'DenturesFulfillmentFixupStructuralIssuesMetadata';
  fixup_reason: LabsGqlDenturesFixupReason;
  scan_export_id?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDenturesFulfillmentFollowupAestheticIssuesMetadata = {
  __typename?: 'DenturesFulfillmentFollowupAestheticIssuesMetadata';
  followup_reason: LabsGqlDenturesFixupReason;
};

export type LabsGqlDenturesFulfillmentFollowupArch = {
  __typename?: 'DenturesFulfillmentFollowupArch';
  followup_issues: Array<LabsGqlDenturesFulfillmentFixupIssueDto>;
  followup_reasons: Array<LabsGqlDenturesFixupReason>;
};

export type LabsGqlDenturesFulfillmentFollowupArchInput = {
  followup_issues: Array<LabsGqlDenturesFulfillmentFixupIssueInput>;
  followup_reasons: Array<LabsGqlDenturesFixupReason>;
};

export type LabsGqlDenturesFulfillmentFollowupIssuesMetadata = LabsGqlDenturesFulfillmentFollowupAestheticIssuesMetadata | LabsGqlDenturesFulfillmentFollowupStructuralIssuesMetadata;

export type LabsGqlDenturesFulfillmentFollowupStructuralIssuesMetadata = {
  __typename?: 'DenturesFulfillmentFollowupStructuralIssuesMetadata';
  followup_reason: LabsGqlDenturesFixupReason;
  scan_export_id?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDenturesFulfillmentImplantSupportDto = {
  __typename?: 'DenturesFulfillmentImplantSupportDTO';
  collar_heights_mm: Scalars['String']['output'];
  horseshoe?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlDenturesFulfillmentImplantSupportInput = {
  collar_heights_mm: Scalars['String']['input'];
  horseshoe?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlDenturesFulfillmentMidlineCorrectionDto = {
  __typename?: 'DenturesFulfillmentMidlineCorrectionDTO';
  distance?: Maybe<Scalars['Float']['output']>;
  type: LabsGqlDenturesMidlineCorrectionType;
};

export type LabsGqlDenturesFulfillmentSinglePhotoDto = {
  __typename?: 'DenturesFulfillmentSinglePhotoDTO';
  path: Scalars['String']['output'];
};

export type LabsGqlDenturesFulfillmentSinglePhotoInput = {
  path: Scalars['String']['input'];
};

export type LabsGqlDenturesFulfillmentTryInFollowupDto = {
  __typename?: 'DenturesFulfillmentTryInFollowupDTO';
  followup_issues_metadata?: Maybe<LabsGqlDenturesFulfillmentFollowupIssuesMetadata>;
  is_try_in: Scalars['Boolean']['output'];
  lower_arch?: Maybe<LabsGqlDenturesFulfillmentFollowupArch>;
  type: LabsGqlDenturesSingleFulfillmentType;
  upper_arch?: Maybe<LabsGqlDenturesFulfillmentFollowupArch>;
};

export type LabsGqlDenturesFulfillmentWaxRimDto = {
  __typename?: 'DenturesFulfillmentWaxRimDTO';
  arch: LabsGqlDenturesArchType;
  implant_support?: Maybe<LabsGqlDenturesFulfillmentImplantSupportDto>;
  notes: Scalars['String']['output'];
  type: LabsGqlDenturesSingleFulfillmentType;
};

export enum LabsGqlDenturesHandleDiastemaOption {
  CloseDiastema = 'CloseDiastema',
  KeepDiastema = 'KeepDiastema',
  NoDiastema = 'NoDiastema'
}

export enum LabsGqlDenturesMidlineCorrectionType {
  LeaveAsIs = 'LeaveAsIs',
  MatchLowerMidline = 'MatchLowerMidline',
  MatchUpperMidline = 'MatchUpperMidline',
  ShiftLeft = 'ShiftLeft',
  ShiftRight = 'ShiftRight'
}

export type LabsGqlDenturesPanLabelInfo = LabsGqlPanLabelInfo & {
  __typename?: 'DenturesPanLabelInfo';
  arch?: Maybe<Scalars['String']['output']>;
  batchNumber?: Maybe<Scalars['String']['output']>;
  festooning?: Maybe<Scalars['String']['output']>;
  items: Array<LabsGqlPanLabelItemInfo>;
  labOrderId: Scalars['String']['output'];
  labelTags: Array<Scalars['String']['output']>;
  labtracId?: Maybe<Scalars['String']['output']>;
  modelType?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  panCode: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  priority: LabsGqlManufacturingOrderPriority;
  /** Only the date portion is relevant.  The time portion should be noon UTC. */
  shipByDate: Scalars['DateTime']['output'];
  stippling?: Maybe<Scalars['String']['output']>;
  tissueShade?: Maybe<Scalars['String']['output']>;
  toothShade?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlDenturesProductionType {
  Final = 'Final',
  TryIn = 'TryIn',
  WaxRim = 'WaxRim'
}

export enum LabsGqlDenturesSingleFulfillmentArchType {
  CentricTray = 'CentricTray',
  Copy = 'Copy',
  Immediate = 'Immediate',
  WaxRimContinuation = 'WaxRimContinuation'
}

export type LabsGqlDenturesSingleFulfillmentDto = LabsGqlDenturesFulfillmentCoreDto | LabsGqlDenturesFulfillmentFixupDto | LabsGqlDenturesFulfillmentTryInFollowupDto | LabsGqlDenturesFulfillmentWaxRimDto;

export enum LabsGqlDenturesSingleFulfillmentType {
  Core = 'Core',
  Fixup = 'Fixup',
  TryInFollowup = 'TryInFollowup',
  WaxRim = 'WaxRim'
}

export enum LabsGqlDenturesTeethQuality {
  Carded = 'Carded',
  Milled = 'Milled',
  Printed = 'Printed'
}

export enum LabsGqlDenturesTryInDesignType {
  DesignAroundExistingTeeth = 'DesignAroundExistingTeeth',
  DigitallyExtractAllExistingTeeth = 'DigitallyExtractAllExistingTeeth'
}

export type LabsGqlDesignCaseFile = {
  __typename?: 'DesignCaseFile';
  model_elements: Array<LabsGqlDesignCaseFileModelElement>;
  tooth_elements: Array<LabsGqlDesignCaseFileToothElement>;
};

export type LabsGqlDesignCaseFileModelElement = {
  __typename?: 'DesignCaseFileModelElement';
  cache_material_name: Scalars['String']['output'];
  color_id: Scalars['String']['output'];
  insertion_axis?: Maybe<Array<Scalars['Float']['output']>>;
  is_active: Scalars['Boolean']['output'];
  is_virtual: Scalars['Boolean']['output'];
  items_description: Scalars['String']['output'];
  manufacturer_id: Scalars['Float']['output'];
  model_element_id: Scalars['String']['output'];
  model_element_type?: Maybe<LabsGqlDesignCaseFileModelElementType>;
  model_file_name: Scalars['String']['output'];
  model_height?: Maybe<Scalars['Float']['output']>;
  model_job_id?: Maybe<Scalars['String']['output']>;
  model_volume?: Maybe<Scalars['Float']['output']>;
  process_status_id: Scalars['String']['output'];
};

export enum LabsGqlDesignCaseFileModelElementType {
  MeDigitalModelAntagonist = 'meDigitalModelAntagonist',
  MeDigitalModelDie = 'meDigitalModelDie',
  MeDigitalModelNeighbor = 'meDigitalModelNeighbor',
  MeDigitalModelPrepSectioned = 'meDigitalModelPrepSectioned',
  MeDigitalModelPrepUnsectioned = 'meDigitalModelPrepUnsectioned',
  MeDigitalModelTissue = 'meDigitalModelTissue',
  MeImplantPlanning = 'meImplantPlanning',
  MeIndicationRegular = 'meIndicationRegular',
  MeSplint = 'meSplint',
  MeSurgicalGuide = 'meSurgicalGuide',
  MeTransferGuide = 'meTransferGuide'
}

export type LabsGqlDesignCaseFileToothElement = {
  __typename?: 'DesignCaseFileToothElement';
  abutment_kit_id?: Maybe<Scalars['String']['output']>;
  individual_insertion_axis?: Maybe<Array<Scalars['Float']['output']>>;
  is_anatomical: Scalars['Boolean']['output'];
  is_post_and_core: Scalars['Boolean']['output'];
  model_element_id: Scalars['String']['output'];
  tool_element_type_id: Scalars['String']['output'];
  tooth_element_id: Scalars['String']['output'];
  tooth_number: Scalars['Float']['output'];
  tooth_type_class?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDesignEditorPayload = {
  /** ID of the design revision to inject into. */
  design_id: Scalars['String']['input'];
  /** Pass true to override design task validation and force the design to be attached to the order. */
  override_design_task_validation?: InputMaybe<Scalars['Boolean']['input']>;
  /** In-browser collected data on how long it took to create this design. */
  session_timing_data?: InputMaybe<LabsGqlPrepInBrowserSessionTimingData>;
  /** GCS path to the uploaded submission file. */
  submission_file_path: Scalars['String']['input'];
};

export type LabsGqlDesignEventLog = {
  __typename?: 'DesignEventLog';
  event_aggregates: Array<LabsGqlDesignEventLogEventAggregate>;
  metadata: LabsGqlDesignEventLogMetadata;
  sessions?: Maybe<Array<LabsGqlDesignEventLogSession>>;
};

export type LabsGqlDesignEventLogEventAggregate = {
  __typename?: 'DesignEventLogEventAggregate';
  count: Scalars['Float']['output'];
  cumulative_duration: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type LabsGqlDesignEventLogMetadata = {
  __typename?: 'DesignEventLogMetadata';
  abutments_post_and_core_step_duration: Scalars['Float']['output'];
  anatomy_design_step_duration: Scalars['Float']['output'];
  anatomy_pre_design_step_duration: Scalars['Float']['output'];
  bite_alignment_duration: Scalars['Float']['output'];
  cumulative_gap_between_sessions: Scalars['Float']['output'];
  cumulative_session_time: Scalars['Float']['output'];
  directions_step_duration: Scalars['Float']['output'];
  frame_design_step_duration: Scalars['Float']['output'];
  idle_duration: Scalars['Float']['output'];
  interfaces_step_duration: Scalars['Float']['output'];
  loading_duration: Scalars['Float']['output'];
  margin_marking_duration: Scalars['Float']['output'];
  number_sessions: Scalars['Float']['output'];
  occlusal_plane_duration: Scalars['Float']['output'];
  path_of_insertion_duration: Scalars['Float']['output'];
  prepare_step_duration: Scalars['Float']['output'];
  sculpting_scan_duration: Scalars['Float']['output'];
  segmentation_step_duration: Scalars['Float']['output'];
  trimming_scan_duration: Scalars['Float']['output'];
  upload_duration: Scalars['Float']['output'];
};

export type LabsGqlDesignEventLogSession = {
  __typename?: 'DesignEventLogSession';
  duration_ms: Scalars['Float']['output'];
  finished_at: Scalars['DateTime']['output'];
  ms_since_last_session: Scalars['Float']['output'];
  staff_id?: Maybe<Scalars['String']['output']>;
  started_at: Scalars['DateTime']['output'];
};

export type LabsGqlDesignFileDme = {
  __typename?: 'DesignFileDme';
  dme_name: Scalars['String']['output'];
  dme_version: Scalars['Float']['output'];
  latest_version: Scalars['Float']['output'];
  provides_materials: Array<Scalars['String']['output']>;
  provides_smile_styles: Array<Scalars['String']['output']>;
};

export type LabsGqlDesignFileDmeListResult = {
  __typename?: 'DesignFileDmeListResult';
  found_dmes: Array<LabsGqlDesignFileDme>;
  number_unmatched_entries: Scalars['Float']['output'];
};

export enum LabsGqlDesignFileValidationError {
  BadArchiveName = 'BAD_ARCHIVE_NAME',
  ExcessiveFileSize = 'EXCESSIVE_FILE_SIZE',
  Missing_3Oxz = 'MISSING_3OXZ',
  MissingCaseFile = 'MISSING_CASE_FILE',
  MissingDcmStl = 'MISSING_DCM_STL',
  WrongPatientErrorMsg = 'WRONG_PATIENT_ERROR_MSG'
}

/** An individual manufacturer file entry. */
export type LabsGqlDesignManufacturerFileResult = {
  __typename?: 'DesignManufacturerFileResult';
  /** What the file should be called when saved to disk. */
  file_name: Scalars['String']['output'];
  /** The GCS path to the file. */
  gcs_path: Scalars['String']['output'];
  order_id: Scalars['String']['output'];
};

/** An individual merge and stitch file entry. */
export type LabsGqlDesignMergeAndStitchFileResult = {
  __typename?: 'DesignMergeAndStitchFileResult';
  design_revision_id: Scalars['String']['output'];
  /** What the file should be called when saved to disk. */
  file_name: Scalars['String']['output'];
  /** The GCS path to the file. */
  gcs_path: Scalars['String']['output'];
  order_id: Scalars['String']['output'];
};

export type LabsGqlDesignMetafieldSubmission = {
  __typename?: 'DesignMetafieldSubmission';
  name: Scalars['String']['output'];
  value: Scalars['MetafieldJSON']['output'];
};

export type LabsGqlDesignMetafieldSubmissionInput = {
  name: Scalars['String']['input'];
  value: Scalars['MetafieldJSON']['input'];
};

/** The main data structure describing an aligner treatment plan. Combines the former softsmile revisions and treatment plan concepts into one data structure. */
export type LabsGqlDesignOrderAlignerTreatmentPlanDto = {
  __typename?: 'DesignOrderAlignerTreatmentPlanDTO';
  created_at: Scalars['DateTime']['output'];
  /** The ID of the user who created this treatment plan. */
  created_by_user_id?: Maybe<Scalars['String']['output']>;
  /** The ID of the design order this treatment plan belongs to. */
  design_order_id: Scalars['ID']['output'];
  doctor_review?: Maybe<LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewDto>;
  duration_in_months: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Whether this is the most recent treatment plan we've shared with the dentist. */
  is_latest_doctor_treatment_plan: Scalars['Boolean']['output'];
  manufacturing_files: LabsGqlDesignOrderAlignerTreatmentPlanManufacturerFiles;
  needs_attachments: LabsGqlDesignOrderAlignerTreatmentPlanNeedsAttachments;
  /** Whether any steps require interproximal reductions. Provided as a convenience instead of querying all of the steps. */
  needs_interproximal_reductions?: Maybe<Scalars['Boolean']['output']>;
  /** For SoftSmile cases, the metadata needed for the web API. */
  softsmile_metadata: LabsGqlDesignOrderAlignerTreatmentPlanSoftSmileMetadataDto;
  /** What software created this treatment plan. All new treatment plans should use SoftSmile, but old plans may use ArchForm. */
  source: LabsGqlDesignOrderAlignerTreatmentPlanSource;
  steps: Array<LabsGqlDesignOrderAlignerTreatmentPlanStepDto>;
  steps_count: LabsGqlDesignOrderAlignerTreatmentPlanStepsCount;
  updated_at: Scalars['DateTime']['output'];
  /** The version number of this treatment plan, for internal usages only. For SoftSmile treatment plans, this is the SoftSmile version number. */
  version_number_internal: Scalars['Int']['output'];
  /** The version number of this treatment plan, for the doctor's usage, IF and ONLY IF we shared the plan with the doctor. Null implies it is internal only. */
  version_number_practice?: Maybe<Scalars['Int']['output']>;
};

/** The data structure containing information on a doctor's review of an aligner treatment plan. There is exactly zero or one reviews per treatment plan. */
export type LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewDto = {
  __typename?: 'DesignOrderAlignerTreatmentPlanDoctorReviewDTO';
  /** The ID of the treatment plan that this review is for. */
  aligner_treatment_plan_id: Scalars['ID']['output'];
  created_at: Scalars['DateTime']['output'];
  /** The ID of the staff member that submitted this review. */
  created_by_staff_id?: Maybe<Scalars['String']['output']>;
  /** The ID of the design order associated with this review. */
  design_order_id: Scalars['String']['output'];
  /** Any notes associated with this review. Presently only set upon rejections. */
  notes?: Maybe<Scalars['String']['output']>;
  status: LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus;
};

export enum LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type LabsGqlDesignOrderAlignerTreatmentPlanManufacturerFiles = {
  __typename?: 'DesignOrderAlignerTreatmentPlanManufacturerFiles';
  /** The converted manufacturing ZIP file, which can be sent to the lab for manufacturing. */
  converted_gcs_path?: Maybe<Scalars['String']['output']>;
  /** The raw, unconverted manufacturing ZIP file. Will need to be processed before usage. */
  unconverted_gcs_path?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDesignOrderAlignerTreatmentPlanNeedsAttachments = {
  __typename?: 'DesignOrderAlignerTreatmentPlanNeedsAttachments';
  /** Whether attachments are required on the lower jaw to complete this treatment plan. */
  lower: Scalars['Boolean']['output'];
  /** Whether attachments are required on the upper jaw to complete this treatment plan. */
  upper: Scalars['Boolean']['output'];
};

/** Metadata used for communicating with SoftSmile'S API. */
export type LabsGqlDesignOrderAlignerTreatmentPlanSoftSmileMetadataDto = {
  __typename?: 'DesignOrderAlignerTreatmentPlanSoftSmileMetadataDTO';
  metadata_path?: Maybe<Scalars['String']['output']>;
  report_path?: Maybe<Scalars['String']['output']>;
  treatment_plan_path?: Maybe<Scalars['String']['output']>;
  /** When set, implies that webviewer assets have been uploaded to the cloud, and thus the webviewer is ready to be used. */
  viewer_path?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlDesignOrderAlignerTreatmentPlanSource {
  ArchForm = 'ARCH_FORM',
  OnyxCeph = 'ONYX_CEPH',
  SoftSmile = 'SOFT_SMILE'
}

/** The data structure used to describe an individual step of a treatment plan. */
export type LabsGqlDesignOrderAlignerTreatmentPlanStepDto = {
  __typename?: 'DesignOrderAlignerTreatmentPlanStepDTO';
  /** The ID of the treatment plan that this step belongs to */
  aligner_treatment_plan_id: Scalars['ID']['output'];
  /** A mapping of how much interproximal reduction is needed per tooth */
  interproximal_reductions: Array<LabsGqlDesignOrderAlignerTreatmentPlanStepInterproximalReductionDto>;
  /** Webviewer asset paths, used only for pre-SoftSmile cases. */
  legacy_assets: LabsGqlDesignOrderAlignerTreatmentPlanStepLegacyAssets;
  step: Scalars['Int']['output'];
};

export type LabsGqlDesignOrderAlignerTreatmentPlanStepInterproximalReductionDto = {
  __typename?: 'DesignOrderAlignerTreatmentPlanStepInterproximalReductionDTO';
  reduction_in_mm?: Maybe<Scalars['Float']['output']>;
  tooth: Scalars['String']['output'];
};

export type LabsGqlDesignOrderAlignerTreatmentPlanStepLegacyAssets = {
  __typename?: 'DesignOrderAlignerTreatmentPlanStepLegacyAssets';
  lower_path?: Maybe<Scalars['String']['output']>;
  upper_path?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDesignOrderAlignerTreatmentPlanStepsCount = {
  __typename?: 'DesignOrderAlignerTreatmentPlanStepsCount';
  /** The number of lower jaw steps this treatment plan will require */
  lower: Scalars['Float']['output'];
  /** The number of steps, overall, this treatment plan will take end to end. */
  overall: Scalars['Float']['output'];
  /** The number of upper jaw steps this treatment plan will require */
  upper: Scalars['Float']['output'];
};

export type LabsGqlDesignOrderDto = {
  __typename?: 'DesignOrderDTO';
  id: Scalars['ID']['output'];
};

export type LabsGqlDesignOrderDtoNote = {
  __typename?: 'DesignOrderDTONote';
  category: LabsGqlDesignOrderNoteCategory;
};

/** The various 3D assets contained in the design file. If set, these will expire after the parent design_revision's assets_expire_at date. */
export type LabsGqlDesignOrderDesignRevisionAsset = {
  __typename?: 'DesignOrderDesignRevisionAsset';
  file_path: Scalars['String']['output'];
  file_type: LabsGqlDesignRevisionAssetFileType;
  image_path?: Maybe<Scalars['String']['output']>;
  mesh_type: LabsGqlOrderDesignScanType;
};

/** An individual design asset. */
export type LabsGqlDesignOrderDesignRevisionAssetInput = {
  /** Defaults to the latest design revision id. */
  design_revision_id?: InputMaybe<Scalars['String']['input']>;
  file_path: Scalars['String']['input'];
  file_type: LabsGqlDesignRevisionAssetFileType;
  image_path?: InputMaybe<Scalars['String']['input']>;
  mesh_type: LabsGqlOrderDesignScanType;
};

export type LabsGqlDesignOrderDesignRevisionDto = {
  __typename?: 'DesignOrderDesignRevisionDTO';
  /** All of the converted assets of a design, in multiple formats. If the assets are expired, will instead be an empty array. */
  assets?: Maybe<Array<LabsGqlDesignOrderDesignRevisionAsset>>;
  /** The date at which the various converted assets of this design will be auto-cleaned from Storage. */
  assets_expire_at?: Maybe<Scalars['DateTime']['output']>;
  /** The status of automate for this particular design revision. */
  automate_status: LabsGqlDesignRevisionAutomateStatus;
  /** A list of the actual appliances, models, and restoratives as defined in the design case file. These relate to the tooth elements. */
  case_file_model_elements: Array<LabsGqlDesignRevisionCaseFileModelElement>;
  /** A list of tooth elements as defined in the design case file. These are the indications per tooth that were provided in the design case file. */
  case_file_tooth_elements: Array<LabsGqlDesignCaseFileToothElement>;
  /** Various GCS files created during design conversion which may be needed for rendering the design */
  conversion_artifacts?: Maybe<LabsGqlDesignRevisionConversionArtifacts>;
  created_at: Scalars['DateTime']['output'];
  created_by_staff_id?: Maybe<Scalars['String']['output']>;
  /** Margins provided during portal editing */
  custom_margins?: Maybe<Array<LabsGqlDesignRevisionCustomMargin>>;
  /** The ID of the design order associated with this revision. */
  design_order_id: Scalars['String']['output'];
  /** If provided, indicates the prep stage feedback submitted by the designer during finishing */
  design_prep_feedback?: Maybe<Array<Scalars['String']['output']>>;
  design_source: LabsGqlDesignRevisionSource;
  design_type: LabsGqlOrderDesignType;
  doctor_review?: Maybe<LabsGqlDesignOrderDoctorReviewDto>;
  /** The duration and click data of various tools and sections within Dental Designer, aggregated by the name of the action or tool, from the design event log. Only works for restorations. */
  event_log_event_aggregates: Array<LabsGqlDesignRevisionEventLogEventAggregate>;
  /** The overall duration data by section within Dental Designer. Only works for restorations. */
  event_log_metadata?: Maybe<LabsGqlDesignRevisionEventLogMetadata>;
  /** A list of individual sessions taken over the course of the design within Dental Designer. Only works for restorations. */
  event_log_sessions?: Maybe<Array<LabsGqlDesignRevisionEventLogSession>>;
  /** The submitted results of a QC review submitted via guided QC. */
  guided_qc_results?: Maybe<Array<LabsGqlDesignReviewGuidedQcResultEntry>>;
  /** if any dcm in the design revision has morph points. */
  has_morph_points: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  /** Whether this design is the latest design revision in the design order */
  is_latest_design: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  /** GCS paths for nightguard manufacturing files that are manually uploaded by the designer. */
  manually_uploaded_manufacturer_files?: Maybe<Array<Scalars['String']['output']>>;
  /** An array of GCS paths for CAM files meant for lab download. Not presently used by Lehi. */
  manufacturer_files?: Maybe<Array<Scalars['String']['output']>>;
  /** The submitted results of a QC review submitted via the old QC system. */
  non_guided_qc_results?: Maybe<LabsGqlOrderDesignNonGuidedQcResults>;
  /** Notes from the designer, meant for internal usage only. */
  notes?: Maybe<Scalars['String']['output']>;
  /** The id of the revision that this design is based on. */
  parent_design_id?: Maybe<Scalars['String']['output']>;
  /** GCS path for prep in browser metadata like scanTransforms. */
  prep_metadata_path?: Maybe<Scalars['String']['output']>;
  /** The GCS path that the entire design zip is located at. */
  source_file_zip_path: Scalars['String']['output'];
  triggered_by_task_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  /** Automated checks ran during upload and processing of the design */
  validation_data?: Maybe<LabsGqlDesignRevisionValidationData>;
  /** If provided, the additional information of 'WorkflowType' e.g. wftFD/wftRPD distinguishing our removable partial dentures. */
  workflow_type?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDesignOrderDoctorReviewDto = {
  __typename?: 'DesignOrderDoctorReviewDTO';
  annotations?: Maybe<Array<LabsGqlDesignViewPreset>>;
  /** The lab_order workflow task id that this doctor review resolves */
  completes_task_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** The ID of the design order associated with this review. */
  design_order_id: Scalars['String']['output'];
  design_order_revision?: Maybe<LabsGqlDesignOrderDesignRevisionDto>;
  /** The ID of the design order revision associated with this review. */
  design_revision_id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  review_submitted_by_staff_id?: Maybe<Scalars['String']['output']>;
  /** The status of this doctor review. */
  status: LabsGqlDesignOrderDoctorReviewStatus;
};

export enum LabsGqlDesignOrderDoctorReviewStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export enum LabsGqlDesignOrderNoteCategory {
  BringCuspsIntoOcclusion = 'BRING_CUSPS_INTO_OCCLUSION',
  BringCuspsIntoOcclusionBuccal = 'BRING_CUSPS_INTO_OCCLUSION__BUCCAL',
  BringCuspsIntoOcclusionLingual = 'BRING_CUSPS_INTO_OCCLUSION__LINGUAL',
  Buccal = 'BUCCAL',
  BuccalFillOutContour = 'BUCCAL__FILL_OUT_CONTOUR',
  BuccalReduceOvercontour = 'BUCCAL__REDUCE_OVERCONTOUR',
  CorrectCuspPlacement = 'CORRECT_CUSP_PLACEMENT',
  CorrectCuspPlacementBuccal = 'CORRECT_CUSP_PLACEMENT__BUCCAL',
  CorrectCuspPlacementLingual = 'CORRECT_CUSP_PLACEMENT__LINGUAL',
  Distal = 'DISTAL',
  DistalMakeContactBroader = 'DISTAL__MAKE_CONTACT_BROADER',
  DistalMakeContactLessBroad = 'DISTAL__MAKE_CONTACT_LESS_BROAD',
  DistalMakeContactLessTall = 'DISTAL__MAKE_CONTACT_LESS_TALL',
  DistalMakeContactTaller = 'DISTAL__MAKE_CONTACT_TALLER',
  DistalMakeMarginalRidgeHigher = 'DISTAL__MAKE_MARGINAL_RIDGE_HIGHER',
  DistalMakeMarginalRidgeLower = 'DISTAL__MAKE_MARGINAL_RIDGE_LOWER',
  Esthetics = 'ESTHETICS',
  EstheticsEnhanceLineAngles = 'ESTHETICS__ENHANCE_LINE_ANGLES',
  EstheticsOther = 'ESTHETICS__OTHER',
  EstheticsSoftenLineAngles = 'ESTHETICS__SOFTEN_LINE_ANGLES',
  Facial = 'FACIAL',
  FacialFillOutContour = 'FACIAL__FILL_OUT_CONTOUR',
  FacialFillOutContourCervical = 'FACIAL__FILL_OUT_CONTOUR__CERVICAL',
  FacialFillOutContourIncisal = 'FACIAL__FILL_OUT_CONTOUR__INCISAL',
  FacialMakeLessPronounced = 'FACIAL__MAKE_LESS_PRONOUNCED',
  FacialMakeMorePronounced = 'FACIAL__MAKE_MORE_PRONOUNCED',
  FacialReduceOvercontour = 'FACIAL__REDUCE_OVERCONTOUR',
  FacialReduceOvercontourCervical = 'FACIAL__REDUCE_OVERCONTOUR__CERVICAL',
  FacialReduceOvercontourIncisal = 'FACIAL__REDUCE_OVERCONTOUR__INCISAL',
  GingivalEmbrasure = 'GINGIVAL_EMBRASURE',
  GingivalEmbrasureClose = 'GINGIVAL_EMBRASURE__CLOSE',
  GingivalEmbrasureCloseDistal = 'GINGIVAL_EMBRASURE__CLOSE__DISTAL',
  GingivalEmbrasureCloseMesial = 'GINGIVAL_EMBRASURE__CLOSE__MESIAL',
  GingivalEmbrasureOpen = 'GINGIVAL_EMBRASURE__OPEN',
  GingivalEmbrasureOpenDistal = 'GINGIVAL_EMBRASURE__OPEN__DISTAL',
  GingivalEmbrasureOpenMesial = 'GINGIVAL_EMBRASURE__OPEN__MESIAL',
  IncisalEdgeDesign = 'INCISAL_EDGE_DESIGN',
  IncisalEdgeDesignAdjustShape = 'INCISAL_EDGE_DESIGN__ADJUST_SHAPE',
  IncisalEdgeDesignAdjustShapePointy = 'INCISAL_EDGE_DESIGN__ADJUST_SHAPE__POINTY',
  IncisalEdgeDesignAdjustShapeRounded = 'INCISAL_EDGE_DESIGN__ADJUST_SHAPE__ROUNDED',
  IncisalEdgeDesignAdjustShapeSquared = 'INCISAL_EDGE_DESIGN__ADJUST_SHAPE__SQUARED',
  IncisalEdgeDesignDecreaseLength = 'INCISAL_EDGE_DESIGN__DECREASE_LENGTH',
  IncisalEdgeDesignIncreaseLength = 'INCISAL_EDGE_DESIGN__INCREASE_LENGTH',
  Lingual = 'LINGUAL',
  LingualFillOutContour = 'LINGUAL__FILL_OUT_CONTOUR',
  LingualFillOutContourCingulum = 'LINGUAL__FILL_OUT_CONTOUR__CINGULUM',
  LingualFillOutContourIncisal = 'LINGUAL__FILL_OUT_CONTOUR__INCISAL',
  LingualReduceOvercontour = 'LINGUAL__REDUCE_OVERCONTOUR',
  LingualReduceOvercontourCingulum = 'LINGUAL__REDUCE_OVERCONTOUR__CINGULUM',
  LingualReduceOvercontourIncisal = 'LINGUAL__REDUCE_OVERCONTOUR__INCISAL',
  MarginBuccal = 'MARGIN__BUCCAL',
  MarginBuccalAdjustOverextended = 'MARGIN__BUCCAL__ADJUST_OVEREXTENDED',
  MarginBuccalExtend = 'MARGIN__BUCCAL__EXTEND',
  MarginDistal = 'MARGIN__DISTAL',
  MarginDistalAdjustOverextended = 'MARGIN__DISTAL__ADJUST_OVEREXTENDED',
  MarginDistalExtend = 'MARGIN__DISTAL__EXTEND',
  MarginLingual = 'MARGIN__LINGUAL',
  MarginLingualAdjustOverextended = 'MARGIN__LINGUAL__ADJUST_OVEREXTENDED',
  MarginLingualExtend = 'MARGIN__LINGUAL__EXTEND',
  MarginMesial = 'MARGIN__MESIAL',
  MarginMesialAdjustOverextended = 'MARGIN__MESIAL__ADJUST_OVEREXTENDED',
  MarginMesialExtend = 'MARGIN__MESIAL__EXTEND',
  Mesial = 'MESIAL',
  MesialMakeContactBroader = 'MESIAL__MAKE_CONTACT_BROADER',
  MesialMakeContactLessBroad = 'MESIAL__MAKE_CONTACT_LESS_BROAD',
  MesialMakeContactLessTall = 'MESIAL__MAKE_CONTACT_LESS_TALL',
  MesialMakeContactTaller = 'MESIAL__MAKE_CONTACT_TALLER',
  MesialMakeMarginalRidgeHigher = 'MESIAL__MAKE_MARGINAL_RIDGE_HIGHER',
  MesialMakeMarginalRidgeLower = 'MESIAL__MAKE_MARGINAL_RIDGE_LOWER',
  OcclusalAnatomyLessAggressive = 'OCCLUSAL_ANATOMY_LESS_AGGRESSIVE'
}

export type LabsGqlDesignOrderNoteInput = {
  category: LabsGqlDesignOrderNoteCategory;
};

export type LabsGqlDesignOrderRemovableManufacturingFilesDto = {
  __typename?: 'DesignOrderRemovableManufacturingFilesDTO';
  blockout_model_lower_stl?: Maybe<Scalars['String']['output']>;
  blockout_model_upper_stl?: Maybe<Scalars['String']['output']>;
  device_lower_stl?: Maybe<Scalars['String']['output']>;
  device_upper_stl?: Maybe<Scalars['String']['output']>;
  model_lower_stl?: Maybe<Scalars['String']['output']>;
  model_upper_stl?: Maybe<Scalars['String']['output']>;
  trimline_lower_pts?: Maybe<Scalars['String']['output']>;
  trimline_upper_pts?: Maybe<Scalars['String']['output']>;
  waxrim_lower_stl?: Maybe<Scalars['String']['output']>;
  waxrim_upper_stl?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDesignOrderRemovableManufacturingFilesInput = {
  blockout_model_lower_stl?: InputMaybe<Scalars['String']['input']>;
  blockout_model_upper_stl?: InputMaybe<Scalars['String']['input']>;
  device_lower_stl?: InputMaybe<Scalars['String']['input']>;
  device_upper_stl?: InputMaybe<Scalars['String']['input']>;
  model_lower_stl?: InputMaybe<Scalars['String']['input']>;
  model_upper_stl?: InputMaybe<Scalars['String']['input']>;
  trimline_lower_pts?: InputMaybe<Scalars['String']['input']>;
  trimline_upper_pts?: InputMaybe<Scalars['String']['input']>;
  waxrim_lower_stl?: InputMaybe<Scalars['String']['input']>;
  waxrim_upper_stl?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlDesignOrderRevisionSubmissionPayload = {
  /** The GCS path for the design file's zip archive. */
  design_file_zip_path?: InputMaybe<Scalars['String']['input']>;
  design_order_id: Scalars['String']['input'];
  /** Which mechanism was used to upload this design revision. */
  design_revision_source: LabsGqlDesignRevisionSource;
  /** Feedback provided by the designer on previous stages of the design. */
  design_stage_feedback?: InputMaybe<LabsGqlDesignOrderRevisionSubmissionPayloadDesignStageFeedback>;
  /** Submission information needed for a night guards fulfillment, containing the STL outputs of various files needed to manufacture the night guard. */
  nightguard_metadata?: InputMaybe<LabsGqlDesignOrderRevisionSubmissionPayloadNightguardMetadata>;
  /** Notes about this design that are meant to be viewed by internal users only. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** When set to true, we will ignore automated checks for this submission and forcibly submit the design. */
  override_validation: Scalars['Boolean']['input'];
  /** The GCS path of the prep in browser metadata file(like scanTransformations). */
  prep_metadata_path?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlDesignOrderRevisionSubmissionPayloadDesignStageFeedback = {
  prep?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LabsGqlDesignOrderRevisionSubmissionPayloadNightguardMetadata = {
  /** The GCS path of the STL file for the nightguard itself. */
  device_stl_path: Scalars['String']['input'];
  /** The GCS path of the STL file for the lower jaw model. */
  model_lower_stl_path?: InputMaybe<Scalars['String']['input']>;
  /** The GCS path of the STL file for the upper jaw model. */
  model_upper_stl_path?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlDesignOrderRevisionValidationResult = {
  __typename?: 'DesignOrderRevisionValidationResult';
  /** Irreconcilable differences between the submitted design and case file we expected to be submitted. */
  case_file_invalid_differences: Array<Scalars['String']['output']>;
  /** Validation errors that use the old detection mechanism. These will soon be deprecated and replaced by case_file_invalid_differences and other mechanisms. */
  old_design_validation_errors: Array<LabsGqlDesignFileValidationError>;
  /** The overall status of the validation. When FAILED, the uploaded design likely is invalid and should not be submitted. */
  validation_status: LabsGqlDesignOrderRevisionValidationResultStatus;
};

export enum LabsGqlDesignOrderRevisionValidationResultStatus {
  Failed = 'FAILED',
  Passed = 'PASSED'
}

export type LabsGqlDesignPrepCapabilitiesDto = {
  __typename?: 'DesignPrepCapabilitiesDTO';
  anterior_crown_and_bridge?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  dentures?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  implant?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  posterior_crown_and_bridge?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  thermoform?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
};

export type LabsGqlDesignPrepCapabilitiesInput = {
  anterior_crown_and_bridge?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  dentures?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  implant?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  posterior_crown_and_bridge?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  thermoform?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
};

export type LabsGqlDesignQaEvaluationDto = {
  __typename?: 'DesignQaEvaluationDTO';
  assigned_user_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  design_file_path: Scalars['String']['output'];
  design_revision?: Maybe<LabsGqlDesignOrderDesignRevisionDto>;
  design_type: LabsGqlOrderDesignType;
  id: Scalars['ID']['output'];
  is_calibration: Scalars['Boolean']['output'];
  order?: Maybe<LabsGqlLabOrderWithEvents>;
  order_id: Scalars['String']['output'];
  review_result_entries?: Maybe<Array<LabsGqlDesignReviewGuidedQcResultEntry>>;
  status: LabsGqlDesignQaEvaluationStatus;
  unit_count?: Maybe<Scalars['Float']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlDesignQaEvaluationStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Passed = 'PASSED',
  PendingReview = 'PENDING_REVIEW'
}

export type LabsGqlDesignReduceOpposingGuide = {
  __typename?: 'DesignReduceOpposingGuide';
  image_paths: Array<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDesignReduceOpposingGuideInput = {
  image_paths: Array<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlDesignReviewCapabilitiesDto = {
  __typename?: 'DesignReviewCapabilitiesDTO';
  anterior_crown_and_bridge?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  dentures?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  dentures_wax_rim?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  implant?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  model?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  pfm?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  pfz?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  posterior_crown_and_bridge?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  waxup?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
};

export type LabsGqlDesignReviewFieldDto = {
  __typename?: 'DesignReviewFieldDTO';
  applicable_order_types: Array<LabsGqlDesignReviewFieldFilterType>;
  archived: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  field: LabsGqlDesignReviewFormField;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  types: Array<LabsGqlDesignReviewFieldType>;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlDesignReviewFieldFilterType {
  AnteriorBridge = 'AnteriorBridge',
  AnteriorImplant = 'AnteriorImplant',
  AnteriorImplantBridge = 'AnteriorImplantBridge',
  FullDenture = 'FullDenture',
  NightGuard = 'NightGuard',
  PosteriorBridge = 'PosteriorBridge',
  PosteriorImplant = 'PosteriorImplant',
  PosteriorImplantBridge = 'PosteriorImplantBridge',
  Aligner = 'aligner',
  Anterior = 'anterior',
  Implant = 'implant',
  Lower = 'lower',
  Model = 'model',
  Posterior = 'posterior',
  Upper = 'upper'
}

export enum LabsGqlDesignReviewFieldType {
  DesignPrep = 'DesignPrep',
  DesignReview = 'DesignReview',
  FlagScan = 'FlagScan',
  InternalDesign = 'InternalDesign'
}

export type LabsGqlDesignReviewFormField = {
  __typename?: 'DesignReviewFormField';
  default_value?: Maybe<Scalars['MetafieldJSON']['output']>;
  options?: Maybe<Array<LabsGqlICustomFieldOption>>;
  type: LabsGqlCustomFieldType;
};

export type LabsGqlDesignReviewFormFieldInput = {
  default_value?: InputMaybe<Scalars['MetafieldJSON']['input']>;
  options?: InputMaybe<Array<LabsGqlICustomFieldOptionInput>>;
  type: LabsGqlCustomFieldType;
};

export type LabsGqlDesignReviewGuidedQcResultEntry = {
  __typename?: 'DesignReviewGuidedQcResultEntry';
  affected_unns: Array<Scalars['Float']['output']>;
  category: Scalars['String']['output'];
  duration_ms: Scalars['Float']['output'];
  markup_paths?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  reasons?: Maybe<Array<Scalars['String']['output']>>;
  verdict: LabsGqlGuidedQcResultEntryScoreVerdict;
};

export type LabsGqlDesignReviewGuidedQcResultEntryInput = {
  affected_unns: Array<Scalars['Float']['input']>;
  category: Scalars['String']['input'];
  duration_ms: Scalars['Float']['input'];
  markup_paths?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
  verdict: LabsGqlGuidedQcResultEntryScoreVerdict;
};

export type LabsGqlDesignReviewRejectionSubmissionInput = {
  next_assigned_user_id?: InputMaybe<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  reasons: Array<Scalars['String']['input']>;
  skip_next_review?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlDesignReviewWorkflowTask = LabsGqlIWorkflowTask & {
  __typename?: 'DesignReviewWorkflowTask';
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlWorkflowTaskDesignReviewConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export enum LabsGqlDesignRevisionAssetFileType {
  Ctm = 'CTM',
  Dcm = 'DCM',
  Drc = 'DRC',
  Glb = 'GLB',
  Ply = 'PLY',
  Stl = 'STL'
}

export enum LabsGqlDesignRevisionAutomateStatus {
  AutomateInjected = 'AUTOMATE_INJECTED',
  AutomateReplaced = 'AUTOMATE_REPLACED',
  None = 'NONE'
}

export type LabsGqlDesignRevisionCaseFileModelElement = {
  __typename?: 'DesignRevisionCaseFileModelElement';
  cache_material_name: Scalars['String']['output'];
  color_id: Scalars['String']['output'];
  insertion_axis?: Maybe<Array<Scalars['Float']['output']>>;
  is_active: Scalars['Boolean']['output'];
  is_virtual: Scalars['Boolean']['output'];
  items_description: Scalars['String']['output'];
  manufacturer_id: Scalars['Float']['output'];
  model_element_id: Scalars['String']['output'];
  model_element_type?: Maybe<LabsGqlDesignCaseFileModelElementType>;
  model_file_name: Scalars['String']['output'];
  model_height?: Maybe<Scalars['Float']['output']>;
  model_job_id?: Maybe<Scalars['String']['output']>;
  model_volume?: Maybe<Scalars['Float']['output']>;
  process_status_id: Scalars['String']['output'];
};

export type LabsGqlDesignRevisionConversionArtifacts = {
  __typename?: 'DesignRevisionConversionArtifacts';
  case_file_json_path?: Maybe<Scalars['String']['output']>;
  design_acceleration_data_path?: Maybe<Scalars['String']['output']>;
  design_tree_path?: Maybe<Scalars['String']['output']>;
  manufacturing_acceleration_data_path?: Maybe<Scalars['String']['output']>;
  metadata_json_path?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlDesignRevisionCustomMargin = {
  __typename?: 'DesignRevisionCustomMargin';
  coords: Array<Scalars['Float']['output']>;
  source: LabsGqlDesignRevisionCustomMarginSource;
  spline_type: LabsGqlDesignRevisionCustomSplineType;
  unn: Scalars['Float']['output'];
};

export enum LabsGqlDesignRevisionCustomMarginSource {
  Designer = 'DESIGNER',
  Doctor = 'DOCTOR'
}

export enum LabsGqlDesignRevisionCustomSplineType {
  DcmDesignerMargin = 'DCM_DESIGNER_MARGIN',
  DcmDoctorMargin = 'DCM_DOCTOR_MARGIN',
  DcmPrepLine = 'DCM_PREP_LINE',
  Margin = 'MARGIN'
}

export type LabsGqlDesignRevisionEventLogEventAggregate = {
  __typename?: 'DesignRevisionEventLogEventAggregate';
  count: Scalars['Float']['output'];
  cumulative_duration?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
};

export type LabsGqlDesignRevisionEventLogMetadata = {
  __typename?: 'DesignRevisionEventLogMetadata';
  abutments_post_and_core_step_duration: Scalars['Float']['output'];
  anatomy_design_step_duration: Scalars['Float']['output'];
  anatomy_pre_design_step_duration: Scalars['Float']['output'];
  bite_alignment_duration: Scalars['Float']['output'];
  cumulative_gap_between_sessions: Scalars['Float']['output'];
  cumulative_session_time: Scalars['Float']['output'];
  directions_step_duration: Scalars['Float']['output'];
  frame_design_step_duration: Scalars['Float']['output'];
  idle_duration: Scalars['Float']['output'];
  interfaces_step_duration: Scalars['Float']['output'];
  loading_duration: Scalars['Float']['output'];
  margin_marking_duration: Scalars['Float']['output'];
  number_sessions: Scalars['Float']['output'];
  occlusal_plane_duration: Scalars['Float']['output'];
  path_of_insertion_duration: Scalars['Float']['output'];
  prepare_step_duration: Scalars['Float']['output'];
  sculpting_scan_duration: Scalars['Float']['output'];
  segmentation_step_duration: Scalars['Float']['output'];
  trimming_scan_duration: Scalars['Float']['output'];
  upload_duration: Scalars['Float']['output'];
};

export type LabsGqlDesignRevisionEventLogSession = {
  __typename?: 'DesignRevisionEventLogSession';
  duration_ms: Scalars['Float']['output'];
  finished_at: Scalars['DateTime']['output'];
  ms_since_last_session: Scalars['Float']['output'];
  staff_id?: Maybe<Scalars['String']['output']>;
  started_at: Scalars['DateTime']['output'];
};

export enum LabsGqlDesignRevisionSource {
  Cadflow = 'CADFLOW',
  DandyDesignEditor = 'DANDY_DESIGN_EDITOR',
  DandyMl = 'DANDY_ML',
  DandyPortalFinishing = 'DANDY_PORTAL_FINISHING',
  DandyPortalPrep = 'DANDY_PORTAL_PREP',
  ThreeshapeAutomate = 'THREESHAPE_AUTOMATE',
  ThreeshapeDentalSystem = 'THREESHAPE_DENTAL_SYSTEM'
}

export type LabsGqlDesignRevisionValidationData = {
  __typename?: 'DesignRevisionValidationData';
  /** If we run the automate validation for this order */
  automate_validation_done?: Maybe<Scalars['Boolean']['output']>;
  /** Errors found during validation of automate design, if any */
  automate_validation_errors?: Maybe<Array<LabsGqlAutomateValidationError>>;
  /** Potential errors in case setup, obtained by computing an expected case file for the order and diffing it with the real case file. This is not yet stable and should not be depended on until the case file generator is further refined. */
  case_file_invalid_differences?: Maybe<Array<Scalars['String']['output']>>;
  /** Errors found during design conversion */
  conversion_errors?: Maybe<Array<Scalars['String']['output']>>;
  /** Errors shown to the user during upload, which they bypassed and submitted anyway */
  upload_time_errors?: Maybe<Array<LabsGqlDesignFileValidationError>>;
};

export type LabsGqlDesignRevisionVariant = {
  fileUrl: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  variant: LabsGqlOrderItemVariantType;
  variantType: LabsGqlOrderItemVariantsPrefType;
};

export type LabsGqlDesignRule = {
  __typename?: 'DesignRule';
  condition_json: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  disabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  item_condition_json: Scalars['String']['output'];
  /** @deprecated always uses the design forecast day (8am-8am ET) now */
  limit_range: LabsGqlRoutingMetricsRange;
  name: Scalars['String']['output'];
  order_condition_json: Scalars['String']['output'];
  order_limit?: Maybe<Scalars['Int']['output']>;
  unit_limit?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlDesignStaffAllTaskCapabilitiesDto = {
  __typename?: 'DesignStaffAllTaskCapabilitiesDTO';
  DesignPrep: LabsGqlDesignPrepCapabilitiesDto;
  DesignReview: LabsGqlDesignReviewCapabilitiesDto;
  InjectionMoldDesign: LabsGqlInjectionMoldDesignCapabilitiesDto;
  InternalDesign: LabsGqlInternalDesignCapabilitiesDto;
};

export type LabsGqlDesignStaffCapabilityInput = {
  aligners?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  anterior_crown_and_bridge?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  crown_and_bridge?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  dentures?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  dentures_wax_rim?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  emax?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  implant?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  inlay?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  ip_contacts?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  item_variants?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  model?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  new_practice?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  noble_white?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  other?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  partials?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  partials_injection_mold?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  pfm?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  pfz?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  posterior_crown_and_bridge?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  red_carpet?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  refabrication?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  removables?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  split_order?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  thermoform?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
  waxup?: InputMaybe<LabsGqlOrderDesignCapabilityLevel>;
};

export type LabsGqlDesignStaffDto = {
  __typename?: 'DesignStaffDTO';
  assignable_task_types: Array<LabsGqlDesignStaffTaskType>;
  capabilities_updated_at: Scalars['DateTime']['output'];
  country?: Maybe<LabsGqlDesignStaffEligibleCountry>;
  created_at: Scalars['DateTime']['output'];
  design_staff_qc_percentage_required: Scalars['Float']['output'];
  design_staff_region?: Maybe<LabsGqlDesignStaffRegion>;
  design_staff_role?: Maybe<LabsGqlDesignStaffRole>;
  design_staff_team?: Maybe<LabsGqlDesignStaffTeamDto>;
  employment_type?: Maybe<LabsGqlDesignStaffEmploymentType>;
  /** Maps 1:1 with a Retainer User ID */
  id: Scalars['ID']['output'];
  ordered_main_capabilities?: Maybe<Array<LabsGqlOrderDesignCapabilityType>>;
  schedule: LabsGqlShiftSchedule;
  status: LabsGqlDesignStaffStatus;
  status_expires_at?: Maybe<Scalars['DateTime']['output']>;
  status_updated_at: Scalars['DateTime']['output'];
  task_capabilities: LabsGqlDesignStaffAllTaskCapabilitiesDto;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlDesignStaffDetailedStatus = {
  __typename?: 'DesignStaffDetailedStatus';
  assignable_task_types: Array<LabsGqlDesignStaffTaskType>;
  auto_assignments: Array<LabsGqlDesignStaffDetailedStatusAssignment>;
  candidate_orders: Array<LabsGqlAssignmentCandidateOrder>;
  design_staff_id: Scalars['String']['output'];
  focus_areas: Array<LabsGqlDesignStaffFocusAreaDto>;
  manual_assignments: Array<LabsGqlDesignStaffDetailedStatusAssignment>;
  status?: Maybe<LabsGqlDesignStaffStatus>;
  task_capabilities?: Maybe<LabsGqlDesignStaffAllTaskCapabilitiesDto>;
};

export type LabsGqlDesignStaffDetailedStatusAssignment = {
  __typename?: 'DesignStaffDetailedStatusAssignment';
  assigned_at: Scalars['DateTime']['output'];
  auto_assignment_expiration?: Maybe<Scalars['DateTime']['output']>;
  order_id: Scalars['String']['output'];
  priority: Scalars['Float']['output'];
  product_line?: Maybe<LabsGqlProductLine>;
  task_id: Scalars['String']['output'];
  task_type: LabsGqlDesignStaffTaskType;
};

export enum LabsGqlDesignStaffEligibleCountry {
  Argentina = 'ARGENTINA',
  Austria = 'AUSTRIA',
  Bolivia = 'BOLIVIA',
  Canada = 'CANADA',
  Colombia = 'COLOMBIA',
  CostaRica = 'COSTA_RICA',
  Egypt = 'EGYPT',
  Gds = 'GDS',
  Jordan = 'JORDAN',
  Malaysia = 'MALAYSIA',
  Mexico = 'MEXICO',
  Pakistan = 'PAKISTAN',
  Peru = 'PERU',
  Portugal = 'PORTUGAL',
  SouthAfrica = 'SOUTH_AFRICA',
  UnitedStates = 'UNITED_STATES'
}

export enum LabsGqlDesignStaffEmploymentType {
  EorEmployee = 'EOR_EMPLOYEE',
  FixedRate = 'FIXED_RATE',
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  PayAsYouGo = 'PAY_AS_YOU_GO'
}

export type LabsGqlDesignStaffFocusAreaDto = {
  __typename?: 'DesignStaffFocusAreaDTO';
  capability: LabsGqlOrderDesignCapabilityType;
  created_at: Scalars['DateTime']['output'];
  design_staff_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: LabsGqlOrderDesignCapabilityLevel;
  task_type: LabsGqlDesignTaskType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlDesignStaffFocusAreaInput = {
  capability: LabsGqlOrderDesignCapabilityType;
  design_staff_id: Scalars['String']['input'];
  level: LabsGqlOrderDesignCapabilityLevel;
  task_type: LabsGqlDesignTaskType;
};

export enum LabsGqlDesignStaffMatchingLogic {
  Default = 'DEFAULT',
  Never = 'NEVER',
  ReassignPreviousDesignStaff = 'REASSIGN_PREVIOUS_DESIGN_STAFF',
  SkipRefabRequirement = 'SKIP_REFAB_REQUIREMENT'
}

export enum LabsGqlDesignStaffRegion {
  Apac = 'APAC',
  Can = 'CAN',
  Eu = 'EU',
  Gds = 'GDS',
  Latam = 'LATAM',
  Mea = 'MEA',
  Sa = 'SA',
  Us = 'US'
}

export enum LabsGqlDesignStaffRole {
  Andon = 'ANDON',
  AutomateDesigner = 'AUTOMATE_DESIGNER',
  Designer = 'DESIGNER',
  Lead = 'LEAD',
  Model = 'MODEL',
  Mold = 'MOLD',
  Pfz = 'PFZ',
  Prep = 'PREP',
  PrincipleDesigner = 'PRINCIPLE_DESIGNER',
  Qc = 'QC',
  Quality = 'QUALITY',
  RedCarpet = 'RED_CARPET',
  Refab = 'REFAB',
  ScanPrep = 'SCAN_PREP',
  ScanReview = 'SCAN_REVIEW',
  Trainer = 'TRAINER',
  TreamentPlanner = 'TREAMENT_PLANNER',
  Ts = 'TS',
  Waxrim = 'WAXRIM'
}

export enum LabsGqlDesignStaffStatus {
  Active = 'Active',
  Away = 'Away',
  Draining = 'Draining',
  Inactive = 'Inactive',
  Offboarded = 'Offboarded',
  Offline = 'Offline',
  Paused = 'Paused'
}

export type LabsGqlDesignStaffStatusEntry = {
  __typename?: 'DesignStaffStatusEntry';
  id: Scalars['String']['output'];
  status: LabsGqlDesignStaffStatus;
};

export enum LabsGqlDesignStaffTaskType {
  AutomateReview = 'AutomateReview',
  DesignPrep = 'DesignPrep',
  DesignReview = 'DesignReview',
  ExternalDesign = 'ExternalDesign',
  InjectionMoldDesign = 'InjectionMoldDesign',
  InternalDesign = 'InternalDesign',
  ModelDesign = 'ModelDesign'
}

export type LabsGqlDesignStaffTeamDto = {
  __typename?: 'DesignStaffTeamDTO';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlDesignStaffWithUser = {
  __typename?: 'DesignStaffWithUser';
  designStaff: LabsGqlDesignStaffDto;
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
};

export enum LabsGqlDesignTaskType {
  AutomateReview = 'AutomateReview',
  DesignPrep = 'DesignPrep',
  DesignReview = 'DesignReview',
  ExternalDesign = 'ExternalDesign',
  InjectionMoldDesign = 'InjectionMoldDesign',
  InternalDesign = 'InternalDesign',
  ModelDesign = 'ModelDesign'
}

/** Return type for evaluation course lookup query */
export type LabsGqlDesignTrainingCourseForEvaluation = {
  __typename?: 'DesignTrainingCourseForEvaluation';
  /** The number of complete training orders in the course */
  completeCount: Scalars['Float']['output'];
  /** The ID of the training course */
  courseId: Scalars['String']['output'];
  courseName: Scalars['String']['output'];
  /** The name of the designer to whom the course instance belongs */
  designerName: Scalars['String']['output'];
  /** The most recent 'completed_at' date among the course's training orders */
  lastCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The total number of training orders in the course */
  totalCount: Scalars['Float']['output'];
};

/** A design training order */
export type LabsGqlDesignTrainingOrderDto = {
  __typename?: 'DesignTrainingOrderDTO';
  /** For Review and Finish training orders, whether the Automate design was approved */
  automateDesignApproved?: Maybe<Scalars['Boolean']['output']>;
  /** When the training order was completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The design duration in milliseconds for the latest training design revision, if any */
  designDurationMs?: Maybe<Scalars['Float']['output']>;
  /** The ID of the DesignOrder used to hold design artifacts for this training order */
  designOrderId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the design revision to use as a data source for this training order */
  designRevisionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** The ID of the latest training design revision, if any */
  latestDesignRevisionId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the lab order to use as a data source for this training order */
  orderId: Scalars['ID']['output'];
  /** The sequential number of this order in its course, starting from 1 */
  orderNumber: Scalars['Int']['output'];
  /** When the training order was skipped */
  skippedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Design training order type */
  type: LabsGqlDesignTrainingOrderType;
};

export enum LabsGqlDesignTrainingOrderType {
  AutomateReviewAndFinish = 'AutomateReviewAndFinish',
  DesignEditor = 'DesignEditor',
  Prep = 'Prep'
}

export type LabsGqlDesignViewPreset = {
  __typename?: 'DesignViewPreset';
  annotated_image_urls: Array<Scalars['String']['output']>;
  notes: Scalars['String']['output'];
  preset_status: LabsGqlGuidedWaxupPresetStatus;
  preset_type: LabsGqlGuidedWaxupPresetType;
  structured_notes?: Maybe<Array<LabsGqlDesignOrderDtoNote>>;
};

export type LabsGqlDesignerCapability = {
  __typename?: 'DesignerCapability';
  level?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  type: LabsGqlOrderDesignCapabilityType;
};

export type LabsGqlDesignerFilters = {
  order_id?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  per_page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LabsGqlDesignStaffStatus>;
};

export type LabsGqlDisableBiasRuleCommand = {
  rule_id: Scalars['ID']['input'];
};

export type LabsGqlDisableCapacityRuleCommand = {
  rule_id: Scalars['ID']['input'];
};

export type LabsGqlDisableCapacityStationRuleArgs = {
  id: Scalars['String']['input'];
};

export type LabsGqlDisableDesignRuleCommand = {
  rule_id: Scalars['ID']['input'];
};

export type LabsGqlDisableRoutingTargetDistributionRuleCommand = {
  rule_id: Scalars['ID']['input'];
};

export type LabsGqlDisplayRuleOptions = {
  __typename?: 'DisplayRuleOptions';
  material_types: Array<Scalars['String']['output']>;
  unit_types: Array<Scalars['String']['output']>;
};

export enum LabsGqlDisplayRuleOrderProperty {
  LinkRelationship = 'link_relationship',
  MaterialType = 'material_type',
  Teeth = 'teeth',
  UnitType = 'unit_type'
}

export enum LabsGqlDisplayRuleTypeEnum {
  DisplayRules = 'display_rules',
  ReturnRules = 'return_rules'
}

/** Where this doctor alert should be shown */
export enum LabsGqlDoctorAlertLocation {
  DesignTask = 'DesignTask',
  LabSlip = 'LabSlip'
}

export type LabsGqlDoctorAndLastName = {
  __typename?: 'DoctorAndLastName';
  doctor: LabsGqlDoctorPreferences;
  doctor_last_name: Scalars['String']['output'];
};

export type LabsGqlDoctorLabSlipAlert = {
  __typename?: 'DoctorLabSlipAlert';
  /** If not set, will be added to all of this doctors orders */
  filter?: Maybe<LabsGqlFilterSubmission>;
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: LabsGqlLabSlipAlertType;
  visibility?: Maybe<Array<LabsGqlDoctorAlertLocation>>;
};

export type LabsGqlDoctorLabSlipAlertInput = {
  /** If not set, will be added to all of this doctors orders */
  filter?: InputMaybe<LabsGqlFilterSubmissionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  type: LabsGqlLabSlipAlertType;
  visibility?: InputMaybe<Array<LabsGqlDoctorAlertLocation>>;
};

export enum LabsGqlDoctorNotificationCadence {
  Daily = 'daily',
  Immediately = 'immediately',
  Never = 'never'
}

export enum LabsGqlDoctorNotificationChannel {
  Email = 'email',
  Sms = 'sms'
}

export type LabsGqlDoctorNotificationPreferencesDto = {
  __typename?: 'DoctorNotificationPreferencesDTO';
  attach_photos_email_updates?: Maybe<Scalars['Boolean']['output']>;
  attach_photos_sms_updates?: Maybe<Scalars['Boolean']['output']>;
  daily_digest_timing?: Maybe<LabsGqlPartnerLabsNotificationTiming>;
  /** Send SMS when a waxup is ready for review */
  design_review_alerts?: Maybe<Scalars['Boolean']['output']>;
  estimated_delivery_alerts?: Maybe<Scalars['Boolean']['output']>;
  estimated_delivery_emails?: Maybe<Scalars['Boolean']['output']>;
  new_order_note_email_updates?: Maybe<Scalars['Boolean']['output']>;
  new_order_note_sms_updates?: Maybe<Scalars['Boolean']['output']>;
  order_cancelled_sms_updates?: Maybe<Scalars['Boolean']['output']>;
  order_hold_email_updates?: Maybe<Scalars['Boolean']['output']>;
  order_hold_sms_updates?: Maybe<Scalars['Boolean']['output']>;
  rush_request_sms_updates?: Maybe<Scalars['Boolean']['output']>;
  scan_rejection_email_updates?: Maybe<Scalars['Boolean']['output']>;
  scan_rejection_sms_updates?: Maybe<Scalars['Boolean']['output']>;
  scanbody_request_shipped_email_updates?: Maybe<Scalars['Boolean']['output']>;
  unsubmitted_scan_alert_delay_mins?: Maybe<Scalars['Int']['output']>;
  unsubmitted_scans_daily_sms?: Maybe<LabsGqlPartnerLabsNotificationTiming>;
  waxup_daily_reminder_sms_timing?: Maybe<LabsGqlPartnerLabsNotificationTiming>;
  waxup_ready_for_review_email?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlDoctorPreferences = {
  __typename?: 'DoctorPreferences';
  best_call_hour?: Maybe<Scalars['Float']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_phone?: Maybe<Scalars['String']['output']>;
  contact_phone_call_number?: Maybe<Scalars['String']['output']>;
  contact_tightness: LabsGqlContactTightnessEnum;
  created_at: Scalars['DateTime']['output'];
  custom_field_preferences?: Maybe<Array<LabsGqlICustomFieldSubmission>>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  formatted_name: Scalars['String']['output'];
  getting_started_steps: Array<LabsGqlGettingStartedStepDto>;
  grouped_notification_forwarding_preferences: LabsGqlNotificationGroupForwardingDto;
  grouped_notification_preferences: LabsGqlNotificationGroupDto;
  id: Scalars['String']['output'];
  invite_sent?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Removing kustomer */
  kustomer_chat_token?: Maybe<Scalars['String']['output']>;
  kustomer_customer_id?: Maybe<Scalars['String']['output']>;
  lab_slip_alerts: Array<LabsGqlDoctorLabSlipAlert>;
  mailing_address_id?: Maybe<Scalars['String']['output']>;
  mailing_address_nickname?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated no longer sending daily digests and only sending realtime notifs, so no longer need to check this/it is no longer editable */
  notification_cadence?: Maybe<LabsGqlDoctorNotificationCadence>;
  /** @deprecated notification_cadence is deprecated and therefore no need to check this field */
  notification_cadence_other_detail?: Maybe<Scalars['String']['output']>;
  /** @deprecated no longer sending daily digests, which checked notification_channel for where to send the digest to */
  notification_channel?: Maybe<LabsGqlDoctorNotificationChannel>;
  /** @deprecated Use grouped_notification_preferences to manage notifications */
  notification_preferences: LabsGqlDoctorNotificationPreferencesDto;
  partner_id: Scalars['String']['output'];
  practice_name?: Maybe<Scalars['String']['output']>;
  preferred_manufacturers: Array<LabsGqlPreferredManufacturer>;
  profile_photo: LabsGqlDoctorProfilePhotoDto;
  /** @deprecated Added roles to use instead of role so that we could match the staff member model and also have multiples roles on a pref */
  role?: Maybe<LabsGqlStaffRolePractice>;
  roles?: Maybe<Array<LabsGqlStaffRolePractice>>;
  staff_member?: Maybe<LabsGqlStaffMemberWithRelations>;
  staff_member_id?: Maybe<Scalars['String']['output']>;
  test_order_default?: Maybe<Scalars['Boolean']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  who_receives_dr_notifications?: Maybe<LabsGqlWhoReceivesDoctorNotifications>;
};

export enum LabsGqlDoctorProfilePhotoColor {
  Black = 'black',
  DarkBlue = 'dark_blue',
  DarkGreen = 'dark_green',
  LightBlue = 'light_blue',
  LightGreen = 'light_green',
  Orange = 'orange',
  Purple = 'purple',
  Red = 'red',
  Yellow = 'yellow'
}

export type LabsGqlDoctorProfilePhotoDto = {
  __typename?: 'DoctorProfilePhotoDTO';
  color: LabsGqlDoctorProfilePhotoColor;
  source?: Maybe<Scalars['String']['output']>;
  type: LabsGqlDoctorProfilePhotoType;
};

export type LabsGqlDoctorProfilePhotoDtoInput = {
  color: LabsGqlDoctorProfilePhotoColor;
  source?: InputMaybe<Scalars['String']['input']>;
  type: LabsGqlDoctorProfilePhotoType;
};

export enum LabsGqlDoctorProfilePhotoType {
  Avatar = 'avatar',
  None = 'none',
  Uploaded = 'uploaded'
}

export type LabsGqlDoctorRequestDto = {
  __typename?: 'DoctorRequestDTO';
  created_at: Scalars['DateTime']['output'];
  /** Includes the selected option id selected by the doctor, as well as the text notes they might have added with their selection. */
  doctor_request_response?: Maybe<LabsGqlDoctorRequestResponseDto>;
  id: Scalars['ID']['output'];
  /** Indicating the flow used by the internal to create this doctor request; can be Scan Rejection or Hold. These flows do not dispatch the same events when they go through the hold process, hence why we need to track it. */
  initiating_flow: LabsGqlDoctorRequestInitiatingFlow;
  /** List of options to select in the UI for the doctor. */
  options: Array<LabsGqlDoctorRequestOptionDto>;
  order_id: Scalars['ID']['output'];
  photo_attachment_urls: Array<Scalars['String']['output']>;
  /** Question asked to the doctor from internal users */
  question_text: Scalars['String']['output'];
  /** Status of the doctor request, can be set to PendingAnswer or Answered. */
  status: LabsGqlDoctorRequestStatus;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlDoctorRequestInitiatingFlow {
  Hold = 'Hold',
  ScanRejection = 'ScanRejection'
}

export type LabsGqlDoctorRequestOptionConfig = LabsGqlModifyOrderConfig;

export type LabsGqlDoctorRequestOptionDto = {
  __typename?: 'DoctorRequestOptionDTO';
  /** The config is a field which contains the configuration specific to an option type. For instance when an option is of the "ModifyOrder" type, the config will include the list of items and all their settings. */
  config?: Maybe<LabsGqlDoctorRequestOptionConfig>;
  created_at: Scalars['DateTime']['output'];
  doctor_request_id: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** User-friendly text displayed as an option to be selected by the user */
  label: Scalars['String']['output'];
  /** From 1 to N. Position where the option is displayed in the UI. 1 means option is first displayed in the list. */
  position: Scalars['Int']['output'];
  /** The type dictates what happens when the option is selected by the practice. Some types like "ModifyOrder" will automatically update the order and resolve a hold for instance. */
  type: LabsGqlDoctorRequestOptionType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlDoctorRequestOptionInput = {
  label: Scalars['String']['input'];
  modify_order_config?: InputMaybe<LabsGqlModifyOrderConfigInput>;
  type: LabsGqlDoctorRequestOptionType;
};

export enum LabsGqlDoctorRequestOptionModifyOrderMode {
  CancelAndResubmit = 'CancelAndResubmit',
  Update = 'Update'
}

export enum LabsGqlDoctorRequestOptionType {
  AddReductionCoping = 'AddReductionCoping',
  ManualResolution = 'ManualResolution',
  ModifyOrder = 'ModifyOrder',
  NeedsAdditionalScans = 'NeedsAdditionalScans',
  Other = 'Other',
  ProceedAsIs = 'ProceedAsIs'
}

export type LabsGqlDoctorRequestResponseDto = {
  __typename?: 'DoctorRequestResponseDTO';
  created_at: Scalars['DateTime']['output'];
  doctor_request_id: Scalars['ID']['output'];
  /** Text that may be entered by a doctor when selecting a response in the ask doctor request. Only available for the "Other" Doctor Request Option type */
  freeform_notes: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** ID of the Doctor Request Option selected by the doctor when they answer the doctor request. */
  selected_doctor_request_option_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlDoctorRequestStatus {
  Answered = 'Answered',
  PendingAnswer = 'PendingAnswer'
}

export type LabsGqlDownloadedFileInfo = {
  filePath: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type LabsGqlDrReviewHoldWorkflowTask = LabsGqlIWorkflowTask & {
  __typename?: 'DrReviewHoldWorkflowTask';
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlWorkflowTaskDrReviewHoldConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export type LabsGqlDuplicateScanEventData = {
  __typename?: 'DuplicateScanEventData';
  original_create_date: Scalars['DateTime']['output'];
  scanner_id: Scalars['String']['output'];
};

export type LabsGqlEditAccountManagementContactsCommand = {
  account_management_contact_emails: Array<Scalars['String']['input']>;
  partner_id: Scalars['String']['input'];
};

export type LabsGqlEditAlignerCaseCommand = {
  aligner_arch: LabsGqlAlignerArch;
  aligner_notes?: InputMaybe<Scalars['String']['input']>;
  aligner_treatment_area: LabsGqlAlignerTreatmentArea;
  attachment_restricted_teeth: Array<Scalars['ToothNumber']['input']>;
  bite_concerns?: InputMaybe<LabsGqlPendingAlignerCaseBiteConcernsInput>;
  crowding_resolution?: InputMaybe<LabsGqlPendingAlignerCaseCrowdingResolutionInput>;
  extraction_teeth: Array<Scalars['ToothNumber']['input']>;
  interproximal_space_sizes?: InputMaybe<Array<LabsGqlCreatePendingAlignerCaseInterproximalSpaceSizeInput>>;
  movement_restricted_teeth: Array<Scalars['ToothNumber']['input']>;
  order_id: Scalars['String']['input'];
  patient_price_override?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlEditBillingContactsCommand = {
  billing_contact_emails: Array<Scalars['String']['input']>;
  partner_id: Scalars['String']['input'];
};

export type LabsGqlEditDoctorNoteCommand = {
  category_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  new_note?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
};

export type LabsGqlEditQcItemConfigCommand = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  description: Scalars['String']['input'];
  filters: Array<LabsGqlFilterSubmissionInput>;
  id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type LabsGqlEditRefabricationCommand = {
  order_id: Scalars['String']['input'];
  refabrication_notes: Scalars['String']['input'];
};

export type LabsGqlEditRefabricationReasonsCommand = {
  order_id: Scalars['String']['input'];
  reasons: Array<LabsGqlOrderRefabReasonInput>;
};

export type LabsGqlEditSavedInboxSearchCommand = {
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<LabsGqlInboxCustomSearchInput>;
  search_id: Scalars['String']['input'];
  visibility?: InputMaybe<LabsGqlSavedSearchVisibility>;
};

export type LabsGqlEditSavedOrderSearchCommand = {
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<LabsGqlOrderCustomSearchInput>;
  search_id: Scalars['String']['input'];
  visibility?: InputMaybe<LabsGqlSavedSearchVisibility>;
};

export type LabsGqlEditSavedRoutingSearchCommand = {
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<LabsGqlRoutingCustomSearchInput>;
  search_id: Scalars['String']['input'];
  visibility?: InputMaybe<LabsGqlSavedSearchVisibility>;
};

export type LabsGqlEditTagCommand = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LabsGqlEditTicketActionNoteCommand = {
  action_id: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
  ticket_id: Scalars['ID']['input'];
};

export type LabsGqlEditTicketSavedSearchCommand = {
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<LabsGqlTicketFiltersInput>;
  search_id: Scalars['String']['input'];
  visibility?: InputMaybe<LabsGqlSavedSearchVisibility>;
};

export type LabsGqlElementPriceHierarchyInput = {
  item_id: Scalars['String']['input'];
  preferences: Array<LabsGqlPriceFieldInput>;
  teeth: Array<LabsGqlPriceFieldInput>;
};

export type LabsGqlEnableBiasRuleCommand = {
  rule_id: Scalars['ID']['input'];
};

export type LabsGqlEnableCapacityRuleCommand = {
  rule_id: Scalars['ID']['input'];
};

export type LabsGqlEnableCcFeeForAllWorkflowResult = {
  __typename?: 'EnableCcFeeForAllWorkflowResult';
  workflowId: Scalars['String']['output'];
};

export type LabsGqlEnableDesignRuleCommand = {
  rule_id: Scalars['ID']['input'];
};

export type LabsGqlEnableRoutingTargetDistributionRuleCommand = {
  rule_id: Scalars['ID']['input'];
};

/** A course that the designer is enrolled in. */
export type LabsGqlEnrolledCourse = {
  __typename?: 'EnrolledCourse';
  courseWork: Array<LabsGqlDesignTrainingOrderDto>;
  /** A UUID that identifies this course instance */
  id: Scalars['String']['output'];
  /** A slug that identifies this course */
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** The ID of user to whom this course instance belongs. */
  userId: Scalars['String']['output'];
  /** The name of the user to whom this course instance belongs. */
  userName: Scalars['String']['output'];
};

export type LabsGqlEnsureStackCodesForLabOrdersResult = {
  __typename?: 'EnsureStackCodesForLabOrdersResult';
  labOrderIdsInFinalStatus: Array<Scalars['String']['output']>;
  labOrderIdsNotAssignedToLab: Array<Scalars['String']['output']>;
  labOrderIdsWithErrors: Array<Scalars['String']['output']>;
  labOrderIdsWithExistingCodes: Array<Scalars['String']['output']>;
  successfulLabOrderIds: Array<Scalars['String']['output']>;
};

export type LabsGqlEquipmentTrackerSnapshot = {
  __typename?: 'EquipmentTrackerSnapshot';
  account_id: Scalars['String']['output'];
  account_owner_email?: Maybe<Scalars['String']['output']>;
  account_owner_name?: Maybe<Scalars['String']['output']>;
  /** Denotes whether practice is bringing their own device. */
  byod_c?: Maybe<Scalars['Boolean']['output']>;
  /** Cart package carrier. */
  carrier_code?: Maybe<Scalars['String']['output']>;
  contact_id_coalesce?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** Maps to sf opportunity. */
  opportunity_id?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['String']['output']>;
  /** Override address for scanner. */
  scanner_shipment_address_override_c?: Maybe<Scalars['String']['output']>;
  /** Cart package status. */
  shipment_status?: Maybe<Scalars['String']['output']>;
  shipping_city?: Maybe<Scalars['String']['output']>;
  shipping_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_state?: Maybe<Scalars['String']['output']>;
  shipping_street?: Maybe<Scalars['String']['output']>;
  /** Computer/Scanner package status. */
  stage_c?: Maybe<Scalars['String']['output']>;
  /** Cart package status. */
  stage_d?: Maybe<Scalars['String']['output']>;
  /** Practice scheduled training date. */
  synthetic_training_date_c?: Maybe<Scalars['DateTime']['output']>;
  /** Computer/Scanner tracking number. */
  tracking_number_c?: Maybe<Scalars['String']['output']>;
  /** Cart tracking number. */
  tracking_number_d?: Maybe<Scalars['String']['output']>;
};

/** The estimated delivery date response. */
export type LabsGqlEstimatedDeliveryDateRes = {
  __typename?: 'EstimatedDeliveryDateRes';
  /**
   * The estimated design preview date.
   *
   * Only relevant if the order requires a waxup.
   */
  design_preview_eta?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Always null. For legacy feature that is no longer implemented. */
  expected_delivery_days?: Maybe<Scalars['Float']['output']>;
  /** The estimated delivery date for the standard delivery option. */
  standard_delivery_date: Scalars['DateTime']['output'];
};

export enum LabsGqlEventNames {
  AbutmentPartArchived = 'AbutmentPartArchived',
  AbutmentPartCreated = 'AbutmentPartCreated',
  AbutmentPartToImplantScanbodyAdded = 'AbutmentPartToImplantScanbodyAdded',
  AbutmentPartToImplantScanbodyRemoved = 'AbutmentPartToImplantScanbodyRemoved',
  AbutmentPartToImplantScanbodyUpdated = 'AbutmentPartToImplantScanbodyUpdated',
  AbutmentPartUnarchived = 'AbutmentPartUnarchived',
  AbutmentPartUpdated = 'AbutmentPartUpdated',
  AccountManagementContactsEdited = 'AccountManagementContactsEdited',
  ActionCapabilityCreated = 'ActionCapabilityCreated',
  ActionCapabilityUpdated = 'ActionCapabilityUpdated',
  ActionCategoryCreated = 'ActionCategoryCreated',
  ActionCategoryUpdated = 'ActionCategoryUpdated',
  ActionNoteEdited = 'ActionNoteEdited',
  ActionTypeCreated = 'ActionTypeCreated',
  ActionTypeUpdated = 'ActionTypeUpdated',
  ActivationFeeRegistrationUpdated = 'ActivationFeeRegistrationUpdated',
  AgreeToCreditCardFees = 'AgreeToCreditCardFees',
  AlignerCaseDesignSoftwareChanged = 'AlignerCaseDesignSoftwareChanged',
  AlignerCaseEdited = 'AlignerCaseEdited',
  AlignerFabricationApproved = 'AlignerFabricationApproved',
  AlignerPhotoZipConverted = 'AlignerPhotoZipConverted',
  AlignerPreppedScansAttached = 'AlignerPreppedScansAttached',
  AlignerStlZipConverted = 'AlignerStlZipConverted',
  AskedDrAboutOrderHold = 'AskedDrAboutOrderHold',
  AttachmentMessageReceived = 'AttachmentMessageReceived',
  AttachmentMessageSent = 'AttachmentMessageSent',
  AttachmentSessionCreated = 'AttachmentSessionCreated',
  AttachmentSessionUpdated = 'AttachmentSessionUpdated',
  AutoAssignedTaskTimedOut = 'AutoAssignedTaskTimedOut',
  BiasRuleCreated = 'BiasRuleCreated',
  BiasRuleDisabled = 'BiasRuleDisabled',
  BiasRuleEnabled = 'BiasRuleEnabled',
  BiasRuleUpdated = 'BiasRuleUpdated',
  BillingCreditAppliedToInvoice = 'BillingCreditAppliedToInvoice',
  BillingCreditCategoryCreated = 'BillingCreditCategoryCreated',
  BillingCreditCategoryUpdated = 'BillingCreditCategoryUpdated',
  BillingPrepaySplitConfigured = 'BillingPrepaySplitConfigured',
  BillingUsageSplitConfigured = 'BillingUsageSplitConfigured',
  CapacityRuleCreated = 'CapacityRuleCreated',
  CapacityRuleDisabled = 'CapacityRuleDisabled',
  CapacityRuleEnabled = 'CapacityRuleEnabled',
  CapacityRuleUpdated = 'CapacityRuleUpdated',
  CardDeclineEmailSent = 'CardDeclineEmailSent',
  CaseReadinessCreated = 'CaseReadinessCreated',
  CategoryArchivedToggled = 'CategoryArchivedToggled',
  CategoryCreated = 'CategoryCreated',
  CategoryUpdated = 'CategoryUpdated',
  CbctScanUrlSet = 'CbctScanUrlSet',
  ChairsideCaseCreated = 'ChairsideCaseCreated',
  ChairsideCaseMonitoringAssignmentUpdated = 'ChairsideCaseMonitoringAssignmentUpdated',
  ChairsideCaseNotEligibleForMonitoring = 'ChairsideCaseNotEligibleForMonitoring',
  ChairsideCasePatientSelected = 'ChairsideCasePatientSelected',
  ChairsideCaseProductTypesChanged = 'ChairsideCaseProductTypesChanged',
  ChairsideCaseReadyForMonitoring = 'ChairsideCaseReadyForMonitoring',
  ChairsideCaseSkusChanged = 'ChairsideCaseSkusChanged',
  ChatMessageCreated = 'ChatMessageCreated',
  ChatMessageDeleted = 'ChatMessageDeleted',
  ChatMessageEdited = 'ChatMessageEdited',
  ChatMessageViewed = 'ChatMessageViewed',
  ChatMessageVisibilityEdited = 'ChatMessageVisibilityEdited',
  CompletedScanningCaseCreated = 'CompletedScanningCaseCreated',
  CurrentDenturesFulfillmentUpdatedToCore = 'CurrentDenturesFulfillmentUpdatedToCore',
  CurrentDenturesFulfillmentUpdatedToWaxRim = 'CurrentDenturesFulfillmentUpdatedToWaxRim',
  CurrentRetainerUpdated = 'CurrentRetainerUpdated',
  CustomAttachmentCreated = 'CustomAttachmentCreated',
  CustomAttachmentDeleted = 'CustomAttachmentDeleted',
  CustomAttachmentUpdated = 'CustomAttachmentUpdated',
  CustomFieldArchiveToggled = 'CustomFieldArchiveToggled',
  CustomFieldCreated = 'CustomFieldCreated',
  CustomFieldDisplayRuleAdded = 'CustomFieldDisplayRuleAdded',
  CustomFieldDisplayRuleDeleted = 'CustomFieldDisplayRuleDeleted',
  CustomFieldPriceRuleAdded = 'CustomFieldPriceRuleAdded',
  CustomFieldPriceRuleDeleted = 'CustomFieldPriceRuleDeleted',
  CustomFieldPriceRuleUpdated = 'CustomFieldPriceRuleUpdated',
  CustomFieldSortOrderUpdated = 'CustomFieldSortOrderUpdated',
  CustomFieldSuggestionCreated = 'CustomFieldSuggestionCreated',
  CustomFieldSuggestionDeleted = 'CustomFieldSuggestionDeleted',
  CustomFieldSuggestionUpdated = 'CustomFieldSuggestionUpdated',
  CustomFieldUpdated = 'CustomFieldUpdated',
  CustomUnitCreated = 'CustomUnitCreated',
  CustomUnitDeleted = 'CustomUnitDeleted',
  CustomUnitRestored = 'CustomUnitRestored',
  CustomUnitUpdated = 'CustomUnitUpdated',
  DeliveryAddressChanged = 'DeliveryAddressChanged',
  DenturesFulfillmentAdded = 'DenturesFulfillmentAdded',
  DenturesFulfillmentCurrentCoreUpdated = 'DenturesFulfillmentCurrentCoreUpdated',
  DenturesFulfillmentCurrentWaxRimUpdated = 'DenturesFulfillmentCurrentWaxRimUpdated',
  DenturesFulfillmentFixupNewScanUpdated = 'DenturesFulfillmentFixupNewScanUpdated',
  DenturesFulfillmentFixupPlaced = 'DenturesFulfillmentFixupPlaced',
  DenturesFulfillmentTryInFollowupPlaced = 'DenturesFulfillmentTryInFollowupPlaced',
  DenturesFulfillmentTryInNewScanUpdated = 'DenturesFulfillmentTryInNewScanUpdated',
  DenturesFulfillmentTryInToggled = 'DenturesFulfillmentTryInToggled',
  DenturesFulfillmentWaxRimContinued = 'DenturesFulfillmentWaxRimContinued',
  DesignDownloadRecorded = 'DesignDownloadRecorded',
  DesignFileConversionCompleted = 'DesignFileConversionCompleted',
  DesignFilesAttached = 'DesignFilesAttached',
  DesignOrderActiveTaskUpdated = 'DesignOrderActiveTaskUpdated',
  DesignOrderAlignerTreatmentPlanBackfilled = 'DesignOrderAlignerTreatmentPlanBackfilled',
  DesignOrderAlignerTreatmentPlanDoctorReviewSubmitted = 'DesignOrderAlignerTreatmentPlanDoctorReviewSubmitted',
  DesignOrderAlignerTreatmentPlanManufacturingExportAttached = 'DesignOrderAlignerTreatmentPlanManufacturingExportAttached',
  DesignOrderAlignerTreatmentPlanManufacturingFilesConverted = 'DesignOrderAlignerTreatmentPlanManufacturingFilesConverted',
  DesignOrderAlignerTreatmentPlanSaved = 'DesignOrderAlignerTreatmentPlanSaved',
  DesignOrderAlignerTreatmentPlanSentToDoctor = 'DesignOrderAlignerTreatmentPlanSentToDoctor',
  DesignOrderCreated = 'DesignOrderCreated',
  DesignOrderDoctorReviewSubmitted = 'DesignOrderDoctorReviewSubmitted',
  DesignOrderRemovableManufacturingFilesAttached = 'DesignOrderRemovableManufacturingFilesAttached',
  DesignOrderRevisionAttachAsset = 'DesignOrderRevisionAttachAsset',
  DesignOrderRevisionAutomateJobsAdded = 'DesignOrderRevisionAutomateJobsAdded',
  DesignOrderRevisionAutomateTaskResultReceived = 'DesignOrderRevisionAutomateTaskResultReceived',
  DesignOrderRevisionConversionCompleted = 'DesignOrderRevisionConversionCompleted',
  DesignOrderRevisionCreated = 'DesignOrderRevisionCreated',
  DesignOrderRevisionDandyVerifiedDesignReviewed = 'DesignOrderRevisionDandyVerifiedDesignReviewed',
  DesignOrderRevisionInjectionMoldAdded = 'DesignOrderRevisionInjectionMoldAdded',
  DesignOrderRevisionPortalDesignEditsInjected = 'DesignOrderRevisionPortalDesignEditsInjected',
  DesignOrderRevisionPromoted = 'DesignOrderRevisionPromoted',
  DesignOrderRevisionPromotedToLatest = 'DesignOrderRevisionPromotedToLatest',
  DesignOrderRevisionQcReviewSubmitted = 'DesignOrderRevisionQcReviewSubmitted',
  DesignOrderRevisionWritingEnabled = 'DesignOrderRevisionWritingEnabled',
  DesignQaEvaluationCancelled = 'DesignQaEvaluationCancelled',
  DesignQaEvaluationCreated = 'DesignQaEvaluationCreated',
  DesignQaEvaluationSubmitted = 'DesignQaEvaluationSubmitted',
  DesignReduceOpposingSaved = 'DesignReduceOpposingSaved',
  DesignReview2Configured = 'DesignReview2Configured',
  DesignReviewFieldCreated = 'DesignReviewFieldCreated',
  DesignReviewFieldUpdated = 'DesignReviewFieldUpdated',
  DesignRuleCreated = 'DesignRuleCreated',
  DesignRuleDisabled = 'DesignRuleDisabled',
  DesignRuleEnabled = 'DesignRuleEnabled',
  DesignRuleUpdated = 'DesignRuleUpdated',
  DesignStaffCapabilitiesConfigured = 'DesignStaffCapabilitiesConfigured',
  DesignStaffCapabilitiesPatch = 'DesignStaffCapabilitiesPatch',
  DesignStaffCreated = 'DesignStaffCreated',
  DesignStaffFocusAreasConfigured = 'DesignStaffFocusAreasConfigured',
  DesignStaffRosterInfoPatch = 'DesignStaffRosterInfoPatch',
  DesignStaffStatusUpdated = 'DesignStaffStatusUpdated',
  DesignTaskMatchesUpdated = 'DesignTaskMatchesUpdated',
  DoctorCustomPreferencesSet = 'DoctorCustomPreferencesSet',
  DoctorLabSlipAlertsSaved = 'DoctorLabSlipAlertsSaved',
  DoctorNoteCategoryCreated = 'DoctorNoteCategoryCreated',
  DoctorNoteCategoryUpdated = 'DoctorNoteCategoryUpdated',
  DoctorNotificationPreferencesCollected = 'DoctorNotificationPreferencesCollected',
  DoctorPreferenceSet = 'DoctorPreferenceSet',
  DoctorPreferencesCreated = 'DoctorPreferencesCreated',
  DoctorPreferencesDeleted = 'DoctorPreferencesDeleted',
  DoctorPreferencesIdSet = 'DoctorPreferencesIDSet',
  DoctorProfilePhotoSet = 'DoctorProfilePhotoSet',
  DoctorRequestCreated = 'DoctorRequestCreated',
  DueDateChanged = 'DueDateChanged',
  DuplicateNotificationSkipped = 'DuplicateNotificationSkipped',
  DuplicateScanFileUploadAttempted = 'DuplicateScanFileUploadAttempted',
  ExternalAlignerFulfillmentCreated = 'ExternalAlignerFulfillmentCreated',
  FinishingDesignSkipped = 'FinishingDesignSkipped',
  FulfillmentCreated = 'FulfillmentCreated',
  FulfillmentDelivered = 'FulfillmentDelivered',
  FulfillmentShipped = 'FulfillmentShipped',
  FulfillmentShippedToDandy = 'FulfillmentShippedToDandy',
  FulfillmentTaskAssigned = 'FulfillmentTaskAssigned',
  FulfillmentTaskAutoExpirationUpdated = 'FulfillmentTaskAutoExpirationUpdated',
  FulfillmentTaskStarted = 'FulfillmentTaskStarted',
  GettingStartedStepMarkedCompleted = 'GettingStartedStepMarkedCompleted',
  ImplantTypeCreated = 'ImplantTypeCreated',
  ImplantTypeDeleted = 'ImplantTypeDeleted',
  ImplantTypeRestored = 'ImplantTypeRestored',
  ImplantTypeUpdated = 'ImplantTypeUpdated',
  InjectionMoldDesignTaskCompleted = 'InjectionMoldDesignTaskCompleted',
  IntegrationIdRemoved = 'IntegrationIdRemoved',
  IntegrationIdSet = 'IntegrationIdSet',
  IntegrationPanNumberUpdated = 'IntegrationPanNumberUpdated',
  IntegrationPriceUpdated = 'IntegrationPriceUpdated',
  InternalDesignTaskCompleted = 'InternalDesignTaskCompleted',
  InvoiceChargeRefunded = 'InvoiceChargeRefunded',
  InvoiceItemRefunded = 'InvoiceItemRefunded',
  InvoiceItemRegistrationUpdated = 'InvoiceItemRegistrationUpdated',
  InvoiceItemsAdded = 'InvoiceItemsAdded',
  InvoiceItemsRemoved = 'InvoiceItemsRemoved',
  InvoicePaymentFailed = 'InvoicePaymentFailed',
  InvoicePaymentPending = 'InvoicePaymentPending',
  InvoicePaymentSucceeded = 'InvoicePaymentSucceeded',
  InvoicePaymentUpdated = 'InvoicePaymentUpdated',
  InvoiceReceiptEmailSent = 'InvoiceReceiptEmailSent',
  InvoiceSummaryEmailSent = 'InvoiceSummaryEmailSent',
  InvoiceVoided = 'InvoiceVoided',
  KustomerChatMessageSynced = 'KustomerChatMessageSynced',
  LabCaseCompletionCreated = 'LabCaseCompletionCreated',
  LabCaseCompletionUpdated = 'LabCaseCompletionUpdated',
  LabCaseLedgerEntryCreated = 'LabCaseLedgerEntryCreated',
  LabCaseLedgerEntryReviewedToggled = 'LabCaseLedgerEntryReviewedToggled',
  LabCaseLedgerEntryUpdated = 'LabCaseLedgerEntryUpdated',
  LabCaseUnitPriceOverridden = 'LabCaseUnitPriceOverridden',
  LabLedgerEntryReviewedToggled = 'LabLedgerEntryReviewedToggled',
  LabMetalCostCreated = 'LabMetalCostCreated',
  LabMetalCostUpdated = 'LabMetalCostUpdated',
  LabOrderLockStatusChanged = 'LabOrderLockStatusChanged',
  LabOrderPhotosAdded = 'LabOrderPhotosAdded',
  LabOrderPhotosAddedWithNotes = 'LabOrderPhotosAddedWithNotes',
  LabOrderQcStationFailed = 'LabOrderQcStationFailed',
  LabOrderQcStationPassed = 'LabOrderQcStationPassed',
  LabPriceCreated = 'LabPriceCreated',
  LabPriceDefaultPracticePriceSet = 'LabPriceDefaultPracticePriceSet',
  LabPriceDeletionToggled = 'LabPriceDeletionToggled',
  LabPriceLabPricesConfigured = 'LabPriceLabPricesConfigured',
  LabPriceLedgerEntryCreated = 'LabPriceLedgerEntryCreated',
  LabPricePartnerPricesConfigured = 'LabPricePartnerPricesConfigured',
  LabPriceSalesforcePriceMappingSet = 'LabPriceSalesforcePriceMappingSet',
  LabPriceUpdated = 'LabPriceUpdated',
  LabSlipAlertsSaved = 'LabSlipAlertsSaved',
  LinkedToRefabrication = 'LinkedToRefabrication',
  ManufacturerAssignmentChanged = 'ManufacturerAssignmentChanged',
  ManufacturerDelayRequestAccepted = 'ManufacturerDelayRequestAccepted',
  ManufacturerDelayRequestCancelled = 'ManufacturerDelayRequestCancelled',
  ManufacturerDelayRequestEditedAndAccepted = 'ManufacturerDelayRequestEditedAndAccepted',
  ManufacturerDelayRequestRejected = 'ManufacturerDelayRequestRejected',
  ManufacturerDelayRequested = 'ManufacturerDelayRequested',
  ManufacturerDesignFileProcessed = 'ManufacturerDesignFileProcessed',
  ManufacturerProfileCreated = 'ManufacturerProfileCreated',
  ManufacturerProfileSet = 'ManufacturerProfileSet',
  MarkedAsRefabrication = 'MarkedAsRefabrication',
  MetalMaterialCreated = 'MetalMaterialCreated',
  MetalMaterialDeleted = 'MetalMaterialDeleted',
  ModelDesignTaskCompleted = 'ModelDesignTaskCompleted',
  MonthlyMinimumMechanismChanged = 'MonthlyMinimumMechanismChanged',
  MonthlyMinimumScheduleChanged = 'MonthlyMinimumScheduleChanged',
  NeedsRefabrication = 'NeedsRefabrication',
  NewScanAdded = 'NewScanAdded',
  NonDoctorPracticeStaffCreated = 'NonDoctorPracticeStaffCreated',
  NonDoctorPracticeStaffDeleted = 'NonDoctorPracticeStaffDeleted',
  NonDoctorPracticeStaffSet = 'NonDoctorPracticeStaffSet',
  NoteAdded = 'NoteAdded',
  NoteDeleted = 'NoteDeleted',
  NoteQualityControlToggled = 'NoteQualityControlToggled',
  NoteVisibilityChanged = 'NoteVisibilityChanged',
  NotificationGroupForwardingUpdated = 'NotificationGroupForwardingUpdated',
  NotificationGroupUpdated = 'NotificationGroupUpdated',
  NotificationLogCreated = 'NotificationLogCreated',
  NotificationPreferencesSet = 'NotificationPreferencesSet',
  NotificationSendFailed = 'NotificationSendFailed',
  NotificationSendSucceeded = 'NotificationSendSucceeded',
  OrderAddOnsUpdated = 'OrderAddOnsUpdated',
  OrderApprovalRequestCreated = 'OrderApprovalRequestCreated',
  OrderApprovalRequestDeleted = 'OrderApprovalRequestDeleted',
  OrderApprovalRequestSubmitted = 'OrderApprovalRequestSubmitted',
  OrderAutomateDesignTaskCancelled = 'OrderAutomateDesignTaskCancelled',
  OrderAutomateDesignTaskCompleted = 'OrderAutomateDesignTaskCompleted',
  OrderAutomationCreated = 'OrderAutomationCreated',
  OrderAutomationDeleted = 'OrderAutomationDeleted',
  OrderAutomationEdited = 'OrderAutomationEdited',
  OrderAutomationFolderSet = 'OrderAutomationFolderSet',
  OrderAutomationRestored = 'OrderAutomationRestored',
  OrderCancelled = 'OrderCancelled',
  OrderClinicalSupportNotesUpdated = 'OrderClinicalSupportNotesUpdated',
  OrderCreated = 'OrderCreated',
  OrderDesignAutomateIdUpdated = 'OrderDesignAutomateIdUpdated',
  OrderDesignAutomateReviewTaskCompleted = 'OrderDesignAutomateReviewTaskCompleted',
  OrderDesignPrepTaskCancelled = 'OrderDesignPrepTaskCancelled',
  OrderDesignPrepTaskCompleted = 'OrderDesignPrepTaskCompleted',
  OrderDesignRequirementsReconciled = 'OrderDesignRequirementsReconciled',
  OrderDesignReviewConfigurationSet = 'OrderDesignReviewConfigurationSet',
  OrderDesignReviewConfigurationSetV2 = 'OrderDesignReviewConfigurationSetV2',
  OrderDesignReviewTaskCompleted = 'OrderDesignReviewTaskCompleted',
  OrderDesignerTypeConfigured = 'OrderDesignerTypeConfigured',
  OrderDoctorNoteEdited = 'OrderDoctorNoteEdited',
  OrderExternalDesignVerificationCompleted = 'OrderExternalDesignVerificationCompleted',
  OrderFlaggedForReview = 'OrderFlaggedForReview',
  OrderHoldCreated = 'OrderHoldCreated',
  OrderHoldRemoved = 'OrderHoldRemoved',
  OrderHoldUpdated = 'OrderHoldUpdated',
  OrderIssueCreated = 'OrderIssueCreated',
  OrderIssueResolved = 'OrderIssueResolved',
  OrderIssueUpdated = 'OrderIssueUpdated',
  OrderItemAdded = 'OrderItemAdded',
  OrderItemDeleted = 'OrderItemDeleted',
  OrderItemPreferenceFieldsUpdated = 'OrderItemPreferenceFieldsUpdated',
  OrderItemUpdated = 'OrderItemUpdated',
  OrderItemsAddedV2 = 'OrderItemsAddedV2',
  OrderItemsUpdated = 'OrderItemsUpdated',
  OrderItemsUpdatedV2 = 'OrderItemsUpdatedV2',
  OrderManufacturerFabricationStateSet = 'OrderManufacturerFabricationStateSet',
  OrderMerged = 'OrderMerged',
  OrderPatientChanged = 'OrderPatientChanged',
  OrderPriceCalculated = 'OrderPriceCalculated',
  OrderPriceEntryCreated = 'OrderPriceEntryCreated',
  OrderPriceEntryDeleted = 'OrderPriceEntryDeleted',
  OrderPriceEntryUpdated = 'OrderPriceEntryUpdated',
  OrderRefabricationEdited = 'OrderRefabricationEdited',
  OrderRefabricationReasonsEdited = 'OrderRefabricationReasonsEdited',
  OrderRefabricationUnlinked = 'OrderRefabricationUnlinked',
  OrderRefinementRequested = 'OrderRefinementRequested',
  OrderReturnCancelled = 'OrderReturnCancelled',
  OrderReturnCreated = 'OrderReturnCreated',
  OrderReturnLabelScanned = 'OrderReturnLabelScanned',
  OrderReturnReceived = 'OrderReturnReceived',
  OrderReviewFlagResolved = 'OrderReviewFlagResolved',
  OrderSlaEdited = 'OrderSLAEdited',
  OrderSlaStartDateChanged = 'OrderSLAStartDateChanged',
  OrderScanApproved = 'OrderScanApproved',
  OrderScanRejected = 'OrderScanRejected',
  OrderScanRejectionResolved = 'OrderScanRejectionResolved',
  OrderScanReviewRequested = 'OrderScanReviewRequested',
  OrderSentToFloor = 'OrderSentToFloor',
  OrderSlaDelayed = 'OrderSlaDelayed',
  OrderSlaLeadTimesUpdated = 'OrderSlaLeadTimesUpdated',
  OrderSlaModified = 'OrderSlaModified',
  OrderSplitCountUpdated = 'OrderSplitCountUpdated',
  OrderWaxupConfigured = 'OrderWaxupConfigured',
  OrderWaxupReviewed = 'OrderWaxupReviewed',
  OrderingPausedPracticeCreated = 'OrderingPausedPracticeCreated',
  OrderingPausedPracticeUpdated = 'OrderingPausedPracticeUpdated',
  OrganizationPriceConfigCreated = 'OrganizationPriceConfigCreated',
  OrganizationPriceConfigDeleted = 'OrganizationPriceConfigDeleted',
  OrganizationPriceConfigUpdated = 'OrganizationPriceConfigUpdated',
  OverrideDentistAmountDue = 'OverrideDentistAmountDue',
  PackingSheetViewed = 'PackingSheetViewed',
  PartialsFulfillmentCurrentTypeChanged = 'PartialsFulfillmentCurrentTypeChanged',
  PartialsFulfillmentTryInFeedbackUpdated = 'PartialsFulfillmentTryInFeedbackUpdated',
  PartialsFulfillmentTryInReturnInTransit = 'PartialsFulfillmentTryInReturnInTransit',
  PartialsFulfillmentTryInReturnReceived = 'PartialsFulfillmentTryInReturnReceived',
  PartialsFulfillmentTryInReturnedForFinish = 'PartialsFulfillmentTryInReturnedForFinish',
  PartialsFulfillmentTryInReturnedForTryIn = 'PartialsFulfillmentTryInReturnedForTryIn',
  PartialsFulfillmentWaxRimFeedbackUpdated = 'PartialsFulfillmentWaxRimFeedbackUpdated',
  PartialsFulfillmentWaxRimReturnInTransit = 'PartialsFulfillmentWaxRimReturnInTransit',
  PartialsFulfillmentWaxRimReturnReceived = 'PartialsFulfillmentWaxRimReturnReceived',
  PartialsFulfillmentWaxRimReturnedForFinish = 'PartialsFulfillmentWaxRimReturnedForFinish',
  PartialsFulfillmentWaxRimReturnedForTryIn = 'PartialsFulfillmentWaxRimReturnedForTryIn',
  PartnerActivationDateRecorded = 'PartnerActivationDateRecorded',
  PartnerBillingAchOfflineMandateToggled = 'PartnerBillingAchOfflineMandateToggled',
  PartnerBillingAutochargeToggled = 'PartnerBillingAutochargeToggled',
  PartnerBillingContactsEdited = 'PartnerBillingContactsEdited',
  PartnerBillingCreated = 'PartnerBillingCreated',
  PartnerBillingCreditAdded = 'PartnerBillingCreditAdded',
  PartnerBillingCreditBalanceAdjusted = 'PartnerBillingCreditBalanceAdjusted',
  PartnerBillingCreditCategoryUpdated = 'PartnerBillingCreditCategoryUpdated',
  PartnerBillingLiableForEquipmentToggled = 'PartnerBillingLiableForEquipmentToggled',
  PartnerBillingPaymentPlanToggled = 'PartnerBillingPaymentPlanToggled',
  PartnerBillingPlaidAccountLinked = 'PartnerBillingPlaidAccountLinked',
  PartnerBillingPrepayExpires = 'PartnerBillingPrepayExpires',
  PartnerBillingPrepayOverrideUpdated = 'PartnerBillingPrepayOverrideUpdated',
  PartnerBillingPrimaryBillingContactUserIdUpdated = 'PartnerBillingPrimaryBillingContactUserIdUpdated',
  PartnerBillingPrimaryBillingContactUserTypeUpdated = 'PartnerBillingPrimaryBillingContactUserTypeUpdated',
  PartnerBillingTaxExemptToggled = 'PartnerBillingTaxExemptToggled',
  PartnerBillingWillBeChargedCcFeeToggled = 'PartnerBillingWillBeChargedCCFeeToggled',
  PartnerPriceConfigDeleted = 'PartnerPriceConfigDeleted',
  PartnerPriceConfigsBulkUpdated = 'PartnerPriceConfigsBulkUpdated',
  PatientCreated = 'PatientCreated',
  PatientReplaced = 'PatientReplaced',
  PatientUpdated = 'PatientUpdated',
  PendingAlignerCaseConcernsUpdated = 'PendingAlignerCaseConcernsUpdated',
  PendingAlignerCaseCreated = 'PendingAlignerCaseCreated',
  PendingAlignerCaseEstimateSubmitted = 'PendingAlignerCaseEstimateSubmitted',
  PendingAlignerCaseEstimateTimeoutExtended = 'PendingAlignerCaseEstimateTimeoutExtended',
  PendingAlignerCaseRejected = 'PendingAlignerCaseRejected',
  PendingAlignerPatientPriceOverridden = 'PendingAlignerPatientPriceOverridden',
  PortalDesign3ShapeInjected = 'PortalDesign3ShapeInjected',
  PortalDesignEditsSubmitted = 'PortalDesignEditsSubmitted',
  PortalShipShippingMethodChanged = 'PortalShipShippingMethodChanged',
  PracticeBillingRecurringItemCreated = 'PracticeBillingRecurringItemCreated',
  PracticeBillingRecurringItemDeleted = 'PracticeBillingRecurringItemDeleted',
  PracticeCaseCreated = 'PracticeCaseCreated',
  PracticeContractCreated = 'PracticeContractCreated',
  PracticeContractDeleted = 'PracticeContractDeleted',
  PracticeContractEffectiveDateSet = 'PracticeContractEffectiveDateSet',
  PracticeContractPandadocContractIdUpdated = 'PracticeContractPandadocContractIdUpdated',
  PracticeContractSpendTermsChanged = 'PracticeContractSpendTermsChanged',
  PracticeContractSpendTermsUpdated = 'PracticeContractSpendTermsUpdated',
  PracticeRequestCreated = 'PracticeRequestCreated',
  PracticeRespondedToDoctorRequest = 'PracticeRespondedToDoctorRequest',
  PracticeRespondedToOrderHold = 'PracticeRespondedToOrderHold',
  PracticeRespondedToRejectedScan = 'PracticeRespondedToRejectedScan',
  PracticeStaffPreferencesCreated = 'PracticeStaffPreferencesCreated',
  PracticeStaffPreferencesDeleted = 'PracticeStaffPreferencesDeleted',
  PracticeStaffPreferencesSet = 'PracticeStaffPreferencesSet',
  PracticeStaffProfilePhotoSet = 'PracticeStaffProfilePhotoSet',
  PracticeSupportCreated = 'PracticeSupportCreated',
  PracticeSupportDeleted = 'PracticeSupportDeleted',
  PracticeSupportUpdated = 'PracticeSupportUpdated',
  PreferencesStaffMemberSet = 'PreferencesStaffMemberSet',
  PreferredManufacturersSet = 'PreferredManufacturersSet',
  PremiumFlagToggled = 'PremiumFlagToggled',
  PrepayAmountChanged = 'PrepayAmountChanged',
  PrepaymentInvoiceFinalized = 'PrepaymentInvoiceFinalized',
  PriceApplicationRuleAdded = 'PriceApplicationRuleAdded',
  PriceApplicationRuleRemoved = 'PriceApplicationRuleRemoved',
  PriceCreated = 'PriceCreated',
  PriceDeleted = 'PriceDeleted',
  PriceNameChanged = 'PriceNameChanged',
  PricePartnerConfigAdded = 'PricePartnerConfigAdded',
  PricePartnerConfigUpdated = 'PricePartnerConfigUpdated',
  PricePresetCreated = 'PricePresetCreated',
  PricePresetDeleted = 'PricePresetDeleted',
  PricePresetUpdated = 'PricePresetUpdated',
  PrintableLabSlipViewed = 'PrintableLabSlipViewed',
  ProductionOrderOptedOut = 'ProductionOrderOptedOut',
  ProductionOrderSynced = 'ProductionOrderSynced',
  QcItemConfigCreated = 'QCItemConfigCreated',
  QcItemConfigEdited = 'QCItemConfigEdited',
  QcLiteSkipQc = 'QCLiteSkipQC',
  QcFlagSubmitted = 'QcFlagSubmitted',
  QcIssueCreated = 'QcIssueCreated',
  QcIssueUpdated = 'QcIssueUpdated',
  QcPhotosAttached = 'QcPhotosAttached',
  QualityControlItemCompleted = 'QualityControlItemCompleted',
  QualityControlItemIgnored = 'QualityControlItemIgnored',
  QualityControlManualItemAdded = 'QualityControlManualItemAdded',
  QueuedNotificationCreated = 'QueuedNotificationCreated',
  QueuedNotificationUpdated = 'QueuedNotificationUpdated',
  ReasonCodeCreated = 'ReasonCodeCreated',
  ReasonCodeUpdated = 'ReasonCodeUpdated',
  RefabReasonCreated = 'RefabReasonCreated',
  RefabReasonUpdated = 'RefabReasonUpdated',
  RejectedScanReplaced = 'RejectedScanReplaced',
  RetainerAddedToAlignerCase = 'RetainerAddedToAlignerCase',
  ReturnCancelledForOrder = 'ReturnCancelledForOrder',
  ReturnCreatedForOrder = 'ReturnCreatedForOrder',
  ReviewSubmissionCreated = 'ReviewSubmissionCreated',
  ReviewSubmissionItemTagsUpdated = 'ReviewSubmissionItemTagsUpdated',
  ReviewSubmissionUpdated = 'ReviewSubmissionUpdated',
  ReviewTagCreated = 'ReviewTagCreated',
  ReviewTagUpdated = 'ReviewTagUpdated',
  RoutingTargetDistributionRuleCreated = 'RoutingTargetDistributionRuleCreated',
  RoutingTargetDistributionRuleDisabled = 'RoutingTargetDistributionRuleDisabled',
  RoutingTargetDistributionRuleEnabled = 'RoutingTargetDistributionRuleEnabled',
  RoutingTargetDistributionRuleUpdated = 'RoutingTargetDistributionRuleUpdated',
  RushRequestAccepted = 'RushRequestAccepted',
  RushRequestCancelled = 'RushRequestCancelled',
  RushRequestRejected = 'RushRequestRejected',
  RushRequested = 'RushRequested',
  SalesforcePriceMappingSet = 'SalesforcePriceMappingSet',
  SavedSearchCreated = 'SavedSearchCreated',
  SavedSearchDeleted = 'SavedSearchDeleted',
  SavedSearchEdited = 'SavedSearchEdited',
  SavedSearchFavorited = 'SavedSearchFavorited',
  SavedSearchUnfavorited = 'SavedSearchUnfavorited',
  ScanArchSizeUpdated = 'ScanArchSizeUpdated',
  ScanExportStlZipFileReplaced = 'ScanExportStlZipFileReplaced',
  ScanFacetCountUpdated = 'ScanFacetCountUpdated',
  ScanFileCreated = 'ScanFileCreated',
  ScanFileCreatedPreUpload = 'ScanFileCreatedPreUpload',
  ScanFileDeleted = 'ScanFileDeleted',
  ScanFileOverwritten = 'ScanFileOverwritten',
  ScanFileStLsManuallyUploaded = 'ScanFileSTLsManuallyUploaded',
  ScanFileStlConversionCompleted = 'ScanFileStlConversionCompleted',
  ScanIssuesReported = 'ScanIssuesReported',
  ScanManuallyLabelled = 'ScanManuallyLabelled',
  ScanMetadataCreated = 'ScanMetadataCreated',
  ScanMetadataUrlUpdated = 'ScanMetadataUrlUpdated',
  ScanRejectionSharedWithPractice = 'ScanRejectionSharedWithPractice',
  ScanReviewWorkOrderAskedDoctorToResolve = 'ScanReviewWorkOrderAskedDoctorToResolve',
  ScanReviewWorkOrderBackfilled = 'ScanReviewWorkOrderBackfilled',
  ScanReviewWorkOrderCancelled = 'ScanReviewWorkOrderCancelled',
  ScanReviewWorkOrderCreated = 'ScanReviewWorkOrderCreated',
  ScanReviewWorkOrderDoctorResponseReceived = 'ScanReviewWorkOrderDoctorResponseReceived',
  ScanReviewWorkOrderResolved = 'ScanReviewWorkOrderResolved',
  ScanValidated = 'ScanValidated',
  ScanbodyCreated = 'ScanbodyCreated',
  ScanbodyDeleted = 'ScanbodyDeleted',
  ScanbodyRequestCreated = 'ScanbodyRequestCreated',
  ScanbodyRequestDeleted = 'ScanbodyRequestDeleted',
  ScanbodyRequestFulfillmentLinked = 'ScanbodyRequestFulfillmentLinked',
  ScanbodyRequestUpdated = 'ScanbodyRequestUpdated',
  ScanbodyRestored = 'ScanbodyRestored',
  ScanbodyUpdated = 'ScanbodyUpdated',
  ScannerBackfilledEvent = 'ScannerBackfilledEvent',
  ScannerCalibrated = 'ScannerCalibrated',
  ScannerCalibration = 'ScannerCalibration',
  ScannerCalibrationStatusesUpdated = 'ScannerCalibrationStatusesUpdated',
  ScannerCreated = 'ScannerCreated',
  ScannerDonglesUpdated = 'ScannerDonglesUpdated',
  ScannerLocation = 'ScannerLocation',
  ScannerLocationUpdated = 'ScannerLocationUpdated',
  ScannerOwnerSet = 'ScannerOwnerSet',
  ScannerSeen = 'ScannerSeen',
  ScannerSerialUpdated = 'ScannerSerialUpdated',
  ScannerVersion = 'ScannerVersion',
  ScannerVersionUpdated = 'ScannerVersionUpdated',
  ShipmentCreated = 'ShipmentCreated',
  ShipmentRemoved = 'ShipmentRemoved',
  ShipmentStatusUpdated = 'ShipmentStatusUpdated',
  /** @deprecated We no longer use Convey */
  ShipmentTransmittedToConvey = 'ShipmentTransmittedToConvey',
  ShippingEstimateUpdated = 'ShippingEstimateUpdated',
  ShippingInfoUpdated = 'ShippingInfoUpdated',
  ShippingStatusChanged = 'ShippingStatusChanged',
  SingleClinicalPreferenceSet = 'SingleClinicalPreferenceSet',
  SmileStyleSet = 'SmileStyleSet',
  StripeLinkingUpdated = 'StripeLinkingUpdated',
  TagCreated = 'TagCreated',
  TagDeleted = 'TagDeleted',
  TagEdited = 'TagEdited',
  TagUndeleted = 'TagUndeleted',
  TaggedEntity = 'TaggedEntity',
  TaggedEntityCreated = 'TaggedEntityCreated',
  ThreeshapeDmeArchived = 'ThreeshapeDmeArchived',
  ThreeshapeDmeCreated = 'ThreeshapeDmeCreated',
  ThreeshapeDmeFilePathUpdated = 'ThreeshapeDmeFilePathUpdated',
  ThreeshapeDmeParsed = 'ThreeshapeDmeParsed',
  ThreeshapeDmeUnarchived = 'ThreeshapeDmeUnarchived',
  TicketActionAppended = 'TicketActionAppended',
  TicketActionAssigned = 'TicketActionAssigned',
  TicketActionClosed = 'TicketActionClosed',
  TicketActionNotesEdited = 'TicketActionNotesEdited',
  TicketClosed = 'TicketClosed',
  TicketCreated = 'TicketCreated',
  TicketSavedSearchCreated = 'TicketSavedSearchCreated',
  TicketSavedSearchDeleted = 'TicketSavedSearchDeleted',
  TicketSavedSearchEdited = 'TicketSavedSearchEdited',
  TicketSavedSearchFavorited = 'TicketSavedSearchFavorited',
  TicketSavedSearchUnfavorited = 'TicketSavedSearchUnfavorited',
  TrainingCreated = 'TrainingCreated',
  TrainingFcmCompleteUpdated = 'TrainingFcmCompleteUpdated',
  TrainingStartDateUpdated = 'TrainingStartDateUpdated',
  TreatmentPlanConvertedForManufacturer = 'TreatmentPlanConvertedForManufacturer',
  TreatmentPlanRejected = 'TreatmentPlanRejected',
  TreatmentPlanRequestConverted = 'TreatmentPlanRequestConverted',
  TreatmentPlanRequestFinalized = 'TreatmentPlanRequestFinalized',
  TreatmentPlanRequestUrlSet = 'TreatmentPlanRequestUrlSet',
  TreatmentPlanRequested = 'TreatmentPlanRequested',
  TreatmentPlanSaved = 'TreatmentPlanSaved',
  TryInFeedbackCreated = 'TryInFeedbackCreated',
  UntaggedEntity = 'UntaggedEntity',
  UpdateRefabCount = 'UpdateRefabCount',
  UsageInvoiceFinalized = 'UsageInvoiceFinalized',
  WaxupRecommendationRuleCreated = 'WaxupRecommendationRuleCreated',
  WaxupRecommendationRuleDisabled = 'WaxupRecommendationRuleDisabled',
  WaxupRecommendationRuleEnabled = 'WaxupRecommendationRuleEnabled',
  WaxupRecommendationRuleUpdated = 'WaxupRecommendationRuleUpdated',
  WorkflowStepDefinitionCreated = 'WorkflowStepDefinitionCreated',
  WorkflowStepDefinitionUpdated = 'WorkflowStepDefinitionUpdated'
}

export type LabsGqlExecuteTemporalWorkflowArgs = {
  /** A JSON array of workflow arguments */
  argsJson?: InputMaybe<Scalars['String']['input']>;
  taskQueue: Scalars['String']['input'];
  workflow: Scalars['String']['input'];
};

export type LabsGqlExistingPatientOrderMatch = {
  __typename?: 'ExistingPatientOrderMatch';
  order: LabsGqlLabOrderWithEvents;
  type: LabsGqlExistingPatientOrderType;
};

export enum LabsGqlExistingPatientOrderType {
  AlignerCase = 'AlignerCase',
  Delivered = 'Delivered',
  Dentures = 'Dentures',
  ScanRejected = 'ScanRejected'
}

export type LabsGqlExternalAccountRelationshipDto = {
  __typename?: 'ExternalAccountRelationshipDTO';
  id: Scalars['String']['output'];
  type: LabsGqlExternalAccountRelationshipType;
};

export type LabsGqlExternalAccountRelationshipInput = {
  id: Scalars['String']['input'];
  type: LabsGqlExternalAccountRelationshipType;
};

export enum LabsGqlExternalAccountRelationshipType {
  KustomerCompany = 'kustomer_company',
  KustomerCustomer = 'kustomer_customer',
  PandadocContractId = 'pandadoc_contract_id',
  SalesforceAccount = 'salesforce_account',
  SalesforceContact = 'salesforce_contact',
  SalesforceOpportunity = 'salesforce_opportunity',
  StripeCustomer = 'stripe_customer'
}

/** Record of submission to external manufacturer */
export type LabsGqlExternalAlignerFulfillment = {
  __typename?: 'ExternalAlignerFulfillment';
  created_at: Scalars['DateTime']['output'];
  expected_arrival_date?: Maybe<Scalars['DateTime']['output']>;
  /** ID in the manufacturers system */
  id: Scalars['String']['output'];
  /** @deprecated Use manufacturer_id on the lab order. */
  provider: LabsGqlExternalAlignerManufacturer;
};

export enum LabsGqlExternalAlignerManufacturer {
  PhaseOrtho = 'PHASE_ORTHO'
}

export type LabsGqlExternalDesignWorkflowTask = LabsGqlIWorkflowTask & {
  __typename?: 'ExternalDesignWorkflowTask';
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlWorkflowTaskExternalDesignConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export type LabsGqlExternalOrgCreatedRes = {
  __typename?: 'ExternalOrgCreatedRes';
  id: Scalars['String']['output'];
};

export type LabsGqlExternalUserCancelOrderCommand = {
  cancellation_reason?: InputMaybe<Scalars['String']['input']>;
  cancellation_reason_code?: LabsGqlCancellationReasonCode;
  delete_scan?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['String']['input'];
};

export type LabsGqlFabricationDaysRule = {
  __typename?: 'FabricationDaysRule';
  /** Number of days to pad the internally committed SLA for minimum_item_count items */
  buffer_days?: Maybe<Scalars['Int']['output']>;
  /** Number of days of design needed for minimum_item_count items */
  design_days?: Maybe<Scalars['Int']['output']>;
  /** Number of days of fabrication needed to manufacture minimum_item_count items */
  fabrication_days: Scalars['Int']['output'];
  /** Minimum number of matched teeth (for items that have teeth, like Crowns or Bridges) or matched items (for items that don't have teeth, like Night Guards) for which this fabrication day mapping applies. */
  minimum_item_count: Scalars['Int']['output'];
};

export type LabsGqlFabricationDaysRuleInput = {
  /** Number of days to pad the internally committed SLA for minimum_item_count items */
  buffer_days?: InputMaybe<Scalars['Int']['input']>;
  /** Number of days of design needed for minimum_item_count items */
  design_days?: InputMaybe<Scalars['Int']['input']>;
  /** Number of days of fabrication needed to manufacture minimum_item_count items */
  fabrication_days: Scalars['Int']['input'];
  /** Minimum number of matched teeth (for items that have teeth, like Crowns or Bridges) or matched items (for items that don't have teeth, like Night Guards) for which this fabrication day mapping applies. */
  minimum_item_count: Scalars['Int']['input'];
};

export type LabsGqlFailedInvoicePaymentAttemptLog = {
  __typename?: 'FailedInvoicePaymentAttemptLog';
  amount_cents: Scalars['Int']['output'];
  charge_id?: Maybe<Scalars['String']['output']>;
  configuration?: Maybe<LabsGqlPartnerPaymentSplitConfig>;
  created_at: Scalars['DateTime']['output'];
  error_summary: Scalars['String']['output'];
  payment_source_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<LabsGqlStripeChargeStatus>;
  triggered_by_user_id?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlFavoriteSavedSearchCommand = {
  search_id: Scalars['String']['input'];
};

export type LabsGqlFavoriteTicketSavedSearchCommand = {
  search_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type LabsGqlFcmCheckResult = {
  __typename?: 'FcmCheckResult';
  is_eligible: Scalars['Boolean']['output'];
};

export enum LabsGqlFieldType {
  Date = 'date',
  Number = 'number',
  Object = 'object',
  Other = 'other',
  String = 'string'
}

export type LabsGqlFileDownloadUrl = {
  __typename?: 'FileDownloadUrl';
  downloadUrl: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type LabsGqlFilePathAndBucket = {
  bucketName: Scalars['String']['input'];
  path: Scalars['String']['input'];
};

export type LabsGqlFileUploadUrl = {
  __typename?: 'FileUploadUrl';
  path: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export enum LabsGqlFilterCombinator {
  And = 'and',
  Or = 'or'
}

export enum LabsGqlFilterComparator {
  AfterNow = 'afterNow',
  BeforeNow = 'beforeNow',
  DaysAfter = 'daysAfter',
  DaysBefore = 'daysBefore',
  Disabled = 'disabled',
  Equals = 'equals',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  NotEquals = 'notEquals',
  NotOneOf = 'notOneOf',
  NotSet = 'notSet',
  OneOf = 'oneOf',
  Set = 'set'
}

export enum LabsGqlFilterComparisonValueType {
  Boolean = 'boolean',
  Date = 'date',
  Number = 'number',
  Pod = 'pod',
  String = 'string',
  Tag = 'tag'
}

/** Saved state of a filter */
export type LabsGqlFilterCriteriaSubmission = {
  __typename?: 'FilterCriteriaSubmission';
  comparator: LabsGqlFilterComparator;
  comparison_value?: Maybe<Scalars['AutomationFieldValue']['output']>;
  filter_id: Scalars['String']['output'];
  list_combinator?: Maybe<LabsGqlFilterListCombinator>;
};

export type LabsGqlFilterCriteriaSubmissionInput = {
  comparator: LabsGqlFilterComparator;
  comparison_value?: InputMaybe<Scalars['AutomationFieldValue']['input']>;
  filter_id: Scalars['String']['input'];
  list_combinator?: InputMaybe<LabsGqlFilterListCombinator>;
};

export type LabsGqlFilterDefinition = {
  __typename?: 'FilterDefinition';
  /** whether or not the value we are comparing against is nullable */
  comparison_value_nullable: Scalars['Boolean']['output'];
  /** type of value we're comparing against, determines what comparators are shown to user */
  comparison_value_type: LabsGqlFilterComparisonValueType;
  excluded_comparators?: Maybe<Array<LabsGqlFilterComparator>>;
  /** Whether or not this filter has options */
  hasOptions: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  list_entity_name?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum LabsGqlFilterListCombinator {
  All = 'all',
  Any = 'any',
  None = 'none'
}

export type LabsGqlFilterSubmission = {
  __typename?: 'FilterSubmission';
  combinator: LabsGqlFilterCombinator;
  criteria: Array<LabsGqlFilterCriteriaSubmission>;
};

export type LabsGqlFilterSubmissionInput = {
  combinator: LabsGqlFilterCombinator;
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
};

export type LabsGqlFinalizeTreatmentPlanRequestCommand = {
  duration_in_months: Scalars['Int']['input'];
  needs_attachments_lower?: InputMaybe<Scalars['Boolean']['input']>;
  needs_attachments_upper?: InputMaybe<Scalars['Boolean']['input']>;
  order_id: Scalars['String']['input'];
  plan_type: LabsGqlTreatmentPlanType;
  softsmile_version_id?: InputMaybe<Scalars['Int']['input']>;
  steps: Array<LabsGqlAlignerTreatmentPlanStepInput>;
  url: Scalars['String']['input'];
};

export type LabsGqlFinishingInBrowserPayload = {
  /** ID of the design revision to inject into. */
  design_id: Scalars['String']['input'];
  /** Pass true to override design task validation and force the design to be attached to the order. */
  override_design_task_validation?: InputMaybe<Scalars['Boolean']['input']>;
  /** In-browser collected data on how long it took to create this finishing design. */
  session_timing_data: LabsGqlPrepInBrowserSessionTimingData;
  /** GCS path to the uploaded submission file. */
  submission_file_path: Scalars['String']['input'];
};

export type LabsGqlFlagOrderForReviewCommand = {
  flag_reasons: Array<Scalars['String']['input']>;
  needed_review_steps_ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderId: Scalars['String']['input'];
};

export enum LabsGqlFlagStatus {
  Approved = 'Approved',
  Ignored = 'Ignored',
  Rejected = 'Rejected'
}

export enum LabsGqlFormDisplay {
  Both = 'Both',
  Feedback = 'Feedback',
  Refab = 'Refab'
}

export type LabsGqlFulfilledAlignerTreatmentPlan = LabsGqlIAlignerTreatmentPlan & {
  __typename?: 'FulfilledAlignerTreatmentPlan';
  /** Date the dentist approved for manufacturing */
  approved_at: Scalars['DateTime']['output'];
  dandy_plan?: Maybe<LabsGqlAlignerPlan>;
  duration_in_months: Scalars['Int']['output'];
  fulfillment: LabsGqlExternalAlignerFulfillment;
  /** Denotes that the plan was modified by the doctor and will need review by a designer before fabrication. */
  modified_by_doctor?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use needs_attachments_upper and needs_attachments_lower on the treatment plan instead */
  needs_attachments: Scalars['Boolean']['output'];
  needs_attachments_lower?: Maybe<Scalars['Boolean']['output']>;
  needs_attachments_upper?: Maybe<Scalars['Boolean']['output']>;
  needs_ipr: Scalars['Boolean']['output'];
  plan_type: LabsGqlTreatmentPlanType;
  request: LabsGqlConvertedTreatmentPlanRequest;
  softsmile?: Maybe<LabsGqlSoftSmileData>;
  steps: Array<LabsGqlAlignerTreatmentPlanStep>;
};

export type LabsGqlFullyResolvedPracticeContractDto = {
  __typename?: 'FullyResolvedPracticeContractDTO';
  associatedPractices: Array<LabsGqlPracticeToPracticeContractDto>;
  contract: LabsGqlPracticeContractDto;
  spendTerms: Array<LabsGqlPracticeContractSpendTermDto>;
};

export type LabsGqlGenericWorkflowTaskConfig = {
  __typename?: 'GenericWorkflowTaskConfig';
  description?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlGetAllOrganizationsCsvFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<LabsGqlOrganizationType>>;
};

export type LabsGqlGetAllOrganizationsCsvResult = {
  __typename?: 'GetAllOrganizationsCsvResult';
  csv?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlGetAllPendingVariantFeedbackForPracticeInput = {
  end_date?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LabsGqlGetAllPendingVariantFeedbackInput = {
  end_date?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The get cart delivery options response. */
export type LabsGqlGetCartDeliveryOptionsRes = {
  __typename?: 'GetCartDeliveryOptionsRes';
  /**
   * The list of available delivery options.
   *
   * Will always include the Standard delivery option.
   */
  options: Array<LabsGqlCartDeliveryOption>;
};

export type LabsGqlGetEmailPreviewByNotificationLogIdResult = {
  __typename?: 'GetEmailPreviewByNotificationLogIdResult';
  emailTemplate?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlGetFeatureFlagValueByOrganizationIdRequest = {
  flag_name: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type LabsGqlGetFeatureFlagValueByOrganizationIdResponse = {
  __typename?: 'GetFeatureFlagValueByOrganizationIdResponse';
  value: Scalars['String']['output'];
};

export type LabsGqlGetIteroIntegrationAuthDetails = {
  __typename?: 'GetIteroIntegrationAuthDetails';
  authorizeUrl: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  isServiceOperational: Scalars['Boolean']['output'];
};

export type LabsGqlGetOnboardingStepsResponse = {
  __typename?: 'GetOnboardingStepsResponse';
  status: LabsGqlOnboardingStatus;
  step_id: LabsGqlOnboardingStep;
};

export type LabsGqlGetOnboardingStepsResponseV2 = {
  __typename?: 'GetOnboardingStepsResponseV2';
  active_step_id?: Maybe<LabsGqlOnboardingStep>;
  status: LabsGqlOnboardingStatus;
  steps: Array<LabsGqlOnboardingStepData>;
};

export type LabsGqlGetOwnImpersonatedShortenedLinkResponse = {
  __typename?: 'GetOwnImpersonatedShortenedLinkResponse';
  fullUrl: Scalars['String']['output'];
  shortUrl: Scalars['String']['output'];
};

export type LabsGqlGetPaginatedOrganizationsFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  include_ancestors?: InputMaybe<Scalars['Boolean']['input']>;
  include_hierarchy?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<LabsGqlOrganizationType>>;
};

export type LabsGqlGetPaginatedUsersFilter = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mobile_phone_number?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<LabsGqlStaffRole>>;
  staff_organization_type?: InputMaybe<Array<LabsGqlOrganizationType>>;
};

export type LabsGqlGetPatientSharesByOrgIdResult = {
  __typename?: 'GetPatientSharesByOrgIdResult';
  annotation_urls?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  patient_email?: Maybe<Scalars['String']['output']>;
  patient_first_name: Scalars['String']['output'];
  patient_id: Scalars['String']['output'];
  patient_last_name: Scalars['String']['output'];
  patient_mobile_phone?: Maybe<Scalars['String']['output']>;
  scan_export_id: Scalars['String']['output'];
  share_date: Scalars['DateTime']['output'];
  share_type: Scalars['String']['output'];
  staff_member_id: Scalars['String']['output'];
};

export type LabsGqlGetSmsContentsByNotificationLogIdResult = {
  __typename?: 'GetSmsContentsByNotificationLogIdResult';
  smsContents?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlGettingStartedStepDto = {
  __typename?: 'GettingStartedStepDTO';
  completed_at?: Maybe<Scalars['DateTime']['output']>;
  key: LabsGqlGettingStartedStepKey;
  length: Scalars['String']['output'];
  page_title: Scalars['String']['output'];
  page_url: Scalars['String']['output'];
};

export enum LabsGqlGettingStartedStepKey {
  CreateAccount = 'create_account',
  DownloadTeamviewer = 'download_teamviewer',
  LearnAboutDandy = 'learn_about_dandy',
  LearnAboutDigitalScanning = 'learn_about_digital_scanning',
  LearnHowToPlaceAnOrder = 'learn_how_to_place_an_order',
  SetNotificationPreferences = 'set_notification_preferences',
  SetUpYourEquipment = 'set_up_your_equipment'
}

export enum LabsGqlGuidedQcResultEntryScoreVerdict {
  Acceptable = 'ACCEPTABLE',
  Bad = 'BAD',
  Ideal = 'IDEAL'
}

export enum LabsGqlGuidedWaxupPresetStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Skipped = 'SKIPPED'
}

export enum LabsGqlGuidedWaxupPresetType {
  AnteriorContour = 'ANTERIOR_CONTOUR',
  ContactDesign = 'CONTACT_DESIGN',
  ContourView = 'CONTOUR_VIEW',
  FacialAnatomy = 'FACIAL_ANATOMY',
  GeneralView = 'GENERAL_VIEW',
  MarginalRidge = 'MARGINAL_RIDGE',
  MarginView = 'MARGIN_VIEW',
  OcclusalAnatomy = 'OCCLUSAL_ANATOMY',
  PosteriorContour = 'POSTERIOR_CONTOUR',
  ToothDesign = 'TOOTH_DESIGN',
  VideoWalkthrough = 'VIDEO_WALKTHROUGH'
}

export type LabsGqlHistoryItemDetails = {
  __typename?: 'HistoryItemDetails';
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlHoldDetails = {
  hold_category: Scalars['String']['input'];
  hold_created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hold_freetext?: InputMaybe<Scalars['String']['input']>;
  hold_id?: InputMaybe<Scalars['String']['input']>;
  hold_is_practice_managed_pause?: InputMaybe<Scalars['Boolean']['input']>;
  hold_subcategory?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlHolidaySchema = {
  __typename?: 'HolidaySchema';
  end_date: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  start_date: Scalars['String']['output'];
};

export type LabsGqlIAlignerTreatmentPlan = {
  duration_in_months: Scalars['Int']['output'];
  /** Denotes that the plan was modified by the doctor and will need review by a designer before fabrication. */
  modified_by_doctor?: Maybe<Scalars['Boolean']['output']>;
  needs_attachments_lower?: Maybe<Scalars['Boolean']['output']>;
  needs_attachments_upper?: Maybe<Scalars['Boolean']['output']>;
  plan_type: LabsGqlTreatmentPlanType;
  request: LabsGqlConvertedTreatmentPlanRequest;
  softsmile?: Maybe<LabsGqlSoftSmileData>;
  steps: Array<LabsGqlAlignerTreatmentPlanStep>;
};

export type LabsGqlIAutomationField = {
  default_value?: Maybe<Scalars['AutomationFieldValue']['output']>;
  helper_text?: Maybe<Scalars['String']['output']>;
  /** Unique id for identifying this field in submissions */
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  optional?: Maybe<Scalars['Boolean']['output']>;
  type: LabsGqlAutomationFieldType;
};

/** A base interface for Cart items */
export type LabsGqlICartItemBase = {
  attachments: Array<LabsGqlLabOrderAttachment>;
  id?: Maybe<Scalars['String']['output']>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing?: Maybe<LabsGqlCartItemPricing>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
};

export type LabsGqlICompletedTreatmentPlanRequest = {
  completed_at: Scalars['DateTime']['output'];
  created_at: Scalars['DateTime']['output'];
  expected_completion_date: Scalars['DateTime']['output'];
  /** ID in external providers system */
  id: Scalars['String']['output'];
  provider: LabsGqlTreatmentPlanProvider;
  url: Scalars['String']['output'];
};

export type LabsGqlICustomField = {
  __typename?: 'ICustomField';
  archived: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  default_value?: Maybe<Scalars['MetafieldJSON']['output']>;
  display_rules: Array<LabsGqlCustomFieldDisplayRule>;
  element: LabsGqlCustomFieldElement;
  has_preference: Scalars['Boolean']['output'];
  helper_text?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  optional?: Maybe<Scalars['Boolean']['output']>;
  options?: Maybe<Array<LabsGqlICustomFieldOption>>;
  order_form_inline_values?: Maybe<Array<Scalars['String']['output']>>;
  /** Label for field in preferences settings */
  preference_label?: Maybe<Scalars['String']['output']>;
  pricing_rules: Array<LabsGqlCustomFieldPricingRule>;
  sort_order: Scalars['Int']['output'];
  suggested_values?: Maybe<Array<LabsGqlICustomFieldSuggestion>>;
  type: LabsGqlCustomFieldType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlICustomFieldOption = {
  __typename?: 'ICustomFieldOption';
  color?: Maybe<Scalars['String']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  /** If set, overrides displayed value for internal users. */
  internal_display_label?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  /** If true, on a material element field, the field will be shown in an additional options dropdown */
  non_standard?: Maybe<Scalars['Boolean']['output']>;
  value: Scalars['String']['output'];
};

export type LabsGqlICustomFieldOptionInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  /** If set, overrides displayed value for internal users. */
  internal_display_label?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** If true, on a material element field, the field will be shown in an additional options dropdown */
  non_standard?: InputMaybe<Scalars['Boolean']['input']>;
  value: Scalars['String']['input'];
};

export type LabsGqlICustomFieldSelect = {
  __typename?: 'ICustomFieldSelect';
  archived: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  default_value?: Maybe<Scalars['MetafieldJSON']['output']>;
  display_rules: Array<LabsGqlCustomFieldDisplayRule>;
  element: LabsGqlCustomFieldElement;
  has_preference: Scalars['Boolean']['output'];
  helper_text?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  optional?: Maybe<Scalars['Boolean']['output']>;
  options: Array<LabsGqlICustomFieldOption>;
  order_form_inline_values?: Maybe<Array<Scalars['String']['output']>>;
  /** Label for field in preferences settings */
  preference_label?: Maybe<Scalars['String']['output']>;
  pricing_rules: Array<LabsGqlCustomFieldPricingRule>;
  sort_order: Scalars['Int']['output'];
  suggested_values?: Maybe<Array<LabsGqlICustomFieldSuggestion>>;
  type: LabsGqlCustomFieldType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlICustomFieldSubmission = {
  __typename?: 'ICustomFieldSubmission';
  /**
   * This will be either the field's name or form label (if set).
   *         We write it on submission so its consistent for the user if either changes later
   */
  display_name: Scalars['String']['output'];
  field_id: Scalars['String']['output'];
  override_price_cents?: Maybe<Scalars['Int']['output']>;
  override_price_reason?: Maybe<Scalars['String']['output']>;
  price_cents?: Maybe<Scalars['Int']['output']>;
  value: Scalars['MetafieldJSON']['output'];
};

export type LabsGqlICustomFieldSubmissionInput = {
  /**
   * This will be either the field's name or form label (if set).
   *         We write it on submission so its consistent for the user if either changes later
   */
  display_name: Scalars['String']['input'];
  field_id: Scalars['String']['input'];
  override_price_cents?: InputMaybe<Scalars['Int']['input']>;
  override_price_reason?: InputMaybe<Scalars['String']['input']>;
  price_cents?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['MetafieldJSON']['input'];
};

export type LabsGqlICustomFieldSuggestion = {
  __typename?: 'ICustomFieldSuggestion';
  id: Scalars['String']['output'];
  item_rules: Array<LabsGqlCustomFieldDisplayRule>;
  name: Scalars['String']['output'];
  recommended_option: Scalars['String']['output'];
};

export type LabsGqlICustomSearchParams = {
  order_criteria?: Maybe<Array<LabsGqlFilterCriteriaSubmission>>;
  search?: Maybe<Scalars['String']['output']>;
  type: LabsGqlCustomSearchType;
};

export type LabsGqlICustomUnit = {
  __typename?: 'ICustomUnit';
  add_standalone_to_cart: Scalars['Boolean']['output'];
  cart_index?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  lab_add_to_order?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  override_scan_unit_types: Array<Scalars['String']['output']>;
  sku?: Maybe<LabsGqlOrderItemSkuType>;
  /** @deprecated Replaced by unit_type_rules */
  standalone_disabling_cart_units?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Replaced by unit_type_rules */
  standalone_enabling_cart_units?: Maybe<Array<Scalars['String']['output']>>;
  unit_type_rules?: Maybe<Array<LabsGqlCustomFieldDisplayRule>>;
  updated_at: Scalars['DateTime']['output'];
};

/** A base interface for order items */
export type LabsGqlIOrderItemBase = {
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
};

export type LabsGqlIOrderReturn = {
  __typename?: 'IOrderReturn';
  cancellation_date?: Maybe<Scalars['DateTime']['output']>;
  cancelled_by?: Maybe<LabsGqlOrderReturnUserAction>;
  created_at: Scalars['DateTime']['output'];
  created_by: LabsGqlOrderReturnUserAction;
  /** ID in external shipping provider system (ex: GoShippo) */
  external_shipment_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item_ids: Array<Scalars['String']['output']>;
  label_url: Scalars['String']['output'];
  manufacturer_id: Scalars['String']['output'];
  order: LabsGqlLabOrderWithEvents;
  /** @deprecated superseded by order */
  order_id: Scalars['String']['output'];
  partner_id: Scalars['String']['output'];
  received_by?: Maybe<LabsGqlOrderReturnUserAction>;
  received_date?: Maybe<Scalars['DateTime']['output']>;
  scan_export_id?: Maybe<Scalars['String']['output']>;
  shipment: LabsGqlIShipment;
  shipment_id: Scalars['String']['output'];
  status: LabsGqlOrderReturnStatus;
  type: LabsGqlOrderReturnType;
  updated_at: Scalars['DateTime']['output'];
  waxup_requested?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlIPartnerBilling = {
  __typename?: 'IPartnerBilling';
  account_management_contact_emails: Array<Scalars['String']['output']>;
  activation_date?: Maybe<Scalars['DateTime']['output']>;
  activation_source?: Maybe<LabsGqlPartnerActivationSource>;
  agreed_to_cc_fees: Scalars['Boolean']['output'];
  /** Whether or not we should attempt to autocharge usage invoices */
  autocharge_enabled: Scalars['Boolean']['output'];
  contact_emails: Array<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** This is partner id to ensure uniqueness */
  id: Scalars['ID']['output'];
  is_liable_for_equipment: Scalars['Boolean']['output'];
  is_on_payment_plan: Scalars['Boolean']['output'];
  is_tax_exempt: Scalars['Boolean']['output'];
  primary_billing_contact_user_id: Scalars['String']['output'];
  primary_billing_contact_user_type: LabsGqlBillingContactUserType;
  stripe_customer_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  usage_payment_source_config?: Maybe<LabsGqlPartnerPaymentSplitConfig>;
  will_be_charged_cc_fee: Scalars['Boolean']['output'];
};

export type LabsGqlIPartnerBillingWithName = {
  __typename?: 'IPartnerBillingWithName';
  account_management_contact_emails: Array<Scalars['String']['output']>;
  activation_date?: Maybe<Scalars['DateTime']['output']>;
  activation_source?: Maybe<LabsGqlPartnerActivationSource>;
  agreed_to_cc_fees: Scalars['Boolean']['output'];
  /** Whether or not we should attempt to autocharge usage invoices */
  autocharge_enabled: Scalars['Boolean']['output'];
  contact_emails: Array<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** This is partner id to ensure uniqueness */
  id: Scalars['ID']['output'];
  is_liable_for_equipment: Scalars['Boolean']['output'];
  is_on_payment_plan: Scalars['Boolean']['output'];
  is_tax_exempt: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  primary_billing_contact_user_id: Scalars['String']['output'];
  primary_billing_contact_user_type: LabsGqlBillingContactUserType;
  stripe_customer_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  usage_payment_source_config?: Maybe<LabsGqlPartnerPaymentSplitConfig>;
  will_be_charged_cc_fee: Scalars['Boolean']['output'];
};

export type LabsGqlIPendingReview = {
  __typename?: 'IPendingReview';
  /** ID of the LabOrder */
  id: Scalars['String']['output'];
  lab_order: LabsGqlLabOrderWithEvents;
  tags: Array<LabsGqlReviewTagForOrder>;
};

export type LabsGqlIPracticeCase = {
  __typename?: 'IPracticeCase';
  actor_email?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: LabsGqlThreeShapeActorRoleType;
  case_color_names?: Maybe<Scalars['String']['output']>;
  case_creation_date_utc?: Maybe<Scalars['DateTime']['output']>;
  case_item_names?: Maybe<Scalars['String']['output']>;
  case_material_names?: Maybe<Scalars['String']['output']>;
  case_name?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  partner_id: Scalars['ID']['output'];
  scanner_id: Scalars['ID']['output'];
  threeshape_order_id: Scalars['ID']['output'];
  threeshape_patient_id?: Maybe<Scalars['ID']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlIPrice = {
  __typename?: 'IPrice';
  application_rules: Array<LabsGqlPriceApplicationRule>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partner_prices: Array<LabsGqlPartnerPriceOverride>;
  salesforce_price_mapping?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlIPriceDefinitionResult = {
  __typename?: 'IPriceDefinitionResult';
  application_rules: Array<LabsGqlPriceApplicationRule>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partner_prices_count: Scalars['Int']['output'];
  salesforce_price_mapping?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

/** An array of default prices mapped to a reusable code. Can be applied to a partner to set standard pricing. */
export type LabsGqlIPricePreset = {
  __typename?: 'IPricePreset';
  cart_field_price_defaults: Array<LabsGqlCartFieldDefaultPrice>;
  code: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  price_defaults: Array<LabsGqlDefaultPrice>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlIProductCatalogWithDetailsBase = {
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: LabsGqlProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlIProductDetailsBase = {
  catalogId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  type: LabsGqlProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlIReviewSubmission = LabsGqlIReviewSubmissionSection & {
  __typename?: 'IReviewSubmission';
  annotations: Array<LabsGqlReviewSubmissionAnnotationDto>;
  attachments: Array<LabsGqlRefabOrderAttachmentsDto>;
  /** @deprecated used only for deprecated chairside scan feedback */
  case_id?: Maybe<Scalars['String']['output']>;
  chairside_adjustment_minutes?: Maybe<Scalars['Float']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** @deprecated used only for chairside scan feedback, otherwise refer to lab_order_id */
  doctor_id: Scalars['String']['output'];
  /** @deprecated used only for intermediate order feedback, otherwise refer to items' star_rating */
  doctor_is_happy?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  items: Array<LabsGqlReviewSubmissionSectionItem>;
  /** null only for deprecated chairside scan feedback */
  lab_order_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated refer to lab_order_id */
  manufacturer_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated used only for legacy and intermediate order feedback, otherwise refer to items' notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** @deprecated used only for chairside scan feedback, otherwise refer to lab_order_id */
  partner_id: Scalars['String']['output'];
  /** @deprecated used only for chairside scan feedback and legacy order feedback, otherwise refer to items' star_rating */
  rating: LabsGqlReviewRating;
  /** @deprecated denotes chairside scan feedback */
  review_type?: Maybe<LabsGqlReviewType>;
  /** @deprecated refer to creation or modification event */
  submitting_user_id: Scalars['String']['output'];
  /** @deprecated used only for legacy order feedback, otherwise refer to items' tags */
  tags: Array<LabsGqlReviewTagSubmission>;
  updated_at: Scalars['DateTime']['output'];
};

/** deprecated, select from review or review item directly */
export type LabsGqlIReviewSubmissionSection = {
  chairside_adjustment_minutes?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  rating: LabsGqlReviewRating;
  tags: Array<LabsGqlReviewTagSubmission>;
};

/** Configuration for review tags */
export type LabsGqlIReviewTag = {
  applicable_ratings: Array<LabsGqlReviewRating>;
  applicable_sections: Array<LabsGqlReviewSection>;
  archived: Scalars['Boolean']['output'];
  category?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** Only applies if item included in applicable_sections */
  display_rules: Array<LabsGqlCustomFieldDisplayRule>;
  id: Scalars['ID']['output'];
  /** If there are >0 related_cart_field_ids, the item must have one of the cart fields set for this tag to display */
  related_cart_field_ids: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlIShipment = {
  __typename?: 'IShipment';
  carrier: LabsGqlShippingCarriersEnum;
  created_at: Scalars['DateTime']['output'];
  created_via?: Maybe<LabsGqlShipmentCreatedViaEnumType>;
  /** Intended destination for the shipment */
  destination_type: LabsGqlShipmentDestinationType;
  eta: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  label_url?: Maybe<Scalars['String']['output']>;
  partner_address?: Maybe<LabsGqlPartnerDeliveryAddress>;
  shipped_via: Scalars['String']['output'];
  status: LabsGqlShipmentStatus;
  status_description: Scalars['String']['output'];
  tracking_number: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlITimelineItem = {
  actor: LabsGqlTimelineItemActor;
  /** @deprecated use attachments instead */
  attachment_urls: Array<Scalars['String']['output']>;
  attachments: Array<LabsGqlTimelineAttachment>;
  date: Scalars['DateTime']['output'];
  notification_log_id?: Maybe<Scalars['String']['output']>;
  subtitle: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  title_link?: Maybe<LabsGqlTimelineItemLink>;
};

/** An external treatment planning case */
export type LabsGqlITreatmentPlanRequest = {
  created_at: Scalars['DateTime']['output'];
  expected_completion_date: Scalars['DateTime']['output'];
  /** ID in external providers system */
  id: Scalars['String']['output'];
  provider: LabsGqlTreatmentPlanProvider;
};

export type LabsGqlIWorkflowTask = {
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export type LabsGqlIgnoreQualityControlItemCommand = {
  ignored: Scalars['Boolean']['input'];
  item_id: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type LabsGqlImpersonationTokenDto = {
  __typename?: 'ImpersonationTokenDTO';
  enterpriseUrl?: Maybe<Scalars['String']['output']>;
  labPortalUrl?: Maybe<Scalars['String']['output']>;
  practiceUrl: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type LabsGqlImplantBridgeItem = LabsGqlIOrderItemBase & {
  __typename?: 'ImplantBridgeItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  implants: Array<LabsGqlImplantToothGroup>;
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  restoratives: Array<LabsGqlOrderItemSingleToothUnit>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
};

export type LabsGqlImplantBridgeItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  implants: Array<LabsGqlImplantToothGroupInput>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  restoratives: Array<LabsGqlOrderItemSingleToothUnitInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
};

export type LabsGqlImplantItem = LabsGqlIOrderItemBase & {
  __typename?: 'ImplantItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlImplantToothGroup;
};

export type LabsGqlImplantItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlImplantToothGroupInput;
};

export type LabsGqlImplantToScanbodiesInput = {
  is_authentic: Scalars['Boolean']['input'];
  /** A higher priority scan body should be preferred over a lower priority scan body when possible */
  priority?: InputMaybe<Scalars['Float']['input']>;
  scanbody_id: Scalars['String']['input'];
};

export type LabsGqlImplantToScanbodyDto = {
  __typename?: 'ImplantToScanbodyDTO';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  implant_type_id: Scalars['String']['output'];
  is_authentic: Scalars['Boolean']['output'];
  priority?: Maybe<Scalars['Float']['output']>;
  scanbody: LabsGqlScanbodyDto;
  scanbody_id: Scalars['String']['output'];
};

export type LabsGqlImplantToothGroup = {
  __typename?: 'ImplantToothGroup';
  abutment: LabsGqlOrderItemUnitMetadata;
  crown: LabsGqlOrderItemUnitMetadata;
  implant_metadata: LabsGqlOrderItemImplantMetadata;
  unn: Scalars['ToothNumber']['output'];
};

export type LabsGqlImplantToothGroupInput = {
  abutment: LabsGqlOrderItemUnitMetadataInput;
  crown: LabsGqlOrderItemUnitMetadataInput;
  implant_metadata: LabsGqlOrderItemImplantMetadataInput;
  unn: Scalars['Int']['input'];
};

export type LabsGqlImplantToothScanbodyUsage = {
  __typename?: 'ImplantToothScanbodyUsage';
  scanbody?: Maybe<LabsGqlScanbodyDto>;
  /** The id of the scan body used. null if it was unknown or a non-dandy scan body */
  scanbody_id?: Maybe<Scalars['String']['output']>;
  /** UNN tooth numbering from 1-32 this scan body was used for */
  unn: Scalars['Int']['output'];
};

export type LabsGqlImplantToothScanbodyUsageInput = {
  /** The id of the scan body used. null if it was unknown or a non-dandy scan body */
  scanbody_id?: InputMaybe<Scalars['String']['input']>;
  /** UNN tooth numbering from 1-32 this scan body was used for */
  unn: Scalars['Int']['input'];
};

export type LabsGqlImplantTypeDto = {
  __typename?: 'ImplantTypeDTO';
  connection: Scalars['String']['output'];
  connection_type?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  implantToScanbodies: Array<LabsGqlImplantToScanbodyDto>;
  is_archived: Scalars['Boolean']['output'];
  manufacturer: Scalars['String']['output'];
  scanbody_relationships: Array<LabsGqlImplantToScanbodyDto>;
  system: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlInboxActiveOrders = {
  __typename?: 'InboxActiveOrders';
  orders: Array<LabsGqlLabOrderWithEvents>;
  priority: Array<LabsGqlLabOrderWithEvents>;
};

export type LabsGqlInboxCustomSearch = LabsGqlICustomSearchParams & {
  __typename?: 'InboxCustomSearch';
  order_criteria?: Maybe<Array<LabsGqlFilterCriteriaSubmission>>;
  search?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<LabsGqlSavedWorkflowTaskSortParam>;
  task_filter?: Maybe<LabsGqlSavedListWorkflowTasksFilter>;
  type: LabsGqlCustomSearchType;
};

export type LabsGqlInboxCustomSearchInput = {
  order_criteria?: InputMaybe<Array<LabsGqlFilterCriteriaSubmissionInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<LabsGqlWorkflowTaskSortParam>;
  task_filter?: InputMaybe<LabsGqlListWorkflowTasksFilter>;
  type: LabsGqlCustomSearchType;
};

export type LabsGqlIndexedPriceField = {
  __typename?: 'IndexedPriceField';
  amount_due_cents: Scalars['Int']['output'];
  idx: Scalars['Int']['output'];
  override_reason?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlInjectionMoldDesignCapabilitiesDto = {
  __typename?: 'InjectionMoldDesignCapabilitiesDTO';
  partials_injection_mold?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
};

export type LabsGqlInlayItem = LabsGqlIOrderItemBase & {
  __typename?: 'InlayItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlOrderItemSingleToothUnit;
};

export type LabsGqlInlayItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlOrderItemSingleToothUnitInput;
};

export enum LabsGqlInternalDesignAbility {
  Either = 'Either',
  ExternalOnly = 'ExternalOnly',
  InternalOnly = 'InternalOnly'
}

export type LabsGqlInternalDesignCapabilitiesDto = {
  __typename?: 'InternalDesignCapabilitiesDTO';
  anterior_crown_and_bridge?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  dentures?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  dentures_wax_rim?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  emax?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  implant?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  item_variants?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  model?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  noble_white?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  partials?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  pfm?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  pfz?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  posterior_crown_and_bridge?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  red_carpet?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  refabrication?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  thermoform?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
  waxup?: Maybe<LabsGqlOrderDesignCapabilityLevel>;
};

export enum LabsGqlInternalDesignTaskType {
  Final = 'Final'
}

export type LabsGqlInternalDesignWorkflowTask = LabsGqlIWorkflowTask & {
  __typename?: 'InternalDesignWorkflowTask';
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlWorkflowTaskInternalDesignConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  task_has_qa_evaluation_results: Scalars['Boolean']['output'];
  type: LabsGqlWorkflowTaskType;
};

export type LabsGqlInterproximalSpace = {
  __typename?: 'InterproximalSpace';
  tooth1: Scalars['Int']['output'];
  tooth2: Scalars['Int']['output'];
};

export type LabsGqlInterproximalSpaceInput = {
  tooth1: Scalars['Int']['input'];
  tooth2: Scalars['Int']['input'];
};

export type LabsGqlInterproximalSpaceSize = {
  __typename?: 'InterproximalSpaceSize';
  size: Scalars['Float']['output'];
  space: LabsGqlInterproximalSpace;
};

export type LabsGqlInterproximalSpaceSizeInput = {
  size: Scalars['Float']['input'];
  space: LabsGqlInterproximalSpaceInput;
};

export type LabsGqlIntervalSchema = {
  __typename?: 'IntervalSchema';
  end_time: Scalars['Float']['output'];
  start_time: Scalars['Float']['output'];
};

export type LabsGqlInvoice = {
  __typename?: 'Invoice';
  /** Final amount after credits and balance applied. Not affected by payments */
  amount_due: Scalars['Int']['output'];
  /** Amount that the partner has paid */
  amount_paid: Scalars['Int']['output'];
  /** Amount that the partner still needs to pay */
  amount_remaining: Scalars['Int']['output'];
  applied_balance: Scalars['Int']['output'];
  card_decline_email_sent: Scalars['Boolean']['output'];
  charge_automatically: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  default_source_id?: Maybe<Scalars['String']['output']>;
  due_date: Scalars['DateTime']['output'];
  /** ID from stripe */
  id: Scalars['String']['output'];
  /** Unique, human-readable identifier for the invoice derived from the creation event ID */
  invoice_number?: Maybe<Scalars['String']['output']>;
  is_prepayment_charge: Scalars['Boolean']['output'];
  items: Array<LabsGqlInvoiceItem>;
  payment_errors: Array<LabsGqlFailedInvoicePaymentAttemptLog>;
  payments: Array<LabsGqlInvoicePaymentAttemptLog>;
  payments_pending: Array<LabsGqlInvoicePaymentAttemptLog>;
  period_end: Scalars['DateTime']['output'];
  period_start: Scalars['DateTime']['output'];
  receipt_email_sent: Scalars['Boolean']['output'];
  status: LabsGqlStripeInvoiceStatus;
  summary_email_sent: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
};

/** Base interface for invoice adjustment attributions */
export type LabsGqlInvoiceAdjustmentAttributionBase = {
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlInvoiceAdjustmentAttributionDto = LabsGqlInvoiceItemAdjustmentAttribution | LabsGqlOrderAdjustmentAttribution | LabsGqlOrderItemAdjustmentAttribution;

export enum LabsGqlInvoiceAdjustmentAttributionType {
  InvoiceItem = 'invoice_item',
  Order = 'order',
  OrderItem = 'order_item'
}

export type LabsGqlInvoiceCreditAllocationDto = {
  __typename?: 'InvoiceCreditAllocationDTO';
  amount_cents: Scalars['Int']['output'];
  created_at: Scalars['DateTime']['output'];
  credit?: Maybe<LabsGqlInvoiceCreditDto>;
  credit_id: Scalars['ID']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoice_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlInvoiceCreditDto = {
  __typename?: 'InvoiceCreditDTO';
  amount_issued_cents: Scalars['Int']['output'];
  attribution?: Maybe<LabsGqlInvoiceAdjustmentAttributionDto>;
  balance_cents: Scalars['Int']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by_user_id?: Maybe<Scalars['String']['output']>;
  credit_category_id?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  organization_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlInvoiceDto = {
  __typename?: 'InvoiceDTO';
  /** Final amount after credits and balance applied. Not affected by payments */
  amount_due: Scalars['Int']['output'];
  /** Amount that the partner has paid */
  amount_paid: Scalars['Int']['output'];
  /** Amount that the partner still needs to pay */
  amount_remaining: Scalars['Int']['output'];
  card_decline_email_sent: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  credit_allocations: Array<LabsGqlInvoiceCreditAllocationDto>;
  due_date: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Unique, human-readable identifier for the invoice derived from the creation timestamp */
  invoice_number?: Maybe<Scalars['String']['output']>;
  metadata: LabsGqlInvoiceMetadataDto;
  organization_id: Scalars['String']['output'];
  payments: Array<LabsGqlInvoicePaymentDto>;
  period_end: Scalars['DateTime']['output'];
  period_start: Scalars['DateTime']['output'];
  receipt_email_sent: Scalars['Boolean']['output'];
  status: LabsGqlStripeInvoiceStatus;
  summary_email_sent: Scalars['Boolean']['output'];
  /** Total amount of invoice. */
  total: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlInvoiceItem = LabsGqlInvoiceItemBase & {
  __typename?: 'InvoiceItem';
  /** This differs from stripe, will always be negative for credits and positive for charges, no matter the object type */
  amount_cents: Scalars['Int']['output'];
  category: LabsGqlInvoiceItemCategory;
  description?: Maybe<Scalars['String']['output']>;
  /** This ID will only exist when the invoice item is a manually added item (from the PracticeBillingRecurringItemRepo). */
  manual_charge_item_id?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  stripe_object: LabsGqlInvoiceItemStripeObject;
  stripe_object_id: Scalars['String']['output'];
  /** This field will only exist when the invoice item is a manually added item (from the PracticeBillingRecurringItemRepo). */
  subcategory?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  /** Invoice id. Maps to either a PartnerBillingCredit or Invoice with a corresponding charge */
  used_credit_id?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlInvoiceItemAdjustmentAttribution = LabsGqlInvoiceAdjustmentAttributionBase & {
  __typename?: 'InvoiceItemAdjustmentAttribution';
  invoice_id: Scalars['ID']['output'];
  invoice_item_id: Scalars['ID']['output'];
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlInvoiceItemAdjustmentAttributionInput = {
  amount_cents: Scalars['Int']['input'];
  invoice_id: Scalars['ID']['input'];
  invoice_item_id: Scalars['ID']['input'];
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlInvoiceItemBase = {
  /** This differs from stripe, will always be negative for credits and positive for charges, no matter the object type */
  amount_cents: Scalars['Int']['output'];
  category: LabsGqlInvoiceItemCategory;
  description?: Maybe<Scalars['String']['output']>;
  /** This ID will only exist when the invoice item is a manually added item (from the PracticeBillingRecurringItemRepo). */
  manual_charge_item_id?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  stripe_object: LabsGqlInvoiceItemStripeObject;
  /** This field will only exist when the invoice item is a manually added item (from the PracticeBillingRecurringItemRepo). */
  subcategory?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  /** Invoice id. Maps to either a PartnerBillingCredit or Invoice with a corresponding charge */
  used_credit_id?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlInvoiceItemCategory {
  CreditCardProcessingFee = 'credit_card_processing_fee',
  OrderPlaced = 'order_placed',
  OrderRefund = 'order_refund',
  OtherCharge = 'other_charge',
  OtherCredit = 'other_credit',
  PrepaymentCharged = 'prepayment_charged',
  PrepaymentUsed = 'prepayment_used',
  UnusedMinimumCharge = 'unused_minimum_charge'
}

export type LabsGqlInvoiceItemDto = {
  __typename?: 'InvoiceItemDTO';
  /** This differs from stripe, will always be negative for credits and positive for charges, no matter the object type */
  amount_cents: Scalars['Int']['output'];
  category: LabsGqlInvoiceItemCategory;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Tiered rate discount amount */
  discount_cents: Scalars['Int']['output'];
  doctor_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice_id: Scalars['ID']['output'];
  invoiced_at?: Maybe<Scalars['DateTime']['output']>;
  item_type: LabsGqlInvoiceItemStripeObject;
  mailing_address_id?: Maybe<Scalars['String']['output']>;
  order_created_at?: Maybe<Scalars['DateTime']['output']>;
  order_id?: Maybe<Scalars['ID']['output']>;
  recurring_item_id?: Maybe<Scalars['String']['output']>;
  sales_tax_amount_cents?: Maybe<Scalars['Int']['output']>;
  subcategory?: Maybe<Scalars['String']['output']>;
  /** Original amount_cents minus discount */
  subtotal_cents?: Maybe<Scalars['Int']['output']>;
  /** Subtotal with sales tax */
  total_cents?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['DateTime']['output'];
  used_credit_attribution_description?: Maybe<Scalars['String']['output']>;
  used_credit_attribution_key?: Maybe<Scalars['String']['output']>;
  /** The ID of the InvoiceCredit used */
  used_credit_id?: Maybe<Scalars['ID']['output']>;
};

export enum LabsGqlInvoiceItemStripeObject {
  CreditNoteLineItem = 'credit_note_line_item',
  Invoiceitem = 'invoiceitem'
}

export type LabsGqlInvoiceMetadataDto = {
  __typename?: 'InvoiceMetadataDTO';
  achievedTierNumber?: Maybe<Scalars['Int']['output']>;
  poweredByDandyHardware?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlInvoicePaymentAllocationDto = {
  __typename?: 'InvoicePaymentAllocationDTO';
  amount_cents: Scalars['Int']['output'];
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoice_id: Scalars['ID']['output'];
  original_amount_cents?: Maybe<Scalars['Int']['output']>;
  payment_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlInvoicePaymentAttemptLog = {
  __typename?: 'InvoicePaymentAttemptLog';
  amount_cents: Scalars['Int']['output'];
  charge_id?: Maybe<Scalars['String']['output']>;
  configuration?: Maybe<LabsGqlPartnerPaymentSplitConfig>;
  created_at: Scalars['DateTime']['output'];
  payment_source_id?: Maybe<Scalars['String']['output']>;
  refunds: Array<LabsGqlInvoicePaymentRefund>;
  status?: Maybe<LabsGqlStripeChargeStatus>;
  triggered_by_user_id?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlInvoicePaymentDto = {
  __typename?: 'InvoicePaymentDTO';
  allocations: Array<LabsGqlInvoicePaymentAllocationDto>;
  /** This differs from stripe, will always be negative for credits and positive for charges, no matter the object type */
  amount_cents: Scalars['Int']['output'];
  created_at: Scalars['DateTime']['output'];
  error_summary?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  organization_id: Scalars['ID']['output'];
  /** The original amount of the payment, before any refunds (which decrease amount_cents) */
  original_amount_cents?: Maybe<Scalars['Int']['output']>;
  refunds: Array<LabsGqlPaymentRefundDto>;
  status: LabsGqlPaymentStatus;
  stripe_charge_id?: Maybe<Scalars['String']['output']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']['output']>;
  stripe_payment_source_id?: Maybe<Scalars['String']['output']>;
  triggered_by_user_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlInvoicePaymentRefund = {
  __typename?: 'InvoicePaymentRefund';
  created_at: Scalars['DateTime']['output'];
  created_by_user_email: Scalars['String']['output'];
  created_by_user_id: Scalars['String']['output'];
  refund_amount_cents: Scalars['Int']['output'];
  refund_reason: Scalars['String']['output'];
  status: Scalars['String']['output'];
  stripe_refund_id: Scalars['String']['output'];
};

export type LabsGqlInvoicePaymentWithInvoiceIdDto = {
  __typename?: 'InvoicePaymentWithInvoiceIdDTO';
  allocations: Array<LabsGqlInvoicePaymentAllocationDto>;
  /** This differs from stripe, will always be negative for credits and positive for charges, no matter the object type */
  amount_cents: Scalars['Int']['output'];
  created_at: Scalars['DateTime']['output'];
  error_summary?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice_id: Scalars['ID']['output'];
  organization_id: Scalars['ID']['output'];
  /** The original amount of the payment, before any refunds (which decrease amount_cents) */
  original_amount_cents?: Maybe<Scalars['Int']['output']>;
  refunds: Array<LabsGqlPaymentRefundDto>;
  status: LabsGqlPaymentStatus;
  stripe_charge_id?: Maybe<Scalars['String']['output']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']['output']>;
  stripe_payment_source_id?: Maybe<Scalars['String']['output']>;
  triggered_by_user_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlInvoiceWithItemSumaryDto = {
  __typename?: 'InvoiceWithItemSumaryDTO';
  /** Final amount after credits and balance applied. Not affected by payments */
  amount_due: Scalars['Int']['output'];
  /** Amount that the partner has paid */
  amount_paid: Scalars['Int']['output'];
  /** Amount that the partner still needs to pay */
  amount_remaining: Scalars['Int']['output'];
  card_decline_email_sent: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  credit_allocations: Array<LabsGqlInvoiceCreditAllocationDto>;
  due_date: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Unique, human-readable identifier for the invoice derived from the creation timestamp */
  invoice_number?: Maybe<Scalars['String']['output']>;
  is_overdue: Scalars['Boolean']['output'];
  /** The count of all `order_placed` invoice items associated to this invoice. */
  items_order_count: Scalars['Int']['output'];
  /** The sum (`amount_cents`) of all `order_placed` invoice items associated to this invoice. */
  items_total_amount_cents: Scalars['Int']['output'];
  metadata: LabsGqlInvoiceMetadataDto;
  organization_id: Scalars['String']['output'];
  payments: Array<LabsGqlInvoicePaymentDto>;
  /** The sum of all `pending` invoice payments associated to this invoice. */
  pending_payment_amount_cents: Scalars['Int']['output'];
  period_end: Scalars['DateTime']['output'];
  period_start: Scalars['DateTime']['output'];
  receipt_email_sent: Scalars['Boolean']['output'];
  status: LabsGqlStripeInvoiceStatus;
  summary_email_sent: Scalars['Boolean']['output'];
  /** Total amount of invoice. */
  total: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlIssueAttributedRefundsInput = {
  invoice_item_attributions: Array<LabsGqlRefundInvoiceItemAttributionInput>;
  order_attributions: Array<LabsGqlRefundOrderAttributionInput>;
  order_item_attributions: Array<LabsGqlRefundOrderItemAttributionInput>;
  refund_category_id?: InputMaybe<Scalars['String']['input']>;
  refund_reason?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlItemDetails = {
  __typename?: 'ItemDetails';
  display_price?: Maybe<Scalars['String']['output']>;
  item_id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LabsGqlItemPhotosInput = {
  item_id: Scalars['String']['input'];
  photo_paths?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LabsGqlIteroCompanyEntity = {
  __typename?: 'IteroCompanyEntity';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  itero_company_id: Scalars['String']['output'];
  practice_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlIteroOrderEntity = {
  __typename?: 'IteroOrderEntity';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  /** This is the ID from our own itero_company table, which you can use to find the company id from iTero or the organization id from Dandy for that company */
  itero_company_id: Scalars['String']['output'];
  /** This is the iTero Order Id. If you use their portal to find an order, this is the ID to use. */
  itero_order_id: Scalars['String']['output'];
  /** This is the iTero Rx Id field, which you can also see on their portal. This is the main ID used to pull assets from their API. */
  itero_rx_id: Scalars['String']['output'];
  /** This is the ID from our own itero_user table, which you can use to then find the doctor id from iTero or the staff member id from Dandy for that user. */
  itero_user_id: Scalars['String']['output'];
  /** This is the ScanExport ID from the Dandy side, after the assets have been uploaded to our cloud. */
  scan_export_id?: Maybe<Scalars['String']['output']>;
  /** This is the status of the order for our integration (not the status of the lab order or the itero order). This indicates whether the scan was received, downloaded, uploaded, made ready for checkout, etc. */
  status: LabsGqlIteroOrderStatus;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlIteroOrderNotification = {
  __typename?: 'IteroOrderNotification';
  itero_order: LabsGqlIteroOrderEntity;
  /** For orders that can be reviewed, this is the formatted deadline time at which the order will automatically get placed unless the doctor place it themselves. For all orders that can not be reviewed, the field will be undefined. */
  review_deadline_time?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlIteroOrderStatus {
  AwaitingOptionalDoctorCheckout = 'AwaitingOptionalDoctorCheckout',
  DoNotQualifyForAutomaticPlacement = 'DoNotQualifyForAutomaticPlacement',
  Ordered = 'Ordered',
  ReadyForAutomaticPlacement = 'ReadyForAutomaticPlacement',
  ReadyForIngestion = 'ReadyForIngestion'
}

export type LabsGqlIteroUserEntity = {
  __typename?: 'IteroUserEntity';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  itero_doctor_id: Scalars['String']['output'];
  should_immediately_place_order: Scalars['Boolean']['output'];
  staff_member_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlIteroUserSettings = {
  __typename?: 'IteroUserSettings';
  itero_company_id: Scalars['String']['output'];
  itero_doctor_id: Scalars['String']['output'];
  should_immediately_place_order?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlKeyedSlaStages = {
  __typename?: 'KeyedSlaStages';
  Buffer: LabsGqlSlaStage;
  Design: LabsGqlSlaStage;
  DesignPrep?: Maybe<LabsGqlSlaStage>;
  DesignQc?: Maybe<LabsGqlSlaStage>;
  Fabrication: LabsGqlSlaStage;
  NeedsReview?: Maybe<LabsGqlSlaStage>;
  ProcessingStart: LabsGqlSlaStage;
  ReadyForDesign: LabsGqlSlaStage;
  ReadyForFabrication: LabsGqlSlaStage;
  ShipToDandy: LabsGqlSlaStage;
  ShipToPractice: LabsGqlSlaStage;
  WaxupReview: LabsGqlSlaStage;
};

export type LabsGqlKustomerConversation = {
  __typename?: 'KustomerConversation';
  channels: Array<Scalars['String']['output']>;
  close_note?: Maybe<Scalars['String']['output']>;
  close_reason?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  customer_id?: Maybe<Scalars['String']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order_title?: Maybe<Scalars['String']['output']>;
  preview?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlKustomerSyncStatusDto = {
  __typename?: 'KustomerSyncStatusDTO';
  external_id?: Maybe<Scalars['String']['output']>;
  status: LabsGqlChatExternalSyncStatus;
};

export type LabsGqlLabBulkOverrideOrderUnitPriceArgs = {
  overrides: Array<LabsGqlLabOverrideOrderUnitPriceArgs>;
};

export type LabsGqlLabBulkOverrideOrderUnitPriceResult = {
  __typename?: 'LabBulkOverrideOrderUnitPriceResult';
  errors: Array<Scalars['String']['output']>;
};

export type LabsGqlLabCaseAssignmentsByOrderIdResult = {
  __typename?: 'LabCaseAssignmentsByOrderIdResult';
  accepted_at?: Maybe<Scalars['DateTime']['output']>;
  assigned_at?: Maybe<Scalars['DateTime']['output']>;
  canceled_at?: Maybe<Scalars['DateTime']['output']>;
  delivered_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  manufacturer_id: Scalars['String']['output'];
  /** If canceled, the percentage that was completed by the lab, as a number between 0-100 */
  percentage_completed?: Maybe<Scalars['Float']['output']>;
  shipped_at?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlLabCaseCompletionDto = {
  __typename?: 'LabCaseCompletionDTO';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lab_case_id: Scalars['String']['output'];
  /** If canceled, the percentage that was completed by the lab, as a number between 0-100 */
  percentage_completed?: Maybe<Scalars['Float']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlLabCaseDateRangeInput = {
  begin: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
};

export type LabsGqlLabCaseLedgerDto = {
  __typename?: 'LabCaseLedgerDTO';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  /** Which order fulfillment number is this for (zero indexed) */
  fulfillment_number: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lab_id: Scalars['ID']['output'];
  order_id: Scalars['ID']['output'];
  prices: Array<LabsGqlLabPriceLedgerPriceDto>;
  reviewed: Scalars['Boolean']['output'];
  ship_date: Scalars['DateTime']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlLabMetalCostCreateConfigInput = {
  metal_id: Scalars['String']['input'];
  price_cents?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlLabMetalCostDto = {
  __typename?: 'LabMetalCostDTO';
  created_at: Scalars['DateTime']['output'];
  effective_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lab_id: Scalars['String']['output'];
  metal_id: Scalars['String']['output'];
  price_cents?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlLabMetalCostQueryDto = {
  __typename?: 'LabMetalCostQueryDTO';
  current?: Maybe<LabsGqlLabMetalCostQueryPerAggDto>;
  metal_id: Scalars['String']['output'];
  metal_name: Scalars['String']['output'];
  upcoming: LabsGqlLabMetalCostQueryPerAggDto;
};

export type LabsGqlLabMetalCostQueryPerAggDto = {
  __typename?: 'LabMetalCostQueryPerAggDTO';
  id: Scalars['String']['output'];
  price_cents?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlLabMetalCostUpsertInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  metal_id: Scalars['String']['input'];
  price_cents?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlLabOrderAttachment = {
  __typename?: 'LabOrderAttachment';
  /** @deprecated Has not been used since early 2021, and only exists for a small handful of items. */
  custom_attachment_id?: Maybe<Scalars['String']['output']>;
  filepath: Scalars['String']['output'];
};

export type LabsGqlLabOrderAttachmentInput = {
  custom_attachment_id?: InputMaybe<Scalars['String']['input']>;
  filepath: Scalars['String']['input'];
};

export enum LabsGqlLabOrderCommandName {
  AddLabOrderPhotos = 'AddLabOrderPhotos',
  AddLabOrderPhotosWithNotes = 'AddLabOrderPhotosWithNotes',
  AddLabSlipAlert = 'AddLabSlipAlert',
  AddManualQualityControlItem = 'AddManualQualityControlItem',
  AddOrderItem = 'AddOrderItem',
  AddOrderItemsV2 = 'AddOrderItemsV2',
  AddRetainerToAlignerCase = 'AddRetainerToAlignerCase',
  AssignFulfillmentTask = 'AssignFulfillmentTask',
  AttachDesignFiles = 'AttachDesignFiles',
  AttachExternalAlignerFulfillment = 'AttachExternalAlignerFulfillment',
  AttachQcPhotos = 'AttachQcPhotos',
  AttachTreatmentPlanRequest = 'AttachTreatmentPlanRequest',
  CancelAndResubmit = 'CancelAndResubmit',
  ChangeDeliveryAddress = 'ChangeDeliveryAddress',
  ChangeDesignSoftware = 'ChangeDesignSoftware',
  ChangeLabOrderManufacturer = 'ChangeLabOrderManufacturer',
  CompleteDesignPrepTask = 'CompleteDesignPrepTask',
  CompleteDesignReviewTask = 'CompleteDesignReviewTask',
  CompleteExternalDesignVerificationTask = 'CompleteExternalDesignVerificationTask',
  CompleteInternalDesignTask = 'CompleteInternalDesignTask',
  CompleteModelDesignTask = 'CompleteModelDesignTask',
  CompleteQualityControlItem = 'CompleteQualityControlItem',
  CompleteTreatmentPlanConversion = 'CompleteTreatmentPlanConversion',
  ConfigureDesignReview2Command = 'ConfigureDesignReview2Command',
  ConfigureOrderDesignReview = 'ConfigureOrderDesignReview',
  ConfigureOrderDesignerType = 'ConfigureOrderDesignerType',
  ConfigureOrderWaxup = 'ConfigureOrderWaxup',
  ContinueDenturesFulfillmentWaxRim = 'ContinueDenturesFulfillmentWaxRim',
  CreateFulfillment = 'CreateFulfillment',
  CreateOrderHold = 'CreateOrderHold',
  DeleteOrderItem = 'DeleteOrderItem',
  EditAlignerCase = 'EditAlignerCase',
  EditDoctorNote = 'EditDoctorNote',
  EditRefabrication = 'EditRefabrication',
  EditRefabricationReasons = 'EditRefabricationReasons',
  ExternalUserCancelOrder = 'ExternalUserCancelOrder',
  FlagOrderForReview = 'FlagOrderForReview',
  IgnoreQualityControlItem = 'IgnoreQualityControlItem',
  LabOrderQcStationFail = 'LabOrderQcStationFail',
  LabOrderQcStationPass = 'LabOrderQcStationPass',
  LinkOrderToRefabrication = 'LinkOrderToRefabrication',
  MarkDelivered = 'MarkDelivered',
  MarkNeedsRefabrication = 'MarkNeedsRefabrication',
  MarkShipped = 'MarkShipped',
  ModifyOrderSla = 'ModifyOrderSla',
  OpsCancelOrder = 'OpsCancelOrder',
  OptOutProductionOrder = 'OptOutProductionOrder',
  OverrideDentistAmountDue = 'OverrideDentistAmountDue',
  OverrideNonProdManufacturer = 'OverrideNonProdManufacturer',
  PlaceDenturesFulfillmentCore = 'PlaceDenturesFulfillmentCore',
  PlaceDenturesFulfillmentWaxRim = 'PlaceDenturesFulfillmentWaxRim',
  PlaceLabOrderFromTryIn = 'PlaceLabOrderFromTryIn',
  PrintableLabSlipViewed = 'PrintableLabSlipViewed',
  RecordDesignDownload = 'RecordDesignDownload',
  RecordPackingSheetViewed = 'RecordPackingSheetViewed',
  RemoveFulfillmentShipping = 'RemoveFulfillmentShipping',
  RemoveLabSlipAlerts = 'RemoveLabSlipAlerts',
  RemoveOrderHold = 'RemoveOrderHold',
  ReplaceThreeoxz = 'ReplaceThreeoxz',
  RequestOrderRefinement = 'RequestOrderRefinement',
  RerouteLabOrder = 'RerouteLabOrder',
  ResolveOrderReviewFlag = 'ResolveOrderReviewFlag',
  SaveLabSlipAlerts = 'SaveLabSlipAlerts',
  SendOrderToFloor = 'SendOrderToFloor',
  SetCbctScanUrl = 'SetCbctScanUrl',
  SetDoctorPreferencesId = 'SetDoctorPreferencesID',
  SetSmileStyle = 'SetSmileStyle',
  StartFulfillmentTask = 'StartFulfillmentTask',
  SubmitQcFlag = 'SubmitQcFlag',
  SubmitWaxupReview = 'SubmitWaxupReview',
  UndoLinkOrderToRefabrication = 'UndoLinkOrderToRefabrication',
  UpdateCurrentRetainer = 'UpdateCurrentRetainer',
  UpdateOrderClinicalSupportNotes = 'UpdateOrderClinicalSupportNotes',
  UpdateOrderHold = 'UpdateOrderHold',
  UpdateOrderItemPennyweightsV2 = 'UpdateOrderItemPennyweightsV2',
  UpdateOrderItemsV2 = 'UpdateOrderItemsV2',
  UpdateOrderItemsV2Practice = 'UpdateOrderItemsV2Practice',
  UpdatePartialDentureMetadata = 'UpdatePartialDentureMetadata'
}

export type LabsGqlLabOrderCondition = {
  __typename?: 'LabOrderCondition';
  AllItems?: Maybe<LabsGqlLabOrderItemCondition>;
  And?: Maybe<Array<LabsGqlLabOrderCondition>>;
  AnyItem?: Maybe<LabsGqlLabOrderItemCondition>;
  Doctor?: Maybe<LabsGqlLabOrderConditionRelation>;
  InternalDesign?: Maybe<Scalars['Boolean']['output']>;
  Manufacturer?: Maybe<LabsGqlLabOrderConditionRelation>;
  MaxUnitCount?: Maybe<Scalars['Int']['output']>;
  MinRefabCount?: Maybe<Scalars['Int']['output']>;
  MinUnitCount?: Maybe<Scalars['Int']['output']>;
  Not?: Maybe<LabsGqlLabOrderCondition>;
  Or?: Maybe<Array<LabsGqlLabOrderCondition>>;
  PracticeId?: Maybe<Array<Scalars['String']['output']>>;
  PracticeTags?: Maybe<Array<Scalars['String']['output']>>;
};

export type LabsGqlLabOrderConditionRelation = {
  __typename?: 'LabOrderConditionRelation';
  id: Scalars['String']['output'];
};

export enum LabsGqlLabOrderConditionType {
  AllItems = 'AllItems',
  And = 'And',
  AnyItem = 'AnyItem',
  Doctor = 'Doctor',
  InternalDesign = 'InternalDesign',
  Manufacturer = 'Manufacturer',
  MaxUnitCount = 'MaxUnitCount',
  MinRefabCount = 'MinRefabCount',
  MinUnitCount = 'MinUnitCount',
  Not = 'Not',
  Or = 'Or',
  PracticeId = 'PracticeId',
  PracticeTags = 'PracticeTags'
}

export type LabsGqlLabOrderConditionTypeClass = {
  __typename?: 'LabOrderConditionTypeClass';
  type: LabsGqlLabOrderConditionType;
};

export type LabsGqlLabOrderCreationEventItemTooth = {
  __typename?: 'LabOrderCreationEventItemTooth';
  amount_due_cents?: Maybe<Scalars['Int']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  material?: Maybe<Scalars['String']['output']>;
  unit_type: Scalars['String']['output'];
  /** UNN tooth numbering from 1-32 */
  unn: Scalars['String']['output'];
};

export type LabsGqlLabOrderDoctorPhoto = {
  __typename?: 'LabOrderDoctorPhoto';
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type LabsGqlLabOrderForLabSlip = {
  __typename?: 'LabOrderForLabSlip';
  batch_ids?: Maybe<Array<Scalars['String']['output']>>;
  clinical_support_notes?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  delivery_address_alias?: Maybe<Scalars['String']['output']>;
  dentures_fulfillment?: Maybe<LabsGqlDenturesFulfillmentDto>;
  design?: Maybe<LabsGqlOrderDesign>;
  design_revision?: Maybe<LabsGqlDesignOrderDesignRevisionDto>;
  doctor_name: Scalars['String']['output'];
  doctor_notes?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_multi_refab: Scalars['Boolean']['output'];
  /** @deprecated Dont use, no longer implemented */
  is_premium: Scalars['Boolean']['output'];
  items_v2: Array<LabsGqlLabOrderItemV2>;
  lab_slip_alerts: Array<LabsGqlLabSlipAlert>;
  labtrac_id?: Maybe<Scalars['String']['output']>;
  manufacturer_sla: LabsGqlOrderSlaForLabSlip;
  needs_refabrication_date?: Maybe<Scalars['DateTime']['output']>;
  notes_for_refabrication?: Maybe<Scalars['String']['output']>;
  order_metadata?: Maybe<LabsGqlOrderMetadataForLabSlipDto>;
  order_number: Scalars['String']['output'];
  original_order_id?: Maybe<Scalars['String']['output']>;
  panCodeCount: Scalars['Float']['output'];
  partials_fulfillment?: Maybe<LabsGqlPartialsFulfillmentDto>;
  patient: LabsGqlPatientForLabSlip;
  practice_name?: Maybe<Scalars['String']['output']>;
  service_level_speed?: Maybe<LabsGqlLabSalesOrderServiceSpeed>;
  stackAndPanCodes?: Maybe<LabsGqlStackAndPanCodesDto>;
  try_in_feedback_details?: Maybe<Array<LabsGqlTryInFeedbackDetailForLabSlip>>;
};

export type LabsGqlLabOrderFulfillmentDto = {
  __typename?: 'LabOrderFulfillmentDTO';
  current: LabsGqlLabOrderSingleFulfillmentDto;
  history: Array<LabsGqlLabOrderSingleFulfillmentDto>;
};

export enum LabsGqlLabOrderFulfillmentStatus {
  Delivered = 'Delivered',
  New = 'New',
  Shipped = 'Shipped',
  Started = 'Started'
}

export type LabsGqlLabOrderHoldDetails = {
  __typename?: 'LabOrderHoldDetails';
  hold_category: Scalars['String']['output'];
  hold_created_at?: Maybe<Scalars['DateTime']['output']>;
  hold_freetext?: Maybe<Scalars['String']['output']>;
  hold_id?: Maybe<Scalars['String']['output']>;
  hold_is_practice_managed_pause?: Maybe<Scalars['Boolean']['output']>;
  hold_subcategory?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlLabOrderHoldDetailsHistoryElement = {
  __typename?: 'LabOrderHoldDetailsHistoryElement';
  /** The delay days added to SLA because of this hold */
  delay_days?: Maybe<Scalars['Float']['output']>;
  hold_category: Scalars['String']['output'];
  hold_created_at?: Maybe<Scalars['DateTime']['output']>;
  hold_freetext?: Maybe<Scalars['String']['output']>;
  hold_id?: Maybe<Scalars['String']['output']>;
  hold_is_practice_managed_pause?: Maybe<Scalars['Boolean']['output']>;
  hold_removed_at?: Maybe<Scalars['DateTime']['output']>;
  hold_shown_to_practice?: Maybe<Scalars['Boolean']['output']>;
  hold_subcategory?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlLabOrderItem = {
  __typename?: 'LabOrderItem';
  attachments?: Maybe<Array<LabsGqlLabOrderAttachment>>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  link_metadata?: Maybe<LabsGqlLabOrderItemLinkMetadata>;
  link_type?: Maybe<LabsGqlOrderItemLinkType>;
  material?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  partial_denture_metadata?: Maybe<LabsGqlLabOrderItemPartialDentureMetadata>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  removable_metadata?: Maybe<LabsGqlLabOrderItemRemovableMetadata>;
  shades?: Maybe<Array<LabsGqlLabOrderItemShade>>;
  sku: LabsGqlOrderItemSkuType;
  teeth: Array<LabsGqlLabOrderItemTooth>;
};

export type LabsGqlLabOrderItemCondition = {
  __typename?: 'LabOrderItemCondition';
  AbutmentPartIsOEM?: Maybe<Scalars['Boolean']['output']>;
  AbutmentPartManufacturer?: Maybe<LabsGqlLabOrderItemConditionValues>;
  And?: Maybe<Array<LabsGqlLabOrderItemCondition>>;
  FieldValue?: Maybe<LabsGqlLabOrderItemConditionFieldValues>;
  MaterialType?: Maybe<LabsGqlLabOrderItemConditionValues>;
  MaxUnitsPerItem?: Maybe<Scalars['Float']['output']>;
  Not?: Maybe<LabsGqlLabOrderItemCondition>;
  Or?: Maybe<Array<LabsGqlLabOrderItemCondition>>;
  ScanbodyManufacturer?: Maybe<LabsGqlLabOrderItemConditionValues>;
  Shade?: Maybe<LabsGqlLabOrderItemConditionValues>;
  Sku?: Maybe<LabsGqlLabOrderItemConditionValues>;
  ToothGroups?: Maybe<LabsGqlLabOrderItemConditionValues>;
  UnitType?: Maybe<LabsGqlLabOrderItemConditionValues>;
};

export type LabsGqlLabOrderItemConditionFieldValues = {
  __typename?: 'LabOrderItemConditionFieldValues';
  field_id: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export enum LabsGqlLabOrderItemConditionType {
  AbutmentPartIsOem = 'AbutmentPartIsOEM',
  AbutmentPartManufacturer = 'AbutmentPartManufacturer',
  And = 'And',
  FieldValue = 'FieldValue',
  MaterialType = 'MaterialType',
  MaxUnitsPerItem = 'MaxUnitsPerItem',
  Not = 'Not',
  Or = 'Or',
  ScanbodyManufacturer = 'ScanbodyManufacturer',
  Shade = 'Shade',
  Sku = 'Sku',
  ToothGroups = 'ToothGroups',
  UnitType = 'UnitType'
}

export type LabsGqlLabOrderItemConditionTypeClass = {
  __typename?: 'LabOrderItemConditionTypeClass';
  type: LabsGqlLabOrderItemConditionType;
};

export type LabsGqlLabOrderItemConditionValues = {
  __typename?: 'LabOrderItemConditionValues';
  values: Array<Scalars['String']['output']>;
};

export type LabsGqlLabOrderItemInput = {
  attachments?: InputMaybe<Array<LabsGqlLabOrderAttachmentInput>>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  link_metadata?: InputMaybe<LabsGqlLabOrderItemLinkMetadataInput>;
  link_type?: InputMaybe<LabsGqlOrderItemLinkType>;
  material?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  partial_denture_metadata?: InputMaybe<LabsGqlLabOrderItemPartialDentureMetadataInput>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  removable_metadata?: InputMaybe<LabsGqlLabOrderItemRemovableMetadataInput>;
  shades?: InputMaybe<Array<LabsGqlLabOrderItemShadeInput>>;
  teeth: Array<LabsGqlLabOrderItemToothInput>;
};

export type LabsGqlLabOrderItemLinkMetadata = {
  __typename?: 'LabOrderItemLinkMetadata';
  /** Replace manufacturer and generic_manufacturer for more accurate abutment pricing */
  abutment_part_manufacturer?: Maybe<Scalars['String']['output']>;
  /** Whether this implant item should use authentic/OEM parts. Set internally post-checkout. */
  authentic?: Maybe<Scalars['Boolean']['output']>;
  connection?: Maybe<Scalars['String']['output']>;
  connection_type?: Maybe<Scalars['String']['output']>;
  /** If generic, the generic parts manufacturer. Set internally post-checkout. */
  generic_manufacturer?: Maybe<LabsGqlOrderItemLinkGenericManufacturer>;
  /** List of scan body<>tooth used by implants in the associated item. Null for non-implant items or old clients. */
  implant_scanbody_usages?: Maybe<Array<LabsGqlImplantToothScanbodyUsage>>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  non_preferred_part_reason?: Maybe<Scalars['String']['output']>;
  part_id?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<LabsGqlOrderItemLinkRelationship>;
  system?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlLabOrderItemLinkMetadataInput = {
  /** Replace manufacturer and generic_manufacturer for more accurate abutment pricing */
  abutment_part_manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** Whether this implant item should use authentic/OEM parts. Set internally post-checkout. */
  authentic?: InputMaybe<Scalars['Boolean']['input']>;
  connection?: InputMaybe<Scalars['String']['input']>;
  connection_type?: InputMaybe<Scalars['String']['input']>;
  /** If generic, the generic parts manufacturer. Set internally post-checkout. */
  generic_manufacturer?: InputMaybe<LabsGqlOrderItemLinkGenericManufacturer>;
  /** List of scan body<>tooth used by implants in the associated item. Null for non-implant items or old clients. */
  implant_scanbody_usages?: InputMaybe<Array<LabsGqlImplantToothScanbodyUsageInput>>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  non_preferred_part_reason?: InputMaybe<Scalars['String']['input']>;
  part_id?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<LabsGqlOrderItemLinkRelationship>;
  system?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlLabOrderItemPartialDentureMetadata = {
  __typename?: 'LabOrderItemPartialDentureMetadata';
  /** @deprecated Use clasps */
  clasp_type?: Maybe<LabsGqlOrderItemPartialDentureClaspType>;
  clasps?: Maybe<Array<LabsGqlOrderItemPartialDentureClasp>>;
  coverage_type?: Maybe<LabsGqlOrderItemPartialDentureCoverageType>;
};

export type LabsGqlLabOrderItemPartialDentureMetadataInput = {
  clasp_type?: InputMaybe<LabsGqlOrderItemPartialDentureClaspType>;
  clasps?: InputMaybe<Array<LabsGqlOrderItemPartialDentureClaspInput>>;
  coverage_type?: InputMaybe<LabsGqlOrderItemPartialDentureCoverageType>;
};

export type LabsGqlLabOrderItemRemovableMetadata = {
  __typename?: 'LabOrderItemRemovableMetadata';
  clasps?: Maybe<Array<LabsGqlOrderItemRemovableClasp>>;
};

export type LabsGqlLabOrderItemRemovableMetadataInput = {
  clasps?: InputMaybe<Array<LabsGqlOrderItemRemovableClaspInput>>;
};

export type LabsGqlLabOrderItemShade = {
  __typename?: 'LabOrderItemShade';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LabsGqlLabOrderItemShadeInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type LabsGqlLabOrderItemTooth = {
  __typename?: 'LabOrderItemTooth';
  amount_due_cents?: Maybe<Scalars['Int']['output']>;
  /** @deprecated this has been replaced by shades property on LabOrderItem */
  color?: Maybe<Scalars['String']['output']>;
  material?: Maybe<Scalars['String']['output']>;
  override_amount_due_cents?: Maybe<Scalars['Int']['output']>;
  override_amount_due_reason?: Maybe<Scalars['String']['output']>;
  /** The pennyweight of precious metal used to fabricate this item, if specified by the manufacturer. */
  precious_metal_pennyweight?: Maybe<Scalars['Float']['output']>;
  unit_type: Scalars['String']['output'];
  /** UNN tooth numbering from 1-32 */
  unn: Scalars['String']['output'];
};

export type LabsGqlLabOrderItemToothInput = {
  amount_due_cents?: InputMaybe<Scalars['Int']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  material?: InputMaybe<Scalars['String']['input']>;
  override_amount_due_cents?: InputMaybe<Scalars['Int']['input']>;
  override_amount_due_reason?: InputMaybe<Scalars['String']['input']>;
  /** The pennyweight of precious metal used to fabricate this item, if specified by the manufacturer. */
  precious_metal_pennyweight?: InputMaybe<Scalars['Float']['input']>;
  unit_type: Scalars['String']['input'];
  /** UNN tooth numbering from 1-32 */
  unn: Scalars['String']['input'];
};

export type LabsGqlLabOrderItemV2 = LabsGqlAlignerItem | LabsGqlBridgeItem | LabsGqlCrownItem | LabsGqlDentureItem | LabsGqlImplantBridgeItem | LabsGqlImplantItem | LabsGqlInlayItem | LabsGqlModelItem | LabsGqlOtherItem | LabsGqlPartialDentureItem | LabsGqlRemovableItem | LabsGqlSleepApneaItem | LabsGqlSurgicalGuideItem | LabsGqlTmjItem | LabsGqlUnknownItem | LabsGqlVeneerItem | LabsGqlWaxupItem;

export type LabsGqlLabOrderItemV2InputBySku = {
  aligner_items?: InputMaybe<Array<LabsGqlAlignerItemInput>>;
  bridge_items?: InputMaybe<Array<LabsGqlBridgeItemInput>>;
  crown_items?: InputMaybe<Array<LabsGqlCrownItemInput>>;
  denture_items?: InputMaybe<Array<LabsGqlDentureItemInput>>;
  implant_bridge_items?: InputMaybe<Array<LabsGqlImplantBridgeItemInput>>;
  implant_items?: InputMaybe<Array<LabsGqlImplantItemInput>>;
  inlay_items?: InputMaybe<Array<LabsGqlInlayItemInput>>;
  model_items?: InputMaybe<Array<LabsGqlModelItemInput>>;
  other_items?: InputMaybe<Array<LabsGqlOtherItemInput>>;
  partial_denture_items?: InputMaybe<Array<LabsGqlPartialDentureItemInput>>;
  removable_items?: InputMaybe<Array<LabsGqlRemovableItemInput>>;
  sleep_apnea_items?: InputMaybe<Array<LabsGqlSleepApneaItemInput>>;
  surgical_guide_items?: InputMaybe<Array<LabsGqlSurgicalGuideItemInput>>;
  tmj_items?: InputMaybe<Array<LabsGqlTmjItemInput>>;
  unknown_items?: InputMaybe<Array<LabsGqlUnknownItemInput>>;
  veneer_items?: InputMaybe<Array<LabsGqlVeneerItemInput>>;
  waxup_items?: InputMaybe<Array<LabsGqlWaxupItemInput>>;
};

export type LabsGqlLabOrderManufacturerFabricationState = {
  __typename?: 'LabOrderManufacturerFabricationState';
  active: Scalars['Boolean']['output'];
  date_completed?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
};

export enum LabsGqlLabOrderPhotoType {
  Annotation = 'Annotation',
  CloseUp = 'CloseUp',
  FullFace = 'FullFace',
  PatientPhoto = 'PatientPhoto',
  RetractedTeethSeparated = 'RetractedTeethSeparated',
  RetractedTeethTogether = 'RetractedTeethTogether',
  ScanFile = 'ScanFile',
  Side = 'Side',
  SurgicalReport = 'SurgicalReport',
  Xray = 'Xray',
  XrayOfScanbody = 'XrayOfScanbody'
}

export type LabsGqlLabOrderQcStationFailCommand = {
  completed_checklist_item_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  issues_v2: Array<LabsGqlQcIssueResultInputV2>;
  order_id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type LabsGqlLabOrderQcStationPassCommand = {
  completed_checklist_item_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  employee_id?: InputMaybe<Scalars['String']['input']>;
  integration_order_id?: InputMaybe<Scalars['String']['input']>;
  issues_v2: Array<LabsGqlQcIssueResultInputV2>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlLabOrderReasonCodeOption = {
  __typename?: 'LabOrderReasonCodeOption';
  additional_info_request: Array<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  default_fault?: Maybe<LabsGqlReasonCodeFault>;
  form_display?: Maybe<LabsGqlFormDisplay>;
  group?: Maybe<LabsGqlReasonCodeGroup>;
  id: Scalars['String']['output'];
  internal_only: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  requires_return: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type LabsGqlLabOrderRefabRates = {
  __typename?: 'LabOrderRefabRates';
  doctor: Scalars['Float']['output'];
  partner: Scalars['Float']['output'];
};

export type LabsGqlLabOrderRefabReasonOption = {
  __typename?: 'LabOrderRefabReasonOption';
  category?: Maybe<Scalars['String']['output']>;
  default_fault?: Maybe<LabsGqlRefabFault>;
  id: Scalars['String']['output'];
  internal_only: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  requires_return: Scalars['Boolean']['output'];
};

export type LabsGqlLabOrderScanDto = {
  __typename?: 'LabOrderScanDTO';
  export_id: Scalars['String']['output'];
  /** @deprecated Dont use this, its useless */
  issues: Array<Scalars['String']['output']>;
  replaced_export_ids: Array<Scalars['String']['output']>;
};

export type LabsGqlLabOrderShipmentDto = {
  __typename?: 'LabOrderShipmentDTO';
  carrier?: Maybe<Scalars['String']['output']>;
  carrier_account_number?: Maybe<Scalars['String']['output']>;
  created_via?: Maybe<LabsGqlShipmentCreatedViaEnumType>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  eta?: Maybe<Scalars['DateTime']['output']>;
  label_url?: Maybe<Scalars['String']['output']>;
  latest_delivery_attempt_date?: Maybe<Scalars['DateTime']['output']>;
  original_eta?: Maybe<Scalars['DateTime']['output']>;
  package_accepted_date?: Maybe<Scalars['DateTime']['output']>;
  service_level?: Maybe<Scalars['String']['output']>;
  ship_date?: Maybe<Scalars['DateTime']['output']>;
  /** Only null when it has not been shipped yet */
  shipped_by_lab?: Maybe<Scalars['Boolean']['output']>;
  tracking_number?: Maybe<Scalars['String']['output']>;
  tracking_status?: Maybe<Scalars['String']['output']>;
  tracking_status_updated_at?: Maybe<Scalars['DateTime']['output']>;
  tracking_substatus?: Maybe<LabsGqlShipmentSubStatus>;
};

export type LabsGqlLabOrderSingleFulfillmentDto = {
  __typename?: 'LabOrderSingleFulfillmentDTO';
  created_at: Scalars['DateTime']['output'];
  estimated_ship_date?: Maybe<Scalars['DateTime']['output']>;
  inbound_shipment?: Maybe<LabsGqlLabOrderShipmentDto>;
  /** @deprecated Dont use this, its useless */
  manufacturer_fabrication_states: Array<LabsGqlLabOrderManufacturerFabricationState>;
  manufacturer_history: LabsGqlManufacturerOrderHistory;
  manufacturer_sla: LabsGqlOrderSla;
  scan: LabsGqlLabOrderScanDto;
  /** When the order was accepted by the lab */
  sent_to_floor_at?: Maybe<Scalars['DateTime']['output']>;
  shipment?: Maybe<LabsGqlLabOrderShipmentDto>;
  shipping_method_override?: Maybe<LabsGqlPortalShipShippingSpeed>;
  /** When the order was sent to the lab */
  start_date?: Maybe<Scalars['DateTime']['output']>;
  status: LabsGqlLabOrderFulfillmentStatus;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlLabOrderSinglePhotoDto = {
  __typename?: 'LabOrderSinglePhotoDTO';
  fullPath: Scalars['String']['output'];
  sendToChat?: Maybe<Scalars['Boolean']['output']>;
  type: LabsGqlLabOrderPhotoType;
};

export type LabsGqlLabOrderSinglePhotoInput = {
  fullPath: Scalars['String']['input'];
  sendToChat?: InputMaybe<Scalars['Boolean']['input']>;
  type: LabsGqlLabOrderPhotoType;
};

export enum LabsGqlLabOrderSortKey {
  /** @deprecated Not needed, dont use */
  AssignmentFabricationDays = 'assignment_fabrication_days',
  CancelDate = 'cancel_date',
  CreatedAt = 'created_at',
  DeliveryDate = 'delivery_date',
  DentistAmountDueCents = 'dentist_amount_due_cents',
  DueDate = 'due_date',
  FabricationStartDate = 'fabrication_start_date',
  /** @deprecated No longer exists. Will use updated_at instead */
  IntegrationPriceDollars = 'integration_price_dollars',
  /** @deprecated No longer useful and expensive to query, don't use */
  ManufacturerDisplayName = 'manufacturer__display_name',
  NeedsRefabricationDate = 'needs_refabrication_date',
  PatientBirthday = 'patient__birthday',
  PatientFirstName = 'patient__first_name',
  PatientLastName = 'patient__last_name',
  SentToFloorDate = 'sent_to_floor_date',
  ShipDate = 'ship_date',
  SplitOrderCount = 'split_order_count',
  TicketDueAt = 'ticket__due_at',
  UpdatedAt = 'updated_at'
}

export type LabsGqlLabOrderSplitDeltaAddItemInput = {
  /** New item uuid generated client side */
  item_id: Scalars['ID']['input'];
  item_input?: InputMaybe<LabsGqlLabOrderItemInput>;
  /** When provided only the first item found will be used. Clients should only ever intend to provide a single item. */
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
  order_id: Scalars['ID']['input'];
};

export type LabsGqlLabOrderSplitDeltaAddSplitInput = {
  buffer_days?: InputMaybe<Scalars['Int']['input']>;
  design_days?: InputMaybe<Scalars['Int']['input']>;
  fabrication_days?: InputMaybe<Scalars['Int']['input']>;
  manufacturer_id: Scalars['ID']['input'];
  /** New order uuid generated client side */
  order_id: Scalars['ID']['input'];
};

export type LabsGqlLabOrderSplitDeltaChangeManufacturerInput = {
  manufacturer_id: Scalars['ID']['input'];
  order_id: Scalars['ID']['input'];
};

/** Only one of add_split, move_item, add_item, or change_manufacturer should be specified. This is a tagged input type. */
export type LabsGqlLabOrderSplitDeltaInput = {
  add_item?: InputMaybe<LabsGqlLabOrderSplitDeltaAddItemInput>;
  add_split?: InputMaybe<LabsGqlLabOrderSplitDeltaAddSplitInput>;
  change_manufacturer?: InputMaybe<LabsGqlLabOrderSplitDeltaChangeManufacturerInput>;
  move_item?: InputMaybe<LabsGqlLabOrderSplitDeltaMoveItemInput>;
};

export type LabsGqlLabOrderSplitDeltaMoveItemInput = {
  destination_order_id: Scalars['ID']['input'];
  item_id: Scalars['ID']['input'];
  source_order_id: Scalars['ID']['input'];
};

export enum LabsGqlLabOrderStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Fabrication = 'Fabrication',
  NeedsRefabrication = 'NeedsRefabrication',
  NeedsReview = 'NeedsReview',
  New = 'New',
  OnHold = 'OnHold',
  Shipped = 'Shipped'
}

export type LabsGqlLabOrderStatusCount = {
  __typename?: 'LabOrderStatusCount';
  All: Scalars['Int']['output'];
  Cancelled: Scalars['Int']['output'];
  Delivered: Scalars['Int']['output'];
  Fabrication: Scalars['Int']['output'];
  InTransitReturns: Scalars['Int']['output'];
  NeedsOrderFeedback: Scalars['Int']['output'];
  NeedsRefabrication: Scalars['Int']['output'];
  NeedsReview: Scalars['Int']['output'];
  New: Scalars['Int']['output'];
  OnHold: Scalars['Int']['output'];
  Shipped: Scalars['Int']['output'];
  UnshippedReturns: Scalars['Int']['output'];
  UnsubmittedScans: Scalars['Int']['output'];
  WaxupReview: Scalars['Int']['output'];
};

export type LabsGqlLabOrderUpdateSplitsInput = {
  deltas: Array<LabsGqlLabOrderSplitDeltaInput>;
  partner_order_id: Scalars['ID']['input'];
};

export type LabsGqlLabOrderUpdateSplitsResult = {
  __typename?: 'LabOrderUpdateSplitsResult';
  success: Scalars['Boolean']['output'];
};

export enum LabsGqlLabOrderWaxupReviewStatus {
  Approved = 'APPROVED',
  AwaitingDesign = 'AWAITING_DESIGN',
  ReadyForReview = 'READY_FOR_REVIEW'
}

export type LabsGqlLabOrderWithEvents = {
  __typename?: 'LabOrderWithEvents';
  /** The active scan review work order for this lab order. If there are no active scan reviews, returns null. */
  active_scan_review_work_order?: Maybe<LabsGqlScanReviewWorkOrderDto>;
  aligner_case?: Maybe<LabsGqlAlignerCaseDto>;
  allowed_commands: Array<LabsGqlLabOrderCommandName>;
  can_attach_images_or_files?: Maybe<Scalars['Boolean']['output']>;
  can_attach_scans?: Maybe<Scalars['Boolean']['output']>;
  can_cancel?: Maybe<Scalars['Boolean']['output']>;
  can_edit?: Maybe<Scalars['Boolean']['output']>;
  can_leave_notes?: Maybe<Scalars['Boolean']['output']>;
  can_mark_delivered?: Maybe<Scalars['Boolean']['output']>;
  can_put_on_hold?: Maybe<Scalars['Boolean']['output']>;
  can_refabricate?: Maybe<Scalars['Boolean']['output']>;
  can_submit_feedback?: Maybe<Scalars['Boolean']['output']>;
  can_submit_tryin_feedback?: Maybe<Scalars['Boolean']['output']>;
  cancel_date?: Maybe<Scalars['DateTime']['output']>;
  /** Will be set if this order is a resubmitted order (from the cancel & resubmit flow). These are different than refabs, they are triggered from doctors or the ops portal, and allow the users to make any kind of edits to their live orders. */
  cancelled_order_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated Do not use! Will not be supported by sales order system. */
  cancelled_orders?: Maybe<Array<LabsGqlLabOrderWithEvents>>;
  carrier?: Maybe<Scalars['String']['output']>;
  case_readiness_id?: Maybe<Scalars['String']['output']>;
  cbct_url?: Maybe<Scalars['String']['output']>;
  clinical_support_notes?: Maybe<LabsGqlClinicalSupportNotes>;
  created_at: Scalars['DateTime']['output'];
  created_from_return_id?: Maybe<Scalars['String']['output']>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer calculated, dont use */
  delivery_delay?: Maybe<LabsGqlOrderDeliveryDelay>;
  dentist_amount_due_cents?: Maybe<Scalars['Float']['output']>;
  dentures_fulfillment?: Maybe<LabsGqlDenturesFulfillmentDto>;
  /** @deprecated Please use design order revisions */
  design?: Maybe<LabsGqlOrderDesign>;
  design_capability_requirements: Array<LabsGqlOrderDesignCapabilityDto>;
  design_due_date: Scalars['DateTime']['output'];
  design_file_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please utilize practice_dates.digital_design_preview_estimated_completion_date. */
  design_preview_eta?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated This field has been gone from any fragments for 3 months but some old clients still request it. */
  design_qc_capability_requirements: Array<LabsGqlOrderDesignCapabilityDto>;
  /** @deprecated use doctor_preferences { name } */
  doctor_name: Scalars['String']['output'];
  /** List of foreign keys to DoctorNoteCategoryDTO */
  doctor_note_category_ids?: Maybe<Array<Scalars['String']['output']>>;
  doctor_notes?: Maybe<Scalars['String']['output']>;
  doctor_photos: Array<LabsGqlLabOrderDoctorPhoto>;
  doctor_preferences: LabsGqlDoctorPreferences;
  doctor_preferences_id: Scalars['ID']['output'];
  /** @deprecated Use due_date on manufacturer_sla */
  due_date: Scalars['DateTime']['output'];
  /** @deprecated Please utilize practice_dates.estimated_delivery_date. */
  estimated_delivery_date: Scalars['DateTime']['output'];
  fabrication_start_date?: Maybe<Scalars['DateTime']['output']>;
  fulfillment: LabsGqlLabOrderFulfillmentDto;
  fulfillment_workflow: LabsGqlOrderFulfillmentWorkflow;
  hold_details?: Maybe<LabsGqlLabOrderHoldDetails>;
  hold_history: Array<LabsGqlLabOrderHoldDetailsHistoryElement>;
  /** Whether this order is on hold and the doctor has responded to a question. */
  hold_in_review: Scalars['Boolean']['output'];
  /** @deprecated Use hold_details instead */
  hold_reason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Dont use this, its useless */
  integration_order_id?: Maybe<Scalars['String']['output']>;
  /** Whether this order is the 2nd or beyond refab in a chain of refabs. */
  is_multi_refab: Scalars['Boolean']['output'];
  /** When set to true, this order is considered "locked" and is unable to be changed. */
  is_order_locked_for_sales_demo: Scalars['Boolean']['output'];
  /** @deprecated Dont use this, its useless */
  is_premium: Scalars['Boolean']['output'];
  is_training_order: Scalars['Boolean']['output'];
  /** @deprecated Please use items_v2 */
  items: Array<LabsGqlLabOrderItem>;
  items_v2: Array<LabsGqlLabOrderItemV2>;
  /** @deprecated use mailing_address { lab_customer_id } */
  lab_customer_id: Scalars['String']['output'];
  lab_slip_alerts: Array<LabsGqlLabSlipAlert>;
  last_shipment?: Maybe<LabsGqlShipmentDto>;
  /** Contains any validation errors associated with the most recently uploaded design file */
  latest_design_file_validation_errors: Array<LabsGqlDesignFileValidationError>;
  mailing_address: LabsGqlPartnerDeliveryAddress;
  mailing_address_id: Scalars['String']['output'];
  /** @deprecated Dont use this, its useless */
  manufacturer_fabrication_states?: Maybe<Array<LabsGqlLabOrderManufacturerFabricationState>>;
  manufacturer_history: LabsGqlManufacturerOrderHistory;
  manufacturer_id: Scalars['ID']['output'];
  /** The timestamp when the manufacturer was last updated for this order */
  manufacturer_last_updated_date: Scalars['DateTime']['output'];
  /** Due dates for different order stages */
  manufacturer_sla: LabsGqlOrderSla;
  needs_refabrication_date?: Maybe<Scalars['DateTime']['output']>;
  next_fulfillment_order_ids?: Maybe<Array<Scalars['String']['output']>>;
  /** On refabrication orders, notes about what needs to be changed from the previous order. Initially the same as 'refabrication_notes' on the original order, but may be updated by OOps. */
  notes_for_refabrication?: Maybe<Scalars['String']['output']>;
  /** This ID is used to track an order from its creation down the chain of all sub-orders it may create. Through multi-fulfillments, C&R, Refabs, we create new "sub-orders" of an initial order, and we use order_group_id to have a way to keep track of all orders stemming from that initial order easily. */
  order_group_id: Scalars['String']['output'];
  /** A unique identifier short enough to be human readable */
  order_number: Scalars['String']['output'];
  order_tracker_entries: Array<LabsGqlOrderTrackerStepDefinition>;
  /** Will be set if this order is a refabrication of another order */
  original_order_id?: Maybe<Scalars['String']['output']>;
  partials_fulfillment?: Maybe<LabsGqlPartialsFulfillmentDto>;
  partner_id: Scalars['ID']['output'];
  /** The group id for when a place order command results in multiple orders due to manufacturer splitting */
  partner_order_id: Scalars['String']['output'];
  partner_order_number?: Maybe<Scalars['Int']['output']>;
  patient: LabsGqlPatientDto;
  /** @deprecated Individual patient properties are deprecated - use 'patient' field instead. */
  patient_birthday: Scalars['String']['output'];
  /** @deprecated Individual patient properties are deprecated - use 'patient' field instead. */
  patient_first_name: Scalars['String']['output'];
  /** @deprecated Individual patient properties are deprecated - use 'patient' field instead. */
  patient_gender?: Maybe<Scalars['String']['output']>;
  patient_id: Scalars['String']['output'];
  /** @deprecated Individual patient properties are deprecated - use 'patient' field instead. */
  patient_last_name: Scalars['String']['output'];
  /** @deprecated Use patient.order_count instead. */
  patient_order_count: Scalars['Int']['output'];
  photos: Array<LabsGqlLabOrderSinglePhotoDto>;
  practice_dates: LabsGqlOrderPracticeDates;
  /** The id of a previous order that was used to create this one in a multi fulfillment sequence */
  previous_fulfillment_order_id?: Maybe<Scalars['String']['output']>;
  /** The product line category to which this order belongs */
  product_line?: Maybe<LabsGqlProductLine>;
  qc_error?: Maybe<LabsGqlQcErrorType>;
  refab_count?: Maybe<Scalars['Int']['output']>;
  /** @deprecated refer to refab reason codes in feedback on ReviewSubmissionDTO */
  refab_reasons?: Maybe<Array<LabsGqlOrderRefabReasonDto>>;
  /** Notes from practitioner explaining why *this* order needed to be refabricated */
  refabrication_notes?: Maybe<Scalars['String']['output']>;
  /** If a refabrication order has been created for this order, this is the refabrication order ID. See also original_order_id. */
  refabrication_order_id?: Maybe<Scalars['String']['output']>;
  /** Will be set if this order is a cancelled order (from the cancel & resubmit flow). Can include multiple IDs if the new order is split. */
  replaced_by_ids?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Do not use! Will not be supported by sales order system. */
  replaced_by_orders?: Maybe<Array<LabsGqlLabOrderWithEvents>>;
  replaced_scan_export_ids: Array<Scalars['String']['output']>;
  replaced_scan_exports: Array<LabsGqlScan_Export>;
  review_flag_reasons?: Maybe<Array<Scalars['String']['output']>>;
  review_submission?: Maybe<LabsGqlIReviewSubmission>;
  scan_export: LabsGqlScan_Export;
  scan_export_id: Scalars['String']['output'];
  /** @deprecated Dont use this, its useless */
  scan_issues?: Maybe<Array<Scalars['String']['output']>>;
  sent_to_floor_date?: Maybe<Scalars['DateTime']['output']>;
  ship_date?: Maybe<Scalars['DateTime']['output']>;
  ship_servicelevel?: Maybe<Scalars['String']['output']>;
  ship_status?: Maybe<Scalars['String']['output']>;
  ship_status_updated?: Maybe<Scalars['DateTime']['output']>;
  /** Only null when it has not been shipped yet */
  shipped_by_lab?: Maybe<Scalars['Boolean']['output']>;
  shipped_to_dandy: Scalars['Boolean']['output'];
  shipping_label_url?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer used */
  shipping_method: LabsGqlPortalShipShippingSpeed;
  show_hold_status_to_practice?: Maybe<Scalars['Boolean']['output']>;
  smile_style?: Maybe<LabsGqlSmileStyleChoice>;
  /** Count of orders the initial place order command was split into */
  split_order_count?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Do not use! Will not be supported by sales order system. */
  split_orders?: Maybe<Array<LabsGqlLabOrderWithEvents>>;
  status: LabsGqlLabOrderStatus;
  tracking_number?: Maybe<Scalars['String']['output']>;
  try_in_feedback?: Maybe<LabsGqlTryInFeedbackDto>;
  updated_at: Scalars['DateTime']['output'];
  uses_production_order: Scalars['Boolean']['output'];
  variant_feedback_status?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please utilize the doctor_review field on DesignOrderRevision, or the listDesignOrderDoctorReviews query. This field will no longer be maintained in the future. */
  waxup_review_history?: Maybe<LabsGqlOrderWaxupReviewHistory>;
  /** The current status of the order's waxup. */
  waxup_status?: Maybe<LabsGqlLabOrderWaxupReviewStatus>;
  workflow_state: LabsGqlWorkflowState;
};


export type LabsGqlLabOrderWithEventsOrder_Tracker_EntriesArgs = {
  timezone_offset_minutes: Scalars['Int']['input'];
};

export type LabsGqlLabOverrideOrderUnitPriceArgs = {
  fulfillment_number: Scalars['Int']['input'];
  order_id: Scalars['String']['input'];
  override_description: Scalars['String']['input'];
  price_cents_override: Scalars['Int']['input'];
  price_id: Scalars['String']['input'];
  price_idx: Scalars['Int']['input'];
};

export type LabsGqlLabPortalDesignOrderListCounts = {
  __typename?: 'LabPortalDesignOrderListCounts';
  design?: Maybe<Scalars['Int']['output']>;
  designRevise?: Maybe<Scalars['Int']['output']>;
  designSendToFloor?: Maybe<Scalars['Int']['output']>;
  designWaitForDandy?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlLabPortalDesignOrderListItem = {
  __typename?: 'LabPortalDesignOrderListItem';
  design?: Maybe<LabsGqlOrderDesign>;
  designFilePath?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  itemsV2?: Maybe<Array<LabsGqlLabOrderItemV2>>;
  listFlags: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  partnerOrderNumber?: Maybe<Scalars['String']['output']>;
  patientName: Scalars['String']['output'];
  scanExportSanitizedUrl?: Maybe<Scalars['String']['output']>;
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalDesignReviseOrderListItem = {
  __typename?: 'LabPortalDesignReviseOrderListItem';
  design?: Maybe<LabsGqlOrderDesign>;
  designFilePath?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  itemsV2?: Maybe<Array<LabsGqlLabOrderItemV2>>;
  listFlags: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  partnerOrderNumber?: Maybe<Scalars['String']['output']>;
  patientName: Scalars['String']['output'];
  scanExportSanitizedUrl?: Maybe<Scalars['String']['output']>;
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalDesignSendToFloorOrderListItem = {
  __typename?: 'LabPortalDesignSendToFloorOrderListItem';
  id: Scalars['String']['output'];
  listFlags: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalDesignWaitForDandyOrderListItem = {
  __typename?: 'LabPortalDesignWaitForDandyOrderListItem';
  id: Scalars['String']['output'];
  listFlags: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalIntakeOrderListCounts = {
  __typename?: 'LabPortalIntakeOrderListCounts';
  intake?: Maybe<Scalars['Int']['output']>;
  intakeViewedDocs?: Maybe<Scalars['Int']['output']>;
  intakeWithDesign?: Maybe<Scalars['Int']['output']>;
  intakeWithReturn?: Maybe<Scalars['Int']['output']>;
  intakeWithoutDesign?: Maybe<Scalars['Int']['output']>;
  nestedIntake?: Maybe<Scalars['Int']['output']>;
  nestingIntake?: Maybe<Scalars['Int']['output']>;
  newManualEntryNeeded?: Maybe<Scalars['Int']['output']>;
};

export enum LabsGqlLabPortalLabOrderSortKey {
  CreatedAt = 'created_at',
  FabricationScheduledStartDate = 'fabrication_scheduled_start_date',
  ShipByDate = 'ship_by_date',
  TimeInList = 'time_in_list',
  UpdatedAt = 'updated_at'
}

export type LabsGqlLabPortalListOrdersSort = {
  asc?: Scalars['Boolean']['input'];
  key: LabsGqlLabPortalLabOrderSortKey;
};

export type LabsGqlLabPortalNestedIntakeOrderListItem = {
  __typename?: 'LabPortalNestedIntakeOrderListItem';
  design?: Maybe<LabsGqlOrderDesign>;
  designFilePath?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inListDate?: Maybe<Scalars['DateTime']['output']>;
  items: Array<LabsGqlLabPortalNewOrderItem>;
  itemsV2?: Maybe<Array<LabsGqlLabOrderItemV2>>;
  listFlags: Array<Scalars['String']['output']>;
  modelType: Scalars['String']['output'];
  nestingBatchId?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  partnerOrderNumber?: Maybe<Scalars['String']['output']>;
  patientName: Scalars['String']['output'];
  priority: LabsGqlManufacturingOrderPriority;
  scanExportSanitizedUrl?: Maybe<Scalars['String']['output']>;
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalNestingInProgressOrderListItem = {
  __typename?: 'LabPortalNestingInProgressOrderListItem';
  design?: Maybe<LabsGqlOrderDesign>;
  designFilePath?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inListDate?: Maybe<Scalars['DateTime']['output']>;
  items: Array<LabsGqlLabPortalNewOrderItem>;
  itemsV2?: Maybe<Array<LabsGqlLabOrderItemV2>>;
  listFlags: Array<Scalars['String']['output']>;
  manufacturingOrderId: Scalars['String']['output'];
  modelType: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  partnerOrderNumber?: Maybe<Scalars['String']['output']>;
  patientName: Scalars['String']['output'];
  priority: LabsGqlManufacturingOrderPriority;
  scanExportSanitizedUrl?: Maybe<Scalars['String']['output']>;
  shipDate: Scalars['DateTime']['output'];
  stackCodeLabel?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlLabPortalNewNeedsManualEntryOrderListItem = {
  __typename?: 'LabPortalNewNeedsManualEntryOrderListItem';
  errorReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inListDate?: Maybe<Scalars['DateTime']['output']>;
  items: Array<LabsGqlLabPortalNewOrderItem>;
  listFlags: Array<Scalars['String']['output']>;
  lmsOrderId?: Maybe<Scalars['String']['output']>;
  modelType: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalNewOrderItem = {
  __typename?: 'LabPortalNewOrderItem';
  material: Scalars['String']['output'];
  unitType: Scalars['String']['output'];
};

export type LabsGqlLabPortalNewOrderListCounts = {
  __typename?: 'LabPortalNewOrderListCounts';
  new?: Maybe<Scalars['Int']['output']>;
  newManualEntryNeeded?: Maybe<Scalars['Int']['output']>;
  newWithDesign?: Maybe<Scalars['Int']['output']>;
  newWithReturn?: Maybe<Scalars['Int']['output']>;
  newWithoutDesign?: Maybe<Scalars['Int']['output']>;
  newWithoutPrint?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlLabPortalNewOrderListItem = {
  __typename?: 'LabPortalNewOrderListItem';
  design?: Maybe<LabsGqlOrderDesign>;
  designFilePath?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inListDate?: Maybe<Scalars['DateTime']['output']>;
  items: Array<LabsGqlLabPortalNewOrderItem>;
  itemsV2?: Maybe<Array<LabsGqlLabOrderItemV2>>;
  listFlags: Array<Scalars['String']['output']>;
  modelType: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  partnerOrderNumber?: Maybe<Scalars['String']['output']>;
  patientName: Scalars['String']['output'];
  priority: LabsGqlManufacturingOrderPriority;
  scanExportSanitizedUrl?: Maybe<Scalars['String']['output']>;
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalOnHoldOrderListCounts = {
  __typename?: 'LabPortalOnHoldOrderListCounts';
  onHold: Scalars['Int']['output'];
};

export type LabsGqlLabPortalOnHoldOrderListCountsNullable = {
  __typename?: 'LabPortalOnHoldOrderListCountsNullable';
  onHold?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlLabPortalOnHoldOrderListItem = {
  __typename?: 'LabPortalOnHoldOrderListItem';
  id: Scalars['String']['output'];
  listFlags: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalOrderDetails = {
  __typename?: 'LabPortalOrderDetails';
  accepted_at?: Maybe<Scalars['DateTime']['output']>;
  lab_slip_viewed_at?: Maybe<Scalars['DateTime']['output']>;
  stack_code?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlLabPortalOrderUpdateListItem = {
  __typename?: 'LabPortalOrderUpdateListItem';
  items: Array<LabsGqlLabPortalNewOrderItem>;
  maxUpdateDate: Scalars['DateTime']['output'];
  minUpdateDate: Scalars['DateTime']['output'];
  modelType: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  updates: Array<LabsGqlLabPortalUpdateInfo>;
};

export type LabsGqlLabPortalOverviewViewModel = {
  __typename?: 'LabPortalOverviewViewModel';
  designInDesign?: Maybe<Scalars['Int']['output']>;
  designRevise?: Maybe<Scalars['Int']['output']>;
  designSendToFloor?: Maybe<Scalars['Int']['output']>;
  designWaitForDandy?: Maybe<Scalars['Int']['output']>;
  manufacturerName: Scalars['String']['output'];
  ordersInFabrication: Scalars['Int']['output'];
  ordersIntakeOrders: Scalars['Int']['output'];
  ordersNeedsManualEntry?: Maybe<Scalars['Int']['output']>;
  ordersNewOrders: Scalars['Int']['output'];
  ordersOnHold: Scalars['Int']['output'];
  requestDate: Scalars['DateTime']['output'];
  returns?: Maybe<Scalars['Int']['output']>;
  shippingLate: Scalars['Int']['output'];
  shippingToday: Scalars['Int']['output'];
  shippingWeekToDate: Scalars['Int']['output'];
  updatesOther: Scalars['Int']['output'];
  updatesPullFromFloor: Scalars['Int']['output'];
};

export type LabsGqlLabPortalPullFromFloorUpdateListItem = {
  __typename?: 'LabPortalPullFromFloorUpdateListItem';
  items: Array<LabsGqlLabPortalNewOrderItem>;
  maxUpdateDate: Scalars['DateTime']['output'];
  minUpdateDate: Scalars['DateTime']['output'];
  modelType: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  updates: Array<LabsGqlLabPortalUpdateInfo>;
};

export type LabsGqlLabPortalReturnsOrderListCounts = {
  __typename?: 'LabPortalReturnsOrderListCounts';
  returnsPending?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlLabPortalReturnsPendingOrderListItem = {
  __typename?: 'LabPortalReturnsPendingOrderListItem';
  id: Scalars['String']['output'];
  listFlags: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalShipTodayLateOrderListItem = {
  __typename?: 'LabPortalShipTodayLateOrderListItem';
  id: Scalars['String']['output'];
  listFlags: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalShipTodayOrderListCounts = {
  __typename?: 'LabPortalShipTodayOrderListCounts';
  shipLate?: Maybe<Scalars['Int']['output']>;
  shipOvernightToday?: Maybe<Scalars['Int']['output']>;
  shipToday?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlLabPortalShipTodayOrderListItem = {
  __typename?: 'LabPortalShipTodayOrderListItem';
  id: Scalars['String']['output'];
  listFlags: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalShipTodayOvernightOrderListItem = {
  __typename?: 'LabPortalShipTodayOvernightOrderListItem';
  id: Scalars['String']['output'];
  listFlags: Array<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  orderProductLine: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  shipDate: Scalars['DateTime']['output'];
};

export type LabsGqlLabPortalSidebarCounts = {
  __typename?: 'LabPortalSidebarCounts';
  Design_V2?: Maybe<Scalars['Int']['output']>;
  Expedite_V2?: Maybe<Scalars['Int']['output']>;
  Intake?: Maybe<Scalars['Int']['output']>;
  Nested_Intake?: Maybe<Scalars['Int']['output']>;
  Nesting?: Maybe<Scalars['Int']['output']>;
  New_V2?: Maybe<Scalars['Int']['output']>;
  OnHold_V2?: Maybe<Scalars['Int']['output']>;
  RecentAndFabrication_V2?: Maybe<Scalars['Int']['output']>;
  Returns_V2?: Maybe<Scalars['Int']['output']>;
  Updates_V2?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlLabPortalUpdateInfo = {
  __typename?: 'LabPortalUpdateInfo';
  updateDate: Scalars['DateTime']['output'];
  updateType: Scalars['String']['output'];
};

export type LabsGqlLabPortalUpdatesOrderListCounts = {
  __typename?: 'LabPortalUpdatesOrderListCounts';
  orderUpdates?: Maybe<Scalars['Int']['output']>;
  pullFromFloor?: Maybe<Scalars['Int']['output']>;
};

export enum LabsGqlLabPriceAbutmentType {
  CustomTitaniumAbutment = 'CustomTitaniumAbutment',
  CustomZirconiaHybridAbutment = 'CustomZirconiaHybridAbutment',
  TiBase = 'TiBase'
}

export type LabsGqlLabPriceAdditionalPonticsInRemovableRuleDto = {
  __typename?: 'LabPriceAdditionalPonticsInRemovableRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceAlignerKittingRuleDto = {
  __typename?: 'LabPriceAlignerKittingRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceAlignerRushFeeRuleDto = {
  __typename?: 'LabPriceAlignerRushFeeRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceAlignerUnitRuleDto = {
  __typename?: 'LabPriceAlignerUnitRuleDTO';
  /** If true, this rule will only match Aligner Retainer items. If false, it will only match full Aligners */
  aligner_retainers_only: Scalars['Boolean']['output'];
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceConfigDto = {
  __typename?: 'LabPriceConfigDTO';
  /** The ID of the lab this price applies to. Null means a default (applies to labs without an specific config). */
  lab_id?: Maybe<Scalars['String']['output']>;
  price_cents: Scalars['Int']['output'];
};

export type LabsGqlLabPriceConfigInput = {
  /** The ID of the lab this price applies to. Null means a default (applies to labs without an specific config). */
  lab_id?: InputMaybe<Scalars['String']['input']>;
  price_cents: Scalars['Int']['input'];
};

export type LabsGqlLabPriceDto = {
  __typename?: 'LabPriceDTO';
  /** @deprecated Renamed to lab_configs */
  configs: Array<LabsGqlLabPriceConfigDto>;
  created_at: Scalars['DateTime']['output'];
  default_practice_price_cents?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  enabled_for_labs?: Maybe<Scalars['Boolean']['output']>;
  enabled_for_practices?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lab_configs: Array<LabsGqlLabPriceConfigDto>;
  name: Scalars['String']['output'];
  /** @deprecated replaced by `OrganizationPriceConfigDTO` */
  partner_configs: Array<LabsGqlLabPricePartnerConfigDto>;
  rule: LabsGqlLabPriceRuleDto;
  salesforce_price_mapping?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlLabPriceDefinitionResult = {
  __typename?: 'LabPriceDefinitionResult';
  created_at: Scalars['DateTime']['output'];
  default_practice_price_cents?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  enabled_for_labs?: Maybe<Scalars['Boolean']['output']>;
  enabled_for_practices?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  labs_configured: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partners_configured: Scalars['Int']['output'];
  rule: LabsGqlLabPriceRuleDto;
  salesforce_price_mapping?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlLabPriceDentureAddOnCuSilGasketDto = {
  __typename?: 'LabPriceDentureAddOnCuSilGasketDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceDentureAddOnMetalFrameworkDto = {
  __typename?: 'LabPriceDentureAddOnMetalFrameworkDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceDentureAddOnMetalMeshDto = {
  __typename?: 'LabPriceDentureAddOnMetalMeshDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceDentureAddOnSoftlinerDto = {
  __typename?: 'LabPriceDentureAddOnSoftlinerDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceDentureArchRuleDto = {
  __typename?: 'LabPriceDentureArchRuleDTO';
  arch_type: LabsGqlDenturesSingleFulfillmentArchType;
  fabrication_method: LabsGqlDenturesFabricationMethod;
  teeth_quality: LabsGqlDenturesTeethQuality;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceDentureTryInRuleDto = {
  __typename?: 'LabPriceDentureTryInRuleDTO';
  arch: LabsGqlDenturesArchType;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceDentureWaxRimRuleDto = {
  __typename?: 'LabPriceDentureWaxRimRuleDTO';
  arch: LabsGqlDenturesArchType;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceExternalDesignRuleDto = {
  __typename?: 'LabPriceExternalDesignRuleDTO';
  type: LabsGqlLabPriceRuleType;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlLabPriceFirstPonticInRemovableRuleDto = {
  __typename?: 'LabPriceFirstPonticInRemovableRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceImplantAbutmentRuleDto = {
  __typename?: 'LabPriceImplantAbutmentRuleDTO';
  abutment_type: LabsGqlLabPriceAbutmentType;
  authentic: Scalars['Boolean']['output'];
  generic_manufacturer?: Maybe<LabsGqlOrderItemLinkGenericManufacturer>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceImplantDigitalAnalogRuleDto = {
  __typename?: 'LabPriceImplantDigitalAnalogRuleDTO';
  authentic: Scalars['Boolean']['output'];
  generic_manufacturer?: Maybe<LabsGqlOrderItemLinkGenericManufacturer>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceImplantModelRuleDto = {
  __typename?: 'LabPriceImplantModelRuleDTO';
  model_type: LabsGqlOrderItemModelType;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceImplantRuleDto = {
  __typename?: 'LabPriceImplantRuleDTO';
  abutment_type: LabsGqlLabPriceAbutmentType;
  authentic: Scalars['Boolean']['output'];
  generic_manufacturer?: Maybe<LabsGqlOrderItemLinkGenericManufacturer>;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceLabPricesResult = {
  __typename?: 'LabPriceLabPricesResult';
  id: Scalars['ID']['output'];
  lab_configs: Array<LabsGqlLabPriceConfigDto>;
  name: Scalars['String']['output'];
};

export type LabsGqlLabPriceLedgerDto = {
  __typename?: 'LabPriceLedgerDTO';
  created_at: Scalars['DateTime']['output'];
  /** Which order fulfillment number is this for (zero indexed) */
  fulfillment_number?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use organization_type and organization_id */
  lab_id?: Maybe<Scalars['ID']['output']>;
  order_id: Scalars['ID']['output'];
  organization_id?: Maybe<Scalars['ID']['output']>;
  organization_type?: Maybe<LabsGqlSlaResponsibleParty>;
  prices: Array<LabsGqlLabPriceLedgerPriceDto>;
  reviewed: Scalars['Boolean']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlLabPriceLedgerDateRangeInput = {
  begin: Scalars['DateTime']['input'];
  end: Scalars['DateTime']['input'];
};

export enum LabsGqlLabPriceLedgerOverrideReason {
  LabAdjustment = 'LabAdjustment',
  LabFaultRefab = 'LabFaultRefab',
  Refab = 'Refab'
}

export type LabsGqlLabPriceLedgerPriceDto = {
  __typename?: 'LabPriceLedgerPriceDTO';
  item_id?: Maybe<Scalars['ID']['output']>;
  /** How many units the lab will consider this for billing purposes */
  lab_unit_count: Scalars['Int']['output'];
  /** The unit price for this entry */
  price_cents: Scalars['Int']['output'];
  price_id: Scalars['ID']['output'];
  price_name: Scalars['String']['output'];
  price_override?: Maybe<LabsGqlLabPriceLedgerPriceOverrideDto>;
  /** The total price for this entry (equal to price_cents times lab_unit_count) */
  total_price_cents: Scalars['Int']['output'];
  units?: Maybe<Array<LabsGqlLabPriceLedgerUnitInfoDto>>;
};

export type LabsGqlLabPriceLedgerPriceInput = {
  item_id?: InputMaybe<Scalars['ID']['input']>;
  /** How many units the lab will consider this for billing purposes */
  lab_unit_count: Scalars['Int']['input'];
  /** The unit price for this entry */
  price_cents: Scalars['Int']['input'];
  price_id: Scalars['ID']['input'];
  price_name: Scalars['String']['input'];
  price_override?: InputMaybe<LabsGqlLabPriceLedgerPriceOverrideInput>;
  /** The total price for this entry (equal to price_cents times lab_unit_count) */
  total_price_cents: Scalars['Int']['input'];
  units?: InputMaybe<Array<LabsGqlLabPriceLedgerUnitInfoInput>>;
};

export type LabsGqlLabPriceLedgerPriceOverrideDto = {
  __typename?: 'LabPriceLedgerPriceOverrideDTO';
  description?: Maybe<Scalars['String']['output']>;
  original_price_cents: Scalars['Int']['output'];
  original_total_price_cents: Scalars['Int']['output'];
  reason: LabsGqlLabPriceLedgerOverrideReason;
};

export type LabsGqlLabPriceLedgerPriceOverrideInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  original_price_cents: Scalars['Int']['input'];
  original_total_price_cents: Scalars['Int']['input'];
  reason: LabsGqlLabPriceLedgerOverrideReason;
};

export type LabsGqlLabPriceLedgerResult = {
  __typename?: 'LabPriceLedgerResult';
  created_at: Scalars['DateTime']['output'];
  fabrication_start_date?: Maybe<Scalars['DateTime']['output']>;
  fulfillment_number: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  lab_prices: Array<LabsGqlLabPriceLedgerPriceDto>;
  order_id: Scalars['String']['output'];
  order_number: Scalars['String']['output'];
  patient_first_name: Scalars['String']['output'];
  patient_last_name: Scalars['String']['output'];
  reviewed: Scalars['Boolean']['output'];
  ship_date: Scalars['DateTime']['output'];
};

export type LabsGqlLabPriceLedgerSummaryResult = {
  __typename?: 'LabPriceLedgerSummaryResult';
  month: Scalars['DateTime']['output'];
  orders_count: Scalars['Int']['output'];
};

export type LabsGqlLabPriceLedgerUnitInfoDto = {
  __typename?: 'LabPriceLedgerUnitInfoDTO';
  arch?: Maybe<LabsGqlOrderItemArch>;
  material?: Maybe<Scalars['String']['output']>;
  /** The weight of precious metal being priced */
  precious_metal_pennyweight?: Maybe<Scalars['Float']['output']>;
  unit_type: Scalars['String']['output'];
  unn?: Maybe<Scalars['Int']['output']>;
  unns?: Maybe<Array<Scalars['Int']['output']>>;
};

export type LabsGqlLabPriceLedgerUnitInfoInput = {
  arch?: InputMaybe<LabsGqlOrderItemArch>;
  material?: InputMaybe<Scalars['String']['input']>;
  /** The weight of precious metal being priced */
  precious_metal_pennyweight?: InputMaybe<Scalars['Float']['input']>;
  unit_type: Scalars['String']['input'];
  unn?: InputMaybe<Scalars['Int']['input']>;
  unns?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LabsGqlLabPriceModelRuleDto = {
  __typename?: 'LabPriceModelRuleDTO';
  model_type: LabsGqlOrderItemModelType;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceNightguardColorCustomizationRuleDto = {
  __typename?: 'LabPriceNightguardColorCustomizationRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceOrderRuleDto = {
  __typename?: 'LabPriceOrderRuleDTO';
  /** @deprecated Use an external design rule instead */
  designer_type?: Maybe<LabsGqlLabPriceRuleOrderDesignerType>;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceOtherAddOnRuleDto = {
  __typename?: 'LabPriceOtherAddOnRuleDTO';
  type: LabsGqlLabPriceRuleType;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlLabPricePackagingRuleDto = {
  __typename?: 'LabPricePackagingRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPricePartialDentureArchRuleDto = {
  __typename?: 'LabPricePartialDentureArchRuleDTO';
  coverage_type: LabsGqlOrderItemPartialDentureCoverageType;
  material: Scalars['String']['output'];
  /** The range of tooth counts to which this rule applies (inclusive on both ends). */
  tooth_count_range: LabsGqlLabPricePartialDentureToothCountRange;
  type: LabsGqlLabPriceRuleType;
  unit_type: LabsGqlLabPricePartialDentureArchUnitType;
};

export enum LabsGqlLabPricePartialDentureArchUnitType {
  Final = 'Final',
  FinalAfterTryIn = 'FinalAfterTryIn',
  TryIn = 'TryIn'
}

export type LabsGqlLabPricePartialDentureClaspRuleDto = {
  __typename?: 'LabPricePartialDentureClaspRuleDTO';
  clasp_type: LabsGqlOrderItemPartialDentureClaspType;
  type: LabsGqlLabPriceRuleType;
};

export enum LabsGqlLabPricePartialDenturePracticeClaspType {
  Flexible = 'Flexible',
  Metal = 'Metal'
}

export enum LabsGqlLabPricePartialDenturePracticeUnitType {
  Final = 'Final',
  TryInFrameworkOnly = 'TryInFrameworkOnly',
  TryInWithTeethSetup = 'TryInWithTeethSetup',
  WaxBiteRim = 'WaxBiteRim'
}

export type LabsGqlLabPricePartialDentureToothCountRange = {
  __typename?: 'LabPricePartialDentureToothCountRange';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlLabPricePartialDentureToothCountRangeInput = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlLabPricePartialDentureWaxRimRuleDto = {
  __typename?: 'LabPricePartialDentureWaxRimRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPricePartnerConfigDto = {
  __typename?: 'LabPricePartnerConfigDTO';
  from_preset?: Maybe<Scalars['String']['output']>;
  /** The ID of the partner this price applies to. Null means a default (applies to partners without an specific config). */
  partner_id?: Maybe<Scalars['String']['output']>;
  price_cents: Scalars['Int']['output'];
};

export type LabsGqlLabPricePartnerConfigInput = {
  from_preset?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the partner this price applies to. Null means a default (applies to partners without an specific config). */
  partner_id?: InputMaybe<Scalars['String']['input']>;
  price_cents: Scalars['Int']['input'];
};

export type LabsGqlLabPricePartnerPricesResult = {
  __typename?: 'LabPricePartnerPricesResult';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partners_configured: Scalars['Int']['output'];
};

export type LabsGqlLabPricePracticePartialDentureFinalRuleDto = {
  __typename?: 'LabPricePracticePartialDentureFinalRuleDTO';
  coverage_type?: Maybe<LabsGqlOrderItemPartialDentureCoverageType>;
  material: Scalars['String']['output'];
  practice_partial_clasp_type?: Maybe<LabsGqlLabPricePartialDenturePracticeClaspType>;
  /** The range of tooth counts to which this rule applies (inclusive on both ends). */
  tooth_count_range?: Maybe<LabsGqlLabPricePartialDentureToothCountRange>;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPricePracticePartialDentureRuleDto = {
  __typename?: 'LabPricePracticePartialDentureRuleDTO';
  coverage_type?: Maybe<LabsGqlOrderItemPartialDentureCoverageType>;
  material: Scalars['String']['output'];
  practice_partial_clasp_type?: Maybe<LabsGqlLabPricePartialDenturePracticeClaspType>;
  practice_partial_unit_type: LabsGqlLabPricePartialDenturePracticeUnitType;
  /** The range of tooth counts to which this rule applies (inclusive on both ends). */
  tooth_count_range?: Maybe<LabsGqlLabPricePartialDentureToothCountRange>;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPricePracticePartialDentureTryInRuleDto = {
  __typename?: 'LabPricePracticePartialDentureTryInRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPricePracticePartialDentureWaxRimRuleDto = {
  __typename?: 'LabPricePracticePartialDentureWaxRimRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPricePracticeRetainerAdditionalArchRuleDto = {
  __typename?: 'LabPricePracticeRetainerAdditionalArchRuleDTO';
  material: Scalars['String']['output'];
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPricePracticeRetainerPonticToothRuleDto = {
  __typename?: 'LabPricePracticeRetainerPonticToothRuleDTO';
  material: Scalars['String']['output'];
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPricePracticeUnitRuleDto = {
  __typename?: 'LabPricePracticeUnitRuleDTO';
  type: LabsGqlLabPriceRuleType;
  unit_material?: Maybe<Scalars['String']['output']>;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlLabPricePreciousMetalRuleDto = {
  __typename?: 'LabPricePreciousMetalRuleDTO';
  material: Scalars['String']['output'];
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceRemovableClaspRuleDto = {
  __typename?: 'LabPriceRemovableClaspRuleDTO';
  clasp_type: LabsGqlOrderItemRemovableClaspType;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceRemovableRuleDto = {
  __typename?: 'LabPriceRemovableRuleDTO';
  arch: LabsGqlOrderItemArch;
  material: Scalars['String']['output'];
  type: LabsGqlLabPriceRuleType;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlLabPriceRuleDto = LabsGqlLabPriceAdditionalPonticsInRemovableRuleDto | LabsGqlLabPriceAlignerKittingRuleDto | LabsGqlLabPriceAlignerRushFeeRuleDto | LabsGqlLabPriceAlignerUnitRuleDto | LabsGqlLabPriceDentureAddOnCuSilGasketDto | LabsGqlLabPriceDentureAddOnMetalFrameworkDto | LabsGqlLabPriceDentureAddOnMetalMeshDto | LabsGqlLabPriceDentureAddOnSoftlinerDto | LabsGqlLabPriceDentureArchRuleDto | LabsGqlLabPriceDentureTryInRuleDto | LabsGqlLabPriceDentureWaxRimRuleDto | LabsGqlLabPriceExternalDesignRuleDto | LabsGqlLabPriceFirstPonticInRemovableRuleDto | LabsGqlLabPriceImplantAbutmentRuleDto | LabsGqlLabPriceImplantDigitalAnalogRuleDto | LabsGqlLabPriceImplantModelRuleDto | LabsGqlLabPriceImplantRuleDto | LabsGqlLabPriceModelRuleDto | LabsGqlLabPriceNightguardColorCustomizationRuleDto | LabsGqlLabPriceOrderRuleDto | LabsGqlLabPriceOtherAddOnRuleDto | LabsGqlLabPricePackagingRuleDto | LabsGqlLabPricePartialDentureArchRuleDto | LabsGqlLabPricePartialDentureClaspRuleDto | LabsGqlLabPricePartialDentureWaxRimRuleDto | LabsGqlLabPricePracticePartialDentureFinalRuleDto | LabsGqlLabPricePracticePartialDentureRuleDto | LabsGqlLabPricePracticePartialDentureTryInRuleDto | LabsGqlLabPricePracticePartialDentureWaxRimRuleDto | LabsGqlLabPricePracticeRetainerAdditionalArchRuleDto | LabsGqlLabPricePracticeRetainerPonticToothRuleDto | LabsGqlLabPricePracticeUnitRuleDto | LabsGqlLabPricePreciousMetalRuleDto | LabsGqlLabPriceRemovableClaspRuleDto | LabsGqlLabPriceRemovableRuleDto | LabsGqlLabPriceSleepApneaRuleDto | LabsGqlLabPriceSurgicalGuideRuleDto | LabsGqlLabPriceSurgicalGuideSleeveRuleDto | LabsGqlLabPriceTmjRuleDto | LabsGqlLabPriceUnitRuleDto | LabsGqlLabPriceUnknownRuleDto | LabsGqlLabPriceWaxupRuleDto;

export type LabsGqlLabPriceRuleInput = {
  abutment_type?: InputMaybe<LabsGqlLabPriceAbutmentType>;
  aligner_retainers_only?: InputMaybe<Scalars['Boolean']['input']>;
  arch?: InputMaybe<LabsGqlOrderItemArch>;
  arch_type?: InputMaybe<LabsGqlDenturesSingleFulfillmentArchType>;
  authentic?: InputMaybe<Scalars['Boolean']['input']>;
  clasp_type?: InputMaybe<LabsGqlOrderItemPartialDentureClaspType>;
  coverage_type?: InputMaybe<LabsGqlOrderItemPartialDentureCoverageType>;
  denture_arch?: InputMaybe<LabsGqlDenturesArchType>;
  designer_type?: InputMaybe<LabsGqlLabPriceRuleOrderDesignerType>;
  discluding_ramp?: InputMaybe<Scalars['Boolean']['input']>;
  fabrication_method?: InputMaybe<LabsGqlDenturesFabricationMethod>;
  generic_manufacturer?: InputMaybe<LabsGqlOrderItemLinkGenericManufacturer>;
  includes_wiring?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  material?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<LabsGqlOrderItemModelType>;
  partial_unit_type?: InputMaybe<LabsGqlLabPricePartialDentureArchUnitType>;
  practice_partial_clasp_type?: InputMaybe<LabsGqlLabPricePartialDenturePracticeClaspType>;
  practice_partial_unit_type?: InputMaybe<LabsGqlLabPricePartialDenturePracticeUnitType>;
  removable_clasp_type?: InputMaybe<LabsGqlOrderItemRemovableClaspType>;
  sleep_apnea_type?: InputMaybe<LabsGqlOrderItemSleepApneaType>;
  surgical_guide_ownership?: InputMaybe<LabsGqlLabPriceSurgicalGuideOwnership>;
  surgical_guide_type?: InputMaybe<LabsGqlLabPriceSurgicalGuideType>;
  teeth_quality?: InputMaybe<LabsGqlDenturesTeethQuality>;
  tmj_type?: InputMaybe<LabsGqlOrderItemTmjType>;
  tooth_count_range?: InputMaybe<LabsGqlLabPricePartialDentureToothCountRangeInput>;
  tooth_position_class?: InputMaybe<LabsGqlLabPriceToothPositionClass>;
  type: LabsGqlLabPriceRuleType;
  unit_material?: InputMaybe<Scalars['String']['input']>;
  unit_type?: InputMaybe<Scalars['String']['input']>;
};

export enum LabsGqlLabPriceRuleOrderDesignerType {
  External = 'external',
  Internal = 'internal'
}

export enum LabsGqlLabPriceRuleType {
  AdditionalPonticsInRemovable = 'additional_pontics_in_removable',
  AlignerKitting = 'aligner_kitting',
  AlignerRushFee = 'aligner_rush_fee',
  AlignerUnit = 'aligner_unit',
  DentureAddOnCuSilGasket = 'denture_add_on_cu_sil_gasket',
  DentureAddOnMetalFramework = 'denture_add_on_metal_framework',
  DentureAddOnMetalMesh = 'denture_add_on_metal_mesh',
  DentureAddOnSoftliner = 'denture_add_on_softliner',
  DentureArch = 'denture_arch',
  DentureTryIn = 'denture_try_in',
  DentureWaxRim = 'denture_wax_rim',
  ExternalDesign = 'external_design',
  FirstPonticInRemovable = 'first_pontic_in_removable',
  Implant = 'implant',
  ImplantAbutment = 'implant_abutment',
  ImplantDigitalAnalog = 'implant_digital_analog',
  ImplantModel = 'implant_model',
  Model = 'model',
  NightGuardColorCustomization = 'night_guard_color_customization',
  Order = 'order',
  OtherAddOn = 'other_add_on',
  Packaging = 'packaging',
  PartialDentureArch = 'partial_denture_arch',
  PartialDentureClasp = 'partial_denture_clasp',
  PartialDentureWaxRim = 'partial_denture_wax_rim',
  PracticePartialDenture = 'practice_partial_denture',
  PracticePartialDentureFinal = 'practice_partial_denture_final',
  PracticePartialDentureTryIn = 'practice_partial_denture_try_in',
  PracticePartialDentureWaxRim = 'practice_partial_denture_wax_rim',
  PracticeRetainerAdditionalArch = 'practice_retainer_additional_arch',
  PracticeRetainerPonticTooth = 'practice_retainer_pontic_tooth',
  PracticeUnit = 'practice_unit',
  PreciousMetal = 'precious_metal',
  Removable = 'removable',
  RemovableClasp = 'removable_clasp',
  SleepApnea = 'sleep_apnea',
  SurgicalGuide = 'surgical_guide',
  SurgicalGuideSleeve = 'surgical_guide_sleeve',
  Tmj = 'tmj',
  Unit = 'unit',
  Unknown = 'unknown',
  Waxup = 'waxup'
}

export type LabsGqlLabPriceSleepApneaRuleDto = {
  __typename?: 'LabPriceSleepApneaRuleDTO';
  discluding_ramp: Scalars['Boolean']['output'];
  sleep_apnea_type: LabsGqlOrderItemSleepApneaType;
  type: LabsGqlLabPriceRuleType;
};

export enum LabsGqlLabPriceSurgicalGuideOwnership {
  Full = 'Full',
  ManufacturingOnly = 'ManufacturingOnly',
  PlanningOnly = 'PlanningOnly'
}

export type LabsGqlLabPriceSurgicalGuideRuleDto = {
  __typename?: 'LabPriceSurgicalGuideRuleDTO';
  guide_type: LabsGqlLabPriceSurgicalGuideType;
  ownership: LabsGqlLabPriceSurgicalGuideOwnership;
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceSurgicalGuideSleeveRuleDto = {
  __typename?: 'LabPriceSurgicalGuideSleeveRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export enum LabsGqlLabPriceSurgicalGuideType {
  TissueSupported = 'TissueSupported',
  ToothSupported = 'ToothSupported'
}

export type LabsGqlLabPriceTmjRuleDto = {
  __typename?: 'LabPriceTMJRuleDTO';
  includes_wiring?: Maybe<Scalars['Boolean']['output']>;
  tmj_type: LabsGqlOrderItemTmjType;
  type: LabsGqlLabPriceRuleType;
};

export enum LabsGqlLabPriceToothPositionClass {
  Anterior = 'anterior',
  Posterior = 'posterior'
}

export type LabsGqlLabPriceUnitRuleDto = {
  __typename?: 'LabPriceUnitRuleDTO';
  material: Scalars['String']['output'];
  tooth_position_class?: Maybe<LabsGqlLabPriceToothPositionClass>;
  type: LabsGqlLabPriceRuleType;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlLabPriceUnknownRuleDto = {
  __typename?: 'LabPriceUnknownRuleDTO';
  type: LabsGqlLabPriceRuleType;
};

export type LabsGqlLabPriceWaxupRuleDto = {
  __typename?: 'LabPriceWaxupRuleDTO';
  type: LabsGqlLabPriceRuleType;
  unit_type: Scalars['String']['output'];
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderActionEligibilityDto = {
  __typename?: 'LabSalesOrderActionEligibilityDTO';
  can_add_items: Scalars['Boolean']['output'];
  can_change_delivery_address: Scalars['Boolean']['output'];
  can_change_doctor: Scalars['Boolean']['output'];
  can_delete_items: Scalars['Boolean']['output'];
  can_update_items: Scalars['Boolean']['output'];
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderCadInfoDto = {
  __typename?: 'LabSalesOrderCadInfoDTO';
  has_layered_material: Scalars['Boolean']['output'];
  insertion_axis_groups: Array<Array<Scalars['ToothNumber']['output']>>;
  margin_unns: Array<Scalars['ToothNumber']['output']>;
  /** When the order is not eligible for Dandy Verified Design workflow, this property is set to a string explaining why. */
  verified_design_ineligible_reason?: Maybe<Scalars['String']['output']>;
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderDto = {
  __typename?: 'LabSalesOrderDTO';
  action_eligibility: LabsGqlLabSalesOrderActionEligibilityDto;
  cad_info: LabsGqlLabSalesOrderCadInfoDto;
  created_at: Scalars['DateTime']['output'];
  creation_return_id?: Maybe<Scalars['ID']['output']>;
  delivery_address: LabsGqlPartnerDeliveryAddress;
  delivery_address_id: Scalars['ID']['output'];
  doctor_preferences: LabsGqlDoctorPreferences;
  doctor_preferences_id: Scalars['ID']['output'];
  files: Array<LabsGqlLabSalesOrderFileDto>;
  has_design_preview: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  is_training: Scalars['Boolean']['output'];
  /** Basic display info for items in the order, grouped by type. Displaying grouped items is generally preferable to displaying individual items. */
  item_groups_display_info: Array<LabsGqlLabSalesOrderItemGroupDisplayInfoDto>;
  /** Basic display info for each individual item in the order. Displaying grouped items is generally preferable to displaying individual items. */
  items_display_info: Array<LabsGqlLabSalesOrderItemDisplayInfoDto>;
  items_full_display_info: Array<LabsGqlLabSalesOrderItemFullDisplayInfoDto>;
  items_full_display_info_with_common: LabsGqlLabSalesOrderItemFullDisplayInfoWithCommonDto;
  items_v2: Array<LabsGqlLabOrderItemV2>;
  next_order: LabsGqlLabSalesOrderNextOrderInfoDto;
  /** Returns the next orders linked to this one, excluding orders that have been replaced (cancelled and resubmitted). */
  next_order_excluding_replaced: LabsGqlLabSalesOrderNextOrderInfoDto;
  notes: LabsGqlLabSalesOrderNotesDto;
  order_number: Scalars['String']['output'];
  patient: LabsGqlPatientDto;
  patient_id: Scalars['ID']['output'];
  practice_id: Scalars['ID']['output'];
  practice_order_number: Scalars['Int']['output'];
  previous_order: LabsGqlLabSalesOrderPreviousOrderInfoDto;
  product_line: LabsGqlProductLine;
  refabrication_count: Scalars['Int']['output'];
  scan_export: LabsGqlScan_Export;
  scan_export_history: Array<LabsGqlScan_Export>;
  scan_export_id: Scalars['ID']['output'];
  scan_export_id_history: Array<Scalars['String']['output']>;
  service_level: LabsGqlLabSalesOrderServiceLevelDto;
  split_id: Scalars['ID']['output'];
  split_order_ids?: Maybe<Array<Scalars['String']['output']>>;
  treatment_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlLabSalesOrderFileCategory {
  CbctScan = 'CbctScan',
  IntraoralScan = 'IntraoralScan',
  Other = 'Other',
  Photo = 'Photo',
  SurgicalReport = 'SurgicalReport',
  XRay = 'XRay'
}

/** DEPRECATED */
export type LabsGqlLabSalesOrderFileDto = {
  __typename?: 'LabSalesOrderFileDTO';
  category: LabsGqlLabSalesOrderFileCategory;
  gcs_path: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderIdOnlyDto = {
  __typename?: 'LabSalesOrderIdOnlyDTO';
  id: Scalars['String']['output'];
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderItemDisplayInfoDto = {
  __typename?: 'LabSalesOrderItemDisplayInfoDTO';
  count: Scalars['Float']['output'];
  display_name: Scalars['String']['output'];
  full_display_name: Scalars['String']['output'];
  item_id: Scalars['ID']['output'];
  material?: Maybe<Scalars['String']['output']>;
  product_unit_type: Scalars['String']['output'];
  sku: LabsGqlOrderItemSkuType;
  unns: Array<Scalars['ToothNumber']['output']>;
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderItemFullDisplayInfoDto = {
  __typename?: 'LabSalesOrderItemFullDisplayInfoDTO';
  individual_items: Array<LabsGqlLabSalesOrderItemFullDisplayInfoIndividualItemDto>;
  major_details: Array<LabsGqlLabSalesOrderItemFullDisplayInfoDetailDto>;
  minor_details: Array<LabsGqlLabSalesOrderItemFullDisplayInfoDetailDto>;
  notes?: Maybe<Scalars['String']['output']>;
  shade_details: Array<LabsGqlLabSalesOrderItemFullDisplayInfoDetailDto>;
  title: Scalars['String']['output'];
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderItemFullDisplayInfoDetailDto = {
  __typename?: 'LabSalesOrderItemFullDisplayInfoDetailDTO';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderItemFullDisplayInfoIndividualItemDto = {
  __typename?: 'LabSalesOrderItemFullDisplayInfoIndividualItemDTO';
  item_id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderItemFullDisplayInfoWithCommonDto = {
  __typename?: 'LabSalesOrderItemFullDisplayInfoWithCommonDTO';
  common_minor_details: Array<LabsGqlLabSalesOrderItemFullDisplayInfoDetailDto>;
  items_full_display_info: Array<LabsGqlLabSalesOrderItemFullDisplayInfoDto>;
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderItemGroupDisplayInfoDto = {
  __typename?: 'LabSalesOrderItemGroupDisplayInfoDTO';
  count: Scalars['Float']['output'];
  display_name: Scalars['String']['output'];
  full_display_name: Scalars['String']['output'];
  material?: Maybe<Scalars['String']['output']>;
  product_unit_type: Scalars['String']['output'];
  sku: LabsGqlOrderItemSkuType;
  unns: Array<Scalars['ToothNumber']['output']>;
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderNextOrderInfoDto = {
  __typename?: 'LabSalesOrderNextOrderInfoDTO';
  continued_by_order_ids?: Maybe<Array<Scalars['ID']['output']>>;
  refabricated_by_order_ids?: Maybe<Array<Scalars['ID']['output']>>;
  replaced_by_order_ids?: Maybe<Array<Scalars['ID']['output']>>;
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderNotesDto = {
  __typename?: 'LabSalesOrderNotesDTO';
  clinical_support?: Maybe<Scalars['String']['output']>;
  doctor?: Maybe<Scalars['String']['output']>;
  refabrication?: Maybe<Scalars['String']['output']>;
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderPreviousOrderInfoDto = {
  __typename?: 'LabSalesOrderPreviousOrderInfoDTO';
  case_readiness_id?: Maybe<Scalars['ID']['output']>;
  continued_order_id?: Maybe<Scalars['ID']['output']>;
  refabricated_order_id?: Maybe<Scalars['ID']['output']>;
  replaced_order_id?: Maybe<Scalars['ID']['output']>;
};

/** DEPRECATED */
export type LabsGqlLabSalesOrderServiceLevelDto = {
  __typename?: 'LabSalesOrderServiceLevelDTO';
  speed: LabsGqlLabSalesOrderServiceSpeed;
};

export enum LabsGqlLabSalesOrderServiceSpeed {
  Rush = 'Rush',
  Slow = 'Slow',
  Standard = 'Standard'
}

/** A block of plain text shown on the printed lab slip */
export type LabsGqlLabSlipAlert = {
  __typename?: 'LabSlipAlert';
  text: Scalars['String']['output'];
  type: LabsGqlLabSlipAlertType;
  visibility?: Maybe<Array<LabsGqlDoctorAlertLocation>>;
};

export type LabsGqlLabSlipAlertInput = {
  text: Scalars['String']['input'];
  type: LabsGqlLabSlipAlertType;
  visibility?: InputMaybe<Array<LabsGqlDoctorAlertLocation>>;
};

export enum LabsGqlLabSlipAlertType {
  Checkbox = 'checkbox',
  Warning = 'warning'
}

export type LabsGqlLabsGqlQueryGetImplantTypeInput = {
  connection?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  system?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlLabtracIntegrationV2TestResult = {
  __typename?: 'LabtracIntegrationV2TestResult';
  labtracCaseNumber: Scalars['String']['output'];
  /** The raw JSON response from Labtrac */
  payload: Scalars['String']['output'];
  productMappingSuccess: Scalars['Boolean']['output'];
  /** Boolean for success of scheduling order (applicable if success == true). Null if scheduling was not attempted */
  scheduleSuccess?: Maybe<Scalars['Boolean']['output']>;
  success: Scalars['Boolean']['output'];
};

export type LabsGqlLdrCapacityStatsResponse = {
  __typename?: 'LdrCapacityStatsResponse';
  available_slot_count: LabsGqlAvailableLdrSlotCount;
  available_slots: Array<Scalars['String']['output']>;
};

export type LabsGqlLedgerInvoiceDetailsDto = {
  __typename?: 'LedgerInvoiceDetailsDTO';
  id: Scalars['String']['output'];
  invoice_number?: Maybe<Scalars['String']['output']>;
  period_end: Scalars['DateTime']['output'];
  period_start: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
};

/** Represents a snapshot of the final state of legacy multi-fulfillment partial and denture orders. Its sole purpose is to serve as a historical record so frontend clients can display it to the user. */
export type LabsGqlLegacyMultiFulfillmentInfoDto = {
  __typename?: 'LegacyMultiFulfillmentInfoDTO';
  fulfillments: Array<LabsGqlLegacyMultiFulfillmentInfoFulfillmentDto>;
  order_id: Scalars['ID']['output'];
};

/** Represents a single detail as part of of a legacy multi-fulfillment partial or denture order. When value is null, it indicates that the detail represents a title of a sub-section, with the following details (in the array of details within a fulfillment) being the content of that sub-section. */
export type LabsGqlLegacyMultiFulfillmentInfoDetailDto = {
  __typename?: 'LegacyMultiFulfillmentInfoDetailDTO';
  title: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Represents a single fulfillment as part of of a legacy multi-fulfillment partial or denture order. */
export type LabsGqlLegacyMultiFulfillmentInfoFulfillmentDto = {
  __typename?: 'LegacyMultiFulfillmentInfoFulfillmentDTO';
  details: Array<LabsGqlLegacyMultiFulfillmentInfoDetailDto>;
  photo_paths: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type LabsGqlLegacyTokenResult = {
  __typename?: 'LegacyTokenResult';
  accessToken: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type LabsGqlLinkOrderToRefabricationCommand = {
  original_order_id: Scalars['String']['input'];
  practitioner_notes: Scalars['String']['input'];
  refab_order_id: Scalars['String']['input'];
  refab_reasons: Array<LabsGqlOrderRefabReasonInput>;
  /** ID of the return on the original order */
  return_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlLinkOrganizationExternalAccountCommand = {
  id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  type: LabsGqlExternalAccountRelationshipType;
};

export type LabsGqlLinkScanbodyRequestFulfillmentCommand = {
  fulfillment_order_id: Scalars['ID']['input'];
  linked_scanbody?: InputMaybe<LabsGqlLinkedScanbodyInput>;
  new_scanbody?: InputMaybe<LabsGqlNewScanbodyInput>;
  scanbody_request_id: Scalars['ID']['input'];
  shipment_carrier?: InputMaybe<LabsGqlShippingCarriersEnum>;
  shipment_tracking_number?: InputMaybe<Scalars['String']['input']>;
  unlinked_scanbody?: InputMaybe<LabsGqlUnlinkedScanbodyInput>;
};

export type LabsGqlLinkedScanbodyInput = {
  scanbody_id: Scalars['ID']['input'];
};

export type LabsGqlListDesignersResult = {
  __typename?: 'ListDesignersResult';
  designers: Array<LabsGqlDesignStaffWithUser>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type LabsGqlListMatchingDesignersResult = {
  __typename?: 'ListMatchingDesignersResult';
  id: Scalars['ID']['output'];
};

export type LabsGqlListOrdersFilter = {
  delivered_since?: InputMaybe<Scalars['DateTime']['input']>;
  excluded_statuses?: InputMaybe<Array<LabsGqlLabOrderStatus>>;
  is_urgent?: InputMaybe<Scalars['Boolean']['input']>;
  is_waxup?: InputMaybe<Scalars['Boolean']['input']>;
  lab_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  mailing_address_id?: InputMaybe<Scalars['String']['input']>;
  partner_id?: InputMaybe<Scalars['String']['input']>;
  partner_order_id?: InputMaybe<Scalars['String']['input']>;
  sent_to_floor?: InputMaybe<Scalars['Boolean']['input']>;
  show_hold_status_to_practice?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<LabsGqlLabOrderStatus>;
  updated_since?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LabsGqlListOrdersSort = {
  asc?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<LabsGqlLabOrderSortKey>;
};

export type LabsGqlListOrgUsersFilter = {
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  orgIds?: InputMaybe<Array<Scalars['String']['input']>>;
  organization_types?: InputMaybe<Array<LabsGqlOrganizationType>>;
  role?: InputMaybe<LabsGqlStaffRole>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlListOrgsFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LabsGqlOrganizationType>;
};

export type LabsGqlListProductCatalogEntriesInput = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  type: LabsGqlProductType;
};

export type LabsGqlListReasonCodeParams = {
  form_display?: InputMaybe<LabsGqlFormDisplay>;
};

export type LabsGqlListUsersFilter = {
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  orgIds?: InputMaybe<Array<Scalars['String']['input']>>;
  organization_type?: InputMaybe<LabsGqlOrganizationType>;
  role?: InputMaybe<LabsGqlStaffRole>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlListViewEntry = {
  __typename?: 'ListViewEntry';
  id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlListWorkflowTasksFilter = {
  assigned?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_user_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  completed_at?: InputMaybe<LabsGqlTaskDateFilter>;
  /** List of STAFF ids, not user ids, that could have completed the task. */
  completed_by_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  created_at?: InputMaybe<LabsGqlTaskDateFilter>;
  design_review_required?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_assigned_user_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  is_revision?: InputMaybe<Scalars['Boolean']['input']>;
  responsible_party?: InputMaybe<LabsGqlWorkflowTaskResponsiblePartyInput>;
  type?: InputMaybe<Array<LabsGqlWorkflowTaskType>>;
};

export type LabsGqlLiveCasePlanningRequestDto = {
  __typename?: 'LiveCasePlanningRequestDto';
  calendly_invite_event_id: Scalars['ID']['output'];
  created_at: Scalars['DateTime']['output'];
  entry_point: LabsGqlLiveCasePlanningRequestEntryPoint;
  id: Scalars['ID']['output'];
  patient_id?: Maybe<Scalars['ID']['output']>;
  patient_name: Scalars['String']['output'];
  practice_id: Scalars['ID']['output'];
  product_line: Scalars['String']['output'];
  status: LabsGqlLiveCasePlanningRequestStatus;
  topic_to_discuss: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  zendesk_ticket_id?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlLiveCasePlanningRequestEntryPoint {
  ChairsideGetSupport = 'ChairsideGetSupport',
  PracticeGetSupport = 'PracticeGetSupport',
  PracticeGetSupportMobile = 'PracticeGetSupportMobile',
  UploaderGetSupport = 'UploaderGetSupport'
}

export type LabsGqlLiveCasePlanningRequestNotificationInfo = {
  __typename?: 'LiveCasePlanningRequestNotificationInfo';
  calendly_invite_event_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  end_time: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  join_url: Scalars['String']['output'];
  patient_name: Scalars['String']['output'];
  start_time: Scalars['DateTime']['output'];
  topic_to_discuss: Scalars['String']['output'];
};

export enum LabsGqlLiveCasePlanningRequestStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED'
}

export type LabsGqlLiveDdpReviewRequestDto = {
  __typename?: 'LiveDdpReviewRequestDto';
  calendly_event?: Maybe<LabsGqlCalendlyEventDto>;
  calendly_invite_event_id: Scalars['ID']['output'];
  created_at: Scalars['DateTime']['output'];
  design_order_id: Scalars['ID']['output'];
  design_order_revision_id?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  practice_id: Scalars['ID']['output'];
  rejection_notes?: Maybe<Scalars['String']['output']>;
  status: LabsGqlLiveDdpReviewRequestStatus;
  updated_at: Scalars['DateTime']['output'];
  zendesk_ticket_id?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlLiveDdpReviewRequestEntryPoint {
  ChairsideCheckoutDdp = 'ChairsideCheckoutDdp',
  OpsPortalOrderPage = 'OpsPortalOrderPage',
  PracticeGuidedWaxupReview = 'PracticeGuidedWaxupReview',
  PracticeGuidedWaxupReviewLdrOptionsModal = 'PracticeGuidedWaxupReviewLdrOptionsModal',
  PracticeLiveDesignReviewSchedulerRoute = 'PracticeLiveDesignReviewSchedulerRoute',
  PracticeOrderDetailLiveDesignReviewBanner = 'PracticeOrderDetailLiveDesignReviewBanner'
}

export enum LabsGqlLiveDdpReviewRequestStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED'
}

export type LabsGqlLiveLabTechMeetingRequestDto = {
  __typename?: 'LiveLabTechMeetingRequestDto';
  calendly_invite_event_id: Scalars['ID']['output'];
  created_at: Scalars['DateTime']['output'];
  entry_point: LabsGqlLiveLabTechMeetingRequestEntryPoint;
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  practice_id: Scalars['ID']['output'];
  status: LabsGqlLiveLabTechMeetingRequestStatus;
  updated_at: Scalars['DateTime']['output'];
  zendesk_ticket_id?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlLiveLabTechMeetingRequestEntryPoint {
  PracticeGuidedWaxupReview = 'PracticeGuidedWaxupReview'
}

export type LabsGqlLiveLabTechMeetingRequestNotificationInfo = {
  __typename?: 'LiveLabTechMeetingRequestNotificationInfo';
  calendly_invite_event_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  end_time: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  join_url: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  start_time: Scalars['DateTime']['output'];
};

export enum LabsGqlLiveLabTechMeetingRequestStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED'
}

export type LabsGqlLiveLabTechMeetingUrl = {
  __typename?: 'LiveLabTechMeetingUrl';
  url: Scalars['String']['output'];
};

export type LabsGqlManufacturerBulkMutationResult = {
  __typename?: 'ManufacturerBulkMutationResult';
  error_ids: Array<Scalars['String']['output']>;
  success_ids: Array<Scalars['String']['output']>;
};

export type LabsGqlManufacturerCreatedRes = {
  __typename?: 'ManufacturerCreatedRes';
  id: Scalars['String']['output'];
};

export type LabsGqlManufacturerDelayRequest = {
  __typename?: 'ManufacturerDelayRequest';
  delay_days: Scalars['Int']['output'];
  reason: LabsGqlOrderSlaModificationReasonDto;
  shipping_days: Scalars['Int']['output'];
  status: LabsGqlManufacturerDelayRequestStatus;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlManufacturerDelayRequestDto = {
  delay_days: Scalars['Int']['input'];
  reason: LabsGqlOrderSlaModificationReason;
  shipping_days: Scalars['Int']['input'];
  status: LabsGqlManufacturerDelayRequestStatus;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum LabsGqlManufacturerDelayRequestStatus {
  Accepted = 'Accepted',
  EditedAndAccepted = 'EditedAndAccepted',
  Pending = 'Pending'
}

export type LabsGqlManufacturerFeatureStates = {
  __typename?: 'ManufacturerFeatureStates';
  portalShip?: Maybe<Scalars['Boolean']['output']>;
  shipStation?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlManufacturerFeatureStatesInput = {
  portalShip?: InputMaybe<Scalars['Boolean']['input']>;
  shipStation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlManufacturerNotificationPreferences = {
  __typename?: 'ManufacturerNotificationPreferences';
  created_at: Scalars['DateTime']['output'];
  emails: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manufacturer_id: Scalars['ID']['output'];
  new_order_notifications: Array<LabsGqlManufacturerNotificationType>;
  phone_number?: Maybe<Scalars['String']['output']>;
  time_zone?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlManufacturerNotificationType {
  Email = 'email',
  EmailDaily = 'email_daily',
  Sms = 'sms',
  SmsDaily = 'sms_daily'
}

export type LabsGqlManufacturerOrderHistory = {
  __typename?: 'ManufacturerOrderHistory';
  accepted_lab_ids: Array<Scalars['String']['output']>;
  design_downloaded_at?: Maybe<Scalars['DateTime']['output']>;
  lab_slip_printed_at?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlManufacturerProfile = {
  __typename?: 'ManufacturerProfile';
  created_at: Scalars['DateTime']['output'];
  custom_field_preferences: Array<LabsGqlICustomFieldSubmission>;
  deactivated_at?: Maybe<Scalars['DateTime']['output']>;
  default_portalship_destination?: Maybe<LabsGqlPortalShipDestinationType>;
  default_portalship_speed?: Maybe<LabsGqlPortalShipShippingSpeed>;
  design_capability: LabsGqlInternalDesignAbility;
  exclude_from_gold_pricing: Scalars['Boolean']['output'];
  feature_states: LabsGqlManufacturerFeatureStates;
  /** This is manufacturer id to ensure uniqueness */
  id: Scalars['ID']['output'];
  is_internal: Scalars['Boolean']['output'];
  /** Where this lab receives inbound shipments, if different from their outbound shipping address */
  return_shipping_address?: Maybe<LabsGqlDeliveryAddress>;
  /** Where this lab ships outbound orders from */
  shipping_address?: Maybe<LabsGqlDeliveryAddress>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlManufacturerTruckTimeEntity = {
  __typename?: 'ManufacturerTruckTimeEntity';
  /** The carrier (e.g. ups, fedex, etc.) */
  carrier: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  /** The days of the week the pickup occurs */
  days_of_week: Array<LabsGqlDayOfWeek>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** The time that the lab needs to select the label by in order to ensure that the package is ready for this particular truck. */
  label_print_cutoff_time: LabsGqlTruckTimeEntityTimeStruct;
  last_modified_by_staff_id?: Maybe<Scalars['String']['output']>;
  manufacturer_id: Scalars['ID']['output'];
  /** The time at which the truck actually arrives at the lab. */
  pickup_time: LabsGqlTruckTimeEntityTimeStruct;
  /** Whether the carrier supports next day delivery for this pickup time */
  supports_next_day: Scalars['Boolean']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlManufacturerTruckTimeEntityTimeInput = {
  hour: Scalars['Float']['input'];
  minute: Scalars['Float']['input'];
};

export type LabsGqlManufacturerTruckTimeOption = {
  __typename?: 'ManufacturerTruckTimeOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** A booking action. */
export type LabsGqlManufacturingBookingDto = {
  __typename?: 'ManufacturingBookingDto';
  /** The booking action. */
  bookingAction: Scalars['String']['output'];
  /** The time the booking occurred. */
  bookingTime: Scalars['DateTime']['output'];
  /** When the model was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The id of the department. */
  department: Scalars['String']['output'];
  /** The id of the booking. */
  id: Scalars['String']['output'];
  /** Whether the booking happened during overtime work. */
  isOvertime: Scalars['Boolean']['output'];
  /** The id of the machine. */
  machineId?: Maybe<Scalars['String']['output']>;
  /** The id of the related manufacturing order. */
  manufacturingOrderId: Scalars['String']['output'];
  /** The id of the related lab order. */
  orderNumber?: Maybe<Scalars['String']['output']>;
  /** The stack code label for the lab order. */
  stack?: Maybe<Scalars['String']['output']>;
  /** The lab stage. */
  stage: Scalars['String']['output'];
  /** The time the booking was synced to Labtrac. */
  syncCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The reason the sync was skipped. */
  syncSkipReason?: Maybe<Scalars['String']['output']>;
  /** The time the booking skipped syncing to Labtrac. */
  syncSkippedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the model was updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The id of the user that performed the booking action. */
  userId: Scalars['String']['output'];
};

export type LabsGqlManufacturingOrderDto = {
  __typename?: 'ManufacturingOrderDTO';
  acceptedAt?: Maybe<Scalars['String']['output']>;
  fabricationScheduledStartDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  intakeCompletedAt?: Maybe<Scalars['String']['output']>;
  labId: Scalars['String']['output'];
  labOrderId: Scalars['String']['output'];
  labSlipViewedAt?: Maybe<Scalars['String']['output']>;
  lmsErrorStatus?: Maybe<Scalars['String']['output']>;
  lmsIntegratedAt?: Maybe<Scalars['String']['output']>;
  lmsOrderId?: Maybe<Scalars['String']['output']>;
  millingNestExternalId?: Maybe<Scalars['String']['output']>;
  packingCompletedAt?: Maybe<Scalars['String']['output']>;
  panLabelViewedAt?: Maybe<Scalars['String']['output']>;
  priority: LabsGqlManufacturingOrderPriority;
  qcPassedAt?: Maybe<Scalars['String']['output']>;
  scannedInShippingAt?: Maybe<Scalars['String']['output']>;
  status: LabsGqlManufacturingOrderStatus;
};

export enum LabsGqlManufacturingOrderPriority {
  High = 'HIGH',
  Standard = 'STANDARD'
}

export enum LabsGqlManufacturingOrderStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED'
}

export type LabsGqlManufacturingStageDto = {
  __typename?: 'ManufacturingStageDTO';
  deptId: Scalars['String']['output'];
  stageId: Scalars['String']['output'];
};

export type LabsGqlManufacturingStageInputDto = {
  deptId: Scalars['String']['input'];
  stageId: Scalars['String']['input'];
};

export type LabsGqlMarkDeliveredCommand = {
  delivery_date?: InputMaybe<Scalars['DateTime']['input']>;
  orderId: Scalars['String']['input'];
};

export type LabsGqlMarkFulfillmentShippedCommand = {
  carrier: LabsGqlShippingCarriersEnum;
  destination?: InputMaybe<LabsGqlMarkShippedDestinationType>;
  label_url?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
  ship_date: Scalars['DateTime']['input'];
  tracking_number: Scalars['String']['input'];
};

export type LabsGqlMarkMfgUnitsAutoNestingCompletedResult = {
  __typename?: 'MarkMfgUnitsAutoNestingCompletedResult';
  completed_mfg_unit_ids: Array<Scalars['String']['output']>;
  skipped_mfg_unit_ids: Array<Scalars['String']['output']>;
};

export type LabsGqlMarkNeedsRefabricationCommand = {
  annotations?: InputMaybe<Array<LabsGqlReviewSubmissionAnnotationInput>>;
  attachment_urls?: InputMaybe<Array<Scalars['String']['input']>>;
  attachments?: InputMaybe<Array<LabsGqlRefabOrderAttachments>>;
  /** IDs of the items that need refabrication. */
  itemIdsToRefab?: InputMaybe<Array<Scalars['String']['input']>>;
  orderId: Scalars['String']['input'];
  practitioner_notes: Scalars['String']['input'];
  refab_item_reason_codes?: InputMaybe<Array<LabsGqlRefabItemInfo>>;
  refab_reasons?: InputMaybe<Array<LabsGqlOrderRefabReasonInput>>;
  return_id?: InputMaybe<Scalars['String']['input']>;
  scan_export_id?: InputMaybe<Scalars['String']['input']>;
  waxup_requested?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LabsGqlMarkShippedDestinationType {
  Dandy = 'dandy',
  Practice = 'practice'
}

export type LabsGqlMetalMaterialDto = {
  __typename?: 'MetalMaterialDTO';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlMigratePreferencesToStaffArgs = {
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  id: Scalars['String']['input'];
  is_mobile_verified?: InputMaybe<Scalars['Boolean']['input']>;
  last_name: Scalars['String']['input'];
  mobile_phone_number: Scalars['String']['input'];
  /** The org with which to associate this user. Defaults to partner_id on the preferences */
  organization_id?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roles: Array<LabsGqlStaffRolePractice>;
};

export type LabsGqlMigratedStaffResult = {
  __typename?: 'MigratedStaffResult';
  organization_id: Scalars['String']['output'];
  preferences: LabsGqlDoctorPreferences;
  roles: Array<LabsGqlStaffRolePractice>;
  staff_member_id: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

export type LabsGqlModelItem = LabsGqlIOrderItemBase & {
  __typename?: 'ModelItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  model_type: LabsGqlOrderItemModelType;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  selected_teeth?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlModelItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  modelType?: InputMaybe<LabsGqlOrderItemModelType>;
  /** Not optional. Should always be passed from up-to-date clients. */
  model_type?: InputMaybe<LabsGqlOrderItemModelType>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  selected_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit_type: Scalars['String']['input'];
};

export type LabsGqlModifyCrownProductCatalogEntryInput = {
  id: Scalars['ID']['input'];
  isAnterior: Scalars['Boolean']['input'];
  material: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LabsGqlModifyOrderConfig = {
  __typename?: 'ModifyOrderConfig';
  items_v2: Array<LabsGqlLabOrderItemV2>;
  mode: LabsGqlDoctorRequestOptionModifyOrderMode;
  type: Scalars['String']['output'];
};

export type LabsGqlModifyOrderConfigInput = {
  items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
  mode: LabsGqlDoctorRequestOptionModifyOrderMode;
};

export type LabsGqlModifyOrderSlaCommand = {
  buffer_days?: InputMaybe<Scalars['Int']['input']>;
  delay_days?: InputMaybe<Scalars['Int']['input']>;
  design_days?: InputMaybe<Scalars['Int']['input']>;
  fabrication_days?: InputMaybe<Scalars['Int']['input']>;
  /** Opt out of approving a pending rush even though this modification adds negative delay days? */
  ignore_pending_rush_request?: Scalars['Boolean']['input'];
  modification_type: LabsGqlOrderSlaModificationType;
  notify_practice: Scalars['Boolean']['input'];
  order_id: Scalars['String']['input'];
  reason: LabsGqlOrderSlaModificationReason;
  ship_by_date?: InputMaybe<Scalars['DateTime']['input']>;
  shipping_days?: InputMaybe<Scalars['Int']['input']>;
  /** When provided, will automatically compute notify_practice, delay_days, shipping_days, and all other properties submitted. */
  stage_due_date?: InputMaybe<Scalars['DateTime']['input']>;
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LabsGqlMultiShadeInfo = {
  __typename?: 'MultiShadeInfo';
  shadeMessage: Scalars['String']['output'];
};

export type LabsGqlMutation = {
  __typename?: 'Mutation';
  /** @deprecated This endpoint is no longer used and will be removed 11/3/2023. */
  acceptAutomateDesignRevision: Scalars['Boolean']['output'];
  acknowledgeAllLabUpdates: Scalars['Boolean']['output'];
  acknowledgeLabUpdatesForOrder: Scalars['Boolean']['output'];
  acknowledgeManualEntryLmsOrder: Array<LabsGqlAcknowledgeManualEntryLmsOrderResponse>;
  activateOrganizationStaffMember: LabsGqlOrganizationDto;
  activatePracticeContract: LabsGqlFullyResolvedPracticeContractDto;
  addAbutmentPartToImplantScanbody: LabsGqlImplantTypeDto;
  addCRMTicketTags: Scalars['Boolean']['output'];
  /** Add a revision to a smile library. */
  addCadSmileLibraryRevision: LabsGqlCadSmileLibraryDto;
  addEntityTag: LabsGqlTaggedEntityDto;
  addExternalStaffMember: LabsGqlOrganizationDto;
  addInternalStaffMember: LabsGqlOrganizationDto;
  addLabOrderPhotos: LabsGqlLab_Order;
  addLabOrderPhotosWithNotes: LabsGqlLab_Order;
  addLabSlipAlert: LabsGqlLab_Order;
  addLabStaffMember: LabsGqlOrganizationDto;
  addManualQualityControlItem: LabsGqlLab_Order;
  addOrderItem: LabsGqlLab_Order;
  addOrderItemsV2: LabsGqlLab_Order;
  /** Add an order to a given shipment */
  addOrderToShipment: LabsGqlOrderShipmentDto;
  addParentStaffMember: LabsGqlOrganizationDto;
  addPhoneToDrPrefAndUser: Scalars['Boolean']['output'];
  addPracticeStaffMember: LabsGqlOrganizationDto;
  addPriceApplicationRule: LabsGqlIPrice;
  addReasonCodeRule: LabsGqlReasonCodeDto;
  addRefabReasonRule: LabsGqlRefabReasonDto;
  addRetainerToAlignerCase: LabsGqlLab_Order;
  agreeToCreditCardFees: LabsGqlIPartnerBilling;
  appendTicketAction: LabsGqlTicketDto;
  applyInvoiceCreditToInvoice: LabsGqlInvoiceDto;
  applyPricePreset: LabsGqlIPricePreset;
  approveAlignerFabrication: LabsGqlLab_Order;
  archiveAbutmentPart: LabsGqlAbutmentPartDto;
  /** Archive smile library */
  archiveCadSmileLibrary: LabsGqlCadSmileLibraryDto;
  archiveOrderAutomation: LabsGqlOrderAutomation;
  /** This is for a POC - do not use it! */
  archiveProductCatalogEntry: LabsGqlProductCatalogWithDetailsDto;
  archiveQcItemConfig: LabsGqlQcItemConfigDto;
  archiveThreeshapeDme: LabsGqlThreeshapeDmeDto;
  assignFulfillmentTask: LabsGqlLab_Order;
  attachAlignerPreppedScans: Scalars['Boolean']['output'];
  /** Attaches a new design asset to a design revision */
  attachDesignAsset: Scalars['Boolean']['output'];
  attachDesignFiles: LabsGqlLab_Order;
  attachExternalAlignerFulfillment: LabsGqlLab_Order;
  attachQcPhotos: LabsGqlLab_Order;
  attachScanStlFiles: LabsGqlScan_Export;
  attachTreatmentPlanRequest: LabsGqlLab_Order;
  /** Ship a group of orders in the same batch based on the fastest shipping speed */
  batchOrderShipments: LabsGqlBatchOrderShipmentsResult;
  batchOrderShipmentsAndRecordPackingCompleted: LabsGqlBatchOrderShipmentsAndRecordPackingCompletedResult;
  blockStacks: Scalars['Boolean']['output'];
  /** A temporary mutation that will backfill lab_order waxup reviews to the design_order doctor review system */
  bulkBackfillDesignOrderDoctorReviews: Scalars['Boolean']['output'];
  bulkCreateCreditsWorkflow: LabsGqlBulkCreateCreditsWorkflowResult;
  /** Forcefully creates design orders for the given lab order ids. For emergencies only. */
  bulkCreateDesignOrders: Scalars['Boolean']['output'];
  bulkCreateDesignStaffTeams: Array<LabsGqlDesignStaffTeamDto>;
  bulkCreateScanbodyRequests: Array<LabsGqlScanbodyRequestDto>;
  bulkCreateUsers: LabsGqlBulkCreateUserResult;
  bulkDeleteAutomations: Scalars['Boolean']['output'];
  bulkDeleteDesignStaffTeams: Scalars['Boolean']['output'];
  bulkDownloadDesignStaffRoster: LabsGqlBulkDownloadResult;
  bulkOverrideLabUnitPrices: LabsGqlLabBulkOverrideOrderUnitPriceResult;
  bulkRegisterInvoiceItems: LabsGqlBulkRegisterInvoiceItemResult;
  bulkRemoveStaffMembers: LabsGqlOrganizationDto;
  bulkRestoreAutomations: Scalars['Boolean']['output'];
  bulkTagEntities: Scalars['Boolean']['output'];
  bulkToggleWillBeChargedCCFee: LabsGqlBulkToggleWillBeChargedCcFeeResult;
  bulkUpdateAbutmentPartScrewSku: Scalars['Boolean']['output'];
  bulkUpdateDesignStaffRoster: LabsGqlBulkUpdateResult;
  bulkUpdateOrderPrices: Array<LabsGqlOrderPriceDto>;
  bulkUpdateOrganizationIsFakeStatus: Scalars['Boolean']['output'];
  bulkUpdatePartnerBillingConfigFlag: Array<LabsGqlIPartnerBilling>;
  bulkUpsertLabPriceLabPrices: Scalars['Boolean']['output'];
  bulkUpsertOrganizationPrices: Scalars['Boolean']['output'];
  bulkUpsertPrices: LabsGqlBulkPriceUpsertRes;
  cancelAndResubmit: LabsGqlLab_Order;
  cancelDesignQaEvaluations: Scalars['Boolean']['output'];
  cancelLiveCasePlanningRequest: Scalars['Boolean']['output'];
  cancelLiveDdpReviewRequest: Scalars['Boolean']['output'];
  cancelLiveDdpReviewRequestByEventId: Scalars['Boolean']['output'];
  cancelLiveLabTechMeetingRequest: Scalars['Boolean']['output'];
  cancelManufacturingOrder: LabsGqlManufacturingOrderDto;
  cancelOrderAutomateTask: Scalars['Boolean']['output'];
  cancelOrderReturn: LabsGqlIOrderReturn;
  /** Cancel shipment given a shipment ID */
  cancelShipment: Scalars['Boolean']['output'];
  carestreamPreUpload: Scalars['Boolean']['output'];
  changeDeliveryAddress: LabsGqlLab_Order;
  changeDesignSoftware: LabsGqlLab_Order;
  changeLabOrderManufacturer: LabsGqlLab_Order;
  changeManufacturerBulk: LabsGqlManufacturerBulkMutationResult;
  changePreferenceStaffMember: LabsGqlDoctorPreferences;
  changePriceName: LabsGqlIPrice;
  claimTicketAction: LabsGqlTicketDto;
  closeTicket: LabsGqlTicketDto;
  closeTicketAction: LabsGqlTicketDto;
  collectNotificationPreferences: LabsGqlDoctorPreferences;
  collectPartialPayment: LabsGqlInvoicePaymentDto;
  completeDesignPrepTask: LabsGqlLab_Order;
  completeDesignReviewTask: LabsGqlLab_Order;
  completeExternalDesignVerificationTask: LabsGqlLab_Order;
  completeInternalDesignTask: LabsGqlLab_Order;
  completeModelDesignTask: LabsGqlLab_Order;
  completeOnboarding: LabsGqlPracticeOnboardingDto;
  completeQualityControlItem: LabsGqlLab_Order;
  completeTreatmentPlanConversion: LabsGqlLab_Order;
  configureDesignReview2Command: LabsGqlLab_Order;
  configureLabPriceLabPrices: LabsGqlLabPriceDto;
  configureLabPricePartnerPrices: LabsGqlLabPriceDto;
  configureOrderDesignReview: LabsGqlLab_Order;
  configureOrderDesignerType: LabsGqlLab_Order;
  configureOrderWaxup: LabsGqlLab_Order;
  confirmOrderReturnReceived: LabsGqlIOrderReturn;
  continueDenturesFulfillmentWaxRim: LabsGqlLab_Order;
  convertPracticeToParent: Scalars['Boolean']['output'];
  createAbutmentPart: LabsGqlAbutmentPartDto;
  createActionCapability: LabsGqlActionCapabilityDto;
  createActionCategory: LabsGqlActionCategoryDto;
  createActionType: LabsGqlActionTypeDto;
  createAndDeleteFocusAreas: Array<LabsGqlDesignStaffFocusAreaDto>;
  createAttributedCredits: Array<LabsGqlInvoiceCreditDto>;
  createBiasRule: LabsGqlBiasRule;
  createBillingCreditCategory: LabsGqlBillingCreditCategory;
  createCRMChatMessage: LabsGqlCrmChatMessage;
  createCRMTicket: LabsGqlCrmTicket;
  createCRMTicketComment: LabsGqlCrmTicket;
  createCRMUser: LabsGqlCrmUser;
  /** Create a smile library */
  createCadSmileLibrary: LabsGqlCadSmileLibraryDto;
  /** Create smile library with an initial revision. */
  createCadSmileLibraryWithInitialRevision: LabsGqlCadSmileLibraryDto;
  createCapacityRule: LabsGqlCapacityRule;
  createCapacityStation: LabsGqlCapacityStationDto;
  createCapacityStationRule: LabsGqlCapacityStationRuleDto;
  createCaseReadiness: LabsGqlCaseReadinessDto;
  createCategory: LabsGqlCategoryDto;
  createChairsideReviewSubmission: LabsGqlIReviewSubmission;
  createChatMessage: LabsGqlChatMessageDto;
  createCompletedScanningCase: LabsGqlCompletedScanningCaseDto;
  /** This is for a POC - do not use it! */
  createCrownProductCatalogEntry: LabsGqlProductCatalogWithDetailsDto;
  createDeliveryAddress: LabsGqlPartnerDeliveryAddress;
  createDesignQaEvaluation: LabsGqlDesignQaEvaluationDto;
  createDesignReviewField: LabsGqlDesignReviewFieldDto;
  createDesignRule: LabsGqlDesignRule;
  createDesignStaffTeam: LabsGqlDesignStaffTeamDto;
  createDoctorPreferences: LabsGqlDoctorPreferences;
  createDoctorRequest: LabsGqlDoctorRequestDto;
  createEmptyVariantFeedback: LabsGqlVariantFeedbackDto;
  createEnableCcFeeForAllWorkflow: LabsGqlEnableCcFeeForAllWorkflowResult;
  createExternalOrg: LabsGqlExternalOrgCreatedRes;
  createFabricationBulk: Array<LabsGqlCreateFabricationResult>;
  /** Used by PFL for manually level loading internal labs */
  createFabricationBulkInternal: Scalars['Boolean']['output'];
  createFulfillment: LabsGqlLab_Order;
  createGoldilocksControlGroupMetadata: LabsGqlCreateOrderMetadataOutput;
  createImplantType: LabsGqlImplantTypeDto;
  createInvoice?: Maybe<LabsGqlInvoiceDto>;
  createInvoiceCredit: LabsGqlInvoiceCreditDto;
  createLabCaseCompletion: LabsGqlLabCaseCompletionDto;
  createLabCaseLedgerEntry: LabsGqlLabCaseLedgerDto;
  createLabMetalCost: LabsGqlLabMetalCostDto;
  createLabPrice: LabsGqlLabPriceDto;
  createLabPriceLedgerEntry: LabsGqlLabPriceLedgerDto;
  createLabPriceLedgerPartnerEntry: LabsGqlLabPriceLedgerDto;
  createLabsManufacturer: LabsGqlManufacturerCreatedRes;
  createLiveCasePlanningRequest: LabsGqlLiveCasePlanningRequestDto;
  createLiveDdpReviewRequest: LabsGqlLiveDdpReviewRequestDto;
  createLiveLabTechMeetingRequest: LabsGqlLiveLabTechMeetingRequestDto;
  createManualLabOrderIntegrationAttempt?: Maybe<Array<LabsGqlCreateLabOrderIntegrationAttemptResponse>>;
  createManufacturerTruckTime: LabsGqlManufacturerTruckTimeEntity;
  /** Create bookings. */
  createManufacturingBookings?: Maybe<Array<LabsGqlManufacturingBookingDto>>;
  createMetalMaterial: LabsGqlMetalMaterialDto;
  createNonDoctorPracticeStaff: LabsGqlNonDoctorPracticeStaffDto;
  createOrFindChairsidePatient: LabsGqlPatientDto;
  createOrderAutomation: LabsGqlOrderAutomation;
  createOrderHold: LabsGqlLab_Order;
  createOrderItemVariantCharge: LabsGqlPracticeBillingRecurringItemsDto;
  /** Create a temporal workflow to backfill order prices from the lab_order aggregate to order_price_snapshot. Batches should be sized to complete within 1 minute. */
  createOrderPriceBackfillWorkflow: LabsGqlCreateOrderPriceBackfillWorkflowResult;
  createOrderPriceEntry: LabsGqlOrderPriceDto;
  createOrganization: LabsGqlOrganizationDto;
  createOrganizationPriceConfig: LabsGqlOrganizationPriceConfigDto;
  createParentOrg: Scalars['Boolean']['output'];
  createPartner: Scalars['Boolean']['output'];
  createPartnerBilling: LabsGqlIPartnerBilling;
  createPassword: LabsGqlCreatePasswordResult;
  createPatient: LabsGqlPatientDto;
  /** Create a payment method in a partner's stripe account using a stripe token. */
  createPaymentMethod: LabsGqlStripePaymentId;
  createPendingAlignerCase: LabsGqlPendingAlignerCaseDto;
  createPracticeCaseBulk: Array<LabsGqlIPracticeCase>;
  createPracticeRequestMarketingMaterials: LabsGqlPracticeRequestDto;
  createPracticeRequestScheduleTraining: LabsGqlPracticeRequestDto;
  createPracticeSupport: LabsGqlPracticeSupportDto;
  createPrice: LabsGqlIPrice;
  createPricePreset: LabsGqlIPricePreset;
  createProductStationUnitMapping: LabsGqlProductStationUnitMappingDto;
  createQcIssue: LabsGqlQcIssue;
  createQcItemConfig: LabsGqlQcItemConfigDto;
  createReasonCode: LabsGqlReasonCodeDto;
  /** Create a temporal workflow to rebuild snapshots for the aggregate. Batches should be sized to complete within 1 minute. */
  createRebuildAggregateByIdsWorkflow: LabsGqlCreateRebuildAggregateWorkflowResult;
  /** Create a temporal workflow to rebuild snapshots for the aggregate. Batches should be sized to complete within 1 minute. */
  createRebuildAggregateWorkflow: LabsGqlCreateRebuildAggregateWorkflowResult;
  createRefabReason: LabsGqlRefabReasonDto;
  createRefundCategory: LabsGqlRefundCategoryDto;
  createReturnForRefabrication: LabsGqlIOrderReturn;
  createReturnForTryIn: LabsGqlIOrderReturn;
  createReviewSubmissionV3: LabsGqlIReviewSubmission;
  createReviewTag: LabsGqlReviewTag;
  createRoutingTargetDistribution: LabsGqlRoutingTargetDistributionRule;
  createSavedInboxSearch: LabsGqlSavedSearch;
  createSavedOrderSearch: LabsGqlSavedSearch;
  createSavedRoutingSearch: LabsGqlSavedSearch;
  createScanExportPreUpload: LabsGqlCreateScanPreUploadRes;
  createScanExportPreUploadScanner: LabsGqlCreateScanPreUploadRes;
  createScanExportPreUploadWithRaw: LabsGqlCreateScanPreUploadRes;
  createScanExportPreUploadWithRawScanner: LabsGqlCreateScanPreUploadRes;
  createScanbody: LabsGqlScanbodyDto;
  createScanbodyRequest: LabsGqlScanbodyRequestDto;
  createScanner: LabsGqlScannerEntity;
  /** Create a shade suggestion with photos. */
  createShadeSuggestionWithPhotos?: Maybe<Scalars['String']['output']>;
  createShippingLabel: Scalars['String']['output'];
  createTag: LabsGqlTag;
  createThreeshapeDme: LabsGqlThreeshapeDmeDto;
  createTicket: LabsGqlTicketDto;
  createTicketSavedSearch: LabsGqlTicketingSavedSearch;
  createTraining: LabsGqlTraining;
  createTrainingIntakeResponse?: Maybe<Array<Scalars['String']['output']>>;
  createTryInFeedback: LabsGqlTryInFeedbackDto;
  createUser: LabsGqlUserDto;
  createVideoDdpReviewRequest: LabsGqlVideoDdpReviewRequestDto;
  createWorkflowStepDefinition: LabsGqlWorkflowStepDefinition;
  deleteChatMessage: LabsGqlChatMessageDto;
  deleteDoctorPreferences: LabsGqlDoctorPreferences;
  deleteImplantType: LabsGqlImplantTypeDto;
  deleteInvoiceCredit: LabsGqlInvoiceCreditDto;
  deleteManufacturerTruckTime: LabsGqlManufacturerTruckTimeEntity;
  /** Delete manufacturing technicians by ID */
  deleteManufacturingTechnicians: LabsGqlDeleteTechniciansResponseDto;
  deleteMetalMaterial: LabsGqlMetalMaterialDto;
  deleteNonDoctorPracticeStaff: LabsGqlNonDoctorPracticeStaffDto;
  deleteOrderAutomation: LabsGqlOrderAutomation;
  deleteOrderItem: LabsGqlLab_Order;
  deleteOrderPriceEntry: LabsGqlOrderPriceDto;
  deleteOrganizationPriceConfig: LabsGqlOrganizationPriceConfigDto;
  deletePartnerPriceConfig: LabsGqlIPrice;
  deletePracticeSupport: LabsGqlPracticeSupportDto;
  deletePrice: LabsGqlIPrice;
  deletePricePreset: LabsGqlIPricePreset;
  deleteProductStationUnitMapping: LabsGqlProductStationUnitMappingDto;
  deleteReasonCodeRule: LabsGqlReasonCodeDto;
  deleteRefabReasonRule: LabsGqlRefabReasonDto;
  deleteRegisteredInvoiceItem: LabsGqlPracticeBillingRecurringItemsDto;
  deleteSavedSearch: LabsGqlSavedSearch;
  deleteScanExport: Scalars['Boolean']['output'];
  deleteScanbody: LabsGqlScanbodyDto;
  deleteScanbodyRequest: LabsGqlScanbodyRequestDto;
  deleteTag: LabsGqlTag;
  deleteTicketSavedSearch: LabsGqlTicketingSavedSearch;
  dev_associate_treatment_plan_with_case: Scalars['Boolean']['output'];
  disableBiasRule: LabsGqlBiasRule;
  disableCapacityRule: LabsGqlCapacityRule;
  disableCapacityStationRule: LabsGqlCapacityStationRuleDto;
  disableDesignRule: LabsGqlDesignRule;
  disableRoutingTargetDistributionRule: LabsGqlRoutingTargetDistributionRule;
  editAccountManagementContacts: LabsGqlIPartnerBilling;
  editAlignerCase: LabsGqlLab_Order;
  editBillingContacts: LabsGqlIPartnerBilling;
  editDeliveryAddress: LabsGqlPartnerDeliveryAddress;
  editDoctorNote: LabsGqlLab_Order;
  editQcItemConfig: LabsGqlQcItemConfigDto;
  editRefabrication: LabsGqlLab_Order;
  editRefabricationReasons: LabsGqlLab_Order;
  editSavedInboxSearch: LabsGqlSavedSearch;
  editSavedOrderSearch: LabsGqlSavedSearch;
  editSavedRoutingSearch: LabsGqlSavedSearch;
  editTag: LabsGqlTag;
  editTicketActionNote: LabsGqlTicketDto;
  editTicketSavedSearch: LabsGqlTicketingSavedSearch;
  emailReturnLabel: Scalars['Boolean']['output'];
  emergencyOnlyHardDeleteContract: Scalars['Boolean']['output'];
  enableBiasRule: LabsGqlBiasRule;
  enableCapacityRule: LabsGqlCapacityRule;
  enableDesignRule: LabsGqlDesignRule;
  enableRoutingTargetDistributionRule: LabsGqlRoutingTargetDistributionRule;
  /** Enroll in a course, creating an instance of the course and all of its orders for the requesting designer. */
  enrollInDesignTrainingCourse: LabsGqlEnrolledCourse;
  ensureStackCodesForLabOrders: LabsGqlEnsureStackCodesForLabOrdersResult;
  /** Execute an arbitrary temporal workflow. */
  executeTemporalWorkflow: LabsGqlCreateRebuildAggregateWorkflowResult;
  externalUserCancelOrder: LabsGqlLab_Order;
  favoriteSavedSearch: LabsGqlSavedSearch;
  favoriteTicketSavedSearch: LabsGqlTicketingSavedSearch;
  finalizeTreatmentPlanRequest: LabsGqlLab_Order;
  flagOrderForReview: LabsGqlLab_Order;
  forceReturnRefabConversion: Scalars['String']['output'];
  fulfillVideoDdpReviewRequest: Scalars['Boolean']['output'];
  generateEmptyDesignFile: Scalars['String']['output'];
  generateSoftsmileWebviewerLink: Scalars['String']['output'];
  generate_softsmile_vision_link: Scalars['String']['output'];
  getDownloadUrl: Scalars['String']['output'];
  /** @deprecated Use handleFcmCheckByCaseInfo instead */
  handleFcmCheck: LabsGqlFcmCheckResult;
  handleFcmCheckByCaseInfo: LabsGqlFcmCheckResult;
  ignoreQualityControlItem: LabsGqlLab_Order;
  invalidateInternalRemake: Scalars['Boolean']['output'];
  issueAttributedRefunds: Array<LabsGqlInvoiceDto>;
  labOrderQcStationFail: LabsGqlLab_Order;
  labOrderQcStationPass: LabsGqlLab_Order;
  linkOrderToRefabrication: LabsGqlLab_Order;
  linkOrganizationExternalAccount: LabsGqlOrganizationDto;
  linkScanbodyRequestFulfillment: LabsGqlScanbodyRequestDto;
  /** Log a threeshape plugin operation */
  logThreeshapePluginOperation: Scalars['Boolean']['output'];
  /** Creates a new asset files and saves them in the database for the given order id. */
  manuallyAttachInjectionMoldFiles: Scalars['Boolean']['output'];
  markDelivered: LabsGqlLab_Order;
  markEventUnread: LabsGqlUserReadEvents;
  markEventsRead: LabsGqlUserReadEvents;
  markMfgUnitsAutoNestingCompleteWithDetailedResponse: LabsGqlMarkMfgUnitsAutoNestingCompletedResult;
  markNeedsRefabrication: LabsGqlLab_Order;
  markOrdersIntakeCompletedByLabOrderIds: Scalars['Int']['output'];
  markReturnReceivedBulk: LabsGqlManufacturerBulkMutationResult;
  markShipped: LabsGqlLab_Order;
  /** For Fixed + Removable cases, we split the incoming order into multiple orders, and have a designer perform "Merge and Stitch". We previously used an automation to put the Removable side of the order on hold while waiting for the fixed design to complete, but can do it ourselves since we already detect M&S cases for TAT adjustments. This will be removed when we get rid of order splits :) */
  mergeAndStitchDesignOrders: Scalars['Boolean']['output'];
  migratePreferencesToStaff: LabsGqlMigratedStaffResult;
  /** Dispatches a pusher notification for mobile upload page load */
  mobileUploadLoaded: Scalars['Boolean']['output'];
  /** This is for a POC - do not use it! */
  modifyCrownProductCatalogEntry: LabsGqlProductCatalogWithDetailsDto;
  modifyOrderSla: LabsGqlLab_Order;
  /** A development-only endpoint that auto submits a design to the indicated design order. */
  nonProductionAutoSubmitDesign: Scalars['Boolean']['output'];
  /** @deprecated This endpoint's results were unused, and the endpoint will be removed soon. */
  notifyQCOrderError: Scalars['Boolean']['output'];
  offboardDesignStaff: LabsGqlDesignStaffDto;
  oneTimeCodeLoginV2: LabsGqlOneTimeCodeLoginResult;
  oneTimeCodeVerification: Scalars['Boolean']['output'];
  openPendingAlignerCaseEstimateModal: LabsGqlPendingAlignerCaseDto;
  opsCancelOrder: LabsGqlLab_Order;
  optOutProductionOrder: LabsGqlLab_Order;
  optPracticeInToCreditCardSurcharge: LabsGqlPracticeBillingRecurringItemsDto;
  optPracticeOutOfCreditCardSurcharge: LabsGqlPracticeBillingRecurringItemsDto;
  overrideDentistAmountDue: LabsGqlLab_Order;
  overrideLabCaseUnitPrice: LabsGqlLabCaseLedgerDto;
  overrideNonProdManufacturer: LabsGqlLab_Order;
  overridePendingAlignerPatientPrice: LabsGqlPendingAlignerCaseDto;
  patient_portal_link: Scalars['String']['output'];
  payContractInvoicesWithPaymentSource: LabsGqlInvoicePaymentDto;
  payInvoiceWithConfig: Array<LabsGqlInvoicePaymentDto>;
  payInvoiceWithManualStripePayment: LabsGqlInvoicePaymentDto;
  payInvoiceWithPaymentSource: LabsGqlInvoicePaymentDto;
  placeDenturesFulfillmentCore: LabsGqlLab_Order;
  placeDenturesFulfillmentWaxRim: LabsGqlLab_Order;
  placeLabOrderFromTryIn: LabsGqlLab_Order;
  placeOrder: Array<LabsGqlLab_Order>;
  postCreatePracticeOnboarding: LabsGqlPracticeOnboardingDto;
  practiceRespondToDoctorRequest: LabsGqlDoctorRequestDto;
  practiceRespondToRejectedScan: LabsGqlLab_Order;
  preemptDesignTaskTimeout: LabsGqlDesignStaffDto;
  printableLabSlipViewed: LabsGqlLab_Order;
  process3oxzFile: LabsGqlScan_Export_Processed;
  process3oxzFileScanner: LabsGqlScan_Export_Processed;
  processThreeshapeScanNotifications: LabsGqlProcessScanNotificationsRes;
  /** Promotes the design revisision given by `revisionId` to be the "latest" revision. */
  promoteDesignRevisionToLatest: Scalars['Boolean']['output'];
  /** Requeues manufacturing file clean up for a list of lab orders */
  queueManufacturingFileCleanUp: Array<Scalars['Boolean']['output']>;
  /** Requeues manufacturing file processing for a list of lab orders */
  queueManufacturingFileProcessing: Array<Scalars['Boolean']['output']>;
  /** @deprecated This endpoint no longer queues up a job */
  queueZeroOutModelsBulkPricingJob?: Maybe<LabsGqlOrderPriceAdjustmentBulkJobStatus>;
  reOnboardDesignStaff: LabsGqlDesignStaffDto;
  reactivateDoctorPreferences: LabsGqlDoctorPreferences;
  reactivateOrganizationStaffMember: LabsGqlOrganizationDto;
  recomputeOrder: LabsGqlLab_Order;
  recordActivationFee: LabsGqlPracticeBillingRecurringItemsDto;
  recordDesignDownload: LabsGqlLab_Order;
  recordDesignDownloadedManufacturerBulk: LabsGqlManufacturerBulkMutationResult;
  recordInternalRemake: Scalars['Boolean']['output'];
  recordLabSlipsViewedByLabOrderIds: Scalars['Int']['output'];
  recordPackingCompletedForManufacturingOrders: Scalars['Int']['output'];
  recordPackingSheetViewed: LabsGqlLab_Order;
  recordPartnerActivationDate: LabsGqlIPartnerBilling;
  /** Records that the order was scanned in the shipping department, returning the batch candidates */
  recordShippingDeptScanAndFetchBatchCandidates: LabsGqlBatchShipCandidatesSearchResult;
  /** Records that the order was scanned in the shipping department, returning whether/how to batch */
  recordShippingDeptScanAndFetchBatchDecision: LabsGqlBatchShipDecisionResult;
  recordUpgradeFee: LabsGqlPracticeBillingRecurringItemsDto;
  refreshImagesForProcessedScan: LabsGqlScan_Export;
  refreshShipmentTracking: LabsGqlIShipment;
  refreshShippingStatus: LabsGqlUpdate_Delivery_Statuses_Result;
  refundInvoicePayment: LabsGqlInvoicePaymentDto;
  registerActivationFeeCharge: LabsGqlPracticeBillingRecurringItemsDto;
  registerInvoiceItem: LabsGqlPracticeBillingRecurringItemsDto;
  rejectPendingAlignerCase: LabsGqlPendingAlignerCaseDto;
  rejectTreatmentPlan: LabsGqlLab_Order;
  releaseTicketAction: LabsGqlTicketDto;
  removeAbutmentPartToImplantScanbody: LabsGqlImplantTypeDto;
  removeEntityTag: LabsGqlTaggedEntityDto;
  removeFulfillmentShipping: LabsGqlLab_Order;
  removeInvoiceItem: LabsGqlInvoiceDto;
  removeLabSlipAlerts: LabsGqlLab_Order;
  /** Remove an order from a given shipment */
  removeOrderFromShipment: Scalars['Boolean']['output'];
  removeOrderHold: LabsGqlLab_Order;
  removeOrganizationStaffMember: LabsGqlOrganizationDto;
  removePaymentMethod: LabsGqlStripePaymentId;
  removePreferenceStaffMember: LabsGqlDoctorPreferences;
  removePriceApplicationRule: LabsGqlIPrice;
  removeScannerOwner: LabsGqlScannerEntity;
  removeSendersFromPref: Scalars['Boolean']['output'];
  removeUsagePaymentSplitConfig: LabsGqlIPartnerBilling;
  replaceRejectedScan: LabsGqlLab_Order;
  replaceRemovableManufacturingFiles: LabsGqlDesignOrderDto;
  replaceThreeoxz: LabsGqlLab_Order;
  /** Schedules all the DMEs with the provided IDs for processing. */
  reprocessThreeshapeDmes: Scalars['Boolean']['output'];
  requestOrderRefinement: LabsGqlLab_Order;
  requestPrecheckoutScanReview: LabsGqlRequestPrecheckoutScanReviewRes;
  rerouteLabOrder: LabsGqlLab_Order;
  rerouteOrders: Array<LabsGqlRerouteLabOrderResult>;
  rerunDesignConversion: Scalars['Boolean']['output'];
  /** Internal tool for re-submitting orders to Automate, see https://github.com/orthly/orthlyweb/pull/19491 */
  resendOrdersToAutomate: Array<LabsGqlResendOrdersToAutomateResult>;
  /** [DEV] Reset booking sync data. */
  resetManufacturingBookings: LabsGqlResultDto;
  resolveOrderReviewFlag: LabsGqlLab_Order;
  resolveOrderScanRejection: LabsGqlLab_Order;
  restoreImplantType: LabsGqlImplantTypeDto;
  restoreManufacturerTruckTime: LabsGqlManufacturerTruckTimeEntity;
  restoreScanbody: LabsGqlScanbodyDto;
  /** [DEV] Run the start sync workflow. */
  runManufacturingBookingStartSyncWorkflow: LabsGqlRunWorkflowDto;
  /** [DEV] Run the sync workflow. */
  runManufacturingBookingSyncWorkflow: LabsGqlRunWorkflowDto;
  runRescheduleAndReassign: LabsGqlProductionPlanMutationResult;
  salesOrderAddItems: LabsGqlSalesOrderMutationResultDto;
  salesOrderChangeDeliveryAddress: LabsGqlSalesOrderMutationResultDto;
  salesOrderChangeDoctorPreferences: LabsGqlSalesOrderMutationResultDto;
  salesOrderDeleteItem: LabsGqlSalesOrderMutationResultDto;
  salesOrderUpdateItems: LabsGqlSalesOrderMutationResultDto;
  salesforceUserSync: Scalars['Boolean']['output'];
  saveDoctorLabSlipAlerts: LabsGqlDoctorPreferences;
  saveLabSlipAlerts: LabsGqlLab_Order;
  schedule3oxzToStlConversion: Scalars['Boolean']['output'];
  sendAttachPhotosUploadLink: Scalars['Boolean']['output'];
  sendCheckoutImagesUploadLink: Scalars['Boolean']['output'];
  sendDoctorPrefsUpdatedNotification: Scalars['Boolean']['output'];
  sendInviteEmail: Scalars['Boolean']['output'];
  sendInvoiceSummaryEmail: LabsGqlBillingEmailResult;
  sendMobileAppLinks: Scalars['Boolean']['output'];
  sendOneTimeCode: Scalars['String']['output'];
  sendOrderToAutomate: Scalars['Boolean']['output'];
  sendOrderToFloor: LabsGqlLab_Order;
  sendOrdersToFloorBulk: Array<LabsGqlLab_Order>;
  sendRefabFilesUploadLink: Scalars['Boolean']['output'];
  sendResetPasswordEmail: Scalars['Boolean']['output'];
  sendScanbodySurgicalReportUploadLink: Scalars['Boolean']['output'];
  /** @deprecated This is only called by services, and will be removed as a command soon. */
  setAlignerManufacturerTreatmentPlan: LabsGqlLab_Order;
  setCbctScanUrl: LabsGqlLab_Order;
  setChatMessageVisibility: LabsGqlChatMessageDto;
  setDefaultPaymentMethod: LabsGqlStripePaymentId;
  setDesignStaffPrepCapabilities: LabsGqlDesignStaffDto;
  setDoctorNotificationPrefs: LabsGqlDoctorPreferences;
  setDoctorPreferences: LabsGqlDoctorPreferences;
  setDoctorPreferencesId: LabsGqlLab_Order;
  setDoctorPrefsForwarding: LabsGqlDoctorPreferences;
  setDoctorProfilePhoto: LabsGqlDoctorPreferences;
  setManufacturerNotificationPreferences: LabsGqlManufacturerNotificationPreferences;
  setNonDoctorPracticeStaff: LabsGqlNonDoctorPracticeStaffDto;
  setOrderAutomationFolder: LabsGqlOrderAutomation;
  setPartnerNotificationPreferences: LabsGqlPartnerNotificationPreferences;
  setPartnerScanners: LabsGqlScannerResponse;
  setPracticeContractV2EndDate: LabsGqlFullyResolvedPracticeContractDto;
  setPracticeScanStart: LabsGqlPracticeOnboardingDto;
  setPreferredManufacturers: LabsGqlDoctorPreferences;
  setPriceSalesforceMapping: LabsGqlIPrice;
  setReceiversOnPref: Scalars['Boolean']['output'];
  setSalesforcePriceMapping: LabsGqlLabPriceDto;
  setScannerOwner: LabsGqlScannerEntity;
  setSingleClinicalPreference: LabsGqlDoctorPreferences;
  setSmileStyle: LabsGqlLab_Order;
  setTreatmentPlanRequestUrl: LabsGqlLab_Order;
  setUsagePaymentSplitConfigDoctor: LabsGqlIPartnerBilling;
  setUsagePaymentSplitConfigLocation: LabsGqlIPartnerBilling;
  setUsagePaymentSplitConfigPercent: LabsGqlIPartnerBilling;
  setWillBeChargedCcFee: LabsGqlIPartnerBilling;
  sharePatientScan?: Maybe<Scalars['String']['output']>;
  shareScanRejectionWithPractice: LabsGqlLab_Order;
  skipCase: Scalars['Boolean']['output'];
  /** [DEV] Manually skip bookings. */
  skipManufacturingBookings: LabsGqlResultDto;
  skipMillingAutoNestingForAllOrders: Scalars['Boolean']['output'];
  skipMillingAutoNestingForOrder: Scalars['Boolean']['output'];
  /** @deprecated Replaced by skipOnboardingV2 */
  skipOnboarding: LabsGqlPracticeOnboardingDto;
  skipOnboardingV2: Scalars['Boolean']['output'];
  /** Starts a Design Editor session, returning a unique identifier for the session */
  startDesignEditorSession: Scalars['String']['output'];
  startFulfillmentTask: LabsGqlLab_Order;
  startOnboarding: LabsGqlPracticeOnboardingDto;
  startOrderIntegrationManualV2: LabsGqlStartOrderIntegrationManualV2Result;
  /** Starts a Dandy Prep session, returning a unique identifier for the session */
  startPrepInBrowser: Scalars['String']['output'];
  submitAutomateReview: LabsGqlDesignOrderDto;
  /** Submit Automate review feedback for the training design order. */
  submitAutomateReviewForTraining: Scalars['Boolean']['output'];
  submitDesign: LabsGqlDesignOrderDesignRevisionDto;
  submitDesignEditor: Scalars['Boolean']['output'];
  submitDesignEditorForTraining: Scalars['Boolean']['output'];
  submitDesignQaEvaluation: LabsGqlDesignQaEvaluationDto;
  submitFinishingInBrowser: Scalars['Boolean']['output'];
  submitFinishingInBrowserForTraining: Scalars['Boolean']['output'];
  submitOrderScanReview: LabsGqlLab_Order;
  submitPendingAlignerCaseEstimate: LabsGqlPendingAlignerCaseDto;
  /** @deprecated This mutation is no longer used. */
  submitPracticeFeedback: Scalars['Boolean']['output'];
  submitPrepInBrowser: Scalars['Boolean']['output'];
  submitPrepInBrowserForTraining: Scalars['Boolean']['output'];
  submitQcFlag: LabsGqlLab_Order;
  submitVariantFeedback: LabsGqlVariantFeedbackDto;
  submitWaxupReview: LabsGqlLab_Order;
  syncOrderPrices: Array<LabsGqlLab_Order>;
  syncPricePresetsToSalesforce: Scalars['Boolean']['output'];
  tagEntity: LabsGqlTag;
  /** Migrate a lab order's sla data to the tat service. Idempotent. */
  tatMigrate: LabsGqlTat;
  /** Start the workflow for migrating lab orders to tat. */
  tatMigrateWorkflow: Scalars['String']['output'];
  /** Synchronize a tat with a lab order. Idempotent. */
  tatSync: LabsGqlTat;
  /** Upsert an add-on lead time. */
  tatUpsertAddOnLeadTime: LabsGqlTatAddOnLeadTimeDto;
  /** Upsert an item lead time. */
  tatUpsertItemLeadTime: LabsGqlTatItemLeadTimeDto;
  /** Upsert an item lead time override. */
  tatUpsertItemLeadTimeOverride: LabsGqlTatItemLeadTimeOverrideDto;
  /** Used to manually test the integration with labtrac, using the items from the provided order ID. */
  testInternalLabtracIntegrationV2: LabsGqlLabtracIntegrationV2TestResult;
  testOrderAutomation: LabsGqlTestOrderAutomationResult;
  toggleArchiveCategory: LabsGqlCategoryDto;
  toggleDeliveryAddressDeleted: LabsGqlPartnerDeliveryAddress;
  toggleLabCaseLedgerEntryReviewed: LabsGqlLabCaseLedgerDto;
  toggleLabLedgerEntryReviewed: LabsGqlLabPriceLedgerDto;
  toggleLabPriceDeletion: LabsGqlLabPriceDto;
  transitionOnboardingStep: Scalars['Boolean']['output'];
  /** @deprecated Replaced by transitionOnboardingStep */
  transitionStep?: Maybe<LabsGqlPracticeOnboardingDto>;
  unarchiveAbutmentPart: LabsGqlAbutmentPartDto;
  /** Unarchive smile library */
  unarchiveSmileLibrary: LabsGqlCadSmileLibraryDto;
  unarchiveThreeshapeDme: LabsGqlThreeshapeDmeDto;
  unblockStacks: Scalars['Boolean']['output'];
  undeleteTag: LabsGqlTag;
  undoLinkOrderToRefabrication: LabsGqlLab_Order;
  unfavoriteSavedSearch: LabsGqlSavedSearch;
  unfavoriteTicketSavedSearch: LabsGqlTicketingSavedSearch;
  unlinkOrganizationExternalAccount: LabsGqlOrganizationDto;
  untagEntity: LabsGqlTag;
  updateAbutmentPart: LabsGqlAbutmentPartDto;
  updateAbutmentPartToImplantScanbody: LabsGqlImplantTypeDto;
  updateActionCapability: LabsGqlActionCapabilityDto;
  updateActionCategory: LabsGqlActionCategoryDto;
  updateActionType: LabsGqlActionTypeDto;
  updateAndCreateOrderPrices: Array<LabsGqlOrderPriceEntityDto>;
  updateBiasRule: LabsGqlBiasRule;
  updateBillingCreditCategory: LabsGqlBillingCreditCategory;
  updateCRMChatConversation: LabsGqlCrmChatConversation;
  updateCRMTicket: Scalars['Boolean']['output'];
  updateCapacityRule: LabsGqlCapacityRule;
  updateCapacityStation: LabsGqlCapacityStationDto;
  updateCapacityStationPool: LabsGqlCapacityStationPoolDto;
  updateCapacityStationRule: LabsGqlCapacityStationRuleDto;
  updateCategory: LabsGqlCategoryDto;
  updateCurrentRetainer: LabsGqlLab_Order;
  updateDesignReviewField: LabsGqlDesignReviewFieldDto;
  updateDesignRule: LabsGqlDesignRule;
  updateDesignStaffRosterInfo: LabsGqlDesignStaffDto;
  updateDesignStaffStatus: LabsGqlDesignStaffDto;
  updateExternalStaffMemberRoles: LabsGqlOrganizationDto;
  updateImplantType: LabsGqlImplantTypeDto;
  updateInternalStaffMemberRoles: LabsGqlOrganizationDto;
  updateInvoiceCredit: LabsGqlInvoiceCreditDto;
  updateIteroSettingsOfCurrentUser: LabsGqlIteroUserSettings;
  updateLabCaseLedgerEntry: LabsGqlLabCaseLedgerDto;
  updateLabOrderLockStatus: Scalars['Boolean']['output'];
  updateLabOrderSplits: LabsGqlLabOrderUpdateSplitsResult;
  updateLabPrice: LabsGqlLabPriceDto;
  updateLabStaffMemberRoles: LabsGqlOrganizationDto;
  updateLiveLabTechMeetingRequestNotes: Scalars['Boolean']['output'];
  updateManufacturerTruckTime: LabsGqlManufacturerTruckTimeEntity;
  updateOrCreateIteroCompany: LabsGqlIteroCompanyEntity;
  updateOrCreateIteroUser?: Maybe<LabsGqlIteroUserEntity>;
  updateOrderAutomation: LabsGqlOrderAutomation;
  updateOrderClinicalSupportNotes: LabsGqlLab_Order;
  updateOrderHold: LabsGqlLab_Order;
  updateOrderItemPennyweightsV2: LabsGqlLab_Order;
  updateOrderItemsV2: LabsGqlLab_Order;
  updateOrderItemsV2Practice: LabsGqlLab_Order;
  updateOrderPriceEntry: LabsGqlOrderPriceDto;
  updateOrganizationName: LabsGqlOrganizationDto;
  updateOrganizationParentId: LabsGqlOrganizationDto;
  updateOrganizationPhoneNumber: LabsGqlOrganizationDto;
  updateOrganizationPriceConfig: LabsGqlOrganizationPriceConfigDto;
  updateOrganizationSettings: LabsGqlOrganizationDto;
  updateOrganizationStaffMemberZendeskId: LabsGqlOrganizationDto;
  updateOrganizationType: LabsGqlOrganizationDto;
  updateParentStaffMemberRoles: LabsGqlOrganizationDto;
  updatePartialDentureMetadata: LabsGqlLab_Order;
  updatePaymentAmount: LabsGqlInvoicePaymentDto;
  updatePracticeOrderingPauseReasons: LabsGqlOrderingPausedPracticeDto;
  updatePracticeSettings: LabsGqlOrganizationDto;
  updatePracticeStaffMemberRoles: LabsGqlOrganizationDto;
  updatePracticeSupport: LabsGqlPracticeSupportDto;
  updatePricePreset: LabsGqlIPricePreset;
  updatePrimaryBillingContactUser: LabsGqlIPartnerBilling;
  updatePrimaryBillingContactUserId: LabsGqlIPartnerBilling;
  updateProductStationUnitMappingUnits: LabsGqlProductStationUnitMappingDto;
  updateQcIssue: LabsGqlQcIssue;
  updateReasonCode: LabsGqlReasonCodeDto;
  updateRefabReason: LabsGqlRefabReasonDto;
  updateRefundCategory: LabsGqlRefundCategoryDto;
  updateRegisteredInvoiceItem: LabsGqlPracticeBillingRecurringItemsDto;
  updateReviewSubmissionItemTags: LabsGqlIReviewSubmission;
  updateReviewTag: LabsGqlReviewTag;
  updateRoutingTargetDistributionRule: LabsGqlRoutingTargetDistributionRule;
  updateRushConfiguration: LabsGqlSlaPreviewResult;
  updateScanbody: LabsGqlScanbodyDto;
  updateScanbodyRequest: LabsGqlScanbodyRequestDto;
  updateStaffMemberOnboardingPreferences: LabsGqlStaffMemberOnboardingDto;
  updateStaffMemberOnboardingPreferencesById: LabsGqlStaffMemberOnboardingDto;
  updateStripeLinking: LabsGqlIPartnerBilling;
  updateThreeshapeDmeFilePath: LabsGqlThreeshapeDmeDto;
  updateUser: LabsGqlUserDto;
  updateUserLoginPhone: LabsGqlUserDto;
  updateUserPassword: LabsGqlUserDto;
  updateWorkflowStepDefinition: LabsGqlWorkflowStepDefinition;
  upsertDesignStaff: LabsGqlDesignStaffDto;
  upsertManufacturerProfile: LabsGqlManufacturerProfile;
  upsertMetalCostsBulk: Array<LabsGqlLabMetalCostDto>;
  upsertPartnerPriceConfig: LabsGqlIPrice;
  verifyLoginPhoneNumber: Scalars['Boolean']['output'];
  voidInvoice?: Maybe<LabsGqlInvoiceDto>;
  withdrawFromDesignTrainingCourse: Scalars['Boolean']['output'];
};


export type LabsGqlMutationAcceptAutomateDesignRevisionArgs = {
  automate_id?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationAcknowledgeAllLabUpdatesArgs = {
  max_update_date: Scalars['DateTime']['input'];
};


export type LabsGqlMutationAcknowledgeLabUpdatesForOrderArgs = {
  max_update_date: Scalars['DateTime']['input'];
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationAcknowledgeManualEntryLmsOrderArgs = {
  args: Array<LabsGqlAcknowledgeManualEntryLmsOrderArgs>;
};


export type LabsGqlMutationActivateOrganizationStaffMemberArgs = {
  data: LabsGqlActivateOrganizationStaffMemberCommand;
};


export type LabsGqlMutationActivatePracticeContractArgs = {
  data: LabsGqlActivateContractEntityInput;
};


export type LabsGqlMutationAddAbutmentPartToImplantScanbodyArgs = {
  data: LabsGqlAddAbutmentPartToImplantScanbodyCommand;
};


export type LabsGqlMutationAddCrmTicketTagsArgs = {
  args: LabsGqlAddCrmTicketTagsArgs;
};


export type LabsGqlMutationAddCadSmileLibraryRevisionArgs = {
  libraryId: Scalars['String']['input'];
  previewImagePath: Scalars['String']['input'];
  revisionId: Scalars['String']['input'];
  templates: Array<LabsGqlCadSmileLibraryToothTemplateDto>;
};


export type LabsGqlMutationAddEntityTagArgs = {
  data: LabsGqlAddEntityTagCommand;
};


export type LabsGqlMutationAddExternalStaffMemberArgs = {
  data: LabsGqlAddExternalStaffMemberCommand;
};


export type LabsGqlMutationAddInternalStaffMemberArgs = {
  data: LabsGqlAddInternalStaffMemberCommand;
};


export type LabsGqlMutationAddLabOrderPhotosArgs = {
  data: LabsGqlAddLabOrderPhotosCommand;
};


export type LabsGqlMutationAddLabOrderPhotosWithNotesArgs = {
  data: LabsGqlAddLabOrderPhotosWithNotesCommand;
};


export type LabsGqlMutationAddLabSlipAlertArgs = {
  data: LabsGqlAddLabSlipAlertCommand;
};


export type LabsGqlMutationAddLabStaffMemberArgs = {
  data: LabsGqlAddLabStaffMemberCommand;
};


export type LabsGqlMutationAddManualQualityControlItemArgs = {
  data: LabsGqlAddManualQualityControlItemCommand;
};


export type LabsGqlMutationAddOrderItemArgs = {
  data: LabsGqlAddOrderItemCommand;
};


export type LabsGqlMutationAddOrderItemsV2Args = {
  data: LabsGqlAddOrderItemsV2Command;
};


export type LabsGqlMutationAddOrderToShipmentArgs = {
  orderId: Scalars['String']['input'];
  shipmentId: Scalars['String']['input'];
};


export type LabsGqlMutationAddParentStaffMemberArgs = {
  data: LabsGqlAddParentStaffMemberCommand;
};


export type LabsGqlMutationAddPhoneToDrPrefAndUserArgs = {
  args: LabsGqlAddPhoneToDrPrefAndUserArgs;
};


export type LabsGqlMutationAddPracticeStaffMemberArgs = {
  data: LabsGqlAddPracticeStaffMemberCommand;
};


export type LabsGqlMutationAddPriceApplicationRuleArgs = {
  data: LabsGqlAddPriceApplicationRuleCommand;
};


export type LabsGqlMutationAddReasonCodeRuleArgs = {
  data: LabsGqlAddReasonCodeRuleCommand;
};


export type LabsGqlMutationAddRefabReasonRuleArgs = {
  data: LabsGqlAddRefabReasonRuleCommand;
};


export type LabsGqlMutationAddRetainerToAlignerCaseArgs = {
  data: LabsGqlAddRetainerToAlignerCaseCommand;
};


export type LabsGqlMutationAgreeToCreditCardFeesArgs = {
  agreedToCCFees: Scalars['Boolean']['input'];
  partnerId: Scalars['String']['input'];
};


export type LabsGqlMutationAppendTicketActionArgs = {
  data: LabsGqlAppendTicketActionCommand;
};


export type LabsGqlMutationApplyInvoiceCreditToInvoiceArgs = {
  data: LabsGqlApplyInvoiceCreditToInvoiceInput;
};


export type LabsGqlMutationApplyPricePresetArgs = {
  data: LabsGqlApplyPricePresetCommand;
};


export type LabsGqlMutationApproveAlignerFabricationArgs = {
  data: LabsGqlApproveAlignerFabricationCommand;
};


export type LabsGqlMutationArchiveAbutmentPartArgs = {
  data: LabsGqlArchiveAbutmentPartCommand;
};


export type LabsGqlMutationArchiveCadSmileLibraryArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlMutationArchiveOrderAutomationArgs = {
  data: LabsGqlArchiveOrderAutomationCommand;
};


export type LabsGqlMutationArchiveProductCatalogEntryArgs = {
  input: LabsGqlArchiveProductCatalogEntryInput;
};


export type LabsGqlMutationArchiveQcItemConfigArgs = {
  data: LabsGqlArchiveQcItemConfigCommand;
};


export type LabsGqlMutationArchiveThreeshapeDmeArgs = {
  data: LabsGqlArchiveThreeshapeDmeCommand;
};


export type LabsGqlMutationAssignFulfillmentTaskArgs = {
  data: LabsGqlAssignFulfillmentTaskCommand;
};


export type LabsGqlMutationAttachAlignerPreppedScansArgs = {
  data: LabsGqlAttachAlignerPreppedScansCommand;
};


export type LabsGqlMutationAttachDesignAssetArgs = {
  designAsset: LabsGqlDesignOrderDesignRevisionAssetInput;
  orderId: Scalars['String']['input'];
};


export type LabsGqlMutationAttachDesignFilesArgs = {
  data: LabsGqlAttachDesignFilesCommand;
};


export type LabsGqlMutationAttachExternalAlignerFulfillmentArgs = {
  data: LabsGqlAttachExternalAlignerFulfillmentCommand;
};


export type LabsGqlMutationAttachQcPhotosArgs = {
  data: LabsGqlAttachQcPhotosCommand;
};


export type LabsGqlMutationAttachScanStlFilesArgs = {
  data: LabsGqlAttachScanExportStlsCommand;
};


export type LabsGqlMutationAttachTreatmentPlanRequestArgs = {
  data: LabsGqlAttachTreatmentPlanRequestCommand;
};


export type LabsGqlMutationBatchOrderShipmentsArgs = {
  orderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBatchOrderShipmentsAndRecordPackingCompletedArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBlockStacksArgs = {
  labId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  stackCodes: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBulkBackfillDesignOrderDoctorReviewsArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBulkCreateCreditsWorkflowArgs = {
  data: LabsGqlBulkCreateCreditsWorkflowInput;
};


export type LabsGqlMutationBulkCreateDesignOrdersArgs = {
  lab_order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBulkCreateDesignStaffTeamsArgs = {
  names: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBulkCreateScanbodyRequestsArgs = {
  args: LabsGqlBulkCreateScanbodyRequestCommand;
};


export type LabsGqlMutationBulkCreateUsersArgs = {
  args: LabsGqlBulkCreateUsersCommand;
};


export type LabsGqlMutationBulkDeleteAutomationsArgs = {
  automation_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBulkDeleteDesignStaffTeamsArgs = {
  team_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBulkOverrideLabUnitPricesArgs = {
  overrides: LabsGqlLabBulkOverrideOrderUnitPriceArgs;
};


export type LabsGqlMutationBulkRegisterInvoiceItemsArgs = {
  data: LabsGqlBulkRegisterInvoiceItemCommand;
};


export type LabsGqlMutationBulkRemoveStaffMembersArgs = {
  data: LabsGqlBulkRemoveStaffMembersCommand;
};


export type LabsGqlMutationBulkRestoreAutomationsArgs = {
  automation_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBulkTagEntitiesArgs = {
  entityIds: Array<Scalars['String']['input']>;
  tagId: Scalars['String']['input'];
};


export type LabsGqlMutationBulkToggleWillBeChargedCcFeeArgs = {
  data: LabsGqlBulkToggleWillBeChargedCcFeeCommand;
};


export type LabsGqlMutationBulkUpdateAbutmentPartScrewSkuArgs = {
  part_updates: Array<LabsGqlAbutmentPartScrewSkuDatum>;
};


export type LabsGqlMutationBulkUpdateDesignStaffRosterArgs = {
  uploadPath: Scalars['String']['input'];
};


export type LabsGqlMutationBulkUpdateOrderPricesArgs = {
  updates: Array<LabsGqlUpdateOrderPriceEntryCommand>;
};


export type LabsGqlMutationBulkUpdateOrganizationIsFakeStatusArgs = {
  is_fake: Scalars['Boolean']['input'];
  organization_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationBulkUpdatePartnerBillingConfigFlagArgs = {
  update: LabsGqlPartnerBillingBulkUpdateFlagCommand;
};


export type LabsGqlMutationBulkUpsertLabPriceLabPricesArgs = {
  data: LabsGqlBulkUpsertLabPriceLabPrices;
};


export type LabsGqlMutationBulkUpsertOrganizationPricesArgs = {
  data: LabsGqlBulkUpsertOrganizationPricesCommand;
};


export type LabsGqlMutationBulkUpsertPricesArgs = {
  upserts: Array<LabsGqlBulkUploadPartnerPriceUpsert>;
};


export type LabsGqlMutationCancelAndResubmitArgs = {
  data: LabsGqlCancelAndResubmitCommand;
};


export type LabsGqlMutationCancelDesignQaEvaluationsArgs = {
  evaluation_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationCancelLiveCasePlanningRequestArgs = {
  calendly_invite_event_id: Scalars['String']['input'];
};


export type LabsGqlMutationCancelLiveDdpReviewRequestArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationCancelLiveDdpReviewRequestByEventIdArgs = {
  calendly_invite_event_id: Scalars['String']['input'];
};


export type LabsGqlMutationCancelLiveLabTechMeetingRequestArgs = {
  calendly_invite_event_id: Scalars['String']['input'];
};


export type LabsGqlMutationCancelManufacturingOrderArgs = {
  manufacturing_order_id: Scalars['String']['input'];
};


export type LabsGqlMutationCancelOrderAutomateTaskArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationCancelOrderReturnArgs = {
  data: LabsGqlCancelOrderReturnCommand;
};


export type LabsGqlMutationCancelShipmentArgs = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  shipmentId: Scalars['String']['input'];
};


export type LabsGqlMutationCarestreamPreUploadArgs = {
  caseId: Scalars['String']['input'];
};


export type LabsGqlMutationChangeDeliveryAddressArgs = {
  data: LabsGqlChangeDeliveryAddressCommand;
};


export type LabsGqlMutationChangeDesignSoftwareArgs = {
  data: LabsGqlChangeDesignSoftwareCommand;
};


export type LabsGqlMutationChangeLabOrderManufacturerArgs = {
  data: LabsGqlChangeManufacturerCommand;
};


export type LabsGqlMutationChangeManufacturerBulkArgs = {
  disable_notification: Scalars['Boolean']['input'];
  disable_sla_reset: Scalars['Boolean']['input'];
  manufacturerId: Scalars['String']['input'];
  order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationChangePreferenceStaffMemberArgs = {
  data: LabsGqlChangePreferenceStaffMemberCommand;
};


export type LabsGqlMutationChangePriceNameArgs = {
  data: LabsGqlChangePriceNameCommand;
};


export type LabsGqlMutationClaimTicketActionArgs = {
  data: LabsGqlClaimTicketActionCommand;
};


export type LabsGqlMutationCloseTicketArgs = {
  data: LabsGqlCloseTicketCommand;
};


export type LabsGqlMutationCloseTicketActionArgs = {
  data: LabsGqlCloseTicketActionCommand;
};


export type LabsGqlMutationCollectNotificationPreferencesArgs = {
  data: LabsGqlCollectNotificationPreferencesCommand;
};


export type LabsGqlMutationCollectPartialPaymentArgs = {
  data: LabsGqlPartialPaymentInput;
};


export type LabsGqlMutationCompleteDesignPrepTaskArgs = {
  data: LabsGqlCompleteDesignPrepTaskCommand;
};


export type LabsGqlMutationCompleteDesignReviewTaskArgs = {
  data: LabsGqlCompleteDesignReviewTaskCommand;
};


export type LabsGqlMutationCompleteExternalDesignVerificationTaskArgs = {
  data: LabsGqlCompleteExternalDesignVerificationTaskCommand;
};


export type LabsGqlMutationCompleteInternalDesignTaskArgs = {
  data: LabsGqlCompleteInternalDesignTaskCommand;
};


export type LabsGqlMutationCompleteModelDesignTaskArgs = {
  data: LabsGqlCompleteModelDesignTaskCommand;
};


export type LabsGqlMutationCompleteQualityControlItemArgs = {
  data: LabsGqlCompleteQualityControlItemCommand;
};


export type LabsGqlMutationCompleteTreatmentPlanConversionArgs = {
  data: LabsGqlCompleteTreatmentPlanConversionCommand;
};


export type LabsGqlMutationConfigureDesignReview2CommandArgs = {
  data: LabsGqlConfigureDesignReview2Command;
};


export type LabsGqlMutationConfigureLabPriceLabPricesArgs = {
  data: LabsGqlConfigureLabPriceLabPricesCommand;
};


export type LabsGqlMutationConfigureLabPricePartnerPricesArgs = {
  data: LabsGqlConfigureLabPricePartnerPricesCommand;
};


export type LabsGqlMutationConfigureOrderDesignReviewArgs = {
  data: LabsGqlConfigureOrderDesignReviewCommand;
};


export type LabsGqlMutationConfigureOrderDesignerTypeArgs = {
  data: LabsGqlConfigureOrderDesignerTypeCommand;
};


export type LabsGqlMutationConfigureOrderWaxupArgs = {
  data: LabsGqlConfigureOrderWaxupCommand;
};


export type LabsGqlMutationConfirmOrderReturnReceivedArgs = {
  data: LabsGqlConfirmOrderReturnReceivedCommand;
};


export type LabsGqlMutationContinueDenturesFulfillmentWaxRimArgs = {
  data: LabsGqlContinueDenturesFulfillmentWaxRimCommand;
};


export type LabsGqlMutationConvertPracticeToParentArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlMutationCreateAbutmentPartArgs = {
  data: LabsGqlCreateAbutmentPartCommand;
};


export type LabsGqlMutationCreateActionCapabilityArgs = {
  data: LabsGqlCreateActionCapabilityCommand;
};


export type LabsGqlMutationCreateActionCategoryArgs = {
  data: LabsGqlCreateActionCategoryCommand;
};


export type LabsGqlMutationCreateActionTypeArgs = {
  data: LabsGqlCreateActionTypeCommand;
};


export type LabsGqlMutationCreateAndDeleteFocusAreasArgs = {
  focus_areas: Array<LabsGqlDesignStaffFocusAreaInput>;
  user_id: Scalars['String']['input'];
};


export type LabsGqlMutationCreateAttributedCreditsArgs = {
  data: LabsGqlCreateAttributedCreditsInput;
};


export type LabsGqlMutationCreateBiasRuleArgs = {
  data: LabsGqlCreateBiasRuleCommand;
};


export type LabsGqlMutationCreateBillingCreditCategoryArgs = {
  data: LabsGqlCreateBillingCreditCategoryCommand;
};


export type LabsGqlMutationCreateCrmChatMessageArgs = {
  args: LabsGqlCreateCrmChatMessageArgs;
};


export type LabsGqlMutationCreateCrmTicketArgs = {
  args: LabsGqlCreateCrmTicketArgs;
};


export type LabsGqlMutationCreateCrmTicketCommentArgs = {
  args: LabsGqlCreateCrmTicketCommentArgs;
};


export type LabsGqlMutationCreateCrmUserArgs = {
  args: LabsGqlCreateCrmUserArgs;
};


export type LabsGqlMutationCreateCadSmileLibraryArgs = {
  name: Scalars['String']['input'];
};


export type LabsGqlMutationCreateCadSmileLibraryWithInitialRevisionArgs = {
  libraryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  previewImagePath?: InputMaybe<Scalars['String']['input']>;
  revisionId: Scalars['String']['input'];
  templates: Array<LabsGqlCadSmileLibraryToothTemplateDto>;
};


export type LabsGqlMutationCreateCapacityRuleArgs = {
  data: LabsGqlCreateCapacityRuleCommand;
};


export type LabsGqlMutationCreateCapacityStationArgs = {
  name: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  unit_type: LabsGqlCapacityLimitUnitType;
};


export type LabsGqlMutationCreateCapacityStationRuleArgs = {
  createCapacityStationRuleArgs: LabsGqlCreateCapacityStationRuleArgs;
};


export type LabsGqlMutationCreateCaseReadinessArgs = {
  data: LabsGqlCreateCaseReadinessCommand;
};


export type LabsGqlMutationCreateCategoryArgs = {
  data: LabsGqlCreateCategoryCommand;
};


export type LabsGqlMutationCreateChairsideReviewSubmissionArgs = {
  data: LabsGqlCreateChairsideReviewSubmissionCommand;
};


export type LabsGqlMutationCreateChatMessageArgs = {
  data: LabsGqlCreateChatMessageCommand;
};


export type LabsGqlMutationCreateCompletedScanningCaseArgs = {
  data: LabsGqlCreateCompletedScanningCaseCommand;
};


export type LabsGqlMutationCreateCrownProductCatalogEntryArgs = {
  input: LabsGqlCreateCrownProductCatalogEntryInput;
};


export type LabsGqlMutationCreateDeliveryAddressArgs = {
  data: LabsGqlPartnerDeliveryAddressInput;
};


export type LabsGqlMutationCreateDesignQaEvaluationArgs = {
  data: LabsGqlCreateDesignQaEvaluationCommand;
};


export type LabsGqlMutationCreateDesignReviewFieldArgs = {
  data: LabsGqlCreateDesignReviewFieldCommand;
};


export type LabsGqlMutationCreateDesignRuleArgs = {
  data: LabsGqlCreateDesignRuleCommand;
};


export type LabsGqlMutationCreateDesignStaffTeamArgs = {
  name: Scalars['String']['input'];
};


export type LabsGqlMutationCreateDoctorPreferencesArgs = {
  data: LabsGqlCreateDoctorPreferencesCommand;
};


export type LabsGqlMutationCreateDoctorRequestArgs = {
  data: LabsGqlCreateDoctorRequestCommand;
};


export type LabsGqlMutationCreateEmptyVariantFeedbackArgs = {
  data: LabsGqlCreateEmptyVariantFeedbackInput;
};


export type LabsGqlMutationCreateEnableCcFeeForAllWorkflowArgs = {
  args: LabsGqlCreateEnableCcFeeForAllWorkflowArgs;
};


export type LabsGqlMutationCreateExternalOrgArgs = {
  fields: LabsGqlCreateExternalOrgCommand;
};


export type LabsGqlMutationCreateFabricationBulkArgs = {
  order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationCreateFabricationBulkInternalArgs = {
  labId: Scalars['String']['input'];
  orderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationCreateFulfillmentArgs = {
  data: LabsGqlCreateFulfillmentCommand;
};


export type LabsGqlMutationCreateGoldilocksControlGroupMetadataArgs = {
  data: LabsGqlCreateOrderMetadataInput;
};


export type LabsGqlMutationCreateImplantTypeArgs = {
  data: LabsGqlCreateImplantTypeCommand;
};


export type LabsGqlMutationCreateInvoiceArgs = {
  data: LabsGqlCreateInvoiceInput;
};


export type LabsGqlMutationCreateInvoiceCreditArgs = {
  data: LabsGqlCreateInvoiceCreditInput;
};


export type LabsGqlMutationCreateLabCaseCompletionArgs = {
  data: LabsGqlCreateLabCaseCompletionCommand;
};


export type LabsGqlMutationCreateLabCaseLedgerEntryArgs = {
  data: LabsGqlCreateLabCaseLedgerEntryCommand;
};


export type LabsGqlMutationCreateLabMetalCostArgs = {
  data: LabsGqlCreateLabMetalCostCommand;
};


export type LabsGqlMutationCreateLabPriceArgs = {
  data: LabsGqlCreateLabPriceCommand;
};


export type LabsGqlMutationCreateLabPriceLedgerEntryArgs = {
  data: LabsGqlCreateLabPriceLedgerEntryCommand;
};


export type LabsGqlMutationCreateLabPriceLedgerPartnerEntryArgs = {
  data: LabsGqlCreateLabPriceLedgerPartnerEntryCommand;
};


export type LabsGqlMutationCreateLabsManufacturerArgs = {
  fields: LabsGqlCreateManufacturerCommand;
};


export type LabsGqlMutationCreateLiveCasePlanningRequestArgs = {
  args: LabsGqlCreateLiveCasePlanningRequestArgs;
};


export type LabsGqlMutationCreateLiveDdpReviewRequestArgs = {
  args: LabsGqlCreateLiveDdpReviewRequestArgs;
};


export type LabsGqlMutationCreateLiveLabTechMeetingRequestArgs = {
  args: LabsGqlCreateLiveLabTechMeetingRequestArgs;
};


export type LabsGqlMutationCreateManualLabOrderIntegrationAttemptArgs = {
  order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationCreateManufacturerTruckTimeArgs = {
  payload: LabsGqlCreateManufacturerTruckTimePayload;
};


export type LabsGqlMutationCreateManufacturingBookingsArgs = {
  bookingAction: Scalars['String']['input'];
  department: Scalars['String']['input'];
  isOvertime: Scalars['Boolean']['input'];
  machineId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
  stages: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationCreateMetalMaterialArgs = {
  data: LabsGqlCreateMetalMaterialCommand;
};


export type LabsGqlMutationCreateNonDoctorPracticeStaffArgs = {
  data: LabsGqlCreateNonDoctorPracticeStaffCommand;
};


export type LabsGqlMutationCreateOrFindChairsidePatientArgs = {
  data: LabsGqlCreateOrFindChairsidePatientCommand;
};


export type LabsGqlMutationCreateOrderAutomationArgs = {
  data: LabsGqlCreateOrderAutomationCommand;
};


export type LabsGqlMutationCreateOrderHoldArgs = {
  data: LabsGqlCreateOrderHoldCommand;
};


export type LabsGqlMutationCreateOrderItemVariantChargeArgs = {
  data: LabsGqlCreateOrderItemVariantChargeCommand;
};


export type LabsGqlMutationCreateOrderPriceBackfillWorkflowArgs = {
  args: LabsGqlCreateOrderPriceBackfillWorkflowArgs;
};


export type LabsGqlMutationCreateOrderPriceEntryArgs = {
  data: LabsGqlCreateOrderPriceEntryCommand;
};


export type LabsGqlMutationCreateOrganizationArgs = {
  data: LabsGqlCreateOrganizationCommand;
};


export type LabsGqlMutationCreateOrganizationPriceConfigArgs = {
  data: LabsGqlCreateOrganizationPriceConfigCommand;
};


export type LabsGqlMutationCreateParentOrgArgs = {
  data: LabsGqlParentOrgRegistrationFields;
};


export type LabsGqlMutationCreatePartnerArgs = {
  data: LabsGqlPartnerRegistrationFields;
};


export type LabsGqlMutationCreatePartnerBillingArgs = {
  data: LabsGqlCreatePartnerBillingCommand;
};


export type LabsGqlMutationCreatePasswordArgs = {
  args: LabsGqlCreatePasswordArgs;
};


export type LabsGqlMutationCreatePatientArgs = {
  data: LabsGqlCreatePatientCommand;
};


export type LabsGqlMutationCreatePaymentMethodArgs = {
  cardToken: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
  setAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlMutationCreatePendingAlignerCaseArgs = {
  data: LabsGqlCreatePendingAlignerCaseCommand;
};


export type LabsGqlMutationCreatePracticeCaseBulkArgs = {
  cases: Array<LabsGqlPracticeCaseInput>;
};


export type LabsGqlMutationCreatePracticeRequestMarketingMaterialsArgs = {
  data: LabsGqlCreatePracticeRequestMarketingMaterialsCommand;
};


export type LabsGqlMutationCreatePracticeRequestScheduleTrainingArgs = {
  data: LabsGqlCreatePracticeRequestScheduleTrainingCommand;
};


export type LabsGqlMutationCreatePracticeSupportArgs = {
  data: LabsGqlCreatePracticeSupportCommand;
};


export type LabsGqlMutationCreatePriceArgs = {
  data: LabsGqlCreatePriceCommand;
};


export type LabsGqlMutationCreatePricePresetArgs = {
  data: LabsGqlCreatePricePresetCommand;
};


export type LabsGqlMutationCreateProductStationUnitMappingArgs = {
  createProductStationUnitMappingArgs: LabsGqlCreateProductStationUnitMappingArgs;
};


export type LabsGqlMutationCreateQcIssueArgs = {
  data: LabsGqlCreateQcIssueCommand;
};


export type LabsGqlMutationCreateQcItemConfigArgs = {
  data: LabsGqlCreateQcItemConfigCommand;
};


export type LabsGqlMutationCreateReasonCodeArgs = {
  data: LabsGqlCreateReasonCodeCommand;
};


export type LabsGqlMutationCreateRebuildAggregateByIdsWorkflowArgs = {
  args: LabsGqlCreateRebuildAggregateByIdsWorkflowArgs;
};


export type LabsGqlMutationCreateRebuildAggregateWorkflowArgs = {
  args: LabsGqlCreateRebuildAggregateWorkflowArgs;
};


export type LabsGqlMutationCreateRefabReasonArgs = {
  data: LabsGqlCreateRefabReasonCommand;
};


export type LabsGqlMutationCreateRefundCategoryArgs = {
  name: Scalars['String']['input'];
};


export type LabsGqlMutationCreateReturnForRefabricationArgs = {
  data: LabsGqlCreateReturnForRefabricationCommand;
};


export type LabsGqlMutationCreateReturnForTryInArgs = {
  data: LabsGqlCreateReturnForTryInCommand;
};


export type LabsGqlMutationCreateReviewSubmissionV3Args = {
  data: LabsGqlCreateReviewSubmissionV3Command;
};


export type LabsGqlMutationCreateReviewTagArgs = {
  data: LabsGqlCreateReviewTagCommand;
};


export type LabsGqlMutationCreateRoutingTargetDistributionArgs = {
  data: LabsGqlCreateRoutingTargetDistributionRuleCommand;
};


export type LabsGqlMutationCreateSavedInboxSearchArgs = {
  data: LabsGqlCreateSavedInboxSearchCommand;
};


export type LabsGqlMutationCreateSavedOrderSearchArgs = {
  data: LabsGqlCreateSavedOrderSearchCommand;
};


export type LabsGqlMutationCreateSavedRoutingSearchArgs = {
  data: LabsGqlCreateSavedRoutingSearchCommand;
};


export type LabsGqlMutationCreateScanExportPreUploadArgs = {
  data: LabsGqlCreateScanFilePreUploadCommand;
};


export type LabsGqlMutationCreateScanExportPreUploadScannerArgs = {
  data: LabsGqlCreateScanFilePreUploadCommand;
};


export type LabsGqlMutationCreateScanExportPreUploadWithRawArgs = {
  raw_3ox: Scalars['String']['input'];
  raw_case: Scalars['String']['input'];
  source?: InputMaybe<LabsGqlScanExportSource>;
  source_version?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationCreateScanExportPreUploadWithRawScannerArgs = {
  raw_3ox: Scalars['String']['input'];
  raw_case: Scalars['String']['input'];
  source?: InputMaybe<LabsGqlScanExportSource>;
  source_version?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationCreateScanbodyArgs = {
  data: LabsGqlCreateScanbodyCommand;
};


export type LabsGqlMutationCreateScanbodyRequestArgs = {
  data: LabsGqlCreateScanbodyRequestCommand;
};


export type LabsGqlMutationCreateScannerArgs = {
  data: LabsGqlCreateScannerCommand;
};


export type LabsGqlMutationCreateShadeSuggestionWithPhotosArgs = {
  args: LabsGqlCreateShadeSuggestionWithPhotosDto;
};


export type LabsGqlMutationCreateShippingLabelArgs = {
  data: LabsGqlCreateShippingLabelCommand;
};


export type LabsGqlMutationCreateTagArgs = {
  data: LabsGqlCreateTagCommand;
};


export type LabsGqlMutationCreateThreeshapeDmeArgs = {
  data: LabsGqlCreateThreeshapeDmeCommand;
};


export type LabsGqlMutationCreateTicketArgs = {
  data: LabsGqlCreateTicketCommand;
};


export type LabsGqlMutationCreateTicketSavedSearchArgs = {
  data: LabsGqlCreateTicketSavedSearchCommand;
};


export type LabsGqlMutationCreateTrainingArgs = {
  data: LabsGqlCreateTrainingCommand;
};


export type LabsGqlMutationCreateTrainingIntakeResponseArgs = {
  data: Array<LabsGqlTrainingIntakeResponseInput>;
};


export type LabsGqlMutationCreateTryInFeedbackArgs = {
  data: LabsGqlCreateTryInFeedbackCommand;
};


export type LabsGqlMutationCreateUserArgs = {
  data: LabsGqlCreateUserCommand;
};


export type LabsGqlMutationCreateVideoDdpReviewRequestArgs = {
  args: LabsGqlCreateVideoDdpReviewRequestArgs;
};


export type LabsGqlMutationCreateWorkflowStepDefinitionArgs = {
  data: LabsGqlCreateWorkflowStepDefinitionCommand;
};


export type LabsGqlMutationDeleteChatMessageArgs = {
  data: LabsGqlDeleteChatMessageCommand;
};


export type LabsGqlMutationDeleteDoctorPreferencesArgs = {
  data: LabsGqlDeleteDoctorPreferencesCommand;
};


export type LabsGqlMutationDeleteImplantTypeArgs = {
  data: LabsGqlDeleteImplantTypeCommand;
};


export type LabsGqlMutationDeleteInvoiceCreditArgs = {
  data: LabsGqlDeleteInvoiceCreditInput;
};


export type LabsGqlMutationDeleteManufacturerTruckTimeArgs = {
  payload: LabsGqlDeleteManufacturerTruckTimePayload;
};


export type LabsGqlMutationDeleteManufacturingTechniciansArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationDeleteMetalMaterialArgs = {
  data: LabsGqlDeleteMetalMaterialCommand;
};


export type LabsGqlMutationDeleteNonDoctorPracticeStaffArgs = {
  data: LabsGqlDeleteNonDoctorPracticeStaffCommand;
};


export type LabsGqlMutationDeleteOrderAutomationArgs = {
  data: LabsGqlDeleteOrderAutomationCommand;
};


export type LabsGqlMutationDeleteOrderItemArgs = {
  data: LabsGqlDeleteOrderItemCommand;
};


export type LabsGqlMutationDeleteOrderPriceEntryArgs = {
  data: LabsGqlDeleteOrderPriceEntryCommand;
};


export type LabsGqlMutationDeleteOrganizationPriceConfigArgs = {
  data: LabsGqlDeleteOrganizationPriceConfigCommand;
};


export type LabsGqlMutationDeletePartnerPriceConfigArgs = {
  data: LabsGqlDeletePartnerPriceConfigCommand;
};


export type LabsGqlMutationDeletePracticeSupportArgs = {
  data: LabsGqlDeletePracticeSupportCommand;
};


export type LabsGqlMutationDeletePriceArgs = {
  data: LabsGqlDeletePriceCommand;
};


export type LabsGqlMutationDeletePricePresetArgs = {
  data: LabsGqlDeletePricePresetCommand;
};


export type LabsGqlMutationDeleteProductStationUnitMappingArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlMutationDeleteReasonCodeRuleArgs = {
  data: LabsGqlDeleteReasonCodeRuleCommand;
};


export type LabsGqlMutationDeleteRefabReasonRuleArgs = {
  data: LabsGqlDeleteRefabReasonRuleCommand;
};


export type LabsGqlMutationDeleteRegisteredInvoiceItemArgs = {
  data: LabsGqlDeleteRegisteredInvoiceItemCommand;
};


export type LabsGqlMutationDeleteSavedSearchArgs = {
  data: LabsGqlDeleteSavedSearchCommand;
};


export type LabsGqlMutationDeleteScanExportArgs = {
  data: LabsGqlDeleteScanExportCommand;
};


export type LabsGqlMutationDeleteScanbodyArgs = {
  data: LabsGqlDeleteScanbodyCommand;
};


export type LabsGqlMutationDeleteScanbodyRequestArgs = {
  data: LabsGqlDeleteScanbodyRequestCommand;
};


export type LabsGqlMutationDeleteTagArgs = {
  data: LabsGqlDeleteTagCommand;
};


export type LabsGqlMutationDeleteTicketSavedSearchArgs = {
  data: LabsGqlDeleteTicketSavedSearchCommand;
};


export type LabsGqlMutationDev_Associate_Treatment_Plan_With_CaseArgs = {
  orderId: Scalars['String']['input'];
  pathPrefix: Scalars['String']['input'];
  versionId: Scalars['Float']['input'];
};


export type LabsGqlMutationDisableBiasRuleArgs = {
  data: LabsGqlDisableBiasRuleCommand;
};


export type LabsGqlMutationDisableCapacityRuleArgs = {
  data: LabsGqlDisableCapacityRuleCommand;
};


export type LabsGqlMutationDisableCapacityStationRuleArgs = {
  disableCapacityStationRuleArgs: LabsGqlDisableCapacityStationRuleArgs;
};


export type LabsGqlMutationDisableDesignRuleArgs = {
  data: LabsGqlDisableDesignRuleCommand;
};


export type LabsGqlMutationDisableRoutingTargetDistributionRuleArgs = {
  data: LabsGqlDisableRoutingTargetDistributionRuleCommand;
};


export type LabsGqlMutationEditAccountManagementContactsArgs = {
  data: LabsGqlEditAccountManagementContactsCommand;
};


export type LabsGqlMutationEditAlignerCaseArgs = {
  data: LabsGqlEditAlignerCaseCommand;
};


export type LabsGqlMutationEditBillingContactsArgs = {
  data: LabsGqlEditBillingContactsCommand;
};


export type LabsGqlMutationEditDeliveryAddressArgs = {
  address_id: Scalars['String']['input'];
  data: LabsGqlPartnerDeliveryAddressInput;
};


export type LabsGqlMutationEditDoctorNoteArgs = {
  data: LabsGqlEditDoctorNoteCommand;
};


export type LabsGqlMutationEditQcItemConfigArgs = {
  data: LabsGqlEditQcItemConfigCommand;
};


export type LabsGqlMutationEditRefabricationArgs = {
  data: LabsGqlEditRefabricationCommand;
};


export type LabsGqlMutationEditRefabricationReasonsArgs = {
  data: LabsGqlEditRefabricationReasonsCommand;
};


export type LabsGqlMutationEditSavedInboxSearchArgs = {
  data: LabsGqlEditSavedInboxSearchCommand;
};


export type LabsGqlMutationEditSavedOrderSearchArgs = {
  data: LabsGqlEditSavedOrderSearchCommand;
};


export type LabsGqlMutationEditSavedRoutingSearchArgs = {
  data: LabsGqlEditSavedRoutingSearchCommand;
};


export type LabsGqlMutationEditTagArgs = {
  data: LabsGqlEditTagCommand;
};


export type LabsGqlMutationEditTicketActionNoteArgs = {
  data: LabsGqlEditTicketActionNoteCommand;
};


export type LabsGqlMutationEditTicketSavedSearchArgs = {
  data: LabsGqlEditTicketSavedSearchCommand;
};


export type LabsGqlMutationEmailReturnLabelArgs = {
  email: Scalars['String']['input'];
  order_id: Scalars['ID']['input'];
};


export type LabsGqlMutationEmergencyOnlyHardDeleteContractArgs = {
  practice_contract_id: Scalars['String']['input'];
};


export type LabsGqlMutationEnableBiasRuleArgs = {
  data: LabsGqlEnableBiasRuleCommand;
};


export type LabsGqlMutationEnableCapacityRuleArgs = {
  data: LabsGqlEnableCapacityRuleCommand;
};


export type LabsGqlMutationEnableDesignRuleArgs = {
  data: LabsGqlEnableDesignRuleCommand;
};


export type LabsGqlMutationEnableRoutingTargetDistributionRuleArgs = {
  data: LabsGqlEnableRoutingTargetDistributionRuleCommand;
};


export type LabsGqlMutationEnrollInDesignTrainingCourseArgs = {
  identifier: Scalars['String']['input'];
};


export type LabsGqlMutationEnsureStackCodesForLabOrdersArgs = {
  labId: Scalars['String']['input'];
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationExecuteTemporalWorkflowArgs = {
  args: LabsGqlExecuteTemporalWorkflowArgs;
};


export type LabsGqlMutationExternalUserCancelOrderArgs = {
  data: LabsGqlExternalUserCancelOrderCommand;
};


export type LabsGqlMutationFavoriteSavedSearchArgs = {
  data: LabsGqlFavoriteSavedSearchCommand;
};


export type LabsGqlMutationFavoriteTicketSavedSearchArgs = {
  data: LabsGqlFavoriteTicketSavedSearchCommand;
};


export type LabsGqlMutationFinalizeTreatmentPlanRequestArgs = {
  data: LabsGqlFinalizeTreatmentPlanRequestCommand;
};


export type LabsGqlMutationFlagOrderForReviewArgs = {
  data: LabsGqlFlagOrderForReviewCommand;
};


export type LabsGqlMutationForceReturnRefabConversionArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationFulfillVideoDdpReviewRequestArgs = {
  order_id: Scalars['String']['input'];
  video_url: Scalars['String']['input'];
};


export type LabsGqlMutationGenerateEmptyDesignFileArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationGenerateSoftsmileWebviewerLinkArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
  versionId?: InputMaybe<Scalars['Float']['input']>;
};


export type LabsGqlMutationGenerate_Softsmile_Vision_LinkArgs = {
  link_type?: InputMaybe<LabsGqlVisionLinkType>;
  order_id: Scalars['String']['input'];
  version_id?: InputMaybe<Scalars['Float']['input']>;
};


export type LabsGqlMutationGetDownloadUrlArgs = {
  bucket: Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
};


export type LabsGqlMutationHandleFcmCheckArgs = {
  case_id: Scalars['String']['input'];
  device_name?: InputMaybe<Scalars['String']['input']>;
  skus: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationHandleFcmCheckByCaseInfoArgs = {
  device_name?: InputMaybe<Scalars['String']['input']>;
  doctor_preferences_id: Scalars['String']['input'];
  patient_name?: InputMaybe<Scalars['String']['input']>;
  practice_id: Scalars['String']['input'];
  skus: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationIgnoreQualityControlItemArgs = {
  data: LabsGqlIgnoreQualityControlItemCommand;
};


export type LabsGqlMutationInvalidateInternalRemakeArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlMutationIssueAttributedRefundsArgs = {
  args: LabsGqlIssueAttributedRefundsInput;
};


export type LabsGqlMutationLabOrderQcStationFailArgs = {
  data: LabsGqlLabOrderQcStationFailCommand;
};


export type LabsGqlMutationLabOrderQcStationPassArgs = {
  data: LabsGqlLabOrderQcStationPassCommand;
};


export type LabsGqlMutationLinkOrderToRefabricationArgs = {
  data: LabsGqlLinkOrderToRefabricationCommand;
};


export type LabsGqlMutationLinkOrganizationExternalAccountArgs = {
  data: LabsGqlLinkOrganizationExternalAccountCommand;
};


export type LabsGqlMutationLinkScanbodyRequestFulfillmentArgs = {
  data: LabsGqlLinkScanbodyRequestFulfillmentCommand;
};


export type LabsGqlMutationLogThreeshapePluginOperationArgs = {
  operation: LabsGqlThreeshapePluginOperationInput;
};


export type LabsGqlMutationManuallyAttachInjectionMoldFilesArgs = {
  filePaths: Array<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
};


export type LabsGqlMutationMarkDeliveredArgs = {
  data: LabsGqlMarkDeliveredCommand;
};


export type LabsGqlMutationMarkEventUnreadArgs = {
  eventId: Scalars['Int']['input'];
};


export type LabsGqlMutationMarkEventsReadArgs = {
  eventIds: Array<Scalars['Int']['input']>;
};


export type LabsGqlMutationMarkMfgUnitsAutoNestingCompleteWithDetailedResponseArgs = {
  mfg_unit_ids: Array<Scalars['String']['input']>;
  nest_id: Scalars['String']['input'];
};


export type LabsGqlMutationMarkNeedsRefabricationArgs = {
  data: LabsGqlMarkNeedsRefabricationCommand;
};


export type LabsGqlMutationMarkOrdersIntakeCompletedByLabOrderIdsArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationMarkReturnReceivedBulkArgs = {
  order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationMarkShippedArgs = {
  data: LabsGqlMarkFulfillmentShippedCommand;
};


export type LabsGqlMutationMergeAndStitchDesignOrdersArgs = {
  lab_order_id: Scalars['String']['input'];
};


export type LabsGqlMutationMigratePreferencesToStaffArgs = {
  args: LabsGqlMigratePreferencesToStaffArgs;
};


export type LabsGqlMutationMobileUploadLoadedArgs = {
  case_id: Scalars['String']['input'];
};


export type LabsGqlMutationModifyCrownProductCatalogEntryArgs = {
  input: LabsGqlModifyCrownProductCatalogEntryInput;
};


export type LabsGqlMutationModifyOrderSlaArgs = {
  data: LabsGqlModifyOrderSlaCommand;
};


export type LabsGqlMutationNonProductionAutoSubmitDesignArgs = {
  design_order_id: Scalars['String']['input'];
};


export type LabsGqlMutationNotifyQcOrderErrorArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationOffboardDesignStaffArgs = {
  data: LabsGqlOffboardDesignStaffCommand;
};


export type LabsGqlMutationOneTimeCodeLoginV2Args = {
  code: Scalars['String']['input'];
  mobilePhoneNumber: Scalars['String']['input'];
};


export type LabsGqlMutationOneTimeCodeVerificationArgs = {
  code: Scalars['String']['input'];
  mobile_phone_number: Scalars['String']['input'];
};


export type LabsGqlMutationOpenPendingAlignerCaseEstimateModalArgs = {
  data: LabsGqlOpenPendingAlignerCaseEstimateModalCommand;
};


export type LabsGqlMutationOpsCancelOrderArgs = {
  data: LabsGqlOpsCancelOrderCommand;
};


export type LabsGqlMutationOptOutProductionOrderArgs = {
  data: LabsGqlOptOutProductionOrderCommand;
};


export type LabsGqlMutationOptPracticeInToCreditCardSurchargeArgs = {
  data: LabsGqlOptPracticeInToCreditCardSurchargeCommand;
};


export type LabsGqlMutationOptPracticeOutOfCreditCardSurchargeArgs = {
  data: LabsGqlOptPracticeOutOfCreditCardSurchargeCommand;
};


export type LabsGqlMutationOverrideDentistAmountDueArgs = {
  data: LabsGqlOverrideDentistAmountDueCommand;
};


export type LabsGqlMutationOverrideLabCaseUnitPriceArgs = {
  data: LabsGqlOverrideLabCaseUnitPriceCommand;
};


export type LabsGqlMutationOverrideNonProdManufacturerArgs = {
  data: LabsGqlOverrideNonProdManufacturerCommand;
};


export type LabsGqlMutationOverridePendingAlignerPatientPriceArgs = {
  data: LabsGqlOverridePendingAlignerPatientPrice;
};


export type LabsGqlMutationPatient_Portal_LinkArgs = {
  order_id: Scalars['String']['input'];
  version_id?: InputMaybe<Scalars['Float']['input']>;
};


export type LabsGqlMutationPayContractInvoicesWithPaymentSourceArgs = {
  data: LabsGqlPayContractInvoicesWithSourceInput;
};


export type LabsGqlMutationPayInvoiceWithConfigArgs = {
  data: LabsGqlPayInvoiceWithConfigInput;
};


export type LabsGqlMutationPayInvoiceWithManualStripePaymentArgs = {
  data: LabsGqlPayInvoiceWithManualStripePaymentInput;
};


export type LabsGqlMutationPayInvoiceWithPaymentSourceArgs = {
  data: LabsGqlPayInvoiceWithSourceInput;
};


export type LabsGqlMutationPlaceDenturesFulfillmentCoreArgs = {
  data: LabsGqlPlaceDenturesFulfillmentCoreCommand;
};


export type LabsGqlMutationPlaceDenturesFulfillmentWaxRimArgs = {
  data: LabsGqlPlaceDenturesFulfillmentWaxRimCommand;
};


export type LabsGqlMutationPlaceLabOrderFromTryInArgs = {
  data: LabsGqlPlaceLabOrderFromTryInCommand;
};


export type LabsGqlMutationPlaceOrderArgs = {
  data: LabsGqlPlaceLabOrderCommand;
};


export type LabsGqlMutationPracticeRespondToDoctorRequestArgs = {
  data: LabsGqlPracticeRespondToDoctorRequestCommand;
};


export type LabsGqlMutationPracticeRespondToRejectedScanArgs = {
  data: LabsGqlPracticeRespondToRejectedScanCommand;
};


export type LabsGqlMutationPreemptDesignTaskTimeoutArgs = {
  data: LabsGqlPreemptDesignTaskTimeoutCommand;
};


export type LabsGqlMutationPrintableLabSlipViewedArgs = {
  data: LabsGqlRecordPrintableLabSlipViewCommand;
};


export type LabsGqlMutationProcess3oxzFileArgs = {
  file_url: Scalars['String']['input'];
  partnerId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<LabsGqlScanExportSource>;
  source_version?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationProcess3oxzFileScannerArgs = {
  file_url: Scalars['String']['input'];
  source?: InputMaybe<LabsGqlScanExportSource>;
  source_version?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationProcessThreeshapeScanNotificationsArgs = {
  scan_file_notifications: Array<LabsGqlThreeshapeScanNotification>;
};


export type LabsGqlMutationPromoteDesignRevisionToLatestArgs = {
  orderId: Scalars['String']['input'];
  revisionId: Scalars['String']['input'];
};


export type LabsGqlMutationQueueManufacturingFileCleanUpArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationQueueManufacturingFileProcessingArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationQueueZeroOutModelsBulkPricingJobArgs = {
  order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationReOnboardDesignStaffArgs = {
  data: LabsGqlReOnboardDesignStaffCommand;
};


export type LabsGqlMutationReactivateDoctorPreferencesArgs = {
  data: LabsGqlReactivateDoctorPreferencesCommand;
};


export type LabsGqlMutationReactivateOrganizationStaffMemberArgs = {
  data: LabsGqlReactivateOrganizationStaffMemberCommand;
};


export type LabsGqlMutationRecomputeOrderArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationRecordActivationFeeArgs = {
  data: LabsGqlRecordActivationFeeCommand;
};


export type LabsGqlMutationRecordDesignDownloadArgs = {
  data: LabsGqlRecordDesignDownloadCommand;
};


export type LabsGqlMutationRecordDesignDownloadedManufacturerBulkArgs = {
  files?: InputMaybe<Array<LabsGqlDownloadedFileInfo>>;
  order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationRecordInternalRemakeArgs = {
  causedByStage: LabsGqlManufacturingStageInputDto;
  labOrderId: Scalars['String']['input'];
  newLmsOrderId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  requiresRestartFromStage: LabsGqlManufacturingStageInputDto;
  stagesNeedingRedo?: Array<LabsGqlManufacturingStageInputDto>;
  timestampOverride?: InputMaybe<Scalars['DateTime']['input']>;
};


export type LabsGqlMutationRecordLabSlipsViewedByLabOrderIdsArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationRecordPackingCompletedForManufacturingOrdersArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationRecordPackingSheetViewedArgs = {
  data: LabsGqlRecordPackingSheetViewedCommand;
};


export type LabsGqlMutationRecordPartnerActivationDateArgs = {
  data: LabsGqlRecordPartnerActivationDateCommand;
};


export type LabsGqlMutationRecordShippingDeptScanAndFetchBatchCandidatesArgs = {
  search: Scalars['String']['input'];
};


export type LabsGqlMutationRecordShippingDeptScanAndFetchBatchDecisionArgs = {
  search: Scalars['String']['input'];
};


export type LabsGqlMutationRecordUpgradeFeeArgs = {
  data: LabsGqlRecordUpgradeFeeCommand;
};


export type LabsGqlMutationRefreshImagesForProcessedScanArgs = {
  processedScanId: Scalars['String']['input'];
};


export type LabsGqlMutationRefreshShipmentTrackingArgs = {
  data: LabsGqlRefreshShipmentTrackingCommand;
};


export type LabsGqlMutationRefreshShippingStatusArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlMutationRefundInvoicePaymentArgs = {
  args: LabsGqlRefundInvoicePaymentInput;
};


export type LabsGqlMutationRegisterActivationFeeChargeArgs = {
  data: LabsGqlRegisterActivationFeeChargeCommand;
};


export type LabsGqlMutationRegisterInvoiceItemArgs = {
  data: LabsGqlRegisterInvoiceItemCommand;
};


export type LabsGqlMutationRejectPendingAlignerCaseArgs = {
  data: LabsGqlRejectPendingAlignerCaseCommand;
};


export type LabsGqlMutationRejectTreatmentPlanArgs = {
  data: LabsGqlRejectTreatmentPlanCommand;
};


export type LabsGqlMutationReleaseTicketActionArgs = {
  data: LabsGqlReleaseTicketActionCommand;
};


export type LabsGqlMutationRemoveAbutmentPartToImplantScanbodyArgs = {
  data: LabsGqlRemoveAbutmentPartToImplantScanbodyCommand;
};


export type LabsGqlMutationRemoveEntityTagArgs = {
  data: LabsGqlRemoveEntityTagCommand;
};


export type LabsGqlMutationRemoveFulfillmentShippingArgs = {
  data: LabsGqlRemoveFulfillmentShippingCommand;
};


export type LabsGqlMutationRemoveInvoiceItemArgs = {
  data: LabsGqlRemoveInvoiceItemInput;
};


export type LabsGqlMutationRemoveLabSlipAlertsArgs = {
  data: LabsGqlRemoveLabSlipAlertsCommand;
};


export type LabsGqlMutationRemoveOrderFromShipmentArgs = {
  orderId: Scalars['String']['input'];
  shipmentId: Scalars['String']['input'];
};


export type LabsGqlMutationRemoveOrderHoldArgs = {
  data: LabsGqlRemoveOrderHoldCommand;
};


export type LabsGqlMutationRemoveOrganizationStaffMemberArgs = {
  data: LabsGqlRemoveOrganizationStaffMemberCommand;
};


export type LabsGqlMutationRemovePaymentMethodArgs = {
  cardId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
};


export type LabsGqlMutationRemovePreferenceStaffMemberArgs = {
  data: LabsGqlRemovePreferenceStaffMemberCommand;
};


export type LabsGqlMutationRemovePriceApplicationRuleArgs = {
  data: LabsGqlRemovePriceApplicationRuleCommand;
};


export type LabsGqlMutationRemoveScannerOwnerArgs = {
  data: LabsGqlRemoveScannerOwnerCommand;
};


export type LabsGqlMutationRemoveSendersFromPrefArgs = {
  data: LabsGqlRemoveSendersInput;
};


export type LabsGqlMutationRemoveUsagePaymentSplitConfigArgs = {
  data: LabsGqlRemoveUsagePaymentSplitConfigCommand;
};


export type LabsGqlMutationReplaceRejectedScanArgs = {
  data: LabsGqlReplaceRejectedScanCommand;
};


export type LabsGqlMutationReplaceRemovableManufacturingFilesArgs = {
  data: LabsGqlReplaceRemovableManufacturingFilesCommand;
};


export type LabsGqlMutationReplaceThreeoxzArgs = {
  data: LabsGqlReplaceThreeoxzCommand;
};


export type LabsGqlMutationReprocessThreeshapeDmesArgs = {
  dme_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationRequestOrderRefinementArgs = {
  data: LabsGqlRequestOrderRefinementCommand;
};


export type LabsGqlMutationRequestPrecheckoutScanReviewArgs = {
  precheckout_scan_review_request: LabsGqlPrecheckoutScanReviewRequest;
};


export type LabsGqlMutationRerouteLabOrderArgs = {
  data: LabsGqlRerouteLabOrderCommand;
};


export type LabsGqlMutationRerouteOrdersArgs = {
  disable_notification: Scalars['Boolean']['input'];
  disable_sla_reset: Scalars['Boolean']['input'];
  order_ids: Array<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
};


export type LabsGqlMutationRerunDesignConversionArgs = {
  force_temporal_workflow?: InputMaybe<Scalars['Boolean']['input']>;
  order_id: Scalars['String']['input'];
  revision_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationResendOrdersToAutomateArgs = {
  order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationResetManufacturingBookingsArgs = {
  manufacturingOrderId: Scalars['String']['input'];
};


export type LabsGqlMutationResolveOrderReviewFlagArgs = {
  data: LabsGqlResolveOrderReviewFlagCommand;
};


export type LabsGqlMutationResolveOrderScanRejectionArgs = {
  data: LabsGqlResolveOrderScanRejectionCommand;
};


export type LabsGqlMutationRestoreImplantTypeArgs = {
  data: LabsGqlRestoreImplantTypeCommand;
};


export type LabsGqlMutationRestoreManufacturerTruckTimeArgs = {
  payload: LabsGqlRestoreManufacturerTruckTimePayload;
};


export type LabsGqlMutationRestoreScanbodyArgs = {
  data: LabsGqlRestoreScanbodyCommand;
};


export type LabsGqlMutationRunManufacturingBookingSyncWorkflowArgs = {
  manufacturingOrderId: Scalars['String']['input'];
};


export type LabsGqlMutationRunRescheduleAndReassignArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlMutationSalesOrderAddItemsArgs = {
  args: LabsGqlSalesOrderAddItemsInput;
};


export type LabsGqlMutationSalesOrderChangeDeliveryAddressArgs = {
  args: LabsGqlSalesOrderChangeDeliveryAddressInput;
};


export type LabsGqlMutationSalesOrderChangeDoctorPreferencesArgs = {
  args: LabsGqlSalesOrderChangeDoctorPreferencesInput;
};


export type LabsGqlMutationSalesOrderDeleteItemArgs = {
  args: LabsGqlSalesOrderDeleteItemInput;
};


export type LabsGqlMutationSalesOrderUpdateItemsArgs = {
  args: LabsGqlSalesOrderUpdateItemsInput;
};


export type LabsGqlMutationSalesforceUserSyncArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlMutationSaveDoctorLabSlipAlertsArgs = {
  data: LabsGqlSaveDoctorLabSlipAlertsCommand;
};


export type LabsGqlMutationSaveLabSlipAlertsArgs = {
  data: LabsGqlSaveLabSlipAlertsCommand;
};


export type LabsGqlMutationSchedule3oxzToStlConversionArgs = {
  processedScanId: Scalars['String']['input'];
};


export type LabsGqlMutationSendAttachPhotosUploadLinkArgs = {
  data: LabsGqlAttachPhotosUploadData;
};


export type LabsGqlMutationSendCheckoutImagesUploadLinkArgs = {
  data: LabsGqlCheckoutImagesUploadLinkData;
};


export type LabsGqlMutationSendDoctorPrefsUpdatedNotificationArgs = {
  data: LabsGqlSendDoctorPrefsUpdatedNotificationInput;
  schedule: Scalars['Boolean']['input'];
};


export type LabsGqlMutationSendInviteEmailArgs = {
  data: LabsGqlSendInviteEmailData;
};


export type LabsGqlMutationSendInvoiceSummaryEmailArgs = {
  organizationId: Scalars['String']['input'];
  overrideEmail?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationSendMobileAppLinksArgs = {
  data: LabsGqlSendMobileAppLinksData;
};


export type LabsGqlMutationSendOneTimeCodeArgs = {
  mobilePhoneNumber: Scalars['String']['input'];
};


export type LabsGqlMutationSendOrderToAutomateArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationSendOrderToFloorArgs = {
  data: LabsGqlSendToFloorCommand;
};


export type LabsGqlMutationSendOrdersToFloorBulkArgs = {
  order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationSendRefabFilesUploadLinkArgs = {
  data: LabsGqlRefabFilesUploadData;
};


export type LabsGqlMutationSendResetPasswordEmailArgs = {
  email: Scalars['String']['input'];
};


export type LabsGqlMutationSendScanbodySurgicalReportUploadLinkArgs = {
  data: LabsGqlScanbodySurgicalReportUploadData;
};


export type LabsGqlMutationSetAlignerManufacturerTreatmentPlanArgs = {
  data: LabsGqlSetAlignerManufacturerTreatmentPlanCommand;
};


export type LabsGqlMutationSetCbctScanUrlArgs = {
  data: LabsGqlSetCbctScanUrlCommand;
};


export type LabsGqlMutationSetChatMessageVisibilityArgs = {
  data: LabsGqlSetChatMessageVisibilityCommand;
};


export type LabsGqlMutationSetDefaultPaymentMethodArgs = {
  cardId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
};


export type LabsGqlMutationSetDesignStaffPrepCapabilitiesArgs = {
  capabilities: LabsGqlDesignPrepCapabilitiesInput;
  user_id: Scalars['String']['input'];
};


export type LabsGqlMutationSetDoctorNotificationPrefsArgs = {
  data: LabsGqlSetDoctorNotificationPrefsCommand;
};


export type LabsGqlMutationSetDoctorPreferencesArgs = {
  data: LabsGqlSetDoctorPreferencesCommand;
};


export type LabsGqlMutationSetDoctorPreferencesIdArgs = {
  data: LabsGqlSetDoctorPreferencesIdCommand;
};


export type LabsGqlMutationSetDoctorPrefsForwardingArgs = {
  data: LabsGqlSetDoctorPrefsForwardingCommand;
};


export type LabsGqlMutationSetDoctorProfilePhotoArgs = {
  data: LabsGqlSetDoctorProfilePhotoCommand;
};


export type LabsGqlMutationSetManufacturerNotificationPreferencesArgs = {
  data: LabsGqlSetManufacturerNotificationPreferencesCommand;
  manufacturer_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationSetNonDoctorPracticeStaffArgs = {
  data: LabsGqlSetNonDoctorPracticeStaffCommand;
};


export type LabsGqlMutationSetOrderAutomationFolderArgs = {
  data: LabsGqlSetOrderAutomationFolderCommand;
};


export type LabsGqlMutationSetPartnerNotificationPreferencesArgs = {
  data: LabsGqlSetPartnerNotificationPreferencesCommand;
  partner_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationSetPartnerScannersArgs = {
  partner_id: Scalars['String']['input'];
  scanner_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationSetPracticeContractV2EndDateArgs = {
  data: LabsGqlSetPracticeContractEndDateInput;
};


export type LabsGqlMutationSetPreferredManufacturersArgs = {
  data: LabsGqlSetPreferredManufacturersCommand;
};


export type LabsGqlMutationSetPriceSalesforceMappingArgs = {
  data: LabsGqlSetPriceSalesforceMappingCommand;
};


export type LabsGqlMutationSetReceiversOnPrefArgs = {
  data: LabsGqlSetReceiversInput;
};


export type LabsGqlMutationSetSalesforcePriceMappingArgs = {
  data: LabsGqlSetSalesforcePriceMappingCommand;
};


export type LabsGqlMutationSetScannerOwnerArgs = {
  data: LabsGqlSetScannerOwnerCommand;
};


export type LabsGqlMutationSetSingleClinicalPreferenceArgs = {
  data: LabsGqlSetSingleClinicalPreferenceCommand;
};


export type LabsGqlMutationSetSmileStyleArgs = {
  data: LabsGqlSetSmileStyleCommand;
};


export type LabsGqlMutationSetTreatmentPlanRequestUrlArgs = {
  data: LabsGqlSetTreatmentPlanRequestUrlCommand;
};


export type LabsGqlMutationSetUsagePaymentSplitConfigDoctorArgs = {
  data: LabsGqlSetUsagePaymentSplitConfigDoctorCommand;
};


export type LabsGqlMutationSetUsagePaymentSplitConfigLocationArgs = {
  data: LabsGqlSetUsagePaymentSplitConfigLocationCommand;
};


export type LabsGqlMutationSetUsagePaymentSplitConfigPercentArgs = {
  data: LabsGqlSetUsagePaymentSplitConfigPercentCommand;
};


export type LabsGqlMutationSetWillBeChargedCcFeeArgs = {
  data: LabsGqlSetWillBeChargedCcFeeCommand;
};


export type LabsGqlMutationSharePatientScanArgs = {
  annotation_urls?: InputMaybe<Array<Scalars['String']['input']>>;
  message?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  patient_email?: InputMaybe<Scalars['String']['input']>;
  patient_id: Scalars['String']['input'];
  patient_mobile_phone?: InputMaybe<Scalars['String']['input']>;
  practice_contact_phone?: InputMaybe<Scalars['String']['input']>;
  practice_name?: InputMaybe<Scalars['String']['input']>;
  scan_export_id: Scalars['String']['input'];
  staff_member_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationShareScanRejectionWithPracticeArgs = {
  data: LabsGqlShareScanRejectionWithPracticeCommand;
};


export type LabsGqlMutationSkipCaseArgs = {
  trainingDesignId: Scalars['ID']['input'];
};


export type LabsGqlMutationSkipManufacturingBookingsArgs = {
  manufacturingOrderId: Scalars['String']['input'];
};


export type LabsGqlMutationSkipMillingAutoNestingForAllOrdersArgs = {
  manufacturerId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};


export type LabsGqlMutationSkipMillingAutoNestingForOrderArgs = {
  manufacturing_order_id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};


export type LabsGqlMutationSkipOnboardingV2Args = {
  input: LabsGqlSkipOnboardingInput;
};


export type LabsGqlMutationStartDesignEditorSessionArgs = {
  feature_flags: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
  sentry_replay_id: Scalars['String']['input'];
};


export type LabsGqlMutationStartFulfillmentTaskArgs = {
  data: LabsGqlStartFulfillmentTaskCommand;
};


export type LabsGqlMutationStartOrderIntegrationManualV2Args = {
  manufacturerId: Scalars['String']['input'];
  orderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationStartPrepInBrowserArgs = {
  order_id: Scalars['String']['input'];
  sentry_replay_id: Scalars['String']['input'];
};


export type LabsGqlMutationSubmitAutomateReviewArgs = {
  data: LabsGqlSubmitAutomateReviewCommand;
};


export type LabsGqlMutationSubmitAutomateReviewForTrainingArgs = {
  command: LabsGqlSubmitAutomateReviewCommand;
  trainingDesignId: Scalars['ID']['input'];
};


export type LabsGqlMutationSubmitDesignArgs = {
  args: LabsGqlDesignOrderRevisionSubmissionPayload;
};


export type LabsGqlMutationSubmitDesignEditorArgs = {
  payload: LabsGqlDesignEditorPayload;
  session_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlMutationSubmitDesignEditorForTrainingArgs = {
  payload: LabsGqlDesignEditorPayload;
  trainingDesignId: Scalars['ID']['input'];
};


export type LabsGqlMutationSubmitDesignQaEvaluationArgs = {
  data: LabsGqlSubmitDesignQaEvaluationCommand;
};


export type LabsGqlMutationSubmitFinishingInBrowserArgs = {
  payload: LabsGqlFinishingInBrowserPayload;
};


export type LabsGqlMutationSubmitFinishingInBrowserForTrainingArgs = {
  payload: LabsGqlFinishingInBrowserPayload;
  trainingDesignId: Scalars['ID']['input'];
};


export type LabsGqlMutationSubmitOrderScanReviewArgs = {
  data: LabsGqlSubmitOrderScanReviewCommand;
};


export type LabsGqlMutationSubmitPendingAlignerCaseEstimateArgs = {
  data: LabsGqlSubmitPendingAlignerCaseEstimateCommand;
};


export type LabsGqlMutationSubmitPracticeFeedbackArgs = {
  doctor_name: Scalars['String']['input'];
  feedback: Scalars['String']['input'];
};


export type LabsGqlMutationSubmitPrepInBrowserArgs = {
  payload: LabsGqlPrepInBrowserPayload;
};


export type LabsGqlMutationSubmitPrepInBrowserForTrainingArgs = {
  payload: LabsGqlPrepInBrowserPayload;
  trainingDesignId: Scalars['ID']['input'];
};


export type LabsGqlMutationSubmitQcFlagArgs = {
  data: LabsGqlSubmitQcFlagCommand;
};


export type LabsGqlMutationSubmitVariantFeedbackArgs = {
  data: LabsGqlSubmitVariantFeedbackInput;
};


export type LabsGqlMutationSubmitWaxupReviewArgs = {
  data: LabsGqlSubmitWaxupReviewCommand;
};


export type LabsGqlMutationSyncOrderPricesArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  orderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationTagEntityArgs = {
  data: LabsGqlTagEntityCommand;
};


export type LabsGqlMutationTatMigrateArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlMutationTatMigrateWorkflowArgs = {
  cursor?: InputMaybe<Scalars['DateTime']['input']>;
};


export type LabsGqlMutationTatSyncArgs = {
  orderId: Scalars['String']['input'];
  rebuild?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlMutationTatUpsertAddOnLeadTimeArgs = {
  id: Scalars['String']['input'];
  leadTimeByType: LabsGqlTatLeadTimeByTypeArgsDto;
};


export type LabsGqlMutationTatUpsertItemLeadTimeArgs = {
  catalogTime?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  leadTimeByType?: InputMaybe<LabsGqlTatLeadTimeByTypeArgsDto>;
  overLimitCatalogTime?: InputMaybe<Scalars['Int']['input']>;
  overLimitLeadTimeByType?: InputMaybe<LabsGqlTatLeadTimeByTypeArgsDto>;
  unitCountLimit?: InputMaybe<Scalars['Int']['input']>;
};


export type LabsGqlMutationTatUpsertItemLeadTimeOverrideArgs = {
  catalogTime?: InputMaybe<Scalars['Int']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  leadTimeByType?: InputMaybe<LabsGqlTatLeadTimeByTypeArgsDto>;
  organizationId: Scalars['String']['input'];
  overLimitCatalogTime?: InputMaybe<Scalars['Int']['input']>;
  overLimitLeadTimeByType?: InputMaybe<LabsGqlTatLeadTimeByTypeArgsDto>;
  unitCountLimit?: InputMaybe<Scalars['Int']['input']>;
};


export type LabsGqlMutationTestInternalLabtracIntegrationV2Args = {
  internalLabId: Scalars['String']['input'];
  labOrderId: Scalars['String']['input'];
  scheduleOrder?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlMutationTestOrderAutomationArgs = {
  config: LabsGqlAutomationActionConfigInput;
  orderId: Scalars['String']['input'];
};


export type LabsGqlMutationToggleArchiveCategoryArgs = {
  data: LabsGqlToggleArchiveCategoryCommand;
};


export type LabsGqlMutationToggleDeliveryAddressDeletedArgs = {
  addressId: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
};


export type LabsGqlMutationToggleLabCaseLedgerEntryReviewedArgs = {
  data: LabsGqlToggleLabCaseLedgerEntryReviewedCommand;
};


export type LabsGqlMutationToggleLabLedgerEntryReviewedArgs = {
  data: LabsGqlToggleLabLedgerEntryReviewedCommand;
};


export type LabsGqlMutationToggleLabPriceDeletionArgs = {
  data: LabsGqlToggleLabPriceDeletionCommand;
};


export type LabsGqlMutationTransitionOnboardingStepArgs = {
  input: LabsGqlTransitionOnboardingStepInput;
};


export type LabsGqlMutationTransitionStepArgs = {
  args: LabsGqlPracticeOnboardingTransitionStepInput;
};


export type LabsGqlMutationUnarchiveAbutmentPartArgs = {
  data: LabsGqlUnarchiveAbutmentPartCommand;
};


export type LabsGqlMutationUnarchiveSmileLibraryArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlMutationUnarchiveThreeshapeDmeArgs = {
  data: LabsGqlUnarchiveThreeshapeDmeCommand;
};


export type LabsGqlMutationUnblockStacksArgs = {
  labId: Scalars['String']['input'];
  stackCodes: Array<Scalars['String']['input']>;
};


export type LabsGqlMutationUndeleteTagArgs = {
  data: LabsGqlUndeleteTagCommand;
};


export type LabsGqlMutationUndoLinkOrderToRefabricationArgs = {
  data: LabsGqlUndoLinkOrderToRefabricationCommand;
};


export type LabsGqlMutationUnfavoriteSavedSearchArgs = {
  data: LabsGqlUnfavoriteSavedSearchCommand;
};


export type LabsGqlMutationUnfavoriteTicketSavedSearchArgs = {
  data: LabsGqlUnfavoriteTicketSavedSearchCommand;
};


export type LabsGqlMutationUnlinkOrganizationExternalAccountArgs = {
  data: LabsGqlUnlinkOrganizationExternalAccountCommand;
};


export type LabsGqlMutationUntagEntityArgs = {
  data: LabsGqlUntagEntityCommand;
};


export type LabsGqlMutationUpdateAbutmentPartArgs = {
  data: LabsGqlUpdateAbutmentPartCommand;
};


export type LabsGqlMutationUpdateAbutmentPartToImplantScanbodyArgs = {
  data: LabsGqlUpdateAbutmentPartToImplantScanbodyCommand;
};


export type LabsGqlMutationUpdateActionCapabilityArgs = {
  data: LabsGqlUpdateActionCapabilityCommand;
};


export type LabsGqlMutationUpdateActionCategoryArgs = {
  data: LabsGqlUpdateActionCategoryCommand;
};


export type LabsGqlMutationUpdateActionTypeArgs = {
  data: LabsGqlUpdateActionTypeCommand;
};


export type LabsGqlMutationUpdateAndCreateOrderPricesArgs = {
  newPreferencePrices?: InputMaybe<Array<LabsGqlNewPreferencePriceInput>>;
  orderId: Scalars['String']['input'];
  updates: Array<LabsGqlOverrideOrderPriceInput>;
};


export type LabsGqlMutationUpdateBiasRuleArgs = {
  data: LabsGqlUpdateBiasRuleCommand;
};


export type LabsGqlMutationUpdateBillingCreditCategoryArgs = {
  data: LabsGqlUpdateBillingCreditCategoryCommand;
};


export type LabsGqlMutationUpdateCrmChatConversationArgs = {
  args: LabsGqlUpdateCrmChatConversationArgs;
};


export type LabsGqlMutationUpdateCrmTicketArgs = {
  args: LabsGqlUpdateCrmTicketArgs;
};


export type LabsGqlMutationUpdateCapacityRuleArgs = {
  data: LabsGqlUpdateCapacityRuleCommand;
};


export type LabsGqlMutationUpdateCapacityStationArgs = {
  updateCapacityStationArgs: LabsGqlUpdateCapacityStationArgs;
};


export type LabsGqlMutationUpdateCapacityStationPoolArgs = {
  updateCapacityStationPoolArgs: LabsGqlUpdateCapacityStationPoolArgs;
};


export type LabsGqlMutationUpdateCapacityStationRuleArgs = {
  updateCapacityStationRuleArgs: LabsGqlUpdateCapacityStationRuleArgs;
};


export type LabsGqlMutationUpdateCategoryArgs = {
  data: LabsGqlUpdateCategoryCommand;
};


export type LabsGqlMutationUpdateCurrentRetainerArgs = {
  data: LabsGqlUpdateCurrentRetainerCommand;
};


export type LabsGqlMutationUpdateDesignReviewFieldArgs = {
  data: LabsGqlUpdateDesignReviewFieldCommand;
};


export type LabsGqlMutationUpdateDesignRuleArgs = {
  data: LabsGqlUpdateDesignRuleCommand;
};


export type LabsGqlMutationUpdateDesignStaffRosterInfoArgs = {
  data: LabsGqlUpdateDesignStaffRosterInfoCommand;
};


export type LabsGqlMutationUpdateDesignStaffStatusArgs = {
  data: LabsGqlUpdateDesignStaffStatusCommand;
};


export type LabsGqlMutationUpdateExternalStaffMemberRolesArgs = {
  data: LabsGqlUpdateExternalStaffMemberRolesCommand;
};


export type LabsGqlMutationUpdateImplantTypeArgs = {
  data: LabsGqlUpdateImplantTypeCommand;
};


export type LabsGqlMutationUpdateInternalStaffMemberRolesArgs = {
  data: LabsGqlUpdateInternalStaffMemberRolesCommand;
};


export type LabsGqlMutationUpdateInvoiceCreditArgs = {
  data: LabsGqlUpdateInvoiceCreditInput;
};


export type LabsGqlMutationUpdateIteroSettingsOfCurrentUserArgs = {
  itero_company_id: Scalars['String']['input'];
  itero_doctor_id: Scalars['String']['input'];
  should_immediately_place_order: Scalars['Boolean']['input'];
};


export type LabsGqlMutationUpdateLabCaseLedgerEntryArgs = {
  data: LabsGqlUpdateLabCaseLedgerEntryCommand;
};


export type LabsGqlMutationUpdateLabOrderLockStatusArgs = {
  is_locked: Scalars['Boolean']['input'];
  order_id: Scalars['String']['input'];
};


export type LabsGqlMutationUpdateLabOrderSplitsArgs = {
  data: LabsGqlLabOrderUpdateSplitsInput;
};


export type LabsGqlMutationUpdateLabPriceArgs = {
  data: LabsGqlUpdateLabPriceCommand;
};


export type LabsGqlMutationUpdateLabStaffMemberRolesArgs = {
  data: LabsGqlUpdateLabStaffMemberRolesCommand;
};


export type LabsGqlMutationUpdateLiveLabTechMeetingRequestNotesArgs = {
  args: LabsGqlAddNotesToLiveLabTechMeetingRequestArgs;
};


export type LabsGqlMutationUpdateManufacturerTruckTimeArgs = {
  payload: LabsGqlUpdateManufacturerTruckTimePayload;
};


export type LabsGqlMutationUpdateOrCreateIteroCompanyArgs = {
  itero_company_id: Scalars['String']['input'];
  practice_id: Scalars['String']['input'];
};


export type LabsGqlMutationUpdateOrCreateIteroUserArgs = {
  itero_doctor_id: Scalars['String']['input'];
  should_immediately_place_order: Scalars['Boolean']['input'];
  staff_id: Scalars['String']['input'];
};


export type LabsGqlMutationUpdateOrderAutomationArgs = {
  data: LabsGqlUpdateOrderAutomationCommand;
};


export type LabsGqlMutationUpdateOrderClinicalSupportNotesArgs = {
  data: LabsGqlUpdateOrderClinicalSupportNotesCommand;
};


export type LabsGqlMutationUpdateOrderHoldArgs = {
  data: LabsGqlUpdateOrderHoldCommand;
};


export type LabsGqlMutationUpdateOrderItemPennyweightsV2Args = {
  data: LabsGqlUpdateOrderItemPennyweightsV2Command;
};


export type LabsGqlMutationUpdateOrderItemsV2Args = {
  data: LabsGqlUpdateOrderItemsV2Command;
};


export type LabsGqlMutationUpdateOrderItemsV2PracticeArgs = {
  data: LabsGqlUpdateOrderItemsV2PracticeCommand;
};


export type LabsGqlMutationUpdateOrderPriceEntryArgs = {
  data: LabsGqlUpdateOrderPriceEntryCommand;
};


export type LabsGqlMutationUpdateOrganizationNameArgs = {
  data: LabsGqlUpdateOrganizationNameCommand;
};


export type LabsGqlMutationUpdateOrganizationParentIdArgs = {
  data: LabsGqlUpdateOrganizationParentIdCommand;
};


export type LabsGqlMutationUpdateOrganizationPhoneNumberArgs = {
  data: LabsGqlUpdateOrganizationPhoneNumberCommand;
};


export type LabsGqlMutationUpdateOrganizationPriceConfigArgs = {
  data: LabsGqlUpdateOrganizationPriceConfigCommand;
};


export type LabsGqlMutationUpdateOrganizationSettingsArgs = {
  data: LabsGqlUpdateOrganizationSettingsCommand;
};


export type LabsGqlMutationUpdateOrganizationStaffMemberZendeskIdArgs = {
  data: LabsGqlUpdateOrganizationStaffMemberZendeskIdCommand;
};


export type LabsGqlMutationUpdateOrganizationTypeArgs = {
  data: LabsGqlUpdateOrganizationTypeCommand;
};


export type LabsGqlMutationUpdateParentStaffMemberRolesArgs = {
  data: LabsGqlUpdateParentStaffMemberRolesCommand;
};


export type LabsGqlMutationUpdatePartialDentureMetadataArgs = {
  data: LabsGqlUpdatePartialDentureMetadataCommand;
};


export type LabsGqlMutationUpdatePaymentAmountArgs = {
  amountCents: Scalars['Int']['input'];
  paymentId: Scalars['String']['input'];
};


export type LabsGqlMutationUpdatePracticeOrderingPauseReasonsArgs = {
  data: LabsGqlUpdatePracticeOrderingPauseReasons;
};


export type LabsGqlMutationUpdatePracticeSettingsArgs = {
  data: LabsGqlUpdatePracticeSettingsCommand;
};


export type LabsGqlMutationUpdatePracticeStaffMemberRolesArgs = {
  data: LabsGqlUpdatePracticeStaffMemberRolesCommand;
};


export type LabsGqlMutationUpdatePracticeSupportArgs = {
  data: LabsGqlUpdatePracticeSupportCommand;
};


export type LabsGqlMutationUpdatePricePresetArgs = {
  data: LabsGqlUpdatePricePresetCommand;
};


export type LabsGqlMutationUpdatePrimaryBillingContactUserArgs = {
  data: LabsGqlUpdatePrimaryBillingContactUserCommand;
};


export type LabsGqlMutationUpdatePrimaryBillingContactUserIdArgs = {
  data: LabsGqlUpdatePrimaryBillingContactUserIdCommand;
};


export type LabsGqlMutationUpdateProductStationUnitMappingUnitsArgs = {
  updateProductStationUnitMappingArgs: LabsGqlUpdateProductStationUnitMappingArgs;
};


export type LabsGqlMutationUpdateQcIssueArgs = {
  data: LabsGqlUpdateQcIssueCommand;
};


export type LabsGqlMutationUpdateReasonCodeArgs = {
  data: LabsGqlUpdateReasonCodeCommand;
};


export type LabsGqlMutationUpdateRefabReasonArgs = {
  data: LabsGqlUpdateRefabReasonCommand;
};


export type LabsGqlMutationUpdateRefundCategoryArgs = {
  data: LabsGqlUpdateRefundCategoryInput;
};


export type LabsGqlMutationUpdateRegisteredInvoiceItemArgs = {
  data: LabsGqlUpdateRegisteredInvoiceItemCommand;
};


export type LabsGqlMutationUpdateReviewSubmissionItemTagsArgs = {
  data: LabsGqlUpdateReviewSubmissionItemTagsCommand;
};


export type LabsGqlMutationUpdateReviewTagArgs = {
  data: LabsGqlUpdateReviewTagCommand;
};


export type LabsGqlMutationUpdateRoutingTargetDistributionRuleArgs = {
  data: LabsGqlUpdateRoutingTargetDistributionRuleCommand;
};


export type LabsGqlMutationUpdateRushConfigurationArgs = {
  payload: LabsGqlUpdateOrderRushConfigurationPayload;
};


export type LabsGqlMutationUpdateScanbodyArgs = {
  data: LabsGqlUpdateScanbodyCommand;
};


export type LabsGqlMutationUpdateScanbodyRequestArgs = {
  data: LabsGqlUpdateScanbodyRequestCommand;
};


export type LabsGqlMutationUpdateStaffMemberOnboardingPreferencesArgs = {
  input: LabsGqlUpdateStaffPreferencesInput;
};


export type LabsGqlMutationUpdateStaffMemberOnboardingPreferencesByIdArgs = {
  input: LabsGqlUpdateStaffPreferencesInput;
  staff_member_id: Scalars['String']['input'];
};


export type LabsGqlMutationUpdateStripeLinkingArgs = {
  partnerId: Scalars['String']['input'];
  stripeId: Scalars['String']['input'];
};


export type LabsGqlMutationUpdateThreeshapeDmeFilePathArgs = {
  data: LabsGqlUpdateThreeshapeDmeFilePathCommand;
};


export type LabsGqlMutationUpdateUserArgs = {
  data: LabsGqlUpdateUserCommand;
};


export type LabsGqlMutationUpdateUserLoginPhoneArgs = {
  data: LabsGqlUpdateUserLoginPhoneCommand;
};


export type LabsGqlMutationUpdateUserPasswordArgs = {
  data: LabsGqlUpdateUserPasswordCommand;
};


export type LabsGqlMutationUpdateWorkflowStepDefinitionArgs = {
  data: LabsGqlUpdateWorkflowStepDefinitionCommand;
};


export type LabsGqlMutationUpsertDesignStaffArgs = {
  capabilities: LabsGqlDesignStaffCapabilityInput;
  task_type?: InputMaybe<LabsGqlDesignStaffTaskType>;
  user_id: Scalars['String']['input'];
};


export type LabsGqlMutationUpsertManufacturerProfileArgs = {
  data: LabsGqlUpsertManufacturerProfileCommand;
};


export type LabsGqlMutationUpsertMetalCostsBulkArgs = {
  metal_costs: Array<LabsGqlLabMetalCostUpsertInput>;
};


export type LabsGqlMutationUpsertPartnerPriceConfigArgs = {
  data: LabsGqlUpsertPartnerPriceConfigCommand;
};


export type LabsGqlMutationVerifyLoginPhoneNumberArgs = {
  code: Scalars['String']['input'];
  mobile_phone_number: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type LabsGqlMutationVoidInvoiceArgs = {
  data: LabsGqlVoidInvoiceInput;
};


export type LabsGqlMutationWithdrawFromDesignTrainingCourseArgs = {
  courseId: Scalars['ID']['input'];
};

/** Wrapper for available and enrolled courses */
export type LabsGqlMyCourses = {
  __typename?: 'MyCourses';
  availableCourses: Array<LabsGqlAvailableCourse>;
  enrolledCourses: Array<LabsGqlEnrolledCourse>;
};

export type LabsGqlNewPreferencePriceInput = {
  item_id: Scalars['String']['input'];
  override_total_price_cents: Scalars['Int']['input'];
  override_total_price_cents_reason?: InputMaybe<Scalars['String']['input']>;
  preference_field_id: Scalars['String']['input'];
};

export type LabsGqlNewScanbodyInput = {
  is_authentic: Scalars['Boolean']['input'];
  manufacturer: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sku: Scalars['String']['input'];
};

export type LabsGqlNextInvoiceStatusSummary = {
  __typename?: 'NextInvoiceStatusSummary';
  pending_item_count: Scalars['Float']['output'];
  previous_invoice_amount_cents: Scalars['Float']['output'];
  will_be_invoiced: Scalars['Boolean']['output'];
};

export type LabsGqlNextInvoiceStatusSummaryForOrganization = {
  __typename?: 'NextInvoiceStatusSummaryForOrganization';
  next_invoice_status_summary: LabsGqlNextInvoiceStatusSummary;
  organization_id: Scalars['ID']['output'];
};

export type LabsGqlNightGuardPanLabelInfo = LabsGqlPanLabelInfo & {
  __typename?: 'NightGuardPanLabelInfo';
  anteriorRamp?: Maybe<Scalars['Boolean']['output']>;
  arch?: Maybe<Scalars['String']['output']>;
  batchNumber?: Maybe<Scalars['String']['output']>;
  canineGuidance?: Maybe<Scalars['Boolean']['output']>;
  idealHeight?: Maybe<Scalars['String']['output']>;
  items: Array<LabsGqlPanLabelItemInfo>;
  labOrderId: Scalars['String']['output'];
  labelTags: Array<Scalars['String']['output']>;
  labtracId?: Maybe<Scalars['String']['output']>;
  modelType?: Maybe<Scalars['String']['output']>;
  occlusalSurfaceType?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  panCode: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  priority: LabsGqlManufacturingOrderPriority;
  /** Only the date portion is relevant.  The time portion should be noon UTC. */
  shipByDate: Scalars['DateTime']['output'];
};

export type LabsGqlNonDoctorPracticeStaffDto = {
  __typename?: 'NonDoctorPracticeStaffDTO';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  kustomer_customer_id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partner_id: Scalars['String']['output'];
  role?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlNotificationGroupDto = {
  __typename?: 'NotificationGroupDTO';
  communication_email?: Maybe<Scalars['Boolean']['output']>;
  communication_sms?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated not currently being used as marketing emails are controlled elsewhere */
  marketing_email?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated not currently being used as marketing sms are controlled elsewhere */
  marketing_sms?: Maybe<Scalars['Boolean']['output']>;
  order_tracking_email?: Maybe<Scalars['Boolean']['output']>;
  order_tracking_sms?: Maybe<Scalars['Boolean']['output']>;
  review_required_email?: Maybe<Scalars['Boolean']['output']>;
  review_required_sms?: Maybe<Scalars['Boolean']['output']>;
  status_change_email?: Maybe<Scalars['Boolean']['output']>;
  status_change_sms?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlNotificationGroupForwardingDto = {
  __typename?: 'NotificationGroupForwardingDTO';
  /** A list of ids of preferences that this preference is sending their communications notifications to */
  communication_receivers?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of ids of preferences who are sending their communications notifications to this preference */
  communication_senders?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of ids of preferences that this preference is sending their order tracking notifications to */
  order_tracking_receivers?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of ids of preferences who are sending their order tracking notifications to this preference */
  order_tracking_senders?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of ids of preferences that this preference is sending their review required notifications to */
  review_required_receivers?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of ids of preferences who are sending their review required notifications to this preference */
  review_required_senders?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of ids of preferences that this preference is sending their status change notifications to */
  status_change_receivers?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of ids of preferences who are sending their status change notifications to this preference */
  status_change_senders?: Maybe<Array<Scalars['String']['output']>>;
};

export enum LabsGqlNotificationGroupNames {
  Communication = 'communication',
  OrderTracking = 'order_tracking',
  ReviewRequired = 'review_required',
  StatusChange = 'status_change'
}

export enum LabsGqlNotificationLogEntityType {
  Invoice = 'invoice',
  Order = 'order',
  PatientShare = 'patient_share',
  Scan = 'scan',
  ScanbodyRequest = 'scanbody_request'
}

export type LabsGqlNotificationLogWithEventsArrayResult = {
  __typename?: 'NotificationLogWithEventsArrayResult';
  entities: Array<LabsGqlNotificationLogWithEventsResult>;
  totalCount: Scalars['Float']['output'];
};

export type LabsGqlNotificationLogWithEventsResult = {
  __typename?: 'NotificationLogWithEventsResult';
  category?: Maybe<LabsGqlNotificationLogEntityType>;
  events: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notification_log_created_at: Scalars['DateTime']['output'];
  recipients: Array<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type LabsGqlOxzMarginInformation = {
  __typename?: 'OXZMarginInformation';
  doctorMargins?: Maybe<Array<LabsGqlBasicMarginInfo>>;
  prepMarks?: Maybe<Array<LabsGqlPrepMark>>;
};

export type LabsGqlOffboardDesignStaffCommand = {
  user_id: Scalars['ID']['input'];
};

export type LabsGqlOnboardingMetaDto = {
  __typename?: 'OnboardingMetaDTO';
  billing_info_completed_at?: Maybe<Scalars['DateTime']['output']>;
  completed_at?: Maybe<Scalars['DateTime']['output']>;
  delivery_info_completed_at?: Maybe<Scalars['DateTime']['output']>;
  personalize_training_completed_at?: Maybe<Scalars['DateTime']['output']>;
  practice_scan_start?: Maybe<Scalars['DateTime']['output']>;
  scanner_setup_completed_at?: Maybe<Scalars['DateTime']['output']>;
  scanner_setup_started_at?: Maybe<Scalars['DateTime']['output']>;
  skipped_at?: Maybe<Scalars['DateTime']['output']>;
  started_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum LabsGqlOnboardingStatus {
  Completed = 'COMPLETED',
  Inprogress = 'INPROGRESS',
  NotRequired = 'NOT_REQUIRED',
  Skipped = 'SKIPPED',
  Untouched = 'UNTOUCHED'
}

export enum LabsGqlOnboardingStep {
  BillingAndDelivery = 'BILLING_AND_DELIVERY',
  OrderingPreferences = 'ORDERING_PREFERENCES',
  PrepareForGettingStartedCall = 'PREPARE_FOR_GETTING_STARTED_CALL',
  ProductPreferences = 'PRODUCT_PREFERENCES',
  ScannerSetup = 'SCANNER_SETUP',
  SetDandyAsPreferredLab = 'SET_DANDY_AS_PREFERRED_LAB'
}

export type LabsGqlOnboardingStepData = {
  __typename?: 'OnboardingStepData';
  status: LabsGqlOnboardingStatus;
  step_id: LabsGqlOnboardingStep;
};

export type LabsGqlOnboardingStepInput = {
  status: LabsGqlOnboardingStatus;
  step_id: LabsGqlOnboardingStep;
};

export enum LabsGqlOneTimeChargeCategory {
  ActivationFee = 'ActivationFee',
  ClinicalSupportSubscription = 'ClinicalSupportSubscription',
  ContractCommitment = 'ContractCommitment',
  DamagedEquipment = 'DamagedEquipment',
  LiveScanReviewPayPerUse = 'LiveScanReviewPayPerUse',
  LiveScanReviewSubscription = 'LiveScanReviewSubscription',
  ScanBodies = 'ScanBodies',
  SubscriptionGeneral = 'SubscriptionGeneral',
  UnreturnedEquipment = 'UnreturnedEquipment',
  UpgradeFee = 'UpgradeFee'
}

export type LabsGqlOneTimeChargeConfigDto = {
  __typename?: 'OneTimeChargeConfigDTO';
  category: LabsGqlOneTimeChargeCategory;
  description: Scalars['String']['output'];
  type: LabsGqlPracticeBillingRecurringItemType;
};

export type LabsGqlOneTimeCodeLoginResult = {
  __typename?: 'OneTimeCodeLoginResult';
  approved: Scalars['Boolean']['output'];
  error_message?: Maybe<Scalars['String']['output']>;
  options: Array<LabsGqlUserLoginOption>;
};

export type LabsGqlOnlyId = {
  __typename?: 'OnlyId';
  id: Scalars['String']['output'];
};

export type LabsGqlOpenPendingAlignerCaseEstimateModalCommand = {
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlOpsCancelOrderCommand = {
  cancelOrderBillingAction?: InputMaybe<LabsGqlCancelOrderBillingActionInput>;
  cancellation_reason?: InputMaybe<Scalars['String']['input']>;
  cancellation_reason_code?: LabsGqlCancellationReasonCode;
  delete_scan?: InputMaybe<Scalars['Boolean']['input']>;
  items_prices?: InputMaybe<Array<LabsGqlElementPriceHierarchyInput>>;
  orderId: Scalars['String']['input'];
  override_amount_due_cents?: InputMaybe<Scalars['Float']['input']>;
  skip_notification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlOptOutProductionOrderCommand = {
  order_id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type LabsGqlOptPracticeInToCreditCardSurchargeCommand = {
  practice_id: Scalars['String']['input'];
};

export type LabsGqlOptPracticeOutOfCreditCardSurchargeCommand = {
  item_id: Scalars['String']['input'];
};

export type LabsGqlOrderAdjustmentAttribution = LabsGqlInvoiceAdjustmentAttributionBase & {
  __typename?: 'OrderAdjustmentAttribution';
  order_id: Scalars['ID']['output'];
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlOrderAdjustmentAttributionInput = {
  amount_cents: Scalars['Int']['input'];
  order_id: Scalars['ID']['input'];
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlOrderAdminListViewEntry = {
  __typename?: 'OrderAdminListViewEntry';
  /** The date the order was placed */
  created_at: Scalars['DateTime']['output'];
  /** The due date for design, null if design is done or not internal design */
  design_due_date?: Maybe<Scalars['DateTime']['output']>;
  /** The pre-formatted name of the doctor */
  doctor_name: Scalars['String']['output'];
  /** The flags associated with the order */
  flags: Array<LabsGqlOrderAdminListViewEntryFlag>;
  id: Scalars['ID']['output'];
  /** Whether the order is on hold */
  is_on_hold: Scalars['Boolean']['output'];
  /** The description of the items in the order */
  items_description: Scalars['String']['output'];
  /** The short description of the items in the order */
  items_description_short: Scalars['String']['output'];
  /** The product unit types of the items in the order */
  items_product_unit_types: Array<Scalars['String']['output']>;
  /** The id of the manufacturer for the order */
  manufacturer_id: Scalars['String']['output'];
  order_tracker_entries: Array<LabsGqlOrderTrackerStepDefinition>;
  /** The pre-formatted name of the patient */
  patient_name: Scalars['String']['output'];
  /** The number of orders in our system for the patient */
  patient_order_count: Scalars['Int']['output'];
  /** The practice id associated with the order */
  practice_id: Scalars['String']['output'];
  /** The date of the last update to the order */
  updated_at: Scalars['DateTime']['output'];
};


export type LabsGqlOrderAdminListViewEntryOrder_Tracker_EntriesArgs = {
  timezone_offset_minutes: Scalars['Int']['input'];
};

export type LabsGqlOrderAdminListViewEntryFlag = {
  __typename?: 'OrderAdminListViewEntryFlag';
  description: Scalars['String']['output'];
  type: LabsGqlOrderAdminListViewEntryFlagType;
};

export enum LabsGqlOrderAdminListViewEntryFlagType {
  MissingItemPrice = 'MissingItemPrice',
  MissingPrice = 'MissingPrice',
  Refab = 'Refab',
  ReviewReason = 'ReviewReason',
  Split = 'Split'
}

export type LabsGqlOrderAutomation = {
  __typename?: 'OrderAutomation';
  actions: Array<LabsGqlAutomationActionConfig>;
  archived: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by_user_id: Scalars['String']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  filter: LabsGqlFilterSubmission;
  folder?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** Triggering event names */
  triggered_by: Array<LabsGqlAutomationTriggerEventNamesEnum>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlOrderCustomSearch = LabsGqlICustomSearchParams & {
  __typename?: 'OrderCustomSearch';
  order_criteria?: Maybe<Array<LabsGqlFilterCriteriaSubmission>>;
  search?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<LabsGqlSavedListOrdersSort>;
  type: LabsGqlCustomSearchType;
};

export type LabsGqlOrderCustomSearchInput = {
  order_criteria?: InputMaybe<Array<LabsGqlFilterCriteriaSubmissionInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
  type: LabsGqlCustomSearchType;
};

export enum LabsGqlOrderDelayStatus {
  Confirmed = 'confirmed',
  Potential = 'potential'
}

export type LabsGqlOrderDeliveryDelay = {
  __typename?: 'OrderDeliveryDelay';
  days: Scalars['Int']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  status: LabsGqlOrderDelayStatus;
};

export type LabsGqlOrderDesign = {
  __typename?: 'OrderDesign';
  /** @deprecated This field has been replaced by the new Design Revision system. */
  case_file?: Maybe<LabsGqlDesignCaseFile>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  case_file_parsed?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  case_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  created_at: Scalars['DateTime']['output'];
  /** @deprecated This field has been replaced by the new Design Revision system. */
  ctm_paths?: Maybe<Array<LabsGqlOrderDesignScan>>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  design_conversion_errors?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  design_prep_feedback?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  design_tree_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  design_type?: Maybe<LabsGqlOrderDesignType>;
  empty: Scalars['String']['output'];
  /** @deprecated This field has been replaced by the new Design Revision system. */
  event_log?: Maybe<LabsGqlDesignEventLog>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  file_path: Scalars['String']['output'];
  /** @deprecated This field has been replaced by the new Design Revision system. */
  guided_qc_results?: Maybe<Array<LabsGqlDesignReviewGuidedQcResultEntry>>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  internal_notes?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  is_manual_upload: Scalars['Boolean']['output'];
  /** @deprecated This field has been replaced by the new Design Revision system. */
  is_restorative_design_by_automate?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  last_upload_date: Scalars['DateTime']['output'];
  /** @deprecated This field has been replaced by the new Design Revision system. */
  manually_uploaded_manufacturer_files?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  manufacturer_files: Array<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  metadata_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  non_guided_qc_results?: Maybe<Array<LabsGqlOrderDesignNonGuidedQcResults>>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  parent_design_zip_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  parent_portal_design_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  ply_paths?: Maybe<Array<LabsGqlOrderDesignScan>>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  portal_design_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  portal_margins?: Maybe<Array<LabsGqlPortalMarginType>>;
  /** @deprecated This field is no longer utilized. It has no replacement. */
  reduce_opposing?: Maybe<LabsGqlDesignReduceOpposingGuide>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  revision_uploaded_by_staff_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  stl_paths?: Maybe<Array<LabsGqlOrderDesignScan>>;
  /** @deprecated This field has been replaced by the new Design Revision system. */
  threeshape_automate_order_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field is no longer used, and will be deprecated after all frontend clients update */
  zip_path?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlOrderDesignCapabilityDto = {
  __typename?: 'OrderDesignCapabilityDTO';
  description: Scalars['String']['output'];
  level: LabsGqlOrderDesignCapabilityLevel;
  name: Scalars['String']['output'];
  type: LabsGqlOrderDesignCapabilityType;
};

export type LabsGqlOrderDesignCapabilityInput = {
  description: Scalars['String']['input'];
  level: LabsGqlOrderDesignCapabilityLevel;
  name: Scalars['String']['input'];
  type: LabsGqlOrderDesignCapabilityType;
};

export enum LabsGqlOrderDesignCapabilityLevel {
  Level1 = 'level1',
  Level2 = 'level2',
  Level3 = 'level3',
  Level4 = 'level4',
  Level5 = 'level5',
  Level6 = 'level6',
  Other = 'other'
}

export enum LabsGqlOrderDesignCapabilityType {
  Aligners = 'aligners',
  AnteriorCrownAndBridge = 'anterior_crown_and_bridge',
  CrownAndBridge = 'crown_and_bridge',
  Dentures = 'dentures',
  DenturesWaxRim = 'dentures_wax_rim',
  Emax = 'emax',
  Implant = 'implant',
  Inlay = 'inlay',
  IpContacts = 'ip_contacts',
  ItemVariants = 'item_variants',
  Model = 'model',
  NewPractice = 'new_practice',
  NightGuards = 'night_guards',
  NobleWhite = 'noble_white',
  Other = 'other',
  Partials = 'partials',
  PartialsInjectionMold = 'partials_injection_mold',
  Pfm = 'pfm',
  Pfz = 'pfz',
  PosteriorCrownAndBridge = 'posterior_crown_and_bridge',
  RedCarpet = 'red_carpet',
  Refabrication = 'refabrication',
  Removables = 'removables',
  SplitOrder = 'split_order',
  SurgicalGuides = 'surgical_guides',
  Thermoform = 'thermoform',
  Waxup = 'waxup'
}

export type LabsGqlOrderDesignNonGuidedQcResults = {
  __typename?: 'OrderDesignNonGuidedQcResults';
  notes?: Maybe<Scalars['String']['output']>;
  passed: Scalars['Boolean']['output'];
  reasons?: Maybe<Array<Scalars['String']['output']>>;
};

export type LabsGqlOrderDesignScan = {
  __typename?: 'OrderDesignScan';
  imagePath?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  type: LabsGqlOrderDesignScanType;
};

export enum LabsGqlOrderDesignScanType {
  AnatomyElements = 'AnatomyElements',
  Cad = 'CAD',
  ExternalModels = 'ExternalModels',
  InjectionMold = 'InjectionMold',
  Other = 'Other',
  QcExtras = 'QcExtras',
  Scans = 'Scans'
}

export enum LabsGqlOrderDesignType {
  FinishedDesign = 'FINISHED_DESIGN',
  FinishedDesignWithModel = 'FINISHED_DESIGN_WITH_MODEL',
  Prep = 'PREP'
}

export type LabsGqlOrderFulfillmentWorkflow = {
  __typename?: 'OrderFulfillmentWorkflow';
  active_task?: Maybe<LabsGqlWorkflowTask>;
  closed_tasks: Array<LabsGqlWorkflowTask>;
  configuration: LabsGqlOrderFulfillmentWorkflowConfigDto;
};

export type LabsGqlOrderFulfillmentWorkflowConfigDto = {
  __typename?: 'OrderFulfillmentWorkflowConfigDTO';
  design_review2_required: Scalars['Boolean']['output'];
  design_review_required: Scalars['Boolean']['output'];
  enable_threeshape_automate: Scalars['Boolean']['output'];
  /** If true, enable the Automate Review step for Dandy Verified Design. */
  enable_threeshape_automate_review: Scalars['Boolean']['output'];
  /** If true, the lab will be prevented from starting fabrication until design review finished */
  external_design_review_blocking?: Maybe<Scalars['Boolean']['output']>;
  /** If true, an injection mold design is required for the order. */
  injection_mold_required: Scalars['Boolean']['output'];
  internal_design_prep_required: Scalars['Boolean']['output'];
  internal_design_required: Scalars['Boolean']['output'];
  last_fulfillment_started_at?: Maybe<Scalars['DateTime']['output']>;
  /** sorted list of necessary order review tasks */
  needed_review_steps: Array<LabsGqlReviewStep>;
  separate_model_design_step: Scalars['Boolean']['output'];
  /** If true, skip the finishing design (a.k.a. InternalDesign) step. */
  skip_finishing_design: Scalars['Boolean']['output'];
  waxup_required: Scalars['Boolean']['output'];
};

export type LabsGqlOrderInboxItem = {
  __typename?: 'OrderInboxItem';
  content: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  event_id: Scalars['Int']['output'];
  event_name: LabsGqlEventNames;
  order: LabsGqlLabOrderWithEvents;
  /** @deprecated superseded by order */
  order_id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  user_role?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlOrderInvoicingLedgerDto = {
  __typename?: 'OrderInvoicingLedgerDTO';
  billedItems: Array<LabsGqlInvoiceItemDto>;
  credits: Array<LabsGqlOrderLedgerInvoiceCreditDto>;
  ledgerInvoices: Array<LabsGqlLedgerInvoiceDetailsDto>;
  paidInvoiceIds: Array<Scalars['String']['output']>;
  pendingItem?: Maybe<LabsGqlPendingInvoiceItemDto>;
  totalInvoicedCents: Scalars['Int']['output'];
};

export type LabsGqlOrderItemAdjustmentAttribution = LabsGqlInvoiceAdjustmentAttributionBase & {
  __typename?: 'OrderItemAdjustmentAttribution';
  order_id: Scalars['ID']['output'];
  order_item_id: Scalars['ID']['output'];
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlOrderItemAdjustmentAttributionInput = {
  amount_cents: Scalars['Int']['input'];
  order_id: Scalars['ID']['input'];
  order_item_id: Scalars['ID']['input'];
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlOrderItemAlignerBiteConcerns = {
  __typename?: 'OrderItemAlignerBiteConcerns';
  improve_midline?: Maybe<LabsGqlBiteConcernOptions>;
  improve_overbite?: Maybe<LabsGqlBiteConcernOptions>;
  improve_overjet?: Maybe<LabsGqlBiteConcernOptions>;
};

export type LabsGqlOrderItemAlignerBiteConcernsInput = {
  improve_midline?: InputMaybe<LabsGqlBiteConcernOptions>;
  improve_overbite?: InputMaybe<LabsGqlBiteConcernOptions>;
  improve_overjet?: InputMaybe<LabsGqlBiteConcernOptions>;
};

export type LabsGqlOrderItemAlignerCrowdingResolution = {
  __typename?: 'OrderItemAlignerCrowdingResolution';
  expansion?: Maybe<LabsGqlCrowdingResolutionOption>;
  ipr?: Maybe<LabsGqlCrowdingResolutionOption>;
  proclination?: Maybe<LabsGqlCrowdingResolutionOption>;
};

export type LabsGqlOrderItemAlignerCrowdingResolutionInput = {
  expansion?: InputMaybe<LabsGqlCrowdingResolutionOption>;
  ipr?: InputMaybe<LabsGqlCrowdingResolutionOption>;
  proclination?: InputMaybe<LabsGqlCrowdingResolutionOption>;
};

export type LabsGqlOrderItemAlignerTreatmentMetadata = {
  __typename?: 'OrderItemAlignerTreatmentMetadata';
  aligner_plan?: Maybe<LabsGqlAlignerPlan>;
  attachment_restricted_teeth?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  bite_concerns?: Maybe<LabsGqlOrderItemAlignerBiteConcerns>;
  crowding_resolution?: Maybe<LabsGqlOrderItemAlignerCrowdingResolution>;
  extraction_teeth?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  interproximal_space_sizes?: Maybe<Array<LabsGqlInterproximalSpaceSize>>;
  movement_restricted_teeth?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  proposed_step_limit?: Maybe<Scalars['Int']['output']>;
  trays_count?: Maybe<Scalars['Int']['output']>;
  treatment_area?: Maybe<LabsGqlAlignerTreatmentArea>;
};

export type LabsGqlOrderItemAlignerTreatmentMetadataInput = {
  aligner_plan?: InputMaybe<LabsGqlAlignerPlan>;
  attachment_restricted_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  bite_concerns?: InputMaybe<LabsGqlOrderItemAlignerBiteConcernsInput>;
  crowding_resolution?: InputMaybe<LabsGqlOrderItemAlignerCrowdingResolutionInput>;
  extraction_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  interproximal_space_sizes?: InputMaybe<Array<LabsGqlInterproximalSpaceSizeInput>>;
  movement_restricted_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  proposed_step_limit?: InputMaybe<Scalars['Int']['input']>;
  trays_count?: InputMaybe<Scalars['Int']['input']>;
  treatment_area?: InputMaybe<LabsGqlAlignerTreatmentArea>;
};

export enum LabsGqlOrderItemArch {
  Dual = 'Dual',
  Lower = 'Lower',
  Upper = 'Upper'
}

export type LabsGqlOrderItemArchUnit = {
  __typename?: 'OrderItemArchUnit';
  arch: LabsGqlOrderItemArch;
  material: Scalars['String']['output'];
  unit_type: Scalars['String']['output'];
};

export type LabsGqlOrderItemArchUnitInput = {
  arch: LabsGqlOrderItemArch;
  material: Scalars['String']['input'];
  unit_type: Scalars['String']['input'];
};

export type LabsGqlOrderItemDentureBiteAdjustment = {
  __typename?: 'OrderItemDentureBiteAdjustment';
  adjustment_distance_mm?: Maybe<Scalars['Float']['output']>;
  adjustment_type: LabsGqlDenturesBiteAdjustmentType;
};

export type LabsGqlOrderItemDentureBiteAdjustmentInput = {
  adjustment_distance_mm?: InputMaybe<Scalars['Float']['input']>;
  adjustment_type: LabsGqlDenturesBiteAdjustmentType;
};

export type LabsGqlOrderItemDentureCopyMetadata = {
  __typename?: 'OrderItemDentureCopyMetadata';
  copy_change_notes?: Maybe<Scalars['String']['output']>;
  is_exact_copy: Scalars['Boolean']['output'];
};

export type LabsGqlOrderItemDentureCopyMetadataInput = {
  copy_change_notes?: InputMaybe<Scalars['String']['input']>;
  is_exact_copy: Scalars['Boolean']['input'];
};

export type LabsGqlOrderItemDentureImplantSupport = {
  __typename?: 'OrderItemDentureImplantSupport';
  collar_heights_mm?: Maybe<Scalars['String']['output']>;
  is_horseshoe?: Maybe<Scalars['Boolean']['output']>;
  is_implant_supported: Scalars['Boolean']['output'];
};

export type LabsGqlOrderItemDentureImplantSupportInput = {
  collar_heights_mm?: InputMaybe<Scalars['String']['input']>;
  is_horseshoe?: InputMaybe<Scalars['Boolean']['input']>;
  is_implant_supported: Scalars['Boolean']['input'];
};

export type LabsGqlOrderItemDentureMidlineCorrection = {
  __typename?: 'OrderItemDentureMidlineCorrection';
  correction_distance_mm?: Maybe<Scalars['Float']['output']>;
  correction_type: LabsGqlDenturesMidlineCorrectionType;
};

export type LabsGqlOrderItemDentureMidlineCorrectionInput = {
  correction_distance_mm?: InputMaybe<Scalars['Float']['input']>;
  correction_type: LabsGqlDenturesMidlineCorrectionType;
};

export type LabsGqlOrderItemDentureProductionMetadata = {
  __typename?: 'OrderItemDentureProductionMetadata';
  add_metal_framework?: Maybe<Scalars['Boolean']['output']>;
  add_metal_mesh?: Maybe<Scalars['Boolean']['output']>;
  add_post_dam?: Maybe<Scalars['Boolean']['output']>;
  add_softliner?: Maybe<Scalars['Boolean']['output']>;
  add_stippling?: Maybe<Scalars['Boolean']['output']>;
  bite_adjustment?: Maybe<LabsGqlOrderItemDentureBiteAdjustment>;
  copy_metadata?: Maybe<LabsGqlOrderItemDentureCopyMetadata>;
  correct_occlusal_scheme?: Maybe<Scalars['Boolean']['output']>;
  cusil_gaskets_teeth?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  festooning_level?: Maybe<LabsGqlDenturesFestooningLevel>;
  handle_diastema?: Maybe<LabsGqlDenturesHandleDiastemaOption>;
  implant_support?: Maybe<LabsGqlOrderItemDentureImplantSupport>;
  midline_correction?: Maybe<LabsGqlOrderItemDentureMidlineCorrection>;
  quantity?: Maybe<Scalars['Int']['output']>;
  smile_style?: Maybe<LabsGqlSmileStyleChoice>;
  try_in_design?: Maybe<LabsGqlDenturesTryInDesignType>;
};

export type LabsGqlOrderItemDentureProductionMetadataInput = {
  add_metal_framework?: InputMaybe<Scalars['Boolean']['input']>;
  add_metal_mesh?: InputMaybe<Scalars['Boolean']['input']>;
  add_post_dam?: InputMaybe<Scalars['Boolean']['input']>;
  add_softliner?: InputMaybe<Scalars['Boolean']['input']>;
  add_stippling?: InputMaybe<Scalars['Boolean']['input']>;
  bite_adjustment?: InputMaybe<LabsGqlOrderItemDentureBiteAdjustmentInput>;
  copy_metadata?: InputMaybe<LabsGqlOrderItemDentureCopyMetadataInput>;
  correct_occlusal_scheme?: InputMaybe<Scalars['Boolean']['input']>;
  cusil_gaskets_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  festooning_level?: InputMaybe<LabsGqlDenturesFestooningLevel>;
  handle_diastema?: InputMaybe<LabsGqlDenturesHandleDiastemaOption>;
  implant_support?: InputMaybe<LabsGqlOrderItemDentureImplantSupportInput>;
  midline_correction?: InputMaybe<LabsGqlOrderItemDentureMidlineCorrectionInput>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  smile_style?: InputMaybe<LabsGqlSmileStyleChoice>;
  try_in_design?: InputMaybe<LabsGqlDenturesTryInDesignType>;
};

export enum LabsGqlOrderItemDentureScanType {
  Copy = 'Copy',
  Impression = 'Impression',
  Oral = 'Oral'
}

export type LabsGqlOrderItemImplantMetadata = {
  __typename?: 'OrderItemImplantMetadata';
  /** Replace manufacturer and generic_manufacturer for more accurate abutment pricing */
  abutment_part_manufacturer?: Maybe<Scalars['String']['output']>;
  /** Whether this implant item should use authentic/OEM parts. Set internally post-checkout. */
  authentic?: Maybe<Scalars['Boolean']['output']>;
  connection_size: Scalars['String']['output'];
  /** If generic, the generic parts manufacturer. Set internally post-checkout. */
  generic_manufacturer?: Maybe<LabsGqlOrderItemLinkGenericManufacturer>;
  manufacturer: Scalars['String']['output'];
  non_preferred_part_reason?: Maybe<Scalars['String']['output']>;
  part_id?: Maybe<Scalars['String']['output']>;
  relationship: LabsGqlOrderItemLinkRelationship;
  scanbody_id?: Maybe<Scalars['String']['output']>;
  system: Scalars['String']['output'];
};

export type LabsGqlOrderItemImplantMetadataInput = {
  /** Replace manufacturer and generic_manufacturer for more accurate abutment pricing */
  abutment_part_manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** Whether this implant item should use authentic/OEM parts. Set internally post-checkout. */
  authentic?: InputMaybe<Scalars['Boolean']['input']>;
  connection_size: Scalars['String']['input'];
  /** If generic, the generic parts manufacturer. Set internally post-checkout. */
  generic_manufacturer?: InputMaybe<LabsGqlOrderItemLinkGenericManufacturer>;
  manufacturer: Scalars['String']['input'];
  non_preferred_part_reason?: InputMaybe<Scalars['String']['input']>;
  part_id?: InputMaybe<Scalars['String']['input']>;
  relationship: LabsGqlOrderItemLinkRelationship;
  scanbody_id?: InputMaybe<Scalars['String']['input']>;
  system: Scalars['String']['input'];
};

export enum LabsGqlOrderItemLinkGenericManufacturer {
  Argen = 'Argen',
  Biodenta = 'Biodenta',
  Dess = 'Dess',
  Mist = 'MIST',
  Medentika = 'Medentika',
  NtTrading = 'NTTrading',
  ThreeShape = 'ThreeShape',
  TruAbutment = 'TruAbutment'
}

export enum LabsGqlOrderItemLinkRelationship {
  CementRetained = 'CementRetained',
  ScrewRetained = 'ScrewRetained',
  Screwmentable = 'Screwmentable'
}

export enum LabsGqlOrderItemLinkType {
  Bridge = 'Bridge',
  Implant = 'Implant',
  ImplantBridge = 'ImplantBridge'
}

export enum LabsGqlOrderItemModelType {
  DualFullArch = 'DualFullArch',
  DualQuadrant = 'DualQuadrant',
  Quadrant = 'Quadrant',
  SingleFullArch = 'SingleFullArch'
}

export type LabsGqlOrderItemMultiToothUnit = {
  __typename?: 'OrderItemMultiToothUnit';
  material: Scalars['String']['output'];
  unit_type: Scalars['String']['output'];
  unns: Array<Scalars['ToothNumber']['output']>;
};

export type LabsGqlOrderItemMultiToothUnitInput = {
  material: Scalars['String']['input'];
  unit_type: Scalars['String']['input'];
  unns: Array<Scalars['Int']['input']>;
};

export type LabsGqlOrderItemPartialDentureClasp = {
  __typename?: 'OrderItemPartialDentureClasp';
  count: Scalars['Int']['output'];
  type: LabsGqlOrderItemPartialDentureClaspType;
};

export enum LabsGqlOrderItemPartialDentureClaspColor {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A3_5 = 'A3_5',
  B1 = 'B1',
  C3 = 'C3',
  Clear = 'Clear',
  G2 = 'G2',
  Pink = 'Pink',
  ToothShade = 'ToothShade'
}

export type LabsGqlOrderItemPartialDentureClaspInput = {
  count: Scalars['Int']['input'];
  type: LabsGqlOrderItemPartialDentureClaspType;
};

export enum LabsGqlOrderItemPartialDentureClaspType {
  Acetal = 'Acetal',
  Duraflex = 'Duraflex',
  Metal = 'Metal',
  MetalRest = 'MetalRest',
  ValplastTcs = 'ValplastTCS'
}

export enum LabsGqlOrderItemPartialDentureCoverageType {
  Bilateral = 'Bilateral',
  LabDecision = 'LabDecision',
  Unilateral = 'Unilateral'
}

export enum LabsGqlOrderItemPartialDentureGenericClaspType {
  Acetal = 'Acetal',
  Flexible = 'Flexible',
  FlexibleTissueShade = 'FlexibleTissueShade',
  Metal = 'Metal',
  MetalWithBall = 'MetalWithBall',
  WroughtWire = 'WroughtWire'
}

export type LabsGqlOrderItemPartialDentureProductionMetadata = {
  __typename?: 'OrderItemPartialDentureProductionMetadata';
  clasp_color?: Maybe<LabsGqlOrderItemPartialDentureClaspColor>;
  clasp_generic_type?: Maybe<LabsGqlOrderItemPartialDentureGenericClaspType>;
  clasp_locations?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  clasp_notes?: Maybe<Scalars['String']['output']>;
  clasp_shade?: Maybe<Scalars['String']['output']>;
  digital_extractions?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  is_replacement?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlOrderItemPartialDentureProductionMetadataInput = {
  clasp_color?: InputMaybe<LabsGqlOrderItemPartialDentureClaspColor>;
  clasp_generic_type?: InputMaybe<LabsGqlOrderItemPartialDentureGenericClaspType>;
  clasp_locations?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  clasp_notes?: InputMaybe<Scalars['String']['input']>;
  clasp_shade?: InputMaybe<Scalars['String']['input']>;
  digital_extractions?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  is_replacement?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LabsGqlOrderItemPartialDentureProductionType {
  Final = 'Final',
  TryInFrameworkOnly = 'TryInFrameworkOnly',
  TryInTeethSetup = 'TryInTeethSetup',
  WaxRim = 'WaxRim'
}

export type LabsGqlOrderItemPricing = {
  __typename?: 'OrderItemPricing';
  amount_due_cents?: Maybe<Scalars['Float']['output']>;
  override_amount_due_cents?: Maybe<Scalars['Float']['output']>;
  override_amount_due_reason?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlOrderItemRemovableClasp = {
  __typename?: 'OrderItemRemovableClasp';
  count: Scalars['Int']['output'];
  type: LabsGqlOrderItemRemovableClaspType;
};

export type LabsGqlOrderItemRemovableClaspInput = {
  count: Scalars['Int']['input'];
  type: LabsGqlOrderItemRemovableClaspType;
};

export enum LabsGqlOrderItemRemovableClaspType {
  AdamsClasp = 'AdamsClasp',
  BallClasp = 'BallClasp',
  CClasp = 'CClasp'
}

export enum LabsGqlOrderItemSkuType {
  Aligners = 'Aligners',
  Bridge = 'Bridge',
  Crown = 'Crown',
  Denture = 'Denture',
  Implant = 'Implant',
  ImplantBridge = 'ImplantBridge',
  Inlay = 'Inlay',
  Model = 'Model',
  Other = 'Other',
  Partial = 'Partial',
  Removeable = 'Removeable',
  SleepApnea = 'SleepApnea',
  SurgicalGuide = 'SurgicalGuide',
  Tmj = 'TMJ',
  Unknown = 'Unknown',
  Veneer = 'Veneer',
  Waxup = 'Waxup',
  WellnessScan = 'WellnessScan'
}

export type LabsGqlOrderItemSingleToothUnit = {
  __typename?: 'OrderItemSingleToothUnit';
  material: Scalars['String']['output'];
  /** The pennyweight of precious metal used to fabricate this item, if specified by the manufacturer. */
  precious_metal_pennyweight?: Maybe<Scalars['Float']['output']>;
  unit_type: Scalars['String']['output'];
  unn: Scalars['ToothNumber']['output'];
};

export type LabsGqlOrderItemSingleToothUnitInput = {
  material: Scalars['String']['input'];
  precious_metal_pennyweight?: InputMaybe<Scalars['Float']['input']>;
  unit_type: Scalars['String']['input'];
  unn: Scalars['Int']['input'];
};

export type LabsGqlOrderItemSleepApneaManufacturingMetadata = {
  __typename?: 'OrderItemSleepApneaManufacturingMetadata';
  should_cover_last_molar_distal: Scalars['Boolean']['output'];
  should_have_posterior_support_pads?: Maybe<Scalars['Boolean']['output']>;
  should_include_anterior_discluding_ramp: Scalars['Boolean']['output'];
};

export type LabsGqlOrderItemSleepApneaManufacturingMetadataInput = {
  should_cover_last_molar_distal: Scalars['Boolean']['input'];
  should_have_posterior_support_pads?: InputMaybe<Scalars['Boolean']['input']>;
  should_include_anterior_discluding_ramp: Scalars['Boolean']['input'];
};

export enum LabsGqlOrderItemSleepApneaType {
  Dorsal = 'Dorsal',
  Herbst = 'Herbst'
}

export type LabsGqlOrderItemSurgicalGuidanceTreatmentPlanMetadata = {
  __typename?: 'OrderItemSurgicalGuidanceTreatmentPlanMetadata';
  final_restorative_type: LabsGqlOrderItemSurgicalGuideFinalRestorativeType;
  patient_cbct_appointment_date?: Maybe<Scalars['String']['output']>;
  planned_procedures: Array<LabsGqlOrderItemSurgicalGuidePlannedProcedureType>;
  treatment_plan_review_type: LabsGqlOrderItemSurgicalGuideTreatmentPlanReviewType;
};

export type LabsGqlOrderItemSurgicalGuidanceTreatmentPlanMetadataInput = {
  final_restorative_type: LabsGqlOrderItemSurgicalGuideFinalRestorativeType;
  patient_cbct_appointment_date?: InputMaybe<Scalars['String']['input']>;
  planned_procedures: Array<LabsGqlOrderItemSurgicalGuidePlannedProcedureType>;
  treatment_plan_review_type: LabsGqlOrderItemSurgicalGuideTreatmentPlanReviewType;
};

export enum LabsGqlOrderItemSurgicalGuideFinalRestorativeType {
  FixedFullArch = 'FixedFullArch',
  ImplantBridge = 'ImplantBridge',
  ImplantCrown = 'ImplantCrown',
  ImplantRetainedOverdenture = 'ImplantRetainedOverdenture',
  ImplantRetainedPartial = 'ImplantRetainedPartial'
}

export type LabsGqlOrderItemSurgicalGuideImplantMetadata = {
  __typename?: 'OrderItemSurgicalGuideImplantMetadata';
  desired_size?: Maybe<Scalars['String']['output']>;
  drill_kit: Scalars['String']['output'];
  manufacturer: Scalars['String']['output'];
  system: Scalars['String']['output'];
};

export type LabsGqlOrderItemSurgicalGuideImplantMetadataInput = {
  desired_size?: InputMaybe<Scalars['String']['input']>;
  drill_kit: Scalars['String']['input'];
  manufacturer: Scalars['String']['input'];
  system: Scalars['String']['input'];
};

export enum LabsGqlOrderItemSurgicalGuidePlannedProcedureType {
  BoneGraft = 'BoneGraft',
  BoneReduction = 'BoneReduction',
  SinusLift = 'SinusLift',
  TissueFlap = 'TissueFlap'
}

export enum LabsGqlOrderItemSurgicalGuideTreatmentPlanReviewType {
  LiveReview = 'LiveReview',
  SelfReview = 'SelfReview'
}

export enum LabsGqlOrderItemSurgicalGuideType {
  TissueSupported = 'TissueSupported',
  ToothSupported = 'ToothSupported'
}

export type LabsGqlOrderItemSurgicalGuideUnit = {
  __typename?: 'OrderItemSurgicalGuideUnit';
  implant_metadata: LabsGqlOrderItemSurgicalGuideImplantMetadata;
  unit_type: Scalars['String']['output'];
  unns: Array<Scalars['ToothNumber']['output']>;
};

export type LabsGqlOrderItemSurgicalGuideUnitInput = {
  implant_metadata: LabsGqlOrderItemSurgicalGuideImplantMetadataInput;
  unit_type: Scalars['String']['input'];
  unns: Array<Scalars['Int']['input']>;
};

export enum LabsGqlOrderItemTmjApplianceMaterial {
  Astron = 'Astron',
  Hard = 'Hard',
  HardSoft = 'HardSoft'
}

export enum LabsGqlOrderItemTmjColor {
  Clear = 'Clear',
  Pink = 'Pink'
}

export type LabsGqlOrderItemTmjManufacturingMetadata = {
  __typename?: 'OrderItemTMJManufacturingMetadata';
  appliance_material?: Maybe<LabsGqlOrderItemTmjApplianceMaterial>;
  arch_separation_distance_mm?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<LabsGqlOrderItemTmjColor>;
  lingual_wire?: Maybe<Scalars['Boolean']['output']>;
  splint_material_thickness_mm?: Maybe<Scalars['Float']['output']>;
  teeth_coverage?: Maybe<LabsGqlOrderItemTmjTeethCoverage>;
  teeth_height_coverage?: Maybe<LabsGqlOrderItemTmjTeethHeightCoverage>;
};

export type LabsGqlOrderItemTmjManufacturingMetadataInput = {
  appliance_material?: InputMaybe<LabsGqlOrderItemTmjApplianceMaterial>;
  arch_separation_distance_mm?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<LabsGqlOrderItemTmjColor>;
  lingual_wire?: InputMaybe<Scalars['Boolean']['input']>;
  splint_material_thickness_mm?: InputMaybe<Scalars['Float']['input']>;
  teeth_coverage?: InputMaybe<LabsGqlOrderItemTmjTeethCoverage>;
  teeth_height_coverage?: InputMaybe<LabsGqlOrderItemTmjTeethHeightCoverage>;
};

export enum LabsGqlOrderItemTmjTeethCoverage {
  CanineToCanine = 'CanineToCanine',
  OnlyAnteriors = 'OnlyAnteriors'
}

export enum LabsGqlOrderItemTmjTeethHeightCoverage {
  FullCoverage = 'FullCoverage',
  HalfwayOnTooth = 'HalfwayOnTooth',
  HeightOfContour = 'HeightOfContour',
  IncisalThird = 'IncisalThird'
}

export enum LabsGqlOrderItemTmjType {
  AnteriorDeprogrammer = 'AnteriorDeprogrammer',
  Dawson = 'Dawson',
  Gelb = 'Gelb',
  Nti = 'NTI'
}

export type LabsGqlOrderItemUnitMetadata = {
  __typename?: 'OrderItemUnitMetadata';
  material: Scalars['String']['output'];
  unit_type: Scalars['String']['output'];
};

export type LabsGqlOrderItemUnitMetadataInput = {
  material: Scalars['String']['input'];
  unit_type: Scalars['String']['input'];
};

export enum LabsGqlOrderItemVariantChargeCategory {
  CrownContactsV1 = 'CrownContactsV1'
}

export type LabsGqlOrderItemVariantChargeConfigDto = {
  __typename?: 'OrderItemVariantChargeConfigDTO';
  category: LabsGqlOrderItemVariantChargeCategory;
  description: Scalars['String']['output'];
  salesorder_id: Scalars['String']['output'];
  salesorder_item_id: Scalars['String']['output'];
  type: LabsGqlPracticeBillingRecurringItemType;
};

export enum LabsGqlOrderItemVariantType {
  CrownContactsV1Base = 'CrownContactsV1Base',
  CrownContactsV1Light = 'CrownContactsV1Light',
  CrownContactsV1Tight = 'CrownContactsV1Tight'
}

export enum LabsGqlOrderItemVariantsPrefType {
  CrownContactsV1 = 'CrownContactsV1',
  None = 'None'
}

export type LabsGqlOrderLedgerInvoiceCreditAllocationDto = {
  __typename?: 'OrderLedgerInvoiceCreditAllocationDto';
  amountCents: Scalars['Float']['output'];
  invoiceId: Scalars['ID']['output'];
};

export type LabsGqlOrderLedgerInvoiceCreditDto = {
  __typename?: 'OrderLedgerInvoiceCreditDTO';
  allocations: Array<LabsGqlOrderLedgerInvoiceCreditAllocationDto>;
  amount_issued_cents: Scalars['Int']['output'];
  attribution?: Maybe<LabsGqlInvoiceAdjustmentAttributionDto>;
  balance_cents: Scalars['Int']['output'];
  categoryName: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by_user_id?: Maybe<Scalars['String']['output']>;
  credit_category_id?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  organization_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlOrderMetadataForLabSlipDto = {
  __typename?: 'OrderMetadataForLabSlipDTO';
  creditAmountCents?: Maybe<Scalars['Float']['output']>;
  orderMetadataType: Scalars['String']['output'];
};

export type LabsGqlOrderPracticeDates = {
  __typename?: 'OrderPracticeDates';
  /** If the practice requested a DDP, this is the date on which we expect it to be completed. */
  digital_design_preview_estimated_completion_date?: Maybe<Scalars['DateTime']['output']>;
  /** The date on which we expect this order to be delivered. It is buffered against the original deadline. */
  estimated_delivery_date: Scalars['DateTime']['output'];
  /** Whether or not the order has been delayed. This is true any time that estimated_delivery_date has a date (not time) greater than promised_delivery_date. */
  is_delivery_delayed: Scalars['Boolean']['output'];
  /** Whether or not the digital design preview has been delayed. This is true any time that digital_design_preview_estimated_completion_date has a date (not time) greater than promised_digital_design_preview_completion_date. False if the order didn't request a DDP. */
  is_digital_design_preview_delayed: Scalars['Boolean']['output'];
  /** The date we have promised the practice the order would arrive by. This may change if the RX changes, but it should otherwise be what the practice was shown at Checkout. */
  promised_delivery_date: Scalars['DateTime']['output'];
  /** If the practice requested a DDP, this is the date on which we last promised it would be ready. */
  promised_digital_design_preview_completion_date?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlOrderPracticeListViewEntry = {
  __typename?: 'OrderPracticeListViewEntry';
  /** If there is an active "ask doctor" workflow (eg for holds or scan reviews), this will be the ID of the active request. */
  active_doctor_request_id?: Maybe<Scalars['String']['output']>;
  /** The active return for the sales order, or null if there is not an active ongoing return. */
  active_return?: Maybe<LabsGqlIOrderReturn>;
  /** Whether the order is able to be continued. */
  can_continue_multi_step_crown: Scalars['Boolean']['output'];
  /** Whether is the order is able to be edited at the current time. */
  can_edit: Scalars['Boolean']['output'];
  /** Whether an order can be manually marked as delivered. */
  can_mark_delivered: Scalars['Boolean']['output'];
  /** Whether is the order is able to be refabricated at the current time. */
  can_refabricate: Scalars['Boolean']['output'];
  /** Whether is the order is able to have feedback submitted at the current time. */
  can_submit_feedback: Scalars['Boolean']['output'];
  /** Whether is the order is able to have feedback submitted for the try-in specifically at the current time. */
  can_submit_tryin_feedback: Scalars['Boolean']['output'];
  /** The actual date of delivery of the order, if it has been physically delivered yet. */
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  /** The pre-formatted name of the doctor, eg 'Dr. Gregory House'. */
  doctor_name: Scalars['String']['output'];
  /** Whether the order currently needs a doctor review, eg for a waxup or aligners treatment plan review. */
  does_need_review: Scalars['Boolean']['output'];
  /** If the order is on hold, return the hold id. */
  hold_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the order has been canceled. */
  is_canceled: Scalars['Boolean']['output'];
  /** Whether the order has been delivered to the doctor. */
  is_delivered: Scalars['Boolean']['output'];
  /** Whether the order has a visible hold for the current user. Practices only see a subset of holds. */
  is_on_hold: Scalars['Boolean']['output'];
  /** Whether the order has been paused by the managing practice. */
  is_on_practice_managed_pause: Scalars['Boolean']['output'];
  /** Whether the order has been sent for refabrication. */
  is_refabricated: Scalars['Boolean']['output'];
  /** The product unit type icons to render. */
  item_product_unit_types: Array<Scalars['String']['output']>;
  /** The pre-formatted description of the items, eg 'Implant Bridge 11-13, Crown 5' */
  items_description: Scalars['String']['output'];
  /** The pre-formatted order number, eg '12-345-678 */
  order_number: Scalars['String']['output'];
  order_tracker_entries: Array<LabsGqlOrderTrackerStepDefinition>;
  /** The pre-formatted name of the patient, eg 'Bob Hope'. */
  patient_name: Scalars['String']['output'];
  /** The pre-formatted name of the patient, eg 'Bob Hope'. */
  patient_name_shorthand: Scalars['String']['output'];
  /** If the order is currently on hold due to a practice managed paused, returns the subcategory of the pause */
  practice_managed_pause_subcategory?: Maybe<Scalars['String']['output']>;
  /** The ID of the order that replaces this one. This is typically undefined unless the order has been Cancelled and Resubmitted. */
  replaced_by_order_id?: Maybe<Scalars['String']['output']>;
  /** The list of item descriptions which have already been reviewed by post-delivery doctor feedback. */
  reviewed_items_descriptions: Array<Scalars['String']['output']>;
  /** The list of item descriptions which have not yet been reviewed by post-delivery doctor feedback. */
  unreviewed_items_descriptions: Array<Scalars['String']['output']>;
  /** The date of the latest update to the order. */
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  variant_feedback_status?: Maybe<Scalars['String']['output']>;
  waxup_status?: Maybe<LabsGqlLabOrderWaxupReviewStatus>;
};


export type LabsGqlOrderPracticeListViewEntryOrder_Tracker_EntriesArgs = {
  timezone_offset_minutes: Scalars['Int']['input'];
};

export type LabsGqlOrderPriceAdjustmentBulkJobStatus = {
  __typename?: 'OrderPriceAdjustmentBulkJobStatus';
  completed_at?: Maybe<Scalars['DateTime']['output']>;
  created_at: Scalars['DateTime']['output'];
  errors: Array<LabsGqlBulkPriceAdjustmentOrderResult>;
  id: Scalars['String']['output'];
  order_ids: Array<Scalars['String']['output']>;
  /** Orders where the partner did not have prices for other items in the order */
  orders_missing_pricing: Array<LabsGqlBulkPriceAdjustmentOrderResult>;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
  successes: Array<LabsGqlBulkPriceAdjustmentOrderResult>;
};

export type LabsGqlOrderPriceDto = {
  __typename?: 'OrderPriceDTO';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  item_id: Scalars['ID']['output'];
  order_id: Scalars['ID']['output'];
  override_total_price_cents?: Maybe<Scalars['Int']['output']>;
  override_total_price_cents_reason?: Maybe<Scalars['String']['output']>;
  /** The total unit price for this entry */
  price_cents: Scalars['Int']['output'];
  price_id: Scalars['ID']['output'];
  price_name: Scalars['String']['output'];
  /** The total price for this entry (equal to price_cents times unit_count) */
  total_price_cents: Scalars['Int']['output'];
  /** How many units to be considered for billing purposes */
  unit_count: Scalars['Int']['output'];
  units: Array<LabsGqlOrderPriceUnitInfoDto>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlOrderPriceEntityDto = {
  __typename?: 'OrderPriceEntityDTO';
  id: Scalars['ID']['output'];
  item_id: Scalars['ID']['output'];
  order_id: Scalars['ID']['output'];
  override_reason?: Maybe<Scalars['String']['output']>;
  override_total_price_cents?: Maybe<Scalars['Int']['output']>;
  /** The total unit price for this entry */
  price_cents: Scalars['Int']['output'];
  price_id: Scalars['ID']['output'];
  /** The total price for this entry (equal to price_cents times unit_count) */
  total_price_cents?: Maybe<Scalars['Int']['output']>;
  unit: LabsGqlUnitAndPreferenceInfoDto;
  /** How many units to be considered for billing purposes */
  unit_count: Scalars['Int']['output'];
  /** Whether or not price has ever been overridden */
  use_override: Scalars['Boolean']['output'];
};

export type LabsGqlOrderPriceUnitInfoDto = {
  __typename?: 'OrderPriceUnitInfoDTO';
  arch?: Maybe<LabsGqlOrderItemArch>;
  material?: Maybe<Scalars['String']['output']>;
  /** The weight of precious metal being priced */
  precious_metal_pennyweight?: Maybe<Scalars['Float']['output']>;
  unit_type: Scalars['String']['output'];
  unn?: Maybe<Scalars['Int']['output']>;
  unns?: Maybe<Array<Scalars['Int']['output']>>;
};

export type LabsGqlOrderPriceUnitInfoInput = {
  arch?: InputMaybe<LabsGqlOrderItemArch>;
  material?: InputMaybe<Scalars['String']['input']>;
  /** The weight of precious metal being priced */
  precious_metal_pennyweight?: InputMaybe<Scalars['Float']['input']>;
  unit_type: Scalars['String']['input'];
  unn?: InputMaybe<Scalars['Int']['input']>;
  unns?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LabsGqlOrderRefabReasonDto = {
  __typename?: 'OrderRefabReasonDTO';
  category?: Maybe<Scalars['String']['output']>;
  fault?: Maybe<Scalars['String']['output']>;
  fault_override?: Maybe<LabsGqlRefabFault>;
  internal_only: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  refab_reason_id?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlOrderRefabReasonInput = {
  fault_override?: InputMaybe<LabsGqlRefabFault>;
  name: Scalars['String']['input'];
  refab_reason_id?: InputMaybe<Scalars['String']['input']>;
};

export enum LabsGqlOrderReturnStatus {
  Cancelled = 'cancelled',
  NotShipped = 'notShipped',
  Received = 'received',
  Transit = 'transit'
}

export enum LabsGqlOrderReturnType {
  Iteration = 'iteration',
  Refabrication = 'refabrication'
}

export type LabsGqlOrderReturnUserAction = {
  __typename?: 'OrderReturnUserAction';
  notes: Scalars['String']['output'];
  user_first_name: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
  user_last_name: Scalars['String']['output'];
  user_role: Scalars['String']['output'];
};

export type LabsGqlOrderSla = {
  __typename?: 'OrderSLA';
  buffer_days?: Maybe<Scalars['Int']['output']>;
  current_stage?: Maybe<Scalars['String']['output']>;
  design_days?: Maybe<Scalars['Int']['output']>;
  design_due_date?: Maybe<Scalars['DateTime']['output']>;
  due_date: Scalars['DateTime']['output'];
  due_date_buffered?: Maybe<Scalars['DateTime']['output']>;
  /** Deprecated, should always be null */
  due_date_override?: Maybe<Scalars['DateTime']['output']>;
  fabrication_days: Scalars['Int']['output'];
  /** Deprecated, should always be null */
  fabrication_days_override?: Maybe<Scalars['Int']['output']>;
  internal_committed_due_date: Scalars['DateTime']['output'];
  internal_committed_ship_by_date: Scalars['DateTime']['output'];
  manufacturer_committed_due_date: Scalars['DateTime']['output'];
  manufacturer_committed_ship_by_date: Scalars['DateTime']['output'];
  manufacturer_delay_request?: Maybe<LabsGqlManufacturerDelayRequest>;
  manufacturer_shipping_time?: Maybe<Scalars['DateTime']['output']>;
  manufacturer_sla_cutoff_time?: Maybe<Scalars['DateTime']['output']>;
  manufacturer_sla_cutoff_timezone?: Maybe<Scalars['String']['output']>;
  max_committed_sla_days?: Maybe<Scalars['Int']['output']>;
  original_deadline?: Maybe<Scalars['DateTime']['output']>;
  ready_for_fabrication_date?: Maybe<Scalars['DateTime']['output']>;
  review_days: Scalars['Int']['output'];
  revision?: Maybe<Scalars['Int']['output']>;
  rush_request?: Maybe<LabsGqlRushRequest>;
  ship_by_date: Scalars['DateTime']['output'];
  /** Deprecated, should always be null */
  ship_by_date_override?: Maybe<Scalars['DateTime']['output']>;
  ship_by_deadline_at_fabrication_start?: Maybe<Scalars['DateTime']['output']>;
  shipping_days: Scalars['Int']['output'];
  /** Deprecated, should always be null */
  shipping_days_override?: Maybe<Scalars['Int']['output']>;
  stages?: Maybe<LabsGqlKeyedSlaStages>;
  start_date: Scalars['DateTime']['output'];
  version?: Maybe<Scalars['Int']['output']>;
  waxup_review_due_date?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlOrderScanApprovedEventData = {
  __typename?: 'OrderScanApprovedEventData';
  notes?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlOrderScanReviewRejectionInput = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  category: Scalars['String']['input'];
  file_urls?: InputMaybe<Array<Scalars['String']['input']>>;
  internal_notes?: InputMaybe<Scalars['String']['input']>;
  metafields?: InputMaybe<Array<LabsGqlDesignMetafieldSubmissionInput>>;
  notes_for_doctor?: InputMaybe<Scalars['String']['input']>;
  /** A rating of how bad the reason for rejection is */
  severity_score?: InputMaybe<Scalars['Float']['input']>;
};

export type LabsGqlOrderScanReviewRequestedEventData = {
  __typename?: 'OrderScanReviewRequestedEventData';
  notes?: Maybe<Scalars['String']['output']>;
  reasons: Array<Scalars['String']['output']>;
  triggering_automation_name?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlOrderSearchPracticeSearchInput = {
  doctor_preferences_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  product_lines?: InputMaybe<Array<LabsGqlProductLine>>;
  sort_asc?: InputMaybe<Scalars['Boolean']['input']>;
  sort_by?: InputMaybe<LabsGqlOrderSearchPracticeSortField>;
  status?: InputMaybe<LabsGqlPracticeOrderStatus>;
};

export type LabsGqlOrderSearchPracticeSearchOrderIdsResult = {
  __typename?: 'OrderSearchPracticeSearchOrderIdsResult';
  id: Scalars['String']['output'];
};

export enum LabsGqlOrderSearchPracticeSortField {
  CreatedAt = 'created_at',
  DueDate = 'due_date',
  PatientFirstName = 'patient_first_name',
  PatientLastName = 'patient_last_name',
  Relevancy = 'relevancy',
  UpdatedAt = 'updated_at'
}

export type LabsGqlOrderShipmentDto = {
  __typename?: 'OrderShipmentDTO';
  /** When the order and shipment were linked */
  createdAt: Scalars['DateTime']['output'];
  /** The orderId associated with the shipment */
  orderId: Scalars['String']['output'];
  /** The shipment associated with the order */
  shipment: LabsGqlShipmentDto;
};

export type LabsGqlOrderSlaChangeEntry = {
  __typename?: 'OrderSlaChangeEntry';
  event: LabsGqlSlaChangedEvent;
  sla: LabsGqlOrderSla;
  updates: Array<LabsGqlSlaUpdateDetails>;
};

export type LabsGqlOrderSlaForLabSlip = {
  __typename?: 'OrderSlaForLabSlip';
  manufacturer_committed_due_date: Scalars['DateTime']['output'];
  manufacturer_committed_ship_by_date: Scalars['DateTime']['output'];
  ready_for_fabrication_date?: Maybe<Scalars['DateTime']['output']>;
  rush_request?: Maybe<LabsGqlRushRequest>;
  ship_by_date: Scalars['DateTime']['output'];
};

export type LabsGqlOrderSlaModificationReason = {
  category_id: Scalars['String']['input'];
  further_info?: InputMaybe<Scalars['String']['input']>;
  subcategory_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlOrderSlaModificationReasonDto = {
  __typename?: 'OrderSlaModificationReasonDTO';
  category_id: Scalars['String']['output'];
  further_info?: Maybe<Scalars['String']['output']>;
  subcategory_id?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlOrderSlaModificationType {
  FreezeCommitments = 'FreezeCommitments',
  InternalDelay = 'InternalDelay',
  LegacyDelay = 'LegacyDelay',
  ManufacturerDelay = 'ManufacturerDelay',
  PracticeReconfiguration = 'PracticeReconfiguration',
  Reconfiguration = 'Reconfiguration'
}

export type LabsGqlOrderSplitCountUpdatedEventData = {
  __typename?: 'OrderSplitCountUpdatedEventData';
  new_split_count: Scalars['Int']['output'];
};

export enum LabsGqlOrderTimelineEventNameEnum {
  AlignerFabricationApproved = 'AlignerFabricationApproved',
  AskedDrAboutOrderHold = 'AskedDrAboutOrderHold',
  DeliveryAddressChanged = 'DeliveryAddressChanged',
  DesignDownloadRecorded = 'DesignDownloadRecorded',
  DesignFilesAttached = 'DesignFilesAttached',
  DueDateChanged = 'DueDateChanged',
  ExternalAlignerFulfillmentCreated = 'ExternalAlignerFulfillmentCreated',
  FulfillmentCreated = 'FulfillmentCreated',
  FulfillmentDelivered = 'FulfillmentDelivered',
  FulfillmentShipped = 'FulfillmentShipped',
  FulfillmentShippedToDandy = 'FulfillmentShippedToDandy',
  InjectionMoldDesignTaskCompleted = 'InjectionMoldDesignTaskCompleted',
  IntakeCompleted = 'IntakeCompleted',
  InternalDesignTaskCompleted = 'InternalDesignTaskCompleted',
  LabOrderPhotosAdded = 'LabOrderPhotosAdded',
  LabOrderPhotosAddedWithNotes = 'LabOrderPhotosAddedWithNotes',
  LabOrderQcStationFailed = 'LabOrderQcStationFailed',
  LabOrderQcStationPassed = 'LabOrderQcStationPassed',
  LabSlipAlertsSaved = 'LabSlipAlertsSaved',
  ManufacturerAssignmentChanged = 'ManufacturerAssignmentChanged',
  ManufacturerDelayRequestAccepted = 'ManufacturerDelayRequestAccepted',
  ManufacturerDelayRequestCancelled = 'ManufacturerDelayRequestCancelled',
  ManufacturerDelayRequestEditedAndAccepted = 'ManufacturerDelayRequestEditedAndAccepted',
  ManufacturerDelayRequested = 'ManufacturerDelayRequested',
  ModelDesignTaskCompleted = 'ModelDesignTaskCompleted',
  NeedsRefabrication = 'NeedsRefabrication',
  NewScanAdded = 'NewScanAdded',
  OrderCancelled = 'OrderCancelled',
  OrderClinicalSupportNotesUpdated = 'OrderClinicalSupportNotesUpdated',
  OrderCreated = 'OrderCreated',
  OrderDesignPrepTaskCompleted = 'OrderDesignPrepTaskCompleted',
  OrderDesignReviewConfigurationSet = 'OrderDesignReviewConfigurationSet',
  OrderDesignReviewConfigurationSetV2 = 'OrderDesignReviewConfigurationSetV2',
  OrderDesignReviewTaskCompleted = 'OrderDesignReviewTaskCompleted',
  OrderDesignerTypeConfigured = 'OrderDesignerTypeConfigured',
  OrderDoctorNoteEdited = 'OrderDoctorNoteEdited',
  OrderExternalDesignVerificationCompleted = 'OrderExternalDesignVerificationCompleted',
  OrderFlaggedForReview = 'OrderFlaggedForReview',
  OrderHoldCreated = 'OrderHoldCreated',
  OrderHoldRemoved = 'OrderHoldRemoved',
  OrderHoldUpdated = 'OrderHoldUpdated',
  OrderItemAdded = 'OrderItemAdded',
  OrderItemDeleted = 'OrderItemDeleted',
  OrderItemUpdated = 'OrderItemUpdated',
  OrderItemsAddedV2 = 'OrderItemsAddedV2',
  OrderItemsUpdated = 'OrderItemsUpdated',
  OrderItemsUpdatedV2 = 'OrderItemsUpdatedV2',
  OrderNotificationLog = 'OrderNotificationLog',
  OrderRefabricationEdited = 'OrderRefabricationEdited',
  OrderRefabricationUnlinked = 'OrderRefabricationUnlinked',
  OrderReturnCancelled = 'OrderReturnCancelled',
  OrderReturnCreated = 'OrderReturnCreated',
  OrderReturnReceived = 'OrderReturnReceived',
  OrderReviewFlagResolved = 'OrderReviewFlagResolved',
  OrderSlaEdited = 'OrderSLAEdited',
  OrderSlaStartDateChanged = 'OrderSLAStartDateChanged',
  OrderScanApproved = 'OrderScanApproved',
  OrderScanRejected = 'OrderScanRejected',
  OrderScanRejectionResolved = 'OrderScanRejectionResolved',
  OrderScanReviewRequested = 'OrderScanReviewRequested',
  OrderSentToFloor = 'OrderSentToFloor',
  OrderSlaDelayed = 'OrderSlaDelayed',
  OrderSlaModified = 'OrderSlaModified',
  OrderWaxupConfigured = 'OrderWaxupConfigured',
  OrderWaxupReviewed = 'OrderWaxupReviewed',
  PackingSheetViewed = 'PackingSheetViewed',
  PartialsFulfillmentTryInReturnInTransit = 'PartialsFulfillmentTryInReturnInTransit',
  PartialsFulfillmentTryInReturnReceived = 'PartialsFulfillmentTryInReturnReceived',
  PartialsFulfillmentTryInReturnedForFinish = 'PartialsFulfillmentTryInReturnedForFinish',
  PartialsFulfillmentTryInReturnedForTryIn = 'PartialsFulfillmentTryInReturnedForTryIn',
  PartialsFulfillmentWaxRimReturnInTransit = 'PartialsFulfillmentWaxRimReturnInTransit',
  PartialsFulfillmentWaxRimReturnReceived = 'PartialsFulfillmentWaxRimReturnReceived',
  PartialsFulfillmentWaxRimReturnedForFinish = 'PartialsFulfillmentWaxRimReturnedForFinish',
  PartialsFulfillmentWaxRimReturnedForTryIn = 'PartialsFulfillmentWaxRimReturnedForTryIn',
  PracticeRespondedToOrderHold = 'PracticeRespondedToOrderHold',
  PracticeRespondedToRejectedScan = 'PracticeRespondedToRejectedScan',
  /** @deprecated Event no longer included in timeline */
  PremiumFlagToggled = 'PremiumFlagToggled',
  PrintableLabSlipViewed = 'PrintableLabSlipViewed',
  PrintablePanLabelViewed = 'PrintablePanLabelViewed',
  QcLiteSkipQc = 'QCLiteSkipQC',
  QcPhotosAttached = 'QcPhotosAttached',
  RejectedScanReplaced = 'RejectedScanReplaced',
  RetainerAddedToAlignerCase = 'RetainerAddedToAlignerCase',
  ReviewSubmissionItemTagsUpdated = 'ReviewSubmissionItemTagsUpdated',
  RushRequestAccepted = 'RushRequestAccepted',
  RushRequestCancelled = 'RushRequestCancelled',
  RushRequestRejected = 'RushRequestRejected',
  RushRequested = 'RushRequested',
  /** @deprecated Event no longer included in timeline */
  ScanIssuesReported = 'ScanIssuesReported',
  ScanRejectionSharedWithPractice = 'ScanRejectionSharedWithPractice',
  ShipmentRemoved = 'ShipmentRemoved',
  ShippingEstimateUpdated = 'ShippingEstimateUpdated',
  /** @deprecated Event no longer included in timeline */
  ShippingInfoUpdated = 'ShippingInfoUpdated',
  ShippingStatusChanged = 'ShippingStatusChanged',
  TaggedEntity = 'TaggedEntity',
  TreatmentPlanRejected = 'TreatmentPlanRejected',
  TreatmentPlanRequestFinalized = 'TreatmentPlanRequestFinalized',
  UntaggedEntity = 'UntaggedEntity'
}

export type LabsGqlOrderTimelineItem = LabsGqlITimelineItem & {
  __typename?: 'OrderTimelineItem';
  actor: LabsGqlTimelineItemActor;
  /** @deprecated use attachments instead */
  attachment_urls: Array<Scalars['String']['output']>;
  attachments: Array<LabsGqlTimelineAttachment>;
  date: Scalars['DateTime']['output'];
  eventName: LabsGqlOrderTimelineEventNameEnum;
  notification_log_id?: Maybe<Scalars['String']['output']>;
  subtitle: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  title_link?: Maybe<LabsGqlTimelineItemLink>;
};

export type LabsGqlOrderTimelineV2Actor = {
  __typename?: 'OrderTimelineV2Actor';
  initial?: Maybe<Scalars['String']['output']>;
  isInternal: Scalars['Boolean']['output'];
};

export type LabsGqlOrderTimelineV2Attachment = {
  __typename?: 'OrderTimelineV2Attachment';
  downloadUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum LabsGqlOrderTimelineV2EventEnum {
  AlignerFabricationApproved = 'AlignerFabricationApproved',
  AskedDrAboutOrderHold = 'AskedDrAboutOrderHold',
  ChatMessageCreated = 'ChatMessageCreated',
  DeliveryAddressChanged = 'DeliveryAddressChanged',
  DueDateChanged = 'DueDateChanged',
  FulfillmentCreated = 'FulfillmentCreated',
  FulfillmentDelivered = 'FulfillmentDelivered',
  FulfillmentShipped = 'FulfillmentShipped',
  LabOrderPhotosAdded = 'LabOrderPhotosAdded',
  LabOrderPhotosAddedWithNotes = 'LabOrderPhotosAddedWithNotes',
  NeedsRefabrication = 'NeedsRefabrication',
  NewScanAdded = 'NewScanAdded',
  OrderCancelled = 'OrderCancelled',
  OrderCreated = 'OrderCreated',
  OrderDesignReviewTaskCompleted = 'OrderDesignReviewTaskCompleted',
  OrderDesignerTypeConfigured = 'OrderDesignerTypeConfigured',
  OrderExternalDesignVerificationCompleted = 'OrderExternalDesignVerificationCompleted',
  OrderHoldCreated = 'OrderHoldCreated',
  OrderHoldRemoved = 'OrderHoldRemoved',
  OrderItemAdded = 'OrderItemAdded',
  OrderItemDeleted = 'OrderItemDeleted',
  OrderItemUpdated = 'OrderItemUpdated',
  OrderItemsAddedV2 = 'OrderItemsAddedV2',
  OrderItemsUpdated = 'OrderItemsUpdated',
  OrderItemsUpdatedV2 = 'OrderItemsUpdatedV2',
  OrderReturnCancelled = 'OrderReturnCancelled',
  OrderReturnCreated = 'OrderReturnCreated',
  OrderReturnLabelScanned = 'OrderReturnLabelScanned',
  OrderReturnReceived = 'OrderReturnReceived',
  OrderWaxupReviewed = 'OrderWaxupReviewed',
  PartialsFulfillmentTryInReturnInTransit = 'PartialsFulfillmentTryInReturnInTransit',
  PartialsFulfillmentTryInReturnReceived = 'PartialsFulfillmentTryInReturnReceived',
  PartialsFulfillmentTryInReturnedForFinish = 'PartialsFulfillmentTryInReturnedForFinish',
  PartialsFulfillmentTryInReturnedForTryIn = 'PartialsFulfillmentTryInReturnedForTryIn',
  PartialsFulfillmentWaxRimReturnInTransit = 'PartialsFulfillmentWaxRimReturnInTransit',
  PartialsFulfillmentWaxRimReturnReceived = 'PartialsFulfillmentWaxRimReturnReceived',
  PartialsFulfillmentWaxRimReturnedForFinish = 'PartialsFulfillmentWaxRimReturnedForFinish',
  PartialsFulfillmentWaxRimReturnedForTryIn = 'PartialsFulfillmentWaxRimReturnedForTryIn',
  PracticeRespondedToOrderHold = 'PracticeRespondedToOrderHold',
  PracticeRespondedToRejectedScan = 'PracticeRespondedToRejectedScan',
  RetainerAddedToAlignerCase = 'RetainerAddedToAlignerCase',
  RushRequestAccepted = 'RushRequestAccepted',
  RushRequestCancelled = 'RushRequestCancelled',
  RushRequestRejected = 'RushRequestRejected',
  RushRequested = 'RushRequested',
  ScanRejectionSharedWithPractice = 'ScanRejectionSharedWithPractice',
  TreatmentPlanRejected = 'TreatmentPlanRejected',
  TreatmentPlanRequestFinalized = 'TreatmentPlanRequestFinalized'
}

export enum LabsGqlOrderTimelineV2EventIconEnum {
  Add = 'add',
  ArrowForward = 'arrowForward',
  Cancel = 'cancel',
  CheckGray = 'checkGray',
  CheckPrimary = 'checkPrimary',
  Dangerous = 'dangerous',
  Pencil = 'pencil',
  PriorityHigh = 'priorityHigh',
  Refab = 'refab',
  X = 'x'
}

export enum LabsGqlOrderTimelineV2EventVariantEnum {
  Modification = 'modification',
  Plain = 'plain',
  Problem = 'problem',
  Success = 'success'
}

export type LabsGqlOrderTimelineV2Item = {
  __typename?: 'OrderTimelineV2Item';
  actor: LabsGqlOrderTimelineV2Actor;
  attachments?: Maybe<Array<LabsGqlOrderTimelineV2Attachment>>;
  date: Scalars['DateTime']['output'];
  details?: Maybe<Scalars['String']['output']>;
  icon: LabsGqlOrderTimelineV2EventIconEnum;
  linked_order_id?: Maybe<Scalars['String']['output']>;
  linked_order_text?: Maybe<Scalars['String']['output']>;
  name: LabsGqlOrderTimelineV2EventEnum;
  order_id: Scalars['String']['output'];
  showAvatar?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  variant: LabsGqlOrderTimelineV2EventVariantEnum;
};

export type LabsGqlOrderTrackerStepDefinition = {
  __typename?: 'OrderTrackerStepDefinition';
  active: Scalars['Boolean']['output'];
  alert?: Maybe<Scalars['Boolean']['output']>;
  infoTooltip?: Maybe<Array<Scalars['String']['output']>>;
  style?: Maybe<LabsGqlOrderTrackerStepDefinitionStyle>;
  subtitle?: Maybe<Scalars['String']['output']>;
  subtitleAttachment?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export enum LabsGqlOrderTrackerStepDefinitionStyle {
  Attention = 'ATTENTION',
  Cancelled = 'CANCELLED',
  Warning = 'WARNING'
}

export type LabsGqlOrderWaxupReviewHistory = {
  __typename?: 'OrderWaxupReviewHistory';
  approval?: Maybe<LabsGqlOrderWaxupReviewSubmission>;
  rejections: Array<LabsGqlOrderWaxupReviewRejection>;
};

export type LabsGqlOrderWaxupReviewRejection = {
  __typename?: 'OrderWaxupReviewRejection';
  annotations: Array<LabsGqlOrderWaxupReviewRejectionAnnotation>;
  created_at: Scalars['DateTime']['output'];
  /** @deprecated Please use `design_file_path`, and fetch the full design via the DesignOrderRevision system. */
  design?: Maybe<LabsGqlOrderDesign>;
  design_file_path?: Maybe<Scalars['String']['output']>;
  notes: Scalars['String']['output'];
  presets?: Maybe<Array<LabsGqlDesignViewPreset>>;
  review_event_id: Scalars['Float']['output'];
};

export type LabsGqlOrderWaxupReviewRejectionAnnotation = {
  __typename?: 'OrderWaxupReviewRejectionAnnotation';
  comment: Scalars['String']['output'];
  image_url: Scalars['String']['output'];
};

export type LabsGqlOrderWaxupReviewSubmission = {
  __typename?: 'OrderWaxupReviewSubmission';
  created_at: Scalars['DateTime']['output'];
  /** @deprecated Please use `design_file_path`, and fetch the full design via the DesignOrderRevision system. */
  design?: Maybe<LabsGqlOrderDesign>;
  design_file_path?: Maybe<Scalars['String']['output']>;
  review_event_id: Scalars['Float']['output'];
};

export type LabsGqlOrderingPausedPracticeDto = {
  __typename?: 'OrderingPausedPracticeDTO';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  is_archived: Scalars['Boolean']['output'];
  reasons: Array<LabsGqlOrderingPausedPracticeReason>;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlOrderingPausedPracticeReason {
  TooManyUnpaidInvoices = 'TOO_MANY_UNPAID_INVOICES'
}

export enum LabsGqlOrdersSortField {
  CreatedAt = 'created_at',
  DueDate = 'due_date',
  PatientName = 'patient_name',
  Status = 'status',
  UpdatedAt = 'updated_at'
}

export type LabsGqlOrgAggregateSpendForPeriodDto = {
  __typename?: 'OrgAggregateSpendForPeriodDTO';
  aggregate_spend_cents: Scalars['Int']['output'];
  organization_id: Scalars['String']['output'];
};

export type LabsGqlOrganizationDto = {
  __typename?: 'OrganizationDTO';
  created_at: Scalars['DateTime']['output'];
  external_accounts: Array<LabsGqlExternalAccountRelationshipDto>;
  id: Scalars['ID']['output'];
  /** Whether this organization is "fake", meaning that is actually a testing Dandy organization. Only currently used for practices. */
  is_fake_organization: Scalars['Boolean']['output'];
  lab_notification_preferences?: Maybe<LabsGqlManufacturerNotificationPreferences>;
  lab_profile?: Maybe<LabsGqlManufacturerProfile>;
  legal_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent_id?: Maybe<Scalars['ID']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  primary_contact?: Maybe<LabsGqlUserDto>;
  staff: Array<LabsGqlStaffMemberDto>;
  type: LabsGqlOrganizationType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlOrganizationExternalAccountUpdate = {
  id?: InputMaybe<Scalars['String']['input']>;
  type: LabsGqlExternalAccountRelationshipType;
};

export type LabsGqlOrganizationNeedsSummaryEmailDto = {
  __typename?: 'OrganizationNeedsSummaryEmailDTO';
  needsSummaryEmail: Scalars['Boolean']['output'];
  organizationId: Scalars['ID']['output'];
};

/** A price for an item as set by a specific lab or practice. A given organization can only have one price per price id. */
export type LabsGqlOrganizationPriceConfigDto = {
  __typename?: 'OrganizationPriceConfigDTO';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the preset that was used to generate this price, if it exists */
  from_preset?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The ID of the organization this price applies to. */
  organization_id: Scalars['String']['output'];
  /** Specifies whether this is a lab or a practice */
  organization_type: LabsGqlOrganizationTypeForPricing;
  /** The price in cents for the given item for the specified organization */
  price_cents: Scalars['Int']['output'];
  /** The price ID of the item being priced. */
  price_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlOrganizationSummary = {
  __typename?: 'OrganizationSummary';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: LabsGqlOrganizationType;
};

export type LabsGqlOrganizationSummaryDto = {
  __typename?: 'OrganizationSummaryDTO';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  salesforceId?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlOrganizationType {
  External = 'external',
  Internal = 'internal',
  Lab = 'lab',
  Parent = 'parent',
  Practice = 'practice'
}

export enum LabsGqlOrganizationTypeForPricing {
  Lab = 'lab',
  Practice = 'practice'
}

export type LabsGqlOrganizationWithAncestors = {
  __typename?: 'OrganizationWithAncestors';
  ancestors: Array<LabsGqlOrganizationDto>;
  created_at: Scalars['DateTime']['output'];
  external_accounts: Array<LabsGqlExternalAccountRelationshipDto>;
  id: Scalars['ID']['output'];
  /** Whether this organization is "fake", meaning that is actually a testing Dandy organization. Only currently used for practices. */
  is_fake_organization: Scalars['Boolean']['output'];
  legal_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent_id?: Maybe<Scalars['ID']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  staff: Array<LabsGqlStaffMemberDto>;
  type: LabsGqlOrganizationType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlOrganizationWithAncestorsSummary = {
  __typename?: 'OrganizationWithAncestorsSummary';
  ancestors: Array<LabsGqlOrganizationSummary>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: LabsGqlOrganizationType;
};

export type LabsGqlOrganizationWithDescendants = {
  __typename?: 'OrganizationWithDescendants';
  created_at: Scalars['DateTime']['output'];
  descendants: Array<LabsGqlOrganizationDto>;
  external_accounts: Array<LabsGqlExternalAccountRelationshipDto>;
  id: Scalars['ID']['output'];
  /** Whether this organization is "fake", meaning that is actually a testing Dandy organization. Only currently used for practices. */
  is_fake_organization: Scalars['Boolean']['output'];
  legal_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent_id?: Maybe<Scalars['ID']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  staff: Array<LabsGqlStaffMemberDto>;
  type: LabsGqlOrganizationType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlOrganizationWithDescendantsSummary = {
  __typename?: 'OrganizationWithDescendantsSummary';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  practices_count: Scalars['Float']['output'];
  type: LabsGqlOrganizationType;
};

export type LabsGqlOrganizationWithHierarchySummary = {
  __typename?: 'OrganizationWithHierarchySummary';
  ancestors: Array<LabsGqlOrganizationSummary>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  practices_count: Scalars['Float']['output'];
  type: LabsGqlOrganizationType;
};

export type LabsGqlOrganizationWithOptionalHierarchy = {
  __typename?: 'OrganizationWithOptionalHierarchy';
  ancestors?: Maybe<Array<LabsGqlOrganizationDto>>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  practices_count?: Maybe<Scalars['Float']['output']>;
  type: LabsGqlOrganizationType;
};

export type LabsGqlOriginalPrefsInput = {
  communication_email?: InputMaybe<Scalars['Boolean']['input']>;
  communication_sms?: InputMaybe<Scalars['Boolean']['input']>;
  order_tracking_email?: InputMaybe<Scalars['Boolean']['input']>;
  order_tracking_sms?: InputMaybe<Scalars['Boolean']['input']>;
  review_required_email?: InputMaybe<Scalars['Boolean']['input']>;
  review_required_sms?: InputMaybe<Scalars['Boolean']['input']>;
  status_change_email?: InputMaybe<Scalars['Boolean']['input']>;
  status_change_sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlOtherItem = LabsGqlIOrderItemBase & {
  __typename?: 'OtherItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlOrderItemUnitMetadata;
};

export type LabsGqlOtherItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlOrderItemUnitMetadataInput;
};

export type LabsGqlOtherProductDetails = LabsGqlIProductDetailsBase & {
  __typename?: 'OtherProductDetails';
  catalogId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  type: LabsGqlProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlOutstandingTreatmentPlanRequest = LabsGqlITreatmentPlanRequest & {
  __typename?: 'OutstandingTreatmentPlanRequest';
  created_at: Scalars['DateTime']['output'];
  expected_completion_date: Scalars['DateTime']['output'];
  /** ID in external providers system */
  id: Scalars['String']['output'];
  provider: LabsGqlTreatmentPlanProvider;
};

export type LabsGqlOverrideDentistAmountDueCommand = {
  items_prices: Array<LabsGqlElementPriceHierarchyInput>;
  orderId: Scalars['String']['input'];
  override_amount_due_cents?: InputMaybe<Scalars['Float']['input']>;
  override_amount_due_reason?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlOverrideLabCaseUnitPriceCommand = {
  fulfillment_number: Scalars['Int']['input'];
  ledger_id: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
  override_description: Scalars['String']['input'];
  price_cents_override: Scalars['Int']['input'];
  price_id: Scalars['String']['input'];
  price_idx: Scalars['Int']['input'];
};

export type LabsGqlOverrideNonProdManufacturerCommand = {
  disable_notification?: InputMaybe<Scalars['Boolean']['input']>;
  disable_sla_reset?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturerId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type LabsGqlOverrideOrderPriceInput = {
  order_price_id: Scalars['String']['input'];
  override_total_price_cents: Scalars['Int']['input'];
  override_total_price_cents_reason?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlOverridePendingAlignerPatientPrice = {
  price_cents?: InputMaybe<Scalars['Int']['input']>;
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlPaginatedOrganizationsResponse = {
  __typename?: 'PaginatedOrganizationsResponse';
  organizations: Array<LabsGqlOrganizationWithOptionalHierarchy>;
  total: Scalars['Float']['output'];
};

export type LabsGqlPaginatedUsersResponse = {
  __typename?: 'PaginatedUsersResponse';
  total: Scalars['Float']['output'];
  users: Array<LabsGqlUserResponse>;
};

export type LabsGqlPagination = {
  page: Scalars['Int']['input'];
  per_page: Scalars['Int']['input'];
};

/** Contains pan code info for a single item group in an order */
export type LabsGqlPanCodeDto = {
  __typename?: 'PanCodeDTO';
  code: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPanLabelInfo = {
  batchNumber?: Maybe<Scalars['String']['output']>;
  items: Array<LabsGqlPanLabelItemInfo>;
  labOrderId: Scalars['String']['output'];
  labelTags: Array<Scalars['String']['output']>;
  labtracId?: Maybe<Scalars['String']['output']>;
  modelType?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  panCode: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  priority: LabsGqlManufacturingOrderPriority;
  /** Only the date portion is relevant.  The time portion should be noon UTC. */
  shipByDate: Scalars['DateTime']['output'];
};

export type LabsGqlPanLabelItemInfo = {
  __typename?: 'PanLabelItemInfo';
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type LabsGqlPanLabelUnion = LabsGqlCrownBridgeImplantPanLabelInfo | LabsGqlDenturesPanLabelInfo | LabsGqlNightGuardPanLabelInfo | LabsGqlPartialDenturesPanLabelInfo;

export type LabsGqlParentOrgRegistrationFields = {
  confirm_password?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  kustomer_company_id?: InputMaybe<Scalars['String']['input']>;
  last_name: Scalars['String']['input'];
  legal_business_name: Scalars['String']['input'];
  pandadoc_contract_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  salesforce_account_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlPartialDentureItem = LabsGqlIOrderItemBase & {
  __typename?: 'PartialDentureItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  /** @deprecated Use clasps */
  clasp_type?: Maybe<LabsGqlOrderItemPartialDentureClaspType>;
  clasps?: Maybe<Array<LabsGqlOrderItemPartialDentureClasp>>;
  coverage_type?: Maybe<LabsGqlOrderItemPartialDentureCoverageType>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  partial_production_metadata?: Maybe<LabsGqlOrderItemPartialDentureProductionMetadata>;
  partial_production_type?: Maybe<LabsGqlOrderItemPartialDentureProductionType>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlOrderItemMultiToothUnit;
};

export type LabsGqlPartialDentureItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  clasp_type?: InputMaybe<LabsGqlOrderItemPartialDentureClaspType>;
  clasps?: InputMaybe<Array<LabsGqlOrderItemPartialDentureClaspInput>>;
  coverage_type?: InputMaybe<LabsGqlOrderItemPartialDentureCoverageType>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  partial_production_metadata?: InputMaybe<LabsGqlOrderItemPartialDentureProductionMetadataInput>;
  partial_production_type?: InputMaybe<LabsGqlOrderItemPartialDentureProductionType>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlOrderItemMultiToothUnitInput;
};

export type LabsGqlPartialDenturesPanLabelInfo = LabsGqlPanLabelInfo & {
  __typename?: 'PartialDenturesPanLabelInfo';
  batchNumber?: Maybe<Scalars['String']['output']>;
  items: Array<LabsGqlPanLabelItemInfo>;
  labOrderId: Scalars['String']['output'];
  labelTags: Array<Scalars['String']['output']>;
  labtracId?: Maybe<Scalars['String']['output']>;
  modelType?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  panCode: Scalars['String']['output'];
  patientName: Scalars['String']['output'];
  priority: LabsGqlManufacturingOrderPriority;
  /** Only the date portion is relevant.  The time portion should be noon UTC. */
  shipByDate: Scalars['DateTime']['output'];
};

export type LabsGqlPartialPaymentInput = {
  amountCents: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
};

export type LabsGqlPartialsFulfillmentDto = {
  __typename?: 'PartialsFulfillmentDTO';
  current: LabsGqlPartialsSingleFulfillmentDto;
  history: Array<LabsGqlPartialsSingleFulfillmentDto>;
  upcoming?: Maybe<LabsGqlPartialsSingleFulfillmentDto>;
};

export type LabsGqlPartialsFulfillmentFinishDto = {
  __typename?: 'PartialsFulfillmentFinishDTO';
  type: LabsGqlPartialsSingleFulfillmentType;
};

export type LabsGqlPartialsFulfillmentReturnDto = {
  __typename?: 'PartialsFulfillmentReturnDTO';
  created_at: Scalars['DateTime']['output'];
  in_transit_at?: Maybe<Scalars['DateTime']['output']>;
  received_at?: Maybe<Scalars['DateTime']['output']>;
  shipment: LabsGqlIShipment;
  shipment_id: Scalars['String']['output'];
};

export type LabsGqlPartialsFulfillmentTryInDto = {
  __typename?: 'PartialsFulfillmentTryInDTO';
  feedback?: Maybe<LabsGqlPartialsTryInFeedbackDto>;
  return?: Maybe<LabsGqlPartialsFulfillmentReturnDto>;
  type: LabsGqlPartialsSingleFulfillmentType;
};

export type LabsGqlPartialsFulfillmentWaxRimDto = {
  __typename?: 'PartialsFulfillmentWaxRimDTO';
  feedback?: Maybe<LabsGqlPartialsWaxRimFeedbackDto>;
  return?: Maybe<LabsGqlPartialsFulfillmentReturnDto>;
  type: LabsGqlPartialsSingleFulfillmentType;
};

export type LabsGqlPartialsSingleFulfillmentDto = LabsGqlPartialsFulfillmentFinishDto | LabsGqlPartialsFulfillmentTryInDto | LabsGqlPartialsFulfillmentWaxRimDto;

export enum LabsGqlPartialsSingleFulfillmentType {
  Finish = 'Finish',
  TryIn = 'TryIn',
  WaxRim = 'WaxRim'
}

export type LabsGqlPartialsTryInFeedbackDto = {
  __typename?: 'PartialsTryInFeedbackDTO';
  issues: Array<LabsGqlPartialsTryInFeedbackIssueDto>;
  type: LabsGqlPartialsTryInFeedbackType;
};

export type LabsGqlPartialsTryInFeedbackInput = {
  issues: Array<LabsGqlPartialsTryInFeedbackIssueInput>;
  type: LabsGqlPartialsTryInFeedbackType;
};

export type LabsGqlPartialsTryInFeedbackIssueDto = {
  __typename?: 'PartialsTryInFeedbackIssueDTO';
  details: Scalars['String']['output'];
  type: LabsGqlPartialsTryInFeedbackIssueType;
};

export type LabsGqlPartialsTryInFeedbackIssueInput = {
  details: Scalars['String']['input'];
  type: LabsGqlPartialsTryInFeedbackIssueType;
};

export enum LabsGqlPartialsTryInFeedbackIssueType {
  AestheticFitLoose = 'AestheticFitLoose',
  AestheticFitRocking = 'AestheticFitRocking',
  AestheticMaterialsClasp = 'AestheticMaterialsClasp',
  AestheticMaterialsDenture = 'AestheticMaterialsDenture',
  AestheticOther = 'AestheticOther',
  AestheticShadeIncorrect = 'AestheticShadeIncorrect',
  AestheticShadeTooDark = 'AestheticShadeTooDark',
  AestheticShadeTooLight = 'AestheticShadeTooLight',
  AestheticTeethLength = 'AestheticTeethLength',
  AestheticTeethShape = 'AestheticTeethShape',
  AestheticTeethSpacing = 'AestheticTeethSpacing',
  StructuralClaspPlacement = 'StructuralClaspPlacement',
  StructuralClaspTooLoose = 'StructuralClaspTooLoose',
  StructuralClaspTooTight = 'StructuralClaspTooTight',
  StructuralFitTooLoose = 'StructuralFitTooLoose',
  StructuralFitTooTight = 'StructuralFitTooTight',
  StructuralFramework = 'StructuralFramework',
  StructuralOcclusionOpen = 'StructuralOcclusionOpen',
  StructuralOcclusionTight = 'StructuralOcclusionTight',
  StructuralOther = 'StructuralOther',
  StructuralTeethIncorrect = 'StructuralTeethIncorrect',
  StructuralTeethMissing = 'StructuralTeethMissing'
}

export enum LabsGqlPartialsTryInFeedbackType {
  Aesthetic = 'Aesthetic',
  AestheticAndStructural = 'AestheticAndStructural',
  Broken = 'Broken',
  Perfect = 'Perfect',
  Structural = 'Structural'
}

export type LabsGqlPartialsWaxRimFeedbackDto = {
  __typename?: 'PartialsWaxRimFeedbackDTO';
  notes: Scalars['String']['output'];
};

export type LabsGqlPartialsWaxRimFeedbackInput = {
  notes: Scalars['String']['input'];
};

export enum LabsGqlPartnerActivationSource {
  Order = 'order',
  Training = 'training',
  Unknown = 'unknown'
}

export type LabsGqlPartnerBillingAutochargeChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerBillingAutochargeChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value: Scalars['Boolean']['output'];
  old_value: Scalars['Boolean']['output'];
};

export enum LabsGqlPartnerBillingBoolean {
  AutochargeEnabled = 'autocharge_enabled',
  IsLiableForEquipment = 'is_liable_for_equipment',
  IsOnPaymentPlan = 'is_on_payment_plan',
  IsTaxExempt = 'is_tax_exempt'
}

export type LabsGqlPartnerBillingBulkUpdateFlagCommand = {
  new_value: Scalars['Boolean']['input'];
  partner_ids: Array<Scalars['String']['input']>;
  property: LabsGqlPartnerBillingBoolean;
};

export type LabsGqlPartnerBillingChangelogEntry = LabsGqlAgreeToCreditCardFeesChangelogEntry | LabsGqlBillingUsageSplitChangelogEntry | LabsGqlPartnerBillingAutochargeChangelogEntry | LabsGqlPartnerBillingContactsChangelogEntry | LabsGqlPartnerBillingLiableForEquipmentChangelogEntry | LabsGqlPartnerBillingPrepayExpiresChangelogEntry | LabsGqlPartnerBillingPrepayOverrideChangelogEntry | LabsGqlPartnerBillingPrimaryBillingContactUserIdChangeLogEntry | LabsGqlPartnerBillingPrimaryBillingContactUserTypeChangeLogEntry | LabsGqlPartnerBillingTaxExemptChangelogEntry | LabsGqlPartnerBillingWillBeChargedCcFeeChangelogEntry | LabsGqlPartnerStripeLinkingChangelogEntry;

export type LabsGqlPartnerBillingChangelogEntryBase = {
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
};

export type LabsGqlPartnerBillingContactsChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerBillingContactsChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value: Array<Scalars['String']['output']>;
  old_value: Array<Scalars['String']['output']>;
};

export type LabsGqlPartnerBillingCurrentState = {
  __typename?: 'PartnerBillingCurrentState';
  account_management_contact_emails: Array<Scalars['String']['output']>;
  activation_date?: Maybe<Scalars['DateTime']['output']>;
  activation_source?: Maybe<LabsGqlPartnerActivationSource>;
  /** @deprecated Use practice contracts */
  active_minimum_commitment_cents: Scalars['Int']['output'];
  agreed_to_cc_fees: Scalars['Boolean']['output'];
  /** Whether or not we should attempt to autocharge usage invoices */
  autocharge_enabled: Scalars['Boolean']['output'];
  contact_emails: Array<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** This is partner id to ensure uniqueness */
  id: Scalars['ID']['output'];
  /** @deprecated Use the decoupled invoice data */
  invoices: Array<LabsGqlInvoice>;
  is_liable_for_equipment: Scalars['Boolean']['output'];
  is_on_payment_plan: Scalars['Boolean']['output'];
  is_tax_exempt: Scalars['Boolean']['output'];
  /** @deprecated Use practice contracts */
  monthly_prepay_cents: Scalars['Int']['output'];
  /** @deprecated Use nextInvoiceStatus or listPendingInvoiceItems */
  pending_orders_count: Scalars['Int']['output'];
  /** @deprecated Use nextInvoiceStatus or listPendingInvoiceItems */
  pending_total_cents: Scalars['Int']['output'];
  primary_billing_contact_user_id: Scalars['String']['output'];
  primary_billing_contact_user_type: LabsGqlBillingContactUserType;
  stripe_customer_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  usage_payment_source_config?: Maybe<LabsGqlPartnerPaymentSplitConfig>;
  will_be_charged_cc_fee: Scalars['Boolean']['output'];
};

export type LabsGqlPartnerBillingLiableForEquipmentChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerBillingLiableForEquipmentChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value: Scalars['Boolean']['output'];
  old_value: Scalars['Boolean']['output'];
};

export type LabsGqlPartnerBillingPrepayExpiresChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerBillingPrepayExpiresChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value: Scalars['Boolean']['output'];
  old_value: Scalars['Boolean']['output'];
};

export type LabsGqlPartnerBillingPrepayOverrideChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerBillingPrepayOverrideChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value?: Maybe<Scalars['String']['output']>;
  old_value?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPartnerBillingPrimaryBillingContactUserIdChangeLogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerBillingPrimaryBillingContactUserIdChangeLogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value: Scalars['String']['output'];
  old_value: Scalars['String']['output'];
};

export type LabsGqlPartnerBillingPrimaryBillingContactUserTypeChangeLogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerBillingPrimaryBillingContactUserTypeChangeLogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value?: Maybe<Scalars['String']['output']>;
  old_value?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPartnerBillingTaxExemptChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerBillingTaxExemptChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value: Scalars['Boolean']['output'];
  old_value: Scalars['Boolean']['output'];
};

export type LabsGqlPartnerBillingWillBeChargedCcFeeChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerBillingWillBeChargedCCFeeChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value: Scalars['Boolean']['output'];
  old_value: Scalars['Boolean']['output'];
};

export type LabsGqlPartnerDeliveryAddress = {
  __typename?: 'PartnerDeliveryAddress';
  /** @deprecated Deprecated. Use canDeleteDeliveryAddress instead. */
  can_delete: Scalars['Boolean']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  /** Unique integer ID */
  idNumber: Scalars['Int']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  partner_id: Scalars['String']['output'];
  postal_code: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street_one: Scalars['String']['output'];
  street_two?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPartnerDeliveryAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  partner_id: Scalars['String']['input'];
  postal_code: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street_one: Scalars['String']['input'];
  street_two?: InputMaybe<Scalars['String']['input']>;
};

export enum LabsGqlPartnerLabsNotificationTiming {
  Always = 'always',
  Evening = 'evening',
  Morning = 'morning',
  Never = 'never'
}

export type LabsGqlPartnerNotificationPreferences = {
  __typename?: 'PartnerNotificationPreferences';
  created_at: Scalars['DateTime']['output'];
  daily_digest_timing?: Maybe<LabsGqlPartnerLabsNotificationTiming>;
  /** Send SMS when a waxup is ready for review */
  design_review_alerts?: Maybe<Scalars['Boolean']['output']>;
  emails: Array<Scalars['String']['output']>;
  estimated_delivery_alerts?: Maybe<Scalars['Boolean']['output']>;
  estimated_delivery_emails?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  partner_id: Scalars['ID']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
  time_zone?: Maybe<Scalars['String']['output']>;
  unsubmitted_scan_alert_delay_mins?: Maybe<Scalars['Int']['output']>;
  unsubmitted_scans_daily_sms?: Maybe<LabsGqlPartnerLabsNotificationTiming>;
  updated_at: Scalars['DateTime']['output'];
  waxup_daily_reminder_sms_timing?: Maybe<LabsGqlPartnerLabsNotificationTiming>;
  waxup_ready_for_review_email?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlPartnerPaymentSplitConfig = LabsGqlPaymentSplitConfigDoctor | LabsGqlPaymentSplitConfigLocation | LabsGqlPaymentSplitConfigPercent;

export type LabsGqlPartnerPriceOverride = {
  __typename?: 'PartnerPriceOverride';
  from_preset?: Maybe<Scalars['String']['output']>;
  partner_id: Scalars['String']['output'];
  price_cents: Scalars['Int']['output'];
};

export type LabsGqlPartnerPriceOverrideInput = {
  from_preset?: InputMaybe<Scalars['String']['input']>;
  partner_id: Scalars['String']['input'];
  price_cents: Scalars['Int']['input'];
};

export type LabsGqlPartnerPriceResult = {
  __typename?: 'PartnerPriceResult';
  name: Scalars['String']['output'];
  partner_prices: Array<LabsGqlPartnerPriceOverride>;
};

export type LabsGqlPartnerRegistrationFields = {
  confirm_password?: InputMaybe<Scalars['String']['input']>;
  doctor_names: Array<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  kustomer_company_id?: InputMaybe<Scalars['String']['input']>;
  labs_monthly_prepay_amt_dollars: Scalars['Float']['input'];
  last_name: Scalars['String']['input'];
  legal_business_name: Scalars['String']['input'];
  pandadoc_contract_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  prices?: InputMaybe<Array<LabsGqlPartnerRegistrationSalesPrices>>;
  salesforce_account_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlPartnerRegistrationSalesPrices = {
  priceCents: Scalars['Int']['input'];
  salesforcePriceCode: Scalars['String']['input'];
};

export type LabsGqlPartnerStripeLinkingChangelogEntry = LabsGqlPartnerBillingChangelogEntryBase & {
  __typename?: 'PartnerStripeLinkingChangelogEntry';
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_name?: Maybe<Scalars['String']['output']>;
  actor_role: Scalars['String']['output'];
  event_name: Scalars['String']['output'];
  event_timestamp: Scalars['DateTime']['output'];
  new_value: Scalars['String']['output'];
  old_value: Scalars['String']['output'];
};

export type LabsGqlPatientDto = {
  __typename?: 'PatientDTO';
  birthday: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  first_name: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last_name: Scalars['String']['output'];
  order_count: Scalars['Int']['output'];
  partner_id: Scalars['ID']['output'];
  replaced_by_patient_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlPatientDetails = {
  __typename?: 'PatientDetails';
  last_visit?: Maybe<Scalars['DateTime']['output']>;
  patient: LabsGqlPatientDto;
  scans: Array<LabsGqlPatientScan>;
};

export type LabsGqlPatientForLabSlip = {
  __typename?: 'PatientForLabSlip';
  birthday: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
};

export type LabsGqlPatientListInputArgs = {
  sort?: InputMaybe<LabsGqlPatientSortType>;
  with_replaced?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlPatientPortalLabOrder = {
  __typename?: 'PatientPortalLabOrder';
  aligner_case?: Maybe<LabsGqlAlignerCaseDto>;
  aligner_treatment_plan?: Maybe<LabsGqlDesignOrderAlignerTreatmentPlanDto>;
  doctor_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  patient_first_name: Scalars['String']['output'];
  patient_last_name: Scalars['String']['output'];
};

export type LabsGqlPatientScan = {
  __typename?: 'PatientScan';
  doctor_name: Scalars['String']['output'];
  items: Array<LabsGqlLabOrderItemV2>;
  order_id?: Maybe<Scalars['String']['output']>;
  pill_content: LabsGqlPatientScanPillContent;
  product_unit_type: Scalars['String']['output'];
  scan_export_id: Scalars['String']['output'];
  scan_type: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlPatientScanPillContent = {
  __typename?: 'PatientScanPillContent';
  icon: LabsGqlPatientScanPillContentIcon;
  title: Scalars['String']['output'];
  variant: LabsGqlPatientScanPillContentVariant;
};

export enum LabsGqlPatientScanPillContentIcon {
  Archived = 'archived',
  Check = 'check',
  Lab = 'lab',
  Truck = 'truck',
  Warning = 'warning'
}

export enum LabsGqlPatientScanPillContentVariant {
  Archived = 'archived',
  Primary = 'primary',
  Secondary = 'secondary',
  Warning = 'warning'
}

export type LabsGqlPatientShare = {
  __typename?: 'PatientShare';
  annotation_urls?: Maybe<Array<Scalars['String']['output']>>;
  appointment_date: Scalars['DateTime']['output'];
  appointment_title: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  organization_name?: Maybe<Scalars['String']['output']>;
  organization_phone_number?: Maybe<Scalars['String']['output']>;
  scan_export: LabsGqlScan_Export;
};

export enum LabsGqlPatientSortType {
  CreatedAt = 'CreatedAt',
  FirstNameAscending = 'FirstNameAscending',
  FirstNameDescending = 'FirstNameDescending',
  LastNameAscending = 'LastNameAscending',
  LastNameDescending = 'LastNameDescending'
}

export type LabsGqlPayContractInvoicesWithSourceInput = {
  invoiceIds: Array<Scalars['String']['input']>;
  sourceId: Scalars['String']['input'];
};

export type LabsGqlPayInvoiceWithConfigInput = {
  invoiceId: Scalars['String']['input'];
  /** Required for internal */
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlPayInvoiceWithManualStripePaymentInput = {
  amountCents: Scalars['Float']['input'];
  createdAt: Scalars['DateTime']['input'];
  invoiceId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
  paymentSourceId: Scalars['String']['input'];
  stripeChargeId: Scalars['String']['input'];
};

export type LabsGqlPayInvoiceWithSourceInput = {
  invoiceId: Scalars['String']['input'];
  /** Required for internal */
  organizationId?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['String']['input'];
};

export type LabsGqlPaymentRefundDto = {
  __typename?: 'PaymentRefundDTO';
  /** This differs from stripe, will always be negative for credits and positive for charges, no matter the object type */
  amount_cents: Scalars['Int']['output'];
  attribution?: Maybe<LabsGqlInvoiceAdjustmentAttributionDto>;
  created_at: Scalars['DateTime']['output'];
  created_by_user_email?: Maybe<Scalars['String']['output']>;
  created_by_user_id?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  payment_allocation_id?: Maybe<Scalars['ID']['output']>;
  payment_id: Scalars['ID']['output'];
  refund_category_id?: Maybe<Scalars['String']['output']>;
  refund_notes?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  stripe_refund_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlPaymentSplitConfigBase = {
  splits: Array<LabsGqlPaymentSplitConfigEntry>;
  type: LabsGqlPaymentSplitConfigType;
};

export type LabsGqlPaymentSplitConfigDoctor = LabsGqlPaymentSplitConfigBase & {
  __typename?: 'PaymentSplitConfigDoctor';
  splits: Array<LabsGqlPaymentSplitConfigEntryDoctor>;
  type: LabsGqlPaymentSplitConfigType;
};

export type LabsGqlPaymentSplitConfigDoctorInput = {
  splits: Array<LabsGqlPaymentSplitConfigEntryDoctorIn>;
  type?: InputMaybe<LabsGqlPaymentSplitConfigType>;
};

export type LabsGqlPaymentSplitConfigEntry = LabsGqlPaymentSplitConfigEntryDoctor | LabsGqlPaymentSplitConfigEntryLocation | LabsGqlPaymentSplitConfigEntryPercent;

export type LabsGqlPaymentSplitConfigEntryBase = {
  source_id: Scalars['String']['output'];
};

export type LabsGqlPaymentSplitConfigEntryDoctor = LabsGqlPaymentSplitConfigEntryBase & {
  __typename?: 'PaymentSplitConfigEntryDoctor';
  doctor_preferences_id: Scalars['String']['output'];
  source_id: Scalars['String']['output'];
};

export type LabsGqlPaymentSplitConfigEntryDoctorIn = {
  doctor_preferences_id: Scalars['String']['input'];
  source_id: Scalars['String']['input'];
};

export type LabsGqlPaymentSplitConfigEntryLocation = LabsGqlPaymentSplitConfigEntryBase & {
  __typename?: 'PaymentSplitConfigEntryLocation';
  delivery_address_id: Scalars['String']['output'];
  source_id: Scalars['String']['output'];
};

export type LabsGqlPaymentSplitConfigEntryLocationIn = {
  delivery_address_id: Scalars['String']['input'];
  source_id: Scalars['String']['input'];
};

export type LabsGqlPaymentSplitConfigEntryPercent = LabsGqlPaymentSplitConfigEntryBase & {
  __typename?: 'PaymentSplitConfigEntryPercent';
  decimal_percentage: Scalars['Float']['output'];
  source_id: Scalars['String']['output'];
};

export type LabsGqlPaymentSplitConfigEntryPercentIn = {
  decimal_percentage: Scalars['Float']['input'];
  source_id: Scalars['String']['input'];
};

export type LabsGqlPaymentSplitConfigLocation = LabsGqlPaymentSplitConfigBase & {
  __typename?: 'PaymentSplitConfigLocation';
  splits: Array<LabsGqlPaymentSplitConfigEntryLocation>;
  type: LabsGqlPaymentSplitConfigType;
};

export type LabsGqlPaymentSplitConfigLocationInput = {
  splits: Array<LabsGqlPaymentSplitConfigEntryLocationIn>;
  type?: InputMaybe<LabsGqlPaymentSplitConfigType>;
};

export type LabsGqlPaymentSplitConfigPercent = LabsGqlPaymentSplitConfigBase & {
  __typename?: 'PaymentSplitConfigPercent';
  splits: Array<LabsGqlPaymentSplitConfigEntryPercent>;
  type: LabsGqlPaymentSplitConfigType;
};

export type LabsGqlPaymentSplitConfigPercentInput = {
  splits: Array<LabsGqlPaymentSplitConfigEntryPercentIn>;
  type?: InputMaybe<LabsGqlPaymentSplitConfigType>;
};

export enum LabsGqlPaymentSplitConfigType {
  Doctor = 'doctor',
  Location = 'location',
  Percent = 'percent'
}

export enum LabsGqlPaymentStatus {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded'
}

export type LabsGqlPendingAlignerCaseBiteConcerns = {
  __typename?: 'PendingAlignerCaseBiteConcerns';
  improve_midline: LabsGqlBiteConcernOptions;
  improve_overbite: LabsGqlBiteConcernOptions;
  improve_overjet: LabsGqlBiteConcernOptions;
};

export type LabsGqlPendingAlignerCaseBiteConcernsInput = {
  improve_midline: LabsGqlBiteConcernOptions;
  improve_overbite: LabsGqlBiteConcernOptions;
  improve_overjet: LabsGqlBiteConcernOptions;
};

export type LabsGqlPendingAlignerCaseConcernMetadata = {
  __typename?: 'PendingAlignerCaseConcernMetadata';
  concern: Scalars['String']['output'];
  metadata: Scalars['String']['output'];
};

export type LabsGqlPendingAlignerCaseConcernMetadataInput = {
  concern: Scalars['String']['input'];
  metadata: Scalars['String']['input'];
};

export type LabsGqlPendingAlignerCaseConcerns = {
  __typename?: 'PendingAlignerCaseConcerns';
  additional_concerns: Array<Scalars['String']['output']>;
  additional_concerns_notes?: Maybe<Scalars['String']['output']>;
  concerns_metadata?: Maybe<Array<LabsGqlPendingAlignerCaseConcernMetadata>>;
  primary_concern: Scalars['String']['output'];
  primary_concern_severity: LabsGqlAlignerConcernSeverity;
};

export type LabsGqlPendingAlignerCaseConcernsInput = {
  additional_concerns: Array<Scalars['String']['input']>;
  additional_concerns_notes?: InputMaybe<Scalars['String']['input']>;
  concerns_metadata?: InputMaybe<Array<LabsGqlPendingAlignerCaseConcernMetadataInput>>;
  primary_concern: Scalars['String']['input'];
  primary_concern_severity: LabsGqlAlignerConcernSeverity;
};

export type LabsGqlPendingAlignerCaseCrowdingResolution = {
  __typename?: 'PendingAlignerCaseCrowdingResolution';
  expansion: LabsGqlCrowdingResolutionOption;
  ipr: LabsGqlCrowdingResolutionOption;
  proclination: LabsGqlCrowdingResolutionOption;
};

export type LabsGqlPendingAlignerCaseCrowdingResolutionInput = {
  expansion: LabsGqlCrowdingResolutionOption;
  ipr: LabsGqlCrowdingResolutionOption;
  proclination: LabsGqlCrowdingResolutionOption;
};

export type LabsGqlPendingAlignerCaseDto = {
  __typename?: 'PendingAlignerCaseDTO';
  aligner_arch: LabsGqlAlignerArch;
  aligner_notes?: Maybe<Scalars['String']['output']>;
  aligner_treatment_area: LabsGqlAlignerTreatmentArea;
  attachment_restricted_teeth: Array<Scalars['ToothNumber']['output']>;
  bite_concerns?: Maybe<LabsGqlPendingAlignerCaseBiteConcerns>;
  /** @deprecated Aligner v2 no longer uses concerns. Use dedicated v2 fields instead. */
  concerns?: Maybe<LabsGqlPendingAlignerCaseConcerns>;
  created_at: Scalars['DateTime']['output'];
  crowding_resolution?: Maybe<LabsGqlPendingAlignerCaseCrowdingResolution>;
  estimate?: Maybe<LabsGqlPendingAlignerCaseEstimate>;
  extraction_teeth: Array<Scalars['ToothNumber']['output']>;
  id: Scalars['ID']['output'];
  interproximal_space_sizes: Array<LabsGqlPendingAlignerCaseInterproximalSpaceSize>;
  movement_restricted_teeth: Array<Scalars['ToothNumber']['output']>;
  overridden_patient_price_in_cents?: Maybe<Scalars['Int']['output']>;
  photos: LabsGqlPendingAlignerCasePhotoMap;
  rejection?: Maybe<LabsGqlPendingAlignerCaseRejection>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlPendingAlignerCaseEstimate = {
  __typename?: 'PendingAlignerCaseEstimate';
  dandy_plan: LabsGqlAlignerPlan;
  doctor_proposed_step_limit?: Maybe<Scalars['Int']['output']>;
  /** @deprecated aligner v2 uses dandy_plan instead */
  durationInMonthsMax?: Maybe<Scalars['Int']['output']>;
  /** @deprecated aligner v2 uses dandy_plan instead */
  durationInMonthsMin?: Maybe<Scalars['Int']['output']>;
  needsAttachments: Scalars['Boolean']['output'];
  needsIpr: Scalars['Boolean']['output'];
  overridden_patient_price_in_cents?: Maybe<Scalars['Int']['output']>;
  /** @deprecated aligner v2 uses dandy_plan instead */
  priceInCents?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlPendingAlignerCaseEstimateInput = {
  dandy_plan: LabsGqlAlignerPlan;
  doctor_proposed_step_limit?: InputMaybe<Scalars['Int']['input']>;
  durationInMonthsMax?: InputMaybe<Scalars['Int']['input']>;
  durationInMonthsMin?: InputMaybe<Scalars['Int']['input']>;
  needsAttachments: Scalars['Boolean']['input'];
  needsIpr: Scalars['Boolean']['input'];
  overridden_patient_price_in_cents?: InputMaybe<Scalars['Int']['input']>;
  priceInCents?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlPendingAlignerCaseInput = {
  aligner_arch: LabsGqlAlignerArch;
  aligner_notes?: InputMaybe<Scalars['String']['input']>;
  aligner_treatment_area: LabsGqlAlignerTreatmentArea;
  attachment_restricted_teeth: Array<Scalars['ToothNumber']['input']>;
  bite_concerns?: InputMaybe<LabsGqlPendingAlignerCaseBiteConcernsInput>;
  concerns?: InputMaybe<LabsGqlPendingAlignerCaseConcernsInput>;
  created_at: Scalars['DateTime']['input'];
  crowding_resolution?: InputMaybe<LabsGqlPendingAlignerCaseCrowdingResolutionInput>;
  estimate?: InputMaybe<LabsGqlPendingAlignerCaseEstimateInput>;
  extraction_teeth: Array<Scalars['ToothNumber']['input']>;
  id: Scalars['ID']['input'];
  interproximal_space_sizes: Array<LabsGqlPendingAlignerCaseInterproximalSpaceSizeInput>;
  movement_restricted_teeth: Array<Scalars['ToothNumber']['input']>;
  overridden_patient_price_in_cents?: InputMaybe<Scalars['Int']['input']>;
  photos: LabsGqlPendingAlignerCasePhotoMapInput;
  rejection?: InputMaybe<LabsGqlPendingAlignerCaseRejectionInput>;
  updated_at: Scalars['DateTime']['input'];
};

export type LabsGqlPendingAlignerCaseInterproximalSpaceShape = {
  __typename?: 'PendingAlignerCaseInterproximalSpaceShape';
  tooth1: Scalars['Float']['output'];
  tooth2: Scalars['Float']['output'];
};

export type LabsGqlPendingAlignerCaseInterproximalSpaceShapeInput = {
  tooth1: Scalars['Float']['input'];
  tooth2: Scalars['Float']['input'];
};

export type LabsGqlPendingAlignerCaseInterproximalSpaceSize = {
  __typename?: 'PendingAlignerCaseInterproximalSpaceSize';
  size: Scalars['Float']['output'];
  space: LabsGqlPendingAlignerCaseInterproximalSpaceShape;
};

export type LabsGqlPendingAlignerCaseInterproximalSpaceSizeInput = {
  size: Scalars['Float']['input'];
  space: LabsGqlPendingAlignerCaseInterproximalSpaceShapeInput;
};

export type LabsGqlPendingAlignerCasePhotoMap = {
  __typename?: 'PendingAlignerCasePhotoMap';
  FrontBite?: Maybe<Scalars['String']['output']>;
  FrontPortraitNoSmile?: Maybe<Scalars['String']['output']>;
  FrontPortraitSmile?: Maybe<Scalars['String']['output']>;
  LeftBite?: Maybe<Scalars['String']['output']>;
  MandibularOcclusion?: Maybe<Scalars['String']['output']>;
  MaxillaryOcclusion?: Maybe<Scalars['String']['output']>;
  PanoramicXray?: Maybe<Scalars['String']['output']>;
  RightBite?: Maybe<Scalars['String']['output']>;
  SideView?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPendingAlignerCasePhotoMapInput = {
  FrontBite?: InputMaybe<Scalars['String']['input']>;
  FrontPortraitNoSmile?: InputMaybe<Scalars['String']['input']>;
  FrontPortraitSmile?: InputMaybe<Scalars['String']['input']>;
  LeftBite?: InputMaybe<Scalars['String']['input']>;
  MandibularOcclusion?: InputMaybe<Scalars['String']['input']>;
  MaxillaryOcclusion?: InputMaybe<Scalars['String']['input']>;
  PanoramicXray?: InputMaybe<Scalars['String']['input']>;
  RightBite?: InputMaybe<Scalars['String']['input']>;
  SideView?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlPendingAlignerCaseRejection = {
  __typename?: 'PendingAlignerCaseRejection';
  notes?: Maybe<Scalars['String']['output']>;
  reason: LabsGqlPendingAlignerCaseRejectionReason;
};

export type LabsGqlPendingAlignerCaseRejectionInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: LabsGqlPendingAlignerCaseRejectionReason;
};

export enum LabsGqlPendingAlignerCaseRejectionReason {
  ExtremeRootExposureGumRecession = 'EXTREME_ROOT_EXPOSURE_GUM_RECESSION',
  Other = 'OTHER',
  PrimaryTeeth = 'PRIMARY_TEETH',
  SeverePeriodontalDisease = 'SEVERE_PERIODONTAL_DISEASE',
  SignificantClassIiiMalocclusion = 'SIGNIFICANT_CLASS_III_MALOCCLUSION',
  SkeletalImbalance = 'SKELETAL_IMBALANCE'
}

export type LabsGqlPendingInvoiceItemDto = {
  __typename?: 'PendingInvoiceItemDTO';
  /** This differs from stripe, will always be negative for credits and positive for charges, no matter the object type */
  amount_cents: Scalars['Int']['output'];
  category: LabsGqlInvoiceItemCategory;
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  item_type: LabsGqlInvoiceItemStripeObject;
  order_id?: Maybe<Scalars['String']['output']>;
  recurring_item_id?: Maybe<Scalars['String']['output']>;
  sales_tax_amount_cents?: Maybe<Scalars['Int']['output']>;
  subcategory?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  /** Invoice id. Maps to either a PartnerBillingCredit or Invoice with a corresponding charge */
  used_credit_id?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPendingInvoiceItemForOrganizationDto = {
  __typename?: 'PendingInvoiceItemForOrganizationDTO';
  /** This differs from stripe, will always be negative for credits and positive for charges, no matter the object type */
  amount_cents: Scalars['Int']['output'];
  category: LabsGqlInvoiceItemCategory;
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  item_type: LabsGqlInvoiceItemStripeObject;
  order_id?: Maybe<Scalars['String']['output']>;
  organization_id: Scalars['String']['output'];
  recurring_item_id?: Maybe<Scalars['String']['output']>;
  sales_tax_amount_cents?: Maybe<Scalars['Int']['output']>;
  subcategory?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  /** Invoice id. Maps to either a PartnerBillingCredit or Invoice with a corresponding charge */
  used_credit_id?: Maybe<Scalars['String']['output']>;
};

/** Data necessary for uploading image files to a cloud location */
export type LabsGqlPerfectShadeAppLaunch = {
  __typename?: 'PerfectShadeAppLaunch';
  /** The shade suggestion. */
  appLaunchUrl: Scalars['String']['output'];
};

/** A photo upload. */
export type LabsGqlPhotoUploadDto = {
  __typename?: 'PhotoUploadDto';
  /** The camera settings of the photo upload. */
  cameraSettings: Array<LabsGqlCameraSettingsDto>;
  /** The date and time the photo upload was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The id of the photo upload. */
  id: Scalars['String']['output'];
  /** The type of the photo upload. */
  photoType: Scalars['String']['output'];
  /** The processed image url of the photo upload. */
  processedImageUrl: Scalars['String']['output'];
  /** The raw image url of the photo upload. */
  rawImageUrl: Scalars['String']['output'];
  /** The id of the shade capture. */
  shadeCaptureId: Scalars['String']['output'];
  /** The tooth coordinates of the photo upload. */
  toothCoordinates: Array<LabsGqlToothCoordinateDto>;
  /** The unns of the photo upload. */
  unns: Array<Scalars['Float']['output']>;
};

export type LabsGqlPlaceAlignerOrderInfo = {
  doctor_proposed_step_limit?: InputMaybe<Scalars['Int']['input']>;
};

/** DEPRECATED: Use PlaceLabOrder instead. */
export type LabsGqlPlaceDenturesFulfillmentCoreCommand = {
  add_post_dam?: InputMaybe<Scalars['Boolean']['input']>;
  add_stippling?: InputMaybe<Scalars['Boolean']['input']>;
  bite_adjustment?: InputMaybe<LabsGqlDenturesFulfillmentBiteAdjustmentInput>;
  copy_change_notes?: InputMaybe<Scalars['String']['input']>;
  correct_occlusal_scheme?: InputMaybe<Scalars['Boolean']['input']>;
  doctor_preferences_id: Scalars['String']['input'];
  festooning_level?: InputMaybe<LabsGqlDenturesFestooningLevel>;
  handle_diastema?: InputMaybe<LabsGqlDenturesHandleDiastemaOption>;
  implant_support?: InputMaybe<LabsGqlDenturesFulfillmentImplantSupportInput>;
  is_try_in?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<LabsGqlLabOrderItemInput>>;
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
  lower_arch?: InputMaybe<LabsGqlDenturesFulfillmentCoreArchInput>;
  mailing_address_id: Scalars['String']['input'];
  midline_correction?: InputMaybe<LabsGqlDenturesFulfillmentBiteMidlineCorrectionInput>;
  notes: Scalars['String']['input'];
  number_of_spares?: InputMaybe<Scalars['Int']['input']>;
  patient_birthday?: InputMaybe<Scalars['String']['input']>;
  patient_first_name?: InputMaybe<Scalars['String']['input']>;
  patient_gender?: InputMaybe<Scalars['String']['input']>;
  /** When provided, the info from an existing patient with that id will be used instead of any patient information provided here. */
  patient_id?: InputMaybe<Scalars['String']['input']>;
  patient_last_name?: InputMaybe<Scalars['String']['input']>;
  photos: Array<LabsGqlDenturesFulfillmentSinglePhotoInput>;
  scan_export_id: Scalars['String']['input'];
  smile_style: LabsGqlSmileStyleChoice;
  upper_arch?: InputMaybe<LabsGqlDenturesFulfillmentCoreArchInput>;
  waxup_requested?: InputMaybe<Scalars['Boolean']['input']>;
};

/** DEPRECATED: Use PlaceLabOrder instead. */
export type LabsGqlPlaceDenturesFulfillmentWaxRimCommand = {
  arch?: InputMaybe<LabsGqlDenturesArchType>;
  doctor_preferences_id: Scalars['String']['input'];
  implant_support?: InputMaybe<LabsGqlDenturesFulfillmentImplantSupportInput>;
  items?: InputMaybe<Array<LabsGqlLabOrderItemInput>>;
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
  mailing_address_id: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  patient_birthday?: InputMaybe<Scalars['String']['input']>;
  patient_first_name?: InputMaybe<Scalars['String']['input']>;
  patient_gender?: InputMaybe<Scalars['String']['input']>;
  /** When provided, the info from an existing patient with that id will be used instead of any patient information provided here. */
  patient_id?: InputMaybe<Scalars['String']['input']>;
  patient_last_name?: InputMaybe<Scalars['String']['input']>;
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlPlaceLabOrderCommand = {
  /** Only required if order is for aligner */
  aligner_info?: InputMaybe<LabsGqlPlaceAlignerOrderInfo>;
  attachments?: InputMaybe<Array<LabsGqlLabOrderSinglePhotoInput>>;
  case_readiness_id?: InputMaybe<Scalars['String']['input']>;
  cbct_url?: InputMaybe<Scalars['String']['input']>;
  doctor_note_category_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  doctor_notes?: InputMaybe<Scalars['String']['input']>;
  doctor_preferences_id: Scalars['String']['input'];
  /** If set & not-null, can be used to prevent duplicate orders */
  idempotency_key?: InputMaybe<Scalars['String']['input']>;
  is_aligner_order?: InputMaybe<Scalars['Boolean']['input']>;
  is_training_order?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<LabsGqlLabOrderItemInput>>;
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
  mailing_address_id: Scalars['String']['input'];
  partials_fulfillment?: InputMaybe<LabsGqlPlacePartialsFulfillmentCommandData>;
  patient_birthday?: InputMaybe<Scalars['String']['input']>;
  patient_first_name?: InputMaybe<Scalars['String']['input']>;
  patient_gender?: InputMaybe<Scalars['String']['input']>;
  /** When provided, the info from an existing patient with that id will be used instead of any patient information provided here. */
  patient_id?: InputMaybe<Scalars['String']['input']>;
  patient_last_name?: InputMaybe<Scalars['String']['input']>;
  previous_fulfillment_order_id?: InputMaybe<Scalars['String']['input']>;
  promo_codes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Only required if order is refabrication */
  refabrication_info?: InputMaybe<LabsGqlPlaceOrderRefabInfo>;
  refabrication_notes?: InputMaybe<Scalars['String']['input']>;
  scan_export_id: Scalars['String']['input'];
  sla_modifier?: InputMaybe<LabsGqlCartSlaModifier>;
  smile_style?: InputMaybe<LabsGqlSmileStyleChoice>;
  waxup_requested?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlPlaceLabOrderFromTryInCommand = {
  new_order_items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
  original_order_id: Scalars['String']['input'];
  try_in_feedback_details: Array<LabsGqlTryInFeedbackDetailInput>;
};

export type LabsGqlPlaceOrderRefabInfo = {
  annotations?: InputMaybe<Array<LabsGqlReviewSubmissionAnnotationInput>>;
  attachment_urls?: InputMaybe<Array<Scalars['String']['input']>>;
  attachments?: InputMaybe<Array<LabsGqlRefabOrderAttachments>>;
  notes: Scalars['String']['input'];
  original_order_id: Scalars['String']['input'];
  reasons?: InputMaybe<Array<LabsGqlOrderRefabReasonInput>>;
  refab_item_reason_codes?: InputMaybe<Array<LabsGqlRefabItemInfo>>;
};

export type LabsGqlPlacePartialsFulfillmentCommandData = {
  type: LabsGqlPartialsSingleFulfillmentType;
};

export type LabsGqlPlyWithImage = {
  __typename?: 'PlyWithImage';
  imagePath?: Maybe<Scalars['String']['output']>;
  plyPath: Scalars['String']['output'];
};

export type LabsGqlPortalMarginInput = {
  /** The margin coordinates packed as [x1, y1, z1, ..., xn, yn, zn] */
  coords: Array<Scalars['Float']['input']>;
  unn: Scalars['Float']['input'];
};

export type LabsGqlPortalMarginType = {
  __typename?: 'PortalMarginType';
  /** The margin coordinates packed as [x1, y1, z1, ..., xn, yn, zn] */
  coords: Array<Scalars['Float']['output']>;
  unn: Scalars['Float']['output'];
};

export enum LabsGqlPortalShipDestinationType {
  Dandy = 'Dandy',
  Practice = 'Practice'
}

export enum LabsGqlPortalShipShippingSpeed {
  Ground = 'Ground',
  Overnight = 'Overnight',
  ThreeDay = 'ThreeDay',
  TwoDay = 'TwoDay'
}

export type LabsGqlPracticeBillingRecurringItemConfigDto = LabsGqlCreditCardSurchargeConfigDto | LabsGqlOneTimeChargeConfigDto | LabsGqlOrderItemVariantChargeConfigDto;

export enum LabsGqlPracticeBillingRecurringItemType {
  CreditCardSurcharge = 'CreditCardSurcharge',
  MinimumSchedule = 'MinimumSchedule',
  OneTimeCharge = 'OneTimeCharge',
  OrderItemVariantCharge = 'OrderItemVariantCharge'
}

export type LabsGqlPracticeBillingRecurringItemsDto = {
  __typename?: 'PracticeBillingRecurringItemsDTO';
  config: LabsGqlPracticeBillingRecurringItemConfigDto;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  practice_id: Scalars['ID']['output'];
  price_cents?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['DateTime']['output']>;
  type: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlPracticeCaseInput = {
  actor_email?: InputMaybe<Scalars['String']['input']>;
  actor_name?: InputMaybe<Scalars['String']['input']>;
  actor_role: LabsGqlThreeShapeActorRoleType;
  case_color_names?: InputMaybe<Scalars['String']['input']>;
  case_creation_date_utc?: InputMaybe<Scalars['DateTime']['input']>;
  case_item_names?: InputMaybe<Scalars['String']['input']>;
  case_material_names?: InputMaybe<Scalars['String']['input']>;
  case_name?: InputMaybe<Scalars['String']['input']>;
  threeshape_order_id: Scalars['String']['input'];
  threeshape_patient_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlPracticeContact = {
  __typename?: 'PracticeContact';
  contact_name: Scalars['String']['output'];
  email: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  practice_name: Scalars['String']['output'];
  practice_status?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
};

export type LabsGqlPracticeContract = {
  __typename?: 'PracticeContract';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  effective_end_date?: Maybe<Scalars['DateTime']['output']>;
  effective_start_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  organization_id: Scalars['ID']['output'];
  pandadoc_contract_id?: Maybe<Scalars['String']['output']>;
  spend_terms: Array<LabsGqlPracticeContractSpendTerm>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlPracticeContractDto = {
  __typename?: 'PracticeContractDTO';
  activationFeeCents?: Maybe<Scalars['Float']['output']>;
  contractSignedDate: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  effectiveDateContractOriginal?: Maybe<Scalars['DateTime']['output']>;
  effectiveDateOffsetMonths?: Maybe<Scalars['Float']['output']>;
  effectiveDateType: Scalars['String']['output'];
  enforceContractFirstMonth?: Maybe<Scalars['Float']['output']>;
  enforceContractFirstYear?: Maybe<Scalars['Float']['output']>;
  enforceContractLastMonth?: Maybe<Scalars['Float']['output']>;
  enforceContractLastYear?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  introductoryCycleLengthMonths?: Maybe<Scalars['Float']['output']>;
  introductoryCycleMinimumSpendCents?: Maybe<Scalars['Float']['output']>;
  migrationNote?: Maybe<Scalars['String']['output']>;
  ongoingCycleLengthMonths: Scalars['Float']['output'];
  ongoingCycleMinimumSpendCents: Scalars['Float']['output'];
  practiceId: Scalars['String']['output'];
  salesForceContractId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlPracticeContractFilterCriteriaInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  effective?: InputMaybe<Scalars['Boolean']['input']>;
  pandadoc_contract_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlPracticeContractSpendTerm = {
  __typename?: 'PracticeContractSpendTerm';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  effective_end_date?: Maybe<Scalars['DateTime']['output']>;
  effective_start_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  practice_contract_id: Scalars['ID']['output'];
  spend_cycle_count?: Maybe<Scalars['Int']['output']>;
  spend_cycle_length: Scalars['Int']['output'];
  spend_minimum_cents: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlPracticeContractSpendTermDto = {
  __typename?: 'PracticeContractSpendTermDTO';
  createdAt: Scalars['DateTime']['output'];
  firstMonth: Scalars['Float']['output'];
  firstYear: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  isMonthlyContract: Scalars['Boolean']['output'];
  lastMonth?: Maybe<Scalars['Float']['output']>;
  lastYear?: Maybe<Scalars['Float']['output']>;
  practiceContractId: Scalars['String']['output'];
  spendMinimumCents: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlPracticeDetails = {
  __typename?: 'PracticeDetails';
  AM?: Maybe<Scalars['String']['output']>;
  CSL?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  avg_monthly_spend?: Maybe<Scalars['Float']['output']>;
  chairside_stage?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  device_id_list?: Maybe<Scalars['String']['output']>;
  last_order_date?: Maybe<Scalars['DateTime']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  pod?: Maybe<Scalars['String']['output']>;
  practice_email?: Maybe<Scalars['String']['output']>;
  practice_name: Scalars['String']['output'];
  /** @deprecated making a separate call to get the impersonation token using useGetImpersonationTokenQuery in order to correctly set the impersonation type */
  practice_url: Scalars['String']['output'];
  salesforce_account_id?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  team_viewer_id?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPracticeInteractions = {
  __typename?: 'PracticeInteractions';
  /** @deprecated moved to PracticeDetails */
  city?: Maybe<Scalars['String']['output']>;
  kustomer_conversations: Array<LabsGqlKustomerConversation>;
  kustomer_pages?: Maybe<Scalars['Float']['output']>;
  /** @deprecated moved to PracticeDetails */
  practice_name: Scalars['String']['output'];
  /** @deprecated moved to PracticeDetails */
  practice_url: Scalars['String']['output'];
  /** @deprecated moved to PracticeDetails */
  salesforce_account_id?: Maybe<Scalars['String']['output']>;
  sf_escalations: Array<LabsGqlSalesforceCase>;
  /** @deprecated moved to PracticeDetails */
  state?: Maybe<Scalars['String']['output']>;
  /** @deprecated moved to PracticeDetails */
  team_viewer_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated moved to PracticeDetails */
  timezone?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPracticeListViewFilterInput = {
  doctor_id_filter?: InputMaybe<Array<Scalars['String']['input']>>;
  product_line_filter?: InputMaybe<Array<LabsGqlProductLine>>;
  sort_asc: Scalars['Boolean']['input'];
  sort_field?: InputMaybe<LabsGqlOrdersSortField>;
  status: LabsGqlPracticeOrderStatus;
  unit_type_filter?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LabsGqlPracticeLoyaltyDetails = {
  __typename?: 'PracticeLoyaltyDetails';
  has_opted_into_loyalty: Scalars['Boolean']['output'];
  loyalty_points_balance: Scalars['Float']['output'];
};

export type LabsGqlPracticeMetadata = {
  __typename?: 'PracticeMetadata';
  am_name?: Maybe<Scalars['String']['output']>;
  cx_name?: Maybe<Scalars['String']['output']>;
  pod?: Maybe<Scalars['String']['output']>;
  total_non_cancelled_orders?: Maybe<Scalars['Float']['output']>;
};

export type LabsGqlPracticeNameAndPrimaryContactInfo = {
  __typename?: 'PracticeNameAndPrimaryContactInfo';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  primary_contact_email?: Maybe<Scalars['String']['output']>;
  primary_contact_phone_number?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPracticeOnboardingDto = {
  __typename?: 'PracticeOnboardingDTO';
  completed_steps: Array<LabsGqlPracticeOnboardingStep>;
  created_at: Scalars['DateTime']['output'];
  doctor_id?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  meta: LabsGqlOnboardingMetaDto;
  portal_onboarding_complete: Scalars['Boolean']['output'];
  practice_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlPracticeOnboardingStep {
  BillingInfo = 'BILLING_INFO',
  DeliveryInfo = 'DELIVERY_INFO',
  PersonalizeTraining = 'PERSONALIZE_TRAINING',
  ScannerSetup = 'SCANNER_SETUP'
}

export type LabsGqlPracticeOnboardingTransitionStepInput = {
  completedStep?: InputMaybe<LabsGqlPracticeOnboardingStep>;
  completedSteps?: InputMaybe<Array<LabsGqlPracticeOnboardingStep>>;
  startedStep?: InputMaybe<LabsGqlPracticeOnboardingStep>;
};

export enum LabsGqlPracticeOrderStatus {
  All = 'All',
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Draft = 'Draft',
  Fabrication = 'Fabrication',
  NeedsOrderFeedback = 'NeedsOrderFeedback',
  New = 'New',
  OnHold = 'OnHold',
  Return = 'Return',
  Shipped = 'Shipped',
  Waxup = 'Waxup'
}

export type LabsGqlPracticeOverviewOrderCounts = {
  __typename?: 'PracticeOverviewOrderCounts';
  InFlightOrders: Scalars['Int']['output'];
  TotalOrders: Scalars['Int']['output'];
};

export type LabsGqlPracticePricesResult = {
  __typename?: 'PracticePricesResult';
  partnerId: Scalars['String']['output'];
  prices: Array<LabsGqlIPrice>;
};

export type LabsGqlPracticeProductionOrderActionEligibilityDto = {
  __typename?: 'PracticeProductionOrderActionEligibilityDTO';
  can_cancel: Scalars['Boolean']['output'];
};

export type LabsGqlPracticeProductionOrderDatesDto = {
  __typename?: 'PracticeProductionOrderDatesDTO';
  digital_design_preview_estimated_completion_date?: Maybe<Scalars['DateTime']['output']>;
  estimated_delivery_date?: Maybe<Scalars['DateTime']['output']>;
  promised_delivery_date?: Maybe<Scalars['DateTime']['output']>;
  promised_digital_design_preview_completion_date?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlPracticeProductionOrderOverviewDto = {
  __typename?: 'PracticeProductionOrderOverviewDTO';
  action_eligibility: LabsGqlPracticeProductionOrderActionEligibilityDto;
  dates: LabsGqlPracticeProductionOrderDatesDto;
  id: Scalars['ID']['output'];
  status: LabsGqlPracticeProductionOrderOverviewStatus;
};

export enum LabsGqlPracticeProductionOrderOverviewStatus {
  Canceled = 'Canceled',
  Delivered = 'Delivered',
  InProgress = 'InProgress'
}

export type LabsGqlPracticeRejectedScanResponse = {
  __typename?: 'PracticeRejectedScanResponse';
  practice_decision: LabsGqlResolveScanRejectionDecisionEnum;
  practice_notes?: Maybe<Scalars['String']['output']>;
  practice_selected_option?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPracticeRejectedScanResponseInput = {
  practice_decision: LabsGqlResolveScanRejectionDecisionEnum;
  practice_notes?: InputMaybe<Scalars['String']['input']>;
  practice_selected_option?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlPracticeRequestDto = {
  __typename?: 'PracticeRequestDTO';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  metadata: LabsGqlPracticeRequestMetadata;
  organization_id: Scalars['ID']['output'];
  sku: LabsGqlOrderItemSkuType;
  type: LabsGqlPracticeRequestType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlPracticeRequestMarketingMaterials = {
  __typename?: 'PracticeRequestMarketingMaterials';
  address_id: Scalars['String']['output'];
  doctor_id: Scalars['String']['output'];
  email_requested: Scalars['Boolean']['output'];
  type: LabsGqlPracticeRequestType;
};

export type LabsGqlPracticeRequestMarketingMaterialsInput = {
  address_id: Scalars['String']['input'];
  doctor_id: Scalars['String']['input'];
  email_requested?: InputMaybe<Scalars['Boolean']['input']>;
  type: LabsGqlPracticeRequestType;
};

export type LabsGqlPracticeRequestMetadata = LabsGqlPracticeRequestMarketingMaterials | LabsGqlPracticeRequestScheduleTraining;

export type LabsGqlPracticeRequestScheduleTraining = {
  __typename?: 'PracticeRequestScheduleTraining';
  /** Calendly URI that is a link to appointment information */
  training_uri: Scalars['String']['output'];
  type: LabsGqlPracticeRequestType;
};

export type LabsGqlPracticeRequestScheduleTrainingInput = {
  /** Calendly URI that is a link to appointment information */
  training_uri: Scalars['String']['input'];
  type: LabsGqlPracticeRequestType;
};

export enum LabsGqlPracticeRequestType {
  MarketingMaterials = 'marketing_materials',
  ScheduleTraining = 'schedule_training'
}

export type LabsGqlPracticeRespondToDoctorRequestCommand = {
  doctor_request_id: Scalars['String']['input'];
  freeform_notes: Scalars['String']['input'];
  selected_doctor_request_option_id: Scalars['String']['input'];
};

export type LabsGqlPracticeRespondToRejectedScanCommand = {
  order_id: Scalars['String']['input'];
  practice_response: LabsGqlPracticeRejectedScanResponseInput;
};

export type LabsGqlPracticeRespondedToRejectedScanData = {
  __typename?: 'PracticeRespondedToRejectedScanData';
  practice_decision?: Maybe<LabsGqlResolveScanRejectionDecisionEnum>;
  practice_notes?: Maybe<Scalars['String']['output']>;
  practice_selected_option?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlPracticeSoftwareType {
  Byod = 'Byod',
  Chairside = 'Chairside',
  Trios = 'Trios'
}

export type LabsGqlPracticeStage = {
  __typename?: 'PracticeStage';
  stage?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPracticeSupportDto = {
  __typename?: 'PracticeSupportDTO';
  agreement_version?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  pod_id?: Maybe<Scalars['String']['output']>;
  practice_id: Scalars['String']['output'];
  software_type?: Maybe<LabsGqlPracticeSoftwareType>;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlPracticeTimelineEventNameEnum {
  DoctorCustomPreferencesSet = 'DoctorCustomPreferencesSet',
  DoctorLabSlipAlertsSaved = 'DoctorLabSlipAlertsSaved',
  DoctorNotificationPreferencesCollected = 'DoctorNotificationPreferencesCollected',
  DoctorPreferenceSet = 'DoctorPreferenceSet',
  DoctorPreferencesCreated = 'DoctorPreferencesCreated',
  DoctorPreferencesDeleted = 'DoctorPreferencesDeleted',
  DoctorProfilePhotoSet = 'DoctorProfilePhotoSet',
  NotificationGroupForwardingUpdated = 'NotificationGroupForwardingUpdated',
  NotificationGroupUpdated = 'NotificationGroupUpdated',
  NotificationPreferencesSet = 'NotificationPreferencesSet',
  PracticeNotificationLog = 'PracticeNotificationLog',
  PreferredManufacturersSet = 'PreferredManufacturersSet',
  SingleClinicalPreferenceSet = 'SingleClinicalPreferenceSet',
  TaggedEntity = 'TaggedEntity',
  UntaggedEntity = 'UntaggedEntity'
}

export type LabsGqlPracticeTimelineItem = LabsGqlITimelineItem & {
  __typename?: 'PracticeTimelineItem';
  actor: LabsGqlTimelineItemActor;
  /** @deprecated use attachments instead */
  attachment_urls: Array<Scalars['String']['output']>;
  attachments: Array<LabsGqlTimelineAttachment>;
  date: Scalars['DateTime']['output'];
  eventName: LabsGqlPracticeTimelineEventNameEnum;
  notification_log_id?: Maybe<Scalars['String']['output']>;
  subtitle: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  title_link?: Maybe<LabsGqlTimelineItemLink>;
};

export type LabsGqlPracticeToPracticeContractDto = {
  __typename?: 'PracticeToPracticeContractDTO';
  createdAt: Scalars['DateTime']['output'];
  isPrimary: Scalars['Boolean']['output'];
  practiceContractId: Scalars['String']['output'];
  practiceId: Scalars['String']['output'];
  practiceToPracticeContractId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlPrecheckoutScanReviewRequest = {
  case_id: Scalars['String']['input'];
  case_items_raw?: InputMaybe<Scalars['String']['input']>;
  case_materials_raw?: InputMaybe<Scalars['String']['input']>;
  case_shades_raw?: InputMaybe<Scalars['String']['input']>;
  machine_id: Scalars['String']['input'];
};

export enum LabsGqlPreemptDesignTaskTimeoutAction {
  Extend = 'Extend',
  Release = 'Release'
}

export type LabsGqlPreemptDesignTaskTimeoutCommand = {
  action: LabsGqlPreemptDesignTaskTimeoutAction;
  order_id: Scalars['ID']['input'];
  task_id: Scalars['ID']['input'];
  user_id: Scalars['ID']['input'];
};

export type LabsGqlPreferredManufacturer = {
  __typename?: 'PreferredManufacturer';
  id: Scalars['String']['output'];
  item_rules: Array<LabsGqlCustomFieldDisplayRule>;
  manufacturer_id: Scalars['String']['output'];
};

export type LabsGqlPreferredManufacturerInput = {
  item_rules: Array<LabsGqlCustomFieldDisplayRuleInput>;
  manufacturer_id: Scalars['String']['input'];
};

export type LabsGqlPrepInBrowserDcmModification = {
  /** The path of the modified asset. */
  new_path: Scalars['String']['input'];
  /** The original STL/PLY/DCM asset path */
  original_path: Scalars['String']['input'];
  scan_type: LabsGqlScanExportScanType;
};

export type LabsGqlPrepInBrowserInsertionDirectionPayload = {
  /** A Vector3, eg x0, y0, z0 */
  direction: Array<Scalars['Float']['input']>;
  /** An array of n points in the format x0, y0, z0, ..., xn, yn, zn. */
  spline: Array<Scalars['Float']['input']>;
  unn: Scalars['Float']['input'];
};

export type LabsGqlPrepInBrowserItemMatrixPayload = {
  /** An array of points constituting a Matrix4, eg x0, y0, z0, ..., x3, y3, z3. */
  matrix: Array<Scalars['Float']['input']>;
  unn: Scalars['Float']['input'];
};

export type LabsGqlPrepInBrowserMarginPayload = {
  /** An array of n points in the format x0, y0, z0, ..., xn, yn, zn. */
  spline: Array<Scalars['Float']['input']>;
  unn: Scalars['Float']['input'];
};

export type LabsGqlPrepInBrowserPayload = {
  adjustment_matrices?: InputMaybe<Array<LabsGqlPrepInBrowserItemMatrixPayload>>;
  /** A Vector3, eg x0, y0, z0 */
  center_vector?: InputMaybe<Array<Scalars['Float']['input']>>;
  dcm_paths: Array<LabsGqlPrepInBrowserDcmModification>;
  /** Whether to enable the AutomateReview design workflow task for this order */
  enable_direct_to_lab_review?: InputMaybe<Scalars['Boolean']['input']>;
  /** A Vector3, eg x0, y0, z0 */
  front_direction_vector?: InputMaybe<Array<Scalars['Float']['input']>>;
  individual_transformation_matrices?: InputMaybe<Array<LabsGqlPrepInBrowserItemMatrixPayload>>;
  insertion_directions: Array<LabsGqlPrepInBrowserInsertionDirectionPayload>;
  /** Whether this order is eligible for AutomateReview */
  is_direct_to_lab_eligible?: InputMaybe<Scalars['Boolean']['input']>;
  /** An array of points constituting a Matrix4, eg x0, y0, z0, ..., x3, y3, z3. */
  mandible_design_transformation_matrix?: InputMaybe<Array<Scalars['Float']['input']>>;
  margins: Array<LabsGqlPrepInBrowserMarginPayload>;
  /** A Vector3, eg x0, y0, z0 */
  occlusal_direction_vector?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** An array of points constituting a Matrix4, eg x0, y0, z0, ..., x3, y3, z3. */
  old_mandible_design_transformation_matrix?: InputMaybe<Array<Scalars['Float']['input']>>;
  order_id: Scalars['String']['input'];
  placement_matrices?: InputMaybe<Array<LabsGqlPrepInBrowserItemMatrixPayload>>;
  /** GCS path for prep in browser metadata like scanTransforms. */
  prep_metadata_path?: InputMaybe<Scalars['String']['input']>;
  /** An array of points constituting a Matrix4, eg x0, y0, z0, ..., x3, y3, z3. */
  prep_occlusal_alignment_matrix?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** An array of points constituting a Matrix4, eg x0, y0, z0, ..., x3, y3, z3. */
  prep_transformation_matrix?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** ID of the Prep in Browser session. */
  session_id?: InputMaybe<Scalars['String']['input']>;
  /** In-browser collected data on how long it took to actually create this prep design. */
  session_timing_data?: InputMaybe<LabsGqlPrepInBrowserSessionTimingData>;
  should_complete_task?: InputMaybe<Scalars['Boolean']['input']>;
  /** Path to the JSON file containing saved PiB scans with DandyPrepSubmissionScans schema */
  submission_file_path?: InputMaybe<Scalars['String']['input']>;
  /** An array of points constituting a Matrix4, eg x0, y0, z0, ..., x3, y3, z3. */
  upper_jaw_to_articulator_matrix?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** An array of points constituting a Matrix4, eg x0, y0, z0, ..., x3, y3, z3. */
  upper_jaw_to_lower_jaw_matrix?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** An array of points constituting a Matrix4, eg x0, y0, z0, ..., x3, y3, z3. */
  upper_jaw_to_lower_jaw_static_occlusion_matrix?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type LabsGqlPrepInBrowserSessionTimingData = {
  asset_upload_time_ms: Scalars['Float']['input'];
  sections: Array<LabsGqlPrepInBrowserSessionTimingDataSectionTime>;
  start_time_ms: Scalars['Float']['input'];
  submit_time_ms: Scalars['Float']['input'];
};

export type LabsGqlPrepInBrowserSessionTimingDataSectionTime = {
  duration_ms: Scalars['Float']['input'];
  section: Scalars['String']['input'];
};

export type LabsGqlPrepMark = {
  __typename?: 'PrepMark';
  dcmName: Scalars['String']['output'];
  details: Scalars['String']['output'];
  origin: LabsGqlSimpleVector3;
  sectioned?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  unn: Scalars['Float']['output'];
};

export type LabsGqlPresetInput = {
  annotated_image_urls: Array<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  preset_status: LabsGqlGuidedWaxupPresetStatus;
  preset_type: LabsGqlGuidedWaxupPresetType;
  structured_notes?: InputMaybe<Array<LabsGqlDesignOrderNoteInput>>;
};

export type LabsGqlPreviewCreditAndRefundOptionForExistingInvoice = {
  __typename?: 'PreviewCreditAndRefundOptionForExistingInvoice';
  amountCents: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  invoiceId: Scalars['ID']['output'];
  invoiceNumber: Scalars['String']['output'];
};

export type LabsGqlPreviewCreditAndRefundOptionForFutureInvoice = {
  __typename?: 'PreviewCreditAndRefundOptionForFutureInvoice';
  amountCents: Scalars['Int']['output'];
};

export type LabsGqlPreviewCreditAndRefundOptionsForOrderCancellation = {
  __typename?: 'PreviewCreditAndRefundOptionsForOrderCancellation';
  creditExistingInvoiceOption?: Maybe<LabsGqlPreviewCreditAndRefundOptionForExistingInvoice>;
  creditFutureInvoiceOption: LabsGqlPreviewCreditAndRefundOptionForFutureInvoice;
  hasOrderBeenInvoiced: Scalars['Boolean']['output'];
  refundExistingInvoiceOption?: Maybe<LabsGqlPreviewCreditAndRefundOptionForExistingInvoice>;
};

export type LabsGqlPriceApplicationRule = {
  __typename?: 'PriceApplicationRule';
  /** If null, applies for this unit type with any material type */
  material_type?: Maybe<Scalars['String']['output']>;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlPriceApplicationRuleInput = {
  /** If null, applies for this unit type with any material type */
  material_type?: InputMaybe<Scalars['String']['input']>;
  unit_type: Scalars['String']['input'];
};

export type LabsGqlPriceFieldInput = {
  amount_due_cents: Scalars['Int']['input'];
  idx: Scalars['Int']['input'];
  override_reason?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlPricingHistoryItemActorDto = {
  __typename?: 'PricingHistoryItemActorDTO';
  actor: Scalars['String']['output'];
  actor_id?: Maybe<Scalars['String']['output']>;
  automation_id?: Maybe<Scalars['String']['output']>;
  user_display_text?: Maybe<Scalars['String']['output']>;
  user_email?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlPricingHistoryItemDto = {
  __typename?: 'PricingHistoryItemDTO';
  actor: LabsGqlPricingHistoryItemActorDto;
  date: Scalars['DateTime']['output'];
  details?: Maybe<Array<LabsGqlHistoryItemDetails>>;
  item_details?: Maybe<Array<LabsGqlItemDetails>>;
  title: Scalars['String']['output'];
};

export type LabsGqlProcessScanNotificationsRes = {
  __typename?: 'ProcessScanNotificationsRes';
  initiate_implant_reminder: Scalars['Boolean']['output'];
  initiate_scan_review: Scalars['Boolean']['output'];
};

export type LabsGqlProductCatalog = {
  __typename?: 'ProductCatalog';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: LabsGqlProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlProductCatalogPrice = {
  __typename?: 'ProductCatalogPrice';
  catalogId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  priceCents: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlProductCatalogWithCrownDetails = LabsGqlIProductCatalogWithDetailsBase & {
  __typename?: 'ProductCatalogWithCrownDetails';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  details: LabsGqlCrownProductDetails;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: LabsGqlProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlProductCatalogWithDetailsDto = LabsGqlProductCatalogWithCrownDetails | LabsGqlProductCatalogWithOtherDetails;

export type LabsGqlProductCatalogWithOtherDetails = LabsGqlIProductCatalogWithDetailsBase & {
  __typename?: 'ProductCatalogWithOtherDetails';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  details: LabsGqlOtherProductDetails;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: LabsGqlProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum LabsGqlProductLine {
  Aligner = 'Aligner',
  Denture = 'Denture',
  HighComplexityCrownOrBridge = 'HighComplexityCrownOrBridge',
  Implant = 'Implant',
  ImplantSurgery = 'ImplantSurgery',
  Other = 'Other',
  Partial = 'Partial',
  Removable = 'Removable',
  SimpleCrownOrBridge = 'SimpleCrownOrBridge',
  Sleep = 'Sleep'
}

export type LabsGqlProductStationUnitMappingDto = {
  __typename?: 'ProductStationUnitMappingDTO';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  product_header: Scalars['String']['output'];
  station_id: Scalars['String']['output'];
  units: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlProductType {
  Crown = 'CROWN',
  Other = 'OTHER'
}

export type LabsGqlProductionOrderDto = {
  __typename?: 'ProductionOrderDTO';
  closed_at?: Maybe<Scalars['DateTime']['output']>;
  committed_delivery_date?: Maybe<Scalars['DateTime']['output']>;
  created_at: Scalars['DateTime']['output'];
  error_state?: Maybe<LabsGqlProductionOrderErrorStateEnum>;
  id: Scalars['ID']['output'];
  requirements: Array<LabsGqlProductionRequirementDto>;
  sales_order_id: Scalars['ID']['output'];
  status: LabsGqlProductionOrderStatusEnum;
  target_delivery_date?: Maybe<Scalars['DateTime']['output']>;
  updated_at: Scalars['DateTime']['output'];
  work_orders: Array<LabsGqlProductionOrderWorkOrderDto>;
};

export enum LabsGqlProductionOrderErrorStateEnum {
  NoOnTimePlansFailure = 'NoOnTimePlansFailure',
  PlanningFailure = 'PlanningFailure',
  PlanningInputsFailure = 'PlanningInputsFailure'
}

export enum LabsGqlProductionOrderStatusEnum {
  Active = 'Active',
  Canceled = 'Canceled',
  Completed = 'Completed'
}

export type LabsGqlProductionOrderWorkOrderDto = {
  __typename?: 'ProductionOrderWorkOrderDTO';
  assignment: LabsGqlWorkOrderAssignmentDto;
  created_at: Scalars['DateTime']['output'];
  fulfillment?: Maybe<LabsGqlWorkOrderFulfillmentDto>;
  history: LabsGqlWorkOrderHistoryDto;
  id: Scalars['ID']['output'];
  requirement_ids: Array<Scalars['ID']['output']>;
  schedule: LabsGqlWorkOrderScheduleDto;
  status: LabsGqlWorkOrderStatusEnum;
  type: LabsGqlWorkOrderTypeEnum;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlProductionPlanAssignmentCapacityUtilization = {
  __typename?: 'ProductionPlanAssignmentCapacityUtilization';
  ruleId: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type LabsGqlProductionPlanAssignmentMetadata = {
  __typename?: 'ProductionPlanAssignmentMetadata';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** A step for visualizing the overall */
export type LabsGqlProductionPlanDebuggerStep = {
  __typename?: 'ProductionPlanDebuggerStep';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  /** The IDs of the tasks that must be completed before this one */
  dependencies: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plannedEnd: Scalars['DateTime']['output'];
  plannedStart: Scalars['DateTime']['output'];
  /** The ID of the project */
  project?: Maybe<Scalars['String']['output']>;
  tooltipMeta: Array<LabsGqlProductionPlanStepMetadata>;
  /** One of project, task, or milestone */
  type: Scalars['String']['output'];
};

export type LabsGqlProductionPlanDebuggerView = {
  __typename?: 'ProductionPlanDebuggerView';
  committedDateInput?: Maybe<Scalars['DateTime']['output']>;
  /** The earliest date a plan can get there */
  earliestArrivalDate?: Maybe<Scalars['DateTime']['output']>;
  errorState?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orderCreated: Scalars['DateTime']['output'];
  plans: Array<LabsGqlProductionPlanDebuggerStep>;
  potentialAssignments: Array<LabsGqlProductionPlanPotentialAssignment>;
  /** @deprecated No longer populated */
  profilingResults?: Maybe<Scalars['String']['output']>;
  requirementSchedule: Array<LabsGqlProductionPlanDebuggerStep>;
  resultsAreFiltered: Scalars['Boolean']['output'];
  totalPlansConsidered: Scalars['Float']['output'];
};

/** Standard production order mutations only return the id of the production order. */
export type LabsGqlProductionPlanMutationResult = {
  __typename?: 'ProductionPlanMutationResult';
  id: Scalars['ID']['output'];
};

/** A potential assignment considered for the plans */
export type LabsGqlProductionPlanPotentialAssignment = {
  __typename?: 'ProductionPlanPotentialAssignment';
  capacityUtilizations: Array<LabsGqlProductionPlanAssignmentCapacityUtilization>;
  capacityWindowEnd: Scalars['DateTime']['output'];
  capacityWindowStart: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  /** One of project, task, or milestone */
  requirementId: Scalars['String']['output'];
  tooltipMeta: Array<LabsGqlProductionPlanAssignmentMetadata>;
};

export type LabsGqlProductionPlanStepMetadata = {
  __typename?: 'ProductionPlanStepMetadata';
  isDate: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LabsGqlProductionRequirementDto = {
  __typename?: 'ProductionRequirementDTO';
  dr_approval_required: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  item_grouping_id: Scalars['String']['output'];
  item_ids: Array<Scalars['String']['output']>;
  lead_time_hours: Scalars['Int']['output'];
  pre_req_ids: Array<Scalars['String']['output']>;
  required_organization_id?: Maybe<Scalars['String']['output']>;
  type: LabsGqlWorkOrderTypeEnum;
};

export type LabsGqlPuck = {
  __typename?: 'Puck';
  heightMillimeters: Scalars['Int']['output'];
  material: Scalars['String']['output'];
};

export enum LabsGqlQcErrorType {
  InDesign = 'InDesign',
  InReview = 'InReview',
  OnHold = 'OnHold',
  Unknown = 'Unknown'
}

export type LabsGqlQcItemConfigDto = {
  __typename?: 'QCItemConfigDTO';
  archived_at?: Maybe<Scalars['DateTime']['output']>;
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  created_at: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** If any pass, this task will display on the order */
  filters: Array<LabsGqlFilterSubmission>;
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlQcWarningResult = {
  __typename?: 'QCWarningResult';
  details: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type LabsGqlQcIssue = {
  __typename?: 'QcIssue';
  archived: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  /** If any pass, this task will display on the order */
  filters: Array<LabsGqlFilterSubmission>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated use sub_issues instead, this is left in for backwards compatibility to be removed after deployment */
  options: Array<Scalars['String']['output']>;
  prompt: Scalars['String']['output'];
  sub_issues: Array<LabsGqlQcSubIssue>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlQcIssueResultInputV2 = {
  id: Scalars['ID']['input'];
  options: Array<Scalars['String']['input']>;
};

export type LabsGqlQcSubIssue = {
  __typename?: 'QcSubIssue';
  is_fail_reason: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type LabsGqlQcSubIssueInput = {
  is_fail_reason: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type LabsGqlQualityControlItemResult = {
  __typename?: 'QualityControlItemResult';
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  complete: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  ignore: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type LabsGqlQuery = {
  __typename?: 'Query';
  actionTypesWithCategoryInfo: Array<LabsGqlActionTypeWithCategory>;
  active_price_presets: Array<LabsGqlIPricePreset>;
  alignerPhotoUploaded: Scalars['Boolean']['output'];
  areItemsEligibleForLiveDesignReview: Scalars['Boolean']['output'];
  biasRulesForDoctor: Array<LabsGqlBiasRule>;
  bulkLoadPracticePrices: Array<LabsGqlPracticePricesResult>;
  calculateActiveContractAggregateSpendOverPeriod: Array<LabsGqlOrgAggregateSpendForPeriodDto>;
  calculateCreditCardProcessingFeeForAmount: Scalars['Int']['output'];
  calculateCreditCardProcessingFeeForInvoiceAmounts: Array<LabsGqlCreditCardFeePerInvoiceDto>;
  calculatePracticeAggregateSpendOverPeriod: Scalars['Float']['output'];
  calculatePracticeInvoicedSpendOverPeriod: Array<LabsGqlOrgAggregateSpendForPeriodDto>;
  calendlyGetNextAvailableLdrTime?: Maybe<LabsGqlCalendlyGetNextAvailableLdrTimeResponse>;
  canDeleteDeliveryAddress: Scalars['Boolean']['output'];
  capacityRulesForManufacturer: Array<LabsGqlCapacityRule>;
  checkCsSchedule: LabsGqlCheckCsScheduleResult;
  checkPracticeAdmins: Scalars['Boolean']['output'];
  checkScannerConnected: LabsGqlScannerResponse;
  debugDesignerAutoAssignment: LabsGqlAutoAssignerDebuggerInfo;
  debugRoutingDecision: Array<LabsGqlRoutingDecisionEvent>;
  deleteFile: Scalars['String']['output'];
  designRulesForManufacturer: Array<LabsGqlDesignRule>;
  designStaffDetailedStatus: LabsGqlDesignStaffDetailedStatus;
  designStaffStatusBulk: Array<LabsGqlDesignStaffStatusEntry>;
  /** @deprecated Use getCartDeliveryOptions to get all available delivery options for an order. */
  estimatedDeliveryDate: LabsGqlEstimatedDeliveryDateRes;
  exchangeLegacyToken: Scalars['String']['output'];
  exchangeMultiAuthLegacyToken: LabsGqlLegacyTokenResult;
  fetchAllPracticeEmployees: Array<LabsGqlDoctorPreferences>;
  fetchDoctors: Array<LabsGqlDoctorPreferences>;
  /** @deprecated Use listOverdueInvoices - this is a compatibility shim for Chairside */
  fetchOverdueInvoices: Array<LabsGqlInvoice>;
  fetchPartnerBillingChangelog: Array<LabsGqlPartnerBillingChangelogEntry>;
  fetchPracticeStaff: Array<LabsGqlDoctorPreferences>;
  fetchPracticeStaffUsers: Array<LabsGqlUserStaff>;
  fetchStaffForSelectionScreen: Array<LabsGqlStaffForSelection>;
  filteredTickets: Array<LabsGqlTicketWithPracticeAndOrderInfo>;
  findCRMTickets: Array<LabsGqlCrmTicket>;
  findCRMUsers: Array<LabsGqlCrmUser>;
  findLastChatForEntity?: Maybe<LabsGqlChatMessageDto>;
  getAbutmentPart?: Maybe<LabsGqlAbutmentPartDto>;
  getActionCapabilities: Array<LabsGqlActionCapabilityDto>;
  getActionCategories: Array<LabsGqlActionCategoryDto>;
  getActionCategoryById?: Maybe<LabsGqlActionCategoryDto>;
  getActionTypeById?: Maybe<LabsGqlActionTypeDto>;
  getActionTypes: Array<LabsGqlActionTypeDto>;
  getActiveContractAndAssociatedOrgsByOrganizationId?: Maybe<LabsGqlContractAndAssociatedPracticesDto>;
  getActiveInternalDesignTask?: Maybe<LabsGqlActiveInternalDesignTaskSummary>;
  getActiveInternalDesignTasks: Array<LabsGqlActiveInternalDesignTaskSummary>;
  getActiveScanReviewByLabOrderId?: Maybe<LabsGqlScanReviewWorkOrderDto>;
  getActiveVideoDdpReviewRequest?: Maybe<LabsGqlVideoDdpReviewRequestDto>;
  /** Returns a summary of retainer sets attached to the aligner order */
  getAlignerRetainerSummaryForOrder: LabsGqlAlignerCaseRetainersSummaryDto;
  getAlignerTreatmentPlan?: Maybe<LabsGqlAlignerTreatmentPlan>;
  getAllActiveCapacityStationPools: Array<LabsGqlCapacityStationPoolDto>;
  getAllBillingCreditCategories: Array<LabsGqlBillingCreditCategory>;
  /** Get all smile libraries. */
  getAllCadSmileLibraries: Array<LabsGqlCadSmileLibraryDto>;
  getAllCapacityStationRules: Array<LabsGqlCapacityStationRuleDto>;
  getAllCapacityStations: Array<LabsGqlCapacityStationDto>;
  getAllDesignOrderAlignerTreatmentPlans: Array<LabsGqlDesignOrderAlignerTreatmentPlanDto>;
  getAllDesignOrderDesignRevisions: Array<LabsGqlDesignOrderDesignRevisionDto>;
  getAllOrganizationsCsv: LabsGqlGetAllOrganizationsCsvResult;
  getAllPendingVariantFeedback: Array<LabsGqlVariantFeedbackWithOrderInfoDto>;
  getAllPendingVariantFeedbackForPractice: Array<LabsGqlVariantFeedbackDto>;
  getAllPracticeContactsIncludingExternal: Array<LabsGqlPracticeContact>;
  /** @deprecated No longer needed, avoid fetching all rows */
  getAllPreferenceSets: Array<LabsGqlDoctorPreferences>;
  getAllProductStationUnitMappings: Array<LabsGqlProductStationUnitMappingDto>;
  getAllScanners: LabsGqlAllScannersResponse;
  getAllScannersWithSearch: LabsGqlAllScannersResponse;
  /** Get the batch shipping manifest for this moment in time */
  getBatchShipManifest: LabsGqlBatchShipManifestDto;
  getBillingContactEmailsForInvoices: Array<LabsGqlBillingPrimaryContactEmail>;
  getBillingCreditCategoriesById: Array<LabsGqlBillingCreditCategory>;
  getCRMChatConversation?: Maybe<LabsGqlCrmChatConversation>;
  getCRMChatUser?: Maybe<LabsGqlCrmChatUser>;
  getCRMPractice: LabsGqlCrmPractice;
  getCRMPracticeMetadata: LabsGqlPracticeMetadata;
  getCRMTicket?: Maybe<LabsGqlCrmTicket>;
  getCRMTicketCommentAttachment: Scalars['String']['output'];
  getCRMTicketComments: LabsGqlCrmTicketComments;
  /** Get a smile library by id. */
  getCadSmileLibraryById: LabsGqlCadSmileLibraryDto;
  getCalendlySchedulingUrl: Scalars['String']['output'];
  getCanceledLabOrders: Array<LabsGqlCanceledLabOrderResult>;
  getCapacityRuleLoads: Array<LabsGqlCapacityRuleLoadResult>;
  /** Get the available delivery options for an order. Will always return an option with the standard modifier. */
  getCartDeliveryOptions: LabsGqlGetCartDeliveryOptionsRes;
  getCaseReadiness?: Maybe<LabsGqlCaseReadinessDto>;
  getCaseReadinessCases: Array<LabsGqlCaseReadinessDto>;
  getChairsideFirebaseDownloadUrl: LabsGqlChairsideSignedDownloadUrl;
  getChairsideFirebaseUploadUrl: LabsGqlChairsideUpload;
  getCompletedStagesForOrder: Array<LabsGqlManufacturingStageDto>;
  getConnectedPreferencesByEmailAndPhone: Array<LabsGqlDoctorPreferences>;
  /** Gets the active contract, or the most recently created contract which will be active first. */
  getContractAndAssociatedOrgsByOrganizationId?: Maybe<LabsGqlContractAndAssociatedPracticesDto>;
  getCreditById: LabsGqlInvoiceCreditDto;
  getCreditCardProcessingFeeAmountCentsForInvoice?: Maybe<Scalars['Int']['output']>;
  getCreditCardProcessingFeeAmountCentsForInvoiceIds: Array<LabsGqlCreditCardFeePerInvoiceDto>;
  getDesignFileDmeList: LabsGqlDesignFileDmeListResult;
  getDesignOrderDesignRevisionById?: Maybe<LabsGqlDesignOrderDesignRevisionDto>;
  getDesignOrderDesignRevisionsByTaskIds: Array<LabsGqlDesignOrderDesignRevisionDto>;
  getDesignQaEvaluationById: LabsGqlDesignQaEvaluationDto;
  getDesignQaEvaluationsByOrderId: Array<LabsGqlDesignQaEvaluationDto>;
  getDesignStaff?: Maybe<LabsGqlDesignStaffDto>;
  getDesignStaffFocusAreasByDesigner?: Maybe<Array<LabsGqlDesignStaffFocusAreaDto>>;
  getDesignStaffTeams?: Maybe<Array<LabsGqlDesignStaffTeamDto>>;
  getDesignTrainingEnrolledCourse: LabsGqlEnrolledCourse;
  getDoctorPreferencesById: LabsGqlDoctorPreferences;
  getDoctorPreferencesByToken: LabsGqlDoctorAndLastName;
  getDoctorRequest?: Maybe<LabsGqlDoctorRequestDto>;
  getDoesUserPreferPasswordLogin: Scalars['Boolean']['output'];
  getEmailPreviewByNotificationLogId: LabsGqlGetEmailPreviewByNotificationLogIdResult;
  getFeatureFlagValueByOrganizationId: LabsGqlGetFeatureFlagValueByOrganizationIdResponse;
  getFirebaseToken: Scalars['String']['output'];
  getFulfilledVideoDdpReviewRequest?: Maybe<LabsGqlVideoDdpReviewRequestDto>;
  /** Gets all contracts for the practice */
  getFullyResolvedPracticeContractsV2ByPracticeId: Array<LabsGqlFullyResolvedPracticeContractDto>;
  getHoldRemovalSuggestedStageEndDate: Scalars['DateTime']['output'];
  getImpersonationToken: LabsGqlImpersonationTokenDto;
  getImplantById?: Maybe<LabsGqlImplantTypeDto>;
  getImplantTypes: Array<LabsGqlImplantTypeDto>;
  getImplantTypesLite: Array<LabsGqlImplantTypeDto>;
  /** Returns the list of injection mold files for the latest revision of the specified order. */
  getInjectionMoldFiles: Array<Scalars['String']['output']>;
  getInvoiceById: LabsGqlInvoiceDto;
  getInvoiceHistoryItems: Array<LabsGqlPricingHistoryItemDto>;
  getIsFcmTrainingComplete: Scalars['Boolean']['output'];
  getIsPracticeByod: Scalars['Boolean']['output'];
  getIteroCompaniesByPracticeIds: Array<LabsGqlIteroCompanyEntity>;
  getIteroCompanyByPracticeId?: Maybe<LabsGqlIteroCompanyEntity>;
  getIteroIntegrationAuthDetails: LabsGqlGetIteroIntegrationAuthDetails;
  getIteroSettingsOfCurrentUser: LabsGqlIteroUserSettings;
  getIteroUserByStaffId?: Maybe<LabsGqlIteroUserEntity>;
  getIteroUsersByStaffIds?: Maybe<Array<LabsGqlIteroUserEntity>>;
  getLabAssignmentsByOrderId: Array<LabsGqlLabCaseAssignmentsByOrderIdResult>;
  getLabCaseCompletion: Array<LabsGqlLabCaseCompletionDto>;
  getLabCaseLedgerData: Array<LabsGqlLabPriceLedgerResult>;
  getLabCaseLedgerSummary: Array<LabsGqlLabPriceLedgerSummaryResult>;
  getLabMetalCost: Array<LabsGqlLabMetalCostQueryDto>;
  getLabPriceDefinitions: Array<LabsGqlLabPriceDefinitionResult>;
  getLabPriceLabPrices: Array<LabsGqlLabPriceLabPricesResult>;
  getLabPricePartnerPrices: Array<LabsGqlLabPricePartnerPricesResult>;
  getLabPrices: Array<LabsGqlLabPriceDto>;
  /** @deprecated DEPRECATED */
  getLabSalesOrderById: LabsGqlLabSalesOrderDto;
  /** @deprecated DEPRECATED */
  getLabSalesOrderIdByOrderNumber: LabsGqlLabSalesOrderIdOnlyDto;
  /** @deprecated DEPRECATED */
  getLabSalesOrdersBySplitId: Array<LabsGqlLabSalesOrderDto>;
  /**
   * Returns all previous and future sales orders in the refab chain for a provided sales order id, ordered by oldest to newest.
   * @deprecated DEPRECATED
   */
  getLabSalesOrdersInRefabChain: Array<LabsGqlLabSalesOrderDto>;
  getLabsPaymentMethods: Array<LabsGqlStripePaymentMethod>;
  getLastInvoiceEmailForOrganization: LabsGqlBillingSummaryEmail;
  getLatestDesignOrderDesignRevision?: Maybe<LabsGqlDesignOrderDesignRevisionDto>;
  getLatestDesignOrderDesignRevisionsForOrders: Array<LabsGqlDesignOrderDesignRevisionDto>;
  /** Returns the treatment plan that has most recently been shared with the doctor. */
  getLatestDoctorVisibleDesignOrderAlignerTreatmentPlan?: Maybe<LabsGqlDesignOrderAlignerTreatmentPlanDto>;
  /** The list of the manufacturer files of the latest design revision of each order. Will provide rotated models if requested and available, or non-rotated assets otherwise. */
  getLatestManufacturerFilesForOrders: Array<LabsGqlDesignManufacturerFileResult>;
  /** Returns any artifacts generated from "mergeAndStitchDesignOrders" for the latest design revision */
  getLatestMergeAndStitchFilesForOrder: Array<LabsGqlDesignMergeAndStitchFileResult>;
  getLdrCapacityStats: LabsGqlLdrCapacityStatsResponse;
  /** Returns legacy multi fulfillment info for an order, if it exists. Returns null if the order did not use a legacy multi fulfillment system. */
  getLegacyMultiFulfillmentInfoByOrderId?: Maybe<LabsGqlLegacyMultiFulfillmentInfoDto>;
  getLinkedDoctorPreferences: LabsGqlDoctorAndLastName;
  getLiveDdpReviewRequest?: Maybe<LabsGqlLiveDdpReviewRequestDto>;
  getLiveLabTechMeetingUrl: LabsGqlLiveLabTechMeetingUrl;
  getLoginOptions: Array<LabsGqlUserLoginOption>;
  getManufacturerPreferencesPreferences?: Maybe<LabsGqlManufacturerNotificationPreferences>;
  getManufacturerProfile: LabsGqlManufacturerProfile;
  /** Get a booking by id. */
  getManufacturingBookingById?: Maybe<LabsGqlManufacturingBookingDto>;
  getMembershipLogin?: Maybe<LabsGqlUserLoginOption>;
  getMetalMaterial: Array<LabsGqlMetalMaterialDto>;
  getMostFrequentlyUsedImplantManufacturers: Array<Scalars['String']['output']>;
  getMyPendingDesignQaEvaluations: Array<LabsGqlDesignQaEvaluationDto>;
  getNextInvoiceStatus: LabsGqlNextInvoiceStatusSummary;
  getNextInvoiceStatusForContractOrgs: Array<LabsGqlNextInvoiceStatusSummaryForOrganization>;
  getNextPartnerOrderNumber: Scalars['Float']['output'];
  getNonDoctorStaffById: LabsGqlNonDoctorPracticeStaffDto;
  getNonDoctorStaffByPartnerId: Array<LabsGqlNonDoctorPracticeStaffDto>;
  getNonFavoritedSavedSearches: Array<LabsGqlSavedSearch>;
  getNotificationLogsWithEvents: LabsGqlNotificationLogWithEventsArrayResult;
  getOnboardingSteps: Array<LabsGqlGetOnboardingStepsResponse>;
  getOnboardingStepsV2: LabsGqlGetOnboardingStepsResponseV2;
  getOrCreatePracticeSupport: LabsGqlPracticeSupportDto;
  /** Fetches Order information for Ops facing list view experiences. */
  getOrderAdminListViewEntriesByIds: Array<LabsGqlOrderAdminListViewEntry>;
  /** @deprecated This endpoint is no longer used and will be removed 11/3/2023. */
  getOrderAutomateResult?: Maybe<LabsGqlThreeshapeAutomateCaseResult>;
  getOrderByQcSearch?: Maybe<LabsGqlLabOrderWithEvents>;
  getOrderHasPendingPayment: Scalars['Boolean']['output'];
  getOrderIdByStackCode: LabsGqlStackCodeOrderDetails;
  /** @deprecated Use labSalesOrderGetIdByOrderNumber instead. */
  getOrderIdFromOrderNumber?: Maybe<Scalars['String']['output']>;
  getOrderLedger: LabsGqlOrderInvoicingLedgerDto;
  /** Fetches Order information for Practice facing list view experiences. */
  getOrderPracticeListViewEntriesByIds: Array<LabsGqlOrderPracticeListViewEntry>;
  getOrderPriceEntitiesByOrderId: Array<LabsGqlOrderPriceEntityDto>;
  getOrderPriceEntitiesByOrderIds: Array<LabsGqlOrderPriceEntityDto>;
  getOrderPriceHistoryItems: Array<LabsGqlPricingHistoryItemDto>;
  getOrderPricesByOrderId: Array<LabsGqlOrderPriceDto>;
  getOrderSlaChanges: Array<LabsGqlOrderSlaChangeEntry>;
  getOrderSlaStageStatuses: Array<LabsGqlSlaStageStatus>;
  getOrderTicketById?: Maybe<LabsGqlTicketDto>;
  getOrderTickets: Array<LabsGqlTicketDto>;
  getOrderTrackerEntries: Array<LabsGqlOrderTrackerStepDefinition>;
  getOrderTrackingHoldMessage?: Maybe<Scalars['String']['output']>;
  getOrderTrackingHoldTitle?: Maybe<Scalars['String']['output']>;
  getOrdersForManufacturer: Array<LabsGqlLabOrderWithEvents>;
  /** Get all orders associated with a given shipment */
  getOrdersForShipment: Array<LabsGqlLabOrderWithEvents>;
  getOrgAdmins: Array<LabsGqlStaffMemberWithRelations>;
  getOrgPrimaryAdmin: LabsGqlStaffMemberWithRelations;
  getOrganization: LabsGqlOrganizationDto;
  getOrganizationByExternalAccount?: Maybe<LabsGqlOrganizationDto>;
  getOrganizationPriceConfigs: Array<LabsGqlOrganizationPriceConfigDto>;
  getOrganizationWithAncestors: LabsGqlOrganizationWithAncestors;
  getOrganizationWithDescendants: LabsGqlOrganizationWithDescendants;
  /** For use by the enterprise portal while we simulate the enterprise <-> child relationship through parent <-> descendant hierarchy */
  getOrganizationWithDescendantsAndStaff: LabsGqlOrganizationWithDescendants;
  getOrganizationsForUser: Array<LabsGqlUserOrganizationDto>;
  getOutstandingBalanceCents: Scalars['Float']['output'];
  getOwnImpersonatedShortenedLink: LabsGqlGetOwnImpersonatedShortenedLinkResponse;
  getOwnImpersonationToken: LabsGqlImpersonationTokenDto;
  getPaginatedOrganizations: LabsGqlPaginatedOrganizationsResponse;
  getPaginatedUsers: LabsGqlPaginatedUsersResponse;
  getPartnerBillingAccount: LabsGqlIPartnerBilling;
  /** Given a practice id, retrieve the relevant contract. If the requesting practice is the primary on the contract, returns *all* partner data for *all* practices on the contract. Otherwise, returns only the partner data for the requesting practice. */
  getPartnerBillingAccountsForRelevantContract: Array<LabsGqlIPartnerBilling>;
  getPartnerPreferenceSets: Array<LabsGqlDoctorPreferences>;
  getPartnerPreferences?: Maybe<LabsGqlPartnerNotificationPreferences>;
  getPartnerScanners: LabsGqlAllScannersResponse;
  getPatient: LabsGqlPatientDto;
  getPatientDetails: LabsGqlPatientDetails;
  getPatientSharesByOrgId: Array<LabsGqlGetPatientSharesByOrgIdResult>;
  /** Get auth data to enable uploading photos for Perfect Shade. */
  getPerfectShadeAppLaunch: LabsGqlPerfectShadeAppLaunch;
  getPracticeContractV2ByOrganizationId?: Maybe<LabsGqlPracticeContract>;
  getPracticeDetails: LabsGqlPracticeDetails;
  getPracticeEquipmentData?: Maybe<LabsGqlEquipmentTrackerSnapshot>;
  getPracticeInteractions: LabsGqlPracticeInteractions;
  getPracticeOnboarding?: Maybe<LabsGqlPracticeOnboardingDto>;
  getPracticeOrderingPauseInformation?: Maybe<LabsGqlOrderingPausedPracticeDto>;
  getPracticeProductionOrderOverview: LabsGqlPracticeProductionOrderOverviewDto;
  getPracticeRequests: Array<LabsGqlPracticeRequestDto>;
  getPracticeStaffPreferencesById: LabsGqlDoctorPreferences;
  getPracticeTrainingDate?: Maybe<Scalars['DateTime']['output']>;
  getPreferencesByIds: Array<LabsGqlDoctorPreferences>;
  getPreferencesByStaffMemberIds: Array<LabsGqlDoctorPreferences>;
  /** This is for a POC - do not use it! */
  getProductCatalogEntry?: Maybe<LabsGqlProductCatalog>;
  /** This is for a POC - do not use it! */
  getProductCatalogEntryWithDetails?: Maybe<LabsGqlProductCatalogWithDetailsDto>;
  /** This is for a POC - do not use it! */
  getProductCatalogPrice?: Maybe<LabsGqlProductCatalogPrice>;
  getProductionOrderById: LabsGqlProductionOrderDto;
  getProductionPlanForOrder?: Maybe<LabsGqlProductionPlanDebuggerView>;
  getQCIssuesForOrder: Array<LabsGqlQcIssue>;
  getQCItemsForOrder: Array<LabsGqlQualityControlItemResult>;
  getQcWarningsForOrder: Array<LabsGqlQcWarningResult>;
  getReasonCodeAdditionalInfoRequestsAndCounts: Array<LabsGqlReasonCodeAdditionalInfoRequestCount>;
  getReasonCodeCategoriesAndCounts: Array<LabsGqlReasonCodeCategoryCount>;
  getRecentReviews: Array<LabsGqlIReviewSubmission>;
  getRefabCategoriesAndCounts: Array<LabsGqlRefabReasonCategoryCount>;
  getRefabRateBySkuForPartner: LabsGqlRefabRateBySkuForPartner;
  getRegistrationURL: Scalars['String']['output'];
  getRemovableManufacturingFiles: LabsGqlDesignOrderRemovableManufacturingFilesDto;
  getReview?: Maybe<LabsGqlIReviewSubmission>;
  getReviewTagCategories: Array<Scalars['String']['output']>;
  getReviews: Array<LabsGqlIReviewSubmission>;
  getRoutingRulesForOrder: LabsGqlRoutingRulesForOrderResult;
  getSavedSearchById?: Maybe<LabsGqlSavedSearch>;
  getSavedSearches: Array<LabsGqlSavedSearch>;
  getScanbodies: Array<LabsGqlScanbodyDto>;
  getScanbodiesByIds: Array<LabsGqlScanbodyDto>;
  getScanbodyRequestById?: Maybe<LabsGqlScanbodyRequestDto>;
  getScanbodyRequests: Array<LabsGqlScanbodyRequestDto>;
  getScanbodyRequestsInLast30Days: Array<LabsGqlScanbodyRequestDto>;
  getScanbodyRequestsInLastNDays: Array<LabsGqlScanbodyRequestDto>;
  getScannersByPractice: Array<LabsGqlScannerEntity>;
  getScheduledLiveCasePlanningRequestNotificationInfo: Array<LabsGqlLiveCasePlanningRequestNotificationInfo>;
  getScheduledLiveDdpReviewRequestNotificationInfo: Array<LabsGqlLiveCasePlanningRequestNotificationInfo>;
  getScheduledLiveLabTechMeetingRequestNotificationInfo: Array<LabsGqlLiveLabTechMeetingRequestNotificationInfo>;
  getSetupChampionBySalesforceID?: Maybe<LabsGqlSetupChampion>;
  /** Get a shade suggestion with photos by id. */
  getShadeSuggestionWithPhotosByShadeCaptureId?: Maybe<LabsGqlShadeSuggestionWithPhotosDto>;
  /** Get all shipments associated with a given order */
  getShipmentsForOrder: Array<LabsGqlShipmentDto>;
  getShippingAddressByPracticeID?: Maybe<LabsGqlShippingAddress>;
  getShortenedLink: Scalars['String']['output'];
  getSignedDownloadUrl: LabsGqlFileDownloadUrl;
  getSignedUploadUrl: LabsGqlFileUploadUrl;
  getSkusForPartner: LabsGqlSkusForPartner;
  getSmsContentsByNotificationLogId: LabsGqlGetSmsContentsByNotificationLogIdResult;
  getStaffActivatedAtFromPreferencesId?: Maybe<Scalars['String']['output']>;
  getStaffForUser: LabsGqlStaffMemberWithRelations;
  getStaffMemberById: LabsGqlStaffMemberWithRelations;
  getStaffMemberForImpersonate?: Maybe<LabsGqlStaffMemberWithRelations>;
  getStaffMemberOnboardingPreferences?: Maybe<LabsGqlStaffMemberOnboardingDto>;
  getStaffMembersByIds: Array<LabsGqlStaffMemberWithRelations>;
  getStaffMembersOnboardingPreferences?: Maybe<Array<LabsGqlStaffMemberOnboardingDto>>;
  getStageByPracticeId: LabsGqlPracticeStage;
  getStoragePathConfig: LabsGqlStoragePathConfig;
  getTicketSavedSearches: Array<LabsGqlTicketingSavedSearch>;
  getTieredPricingConfig?: Maybe<LabsGqlTieredPricingConfigEntityDto>;
  getTrainingStartTime?: Maybe<Scalars['DateTime']['output']>;
  getTryInFeedbackForOrder?: Maybe<LabsGqlTryInFeedbackDto>;
  getUnusedCaseReadinessCases: Array<LabsGqlCaseReadinessDto>;
  getUser?: Maybe<LabsGqlUserDto>;
  getUserByMobilePhoneNumber?: Maybe<LabsGqlUserDto>;
  getUserFromDrPrefId: LabsGqlUserDtoFragment;
  getUserReadEvents: LabsGqlUserReadEvents;
  /** @deprecated CST has been deprecated. Please do not use. */
  getZendeskAgentsFromGroup: Array<LabsGqlZendeskAgent>;
  googleJwtLogin: Array<LabsGqlUserLoginOption>;
  isAccountActivated: Scalars['Boolean']['output'];
  isOrderEligibleForLiveDesignReview: Scalars['Boolean']['output'];
  isOrderEligibleForLiveLabTechMeeting: Scalars['Boolean']['output'];
  labOrderCountByStatus: LabsGqlLabOrderStatusCount;
  labOrderRefabRates: LabsGqlLabOrderRefabRates;
  labPortalAutoNestingInProgressOrdersByListView: Array<LabsGqlLabPortalNestingInProgressOrderListItem>;
  labPortalDesignOrderCounts: LabsGqlLabPortalDesignOrderListCounts;
  labPortalDesignOrdersByListView: Array<LabsGqlLabPortalDesignOrderListItem>;
  labPortalDesignReviseOrdersByListView: Array<LabsGqlLabPortalDesignReviseOrderListItem>;
  labPortalDesignSendToFloorOrdersByListView: Array<LabsGqlLabPortalDesignSendToFloorOrderListItem>;
  labPortalDesignWaitForDandyOrdersByListView: Array<LabsGqlLabPortalDesignWaitForDandyOrderListItem>;
  labPortalIntakeOrderCounts: LabsGqlLabPortalIntakeOrderListCounts;
  labPortalIntakeOrdersWithDesignByListView: Array<LabsGqlLabPortalNewOrderListItem>;
  labPortalIntakeOrdersWithReturnByListView: Array<LabsGqlLabPortalNewOrderListItem>;
  labPortalIntakeOrdersWithViewedDocsByListView: Array<LabsGqlLabPortalNewOrderListItem>;
  labPortalIntakeOrdersWithoutDesignByListView: Array<LabsGqlLabPortalNewOrderListItem>;
  labPortalNestedIntakeOrdersByListView: Array<LabsGqlLabPortalNestedIntakeOrderListItem>;
  labPortalNewAcceptedOrdersWithoutLabSlipViewByListView: Array<LabsGqlLabPortalNewOrderListItem>;
  labPortalNewNeedsManualEntryOrdersByListView: Array<LabsGqlLabPortalNewNeedsManualEntryOrderListItem>;
  labPortalNewOrderCounts: LabsGqlLabPortalNewOrderListCounts;
  labPortalNewOrdersByListView: Array<LabsGqlLabPortalNewOrderListItem>;
  labPortalNewOrdersWithDesignByListView: Array<LabsGqlLabPortalNewOrderListItem>;
  labPortalNewOrdersWithReturnByListView: Array<LabsGqlLabPortalNewOrderListItem>;
  labPortalNewOrdersWithoutDesignByListView: Array<LabsGqlLabPortalNewOrderListItem>;
  labPortalOnHoldOrderCounts: LabsGqlLabPortalOnHoldOrderListCounts;
  labPortalOnHoldOrderCountsNullable: LabsGqlLabPortalOnHoldOrderListCountsNullable;
  labPortalOnHoldOrdersByListView: Array<LabsGqlLabPortalOnHoldOrderListItem>;
  labPortalOrderDetails: LabsGqlLabPortalOrderDetails;
  labPortalOrderUpdatesByListView: Array<LabsGqlLabPortalOrderUpdateListItem>;
  labPortalOverview: LabsGqlLabPortalOverviewViewModel;
  labPortalPullFromFloorUpdatesByListView: Array<LabsGqlLabPortalPullFromFloorUpdateListItem>;
  labPortalReturnsOrderCounts: LabsGqlLabPortalReturnsOrderListCounts;
  labPortalReturnsPendingOrdersByListView: Array<LabsGqlLabPortalReturnsPendingOrderListItem>;
  labPortalShipTodayLateOrdersByListView: Array<LabsGqlLabPortalShipTodayLateOrderListItem>;
  labPortalShipTodayOrderCounts: LabsGqlLabPortalShipTodayOrderListCounts;
  labPortalShipTodayOrdersByListView: Array<LabsGqlLabPortalShipTodayOrderListItem>;
  labPortalShipTodayOvernightOrdersByListView: Array<LabsGqlLabPortalShipTodayOvernightOrderListItem>;
  labPortalSidebarCounts: LabsGqlLabPortalSidebarCounts;
  labPortalUpdatesOrderCounts: LabsGqlLabPortalUpdatesOrderListCounts;
  lab_order: LabsGqlLabOrderWithEvents;
  labtracIdForOrder?: Maybe<Scalars['String']['output']>;
  /** Gets last full order for removable item of inputted unit type */
  lastMatchingOrderByUnitType?: Maybe<LabsGqlLab_Order>;
  listAbutmentPartByImplantAndScanbody: Array<LabsGqlAbutmentPartToImplantAndScanbodyDto>;
  listAbutmentParts: Array<LabsGqlAbutmentPartDto>;
  listAddresses: Array<LabsGqlPartnerDeliveryAddress>;
  listAddressesForContract: Array<LabsGqlPartnerDeliveryAddress>;
  listAutomationActions: Array<LabsGqlAutomationActionDefinition>;
  listCategories: Array<LabsGqlCategoryDto>;
  listChatMessages: Array<LabsGqlChatMessageDto>;
  listDesignOrderDoctorReviews: Array<LabsGqlDesignOrderDoctorReviewDto>;
  listDesignReviewFields: Array<LabsGqlDesignReviewFieldDto>;
  listDesignTrainingCoursesForEvaluation: Array<LabsGqlDesignTrainingCourseForEvaluation>;
  listDisplayRuleOptions: LabsGqlDisplayRuleOptions;
  listFilteredDesigners: LabsGqlListDesignersResult;
  listInvoiceCreditsForOrganization: Array<LabsGqlInvoiceCreditDto>;
  listInvoiceItemsForExport: Array<LabsGqlInvoiceItemDto>;
  listInvoiceItemsForInvoice: Array<LabsGqlInvoiceItemDto>;
  listInvoicePaymentsForExport: Array<LabsGqlInvoicePaymentWithInvoiceIdDto>;
  listInvoicesForOrganization: Array<LabsGqlInvoiceDto>;
  /** Given a practice id, retrieve invoices associated to the current active contract. If the requesting practice is the primary on the contract, returns *all* invoices for *all* practices on the contract. Otherwise, returns only the invoices for the requesting practice. */
  listInvoicesWithItemSummariesForActiveContract: Array<LabsGqlInvoiceWithItemSumaryDto>;
  listInvoicesWithItemSummariesForEnterprise: Array<LabsGqlInvoiceWithItemSumaryDto>;
  listInvoicesWithItemSummariesForOrganization: Array<LabsGqlInvoiceWithItemSumaryDto>;
  listLabtracProductCodes: Array<Scalars['String']['output']>;
  listManufacturerPreferences: Array<LabsGqlManufacturerNotificationPreferences>;
  listManufacturerProfiles: Array<LabsGqlManufacturerProfile>;
  listManufacturerTruckTimeSupportedCarriers: Array<LabsGqlManufacturerTruckTimeOption>;
  listManufacturerTruckTimes: Array<LabsGqlManufacturerTruckTimeEntity>;
  listMatchingDesignersForOrder: Array<LabsGqlListMatchingDesignersResult>;
  listMyDesignTasks: Array<LabsGqlWorkflowTask>;
  listMyDesignTrainingCourses: LabsGqlMyCourses;
  listOrderAutomations: Array<LabsGqlOrderAutomation>;
  listOrderFilters: Array<LabsGqlFilterDefinition>;
  listOrderReturns: Array<LabsGqlIOrderReturn>;
  listOrgUsers: Array<LabsGqlUserDto>;
  listOrganizationStaffBillingContacts: Array<LabsGqlStaffBillingContact>;
  listOrganizationSummaries: Array<LabsGqlOrganizationSummary>;
  listOrganizations: Array<LabsGqlOrganizationDto>;
  listOrganizationsWithAncestors: Array<LabsGqlOrganizationWithAncestors>;
  listOrganizationsWithAncestorsSummary: Array<LabsGqlOrganizationWithAncestorsSummary>;
  listOrganizationsWithDescendants: Array<LabsGqlOrganizationWithDescendants>;
  listOrganizationsWithDescendantsSummary: Array<LabsGqlOrganizationWithDescendantsSummary>;
  listOrganizationsWithHierarchySummary: Array<LabsGqlOrganizationWithHierarchySummary>;
  listOverdueInvoices: Array<LabsGqlInvoiceDto>;
  listPalateOrderFilters: Array<LabsGqlFilterDefinition>;
  /** @deprecated Partials fulfillments are no longer used for new orders */
  listPartialsFulfillmentNeedsFeedbackOrders: Array<LabsGqlLabOrderWithEvents>;
  /** @deprecated Partials fulfillments are no longer used for new orders */
  listPartialsFulfillmentNeedsReturnLabelOrders: Array<LabsGqlLabOrderWithEvents>;
  listPartnerBillingAccounts: Array<LabsGqlIPartnerBillingWithName>;
  listPartnerPreferences: Array<LabsGqlPartnerNotificationPreferences>;
  listPendingIteroOrdersForPortalNotifications: Array<LabsGqlIteroOrderNotification>;
  listPendingReviewLabOrderIds: Array<Scalars['String']['output']>;
  listPracticeBillingContactUsers: Array<LabsGqlBillingContactUser>;
  listPracticeContractsV2ByOrganizationId: Array<LabsGqlPracticeContract>;
  listPracticeNames: Array<LabsGqlPracticeNameAndPrimaryContactInfo>;
  listPracticePatients: Array<LabsGqlPatientDto>;
  listPracticePatientsWithSortTools: Array<LabsGqlPatientDto>;
  listPracticeSupport: Array<LabsGqlPracticeSupportDto>;
  listPrices: Array<LabsGqlIPrice>;
  listPricesWithPracticeCounts: Array<LabsGqlIPriceDefinitionResult>;
  /** This is for a POC - do not use it! */
  listProductCatalogEntries: Array<LabsGqlProductCatalog>;
  /** This is for a POC - do not use it! */
  listProductCatalogEntriesWithDetails: Array<LabsGqlProductCatalogWithDetailsDto>;
  /** This is for a POC - do not use it! */
  listProductCatalogPrices: Array<LabsGqlProductCatalogPrice>;
  listQCItemConfigs: Array<LabsGqlQcItemConfigDto>;
  listQcIssues: Array<LabsGqlQcIssue>;
  listReasonCodes: Array<LabsGqlReasonCodeDto>;
  listRefabReasons: Array<LabsGqlRefabReasonDto>;
  listRefundCategories?: Maybe<Array<LabsGqlRefundCategoryDto>>;
  listReviewTags: Array<LabsGqlReviewTag>;
  listSalesforcePriceNameMappings: Array<Scalars['String']['output']>;
  listSalesforcePriceNameMappingsForLabPrices: Array<Scalars['String']['output']>;
  listShipments: Array<LabsGqlIShipment>;
  listSurgicalGuideDrillKits: LabsGqlSurgicalGuideDrillKit;
  listThreeshapeDmes: Array<LabsGqlThreeshapeDmeDto>;
  listUserOrganizations: Array<LabsGqlOrganizationDto>;
  listUserPositions: Array<LabsGqlStaffMemberDto>;
  listUsers: Array<LabsGqlUserDto>;
  listUsersPositions: Array<LabsGqlStaffMemberDto>;
  listWorkflowStepDefinitions: Array<LabsGqlWorkflowStepDefinition>;
  listZeroOutModelsBulkPricingJobs: Array<LabsGqlOrderPriceAdjustmentBulkJobStatus>;
  loadOrderFilterOptions: Array<LabsGqlICustomFieldOption>;
  /** Used for the Updates count in ManufacturerSidebar, must stay aligned with orders in ManufacturerTaskListsUpdates */
  manufacturerUpdatesScreenCount: Scalars['Int']['output'];
  needsBillingSummaryEmail: Scalars['Boolean']['output'];
  needsBillingSummaryForContract: Array<LabsGqlOrganizationNeedsSummaryEmailDto>;
  orderEntriesByListView: Array<LabsGqlListViewEntry>;
  orderInboxItems: Array<LabsGqlOrderInboxItem>;
  orderWorkflowTasksByListView: Array<LabsGqlWorkflowTask>;
  orderWorkflowTasksCount: LabsGqlWorkflowTaskStatusCounts;
  /** @deprecated This endpoint has been deprecated since 10/27/2023. It always will return [], re-added only to support machines still running the BiteWing software. */
  ordersByActiveDesignTask: Array<LabsGqlActiveDesignTaskListPayload>;
  ordersByIds: Array<LabsGqlLabOrderWithEvents>;
  ordersByListView: Array<LabsGqlLabOrderWithEvents>;
  ordersByScanId: Array<LabsGqlLabOrderWithEvents>;
  ordersForChairsideCache: Array<LabsGqlLabOrderWithEvents>;
  ordersForLabSlipByIds: Array<LabsGqlLabOrderForLabSlip>;
  ordersMatchingAutomationFilterCount?: Maybe<Scalars['Int']['output']>;
  panLabelsByLabOrderIds: Array<LabsGqlPanLabelUnion>;
  parse_3oxz_3ox: LabsGqlThree_Shape_3ox;
  parse_3oxz_case: LabsGqlThree_Shape_Case;
  parse_3oxz_from_filepath: LabsGqlThree_Shape_3oxz_Data;
  parse_3oxz_from_files: LabsGqlThree_Shape_3oxz_Data;
  partnerBillingCurrentState: LabsGqlPartnerBillingCurrentState;
  partnerPrices: Array<LabsGqlPartnerPriceResult>;
  partnerSessionForScanner: LabsGqlScannerPartnerSession;
  passwordLogin: Array<LabsGqlUserLoginOption>;
  patientPortalPatientShare: LabsGqlPatientShare;
  patient_portal_lab_order: LabsGqlPatientPortalLabOrder;
  pendingAlignerCaseForScanExport?: Maybe<LabsGqlPendingAlignerCaseDto>;
  pendingReturnForOrder?: Maybe<LabsGqlIOrderReturn>;
  pendingReviewForOrder?: Maybe<LabsGqlIPendingReview>;
  phoneNumberPasswordLogin: Array<LabsGqlUserLoginOption>;
  practiceInboxActiveOrders: LabsGqlInboxActiveOrders;
  practiceOrderCountsOverview: LabsGqlPracticeOverviewOrderCounts;
  practiceOrderIdsByStatus: Array<LabsGqlOnlyId>;
  practiceOrdersCount: Scalars['Int']['output'];
  /** Returns the set of IDs that matches a practice search request. */
  practiceSearchOrderIds: Array<LabsGqlOrderSearchPracticeSearchOrderIdsResult>;
  previewCreditAndRefundOptionsForOrderCancellation: LabsGqlPreviewCreditAndRefundOptionsForOrderCancellation;
  previewPracticePendingInvoiceItems: Array<LabsGqlPendingInvoiceItemDto>;
  /** Given a practice id, retrieve the current active contract for that practice. If the requesting practice is the primary on the contract, returns *all* pending invoice items for *all* practices on the contract. Otherwise, returns only the pending invoice items for the requesting practice. */
  previewPracticePendingInvoiceItemsForActiveContract: Array<LabsGqlPendingInvoiceItemForOrganizationDto>;
  previewProductionPlanForLabOrder: LabsGqlProductionPlanDebuggerView;
  previewUpdateOrderSla: LabsGqlSlaPreviewResult;
  previewUpdateRushConfiguration: LabsGqlSlaPreviewResult;
  reasonCodesForOrderItem: Array<LabsGqlLabOrderReasonCodeOption>;
  refabReasonsForOrderItems: Array<LabsGqlLabOrderRefabReasonOption>;
  refreshToken: Scalars['String']['output'];
  resolveDefaultActionTimes: LabsGqlActionTypeActionTimes;
  routingTargetDistributionRules: Array<LabsGqlRoutingTargetDistributionRule>;
  salesOrderGetById: LabsGqlSalesOrderDto;
  salesOrderGetBySplitId: Array<LabsGqlSalesOrderDto>;
  salesOrderGetIdByOrderNumber: LabsGqlSalesOrderIdOnlyDto;
  /** Returns all previous and future sales orders in the refab chain for a provided sales order id, ordered by oldest to newest. */
  salesOrderGetInRefabChain: Array<LabsGqlSalesOrderDto>;
  scan_file: LabsGqlScan_Export;
  scan_files: Array<LabsGqlScan_Export>;
  scan_files_for_patient: Array<LabsGqlScan_Export>;
  searchPracticePatients: Array<LabsGqlPatientDto>;
  singleAddress: LabsGqlPartnerDeliveryAddress;
  slimOrderInboxItems: Array<LabsGqlSlimOrderInboxItem>;
  staffMemberForPreferences?: Maybe<LabsGqlMigratedStaffResult>;
  tags: Array<LabsGqlTag>;
  taskAssignmentTimeline: Array<LabsGqlTaskAssignmentTimelineEntry>;
  /** Get a tat by id. */
  tatGetById?: Maybe<LabsGqlTat>;
  /** Get a tat by order id. */
  tatGetByOrderId?: Maybe<LabsGqlTat>;
  /** List add-on lead times. */
  tatListAddOnLeadTimes: Array<LabsGqlTatAddOnLeadTimeDto>;
  /** List item lead time overrides. */
  tatListItemLeadTimeOverrides: Array<LabsGqlTatItemLeadTimeOverrideDto>;
  /** List item lead times. */
  tatListItemLeadTimes: Array<LabsGqlTatItemLeadTimeDto>;
  /** Make a plan for a set of items. */
  tatMakePlan: LabsGqlTatNonNullablePlan;
  timelineItems: Array<LabsGqlTimelineItemUnion>;
  timelineV2Items: Array<LabsGqlOrderTimelineV2Item>;
  uploadedAlignerPhotoGCSPaths: Array<Scalars['String']['output']>;
  uploadedAlignerPhotoTypes: Array<LabsGqlAlignerCasePhotoType>;
  userExistsWithMobileNumber: Scalars['Boolean']['output'];
  validateDeliveryAddress: LabsGqlDeliveryAddressValidationResult;
  /** Runs design validation on the specified design path for the specified order. Should be ran prior to attempting to complete a design task. */
  validateDesignOrderRevisionUpload: LabsGqlDesignOrderRevisionValidationResult;
  /**
   * Returns whether or not a waxup should be recommended based on cart contents
   * @deprecated Not used anymore.
   */
  waxupRecommendationV2: LabsGqlCartRecommendationV2;
  zendeskLSRTicketForScan?: Maybe<LabsGqlZendeskLsrTicketDetails>;
  zendeskUser: LabsGqlZendeskChatUser;
};


export type LabsGqlQueryActionTypesWithCategoryInfoArgs = {
  filters: LabsGqlActionTypeFilters;
};


export type LabsGqlQueryAlignerPhotoUploadedArgs = {
  scan_export_id: Scalars['String']['input'];
};


export type LabsGqlQueryAreItemsEligibleForLiveDesignReviewArgs = {
  items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
};


export type LabsGqlQueryBiasRulesForDoctorArgs = {
  doctor_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryBulkLoadPracticePricesArgs = {
  partnerIds: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryCalculateActiveContractAggregateSpendOverPeriodArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  periodEnd: Scalars['DateTime']['input'];
  periodStart: Scalars['DateTime']['input'];
};


export type LabsGqlQueryCalculateCreditCardProcessingFeeForAmountArgs = {
  amount_cents: Scalars['Float']['input'];
};


export type LabsGqlQueryCalculateCreditCardProcessingFeeForInvoiceAmountsArgs = {
  data: Array<LabsGqlCreditCardFeePerInvoiceInput>;
};


export type LabsGqlQueryCalculatePracticeAggregateSpendOverPeriodArgs = {
  partnerId: Scalars['String']['input'];
  periodEnd: Scalars['DateTime']['input'];
  periodStart: Scalars['DateTime']['input'];
};


export type LabsGqlQueryCalculatePracticeInvoicedSpendOverPeriodArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  periodEnd: Scalars['DateTime']['input'];
  periodStart: Scalars['DateTime']['input'];
};


export type LabsGqlQueryCalendlyGetNextAvailableLdrTimeArgs = {
  orderId?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};


export type LabsGqlQueryCanDeleteDeliveryAddressArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryCapacityRulesForManufacturerArgs = {
  manufacturer_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryCheckCsScheduleArgs = {
  args: LabsGqlCheckCsScheduleArgs;
};


export type LabsGqlQueryCheckPracticeAdminsArgs = {
  partner_id: Scalars['String']['input'];
  staff_member_id: Scalars['String']['input'];
};


export type LabsGqlQueryDebugDesignerAutoAssignmentArgs = {
  designerId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryDebugRoutingDecisionArgs = {
  jitConfigType: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryDeleteFileArgs = {
  filePath: LabsGqlFilePathAndBucket;
};


export type LabsGqlQueryDesignRulesForManufacturerArgs = {
  manufacturer_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryDesignStaffDetailedStatusArgs = {
  user_id: Scalars['String']['input'];
};


export type LabsGqlQueryDesignStaffStatusBulkArgs = {
  user_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryEstimatedDeliveryDateArgs = {
  case_id?: InputMaybe<Scalars['String']['input']>;
  doctor_id?: InputMaybe<Scalars['String']['input']>;
  enable_delivery_options?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<LabsGqlLabOrderItemInput>>;
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
  mailing_address_id?: InputMaybe<Scalars['String']['input']>;
  waxup?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlQueryExchangeLegacyTokenArgs = {
  nativeIdToken: Scalars['String']['input'];
};


export type LabsGqlQueryExchangeMultiAuthLegacyTokenArgs = {
  nativeIdToken: Scalars['String']['input'];
};


export type LabsGqlQueryFetchAllPracticeEmployeesArgs = {
  practice_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryFetchOverdueInvoicesArgs = {
  partnerId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryFetchPartnerBillingChangelogArgs = {
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryFetchPracticeStaffUsersArgs = {
  include_deleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlQueryFilteredTicketsArgs = {
  input: LabsGqlTicketFilterInput;
};


export type LabsGqlQueryFindCrmTicketsArgs = {
  created_greater_than?: InputMaybe<Scalars['String']['input']>;
  custom_fields?: InputMaybe<Array<LabsGqlCrmTicketCustomFieldValueInput>>;
  field_value?: InputMaybe<Scalars['String']['input']>;
  requester?: InputMaybe<Scalars['String']['input']>;
  solved_greater_than?: InputMaybe<Scalars['String']['input']>;
  sort_results?: InputMaybe<Scalars['Boolean']['input']>;
  status_less_than?: InputMaybe<LabsGqlCrmTicketStatus>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  ticket_type?: InputMaybe<LabsGqlCrmTicketForm>;
};


export type LabsGqlQueryFindCrmUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryFindLastChatForEntityArgs = {
  entity_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetAbutmentPartArgs = {
  part_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetActionCategoryByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetActionTypeByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetActionTypesArgs = {
  category_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetActiveContractAndAssociatedOrgsByOrganizationIdArgs = {
  organization_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetActiveInternalDesignTaskArgs = {
  labOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetActiveInternalDesignTasksArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetActiveScanReviewByLabOrderIdArgs = {
  lab_order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetActiveVideoDdpReviewRequestArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetAlignerRetainerSummaryForOrderArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetAlignerTreatmentPlanArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetAllBillingCreditCategoriesArgs = {
  include_archived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlQueryGetAllDesignOrderAlignerTreatmentPlansArgs = {
  labOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetAllDesignOrderDesignRevisionsArgs = {
  labOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetAllOrganizationsCsvArgs = {
  filter?: InputMaybe<LabsGqlGetAllOrganizationsCsvFilter>;
};


export type LabsGqlQueryGetAllPendingVariantFeedbackArgs = {
  data: LabsGqlGetAllPendingVariantFeedbackInput;
};


export type LabsGqlQueryGetAllPendingVariantFeedbackForPracticeArgs = {
  data: LabsGqlGetAllPendingVariantFeedbackForPracticeInput;
};


export type LabsGqlQueryGetAllPreferenceSetsArgs = {
  include_deleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlQueryGetAllScannersWithSearchArgs = {
  search: Scalars['String']['input'];
};


export type LabsGqlQueryGetBatchShipManifestArgs = {
  shippingManufacturerId: Scalars['String']['input'];
};


export type LabsGqlQueryGetBillingContactEmailsForInvoicesArgs = {
  invoiceIds: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetBillingCreditCategoriesByIdArgs = {
  category_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetCrmChatConversationArgs = {
  conversation_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetCrmChatUserArgs = {
  user_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetCrmPracticeArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetCrmPracticeMetadataArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetCrmTicketArgs = {
  ticket_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetCrmTicketCommentAttachmentArgs = {
  attachment_url: Scalars['String']['input'];
};


export type LabsGqlQueryGetCrmTicketCommentsArgs = {
  page_num?: InputMaybe<Scalars['Int']['input']>;
  ticket_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetCadSmileLibraryByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetCalendlySchedulingUrlArgs = {
  order_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetCanceledLabOrdersArgs = {
  date_range?: InputMaybe<LabsGqlLabCaseDateRangeInput>;
};


export type LabsGqlQueryGetCapacityRuleLoadsArgs = {
  ruleIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type LabsGqlQueryGetCartDeliveryOptionsArgs = {
  case_id?: InputMaybe<Scalars['String']['input']>;
  doctor_id?: InputMaybe<Scalars['String']['input']>;
  enable_delivery_options?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<LabsGqlLabOrderItemInput>>;
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
  mailing_address_id?: InputMaybe<Scalars['String']['input']>;
  waxup?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlQueryGetCaseReadinessArgs = {
  patient_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetChairsideFirebaseDownloadUrlArgs = {
  chairsideCaseId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};


export type LabsGqlQueryGetChairsideFirebaseUploadUrlArgs = {
  chairsideCaseId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  uploadType: Scalars['String']['input'];
};


export type LabsGqlQueryGetCompletedStagesForOrderArgs = {
  labOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetConnectedPreferencesByEmailAndPhoneArgs = {
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type LabsGqlQueryGetContractAndAssociatedOrgsByOrganizationIdArgs = {
  contract_id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetCreditByIdArgs = {
  creditId: Scalars['String']['input'];
};


export type LabsGqlQueryGetCreditCardProcessingFeeAmountCentsForInvoiceArgs = {
  invoice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetCreditCardProcessingFeeAmountCentsForInvoiceIdsArgs = {
  invoice_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetDesignFileDmeListArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetDesignOrderDesignRevisionByIdArgs = {
  revisionId: Scalars['String']['input'];
};


export type LabsGqlQueryGetDesignOrderDesignRevisionsByTaskIdsArgs = {
  taskIds: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetDesignQaEvaluationByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetDesignQaEvaluationsByOrderIdArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetDesignStaffArgs = {
  user_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetDesignStaffFocusAreasByDesignerArgs = {
  designer_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetDesignTrainingEnrolledCourseArgs = {
  courseId: Scalars['String']['input'];
};


export type LabsGqlQueryGetDoctorPreferencesByIdArgs = {
  doctor_id: Scalars['String']['input'];
  include_deleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlQueryGetDoctorPreferencesByTokenArgs = {
  token: Scalars['String']['input'];
};


export type LabsGqlQueryGetDoctorRequestArgs = {
  doctorRequestId: Scalars['String']['input'];
};


export type LabsGqlQueryGetDoesUserPreferPasswordLoginArgs = {
  mobile_phone_number: Scalars['String']['input'];
  staff_member_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetEmailPreviewByNotificationLogIdArgs = {
  notification_log_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetFeatureFlagValueByOrganizationIdArgs = {
  params: LabsGqlGetFeatureFlagValueByOrganizationIdRequest;
};


export type LabsGqlQueryGetFulfilledVideoDdpReviewRequestArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetFullyResolvedPracticeContractsV2ByPracticeIdArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetHoldRemovalSuggestedStageEndDateArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetImpersonationTokenArgs = {
  expires_in_seconds?: InputMaybe<Scalars['Float']['input']>;
  organization_id: Scalars['String']['input'];
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetImplantByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetImplantTypesArgs = {
  typeSystems?: InputMaybe<Array<LabsGqlLabsGqlQueryGetImplantTypeInput>>;
  withDeleted: Scalars['Boolean']['input'];
};


export type LabsGqlQueryGetImplantTypesLiteArgs = {
  withDeleted: Scalars['Boolean']['input'];
};


export type LabsGqlQueryGetInjectionMoldFilesArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetInvoiceByIdArgs = {
  invoiceId: Scalars['String']['input'];
};


export type LabsGqlQueryGetInvoiceHistoryItemsArgs = {
  invoiceId: Scalars['String']['input'];
};


export type LabsGqlQueryGetIsFcmTrainingCompleteArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetIsPracticeByodArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetIteroCompaniesByPracticeIdsArgs = {
  practice_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetIteroCompanyByPracticeIdArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetIteroUserByStaffIdArgs = {
  staff_member_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetIteroUsersByStaffIdsArgs = {
  staff_member_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetLabAssignmentsByOrderIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetLabCaseLedgerDataArgs = {
  date_range?: InputMaybe<LabsGqlLabPriceLedgerDateRangeInput>;
};


export type LabsGqlQueryGetLabSalesOrderByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetLabSalesOrderIdByOrderNumberArgs = {
  order_number: Scalars['String']['input'];
};


export type LabsGqlQueryGetLabSalesOrdersBySplitIdArgs = {
  split_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetLabSalesOrdersInRefabChainArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetLabsPaymentMethodsArgs = {
  partnerId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetLastInvoiceEmailForOrganizationArgs = {
  organizationId: Scalars['String']['input'];
};


export type LabsGqlQueryGetLatestDesignOrderDesignRevisionArgs = {
  labOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetLatestDesignOrderDesignRevisionsForOrdersArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetLatestDoctorVisibleDesignOrderAlignerTreatmentPlanArgs = {
  labOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetLatestManufacturerFilesForOrdersArgs = {
  labOrderIds: Array<Scalars['String']['input']>;
  return_design_metadata?: InputMaybe<Scalars['Boolean']['input']>;
  rotated: Scalars['Boolean']['input'];
};


export type LabsGqlQueryGetLatestMergeAndStitchFilesForOrderArgs = {
  labOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetLegacyMultiFulfillmentInfoByOrderIdArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetLiveDdpReviewRequestArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetManufacturerPreferencesPreferencesArgs = {
  manufacturer_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetManufacturerProfileArgs = {
  manufacturer_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetManufacturingBookingByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetMembershipLoginArgs = {
  mobile_phone_number: Scalars['String']['input'];
};


export type LabsGqlQueryGetMostFrequentlyUsedImplantManufacturersArgs = {
  doctor_preferences_id: Scalars['String']['input'];
  max_results?: InputMaybe<Scalars['Int']['input']>;
};


export type LabsGqlQueryGetNextInvoiceStatusArgs = {
  organizationId: Scalars['String']['input'];
};


export type LabsGqlQueryGetNextInvoiceStatusForContractOrgsArgs = {
  organizationId: Scalars['String']['input'];
};


export type LabsGqlQueryGetNextPartnerOrderNumberArgs = {
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetNonDoctorStaffByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetNonDoctorStaffByPartnerIdArgs = {
  include_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetNonFavoritedSavedSearchesArgs = {
  textFilter?: InputMaybe<Scalars['String']['input']>;
  type: LabsGqlCustomSearchType;
};


export type LabsGqlQueryGetNotificationLogsWithEventsArgs = {
  doctor_preferences_id?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Float']['input'];
  rows_per_page: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};


export type LabsGqlQueryGetOnboardingStepsArgs = {
  is_chairside: Scalars['Boolean']['input'];
};


export type LabsGqlQueryGetOnboardingStepsV2Args = {
  is_chairside: Scalars['Boolean']['input'];
};


export type LabsGqlQueryGetOrCreatePracticeSupportArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderAdminListViewEntriesByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type LabsGqlQueryGetOrderAutomateResultArgs = {
  automate_id?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderByQcSearchArgs = {
  search_term: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderHasPendingPaymentArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderIdByStackCodeArgs = {
  stackCode: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderIdFromOrderNumberArgs = {
  order_number: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderLedgerArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderPracticeListViewEntriesByIdsArgs = {
  ids: Array<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetOrderPriceEntitiesByOrderIdArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderPriceEntitiesByOrderIdsArgs = {
  order_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetOrderPriceHistoryItemsArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderPricesByOrderIdArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderSlaChangesArgs = {
  order_id: Scalars['String']['input'];
  showAllEvents: Scalars['Boolean']['input'];
};


export type LabsGqlQueryGetOrderSlaStageStatusesArgs = {
  order_id: Scalars['ID']['input'];
};


export type LabsGqlQueryGetOrderTicketByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderTicketsArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderTrackerEntriesArgs = {
  fulfillment_index?: InputMaybe<Scalars['Float']['input']>;
  order_id: Scalars['String']['input'];
  timezone_offset_minutes: Scalars['Int']['input'];
};


export type LabsGqlQueryGetOrderTrackingHoldMessageArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrderTrackingHoldTitleArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrdersForManufacturerArgs = {
  manufacturer_id: Scalars['String']['input'];
  updated_before?: InputMaybe<Scalars['DateTime']['input']>;
  updated_since?: InputMaybe<Scalars['DateTime']['input']>;
};


export type LabsGqlQueryGetOrdersForShipmentArgs = {
  shipmentId: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrgAdminsArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrgPrimaryAdminArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrganizationByExternalAccountArgs = {
  external_account: LabsGqlExternalAccountRelationshipInput;
};


export type LabsGqlQueryGetOrganizationPriceConfigsArgs = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
  organization_type?: InputMaybe<LabsGqlOrganizationTypeForPricing>;
  price_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetOrganizationWithAncestorsArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrganizationWithDescendantsArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrganizationWithDescendantsAndStaffArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOrganizationsForUserArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetOutstandingBalanceCentsArgs = {
  organizationId: Scalars['String']['input'];
};


export type LabsGqlQueryGetOwnImpersonatedShortenedLinkArgs = {
  initial_url: Scalars['String']['input'];
};


export type LabsGqlQueryGetPaginatedOrganizationsArgs = {
  filter?: InputMaybe<LabsGqlGetPaginatedOrganizationsFilter>;
  page: Scalars['Float']['input'];
  rows_per_page: Scalars['Float']['input'];
};


export type LabsGqlQueryGetPaginatedUsersArgs = {
  filter?: InputMaybe<LabsGqlGetPaginatedUsersFilter>;
  page: Scalars['Float']['input'];
  rows_per_page: Scalars['Float']['input'];
};


export type LabsGqlQueryGetPartnerBillingAccountArgs = {
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPartnerBillingAccountsForRelevantContractArgs = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetPartnerPreferenceSetsArgs = {
  include_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPartnerPreferencesArgs = {
  partner_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetPartnerScannersArgs = {
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPatientArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPatientDetailsArgs = {
  patient_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPatientSharesByOrgIdArgs = {
  organization_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPerfectShadeAppLaunchArgs = {
  caseId: Scalars['String']['input'];
  shadeCaptureId: Scalars['String']['input'];
  unns: Array<Scalars['Int']['input']>;
};


export type LabsGqlQueryGetPracticeContractV2ByOrganizationIdArgs = {
  organization_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPracticeDetailsArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPracticeEquipmentDataArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPracticeInteractionsArgs = {
  customer_names?: InputMaybe<Array<Scalars['String']['input']>>;
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPracticeOrderingPauseInformationArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPracticeProductionOrderOverviewArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPracticeRequestsArgs = {
  organization_id: Scalars['String']['input'];
  sku: LabsGqlOrderItemSkuType;
  types: Array<LabsGqlPracticeRequestType>;
};


export type LabsGqlQueryGetPracticeStaffPreferencesByIdArgs = {
  practice_staff_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPracticeTrainingDateArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetPreferencesByIdsArgs = {
  pref_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetPreferencesByStaffMemberIdsArgs = {
  staff_member_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetProductCatalogEntryArgs = {
  id: Scalars['ID']['input'];
};


export type LabsGqlQueryGetProductCatalogEntryWithDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type LabsGqlQueryGetProductCatalogPriceArgs = {
  catalogId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type LabsGqlQueryGetProductionOrderByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetProductionPlanForOrderArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetQcIssuesForOrderArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetQcItemsForOrderArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetQcWarningsForOrderArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetRecentReviewsArgs = {
  doctor_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetRefabRateBySkuForPartnerArgs = {
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetRegistrationUrlArgs = {
  contract_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetRemovableManufacturingFilesArgs = {
  designOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetReviewArgs = {
  lab_order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetReviewsArgs = {
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetRoutingRulesForOrderArgs = {
  internalDesign?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturerId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetSavedSearchByIdArgs = {
  search_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetSavedSearchesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  type: LabsGqlCustomSearchType;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlQueryGetScanbodiesArgs = {
  withDeleted: Scalars['Boolean']['input'];
};


export type LabsGqlQueryGetScanbodiesByIdsArgs = {
  scanbody_ids: Array<Scalars['ID']['input']>;
};


export type LabsGqlQueryGetScanbodyRequestByIdArgs = {
  scanbody_request_id: Scalars['ID']['input'];
};


export type LabsGqlQueryGetScanbodyRequestsArgs = {
  connection?: InputMaybe<Scalars['String']['input']>;
  include_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  only_fulfilled?: InputMaybe<Scalars['Boolean']['input']>;
  system?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetScanbodyRequestsInLastNDaysArgs = {
  date_range?: Scalars['String']['input'];
};


export type LabsGqlQueryGetScannersByPracticeArgs = {
  practiceId: Scalars['String']['input'];
};


export type LabsGqlQueryGetSetupChampionBySalesforceIdArgs = {
  salesforce_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetShadeSuggestionWithPhotosByShadeCaptureIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetShipmentsForOrderArgs = {
  includeStatuses?: InputMaybe<Array<LabsGqlShipmentStatus>>;
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryGetShippingAddressByPracticeIdArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetShortenedLinkArgs = {
  initial_url: Scalars['String']['input'];
};


export type LabsGqlQueryGetSignedDownloadUrlArgs = {
  bucket: Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  timeoutInMinutes?: InputMaybe<Scalars['Int']['input']>;
};


export type LabsGqlQueryGetSignedUploadUrlArgs = {
  bucket: Scalars['String']['input'];
  path: Scalars['String']['input'];
  timeoutInMinutes?: InputMaybe<Scalars['Int']['input']>;
};


export type LabsGqlQueryGetSkusForPartnerArgs = {
  partner_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetSmsContentsByNotificationLogIdArgs = {
  notification_log_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetStaffActivatedAtFromPreferencesIdArgs = {
  preferences_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetStaffForUserArgs = {
  organization_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetStaffMemberByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryGetStaffMemberForImpersonateArgs = {
  organization_id: Scalars['String']['input'];
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetStaffMemberOnboardingPreferencesArgs = {
  staff_member_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetStaffMembersByIdsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetStaffMembersOnboardingPreferencesArgs = {
  staff_member_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryGetStageByPracticeIdArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetStoragePathConfigArgs = {
  paths: Array<Scalars['String']['input']>;
  uploadType: Scalars['String']['input'];
  whichConfig: Scalars['String']['input'];
};


export type LabsGqlQueryGetTieredPricingConfigArgs = {
  practice_contract_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetTrainingStartTimeArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetTryInFeedbackForOrderArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mobile_phone_number?: InputMaybe<Scalars['String']['input']>;
  salesforce_contact_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryGetUserByMobilePhoneNumberArgs = {
  mobile_phone_number: Scalars['String']['input'];
};


export type LabsGqlQueryGetUserFromDrPrefIdArgs = {
  preferences_id: Scalars['String']['input'];
};


export type LabsGqlQueryGetZendeskAgentsFromGroupArgs = {
  group_name?: InputMaybe<LabsGqlCstGroupNames>;
};


export type LabsGqlQueryGoogleJwtLoginArgs = {
  jwt: Scalars['String']['input'];
};


export type LabsGqlQueryIsAccountActivatedArgs = {
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryIsOrderEligibleForLiveDesignReviewArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryLabOrderCountByStatusArgs = {
  doctor_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  doctor_preferences_id?: InputMaybe<Scalars['String']['input']>;
  partner_id?: InputMaybe<Scalars['String']['input']>;
  product_lines?: InputMaybe<Array<LabsGqlProductLine>>;
};


export type LabsGqlQueryLabOrderRefabRatesArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryLabPortalAutoNestingInProgressOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalDesignOrderCountsArgs = {
  columns: Array<Scalars['String']['input']>;
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalDesignOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalDesignReviseOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalDesignSendToFloorOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalDesignWaitForDandyOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalIntakeOrderCountsArgs = {
  columns: Array<Scalars['String']['input']>;
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalIntakeOrdersWithDesignByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalIntakeOrdersWithReturnByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalIntakeOrdersWithViewedDocsByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalIntakeOrdersWithoutDesignByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalNestedIntakeOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalNewAcceptedOrdersWithoutLabSlipViewByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalNewNeedsManualEntryOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalNewOrderCountsArgs = {
  columns: Array<Scalars['String']['input']>;
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalNewOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalNewOrdersWithDesignByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalNewOrdersWithReturnByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalNewOrdersWithoutDesignByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalOnHoldOrderCountsArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalOnHoldOrderCountsNullableArgs = {
  columns: Array<Scalars['String']['input']>;
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalOnHoldOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalOrderDetailsArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryLabPortalOrderUpdatesByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalOverviewArgs = {
  pages: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryLabPortalPullFromFloorUpdatesByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalReturnsOrderCountsArgs = {
  columns: Array<Scalars['String']['input']>;
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalReturnsPendingOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalShipTodayLateOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalShipTodayOrderCountsArgs = {
  columns: Array<Scalars['String']['input']>;
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalShipTodayOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalShipTodayOvernightOrdersByListViewArgs = {
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLabPortalSidebarCountsArgs = {
  pages: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryLabPortalUpdatesOrderCountsArgs = {
  columns: Array<Scalars['String']['input']>;
  criteria: Array<LabsGqlFilterCriteriaSubmissionInput>;
  labPortalSort?: InputMaybe<LabsGqlLabPortalListOrdersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryLab_OrderArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryLabtracIdForOrderArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryLastMatchingOrderByUnitTypeArgs = {
  doctor_id: Scalars['String']['input'];
  unit_type: Scalars['String']['input'];
};


export type LabsGqlQueryListAbutmentPartByImplantAndScanbodyArgs = {
  implantToScanbodyId: Scalars['String']['input'];
};


export type LabsGqlQueryListAbutmentPartsArgs = {
  withArchived: Scalars['Boolean']['input'];
};


export type LabsGqlQueryListAddressesArgs = {
  partner_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryListCategoriesArgs = {
  kind: LabsGqlCategoryKind;
};


export type LabsGqlQueryListChatMessagesArgs = {
  entity_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryListDesignOrderDoctorReviewsArgs = {
  labOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryListDesignReviewFieldsArgs = {
  type?: InputMaybe<LabsGqlDesignReviewFieldType>;
};


export type LabsGqlQueryListDesignTrainingCoursesForEvaluationArgs = {
  query: Scalars['String']['input'];
};


export type LabsGqlQueryListFilteredDesignersArgs = {
  input: LabsGqlDesignerFilters;
};


export type LabsGqlQueryListInvoiceCreditsForOrganizationArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['String']['input'];
};


export type LabsGqlQueryListInvoiceItemsForExportArgs = {
  invoiceIds: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryListInvoiceItemsForInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
};


export type LabsGqlQueryListInvoicePaymentsForExportArgs = {
  invoiceIds: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryListInvoicesForOrganizationArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryListInvoicesWithItemSummariesForActiveContractArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryListInvoicesWithItemSummariesForEnterpriseArgs = {
  enterpriseId: Scalars['String']['input'];
};


export type LabsGqlQueryListInvoicesWithItemSummariesForOrganizationArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryListManufacturerTruckTimesArgs = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryListMatchingDesignersForOrderArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryListMyDesignTasksArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sort: LabsGqlWorkflowTaskSortParam;
};


export type LabsGqlQueryListOrgUsersArgs = {
  filter?: InputMaybe<LabsGqlListOrgUsersFilter>;
};


export type LabsGqlQueryListOrganizationStaffBillingContactsArgs = {
  organization_id: Scalars['String']['input'];
};


export type LabsGqlQueryListOrganizationSummariesArgs = {
  filter?: InputMaybe<LabsGqlListOrgsFilter>;
};


export type LabsGqlQueryListOrganizationsArgs = {
  filter?: InputMaybe<LabsGqlListOrgsFilter>;
};


export type LabsGqlQueryListOrganizationsWithAncestorsArgs = {
  filter?: InputMaybe<LabsGqlListOrgsFilter>;
};


export type LabsGqlQueryListOrganizationsWithAncestorsSummaryArgs = {
  filter?: InputMaybe<LabsGqlListOrgsFilter>;
};


export type LabsGqlQueryListOrganizationsWithDescendantsArgs = {
  filter?: InputMaybe<LabsGqlListOrgsFilter>;
};


export type LabsGqlQueryListOrganizationsWithDescendantsSummaryArgs = {
  filter?: InputMaybe<LabsGqlListOrgsFilter>;
};


export type LabsGqlQueryListOrganizationsWithHierarchySummaryArgs = {
  filter?: InputMaybe<LabsGqlListOrgsFilter>;
};


export type LabsGqlQueryListOverdueInvoicesArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryListPalateOrderFiltersArgs = {
  partnerId: Scalars['String']['input'];
};


export type LabsGqlQueryListPartialsFulfillmentNeedsFeedbackOrdersArgs = {
  delivered_after?: InputMaybe<Scalars['DateTime']['input']>;
};


export type LabsGqlQueryListPartialsFulfillmentNeedsReturnLabelOrdersArgs = {
  delivered_after?: InputMaybe<Scalars['DateTime']['input']>;
};


export type LabsGqlQueryListPartnerBillingAccountsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  partner_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryListPracticeBillingContactUsersArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryListPracticeContractsV2ByOrganizationIdArgs = {
  filter_criteria?: InputMaybe<LabsGqlPracticeContractFilterCriteriaInput>;
  organization_id: Scalars['String']['input'];
};


export type LabsGqlQueryListPracticePatientsArgs = {
  with_replaced?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlQueryListPracticePatientsWithSortToolsArgs = {
  args: LabsGqlPatientListInputArgs;
};


export type LabsGqlQueryListPricesArgs = {
  priceId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryListPricesWithPracticeCountsArgs = {
  priceId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryListProductCatalogEntriesArgs = {
  input: LabsGqlListProductCatalogEntriesInput;
};


export type LabsGqlQueryListProductCatalogEntriesWithDetailsArgs = {
  input: LabsGqlListProductCatalogEntriesInput;
};


export type LabsGqlQueryListProductCatalogPricesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type LabsGqlQueryListReasonCodesArgs = {
  params?: InputMaybe<LabsGqlListReasonCodeParams>;
};


export type LabsGqlQueryListRefundCategoriesArgs = {
  includeArchived: Scalars['Boolean']['input'];
};


export type LabsGqlQueryListShipmentsArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryListSurgicalGuideDrillKitsArgs = {
  includeDefaults: Scalars['Boolean']['input'];
};


export type LabsGqlQueryListUserOrganizationsArgs = {
  user_id: Scalars['String']['input'];
};


export type LabsGqlQueryListUserPositionsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['String']['input'];
};


export type LabsGqlQueryListUsersArgs = {
  filter?: InputMaybe<LabsGqlListUsersFilter>;
};


export type LabsGqlQueryListUsersPositionsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  user_ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryLoadOrderFilterOptionsArgs = {
  filterId: Scalars['String']['input'];
  partnerId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryNeedsBillingSummaryEmailArgs = {
  organizationId: Scalars['String']['input'];
};


export type LabsGqlQueryNeedsBillingSummaryForContractArgs = {
  organizationId: Scalars['String']['input'];
};


export type LabsGqlQueryOrderEntriesByListViewArgs = {
  combinator?: InputMaybe<LabsGqlFilterCombinator>;
  criteria?: InputMaybe<Array<LabsGqlFilterCriteriaSubmissionInput>>;
  filter?: InputMaybe<LabsGqlListOrdersFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryOrderWorkflowTasksByListViewArgs = {
  advancedSearch?: InputMaybe<Scalars['Boolean']['input']>;
  order_filter?: InputMaybe<Array<LabsGqlFilterCriteriaSubmissionInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<LabsGqlWorkflowTaskSortParam>;
  task_filter?: InputMaybe<LabsGqlListWorkflowTasksFilter>;
};


export type LabsGqlQueryOrdersByIdsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryOrdersByListViewArgs = {
  combinator?: InputMaybe<LabsGqlFilterCombinator>;
  criteria?: InputMaybe<Array<LabsGqlFilterCriteriaSubmissionInput>>;
  filter?: InputMaybe<LabsGqlListOrdersFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<LabsGqlListOrdersSort>;
};


export type LabsGqlQueryOrdersByScanIdArgs = {
  scan_id: Scalars['String']['input'];
};


export type LabsGqlQueryOrdersForChairsideCacheArgs = {
  limit: Scalars['Int']['input'];
};


export type LabsGqlQueryOrdersForLabSlipByIdsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryOrdersMatchingAutomationFilterCountArgs = {
  automationFilter: LabsGqlFilterSubmissionInput;
  minAgeDays: Scalars['Int']['input'];
};


export type LabsGqlQueryPanLabelsByLabOrderIdsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type LabsGqlQueryParse_3oxz_3oxArgs = {
  raw_3ox: Scalars['String']['input'];
};


export type LabsGqlQueryParse_3oxz_CaseArgs = {
  raw_case: Scalars['String']['input'];
};


export type LabsGqlQueryParse_3oxz_From_FilepathArgs = {
  filepath: Scalars['String']['input'];
};


export type LabsGqlQueryParse_3oxz_From_FilesArgs = {
  raw_3ox: Scalars['String']['input'];
  raw_case: Scalars['String']['input'];
};


export type LabsGqlQueryPartnerBillingCurrentStateArgs = {
  partnerId?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryPartnerPricesArgs = {
  partnerId: Scalars['String']['input'];
};


export type LabsGqlQueryPartnerSessionForScannerArgs = {
  staff_member_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryPasswordLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type LabsGqlQueryPatientPortalPatientShareArgs = {
  token: Scalars['String']['input'];
};


export type LabsGqlQueryPatient_Portal_Lab_OrderArgs = {
  token: Scalars['String']['input'];
  treatmentPlanVersion?: InputMaybe<Scalars['Float']['input']>;
};


export type LabsGqlQueryPendingAlignerCaseForScanExportArgs = {
  scan_export_id: Scalars['String']['input'];
};


export type LabsGqlQueryPendingReturnForOrderArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryPendingReviewForOrderArgs = {
  lab_order_id: Scalars['String']['input'];
};


export type LabsGqlQueryPhoneNumberPasswordLoginArgs = {
  mobile_phone_number: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type LabsGqlQueryPracticeInboxActiveOrdersArgs = {
  doctor_preferences_id: Scalars['String']['input'];
};


export type LabsGqlQueryPracticeOrderCountsOverviewArgs = {
  doctorId?: InputMaybe<Scalars['String']['input']>;
  practiceId: Scalars['String']['input'];
};


export type LabsGqlQueryPracticeOrderIdsByStatusArgs = {
  filter: LabsGqlPracticeListViewFilterInput;
};


export type LabsGqlQueryPracticeOrdersCountArgs = {
  practice_id: Scalars['String']['input'];
};


export type LabsGqlQueryPracticeSearchOrderIdsArgs = {
  args: LabsGqlOrderSearchPracticeSearchInput;
};


export type LabsGqlQueryPreviewCreditAndRefundOptionsForOrderCancellationArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryPreviewPracticePendingInvoiceItemsArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  targetCreationDate: Scalars['DateTime']['input'];
};


export type LabsGqlQueryPreviewPracticePendingInvoiceItemsForActiveContractArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  targetCreationDate: Scalars['DateTime']['input'];
};


export type LabsGqlQueryPreviewProductionPlanForLabOrderArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryPreviewUpdateOrderSlaArgs = {
  command: LabsGqlModifyOrderSlaCommand;
};


export type LabsGqlQueryPreviewUpdateRushConfigurationArgs = {
  payload: LabsGqlUpdateOrderRushConfigurationPayload;
};


export type LabsGqlQueryReasonCodesForOrderItemArgs = {
  form_display: LabsGqlFormDisplay;
  item_id: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryRefabReasonsForOrderItemsArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryRefreshTokenArgs = {
  accessToken: Scalars['String']['input'];
};


export type LabsGqlQueryResolveDefaultActionTimesArgs = {
  order_id: Scalars['String']['input'];
  type_id: Scalars['String']['input'];
};


export type LabsGqlQuerySalesOrderGetByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQuerySalesOrderGetBySplitIdArgs = {
  split_id: Scalars['String']['input'];
};


export type LabsGqlQuerySalesOrderGetIdByOrderNumberArgs = {
  order_number: Scalars['String']['input'];
};


export type LabsGqlQuerySalesOrderGetInRefabChainArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryScan_FileArgs = {
  scanId: Scalars['String']['input'];
};


export type LabsGqlQueryScan_FilesArgs = {
  patient_names?: InputMaybe<Array<Scalars['String']['input']>>;
  practice_id?: InputMaybe<Scalars['String']['input']>;
  reused_scan_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type LabsGqlQueryScan_Files_For_PatientArgs = {
  patient_id: Scalars['String']['input'];
};


export type LabsGqlQuerySearchPracticePatientsArgs = {
  search: Scalars['String']['input'];
};


export type LabsGqlQuerySingleAddressArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryStaffMemberForPreferencesArgs = {
  preferences_id: Scalars['String']['input'];
};


export type LabsGqlQueryTagsArgs = {
  entity_id?: InputMaybe<Scalars['String']['input']>;
  entity_type?: InputMaybe<LabsGqlTaggableEntityType>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryTaskAssignmentTimelineArgs = {
  order_id: Scalars['String']['input'];
};


export type LabsGqlQueryTatGetByIdArgs = {
  id: Scalars['String']['input'];
};


export type LabsGqlQueryTatGetByOrderIdArgs = {
  orderId: Scalars['String']['input'];
};


export type LabsGqlQueryTatMakePlanArgs = {
  adjustments: Array<LabsGqlTatAdjustmentType>;
  items: LabsGqlLabOrderItemV2InputBySku;
  rush: Scalars['Boolean']['input'];
  start: Scalars['DateTime']['input'];
  waxup: Scalars['Boolean']['input'];
};


export type LabsGqlQueryTimelineItemsArgs = {
  entityId: Scalars['String']['input'];
  entityType: Scalars['String']['input'];
  forceProjection?: InputMaybe<Scalars['Boolean']['input']>;
};


export type LabsGqlQueryTimelineV2ItemsArgs = {
  entityId: Scalars['String']['input'];
};


export type LabsGqlQueryUploadedAlignerPhotoGcsPathsArgs = {
  scan_export_id: Scalars['String']['input'];
};


export type LabsGqlQueryUploadedAlignerPhotoTypesArgs = {
  scan_export_id: Scalars['String']['input'];
};


export type LabsGqlQueryUserExistsWithMobileNumberArgs = {
  mobilePhoneNumber: Scalars['String']['input'];
  staff_member_id?: InputMaybe<Scalars['String']['input']>;
};


export type LabsGqlQueryValidateDeliveryAddressArgs = {
  data: LabsGqlDeliveryAddressInput;
};


export type LabsGqlQueryValidateDesignOrderRevisionUploadArgs = {
  designFilePath: Scalars['String']['input'];
  labOrderId: Scalars['String']['input'];
};


export type LabsGqlQueryWaxupRecommendationV2Args = {
  doctor_id: Scalars['String']['input'];
  items?: InputMaybe<Array<LabsGqlLabOrderItemInput>>;
  items_v2_by_sku?: InputMaybe<LabsGqlLabOrderItemV2InputBySku>;
};


export type LabsGqlQueryZendeskLsrTicketForScanArgs = {
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlReOnboardDesignStaffCommand = {
  user_id: Scalars['ID']['input'];
};

export type LabsGqlReactivateDoctorPreferencesCommand = {
  preferences_id: Scalars['String']['input'];
};

export type LabsGqlReactivateOrganizationStaffMemberCommand = {
  organization_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type LabsGqlReasonCodeAdditionalInfoRequestCount = {
  __typename?: 'ReasonCodeAdditionalInfoRequestCount';
  additional_info_request: Scalars['String']['output'];
  count: Scalars['Float']['output'];
};

export type LabsGqlReasonCodeCategoryCount = {
  __typename?: 'ReasonCodeCategoryCount';
  category: Scalars['String']['output'];
  count: Scalars['Float']['output'];
};

export type LabsGqlReasonCodeDto = {
  __typename?: 'ReasonCodeDTO';
  additional_info_request: Array<Scalars['String']['output']>;
  archived: Scalars['Boolean']['output'];
  category?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  default_fault?: Maybe<LabsGqlReasonCodeFault>;
  display_rules: Array<LabsGqlCustomFieldDisplayRule>;
  form_display?: Maybe<LabsGqlFormDisplay>;
  group?: Maybe<LabsGqlReasonCodeGroup>;
  id: Scalars['ID']['output'];
  internal_only: Scalars['Boolean']['output'];
  return_rules: Array<LabsGqlCustomFieldDisplayRule>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlReasonCodeFault {
  Carrier = 'Carrier',
  Dandy = 'Dandy',
  Designer = 'Designer',
  Doctor = 'Doctor',
  Lab = 'Lab',
  NeedsReviewOps = 'NeedsReviewOps',
  Warranty = 'Warranty'
}

export enum LabsGqlReasonCodeGroup {
  Aesthetic = 'Aesthetic',
  Fit = 'Fit',
  Other = 'Other'
}

export type LabsGqlRecordActivationFeeCommand = {
  id?: InputMaybe<Scalars['String']['input']>;
  practice_id: Scalars['String']['input'];
  price_cents: Scalars['Float']['input'];
};

export type LabsGqlRecordDesignDownloadCommand = {
  filePath?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlRecordPackingSheetViewedCommand = {
  orderId: Scalars['String']['input'];
};

export type LabsGqlRecordPartnerActivationDateCommand = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  partner_id: Scalars['String']['input'];
  source?: InputMaybe<LabsGqlPartnerActivationSource>;
};

export type LabsGqlRecordPrintableLabSlipViewCommand = {
  manufacturer_id: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type LabsGqlRecordUpgradeFeeCommand = {
  practice_id: Scalars['String']['input'];
  price_cents: Scalars['Float']['input'];
  start_date: Scalars['DateTime']['input'];
};

export enum LabsGqlRefabFault {
  Carrier = 'Carrier',
  Dandy = 'Dandy',
  Designer = 'Designer',
  Doctor = 'Doctor',
  Lab = 'Lab',
  NeedsReviewOps = 'NeedsReviewOps',
  Warranty = 'Warranty'
}

export type LabsGqlRefabFilesUploadData = {
  doctor_name?: InputMaybe<Scalars['String']['input']>;
  phone_number: Scalars['String']['input'];
  practice_id: Scalars['String']['input'];
  upload_link: Scalars['String']['input'];
};

export type LabsGqlRefabItemInfo = {
  change_requests?: InputMaybe<LabsGqlReviewChangeRequestsInput>;
  item_attachments?: InputMaybe<Array<LabsGqlReviewItemAttachmentInput>>;
  item_id: Scalars['String']['input'];
  refab_reason_codes: Array<LabsGqlReviewTagSubmissionInput>;
};

export type LabsGqlRefabOrderAttachments = {
  url: Scalars['String']['input'];
};

export type LabsGqlRefabOrderAttachmentsDto = {
  __typename?: 'RefabOrderAttachmentsDTO';
  url: Scalars['String']['output'];
};

export type LabsGqlRefabRateBySku = {
  __typename?: 'RefabRateBySku';
  name: Scalars['String']['output'];
  refab: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type LabsGqlRefabRateBySkuForPartner = {
  __typename?: 'RefabRateBySkuForPartner';
  skus: Array<LabsGqlRefabRateBySku>;
};

export type LabsGqlRefabReasonCategoryCount = {
  __typename?: 'RefabReasonCategoryCount';
  category: Scalars['String']['output'];
  count: Scalars['Float']['output'];
};

export type LabsGqlRefabReasonDto = {
  __typename?: 'RefabReasonDTO';
  archived: Scalars['Boolean']['output'];
  category?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  default_fault?: Maybe<LabsGqlRefabFault>;
  display_rules: Array<LabsGqlCustomFieldDisplayRule>;
  id: Scalars['ID']['output'];
  internal_only: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  return_rules: Array<LabsGqlCustomFieldDisplayRule>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlRefreshShipmentTrackingCommand = {
  shipment_id: Scalars['ID']['input'];
};

export type LabsGqlRefundCategoryDto = {
  __typename?: 'RefundCategoryDTO';
  archived: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlRefundInvoiceItemAttributionInput = {
  amount_cents: Scalars['Int']['input'];
  invoice_id: Scalars['ID']['input'];
  invoice_item_id: Scalars['ID']['input'];
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlRefundInvoicePaymentInput = {
  amountCents: Scalars['Int']['input'];
  invoiceId: Scalars['ID']['input'];
  refundReason: Scalars['String']['input'];
  stripeChargeOrPaymentIntentId: Scalars['String']['input'];
};

export type LabsGqlRefundOrderAttributionInput = {
  amount_cents: Scalars['Int']['input'];
  invoice_id: Scalars['ID']['input'];
  invoice_item_id: Scalars['ID']['input'];
  order_id: Scalars['ID']['input'];
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlRefundOrderItemAttributionInput = {
  amount_cents: Scalars['Int']['input'];
  invoice_id: Scalars['ID']['input'];
  invoice_item_id: Scalars['ID']['input'];
  order_id: Scalars['ID']['input'];
  order_item_id: Scalars['ID']['input'];
  type: LabsGqlInvoiceAdjustmentAttributionType;
};

export type LabsGqlRegisterActivationFeeChargeCommand = {
  activation_date: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
};

export type LabsGqlRegisterInvoiceItemCommand = {
  category: LabsGqlOneTimeChargeCategory;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  practice_id: Scalars['String']['input'];
  price_cents: Scalars['Float']['input'];
  registered_at: Scalars['DateTime']['input'];
};

export type LabsGqlRejectPendingAlignerCaseCommand = {
  notes?: InputMaybe<Scalars['String']['input']>;
  reason: LabsGqlPendingAlignerCaseRejectionReason;
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlRejectTreatmentPlanCommand = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlRejectedAlignerTreatmentPlan = LabsGqlIAlignerTreatmentPlan & {
  __typename?: 'RejectedAlignerTreatmentPlan';
  duration_in_months: Scalars['Int']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  /** Denotes that the plan was modified by the doctor and will need review by a designer before fabrication. */
  modified_by_doctor?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use needs_attachments_upper and needs_attachments_lower on the treatment plan instead */
  needs_attachments: Scalars['Boolean']['output'];
  needs_attachments_lower?: Maybe<Scalars['Boolean']['output']>;
  needs_attachments_upper?: Maybe<Scalars['Boolean']['output']>;
  needs_ipr: Scalars['Boolean']['output'];
  plan_type: LabsGqlTreatmentPlanType;
  rejected_at: Scalars['DateTime']['output'];
  request: LabsGqlConvertedTreatmentPlanRequest;
  softsmile?: Maybe<LabsGqlSoftSmileData>;
  steps: Array<LabsGqlAlignerTreatmentPlanStep>;
};

export enum LabsGqlRejectedScanResolution {
  NewScan = 'new_scan',
  NotShared = 'not_shared',
  Other = 'other',
  ProceedWithChange = 'proceed_with_change',
  ProceedWithoutChange = 'proceed_without_change',
  SharedNoHold = 'shared_no_hold'
}

export type LabsGqlReleaseTicketActionCommand = {
  action_id: Scalars['ID']['input'];
  ticket_id: Scalars['ID']['input'];
};

export type LabsGqlRemovableItem = LabsGqlIOrderItemBase & {
  __typename?: 'RemovableItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  clasps?: Maybe<Array<LabsGqlOrderItemRemovableClasp>>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlOrderItemArchUnit;
};

export type LabsGqlRemovableItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  clasps?: InputMaybe<Array<LabsGqlOrderItemRemovableClaspInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlOrderItemArchUnitInput;
};

export type LabsGqlRemoveAbutmentPartToImplantScanbodyCommand = {
  abutment_part_id: Scalars['String']['input'];
  implant_to_scanbody_id: Scalars['String']['input'];
  implant_type_id: Scalars['String']['input'];
};

export type LabsGqlRemoveEntityTagCommand = {
  entity_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};

export type LabsGqlRemoveFulfillmentShippingCommand = {
  orderId: Scalars['String']['input'];
};

export type LabsGqlRemoveInvoiceItemInput = {
  invoiceId: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
};

export type LabsGqlRemoveLabSlipAlertsCommand = {
  orderId: Scalars['String']['input'];
  text: Array<Scalars['String']['input']>;
};

export type LabsGqlRemoveOrderHoldCommand = {
  holdId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  stage_due_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LabsGqlRemoveOrganizationStaffMemberCommand = {
  member_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type LabsGqlRemovePreferenceStaffMemberCommand = {
  preference_set_id: Scalars['String']['input'];
};

export type LabsGqlRemovePriceApplicationRuleCommand = {
  /** If null, applies for this unit type with any material type */
  material_type?: InputMaybe<Scalars['String']['input']>;
  price_id: Scalars['String']['input'];
  unit_type: Scalars['String']['input'];
};

export type LabsGqlRemoveScannerOwnerCommand = {
  scanner_id: Scalars['String']['input'];
};

export type LabsGqlRemoveSendersInput = {
  /** the notification_group to remove the sender ids from */
  notification_group: LabsGqlNotificationGroupNames;
  /** the name of the notification group that we're updating, to be used in the email that we send notifying of the changes */
  notification_name: Scalars['String']['input'];
  /** id of preference to remove sender_ids_to_remove from */
  preference_id: Scalars['String']['input'];
  /** list of sender ids to remove from the notification_group */
  sender_ids_to_remove: Array<Scalars['String']['input']>;
};

export type LabsGqlRemoveUsagePaymentSplitConfigCommand = {
  partner_id: Scalars['String']['input'];
};

export type LabsGqlReplaceRejectedScanCommand = {
  notes?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  scan_id: Scalars['String']['input'];
};

export type LabsGqlReplaceRejectedScanData = {
  /** Path to the new 3oxz in gcs */
  new_3oxz_gcs_path: Scalars['String']['input'];
  /** Preview url for the original 3oxz */
  old_3oxz_preview_link: Scalars['String']['input'];
  skip_new_scan_review: Scalars['Boolean']['input'];
};

export type LabsGqlReplaceRemovableManufacturingFilesCommand = {
  files: LabsGqlDesignOrderRemovableManufacturingFilesInput;
  /** The ID of the order */
  order_id: Scalars['String']['input'];
};

export type LabsGqlReplaceThreeoxzCommand = {
  /** Path to the new 3oxz in gcs */
  new_3oxz_gcs_path: Scalars['String']['input'];
  /** Notes associated with the new scans */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Preview url for the original 3oxz */
  old_3oxz_preview_link?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  /** Patient photos associated with the new scan */
  photos?: InputMaybe<Array<LabsGqlLabOrderSinglePhotoInput>>;
  /** Whether to notify the doctor */
  send_email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlRequestOrderRefinementCommand = {
  order_id: Scalars['String']['input'];
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlRequestPrecheckoutScanReviewRes = {
  __typename?: 'RequestPrecheckoutScanReviewRes';
  success: Scalars['Boolean']['output'];
};

/** Reroute the order using the routing system. Skips split assignments to prevent multiple orders */
export type LabsGqlRerouteLabOrderCommand = {
  disable_notification: Scalars['Boolean']['input'];
  disable_sla_reset: Scalars['Boolean']['input'];
  order_id: Scalars['String']['input'];
  /** Why are we rerunning routing? */
  reason: Scalars['String']['input'];
};

export type LabsGqlRerouteLabOrderResult = {
  __typename?: 'RerouteLabOrderResult';
  manufacturer_assignment_changed: Scalars['Boolean']['output'];
  manufacturer_last_updated_date: Scalars['DateTime']['output'];
  new_manufacturer_id?: Maybe<Scalars['String']['output']>;
  order_id: Scalars['String']['output'];
  previous_manufacturer_id: Scalars['String']['output'];
};

export type LabsGqlResendOrdersToAutomateResult = {
  __typename?: 'ResendOrdersToAutomateResult';
  error?: Maybe<Scalars['String']['output']>;
  order_id: Scalars['String']['output'];
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlResolveHoldWorkflowTask = LabsGqlIWorkflowTask & {
  __typename?: 'ResolveHoldWorkflowTask';
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlWorkflowTaskReasonsConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export type LabsGqlResolveOrderReviewFlagCommand = {
  orderId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlResolveOrderScanRejectionCommand = {
  delay_days?: InputMaybe<Scalars['Float']['input']>;
  new_scan?: InputMaybe<LabsGqlReplaceRejectedScanData>;
  notes: Scalars['String']['input'];
  notify_practice_of_delay?: InputMaybe<Scalars['Boolean']['input']>;
  order_id: Scalars['String']['input'];
  resolution?: InputMaybe<LabsGqlRejectedScanResolution>;
  shipping_days?: InputMaybe<Scalars['Float']['input']>;
  should_reset_sla_start_date?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LabsGqlResolveScanRejectionDecisionEnum {
  Cancel = 'cancel',
  Proceed = 'proceed'
}

export type LabsGqlResolveScanRejectionWorkflowTask = LabsGqlIWorkflowTask & {
  __typename?: 'ResolveScanRejectionWorkflowTask';
  /** The active scan review work order for the lab order associated with this task. If there are no active scan reviews, returns null. */
  active_scan_review_work_order?: Maybe<LabsGqlScanReviewWorkOrderDto>;
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlWorkflowTaskResolveScanIssueConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export enum LabsGqlResponsiblePartyEnum {
  Manufacturer = 'manufacturer',
  Practice = 'practice',
  Psr = 'psr'
}

export type LabsGqlRestoreImplantTypeCommand = {
  implant_type_id: Scalars['String']['input'];
};

export type LabsGqlRestoreManufacturerTruckTimePayload = {
  id: Scalars['String']['input'];
};

export type LabsGqlRestoreScanbodyCommand = {
  id: Scalars['String']['input'];
};

/** The result of an operation. */
export type LabsGqlResultDto = {
  __typename?: 'ResultDto';
  /** The result. */
  result: Scalars['String']['output'];
};

export type LabsGqlRetainerDto = {
  __typename?: 'RetainerDTO';
  arch: LabsGqlOrderItemArch;
  hasRestorations: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isFixedLingual: Scalars['Boolean']['output'];
  priceInCents: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type LabsGqlRetainerInput = {
  arch: LabsGqlOrderItemArch;
  hasRestorations: Scalars['Boolean']['input'];
  isFixedLingual: Scalars['Boolean']['input'];
  quantity: Scalars['Int']['input'];
};

export type LabsGqlRetainersCollection = {
  __typename?: 'RetainersCollection';
  current?: Maybe<LabsGqlRetainerDto>;
  historical: Array<LabsGqlRetainerDto>;
};

export type LabsGqlReviewChangeRequests = {
  __typename?: 'ReviewChangeRequests';
  shade_changes?: Maybe<Array<LabsGqlReviewShadeChangeRequest>>;
};

export type LabsGqlReviewChangeRequestsInput = {
  shade_changes?: InputMaybe<Array<LabsGqlReviewShadeChangeRequestInput>>;
};

export type LabsGqlReviewItemAttachment = {
  __typename?: 'ReviewItemAttachment';
  url: Scalars['String']['output'];
};

export type LabsGqlReviewItemAttachmentInput = {
  url: Scalars['String']['input'];
};

export type LabsGqlReviewOrderWorkflowTask = LabsGqlIWorkflowTask & {
  __typename?: 'ReviewOrderWorkflowTask';
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlWorkflowTaskReviewOrderConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export enum LabsGqlReviewRating {
  Bad = 'bad',
  Good = 'good',
  Perfect = 'perfect'
}

export enum LabsGqlReviewSection {
  Chairside = 'chairside',
  Item = 'item',
  Order = 'order'
}

export type LabsGqlReviewShadeChangeRequest = {
  __typename?: 'ReviewShadeChangeRequest';
  new_value?: Maybe<Scalars['String']['output']>;
  previous_value?: Maybe<Scalars['String']['output']>;
  shade_name: LabsGqlValidShadeTypeEnum;
};

export type LabsGqlReviewShadeChangeRequestInput = {
  new_value?: InputMaybe<Scalars['String']['input']>;
  previous_value?: InputMaybe<Scalars['String']['input']>;
  shade_name: LabsGqlValidShadeTypeEnum;
};

export type LabsGqlReviewStep = {
  __typename?: 'ReviewStep';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** determines order of steps in a sequence, smallest number comes first */
  priority: Scalars['Float']['output'];
  reasons: Array<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlReviewSubmissionAnnotationDto = {
  __typename?: 'ReviewSubmissionAnnotationDTO';
  comment: Scalars['String']['output'];
  image_url: Scalars['String']['output'];
};

export type LabsGqlReviewSubmissionAnnotationInput = {
  comment: Scalars['String']['input'];
  image_url: Scalars['String']['input'];
};

export type LabsGqlReviewSubmissionSectionItem = LabsGqlIReviewSubmissionSection & {
  __typename?: 'ReviewSubmissionSectionItem';
  chairside_adjustment_minutes?: Maybe<Scalars['Float']['output']>;
  change_requests?: Maybe<LabsGqlReviewChangeRequests>;
  is_refab?: Maybe<Scalars['Boolean']['output']>;
  item_attachments?: Maybe<Array<LabsGqlReviewItemAttachment>>;
  item_id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  rating: LabsGqlReviewRating;
  star_rating?: Maybe<Scalars['Int']['output']>;
  tags: Array<LabsGqlReviewTagSubmission>;
};

export type LabsGqlReviewSubmissionSectionItemInput = {
  chairside_adjustment_minutes?: InputMaybe<Scalars['Float']['input']>;
  change_requests?: InputMaybe<LabsGqlReviewChangeRequestsInput>;
  is_refab?: InputMaybe<Scalars['Boolean']['input']>;
  item_attachments?: InputMaybe<Array<LabsGqlReviewItemAttachmentInput>>;
  item_id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  rating: LabsGqlReviewRating;
  star_rating?: InputMaybe<Scalars['Int']['input']>;
  tags: Array<LabsGqlReviewTagSubmissionInput>;
};

export type LabsGqlReviewSubmissionV3ItemInput = {
  chairside_adjustment_minutes?: InputMaybe<Scalars['Float']['input']>;
  item_id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  star_rating?: InputMaybe<Scalars['Int']['input']>;
  tags: Array<LabsGqlReviewTagSubmissionInput>;
};

/** deprecated, long gone */
export type LabsGqlReviewTag = LabsGqlIReviewTag & {
  __typename?: 'ReviewTag';
  applicable_ratings: Array<LabsGqlReviewRating>;
  applicable_sections: Array<LabsGqlReviewSection>;
  archived: Scalars['Boolean']['output'];
  category?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** Only applies if item included in applicable_sections */
  display_rules: Array<LabsGqlCustomFieldDisplayRule>;
  id: Scalars['ID']['output'];
  /** If there are >0 related_cart_field_ids, the item must have one of the cart fields set for this tag to display */
  related_cart_field_ids: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlReviewTagForOrder = LabsGqlIReviewTag & {
  __typename?: 'ReviewTagForOrder';
  /** Item ids to display for */
  applicable_item_ids: Array<Scalars['String']['output']>;
  applicable_ratings: Array<LabsGqlReviewRating>;
  applicable_sections: Array<LabsGqlReviewSection>;
  archived: Scalars['Boolean']['output'];
  category?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** Only applies if item included in applicable_sections */
  display_rules: Array<LabsGqlCustomFieldDisplayRule>;
  id: Scalars['ID']['output'];
  /** If there are >0 related_cart_field_ids, the item must have one of the cart fields set for this tag to display */
  related_cart_field_ids: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlReviewTagSubmission = {
  __typename?: 'ReviewTagSubmission';
  category?: Maybe<Scalars['String']['output']>;
  fault?: Maybe<LabsGqlReasonCodeFault>;
  internal_only?: Maybe<Scalars['Boolean']['output']>;
  other_notes?: Maybe<Scalars['String']['output']>;
  reason_code_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated long gone */
  related_cart_field_ids?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated long gone */
  review_tag_id?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type LabsGqlReviewTagSubmissionInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  fault?: InputMaybe<LabsGqlReasonCodeFault>;
  internal_only?: InputMaybe<Scalars['Boolean']['input']>;
  other_notes?: InputMaybe<Scalars['String']['input']>;
  reason_code_id?: InputMaybe<Scalars['String']['input']>;
  related_cart_field_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  review_tag_id?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export enum LabsGqlReviewType {
  Chairside = 'Chairside',
  Order = 'Order'
}

export type LabsGqlRoutingCustomSearch = LabsGqlICustomSearchParams & {
  __typename?: 'RoutingCustomSearch';
  item_condition_json: Scalars['String']['output'];
  order_condition_json: Scalars['String']['output'];
  order_criteria?: Maybe<Array<LabsGqlFilterCriteriaSubmission>>;
  range: LabsGqlRoutingMetricsRange;
  ruleType: LabsGqlRoutingCustomSearchRuleType;
  search?: Maybe<Scalars['String']['output']>;
  type: LabsGqlCustomSearchType;
};

export type LabsGqlRoutingCustomSearchInput = {
  item_condition_json: Scalars['String']['input'];
  order_condition_json: Scalars['String']['input'];
  order_criteria?: InputMaybe<Array<LabsGqlFilterCriteriaSubmissionInput>>;
  range: LabsGqlRoutingMetricsRange;
  ruleType: LabsGqlRoutingCustomSearchRuleType;
  search?: InputMaybe<Scalars['String']['input']>;
  type: LabsGqlCustomSearchType;
};

export enum LabsGqlRoutingCustomSearchRuleType {
  Item = 'item',
  Order = 'order'
}

export type LabsGqlRoutingDecisionEvent = {
  __typename?: 'RoutingDecisionEvent';
  data: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum LabsGqlRoutingMetricsRange {
  InFlight = 'InFlight',
  LastHour = 'LastHour',
  LastMonth = 'LastMonth',
  LastWeek = 'LastWeek',
  Since8Am = 'Since8AM'
}

export type LabsGqlRoutingRulesForOrderResult = {
  __typename?: 'RoutingRulesForOrderResult';
  capacityRules: Array<LabsGqlCapacityRule>;
  designRules: Array<LabsGqlDesignRule>;
  labFilterRules: Array<LabsGqlBiasRule>;
  targetDistributionRules: Array<LabsGqlRoutingTargetDistributionRule>;
};

export type LabsGqlRoutingTargetDistributionItem = {
  __typename?: 'RoutingTargetDistributionItem';
  manufacturer_id: Scalars['ID']['output'];
  weight: Scalars['Float']['output'];
};

export type LabsGqlRoutingTargetDistributionItemInput = {
  manufacturer_id: Scalars['ID']['input'];
  weight: Scalars['Float']['input'];
};

export type LabsGqlRoutingTargetDistributionRule = {
  __typename?: 'RoutingTargetDistributionRule';
  condition_json: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  disabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Float']['output'];
  routing_target_distribution_items: Array<LabsGqlRoutingTargetDistributionItem>;
  updated_at: Scalars['DateTime']['output'];
};

/** The result of running a workflow. */
export type LabsGqlRunWorkflowDto = {
  __typename?: 'RunWorkflowDto';
  /** The id of the workflow. */
  workflowId: Scalars['String']['output'];
};

export type LabsGqlRushRequest = {
  __typename?: 'RushRequest';
  /** @deprecated Rush requests no longer adjust design */
  design_days?: Maybe<Scalars['Int']['output']>;
  due_date: Scalars['DateTime']['output'];
  /** @deprecated Rush requests no longer use fabrication days */
  fabrication_days?: Maybe<Scalars['Int']['output']>;
  ship_by_date: Scalars['DateTime']['output'];
  shipping_days: Scalars['Int']['output'];
  status: LabsGqlRushRequestStatus;
};

export enum LabsGqlRushRequestStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type LabsGqlSlaPreviewResult = {
  __typename?: 'SLAPreviewResult';
  buffer_days?: Maybe<Scalars['Int']['output']>;
  current_stage?: Maybe<Scalars['String']['output']>;
  design_days?: Maybe<Scalars['Int']['output']>;
  design_due_date?: Maybe<Scalars['DateTime']['output']>;
  due_date: Scalars['DateTime']['output'];
  due_date_buffered?: Maybe<Scalars['DateTime']['output']>;
  /** Deprecated, should always be null */
  due_date_override?: Maybe<Scalars['DateTime']['output']>;
  estimated_delivery_date: Scalars['DateTime']['output'];
  fabrication_days: Scalars['Int']['output'];
  /** Deprecated, should always be null */
  fabrication_days_override?: Maybe<Scalars['Int']['output']>;
  internal_committed_due_date: Scalars['DateTime']['output'];
  internal_committed_ship_by_date: Scalars['DateTime']['output'];
  manufacturer_committed_due_date: Scalars['DateTime']['output'];
  manufacturer_committed_ship_by_date: Scalars['DateTime']['output'];
  manufacturer_delay_request?: Maybe<LabsGqlManufacturerDelayRequest>;
  manufacturer_shipping_time?: Maybe<Scalars['DateTime']['output']>;
  manufacturer_sla_cutoff_time?: Maybe<Scalars['DateTime']['output']>;
  manufacturer_sla_cutoff_timezone?: Maybe<Scalars['String']['output']>;
  max_committed_sla_days?: Maybe<Scalars['Int']['output']>;
  original_deadline?: Maybe<Scalars['DateTime']['output']>;
  ready_for_fabrication_date?: Maybe<Scalars['DateTime']['output']>;
  review_days: Scalars['Int']['output'];
  revision?: Maybe<Scalars['Int']['output']>;
  rush_request?: Maybe<LabsGqlRushRequest>;
  ship_by_date: Scalars['DateTime']['output'];
  /** Deprecated, should always be null */
  ship_by_date_override?: Maybe<Scalars['DateTime']['output']>;
  ship_by_deadline_at_fabrication_start?: Maybe<Scalars['DateTime']['output']>;
  shipping_days: Scalars['Int']['output'];
  /** Deprecated, should always be null */
  shipping_days_override?: Maybe<Scalars['Int']['output']>;
  stages?: Maybe<LabsGqlKeyedSlaStages>;
  start_date: Scalars['DateTime']['output'];
  version?: Maybe<Scalars['Int']['output']>;
  waxup_review_due_date?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlSlaStageStatus = {
  __typename?: 'SLAStageStatus';
  actual_end_time?: Maybe<Scalars['DateTime']['output']>;
  actual_start_time?: Maybe<Scalars['DateTime']['output']>;
  /** Used only for stages that have started when the sla_days value does not lead to the desired stage due date */
  overridden_projected_end_time?: Maybe<Scalars['DateTime']['output']>;
  sla_days: Scalars['Int']['output'];
  stage_name: Scalars['String']['output'];
};

/** Describes what actions can be taken on a given sales order, given the state of the order and downstream production. */
export type LabsGqlSalesOrderActionEligibilityDto = {
  __typename?: 'SalesOrderActionEligibilityDTO';
  can_add_items: Scalars['Boolean']['output'];
  can_change_delivery_address: Scalars['Boolean']['output'];
  can_change_doctor: Scalars['Boolean']['output'];
  can_delete_items: Scalars['Boolean']['output'];
  can_update_items: Scalars['Boolean']['output'];
};

export type LabsGqlSalesOrderAddItemsInput = {
  items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
  sales_order_id: Scalars['ID']['input'];
};

/** Calculated information about a sales order relevant to CAD tooling. */
export type LabsGqlSalesOrderCadInfoDto = {
  __typename?: 'SalesOrderCadInfoDTO';
  has_layered_material: Scalars['Boolean']['output'];
  insertion_axis_groups: Array<Array<Scalars['ToothNumber']['output']>>;
  margin_unns: Array<Scalars['ToothNumber']['output']>;
  /** When the order is not eligible for Dandy Verified Design workflow, this property is set to a string explaining why. */
  verified_design_ineligible_reason?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlSalesOrderChangeDeliveryAddressInput = {
  delivery_address_id: Scalars['ID']['input'];
  sales_order_id: Scalars['ID']['input'];
};

export type LabsGqlSalesOrderChangeDoctorPreferencesInput = {
  doctor_preferences_id: Scalars['ID']['input'];
  sales_order_id: Scalars['ID']['input'];
};

export type LabsGqlSalesOrderDto = {
  __typename?: 'SalesOrderDTO';
  action_eligibility: LabsGqlSalesOrderActionEligibilityDto;
  cad_info: LabsGqlSalesOrderCadInfoDto;
  created_at: Scalars['DateTime']['output'];
  creation_return_id?: Maybe<Scalars['ID']['output']>;
  delivery_address: LabsGqlPartnerDeliveryAddress;
  delivery_address_id: Scalars['ID']['output'];
  doctor_preferences: LabsGqlDoctorPreferences;
  doctor_preferences_id: Scalars['ID']['output'];
  files: Array<LabsGqlSalesOrderFileDto>;
  has_design_preview: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  is_training: Scalars['Boolean']['output'];
  /** Basic display info for items in the order, grouped by type. Displaying grouped items is generally preferable to displaying individual items. */
  item_groups_display_info: Array<LabsGqlSalesOrderItemGroupDisplayInfoDto>;
  /** Basic display info for each individual item in the order. Displaying grouped items is generally preferable to displaying individual items. */
  items_display_info: Array<LabsGqlSalesOrderItemDisplayInfoDto>;
  items_full_display_info: Array<LabsGqlSalesOrderItemFullDisplayInfoDto>;
  items_full_display_info_with_common: LabsGqlSalesOrderItemFullDisplayInfoWithCommonDto;
  items_v2: Array<LabsGqlLabOrderItemV2>;
  next_order: LabsGqlSalesOrderNextOrderInfoDto;
  /** Returns the next orders linked to this one, excluding orders that have been replaced (cancelled and resubmitted). */
  next_order_excluding_replaced: LabsGqlSalesOrderNextOrderInfoDto;
  notes: LabsGqlSalesOrderNotesDto;
  order_number: Scalars['String']['output'];
  patient: LabsGqlPatientDto;
  patient_id: Scalars['ID']['output'];
  practice_id: Scalars['ID']['output'];
  practice_order_number: Scalars['Int']['output'];
  previous_order: LabsGqlSalesOrderPreviousOrderInfoDto;
  product_line: LabsGqlProductLine;
  refabrication_count: Scalars['Int']['output'];
  scan_export: LabsGqlScan_Export;
  scan_export_history: Array<LabsGqlScan_Export>;
  scan_export_id: Scalars['ID']['output'];
  scan_export_id_history: Array<Scalars['String']['output']>;
  service_level: LabsGqlSalesOrderServiceLevelDto;
  split_id: Scalars['ID']['output'];
  split_order_ids?: Maybe<Array<Scalars['String']['output']>>;
  treatment_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlSalesOrderDeleteItemInput = {
  item_id: Scalars['ID']['input'];
  sales_order_id: Scalars['ID']['input'];
};

export type LabsGqlSalesOrderFileDto = {
  __typename?: 'SalesOrderFileDTO';
  category: LabsGqlLabSalesOrderFileCategory;
  gcs_path: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type LabsGqlSalesOrderIdOnlyDto = {
  __typename?: 'SalesOrderIdOnlyDTO';
  id: Scalars['String']['output'];
};

export type LabsGqlSalesOrderItemDisplayInfoDto = {
  __typename?: 'SalesOrderItemDisplayInfoDTO';
  count: Scalars['Float']['output'];
  display_name: Scalars['String']['output'];
  full_display_name: Scalars['String']['output'];
  item_id: Scalars['ID']['output'];
  material?: Maybe<Scalars['String']['output']>;
  product_unit_type: Scalars['String']['output'];
  sku: LabsGqlOrderItemSkuType;
  unns: Array<Scalars['ToothNumber']['output']>;
};

/** Full display info for an item or group of items in an order, for display in an items table or lab slip. Never rely on this field for business logic and solely use it for display purposes. */
export type LabsGqlSalesOrderItemFullDisplayInfoDto = {
  __typename?: 'SalesOrderItemFullDisplayInfoDTO';
  individual_items: Array<LabsGqlSalesOrderItemFullDisplayInfoIndividualItemDto>;
  major_details: Array<LabsGqlSalesOrderItemFullDisplayInfoDetailDto>;
  minor_details: Array<LabsGqlSalesOrderItemFullDisplayInfoDetailDto>;
  notes?: Maybe<Scalars['String']['output']>;
  shade_details: Array<LabsGqlSalesOrderItemFullDisplayInfoDetailDto>;
  title: Scalars['String']['output'];
};

/** Helper type for returning key-value pairs for display. */
export type LabsGqlSalesOrderItemFullDisplayInfoDetailDto = {
  __typename?: 'SalesOrderItemFullDisplayInfoDetailDTO';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Helper type for returning an id and label for an item. */
export type LabsGqlSalesOrderItemFullDisplayInfoIndividualItemDto = {
  __typename?: 'SalesOrderItemFullDisplayInfoIndividualItemDTO';
  item_id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

/** Full display info for an item or group of items in an order, with extracted common minor details. Items in items_full_display_info with have common minor details omitted. Never rely on this field for business logic and solely use it for display purposes. */
export type LabsGqlSalesOrderItemFullDisplayInfoWithCommonDto = {
  __typename?: 'SalesOrderItemFullDisplayInfoWithCommonDTO';
  common_minor_details: Array<LabsGqlSalesOrderItemFullDisplayInfoDetailDto>;
  items_full_display_info: Array<LabsGqlSalesOrderItemFullDisplayInfoDto>;
};

export type LabsGqlSalesOrderItemGroupDisplayInfoDto = {
  __typename?: 'SalesOrderItemGroupDisplayInfoDTO';
  count: Scalars['Float']['output'];
  display_name: Scalars['String']['output'];
  full_display_name: Scalars['String']['output'];
  material?: Maybe<Scalars['String']['output']>;
  product_unit_type: Scalars['String']['output'];
  sku: LabsGqlOrderItemSkuType;
  unns: Array<Scalars['ToothNumber']['output']>;
};

export type LabsGqlSalesOrderMutationResultDto = {
  __typename?: 'SalesOrderMutationResultDTO';
  id: Scalars['String']['output'];
};

export type LabsGqlSalesOrderNextOrderInfoDto = {
  __typename?: 'SalesOrderNextOrderInfoDTO';
  continued_by_order_ids?: Maybe<Array<Scalars['ID']['output']>>;
  refabricated_by_order_ids?: Maybe<Array<Scalars['ID']['output']>>;
  replaced_by_order_ids?: Maybe<Array<Scalars['ID']['output']>>;
};

export type LabsGqlSalesOrderNotesDto = {
  __typename?: 'SalesOrderNotesDTO';
  clinical_support?: Maybe<Scalars['String']['output']>;
  doctor?: Maybe<Scalars['String']['output']>;
  refabrication?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlSalesOrderPreviousOrderInfoDto = {
  __typename?: 'SalesOrderPreviousOrderInfoDTO';
  case_readiness_id?: Maybe<Scalars['ID']['output']>;
  continued_order_id?: Maybe<Scalars['ID']['output']>;
  refabricated_order_id?: Maybe<Scalars['ID']['output']>;
  replaced_order_id?: Maybe<Scalars['ID']['output']>;
};

export type LabsGqlSalesOrderServiceLevelDto = {
  __typename?: 'SalesOrderServiceLevelDTO';
  speed: LabsGqlLabSalesOrderServiceSpeed;
};

export type LabsGqlSalesOrderUpdateItemsInput = {
  items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
  sales_order_id: Scalars['ID']['input'];
};

export type LabsGqlSalesforceCase = {
  __typename?: 'SalesforceCase';
  /** @deprecated not used */
  CaseNumber: Scalars['String']['output'];
  Case_Type_Description__c?: Maybe<Scalars['String']['output']>;
  CreatedDate?: Maybe<Scalars['String']['output']>;
  Id: Scalars['String']['output'];
  Owner?: Maybe<LabsGqlSalesforceCaseOwner>;
  Resolution_Notes__c?: Maybe<Scalars['String']['output']>;
  Resolution_Type__c?: Maybe<Scalars['String']['output']>;
  Subject?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlSalesforceCaseOwner = {
  __typename?: 'SalesforceCaseOwner';
  Name?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlSaveDoctorLabSlipAlertsCommand = {
  alerts: Array<LabsGqlDoctorLabSlipAlertInput>;
  doctor_id: Scalars['String']['input'];
};

export type LabsGqlSaveLabSlipAlertsCommand = {
  orderId: Scalars['String']['input'];
  /** Finalized alerts for the order, full state */
  saved_alerts: Array<LabsGqlLabSlipAlertInput>;
};

export type LabsGqlSavedListOrdersSort = {
  __typename?: 'SavedListOrdersSort';
  asc: Scalars['Boolean']['output'];
  key?: Maybe<LabsGqlLabOrderSortKey>;
};

export type LabsGqlSavedListWorkflowTasksFilter = {
  __typename?: 'SavedListWorkflowTasksFilter';
  assigned?: Maybe<Scalars['Boolean']['output']>;
  assigned_user_ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  completed?: Maybe<Scalars['Boolean']['output']>;
  completed_at?: Maybe<LabsGqlSavedTaskDateFilter>;
  /** List of STAFF ids, not user ids, that could have completed the task. */
  completed_by_ids?: Maybe<Array<Scalars['String']['output']>>;
  created_at?: Maybe<LabsGqlSavedTaskDateFilter>;
  design_review_required?: Maybe<Scalars['Boolean']['output']>;
  exclude_assigned_user_ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  is_revision?: Maybe<Scalars['Boolean']['output']>;
  responsible_party?: Maybe<LabsGqlWorkflowTaskResponsibleParty>;
  type?: Maybe<Array<LabsGqlWorkflowTaskType>>;
};

export type LabsGqlSavedSearch = {
  __typename?: 'SavedSearch';
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  created_by_user: LabsGqlUserDto;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  favorited_by: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  search: LabsGqlCustomSearchParams;
  updated_at: Scalars['DateTime']['output'];
  visibility: LabsGqlSavedSearchVisibility;
};

export enum LabsGqlSavedSearchVisibility {
  Private = 'private',
  Public = 'public'
}

export type LabsGqlSavedTaskDateFilter = {
  __typename?: 'SavedTaskDateFilter';
  comparator?: Maybe<LabsGqlFilterComparator>;
  comparison_value?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlSavedWorkflowTaskSortParam = {
  __typename?: 'SavedWorkflowTaskSortParam';
  field: LabsGqlWorkflowTaskSortField;
  isAsc: Scalars['Boolean']['output'];
};

export enum LabsGqlScanArchKind {
  BothFull = 'BothFull',
  BothQuad = 'BothQuad',
  OneQuad = 'OneQuad'
}

export type LabsGqlScanExportAsset = {
  __typename?: 'ScanExportAsset';
  drc_path?: Maybe<Scalars['String']['output']>;
  drc_segmentation_path?: Maybe<Scalars['String']['output']>;
  kind: LabsGqlScanExportAssetKind;
  number?: Maybe<Scalars['Float']['output']>;
  ply_path?: Maybe<Scalars['String']['output']>;
  stl_path?: Maybe<Scalars['String']['output']>;
  texture_path?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlScanExportAssetKind {
  BiteScan = 'BiteScan',
  LowerAlignerPreppedScan = 'LowerAlignerPreppedScan',
  LowerImplantScan = 'LowerImplantScan',
  LowerJawDentureScan = 'LowerJawDentureScan',
  LowerJawPrePreparationScan = 'LowerJawPrePreparationScan',
  LowerJawScan = 'LowerJawScan',
  Unknown = 'Unknown',
  UpperAlignerPreppedScan = 'UpperAlignerPreppedScan',
  UpperImplantScan = 'UpperImplantScan',
  UpperJawDentureScan = 'UpperJawDentureScan',
  UpperJawPrePreparationScan = 'UpperJawPrePreparationScan',
  UpperJawScan = 'UpperJawScan'
}

export type LabsGqlScanExportElement = {
  __typename?: 'ScanExportElement';
  Color?: Maybe<Scalars['String']['output']>;
  DandyMaterial?: Maybe<Scalars['String']['output']>;
  LinkRelationship?: Maybe<LabsGqlOrderItemLinkRelationship>;
  LinkTypeClass: Scalars['String']['output'];
  Material?: Maybe<Scalars['String']['output']>;
  ScanFiles: Array<LabsGqlScanExportElementScanFile>;
  TypeIDs: Array<LabsGqlScanExportElementTypeId>;
};

export type LabsGqlScanExportElementScanFile = {
  __typename?: 'ScanExportElementScanFile';
  FileType: Scalars['String']['output'];
  Path: Scalars['String']['output'];
  ScanProcessState: Scalars['String']['output'];
};

export type LabsGqlScanExportElementTypeId = {
  __typename?: 'ScanExportElementTypeID';
  ImplantDetails?: Maybe<LabsGqlTypeIdImplantDetails>;
  /** Used if a TypeId/Sub-Item has a different material than its parent */
  Material?: Maybe<Scalars['String']['output']>;
  /** This is what maps to unit_type */
  TypeClass: Scalars['String']['output'];
  TypeID: Scalars['String']['output'];
  UNN: Scalars['String']['output'];
};

export type LabsGqlScanExportInputLineItem = {
  color?: InputMaybe<Scalars['String']['input']>;
  material_type: Scalars['String']['input'];
  tooth?: InputMaybe<Scalars['String']['input']>;
  unit_type: Scalars['String']['input'];
};

export enum LabsGqlScanExportScanType {
  BiteOne = 'BITE_ONE',
  BiteTwo = 'BITE_TWO',
  LowerJaw = 'LOWER_JAW',
  LowerPrePrep = 'LOWER_PRE_PREP',
  UpperJaw = 'UPPER_JAW',
  UpperPrePrep = 'UPPER_PRE_PREP'
}

export enum LabsGqlScanExportSource {
  AlliedStar = 'AlliedStar',
  Carestream = 'Carestream',
  Medit = 'Medit',
  ScannerOs = 'ScannerOs',
  Trios = 'Trios',
  ITero = 'iTero'
}

export type LabsGqlScanExportTelemetrySnapshot = {
  __typename?: 'ScanExportTelemetrySnapshot';
  calibrations: Array<LabsGqlScannerCalibrationTimestamps>;
  dongles: Array<Scalars['String']['output']>;
  scanner_serial?: Maybe<Scalars['String']['output']>;
  telemetry_version?: Maybe<Scalars['String']['output']>;
  uploader_version?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlScanFileCreatedData = {
  __typename?: 'ScanFileCreatedData';
  scanner_id: Scalars['String']['output'];
};

export type LabsGqlScanFileDeletedData = {
  __typename?: 'ScanFileDeletedData';
  user_id?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlScanFileOverwrittenEventData = {
  __typename?: 'ScanFileOverwrittenEventData';
  original_create_date: Scalars['DateTime']['output'];
  scanner_id: Scalars['String']['output'];
};

export type LabsGqlScanFileStLsManuallyUploadedEventData = {
  __typename?: 'ScanFileSTLsManuallyUploadedEventData';
  overwrote_existing: Scalars['Boolean']['output'];
};

export type LabsGqlScanReviewWorkOrderDto = {
  __typename?: 'ScanReviewWorkOrderDTO';
  created_at: Scalars['DateTime']['output'];
  current_doctor_request?: Maybe<LabsGqlDoctorRequestDto>;
  current_doctor_request_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  order_id: Scalars['ID']['output'];
  rejection_metadata: LabsGqlScanReviewWorkOrderRejectionMetadataDto;
  resolution_metadata?: Maybe<LabsGqlScanReviewWorkOrderResolutionMetadataDto>;
  status: LabsGqlScanReviewWorkOrderStatus;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlScanReviewWorkOrderRejectionCategory {
  BiteScanConfirmation = 'BITE_SCAN_CONFIRMATION',
  DrApprovalNeededOnMargin = 'DR_APPROVAL_NEEDED_ON_MARGIN',
  FileDownloadError = 'FILE_DOWNLOAD_ERROR',
  ImplantSpecsConfirmation = 'IMPLANT_SPECS_CONFIRMATION',
  IncorrectImplantScanBody = 'INCORRECT_IMPLANT_SCAN_BODY',
  InsufficientClearance = 'INSUFFICIENT_CLEARANCE',
  OpposingArchInsufficient = 'OPPOSING_ARCH_INSUFFICIENT',
  OrderSpecificationConfirmation = 'ORDER_SPECIFICATION_CONFIRMATION',
  Other = 'OTHER',
  PathOfInsertionIssue = 'PATH_OF_INSERTION_ISSUE',
  ScanError = 'SCAN_ERROR',
  TissueOverMargin = 'TISSUE_OVER_MARGIN'
}

export type LabsGqlScanReviewWorkOrderRejectionMetadataDto = {
  __typename?: 'ScanReviewWorkOrderRejectionMetadataDTO';
  /** Photos and other attachments to demonstrate the problems */
  attachment_gcs_file_paths?: Maybe<Array<Scalars['String']['output']>>;
  categories: Array<LabsGqlScanReviewWorkOrderRejectionCategory>;
  /** Notes to be shared only with internal users */
  internal_notes: Scalars['String']['output'];
  /** Notes to be shared with the practice if needed */
  notes_for_doctor?: Maybe<Scalars['String']['output']>;
  /** A rating of how bad the reason for rejection is */
  severity_score: Scalars['Float']['output'];
};

export type LabsGqlScanReviewWorkOrderResolutionMetadataDto = {
  __typename?: 'ScanReviewWorkOrderResolutionMetadataDTO';
  new_scan_export_id?: Maybe<Scalars['String']['output']>;
  resolution?: Maybe<LabsGqlRejectedScanResolution>;
};

export enum LabsGqlScanReviewWorkOrderStatus {
  AwaitingDoctorResponse = 'AWAITING_DOCTOR_RESPONSE',
  Cancelled = 'CANCELLED',
  DoctorResponseReceived = 'DOCTOR_RESPONSE_RECEIVED',
  InReview = 'IN_REVIEW',
  Resolved = 'RESOLVED'
}

export type LabsGqlScanReviewWorkflowTask = LabsGqlIWorkflowTask & {
  __typename?: 'ScanReviewWorkflowTask';
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlWorkflowTaskReasonsConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export type LabsGqlScanToothShadeInput = {
  shade: Scalars['String']['input'];
  tooth: Scalars['Float']['input'];
};

export type LabsGqlScanbodyDto = {
  __typename?: 'ScanbodyDTO';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  is_archived: Scalars['Boolean']['output'];
  manufacturer: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numberOfLinkedImplants: Scalars['Float']['output'];
  sku: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlScanbodyRequestDto = {
  __typename?: 'ScanbodyRequestDTO';
  abutment_type?: Maybe<Scalars['String']['output']>;
  address: Scalars['String']['output'];
  appointment_date: Scalars['DateTime']['output'];
  case_readiness_id?: Maybe<Scalars['String']['output']>;
  connection: Scalars['String']['output'];
  connection_type?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  fulfillment_order_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  implant_type?: Maybe<LabsGqlImplantTypeDto>;
  item?: Maybe<LabsGqlCartItemV2>;
  manufacturer: Scalars['String']['output'];
  patient?: Maybe<LabsGqlPatientDto>;
  practice: LabsGqlOrganizationDto;
  practice_id: Scalars['String']['output'];
  requested_scanbody?: Maybe<LabsGqlScanbodyDto>;
  requested_scanbody_id?: Maybe<Scalars['String']['output']>;
  requesting_doctor_id?: Maybe<Scalars['String']['output']>;
  requesting_doctor_name?: Maybe<Scalars['String']['output']>;
  scanbody?: Maybe<LabsGqlScanbodyDto>;
  scanbody_id?: Maybe<Scalars['String']['output']>;
  shipment?: Maybe<LabsGqlIShipment>;
  shipment_id?: Maybe<Scalars['String']['output']>;
  surgical_report_file?: Maybe<Scalars['String']['output']>;
  system: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlScanbodySurgicalReportUploadData = {
  doctor_name?: InputMaybe<Scalars['String']['input']>;
  phone_number: Scalars['String']['input'];
  practice_id: Scalars['String']['input'];
  upload_link: Scalars['String']['input'];
};

export type LabsGqlScannerCalibrationTimestamps = {
  __typename?: 'ScannerCalibrationTimestamps';
  color_correction?: Maybe<Scalars['Float']['output']>;
  fused_image?: Maybe<Scalars['Float']['output']>;
  mirror_test?: Maybe<Scalars['Float']['output']>;
  serial_number: Scalars['String']['output'];
};

export type LabsGqlScannerEntity = {
  __typename?: 'ScannerEntity';
  assigned_practice_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated Being removed soon! */
  atera_agent_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated Being removed soon! */
  calibrations: Array<LabsGqlScannerCalibrationTimestamps>;
  created_at: Scalars['DateTime']['output'];
  /** @deprecated Being removed soon! */
  dongles: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Being removed soon! */
  laptop_serial?: Maybe<Scalars['String']['output']>;
  /** @deprecated Being removed soon! */
  model: LabsGqlScannerModel;
  owner?: Maybe<LabsGqlOrganizationDto>;
  /** @deprecated Being removed soon! */
  scanner_serial?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field will be removed soon */
  team_viewer_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated Being removed soon! */
  telemetry_version?: Maybe<Scalars['String']['output']>;
  unique_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
  /** @deprecated Being removed soon! */
  uploader_version?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlScannerModel {
  ThreeshapeTrios = 'THREESHAPE_TRIOS'
}

export type LabsGqlScannerPartnerSession = {
  __typename?: 'ScannerPartnerSession';
  native_id_token: Scalars['String']['output'];
  partner_id: Scalars['String']['output'];
  partner_name: Scalars['String']['output'];
  user: LabsGqlScannerPartnerSessionUser;
};

export type LabsGqlScannerPartnerSessionUser = {
  __typename?: 'ScannerPartnerSessionUser';
  birth_date?: Maybe<Scalars['DateTime']['output']>;
  created_at: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  legacy_firebase_id: Scalars['String']['output'];
  phone_number: Scalars['String']['output'];
  practitioner_id?: Maybe<Scalars['String']['output']>;
  superuser: Scalars['Boolean']['output'];
  updated_at: Scalars['DateTime']['output'];
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlScannerResponse = {
  __typename?: 'ScannerResponse';
  /** @deprecated This field is never actually used by any client, and will be removed soon. */
  partner_name?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type LabsGqlScannerWithPartners = {
  __typename?: 'ScannerWithPartners';
  id: Scalars['String']['output'];
  partner_ids: Array<Scalars['String']['output']>;
};

export type LabsGqlSendDoctorPrefsUpdatedNotificationInput = {
  doctor_id: Scalars['String']['input'];
  doctor_token: Scalars['String']['input'];
  original_prefs: LabsGqlOriginalPrefsInput;
};

export type LabsGqlSendInviteEmailData = {
  organization_name: Scalars['String']['input'];
  preference_id: Scalars['String']['input'];
  referrer_email: Scalars['String']['input'];
  referrer_name: Scalars['String']['input'];
};

export type LabsGqlSendMobileAppLinksData = {
  android_link: Scalars['String']['input'];
  ios_link: Scalars['String']['input'];
  is_sign_up?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number: Scalars['String']['input'];
  preference_id: Scalars['String']['input'];
};

export type LabsGqlSendToFloorCommand = {
  /** track staff name if you want... */
  manufacturer_staff_name?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
};

export type LabsGqlSetAlignerManufacturerTreatmentPlanCommand = {
  order_id: Scalars['String']['input'];
  /** Raw download URL of the source zip */
  url: Scalars['String']['input'];
  /** The firebase path to the zip file of centered, prepared STLs for manufacturers */
  zip_path: Scalars['String']['input'];
};

export type LabsGqlSetCbctScanUrlCommand = {
  cbct_url?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlSetChatMessageVisibilityCommand = {
  chat_id: Scalars['String']['input'];
  visible_to_org_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  visible_to_roles: Array<LabsGqlStaffRoleWithAny>;
};

export enum LabsGqlSetDandyAsPreferredEnum {
  Completed = 'COMPLETED',
  NeedHelp = 'NEED_HELP',
  WillDoBeforeFirstPatient = 'WILL_DO_BEFORE_FIRST_PATIENT'
}

export type LabsGqlSetDoctorNotificationPrefsCommand = {
  communication_email?: InputMaybe<Scalars['Boolean']['input']>;
  communication_sms?: InputMaybe<Scalars['Boolean']['input']>;
  marketing_email?: InputMaybe<Scalars['Boolean']['input']>;
  marketing_sms?: InputMaybe<Scalars['Boolean']['input']>;
  order_tracking_email?: InputMaybe<Scalars['Boolean']['input']>;
  order_tracking_sms?: InputMaybe<Scalars['Boolean']['input']>;
  preference_set_id: Scalars['String']['input'];
  review_required_email?: InputMaybe<Scalars['Boolean']['input']>;
  review_required_sms?: InputMaybe<Scalars['Boolean']['input']>;
  status_change_email?: InputMaybe<Scalars['Boolean']['input']>;
  status_change_sms?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
};

export type LabsGqlSetDoctorPreferencesCommand = {
  attach_photos_email_updates?: InputMaybe<Scalars['Boolean']['input']>;
  attach_photos_sms_updates?: InputMaybe<Scalars['Boolean']['input']>;
  best_call_hour?: InputMaybe<Scalars['Float']['input']>;
  communication_email?: InputMaybe<Scalars['Boolean']['input']>;
  communication_sms?: InputMaybe<Scalars['Boolean']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  contact_phone_call_number?: InputMaybe<Scalars['String']['input']>;
  contact_tightness?: InputMaybe<LabsGqlContactTightnessEnum>;
  custom_field_preferences?: InputMaybe<Array<LabsGqlICustomFieldSubmissionInput>>;
  daily_digest_timing?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  /** Send SMS when a waxup is ready for review */
  design_review_alerts?: InputMaybe<Scalars['Boolean']['input']>;
  estimated_delivery_alerts?: InputMaybe<Scalars['Boolean']['input']>;
  estimated_delivery_emails?: InputMaybe<Scalars['Boolean']['input']>;
  invite_sent?: InputMaybe<Scalars['Boolean']['input']>;
  mailing_address_id?: InputMaybe<Scalars['String']['input']>;
  marketing_email?: InputMaybe<Scalars['Boolean']['input']>;
  marketing_sms?: InputMaybe<Scalars['Boolean']['input']>;
  mobile_phone_number?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  new_order_note_email_updates?: InputMaybe<Scalars['Boolean']['input']>;
  new_order_note_sms_updates?: InputMaybe<Scalars['Boolean']['input']>;
  notification_cadence?: InputMaybe<LabsGqlDoctorNotificationCadence>;
  notification_channel?: InputMaybe<LabsGqlDoctorNotificationChannel>;
  order_cancelled_sms_updates?: InputMaybe<Scalars['Boolean']['input']>;
  order_hold_email_updates?: InputMaybe<Scalars['Boolean']['input']>;
  order_hold_sms_updates?: InputMaybe<Scalars['Boolean']['input']>;
  order_tracking_email?: InputMaybe<Scalars['Boolean']['input']>;
  order_tracking_sms?: InputMaybe<Scalars['Boolean']['input']>;
  password_raw?: InputMaybe<Scalars['String']['input']>;
  preference_set_id: Scalars['String']['input'];
  review_required_email?: InputMaybe<Scalars['Boolean']['input']>;
  review_required_sms?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<LabsGqlStaffRolePractice>;
  roles?: InputMaybe<Array<LabsGqlStaffRolePractice>>;
  rush_request_sms_updates?: InputMaybe<Scalars['Boolean']['input']>;
  scan_rejection_email_updates?: InputMaybe<Scalars['Boolean']['input']>;
  scan_rejection_sms_updates?: InputMaybe<Scalars['Boolean']['input']>;
  scanbody_request_shipped_email_updates?: InputMaybe<Scalars['Boolean']['input']>;
  status_change_email?: InputMaybe<Scalars['Boolean']['input']>;
  status_change_sms?: InputMaybe<Scalars['Boolean']['input']>;
  test_order_default?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  unsubmitted_scan_alert_delay_mins?: InputMaybe<Scalars['Int']['input']>;
  unsubmitted_scans_daily_sms?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  user_email?: InputMaybe<Scalars['String']['input']>;
  user_first_name?: InputMaybe<Scalars['String']['input']>;
  user_last_name?: InputMaybe<Scalars['String']['input']>;
  waxup_daily_reminder_sms_timing?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  waxup_ready_for_review_email?: InputMaybe<Scalars['Boolean']['input']>;
  who_receives_dr_notifications?: InputMaybe<LabsGqlWhoReceivesDoctorNotifications>;
};

export type LabsGqlSetDoctorPreferencesIdCommand = {
  doctor_preferences_id: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type LabsGqlSetDoctorPrefsForwardingCommand = {
  communication_receivers?: InputMaybe<Array<Scalars['String']['input']>>;
  communication_senders?: InputMaybe<Array<Scalars['String']['input']>>;
  order_tracking_receivers?: InputMaybe<Array<Scalars['String']['input']>>;
  order_tracking_senders?: InputMaybe<Array<Scalars['String']['input']>>;
  preference_id: Scalars['String']['input'];
  review_required_receivers?: InputMaybe<Array<Scalars['String']['input']>>;
  review_required_senders?: InputMaybe<Array<Scalars['String']['input']>>;
  status_change_receivers?: InputMaybe<Array<Scalars['String']['input']>>;
  status_change_senders?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LabsGqlSetDoctorProfilePhotoCommand = {
  data: LabsGqlDoctorProfilePhotoDtoInput;
  doctor_id: Scalars['String']['input'];
};

export type LabsGqlSetManufacturerNotificationPreferencesCommand = {
  emails: Array<Scalars['String']['input']>;
  new_order_notifications: Array<LabsGqlManufacturerNotificationType>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlSetNonDoctorPracticeStaffCommand = {
  id: Scalars['String']['input'];
  kustomer_customer_id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlSetOrderAutomationFolderCommand = {
  folder?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type LabsGqlSetPartnerNotificationPreferencesCommand = {
  daily_digest_timing?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  design_review_alerts?: InputMaybe<Scalars['Boolean']['input']>;
  emails: Array<Scalars['String']['input']>;
  estimated_delivery_alerts?: InputMaybe<Scalars['Boolean']['input']>;
  estimated_delivery_emails?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
  unsubmitted_scan_alert_delay_mins?: InputMaybe<Scalars['Int']['input']>;
  unsubmitted_scans_daily_sms?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  waxup_daily_reminder_sms_timing?: InputMaybe<LabsGqlPartnerLabsNotificationTiming>;
  waxup_ready_for_review_email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlSetPracticeContractEndDateInput = {
  end_month: Scalars['Float']['input'];
  end_year: Scalars['Float']['input'];
  practice_contract_id: Scalars['String']['input'];
};

export type LabsGqlSetPreferredManufacturersCommand = {
  doctor_preferences_id: Scalars['String']['input'];
  preferred_manufacturers: Array<LabsGqlPreferredManufacturerInput>;
};

export type LabsGqlSetPriceSalesforceMappingCommand = {
  price_id: Scalars['String']['input'];
  salesforce_price_mapping?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlSetReceiversInput = {
  /** the notification_group to set the receiver ids to */
  notification_group: LabsGqlNotificationGroupNames;
  /** id of preference to update the receiver ids for */
  preference_id: Scalars['String']['input'];
  /** list of receiver ids to update for the notification_group */
  receiver_ids: Array<Scalars['String']['input']>;
};

export type LabsGqlSetSalesforcePriceMappingCommand = {
  price_id: Scalars['String']['input'];
  salesforce_code?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlSetScannerOwnerCommand = {
  assigned_practice_id: Scalars['String']['input'];
  scanner_id: Scalars['String']['input'];
};

export type LabsGqlSetSingleClinicalPreferenceCommand = {
  custom_field_preference: LabsGqlICustomFieldSubmissionInput;
  preference_set_id: Scalars['String']['input'];
};

export type LabsGqlSetSmileStyleCommand = {
  order_id: Scalars['String']['input'];
  smile_style: LabsGqlSmileStyleChoice;
};

export type LabsGqlSetTreatmentPlanRequestUrlCommand = {
  order_id: Scalars['String']['input'];
  /** Raw download URL */
  url: Scalars['String']['input'];
};

export type LabsGqlSetUsagePaymentSplitConfigDoctorCommand = {
  config: LabsGqlPaymentSplitConfigDoctorInput;
  partner_id: Scalars['String']['input'];
};

export type LabsGqlSetUsagePaymentSplitConfigLocationCommand = {
  config: LabsGqlPaymentSplitConfigLocationInput;
  partner_id: Scalars['String']['input'];
};

export type LabsGqlSetUsagePaymentSplitConfigPercentCommand = {
  config: LabsGqlPaymentSplitConfigPercentInput;
  partner_id: Scalars['String']['input'];
};

export type LabsGqlSetWillBeChargedCcFeeCommand = {
  partner_id: Scalars['String']['input'];
  will_be_charged_cc_fee: Scalars['Boolean']['input'];
};

export type LabsGqlSetupChampion = {
  __typename?: 'SetupChampion';
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
};

export type LabsGqlShadeInfo = {
  __typename?: 'ShadeInfo';
  base?: Maybe<Scalars['String']['output']>;
  gingival?: Maybe<Scalars['String']['output']>;
  incisal?: Maybe<Scalars['String']['output']>;
  stump?: Maybe<Scalars['String']['output']>;
};

/** A perfect shade suggestion. */
export type LabsGqlShadeSuggestionDto = {
  __typename?: 'ShadeSuggestionDto';
  /** The id of the case. */
  caseId: Scalars['String']['output'];
  /** The date and time the shade suggestion was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The error message of the shade suggestion. */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The id of the shade suggestion. */
  id: Scalars['String']['output'];
  /** The process time in milliseconds. */
  processTimeMs: Scalars['Float']['output'];
  /** The id of the shade capture. */
  shadeCaptureId: Scalars['String']['output'];
  /** The url of the suggestion generated photo. */
  suggestionGeneratedPhotoUrl?: Maybe<Scalars['String']['output']>;
  /** The tooth shade suggestions. */
  toothShadeSuggestions: Array<LabsGqlToothShadeSuggestionDto>;
  /** The unns of the shade suggestion. */
  unns: Array<Scalars['Float']['output']>;
};

/** A perfect shade suggestion with photos. */
export type LabsGqlShadeSuggestionWithPhotosDto = {
  __typename?: 'ShadeSuggestionWithPhotosDto';
  /** The photos of the shade suggestion. */
  photoUploads: Array<LabsGqlPhotoUploadDto>;
  /** The shade suggestion. */
  shadeSuggestion: LabsGqlShadeSuggestionDto;
};

export type LabsGqlShadeUnion = LabsGqlMultiShadeInfo | LabsGqlShadeInfo;

export type LabsGqlShareScanRejectionWithPracticeCommand = {
  added_file_urls?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Scalars['String']['input']>;
  notes_for_doctor?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlShiftSchedule = {
  __typename?: 'ShiftSchedule';
  fri: LabsGqlShiftScheduleStartEnd;
  mon: LabsGqlShiftScheduleStartEnd;
  sat: LabsGqlShiftScheduleStartEnd;
  sun: LabsGqlShiftScheduleStartEnd;
  thu: LabsGqlShiftScheduleStartEnd;
  timezone?: Maybe<Scalars['String']['output']>;
  tue: LabsGqlShiftScheduleStartEnd;
  wed: LabsGqlShiftScheduleStartEnd;
};

export type LabsGqlShiftScheduleInput = {
  fri: LabsGqlShiftScheduleStartEndInput;
  mon: LabsGqlShiftScheduleStartEndInput;
  sat: LabsGqlShiftScheduleStartEndInput;
  sun: LabsGqlShiftScheduleStartEndInput;
  thu: LabsGqlShiftScheduleStartEndInput;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tue: LabsGqlShiftScheduleStartEndInput;
  wed: LabsGqlShiftScheduleStartEndInput;
};

export type LabsGqlShiftScheduleStartEnd = {
  __typename?: 'ShiftScheduleStartEnd';
  /** The scheduled end time in 24-hour local time, e.g. '17:00' */
  end?: Maybe<Scalars['String']['output']>;
  /** The scheduled start time in 24-hour local time, e.g. '8:00' */
  start?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlShiftScheduleStartEndInput = {
  /** The scheduled end time in 24-hour local time, e.g. '17:00' */
  end?: InputMaybe<Scalars['String']['input']>;
  /** The scheduled start time in 24-hour local time, e.g. '8:00' */
  start?: InputMaybe<Scalars['String']['input']>;
};

export enum LabsGqlShipmentCreatedViaEnumType {
  KafkaIntegration = 'KafkaIntegration',
  Manual = 'Manual',
  PortalShip = 'PortalShip'
}

export type LabsGqlShipmentDto = {
  __typename?: 'ShipmentDTO';
  /** The source address of the shipment */
  addressFrom?: Maybe<LabsGqlShippingAddressDto>;
  addressReturn?: Maybe<LabsGqlShippingAddressDto>;
  /** The destination address of the shipment */
  addressTo?: Maybe<LabsGqlShippingAddressDto>;
  /** When the shipment cancelled */
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  /** Reason for cancelling the shipment */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** The id of the actor that cancelled the shipment */
  cancelledByStaffId?: Maybe<Scalars['String']['output']>;
  /** The carrier of the shipment */
  carrier: Scalars['String']['output'];
  /** When the shipment was created */
  createdAt: Scalars['DateTime']['output'];
  /** How the shipment was created */
  createdReason: Scalars['String']['output'];
  /** Most recent eta for the shipment */
  eta: Scalars['DateTime']['output'];
  /** When this shipment was expected to be picked up by the carrier */
  expectedPickupDate?: Maybe<Scalars['DateTime']['output']>;
  /** The primary key */
  id: Scalars['String']['output'];
  /** The URL for the shipping label */
  labelUrl?: Maybe<Scalars['String']['output']>;
  /** Original eta for the shipment */
  originalEta: Scalars['DateTime']['output'];
  /** Service level on the shipment */
  serviceLevel: Scalars['String']['output'];
  /** The status of the shipment */
  status: LabsGqlShipmentStatus;
  /** Detailed substatus */
  substatus?: Maybe<LabsGqlShipmentSubStatus>;
  /** The tracking number on the shipment */
  trackingNumber: Scalars['String']['output'];
  /** When the shipment was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export enum LabsGqlShipmentDestinationType {
  Dandy = 'dandy',
  Manufacturer = 'manufacturer',
  Partner = 'partner'
}

export enum LabsGqlShipmentStatus {
  /** Indicates that the shipment has been cancelled, which can only happen by the hand of a Dandy user. */
  Cancelled = 'CANCELLED',
  /** Indicates that the shipment has arrived and been accepted by someone at the destination. */
  Delivered = 'DELIVERED',
  /** Indicates that the shipment is in a non-recoverable state that is not delivered.  Generally the underlying problem is identified in the tracking information. */
  Failure = 'FAILURE',
  /** Indicates that the parcels associated with this shipment are ready for pickup. */
  PreTransit = 'PRE_TRANSIT',
  /** Indicates that the shipment has been returned to the return address. */
  Returned = 'RETURNED',
  /** Indicates that the shipment is in the custody of the carrier and not yet delivered. */
  Transit = 'TRANSIT',
  /** The state of the shipment is unknown; this typically happens when a label has been generate but has not been updated in the carriers system. */
  Unknown = 'UNKNOWN'
}

export enum LabsGqlShipmentSubStatus {
  AddressIssue = 'address_issue',
  ContactCarrier = 'contact_carrier',
  Delayed = 'delayed',
  Delivered = 'delivered',
  DeliveryAttempted = 'delivery_attempted',
  DeliveryRescheduled = 'delivery_rescheduled',
  DeliveryScheduled = 'delivery_scheduled',
  InformationReceived = 'information_received',
  LocationInaccessible = 'location_inaccessible',
  NoticeLeft = 'notice_left',
  Other = 'other',
  OutForDelivery = 'out_for_delivery',
  PackageAccepted = 'package_accepted',
  PackageArrived = 'package_arrived',
  PackageDamaged = 'package_damaged',
  PackageDeparted = 'package_departed',
  PackageDisposed = 'package_disposed',
  PackageForwarded = 'package_forwarded',
  PackageHeld = 'package_held',
  /** Indicates that the parcel has been lost by the carrier. */
  PackageLost = 'package_lost',
  PackageProcessed = 'package_processed',
  PackageProcessing = 'package_processing',
  PackageUnclaimed = 'package_unclaimed',
  PackageUndeliverable = 'package_undeliverable',
  PickupAvailable = 'pickup_available',
  RescheduleDelivery = 'reschedule_delivery',
  ReturnToSender = 'return_to_sender'
}

export type LabsGqlShippingAddress = {
  __typename?: 'ShippingAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlShippingAddressDto = {
  __typename?: 'ShippingAddressDTO';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  organizationName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  recipientName?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  streetOne?: Maybe<Scalars['String']['output']>;
  streetTwo?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlShippingCarriersEnum {
  DhlEcommerce = 'dhl_ecommerce',
  DhlExpress = 'dhl_express',
  Fedex = 'fedex',
  Shippo = 'shippo',
  Ups = 'ups',
  Usps = 'usps'
}

export type LabsGqlSimpleVector3 = {
  __typename?: 'SimpleVector3';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
  z: Scalars['Float']['output'];
};

export type LabsGqlSingleItemUpdateInput = {
  item: LabsGqlLabOrderItemInput;
  itemId: Scalars['String']['input'];
};

export type LabsGqlSkipOnboardingInput = {
  is_practice_chairside_skipped?: InputMaybe<Scalars['Boolean']['input']>;
  is_practice_portal_skipped?: InputMaybe<Scalars['Boolean']['input']>;
  is_staff_member_chairside_skipped?: InputMaybe<Scalars['Boolean']['input']>;
  is_staff_member_portal_skipped?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlSkusForPartner = {
  __typename?: 'SkusForPartner';
  skus: Array<LabsGqlOrderItemSkuType>;
};

export type LabsGqlSlaChangedEvent = {
  __typename?: 'SlaChangedEvent';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum LabsGqlSlaResponsibleParty {
  External = 'external',
  Internal = 'internal',
  Lab = 'lab',
  Parent = 'parent',
  Practice = 'practice'
}

export type LabsGqlSlaStage = {
  __typename?: 'SlaStage';
  delay_days: LabsGqlSlaStageDelay;
  delay_days_at_recommitment?: Maybe<LabsGqlSlaStageDelay>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  end_event_id?: Maybe<Scalars['Int']['output']>;
  eta?: Maybe<Scalars['DateTime']['output']>;
  interruptions: Array<LabsGqlSlaStageInterruption>;
  original_deadline?: Maybe<Scalars['DateTime']['output']>;
  responsible_party?: Maybe<LabsGqlSlaResponsibleParty>;
  revisions: Array<LabsGqlSlaStageRevision>;
  sla_days: Scalars['Int']['output'];
  start_date?: Maybe<Scalars['DateTime']['output']>;
  start_event_id?: Maybe<Scalars['Int']['output']>;
  was_complete_at_recommitment?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlSlaStageData = {
  __typename?: 'SlaStageData';
  end_date?: Maybe<Scalars['DateTime']['output']>;
  end_event_id?: Maybe<Scalars['Int']['output']>;
  eta?: Maybe<Scalars['DateTime']['output']>;
  original_deadline?: Maybe<Scalars['DateTime']['output']>;
  responsible_party?: Maybe<LabsGqlSlaResponsibleParty>;
  sla_days: Scalars['Int']['output'];
  start_date?: Maybe<Scalars['DateTime']['output']>;
  start_event_id?: Maybe<Scalars['Int']['output']>;
};

export type LabsGqlSlaStageDelay = {
  __typename?: 'SlaStageDelay';
  external: Scalars['Int']['output'];
  internal: Scalars['Int']['output'];
  lab: Scalars['Int']['output'];
  parent: Scalars['Int']['output'];
  practice: Scalars['Int']['output'];
};

export type LabsGqlSlaStageInterruption = {
  __typename?: 'SlaStageInterruption';
  caused_by_party: LabsGqlSlaResponsibleParty;
  effective_interruption_days?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  end_event_id?: Maybe<Scalars['Int']['output']>;
  expected_interruption_days?: Maybe<Scalars['Int']['output']>;
  start_date: Scalars['DateTime']['output'];
  start_event_id: Scalars['Int']['output'];
};

export type LabsGqlSlaStageRevision = {
  __typename?: 'SlaStageRevision';
  caused_by_party?: Maybe<LabsGqlSlaResponsibleParty>;
  event_id: Scalars['Int']['output'];
  previous: LabsGqlSlaStageData;
};

export type LabsGqlSlaUpdateDetails = {
  __typename?: 'SlaUpdateDetails';
  field: Scalars['String']['output'];
  fieldType: LabsGqlFieldType;
  previous?: Maybe<Scalars['String']['output']>;
  updated: Scalars['String']['output'];
};

export type LabsGqlSleepApneaItem = LabsGqlIOrderItemBase & {
  __typename?: 'SleepApneaItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  manufacturing_metadata: LabsGqlOrderItemSleepApneaManufacturingMetadata;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  sleep_apnea_type: LabsGqlOrderItemSleepApneaType;
};

export type LabsGqlSleepApneaItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  manufacturing_metadata: LabsGqlOrderItemSleepApneaManufacturingMetadataInput;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  /** Not optional. Should always be passed from up-to-date clients. */
  sleep_apnea_type?: InputMaybe<LabsGqlOrderItemSleepApneaType>;
  type?: InputMaybe<LabsGqlOrderItemSleepApneaType>;
};

export type LabsGqlSlimOrderInboxItem = {
  __typename?: 'SlimOrderInboxItem';
  content: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  event_id: Scalars['Int']['output'];
  event_name: LabsGqlEventNames;
  order_id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  user_role?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlSmileStyleChoice {
  Aggressive = 'Aggressive',
  Enhanced = 'Enhanced',
  Hollywood = 'Hollywood',
  LabRecommendation = 'LabRecommendation',
  Mature = 'Mature',
  MirrorContralateral = 'MirrorContralateral',
  ReplicatePreOpAnatomy = 'ReplicatePreOpAnatomy',
  ReplicateWaxUp = 'ReplicateWaxUp',
  ReplicateWaxUpWithAdjustments = 'ReplicateWaxUpWithAdjustments',
  Soft = 'Soft',
  UseSeatedDesign = 'UseSeatedDesign',
  Youthful = 'Youthful'
}

export type LabsGqlSoftSmileData = {
  __typename?: 'SoftSmileData';
  version_id: Scalars['Float']['output'];
};

export type LabsGqlSoftSmileDraftAlignerTreatmentPlan = LabsGqlIAlignerTreatmentPlan & {
  __typename?: 'SoftSmileDraftAlignerTreatmentPlan';
  duration_in_months: Scalars['Int']['output'];
  /** Denotes that the plan was modified by the doctor and will need review by a designer before fabrication. */
  modified_by_doctor?: Maybe<Scalars['Boolean']['output']>;
  needs_attachments_lower?: Maybe<Scalars['Boolean']['output']>;
  needs_attachments_upper?: Maybe<Scalars['Boolean']['output']>;
  plan_type: LabsGqlTreatmentPlanType;
  request: LabsGqlConvertedTreatmentPlanRequest;
  softsmile?: Maybe<LabsGqlSoftSmileData>;
  steps: Array<LabsGqlAlignerTreatmentPlanStep>;
};

export type LabsGqlSoftSmilePlanVersion = {
  __typename?: 'SoftSmilePlanVersion';
  author_user_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  duration_in_months?: Maybe<Scalars['Float']['output']>;
  hasWebviewerAssets?: Maybe<Scalars['Boolean']['output']>;
  metadata_path?: Maybe<Scalars['String']['output']>;
  needs_attachments_lower?: Maybe<Scalars['Boolean']['output']>;
  needs_attachments_upper?: Maybe<Scalars['Boolean']['output']>;
  number_of_steps?: Maybe<Scalars['Float']['output']>;
  report_path?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<LabsGqlAlignerTreatmentPlanStep>>;
  treatment_plan_path?: Maybe<Scalars['String']['output']>;
  version_id: Scalars['Float']['output'];
  viewer_path?: Maybe<Scalars['String']['output']>;
};

/** Contains info about the assigned stack for an order, and pan codes for each expected item group in the order */
export type LabsGqlStackAndPanCodesDto = {
  __typename?: 'StackAndPanCodesDTO';
  panCodes: Array<LabsGqlPanCodeDto>;
  stackCode: Scalars['String']['output'];
};

export type LabsGqlStackCodeOrderDetails = {
  __typename?: 'StackCodeOrderDetails';
  order_id?: Maybe<Scalars['String']['output']>;
  stack_code?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlStaffBillingContact = {
  __typename?: 'StaffBillingContact';
  deactivated_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  user: LabsGqlUserDto;
  user_id: Scalars['String']['output'];
};

export type LabsGqlStaffForSelection = {
  __typename?: 'StaffForSelection';
  contact_email?: Maybe<Scalars['String']['output']>;
  does_account_need_credentials_set: Scalars['Boolean']['output'];
  formatted_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partner_id: Scalars['String']['output'];
  profile_photo?: Maybe<LabsGqlDoctorProfilePhotoDto>;
  roles: Array<LabsGqlStaffRolePractice>;
  staff_member_id: Scalars['String']['output'];
};

export type LabsGqlStaffMemberDto = {
  __typename?: 'StaffMemberDTO';
  activated_at?: Maybe<Scalars['DateTime']['output']>;
  created_at: Scalars['DateTime']['output'];
  deactivated_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  organization_id: Scalars['String']['output'];
  organization_type: LabsGqlOrganizationType;
  roles: Array<LabsGqlStaffRole>;
  updated_at: Scalars['DateTime']['output'];
  user_id: Scalars['String']['output'];
  zendesk_contact_id?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlStaffMemberOnboardingDto = {
  __typename?: 'StaffMemberOnboardingDTO';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  set_dandy_as_preferred_lab_status?: Maybe<Scalars['String']['output']>;
  staff_member_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
  wants_preference_review?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlStaffMemberOrganization = {
  __typename?: 'StaffMemberOrganization';
  id: Scalars['ID']['output'];
  /** Whether this organization is "fake", meaning that is actually a testing Dandy organization. Only currently used for practices. */
  is_fake_organization: Scalars['Boolean']['output'];
  legal_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent_id: Scalars['ID']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
  type: LabsGqlOrganizationType;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlStaffMemberWithRelations = {
  __typename?: 'StaffMemberWithRelations';
  activated_at?: Maybe<Scalars['DateTime']['output']>;
  created_at: Scalars['DateTime']['output'];
  deactivated_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  organization: LabsGqlStaffMemberOrganization;
  organization_id: Scalars['String']['output'];
  organization_type: LabsGqlOrganizationType;
  roles: Array<LabsGqlStaffRole>;
  updated_at: Scalars['DateTime']['output'];
  user: LabsGqlUserDto;
  user_id: Scalars['String']['output'];
  zendesk_contact_id?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlStaffRole {
  Admin = 'admin',
  ExternalDesigner = 'external__designer',
  InternalAccountManager = 'internal__accountManager',
  InternalAutomationsAdmin = 'internal__automationsAdmin',
  InternalBillingAdmin = 'internal__billingAdmin',
  InternalConfigurationsAdmin = 'internal__configurationsAdmin',
  InternalCustomerExperience = 'internal__customerExperience',
  InternalDesignManager = 'internal__designManager',
  InternalDesignStaff = 'internal__designStaff',
  InternalDesignTrainer = 'internal__designTrainer',
  InternalDeveloper = 'internal__developer',
  InternalEmployee = 'internal__employee',
  InternalLabManagementAdmin = 'internal__labManagementAdmin',
  InternalOrderOperations = 'internal__orderOperations',
  InternalRevenueOperations = 'internal__revenueOperations',
  InternalSalesAccountExecutive = 'internal__salesAccountExecutive',
  LabDesigner = 'lab__designer',
  LabQc = 'lab__qc',
  ParentBilling = 'parent__billing',
  PracticeAssistant = 'practice__assistant',
  PracticeBilling = 'practice__billing',
  PracticeDoctor = 'practice__doctor',
  PracticeOfficeManager = 'practice__office_manager',
  PracticeReceptionist = 'practice__receptionist'
}

export enum LabsGqlStaffRoleExternal {
  Admin = 'admin',
  ExternalDesigner = 'external__designer'
}

export enum LabsGqlStaffRoleInternal {
  Admin = 'admin',
  InternalAccountManager = 'internal__accountManager',
  InternalAutomationsAdmin = 'internal__automationsAdmin',
  InternalBillingAdmin = 'internal__billingAdmin',
  InternalConfigurationsAdmin = 'internal__configurationsAdmin',
  InternalCustomerExperience = 'internal__customerExperience',
  InternalDesignManager = 'internal__designManager',
  InternalDesignStaff = 'internal__designStaff',
  InternalDesignTrainer = 'internal__designTrainer',
  InternalDeveloper = 'internal__developer',
  InternalEmployee = 'internal__employee',
  InternalLabManagementAdmin = 'internal__labManagementAdmin',
  InternalOrderOperations = 'internal__orderOperations',
  InternalRevenueOperations = 'internal__revenueOperations',
  InternalSalesAccountExecutive = 'internal__salesAccountExecutive'
}

export enum LabsGqlStaffRoleLab {
  Admin = 'admin',
  LabDesigner = 'lab__designer',
  LabQc = 'lab__qc'
}

export enum LabsGqlStaffRoleParent {
  Admin = 'admin',
  ParentBilling = 'parent__billing'
}

export enum LabsGqlStaffRolePractice {
  Admin = 'admin',
  PracticeAssistant = 'practice__assistant',
  PracticeBilling = 'practice__billing',
  PracticeDoctor = 'practice__doctor',
  PracticeOfficeManager = 'practice__office_manager',
  PracticeReceptionist = 'practice__receptionist'
}

/** Used for chat visibility in visible_to_roles */
export enum LabsGqlStaffRoleWithAny {
  Admin = 'admin',
  ExternalAny = 'external__any',
  ExternalDesigner = 'external__designer',
  InternalAccountManager = 'internal__accountManager',
  InternalAny = 'internal__any',
  InternalAutomationsAdmin = 'internal__automationsAdmin',
  InternalBillingAdmin = 'internal__billingAdmin',
  InternalConfigurationsAdmin = 'internal__configurationsAdmin',
  InternalCustomerExperience = 'internal__customerExperience',
  InternalDesignManager = 'internal__designManager',
  InternalDesignStaff = 'internal__designStaff',
  InternalDesignTrainer = 'internal__designTrainer',
  InternalDeveloper = 'internal__developer',
  InternalEmployee = 'internal__employee',
  InternalLabManagementAdmin = 'internal__labManagementAdmin',
  InternalOrderOperations = 'internal__orderOperations',
  InternalRevenueOperations = 'internal__revenueOperations',
  InternalSalesAccountExecutive = 'internal__salesAccountExecutive',
  LabAny = 'lab__any',
  LabDesigner = 'lab__designer',
  LabQc = 'lab__qc',
  ParentAny = 'parent__any',
  ParentBilling = 'parent__billing',
  PracticeAny = 'practice__any',
  PracticeAssistant = 'practice__assistant',
  PracticeBilling = 'practice__billing',
  PracticeDoctor = 'practice__doctor',
  PracticeOfficeManager = 'practice__office_manager',
  PracticeReceptionist = 'practice__receptionist'
}

export type LabsGqlStartFulfillmentTaskCommand = {
  /** user currently assigned to the task */
  last_assigned_user_id?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  task_id: Scalars['String']['input'];
};

export type LabsGqlStartOrderIntegrationManualV2Result = {
  __typename?: 'StartOrderIntegrationManualV2Result';
  kickedOffOrders: Array<LabsGqlStartedOrderInfo>;
  success: Scalars['Boolean']['output'];
};

export type LabsGqlStartedOrderInfo = {
  __typename?: 'StartedOrderInfo';
  manufacturerId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  workflowId: Scalars['String']['output'];
};

export type LabsGqlStoragePathConfig = {
  __typename?: 'StoragePathConfig';
  bucketName: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export enum LabsGqlStripeChargeStatus {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded'
}

export enum LabsGqlStripeInvoiceStatus {
  Deleted = 'deleted',
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void'
}

export type LabsGqlStripePaymentId = {
  __typename?: 'StripePaymentId';
  id: Scalars['String']['output'];
};

export type LabsGqlStripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  brand: Scalars['String']['output'];
  exp_month?: Maybe<Scalars['Float']['output']>;
  exp_year?: Maybe<Scalars['Float']['output']>;
  /** If the payment method type is `card`, `funding` describes how that card is funded. Can be `credit`, `debit`, `prepaid`, or `unknown` (or `null` for non-card payment methods) */
  funding?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_default: Scalars['Boolean']['output'];
  last4: Scalars['String']['output'];
  method: LabsGqlStripePaymentMethodKind;
  name: Scalars['String']['output'];
  routing_number?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlStripePaymentMethodKind {
  BankAccount = 'bank_account',
  Card = 'card'
}

export type LabsGqlSubcategoryDto = {
  __typename?: 'SubcategoryDTO';
  archived: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  parent_id: Scalars['ID']['output'];
  requires_further_info: Scalars['Boolean']['output'];
};

export type LabsGqlSubmitAutomateReviewCommand = {
  /** Pass true if the design is approved and should be sent for manufacturing */
  approved: Scalars['Boolean']['input'];
  /** The ID of the order */
  order_id: Scalars['String']['input'];
  /** The amount of time, in milliseconds, spent reviewing the design */
  review_duration_ms: Scalars['Int']['input'];
};

export type LabsGqlSubmitDesignQaEvaluationCommand = {
  evaluation_id: Scalars['String']['input'];
  is_passing: Scalars['Boolean']['input'];
  qc_results: Array<LabsGqlDesignReviewGuidedQcResultEntryInput>;
};

export type LabsGqlSubmitOrderScanReviewCommand = {
  approval_notes?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  rejection?: InputMaybe<LabsGqlOrderScanReviewRejectionInput>;
};

export type LabsGqlSubmitPendingAlignerCaseEstimateCommand = {
  estimate: LabsGqlPendingAlignerCaseEstimateInput;
  scan_export_id: Scalars['String']['input'];
};

export type LabsGqlSubmitQcFlagCommand = {
  additional_data?: InputMaybe<Scalars['MetafieldJSON']['input']>;
  category: Scalars['String']['input'];
  design_file_path: Scalars['String']['input'];
  message: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
  status: LabsGqlFlagStatus;
  sub_category?: InputMaybe<Scalars['String']['input']>;
  unn?: InputMaybe<Scalars['ToothNumber']['input']>;
};

export type LabsGqlSubmitVariantFeedbackInput = {
  crown_choice?: InputMaybe<Scalars['String']['input']>;
  freeform_feedback?: InputMaybe<Scalars['String']['input']>;
  lab_order_item_id: Scalars['String']['input'];
  star_rating?: InputMaybe<Scalars['Int']['input']>;
  time_to_seat?: InputMaybe<Scalars['Int']['input']>;
  was_drop_in?: InputMaybe<Scalars['Boolean']['input']>;
  would_you_be_disappointed?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlSubmitWaxupReviewCommand = {
  order_id: Scalars['String']['input'];
  /** If provided, the waxup will be rejected */
  rejection?: InputMaybe<LabsGqlWaxupReviewRejectionInput>;
};

export type LabsGqlSurgicalGuideDrillKit = {
  __typename?: 'SurgicalGuideDrillKit';
  drillKits: Array<Scalars['String']['output']>;
};

export type LabsGqlSurgicalGuideItem = LabsGqlIOrderItemBase & {
  __typename?: 'SurgicalGuideItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  surgical_guide_type: LabsGqlOrderItemSurgicalGuideType;
  treatment_plan_metadata: LabsGqlOrderItemSurgicalGuidanceTreatmentPlanMetadata;
  unit: LabsGqlOrderItemSurgicalGuideUnit;
};

export type LabsGqlSurgicalGuideItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  /** Not optional. Should always be passed from up-to-date clients. */
  surgical_guide_type?: InputMaybe<LabsGqlOrderItemSurgicalGuideType>;
  treatment_plan_metadata: LabsGqlOrderItemSurgicalGuidanceTreatmentPlanMetadataInput;
  type?: InputMaybe<LabsGqlOrderItemSurgicalGuideType>;
  unit: LabsGqlOrderItemSurgicalGuideUnitInput;
};

export type LabsGqlTmjItem = LabsGqlIOrderItemBase & {
  __typename?: 'TMJItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  manufacturing_metadata: LabsGqlOrderItemTmjManufacturingMetadata;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  tmj_type: LabsGqlOrderItemTmjType;
  unit: LabsGqlOrderItemArchUnit;
};

export type LabsGqlTmjItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  manufacturing_metadata: LabsGqlOrderItemTmjManufacturingMetadataInput;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  /** Not optional. Should always be passed from up-to-date clients. */
  tmj_type?: InputMaybe<LabsGqlOrderItemTmjType>;
  type?: InputMaybe<LabsGqlOrderItemTmjType>;
  unit: LabsGqlOrderItemArchUnitInput;
};

/** generic entity tag */
export type LabsGqlTag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  entity_type: LabsGqlTaggableEntityType;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** This is now deprectated, please use AddEntityTag */
export type LabsGqlTagEntityCommand = {
  entity_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};

export enum LabsGqlTaggableEntityType {
  Doctor = 'Doctor',
  Manufacturer = 'Manufacturer',
  Order = 'Order',
  OrderDelayReason = 'OrderDelayReason',
  Practice = 'Practice'
}

export type LabsGqlTaggedEntityDto = {
  __typename?: 'TaggedEntityDTO';
  created_at: Scalars['DateTime']['output'];
  entity_type: LabsGqlTaggableEntityType;
  id: Scalars['ID']['output'];
  tag_ids: Array<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlTaskAssignmentTimelineEntry = {
  __typename?: 'TaskAssignmentTimelineEntry';
  assigned_at: Scalars['DateTime']['output'];
  assigned_by: Scalars['String']['output'];
  assignee_name?: Maybe<Scalars['String']['output']>;
  assignment_reason?: Maybe<Scalars['String']['output']>;
  auto_assigned: Scalars['Boolean']['output'];
  auto_assignment_expiration?: Maybe<Scalars['DateTime']['output']>;
  created_at: Scalars['DateTime']['output'];
  event_id: Scalars['Float']['output'];
  event_name: Scalars['String']['output'];
  task_id: Scalars['String']['output'];
  task_type: LabsGqlDesignStaffTaskType;
};

export type LabsGqlTaskDateFilter = {
  comparator?: InputMaybe<LabsGqlFilterComparator>;
  comparison_value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LabsGqlTaskTypeCapabilityMatch = {
  __typename?: 'TaskTypeCapabilityMatch';
  details: Array<LabsGqlCapabilityMatch>;
  matches: Scalars['Boolean']['output'];
  taskType: LabsGqlWorkflowTaskType;
};

/** A queue of activities for a lab order, with durations and due dates. */
export type LabsGqlTat = {
  __typename?: 'Tat';
  /** When we committed to deliver the lab order. */
  committed: Scalars['DateTime']['output'];
  /** When the model was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The primary key. */
  id: Scalars['String']['output'];
  /**
   * The time of the last event processed for the order.
   *
   * The events will come from the event source stream in the short term. Eventually, the timestamp of the last event may come from a change that occurred in the sales order service, manufacturing order service, design order service, or another system. The tat service fetches information from multiple other services and processes them into a timeline of changes.
   */
  lastEventAt?: Maybe<Scalars['DateTime']['output']>;
  /** The id of the associated lab order. */
  orderId: Scalars['String']['output'];
  /** The plan for the lab order. */
  plan: LabsGqlTatPlan;
  /** Progress made on the plan. */
  progress: LabsGqlTatProgress;
  /** When the model was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * A type of activity that occurs while completing a lab order.
 *
 * An activity should generally map to one service, if that service impacts order delivery time. There might be exceptions, but those exceptions should be for things whose turnaround time we want to measure.
 *
 * Design -> Design Order Service
 *
 * Fabrication -> Fabrication Order Service
 *
 * Shipping -> Shipping Service
 *
 * WaxupReview -> Design Order Service
 */
export enum LabsGqlTatActivityType {
  /** The order is being designed. */
  Design = 'DESIGN',
  /** The order is being fabricated. */
  Fabrication = 'FABRICATION',
  /** The order is being reviewed. */
  OrderReview = 'ORDER_REVIEW',
  /** The order is being shipped. */
  Shipping = 'SHIPPING',
  /** The order is undergoing waxup review. */
  WaxupReview = 'WAXUP_REVIEW'
}

/** The add-on lead time for order item add-ons. */
export type LabsGqlTatAddOnLeadTimeDto = {
  __typename?: 'TatAddOnLeadTimeDto';
  /** When the model was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The primary key. */
  id: Scalars['String']['output'];
  /** The lead time in hours grouped by type. */
  leadTimeByType: LabsGqlTatLeadTimeByTypeDto;
  /** When the model was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** An adjustment that adds extra time to due dates. */
export type LabsGqlTatAdjustment = {
  __typename?: 'TatAdjustment';
  /** The amount of extra time to add. */
  duration: LabsGqlTatDuration;
  /** The type of adjustment. */
  type: LabsGqlTatAdjustmentType;
};

/** A type of adjustment that affects a due date. */
export enum LabsGqlTatAdjustmentType {
  /** Extra time for a delay. Affects eta but not due date. */
  Delay = 'DELAY',
  /** Extra time for a delay. Affects both eta and due date. */
  DelayInternal = 'DELAY_INTERNAL',
  /** Extra time for design if the doctor opted in. */
  DemandSmoothing = 'DEMAND_SMOOTHING'
}

/** An activity that has completed. */
export type LabsGqlTatCompleteActivity = {
  __typename?: 'TatCompleteActivity';
  /** Adjustments that affect the due date. */
  adjustments: Array<LabsGqlTatAdjustment>;
  /** The business day when the activity was due. */
  due: Scalars['DateTime']['output'];
  /** The activity's duration. */
  duration: LabsGqlTatCompleteActivityDuration;
  /** The event associated with the end of the activity. */
  endEvent?: Maybe<LabsGqlTatEventRef>;
  /** The event associated with the start of the activity. */
  startEvent?: Maybe<LabsGqlTatEventRef>;
  /** The type of activity. */
  type: LabsGqlTatActivityType;
};

/** A complete activity's duration. */
export type LabsGqlTatCompleteActivityDuration = {
  __typename?: 'TatCompleteActivityDuration';
  /** The actual duration. */
  actual: LabsGqlTatDuration;
  /** The expected duration. */
  expected: LabsGqlTatDuration;
};

/** The activity that is currently due. */
export type LabsGqlTatCurrentActivity = {
  __typename?: 'TatCurrentActivity';
  /** Adjustments that affect the due date. */
  adjustments: Array<LabsGqlTatAdjustment>;
  /** The business day when the activity is due. */
  due: Scalars['DateTime']['output'];
  /** The expected duration. */
  duration: LabsGqlTatDuration;
  /** The event associated with the start of the activity. */
  startEvent?: Maybe<LabsGqlTatEventRef>;
  /** The type of activity. */
  type: LabsGqlTatActivityType;
};

/** A duration in business days and/or hours. */
export type LabsGqlTatDuration = {
  __typename?: 'TatDuration';
  /** The number of business days. */
  days: Scalars['Int']['output'];
  /** The number of business hours. */
  hours: Scalars['Int']['output'];
};

/** A reference to an event. */
export type LabsGqlTatEventRef = {
  __typename?: 'TatEventRef';
  /** When the event was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The id of the event. */
  id: Scalars['Int']['output'];
  /** The name of the event. */
  name: Scalars['String']['output'];
};

/** The item lead time for order items. */
export type LabsGqlTatItemLeadTimeDto = {
  __typename?: 'TatItemLeadTimeDto';
  /** The catalog time from Dandy's marketing, in hours. */
  catalogTime?: Maybe<Scalars['Int']['output']>;
  /** When the model was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The primary key. */
  id: Scalars['String']['output'];
  /** The lead time in hours grouped by type. */
  leadTimeByType: LabsGqlTatLeadTimeByTypeDto;
  /** The over limit catalog time from Dandy's marketing, in hours. */
  overLimitCatalogTime?: Maybe<Scalars['Int']['output']>;
  /** The lead time in hours grouped by type when the unit count is over the limit. */
  overLimitLeadTimeByType?: Maybe<LabsGqlTatLeadTimeByTypeDto>;
  /** The maximum unit count before applying the over limit lead times. */
  unitCountLimit?: Maybe<Scalars['Int']['output']>;
  /** When the model was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

/** The organization-specific lead time overrides for order items. */
export type LabsGqlTatItemLeadTimeOverrideDto = {
  __typename?: 'TatItemLeadTimeOverrideDto';
  /** The catalog time from Dandy's marketing, in hours. */
  catalogTime?: Maybe<Scalars['Int']['output']>;
  /** When the model was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Indicates if this override is disabled or not. */
  disabled: Scalars['Boolean']['output'];
  /** The primary key. */
  id: Scalars['String']['output'];
  /** The lead time in hours grouped by type. */
  leadTimeByType: LabsGqlTatLeadTimeByTypeDto;
  /** The organization ID this override applies to. */
  organizationId: Scalars['String']['output'];
  /** The over limit catalog time from Dandy's marketing, in hours. */
  overLimitCatalogTime?: Maybe<Scalars['Int']['output']>;
  /** The lead time in hours grouped by type when the unit count is over the limit. */
  overLimitLeadTimeByType?: Maybe<LabsGqlTatLeadTimeByTypeDto>;
  /** The maximum unit count before applying the over limit lead times. */
  unitCountLimit?: Maybe<Scalars['Int']['output']>;
  /** When the model was last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type LabsGqlTatLeadTimeByTypeArgsDto = {
  /** Time allocated for unexpected delays. */
  buffer?: InputMaybe<Scalars['Int']['input']>;
  /** Time allocated for design. */
  design?: InputMaybe<Scalars['Int']['input']>;
  /** Time allocated for fabrication. */
  fabrication?: InputMaybe<Scalars['Int']['input']>;
  /** Time allocated for review by Dandy internally. */
  orderReview?: InputMaybe<Scalars['Int']['input']>;
  /** Time allocated for shipping to the practice. */
  shipping?: InputMaybe<Scalars['Int']['input']>;
  /** Time allocated for waxup review. */
  waxupReview?: InputMaybe<Scalars['Int']['input']>;
};

/** Lead time in hours grouped by lead time type. */
export type LabsGqlTatLeadTimeByTypeDto = {
  __typename?: 'TatLeadTimeByTypeDto';
  /** Time allocated for unexpected delays. */
  buffer: Scalars['Int']['output'];
  /** Time allocated for design. */
  design: Scalars['Int']['output'];
  /** Time allocated for fabrication. */
  fabrication: Scalars['Int']['output'];
  /** Time allocated for review by Dandy internally. */
  orderReview: Scalars['Int']['output'];
  /** Time allocated for shipping to the practice. */
  shipping: Scalars['Int']['output'];
  /** Time allocated for waxup review. */
  waxupReview: Scalars['Int']['output'];
};

/** The plan for delivering a lab order. */
export type LabsGqlTatNonNullablePlan = {
  __typename?: 'TatNonNullablePlan';
  /** The sequence of planned activities. */
  activities: Array<LabsGqlTatPlannedActivity>;
  /** Extra time for unplanned events. */
  buffer: LabsGqlTatDuration;
  /**
   * When we committed to deliver the lab order.
   *
   * Includes the buffer.
   */
  committed: Scalars['DateTime']['output'];
  /** The earliest estimate of when the plan might complete. */
  eta: Scalars['DateTime']['output'];
  /** Whether the plan was rushed. */
  rushed: Scalars['Boolean']['output'];
};

/** The plan for delivering a lab order. */
export type LabsGqlTatPlan = {
  __typename?: 'TatPlan';
  /** The sequence of planned activities. */
  activities: Array<LabsGqlTatPlannedActivity>;
  /** Extra time for unplanned events. */
  buffer: LabsGqlTatDuration;
  /**
   * When we committed to deliver the lab order.
   *
   * Includes the buffer.
   */
  committed: Scalars['DateTime']['output'];
  /**
   * The earliest estimate of when the plan might complete.
   *
   * Does not include the buffer. Null if the order is canceled or delivered.
   */
  eta?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the plan was rushed. */
  rushed: Scalars['Boolean']['output'];
};

/** An activity that is planned to occur. */
export type LabsGqlTatPlannedActivity = {
  __typename?: 'TatPlannedActivity';
  /** Adjustments that affect the eta. */
  adjustments: Array<LabsGqlTatAdjustment>;
  /** The expected duration. */
  duration: LabsGqlTatDuration;
  /**
   * The earliest estimate of when the activity might complete.
   *
   * Null if the order is canceled.
   */
  eta?: Maybe<Scalars['DateTime']['output']>;
  /** The recommended start time for the activity */
  recommendedStart?: Maybe<Scalars['DateTime']['output']>;
  /** The type of activity. */
  type: LabsGqlTatActivityType;
};

/** Progress made on a plan. */
export type LabsGqlTatProgress = {
  __typename?: 'TatProgress';
  /** The complete activities. */
  complete: Array<LabsGqlTatCompleteActivity>;
  /** The current activity. */
  current?: Maybe<LabsGqlTatCurrentActivity>;
};

export type LabsGqlTestOrderAutomationResult = {
  __typename?: 'TestOrderAutomationResult';
  success: Scalars['Boolean']['output'];
  summary: Scalars['String']['output'];
};

export enum LabsGqlThreeShapeActorRoleType {
  Clinic = 'Clinic',
  ContactPerson = 'ContactPerson',
  Customer = 'Customer',
  Lab = 'Lab',
  Manufacturer = 'Manufacturer',
  Operator = 'Operator',
  Patient = 'Patient',
  Receiver = 'Receiver',
  Sender = 'Sender',
  ShipTo = 'ShipTo',
  Unknown = 'Unknown'
}

export type LabsGqlThreeshapeAutomateCaseResult = {
  __typename?: 'ThreeshapeAutomateCaseResult';
  id: Scalars['String']['output'];
  resultsUrl?: Maybe<Scalars['String']['output']>;
  status: LabsGqlThreeshapeAutomateCaseResultStatus;
};

export type LabsGqlThreeshapeAutomateCaseResultStatus = {
  __typename?: 'ThreeshapeAutomateCaseResultStatus';
  accepted: Scalars['Boolean']['output'];
  downloaded: Scalars['Boolean']['output'];
  editsRequested: Scalars['Boolean']['output'];
  failed: Scalars['Boolean']['output'];
  failureCode: Scalars['Float']['output'];
  message: Scalars['String']['output'];
  processing: Scalars['Boolean']['output'];
  rejected: Scalars['Boolean']['output'];
  reviewable: Scalars['Boolean']['output'];
  statusCode: Scalars['Float']['output'];
  successful: Scalars['Boolean']['output'];
  uploading: Scalars['Boolean']['output'];
};

export type LabsGqlThreeshapeDmeDto = {
  __typename?: 'ThreeshapeDmeDTO';
  created_at: Scalars['DateTime']['output'];
  current_version: Scalars['Int']['output'];
  file_path: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_archived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

/** Details of a client-side Threeshape Plugin page operation */
export type LabsGqlThreeshapePluginOperationInput = {
  designOrderId: Scalars['String']['input'];
  error?: InputMaybe<Scalars['String']['input']>;
  operation: LabsGqlThreeshapePluginOperationTypeInput;
  taskType: LabsGqlDesignTaskType;
};

export enum LabsGqlThreeshapePluginOperationTypeInput {
  DownloadComplete = 'DOWNLOAD_COMPLETE',
  DownloadFailed = 'DOWNLOAD_FAILED',
  DownloadSkipped = 'DOWNLOAD_SKIPPED',
  DownloadStarting = 'DOWNLOAD_STARTING',
  UploadComplete = 'UPLOAD_COMPLETE',
  UploadFailed = 'UPLOAD_FAILED',
  UploadSkipped = 'UPLOAD_SKIPPED',
  UploadStarting = 'UPLOAD_STARTING'
}

export type LabsGqlThreeshapeScanNotification = {
  case_id: Scalars['String']['input'];
  case_items_raw?: InputMaybe<Scalars['String']['input']>;
  case_materials_raw?: InputMaybe<Scalars['String']['input']>;
  case_shades_raw?: InputMaybe<Scalars['String']['input']>;
  created_at: Scalars['DateTime']['input'];
  filename?: InputMaybe<Scalars['String']['input']>;
  from_tooth?: InputMaybe<Scalars['Int']['input']>;
  machine_id: Scalars['String']['input'];
  patient_id?: InputMaybe<Scalars['String']['input']>;
  scan_id?: InputMaybe<Scalars['String']['input']>;
  to_tooth?: InputMaybe<Scalars['Int']['input']>;
};

export enum LabsGqlTicketActionCompletionResolution {
  Abort = 'Abort',
  Insert = 'Insert',
  Proceed = 'Proceed'
}

export type LabsGqlTicketActionCreationInputData = {
  activate_at?: InputMaybe<Scalars['DateTime']['input']>;
  due_at?: InputMaybe<Scalars['DateTime']['input']>;
  enable_followup_action: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  type_id: Scalars['String']['input'];
};

export type LabsGqlTicketActionDto = {
  __typename?: 'TicketActionDTO';
  activate_at?: Maybe<Scalars['DateTime']['output']>;
  assigned_user_id?: Maybe<Scalars['ID']['output']>;
  closed_at?: Maybe<Scalars['DateTime']['output']>;
  closed_by_user_id?: Maybe<Scalars['ID']['output']>;
  closing_notes?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  created_by_user_id: Scalars['ID']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  followup_id?: Maybe<Scalars['String']['output']>;
  followup_parent_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  opening_notes?: Maybe<Scalars['String']['output']>;
  status: LabsGqlActionStatus;
  type_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlTicketDto = {
  __typename?: 'TicketDTO';
  action_type_ids?: Maybe<Array<Scalars['ID']['output']>>;
  actions: Array<LabsGqlTicketActionDto>;
  active_action?: Maybe<LabsGqlTicketActionDto>;
  created_at: Scalars['DateTime']['output'];
  created_by_automation_event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  is_open: Scalars['Boolean']['output'];
  order_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlTicketFilterInput = {
  filters: LabsGqlTicketFiltersInput;
  pagination: LabsGqlPagination;
};

export type LabsGqlTicketFiltersInput = {
  action_type_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  active_action_is_workable_now?: InputMaybe<Scalars['Boolean']['input']>;
  assigned_user_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  is_open?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturer_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  order_tag_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  practice_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  ticket_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LabsGqlTicketWithPracticeAndOrderInfo = {
  __typename?: 'TicketWithPracticeAndOrderInfo';
  action_type_ids?: Maybe<Array<Scalars['ID']['output']>>;
  actions: Array<LabsGqlTicketActionDto>;
  active_action?: Maybe<LabsGqlTicketActionDto>;
  created_at: Scalars['DateTime']['output'];
  created_by_automation_event_id?: Maybe<Scalars['Int']['output']>;
  doctor_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_open: Scalars['Boolean']['output'];
  items_summary?: Maybe<Scalars['String']['output']>;
  manufacturer_id?: Maybe<Scalars['String']['output']>;
  order_id: Scalars['ID']['output'];
  order_status?: Maybe<LabsGqlLabOrderStatus>;
  partner_id: Scalars['ID']['output'];
  patient_name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlTicketingSavedSearch = {
  __typename?: 'TicketingSavedSearch';
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  favorited_by: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  search: LabsGqlTicketsFilters;
  updated_at: Scalars['DateTime']['output'];
  visibility: LabsGqlSavedSearchVisibility;
};

export type LabsGqlTicketsFilters = {
  __typename?: 'TicketsFilters';
  action_type_ids?: Maybe<Array<Scalars['String']['output']>>;
  active_action_is_workable_now?: Maybe<Scalars['Boolean']['output']>;
  assigned_user_ids?: Maybe<Array<Scalars['String']['output']>>;
  is_open?: Maybe<Scalars['Boolean']['output']>;
  manufacturer_ids?: Maybe<Array<Scalars['String']['output']>>;
  order_tag_ids?: Maybe<Array<Scalars['String']['output']>>;
  practice_ids?: Maybe<Array<Scalars['String']['output']>>;
  ticket_ids?: Maybe<Array<Scalars['String']['output']>>;
};

export type LabsGqlTierBreakpointDto = {
  __typename?: 'TierBreakpointDto';
  breakpoint: Scalars['Int']['output'];
  tier_number: Scalars['Int']['output'];
};

export type LabsGqlTieredPricingConfigEntityDto = {
  __typename?: 'TieredPricingConfigEntityDto';
  created_at: Scalars['DateTime']['output'];
  practice_contract_id: Scalars['ID']['output'];
  /** Cent amounts at which each tier starts. */
  tier_breakpoints: Array<LabsGqlTierBreakpointDto>;
  /** Whether tiered pricing is enabled for the given contract. */
  tiered_pricing_enabled: Scalars['Boolean']['output'];
  tiered_pricing_start_date?: Maybe<Scalars['DateTime']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export enum LabsGqlTimeConstraintCustomConfigType {
  Duration = 'Duration',
  NextEncounter = 'NextEncounter'
}

export type LabsGqlTimelineAttachment = {
  __typename?: 'TimelineAttachment';
  description?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type LabsGqlTimelineItemActor = {
  __typename?: 'TimelineItemActor';
  actor: Scalars['String']['output'];
  actor_id?: Maybe<Scalars['String']['output']>;
  user_display_text?: Maybe<Scalars['String']['output']>;
  user_email?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlTimelineItemLink = {
  __typename?: 'TimelineItemLink';
  href: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type LabsGqlTimelineItemUnion = LabsGqlOrderTimelineItem | LabsGqlPracticeTimelineItem;

export type LabsGqlToggleArchiveCategoryCommand = {
  archived: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type LabsGqlToggleLabCaseLedgerEntryReviewedCommand = {
  ledger_id: Scalars['String']['input'];
  target_value: Scalars['Boolean']['input'];
};

export type LabsGqlToggleLabLedgerEntryReviewedCommand = {
  ledger_id: Scalars['String']['input'];
  target_value: Scalars['Boolean']['input'];
};

export type LabsGqlToggleLabPriceDeletionCommand = {
  lab_price_id: Scalars['String']['input'];
};

export type LabsGqlToggleWillBeChargedCcFeeEntry = {
  partner_id: Scalars['String']['input'];
  will_be_charged_cc_fee: Scalars['Boolean']['input'];
};

/** A tooth coordinate. */
export type LabsGqlToothCoordinateDto = {
  __typename?: 'ToothCoordinateDto';
  /** The x coordinate of the tooth. */
  x: Scalars['Float']['output'];
  /** The y coordinate of the tooth. */
  y: Scalars['Float']['output'];
};

/** A tooth shade suggestion. */
export type LabsGqlToothShadeSuggestionDto = {
  __typename?: 'ToothShadeSuggestionDto';
  /** The shade of the tooth. */
  shade: Scalars['String']['output'];
  /** The shade type of the tooth. */
  shadeType: Scalars['String']['output'];
  /** The unn of the tooth. */
  unn: Scalars['Float']['output'];
};

/** training appointment */
export type LabsGqlTraining = {
  __typename?: 'Training';
  fcm_complete?: Maybe<Scalars['Boolean']['output']>;
  fcm_complete_updated_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  organization_id: Scalars['ID']['output'];
  start_date?: Maybe<Scalars['DateTime']['output']>;
  start_date_updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlTrainingIntakeResponseInput = {
  experience?: InputMaybe<Scalars['String']['input']>;
  material?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  prepStyles?: InputMaybe<Array<Scalars['String']['input']>>;
  retractions?: InputMaybe<Array<Scalars['String']['input']>>;
  role: Scalars['String']['input'];
  scanner?: InputMaybe<Scalars['String']['input']>;
  willAdapt?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlTransitionOnboardingStepInput = {
  practice_id: Scalars['String']['input'];
  review_prefs_on_getting_started_call?: InputMaybe<Scalars['Boolean']['input']>;
  set_dandy_as_preferred_lab?: InputMaybe<LabsGqlSetDandyAsPreferredEnum>;
  staff_member_id: Scalars['String']['input'];
  steps: Array<LabsGqlOnboardingStepInput>;
};

export enum LabsGqlTreatmentPlanProvider {
  AlignerConcierge = 'ALIGNER_CONCIERGE',
  GlobalOrtho = 'GLOBAL_ORTHO',
  InternalDesign = 'INTERNAL_DESIGN'
}

export enum LabsGqlTreatmentPlanType {
  ArchForm = 'ArchForm',
  OnyxCeph = 'OnyxCeph',
  SoftSmile = 'SoftSmile'
}

export type LabsGqlTruckTimeEntityTimeStruct = {
  __typename?: 'TruckTimeEntityTimeStruct';
  hour: Scalars['Float']['output'];
  minute: Scalars['Float']['output'];
};

export type LabsGqlTryInFeedbackDto = {
  __typename?: 'TryInFeedbackDTO';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order_id: Scalars['ID']['output'];
  try_in_feedback_details: Array<LabsGqlTryInFeedbackDetailDto>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlTryInFeedbackDetailDto = {
  __typename?: 'TryInFeedbackDetailDTO';
  arch: LabsGqlOrderItemArch;
  category: Scalars['String']['output'];
  details: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  item_display_name?: Maybe<Scalars['String']['output']>;
  item_id: Scalars['String']['output'];
  try_in_feedback_id: Scalars['ID']['output'];
};

export type LabsGqlTryInFeedbackDetailForLabSlip = {
  __typename?: 'TryInFeedbackDetailForLabSlip';
  arch: LabsGqlOrderItemArch;
  category: Scalars['String']['output'];
  details: Scalars['String']['output'];
};

export type LabsGqlTryInFeedbackDetailInput = {
  arch: LabsGqlOrderItemArch;
  category: Scalars['String']['input'];
  details: Scalars['String']['input'];
  item_id: Scalars['String']['input'];
};

export type LabsGqlTypeIdImplantDetails = {
  __typename?: 'TypeIDImplantDetails';
  Connection: Scalars['String']['output'];
  Manufacturer: Scalars['String']['output'];
  System: Scalars['String']['output'];
};

export type LabsGqlUnapprovedAlignerTreatmentPlan = LabsGqlIAlignerTreatmentPlan & {
  __typename?: 'UnapprovedAlignerTreatmentPlan';
  duration_in_months: Scalars['Int']['output'];
  /** Denotes that the plan was modified by the doctor and will need review by a designer before fabrication. */
  modified_by_doctor?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use needs_attachments_upper and needs_attachments_lower on the treatment plan instead */
  needs_attachments: Scalars['Boolean']['output'];
  needs_attachments_lower?: Maybe<Scalars['Boolean']['output']>;
  needs_attachments_upper?: Maybe<Scalars['Boolean']['output']>;
  needs_ipr: Scalars['Boolean']['output'];
  plan_type: LabsGqlTreatmentPlanType;
  request: LabsGqlConvertedTreatmentPlanRequest;
  softsmile?: Maybe<LabsGqlSoftSmileData>;
  steps: Array<LabsGqlAlignerTreatmentPlanStep>;
};

export type LabsGqlUnarchiveAbutmentPartCommand = {
  abutment_part_id: Scalars['String']['input'];
};

export type LabsGqlUnarchiveThreeshapeDmeCommand = {
  dme_id: Scalars['String']['input'];
};

export type LabsGqlUnconvertedTreatmentPlanRequest = LabsGqlICompletedTreatmentPlanRequest & LabsGqlITreatmentPlanRequest & {
  __typename?: 'UnconvertedTreatmentPlanRequest';
  completed_at: Scalars['DateTime']['output'];
  created_at: Scalars['DateTime']['output'];
  expected_completion_date: Scalars['DateTime']['output'];
  /** ID in external providers system */
  id: Scalars['String']['output'];
  provider: LabsGqlTreatmentPlanProvider;
  url: Scalars['String']['output'];
};

export type LabsGqlUndeleteTagCommand = {
  id: Scalars['String']['input'];
};

export type LabsGqlUndoLinkOrderToRefabricationCommand = {
  original_order_id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type LabsGqlUnfavoriteSavedSearchCommand = {
  search_id: Scalars['String']['input'];
};

export type LabsGqlUnfavoriteTicketSavedSearchCommand = {
  search_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type LabsGqlUnitAndPreferenceInfoDto = {
  __typename?: 'UnitAndPreferenceInfoDTO';
  arch?: Maybe<Scalars['String']['output']>;
  material?: Maybe<Scalars['String']['output']>;
  /** The weight of precious metal being priced */
  precious_metal_pennyweight?: Maybe<Scalars['Float']['output']>;
  preference_field_id?: Maybe<Scalars['String']['output']>;
  unit_type: Scalars['String']['output'];
  unn?: Maybe<Scalars['Int']['output']>;
  unns?: Maybe<Array<Scalars['Int']['output']>>;
};

export type LabsGqlUnknownItem = LabsGqlIOrderItemBase & {
  __typename?: 'UnknownItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
};

export type LabsGqlUnknownItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
};

export type LabsGqlUnlinkOrganizationExternalAccountCommand = {
  organization_id: Scalars['String']['input'];
  type: LabsGqlExternalAccountRelationshipType;
};

export type LabsGqlUnlinkedScanbodyInput = {
  is_authentic: Scalars['Boolean']['input'];
  scanbody_id: Scalars['ID']['input'];
};

/** This is now deprecated, please use RemoveEntityTag */
export type LabsGqlUntagEntityCommand = {
  entity_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};

export type LabsGqlUpdateAbutmentPartCommand = {
  abutment_part_id: Scalars['String']['input'];
  analog_sku?: InputMaybe<Scalars['String']['input']>;
  diameter_mm?: InputMaybe<Scalars['Float']['input']>;
  engagement: LabsGqlAbutmentPartEngagement;
  gingival_height_mm?: InputMaybe<Scalars['Float']['input']>;
  manufacturer: Scalars['String']['input'];
  material: LabsGqlAbutmentPartMaterial;
  screw_sku?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  three_shape_category: Scalars['String']['input'];
  three_shape_kit: Scalars['String']['input'];
  three_shape_manufacturer: Scalars['String']['input'];
  three_shape_manufacturing_process: Scalars['String']['input'];
  three_shape_material: Scalars['String']['input'];
  three_shape_system: Scalars['String']['input'];
  top_cap_height_mm?: InputMaybe<Scalars['Float']['input']>;
};

export type LabsGqlUpdateAbutmentPartToImplantScanbodyCommand = {
  abutment_part_id: Scalars['String']['input'];
  implant_to_scanbody_id: Scalars['String']['input'];
  implant_type_id: Scalars['String']['input'];
  priority: Scalars['Float']['input'];
};

export type LabsGqlUpdateActionCapabilityCommand = {
  capability_id: Scalars['ID']['input'];
  is_archived: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type LabsGqlUpdateActionCategoryCommand = {
  category_id: Scalars['ID']['input'];
  is_archived: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  parent_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdateActionTypeCommand = {
  capability_ids: Array<Scalars['String']['input']>;
  category_id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  is_archived: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  time_constraint_config_json?: InputMaybe<Scalars['String']['input']>;
  type_id: Scalars['String']['input'];
};

export type LabsGqlUpdateBiasRuleCommand = {
  condition_json: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rule_id: Scalars['ID']['input'];
  score?: InputMaybe<Scalars['Int']['input']>;
  veto: Scalars['Boolean']['input'];
};

export type LabsGqlUpdateBillingCreditCategoryCommand = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdateCrmChatConversationArgs = {
  conversation_id: Scalars['String']['input'];
  metadata: Array<LabsGqlCrmChatConversationMetadataInput>;
};

export type LabsGqlUpdateCrmTicketArgs = {
  custom_fields: Array<LabsGqlCrmTicketCustomFieldValueInput>;
  status?: InputMaybe<LabsGqlCrmTicketStatus>;
  ticket_id: Scalars['String']['input'];
};

export type LabsGqlUpdateCapacityRuleCommand = {
  active_sla_days?: InputMaybe<Array<LabsGqlDayOfWeek>>;
  fabrication_days_rule_set?: InputMaybe<Array<LabsGqlFabricationDaysRuleInput>>;
  item_condition_json?: InputMaybe<Scalars['String']['input']>;
  limit_range: LabsGqlRoutingMetricsRange;
  manufacturer_id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order_condition_json?: InputMaybe<Scalars['String']['input']>;
  order_limit?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  rule_id: Scalars['ID']['input'];
  supports_design?: InputMaybe<Scalars['Boolean']['input']>;
  supports_manufacturing?: InputMaybe<Scalars['Boolean']['input']>;
  unit_limit?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlUpdateCapacityStationArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LabsGqlUpdateCapacityStationPoolArgs = {
  id: Scalars['String']['input'];
  unit_limit: Scalars['Int']['input'];
};

export type LabsGqlUpdateCapacityStationRuleArgs = {
  capacity_station_id: Scalars['String']['input'];
  default_unit_limit: Scalars['Int']['input'];
  design_station_codes?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  lab_product_codes?: InputMaybe<Array<Scalars['String']['input']>>;
  matching_logic_json?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  work_order_matching_logic_json?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdateCategoryCommand = {
  archived: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  requires_further_info: Scalars['Boolean']['input'];
  subcategories?: InputMaybe<Array<LabsGqlUpdateSubcategoryInput>>;
  visible_to_internal: Scalars['Boolean']['input'];
  visible_to_lab: Scalars['Boolean']['input'];
  visible_to_practice: Scalars['Boolean']['input'];
};

export type LabsGqlUpdateCurrentRetainerCommand = {
  lab_order_id: Scalars['String']['input'];
  retainer: LabsGqlRetainerInput;
  retainerId: Scalars['String']['input'];
};

export type LabsGqlUpdateDesignReviewFieldCommand = {
  applicable_order_types: Array<LabsGqlDesignReviewFieldFilterType>;
  archived: Scalars['Boolean']['input'];
  category: Scalars['String']['input'];
  design_field_id: Scalars['String']['input'];
  field: LabsGqlDesignReviewFormFieldInput;
  name: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
  types: Array<LabsGqlDesignReviewFieldType>;
};

export type LabsGqlUpdateDesignRuleCommand = {
  item_condition_json: Scalars['String']['input'];
  limit_range: LabsGqlRoutingMetricsRange;
  name: Scalars['String']['input'];
  order_condition_json: Scalars['String']['input'];
  order_limit?: InputMaybe<Scalars['Int']['input']>;
  rule_id: Scalars['ID']['input'];
  unit_limit?: InputMaybe<Scalars['Int']['input']>;
};

export type LabsGqlUpdateDesignStaffRosterInfoCommand = {
  country?: InputMaybe<LabsGqlDesignStaffEligibleCountry>;
  design_staff_qc_percentage_required?: InputMaybe<Scalars['Int']['input']>;
  design_staff_region?: InputMaybe<LabsGqlDesignStaffRegion>;
  design_staff_role?: InputMaybe<LabsGqlDesignStaffRole>;
  design_staff_team_id?: InputMaybe<Scalars['ID']['input']>;
  employment_type?: InputMaybe<LabsGqlDesignStaffEmploymentType>;
  ordered_main_capabilities?: InputMaybe<Array<LabsGqlOrderDesignCapabilityType>>;
  schedule?: InputMaybe<LabsGqlShiftScheduleInput>;
  user_id: Scalars['ID']['input'];
};

export type LabsGqlUpdateDesignStaffStatusCommand = {
  assignable_task_types?: InputMaybe<Array<LabsGqlDesignStaffTaskType>>;
  away_length_minutes?: InputMaybe<Scalars['Float']['input']>;
  status: LabsGqlDesignStaffStatus;
  user_id: Scalars['ID']['input'];
};

export type LabsGqlUpdateExternalStaffMemberRolesCommand = {
  member_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRoleExternal>;
};

export type LabsGqlUpdateImplantTypeCommand = {
  connection?: InputMaybe<Scalars['String']['input']>;
  connection_type?: InputMaybe<Scalars['String']['input']>;
  implantToScanbodies?: InputMaybe<Array<LabsGqlImplantToScanbodiesInput>>;
  implant_type_id: Scalars['String']['input'];
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  system?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdateInternalStaffMemberRolesCommand = {
  member_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRoleInternal>;
};

export type LabsGqlUpdateInvoiceCreditInput = {
  amount_issued_cents?: InputMaybe<Scalars['Int']['input']>;
  credit_category_id?: InputMaybe<Scalars['ID']['input']>;
  credit_id: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LabsGqlUpdateLabCaseLedgerEntryCommand = {
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  ledger_id: Scalars['String']['input'];
  prices?: InputMaybe<Array<LabsGqlLabPriceLedgerPriceInput>>;
  ship_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LabsGqlUpdateLabPriceCommand = {
  configs?: InputMaybe<Array<LabsGqlLabPriceConfigInput>>;
  default_practice_price_cents?: InputMaybe<Scalars['Float']['input']>;
  lab_price_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rule: LabsGqlLabPriceRuleInput;
};

export type LabsGqlUpdateLabStaffMemberRolesCommand = {
  member_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRoleLab>;
};

export type LabsGqlUpdateManufacturerTruckTimePayload = {
  carrier: Scalars['String']['input'];
  days_of_week: Array<LabsGqlDayOfWeek>;
  id: Scalars['String']['input'];
  label_print_cutoff_time: LabsGqlManufacturerTruckTimeEntityTimeInput;
  manufacturer_id: Scalars['String']['input'];
  pickup_time: LabsGqlManufacturerTruckTimeEntityTimeInput;
  supports_next_day: Scalars['Boolean']['input'];
};

export type LabsGqlUpdateOrderAutomationCommand = {
  actions: Array<LabsGqlAutomationActionConfigInput>;
  description: Scalars['String']['input'];
  filter: LabsGqlFilterSubmissionInput;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  triggered_by: Array<LabsGqlAutomationTriggerEventNamesEnum>;
};

export type LabsGqlUpdateOrderClinicalSupportNotesCommand = {
  notes?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
};

export type LabsGqlUpdateOrderHoldCommand = {
  hold_details?: InputMaybe<LabsGqlHoldDetails>;
  order_id: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  show_hold_status_to_practice: Scalars['Boolean']['input'];
};

export type LabsGqlUpdateOrderItemPennyweightsV2Command = {
  itemId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  precious_metal_pennyweights: Array<LabsGqlUpdateSingleUnnPennyweightInput>;
};

export type LabsGqlUpdateOrderItemsV2Command = {
  items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
  orderId: Scalars['String']['input'];
};

/** Updates items in an order for practice-initiated edits. This includes both self-serve and edits initiated on their behalf internally by CX. */
export type LabsGqlUpdateOrderItemsV2PracticeCommand = {
  items_v2_by_sku: LabsGqlLabOrderItemV2InputBySku;
  orderId: Scalars['String']['input'];
};

export type LabsGqlUpdateOrderPriceEntryCommand = {
  order_price_id: Scalars['String']['input'];
  override_total_price_cents: Scalars['Int']['input'];
  override_total_price_cents_reason?: InputMaybe<Scalars['String']['input']>;
};

/** the entire payload for updating a rush request configuration. */
export type LabsGqlUpdateOrderRushConfigurationPayload = {
  order_id: Scalars['String']['input'];
  /** Sets the shipping speed override for the order. If not provided, will retain the present value. The default is null. */
  shipping_speed?: InputMaybe<LabsGqlPortalShipShippingSpeed>;
};

export type LabsGqlUpdateOrganizationNameCommand = {
  legal_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
};

export type LabsGqlUpdateOrganizationParentIdCommand = {
  organization_id: Scalars['String']['input'];
  parent_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdateOrganizationPhoneNumberCommand = {
  organization_id: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
};

export type LabsGqlUpdateOrganizationPriceConfigCommand = {
  id: Scalars['String']['input'];
  price_cents: Scalars['Float']['input'];
};

export type LabsGqlUpdateOrganizationSettingsCommand = {
  externalAccountUpdates?: InputMaybe<Array<LabsGqlOrganizationExternalAccountUpdate>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdateOrganizationStaffMemberZendeskIdCommand = {
  member_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  zendesk_contact_id?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdateOrganizationTypeCommand = {
  organization_id: Scalars['String']['input'];
  type: LabsGqlOrganizationType;
};

export type LabsGqlUpdateParentStaffMemberRolesCommand = {
  member_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRoleParent>;
};

export type LabsGqlUpdatePartialDentureMetadataCommand = {
  clasp_type?: InputMaybe<LabsGqlOrderItemPartialDentureClaspType>;
  clasps?: InputMaybe<Array<LabsGqlOrderItemPartialDentureClaspInput>>;
  coverage_type?: InputMaybe<LabsGqlOrderItemPartialDentureCoverageType>;
  itemId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type LabsGqlUpdatePracticeOrderingPauseReasons = {
  practice_id: Scalars['String']['input'];
  reasons: Array<LabsGqlOrderingPausedPracticeReason>;
};

export type LabsGqlUpdatePracticeSettingsCommand = {
  name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdatePracticeStaffMemberRolesCommand = {
  member_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  roles: Array<LabsGqlStaffRolePractice>;
};

export type LabsGqlUpdatePracticeSupportCommand = {
  agreement_version?: InputMaybe<Scalars['String']['input']>;
  pod_id?: InputMaybe<Scalars['String']['input']>;
  practice_id: Scalars['String']['input'];
  software_type?: InputMaybe<LabsGqlPracticeSoftwareType>;
};

export type LabsGqlUpdatePricePresetCommand = {
  cart_field_price_defaults?: InputMaybe<Array<LabsGqlCartFieldDefaultPriceInput>>;
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  price_defaults?: InputMaybe<Array<LabsGqlDefaultPriceUpdateInput>>;
};

export type LabsGqlUpdatePrimaryBillingContactUserCommand = {
  new_primary_billing_contact_user_id: Scalars['String']['input'];
  new_primary_billing_contact_user_type: LabsGqlBillingContactUserType;
  partner_id: Scalars['String']['input'];
};

export type LabsGqlUpdatePrimaryBillingContactUserIdCommand = {
  new_primary_billing_contact_user_id: Scalars['String']['input'];
  partner_id: Scalars['String']['input'];
};

export type LabsGqlUpdateProductStationUnitMappingArgs = {
  id: Scalars['String']['input'];
  units: Scalars['Int']['input'];
};

export type LabsGqlUpdateQcIssueCommand = {
  archived: Scalars['Boolean']['input'];
  filters?: InputMaybe<Array<LabsGqlFilterSubmissionInput>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  prompt: Scalars['String']['input'];
  sub_issues?: InputMaybe<Array<LabsGqlQcSubIssueInput>>;
};

export type LabsGqlUpdateReasonCodeCommand = {
  additional_info_request: Array<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  default_fault?: InputMaybe<LabsGqlReasonCodeFault>;
  form_display?: InputMaybe<LabsGqlFormDisplay>;
  group?: InputMaybe<LabsGqlReasonCodeGroup>;
  internal_only?: InputMaybe<Scalars['Boolean']['input']>;
  reason_id: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdateRefabReasonCommand = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  default_fault?: InputMaybe<LabsGqlRefabFault>;
  internal_only?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reason_id: Scalars['String']['input'];
};

export type LabsGqlUpdateRefundCategoryInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlUpdateRegisteredInvoiceItemCommand = {
  category: LabsGqlOneTimeChargeCategory;
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  price_cents: Scalars['Float']['input'];
};

export type LabsGqlUpdateReviewSubmissionItemTagsCommand = {
  item_id: Scalars['String']['input'];
  lab_order_id: Scalars['String']['input'];
  tags: Array<LabsGqlReviewTagSubmissionInput>;
};

export type LabsGqlUpdateReviewTagCommand = {
  applicable_ratings: Array<LabsGqlReviewRating>;
  applicable_sections: Array<LabsGqlReviewSection>;
  archived: Scalars['Boolean']['input'];
  category?: InputMaybe<Scalars['String']['input']>;
  display_rules: Array<LabsGqlCustomFieldDisplayRuleInput>;
  field_id: Scalars['String']['input'];
  related_cart_field_ids: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type LabsGqlUpdateRoutingTargetDistributionRuleCommand = {
  condition_json: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Float']['input']>;
  routing_target_distribution_items: Array<LabsGqlRoutingTargetDistributionItemInput>;
  rule_id: Scalars['ID']['input'];
};

export type LabsGqlUpdateScanbodyCommand = {
  id: Scalars['String']['input'];
  manufacturer: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sku: Scalars['String']['input'];
};

export type LabsGqlUpdateScanbodyRequestCommand = {
  address: Scalars['String']['input'];
  appointment_date: Scalars['DateTime']['input'];
  connection: Scalars['String']['input'];
  id: Scalars['String']['input'];
  manufacturer: Scalars['String']['input'];
  system: Scalars['String']['input'];
};

export type LabsGqlUpdateSingleUnnPennyweightInput = {
  pennyweight?: InputMaybe<Scalars['Float']['input']>;
  /** The unn of the tooth to be updated */
  unn: Scalars['ToothNumber']['input'];
};

export type LabsGqlUpdateStaffPreferencesInput = {
  set_dandy_as_preferred_lab_status?: InputMaybe<Scalars['String']['input']>;
  wants_preference_review?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabsGqlUpdateSubcategoryInput = {
  archived: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  requires_further_info: Scalars['Boolean']['input'];
};

export type LabsGqlUpdateThreeshapeDmeFilePathCommand = {
  dme_id: Scalars['String']['input'];
  file_path: Scalars['String']['input'];
};

export type LabsGqlUpdateUserCommand = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  is_mobile_verified?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  mobile_phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  salesforce_contact_id?: InputMaybe<Scalars['String']['input']>;
  skip_legacy_user_backfill?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['String']['input'];
};

export type LabsGqlUpdateUserLoginPhoneCommand = {
  mobile_phone_number: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type LabsGqlUpdateUserPasswordCommand = {
  password_raw: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type LabsGqlUpdateWorkflowStepDefinitionCommand = {
  archived: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priority: Scalars['Float']['input'];
};

export type LabsGqlUpsertLabPriceLabPrices = {
  lab_id: Scalars['String']['input'];
  lab_price_id: Scalars['String']['input'];
  price_cents: Scalars['Float']['input'];
};

export type LabsGqlUpsertManufacturerProfileCommand = {
  custom_field_preferences?: InputMaybe<Array<LabsGqlICustomFieldSubmissionInput>>;
  deactivated_at?: InputMaybe<Scalars['DateTime']['input']>;
  default_portalship_destination?: InputMaybe<LabsGqlPortalShipDestinationType>;
  default_portalship_speed?: InputMaybe<LabsGqlPortalShipShippingSpeed>;
  design_capability?: InputMaybe<LabsGqlInternalDesignAbility>;
  exclude_from_gold_pricing?: InputMaybe<Scalars['Boolean']['input']>;
  feature_states?: InputMaybe<LabsGqlManufacturerFeatureStatesInput>;
  is_internal?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturer_id: Scalars['String']['input'];
  /** Where this lab receives inbound shipments, if different from shipping_address */
  return_shipping_address?: InputMaybe<LabsGqlDeliveryAddressInput>;
  shipping_address?: InputMaybe<LabsGqlDeliveryAddressInput>;
};

export type LabsGqlUpsertOrganizationPrices = {
  organization_id: Scalars['String']['input'];
  price_cents: Scalars['Float']['input'];
  price_id: Scalars['String']['input'];
};

export type LabsGqlUpsertPartnerPriceConfigCommand = {
  partner_id: Scalars['String']['input'];
  preset_id?: InputMaybe<Scalars['String']['input']>;
  price_cents: Scalars['Int']['input'];
  price_id: Scalars['String']['input'];
};

export type LabsGqlUserDto = {
  __typename?: 'UserDTO';
  created_at: Scalars['DateTime']['output'];
  /** Whether this user still needs their account credentials to be set up. This should only be utilized for Practice users. */
  does_account_need_credentials_set: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  has_not_set_email?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  is_account_activated: Scalars['Boolean']['output'];
  is_mobile_verified: Scalars['Boolean']['output'];
  last_name: Scalars['String']['output'];
  mobile_phone_number?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  salesforce_contact_id?: Maybe<Scalars['String']['output']>;
  staff: Array<LabsGqlStaffMemberDto>;
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlUserDtoFragment = {
  __typename?: 'UserDTOFragment';
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  is_account_activated: Scalars['Boolean']['output'];
  is_mobile_verified: Scalars['Boolean']['output'];
  last_name: Scalars['String']['output'];
  mobile_phone_number?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlUserLoginOption = {
  __typename?: 'UserLoginOption';
  access_token: Scalars['String']['output'];
  id: Scalars['String']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  organization_name: Scalars['String']['output'];
  organization_type: LabsGqlOrganizationType;
};

export type LabsGqlUserOrganizationDto = {
  __typename?: 'UserOrganizationDTO';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type LabsGqlUserReadEvents = {
  __typename?: 'UserReadEvents';
  read_event_ids: Array<Scalars['Int']['output']>;
  user_id: Scalars['ID']['output'];
};

export type LabsGqlUserResponse = {
  __typename?: 'UserResponse';
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  mobile_phone_number?: Maybe<Scalars['String']['output']>;
  number_of_staff: Scalars['Float']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlUserStaff = {
  __typename?: 'UserStaff';
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_phone?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  doctor_preference_id: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  invite_sent: Scalars['Boolean']['output'];
  is_active: Scalars['Boolean']['output'];
  is_doctor: Scalars['Boolean']['output'];
  is_mobile_verified: Scalars['Boolean']['output'];
  last_name: Scalars['String']['output'];
  login_email: Scalars['String']['output'];
  login_phone?: Maybe<Scalars['String']['output']>;
  partner_id: Scalars['String']['output'];
  preferred_name?: Maybe<Scalars['String']['output']>;
  roles: Array<LabsGqlStaffRolePractice>;
  staff_member_id: Scalars['String']['output'];
};

export enum LabsGqlValidShadeTypeEnum {
  Base = 'Base',
  Gingival = 'Gingival',
  Incisal = 'Incisal',
  Stump = 'Stump',
  Tissue = 'Tissue'
}

export type LabsGqlVariantFeedbackDto = {
  __typename?: 'VariantFeedbackDTO';
  created_at: Scalars['DateTime']['output'];
  crown_choice?: Maybe<Scalars['String']['output']>;
  feedback_credit_cents?: Maybe<Scalars['Int']['output']>;
  freeform_feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lab_order_id: Scalars['String']['output'];
  lab_order_item_id: Scalars['String']['output'];
  practice_id: Scalars['String']['output'];
  star_rating?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  time_to_seat?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['DateTime']['output'];
  was_drop_in?: Maybe<Scalars['Boolean']['output']>;
  would_you_be_disappointed?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlVariantFeedbackWithOrderInfoDto = {
  __typename?: 'VariantFeedbackWithOrderInfoDTO';
  created_at: Scalars['DateTime']['output'];
  crown_choice?: Maybe<Scalars['String']['output']>;
  doctor_name: Scalars['String']['output'];
  feedback_credit_cents?: Maybe<Scalars['Int']['output']>;
  freeform_feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items_v2: Array<LabsGqlLabOrderItemV2>;
  lab_order_id: Scalars['String']['output'];
  lab_order_item_id: Scalars['String']['output'];
  order_created_date: Scalars['DateTime']['output'];
  order_delivery_date?: Maybe<Scalars['DateTime']['output']>;
  order_no: Scalars['String']['output'];
  patient_name: Scalars['String']['output'];
  practice_id: Scalars['String']['output'];
  star_rating?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  time_to_seat?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['DateTime']['output'];
  was_drop_in?: Maybe<Scalars['Boolean']['output']>;
  would_you_be_disappointed?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlVeneerItem = LabsGqlIOrderItemBase & {
  __typename?: 'VeneerItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit: LabsGqlOrderItemSingleToothUnit;
};

export type LabsGqlVeneerItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit: LabsGqlOrderItemSingleToothUnitInput;
};

export type LabsGqlVideoDdpReviewRequestDto = {
  __typename?: 'VideoDdpReviewRequestDTO';
  created_at: Scalars['DateTime']['output'];
  design_order_id: Scalars['ID']['output'];
  design_order_revision_id: Scalars['ID']['output'];
  dr_opt_in?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  practice_id: Scalars['ID']['output'];
  skipped?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['DateTime']['output'];
  video_url?: Maybe<Scalars['String']['output']>;
};

export enum LabsGqlVisionLinkType {
  Edit = 'Edit',
  Share = 'Share'
}

export type LabsGqlVoidInvoiceInput = {
  invoiceId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type LabsGqlWaxupItem = LabsGqlIOrderItemBase & {
  __typename?: 'WaxupItem';
  attachments: Array<LabsGqlLabOrderAttachment>;
  id: Scalars['String']['output'];
  item_notes?: Maybe<Scalars['String']['output']>;
  original_item_id?: Maybe<Scalars['String']['output']>;
  preference_fields: Array<LabsGqlICustomFieldSubmission>;
  pricing: LabsGqlOrderItemPricing;
  selected_teeth?: Maybe<Array<Scalars['ToothNumber']['output']>>;
  shades: Array<LabsGqlLabOrderItemShade>;
  sku: LabsGqlOrderItemSkuType;
  unit_type: Scalars['String']['output'];
};

export type LabsGqlWaxupItemInput = {
  attachments: Array<LabsGqlLabOrderAttachmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  item_notes?: InputMaybe<Scalars['String']['input']>;
  original_item_id?: InputMaybe<Scalars['String']['input']>;
  preference_fields: Array<LabsGqlICustomFieldSubmissionInput>;
  selected_teeth?: InputMaybe<Array<Scalars['ToothNumber']['input']>>;
  shades: Array<LabsGqlLabOrderItemShadeInput>;
  unit_type: Scalars['String']['input'];
};

export type LabsGqlWaxupReviewRejectionAnnotationInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  image_url: Scalars['String']['input'];
};

export type LabsGqlWaxupReviewRejectionInput = {
  annotations?: InputMaybe<Array<LabsGqlWaxupReviewRejectionAnnotationInput>>;
  notes: Scalars['String']['input'];
  presets?: InputMaybe<Array<LabsGqlPresetInput>>;
};

export type LabsGqlWaxupReviewWorkflowTask = LabsGqlIWorkflowTask & {
  __typename?: 'WaxupReviewWorkflowTask';
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlWorkflowTaskWaxupReviewConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export enum LabsGqlWhoReceivesDoctorNotifications {
  Admin = 'admin',
  Assistant = 'assistant',
  Dr = 'dr',
  Other = 'other'
}

export type LabsGqlWorkOrderAssignmentDto = {
  __typename?: 'WorkOrderAssignmentDTO';
  capacity_window: LabsGqlCapacityWindowDto;
  estimated_cost: Scalars['Int']['output'];
  metadata?: Maybe<LabsGqlWorkOrderAssignmentMetadataDto>;
  organization_id: Scalars['String']['output'];
  quoted_completion_date?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlWorkOrderAssignmentMetadataDto = {
  __typename?: 'WorkOrderAssignmentMetadataDTO';
  arrival_date: Scalars['DateTime']['output'];
  carrier: Scalars['String']['output'];
  cost: Scalars['Int']['output'];
  service_level: Scalars['String']['output'];
  truck_time: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
};

export type LabsGqlWorkOrderFulfillmentDto = {
  __typename?: 'WorkOrderFulfillmentDTO';
  completed_at?: Maybe<Scalars['DateTime']['output']>;
  due_at: Scalars['DateTime']['output'];
  external_id: Scalars['String']['output'];
  rejected_at?: Maybe<Scalars['DateTime']['output']>;
  started_at: Scalars['DateTime']['output'];
};

export type LabsGqlWorkOrderHistoryDto = {
  __typename?: 'WorkOrderHistoryDTO';
  canceled_at?: Maybe<Scalars['DateTime']['output']>;
  ready_at?: Maybe<Scalars['DateTime']['output']>;
};

export type LabsGqlWorkOrderScheduleDto = {
  __typename?: 'WorkOrderScheduleDTO';
  earliest_start: Scalars['DateTime']['output'];
  latest_start: Scalars['DateTime']['output'];
  lead_time_hours: Scalars['Int']['output'];
  planned_due: Scalars['DateTime']['output'];
  planned_start: Scalars['DateTime']['output'];
};

export enum LabsGqlWorkOrderStatusEnum {
  Canceled = 'Canceled',
  Completed = 'Completed',
  InProgress = 'InProgress',
  PreReqsIncomplete = 'PreReqsIncomplete',
  Ready = 'Ready',
  Rejected = 'Rejected',
  WaitingDrApproval = 'WaitingDrApproval'
}

export enum LabsGqlWorkOrderTypeEnum {
  Design = 'design',
  ManualReview = 'manual_review',
  Manufacturing = 'manufacturing',
  Shipping = 'shipping'
}

export type LabsGqlWorkflowState = {
  __typename?: 'WorkflowState';
  responsible_party?: Maybe<LabsGqlResponsiblePartyEnum>;
  state: LabsGqlWorkflowStateEnum;
};

export enum LabsGqlWorkflowStateEnum {
  AwaitingLabAcceptance = 'AwaitingLabAcceptance',
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Design = 'Design',
  DesignReview = 'DesignReview',
  NeedsRefabrication = 'NeedsRefabrication',
  NeedsReview = 'NeedsReview',
  OnFabricationFloor = 'OnFabricationFloor',
  OnHold = 'OnHold',
  Shipped = 'Shipped'
}

export type LabsGqlWorkflowStep = {
  __typename?: 'WorkflowStep';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** determines order of steps in a sequence, smallest number comes first */
  priority: Scalars['Float']['output'];
};

export type LabsGqlWorkflowStepDefinition = {
  __typename?: 'WorkflowStepDefinition';
  archived: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Float']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LabsGqlWorkflowTask = LabsGqlDesignReviewWorkflowTask | LabsGqlDrReviewHoldWorkflowTask | LabsGqlExternalDesignWorkflowTask | LabsGqlInternalDesignWorkflowTask | LabsGqlResolveHoldWorkflowTask | LabsGqlResolveScanRejectionWorkflowTask | LabsGqlReviewOrderWorkflowTask | LabsGqlScanReviewWorkflowTask | LabsGqlWaxupReviewWorkflowTask | LabsGqlWorkflowTaskGeneric;

export type LabsGqlWorkflowTaskAssignee = {
  __typename?: 'WorkflowTaskAssignee';
  assigned_at: Scalars['DateTime']['output'];
  /** @deprecated superseded by assigned_user */
  assigned_user_id: Scalars['String']['output'];
  /** reason for task assignment */
  assignment_reason?: Maybe<Scalars['String']['output']>;
  /** was this task assigned automatically */
  auto_assigned?: Maybe<Scalars['Boolean']['output']>;
  /** time auto-assigned task is due to expire if not completed */
  auto_assignment_expiration?: Maybe<Scalars['DateTime']['output']>;
  user: LabsGqlUserDto;
};

export enum LabsGqlWorkflowTaskCloseReason {
  Cancelled = 'Cancelled',
  Completed = 'Completed'
}

export type LabsGqlWorkflowTaskCloseout = {
  __typename?: 'WorkflowTaskCloseout';
  actor: Scalars['String']['output'];
  actor_id?: Maybe<Scalars['String']['output']>;
  actor_staff?: Maybe<LabsGqlStaffMemberWithRelations>;
  closed_at: Scalars['DateTime']['output'];
  reason: LabsGqlWorkflowTaskCloseReason;
};

export type LabsGqlWorkflowTaskDesignReviewConfig = {
  __typename?: 'WorkflowTaskDesignReviewConfig';
  /** Legacy field for review of manufacturer designs */
  blocks_fabrication: Scalars['Boolean']['output'];
  /** Notes from the designers submission */
  internal_design_notes?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlWorkflowTaskDrReviewHoldConfig = {
  __typename?: 'WorkflowTaskDrReviewHoldConfig';
  doctor_request_id?: Maybe<Scalars['String']['output']>;
  dr_visible_notes: Scalars['String']['output'];
  photo_attachment_urls: Array<Scalars['String']['output']>;
  structured_response_options: Array<Scalars['String']['output']>;
};

export type LabsGqlWorkflowTaskExternalDesignConfig = {
  __typename?: 'WorkflowTaskExternalDesignConfig';
  guided_qc_results?: Maybe<Array<LabsGqlDesignReviewGuidedQcResultEntry>>;
  is_revision?: Maybe<Scalars['Boolean']['output']>;
  prepped_design_file_path?: Maybe<Scalars['String']['output']>;
  previous_metafields?: Maybe<Array<LabsGqlDesignMetafieldSubmission>>;
  rejection_attachment_urls?: Maybe<Array<Scalars['String']['output']>>;
  rejection_notes?: Maybe<Scalars['String']['output']>;
  rejection_reasons?: Maybe<Array<Scalars['String']['output']>>;
  resolved_scan_notes?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlWorkflowTaskGeneric = LabsGqlIWorkflowTask & {
  __typename?: 'WorkflowTaskGeneric';
  assignee?: Maybe<LabsGqlWorkflowTaskAssignee>;
  closeout?: Maybe<LabsGqlWorkflowTaskCloseout>;
  configuration: LabsGqlGenericWorkflowTaskConfig;
  created_at: Scalars['DateTime']['output'];
  /** The id of the event that created this task */
  creation_event_id: Scalars['Int']['output'];
  due_at?: Maybe<Scalars['DateTime']['output']>;
  /** ID generated from creating event id and task type */
  id: Scalars['String']['output'];
  lab_order_id: Scalars['String']['output'];
  responsible_party: LabsGqlWorkflowTaskResponsibleParty;
  started_at?: Maybe<Scalars['DateTime']['output']>;
  type: LabsGqlWorkflowTaskType;
};

export type LabsGqlWorkflowTaskInternalDesignConfig = {
  __typename?: 'WorkflowTaskInternalDesignConfig';
  guided_qc_results?: Maybe<Array<LabsGqlDesignReviewGuidedQcResultEntry>>;
  is_revision?: Maybe<Scalars['Boolean']['output']>;
  prepped_design_file_path?: Maybe<Scalars['String']['output']>;
  previous_metafields?: Maybe<Array<LabsGqlDesignMetafieldSubmission>>;
  rejection_attachment_urls?: Maybe<Array<Scalars['String']['output']>>;
  rejection_notes?: Maybe<Scalars['String']['output']>;
  rejection_reasons?: Maybe<Array<Scalars['String']['output']>>;
  resolved_scan_notes?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlWorkflowTaskReasonsConfig = {
  __typename?: 'WorkflowTaskReasonsConfig';
  doctor_request_id?: Maybe<Scalars['String']['output']>;
  hold_is_practice_managed_pause?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  reasons: Array<Scalars['String']['output']>;
};

export type LabsGqlWorkflowTaskResolveScanIssueConfig = {
  __typename?: 'WorkflowTaskResolveScanIssueConfig';
  categories: Array<Scalars['String']['output']>;
  /** @deprecated use categories instead */
  category: Scalars['String']['output'];
  doctor_request_id?: Maybe<Scalars['String']['output']>;
  file_urls?: Maybe<Array<Scalars['String']['output']>>;
  internal_notes?: Maybe<Scalars['String']['output']>;
  notes_for_doctor?: Maybe<Scalars['String']['output']>;
  options_for_doctor?: Maybe<Array<Scalars['String']['output']>>;
  practice_response?: Maybe<LabsGqlPracticeRejectedScanResponse>;
  /** A rating of how bad the reason for rejection is */
  severity_score?: Maybe<Scalars['Float']['output']>;
  shared_with_practice?: Maybe<Scalars['Boolean']['output']>;
};

export type LabsGqlWorkflowTaskResponsibleParty = {
  __typename?: 'WorkflowTaskResponsibleParty';
  id?: Maybe<Scalars['String']['output']>;
  role: LabsGqlResponsiblePartyEnum;
};

export type LabsGqlWorkflowTaskResponsiblePartyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  role: LabsGqlResponsiblePartyEnum;
};

export type LabsGqlWorkflowTaskReviewOrderConfig = {
  __typename?: 'WorkflowTaskReviewOrderConfig';
  notes?: Maybe<Scalars['String']['output']>;
  reasons: Array<Scalars['String']['output']>;
  review_step: LabsGqlWorkflowStep;
};

export enum LabsGqlWorkflowTaskSortField {
  LabDesignPriority = 'lab_design_priority',
  OrderCreatedAt = 'order_created_at',
  OrderDueDate = 'order_due_date',
  TaskClosedAt = 'task_closed_at',
  TaskCreatedAt = 'task_created_at',
  TaskType = 'task_type'
}

export type LabsGqlWorkflowTaskSortParam = {
  field: LabsGqlWorkflowTaskSortField;
  isAsc: Scalars['Boolean']['input'];
};

export type LabsGqlWorkflowTaskStatusCounts = {
  __typename?: 'WorkflowTaskStatusCounts';
  AcknowledgeDelivery: Scalars['Int']['output'];
  AcknowledgeReturn: Scalars['Int']['output'];
  AllUnassigned: Scalars['Int']['output'];
  ApproveAlignerFabrication: Scalars['Int']['output'];
  AssignedToMe: Scalars['Int']['output'];
  AssignedToOthers: Scalars['Int']['output'];
  AutomateDesign: Scalars['Int']['output'];
  AutomateReview: Scalars['Int']['output'];
  /** @deprecated No longer supported, always returns zero */
  Completed: Scalars['Int']['output'];
  ConvertTreatmentPlanRequest: Scalars['Int']['output'];
  CreateExternalAlignerFulfillment: Scalars['Int']['output'];
  CreateTreatmentPlanRequest: Scalars['Int']['output'];
  DesignPrep: Scalars['Int']['output'];
  DesignReview: Scalars['Int']['output'];
  DesignReview2: Scalars['Int']['output'];
  DrReviewHold: Scalars['Int']['output'];
  ExternalDesign: Scalars['Int']['output'];
  ExternalDesignVerification: Scalars['Int']['output'];
  FinalizeTreatmentPlanRequest: Scalars['Int']['output'];
  InjectionMoldDesign: Scalars['Int']['output'];
  InternalDesign: Scalars['Int']['output'];
  LabAcceptOrder: Scalars['Int']['output'];
  ModelDesign: Scalars['Int']['output'];
  PullFromFloor: Scalars['Int']['output'];
  ResolveHold: Scalars['Int']['output'];
  ResolveScanRejection: Scalars['Int']['output'];
  ReturnOrder: Scalars['Int']['output'];
  ReviewOrder: Scalars['Int']['output'];
  ScanReview: Scalars['Int']['output'];
  SendToFloor: Scalars['Int']['output'];
  SetTreatmentPlanRequestUrl: Scalars['Int']['output'];
  ShipOrder: Scalars['Int']['output'];
  WaitingOnPractice: Scalars['Int']['output'];
  WaxupReview: Scalars['Int']['output'];
};

export enum LabsGqlWorkflowTaskType {
  AcknowledgeDelivery = 'AcknowledgeDelivery',
  AcknowledgeReturn = 'AcknowledgeReturn',
  ApproveAlignerFabrication = 'ApproveAlignerFabrication',
  AutomateDesign = 'AutomateDesign',
  AutomateReview = 'AutomateReview',
  ConvertTreatmentPlanRequest = 'ConvertTreatmentPlanRequest',
  CreateExternalAlignerFulfillment = 'CreateExternalAlignerFulfillment',
  CreateTreatmentPlanRequest = 'CreateTreatmentPlanRequest',
  DesignPrep = 'DesignPrep',
  DesignReview = 'DesignReview',
  DesignReview2 = 'DesignReview2',
  DrReviewHold = 'DrReviewHold',
  ExternalDesign = 'ExternalDesign',
  ExternalDesignVerification = 'ExternalDesignVerification',
  FinalizeTreatmentPlanRequest = 'FinalizeTreatmentPlanRequest',
  InjectionMoldDesign = 'InjectionMoldDesign',
  InternalDesign = 'InternalDesign',
  LabAcceptOrder = 'LabAcceptOrder',
  ModelDesign = 'ModelDesign',
  PullFromFloor = 'PullFromFloor',
  ResolveHold = 'ResolveHold',
  ResolveScanRejection = 'ResolveScanRejection',
  ReturnOrder = 'ReturnOrder',
  ReviewOrder = 'ReviewOrder',
  ScanReview = 'ScanReview',
  SendToFloor = 'SendToFloor',
  SetTreatmentPlanRequestUrl = 'SetTreatmentPlanRequestUrl',
  ShipOrder = 'ShipOrder',
  WaxupReview = 'WaxupReview'
}

export type LabsGqlWorkflowTaskWaxupReviewConfig = {
  __typename?: 'WorkflowTaskWaxupReviewConfig';
  /** Rejection notes from doctor completing this waxup review */
  completion_reject_notes?: Maybe<Scalars['String']['output']>;
  /** Rejection notes from doctor completing an earlier waxup review */
  previous_reject_notes?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlZendeskAgent = {
  __typename?: 'ZendeskAgent';
  name: Scalars['String']['output'];
  photo?: Maybe<LabsGqlZendeskAgentPhoto>;
  user_fields: LabsGqlZendeskAgentUserFields;
};

export type LabsGqlZendeskAgentPhoto = {
  __typename?: 'ZendeskAgentPhoto';
  content_url: Scalars['String']['output'];
};

export type LabsGqlZendeskAgentUserFields = {
  __typename?: 'ZendeskAgentUserFields';
  experience?: Maybe<Scalars['String']['output']>;
  working_hours?: Maybe<Scalars['String']['output']>;
};

export type LabsGqlZendeskChatUser = {
  __typename?: 'ZendeskChatUser';
  expires_at?: Maybe<Scalars['DateTime']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** Summary data from a Zendesk live scan review ticket */
export type LabsGqlZendeskLsrTicketDetails = {
  __typename?: 'ZendeskLSRTicketDetails';
  /** The date the ticket was logged into our system */
  created_at: Scalars['DateTime']['output'];
  /** ID of the log record in the database */
  id: Scalars['String']['output'];
  interaction_notes: Scalars['String']['output'];
  /** ID of the ticket in Zendesk */
  zendesk_ticket_id: Scalars['String']['output'];
  zendesk_ticket_url: Scalars['String']['output'];
};

export enum LabsGqlCore__Purpose {
  /** `EXECUTION` features provide metadata necessary to for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export type LabsGqlImplant_Details_3ox = {
  __typename?: 'implant_details_3ox';
  Connection: Scalars['String']['output'];
  Manufacturer: Scalars['String']['output'];
  System: Scalars['String']['output'];
};

export enum LabsGqlJoin__Graph {
  Crm = 'CRM',
  LabsServer = 'LABS_SERVER',
  ProductCatalog = 'PRODUCT_CATALOG',
  Retainer = 'RETAINER',
  Threeoxz = 'THREEOXZ'
}

export type LabsGqlLab_Order = {
  __typename?: 'lab_order';
  aligner_case?: Maybe<LabsGqlAlignerCaseDto>;
  cancel_date?: Maybe<Scalars['DateTime']['output']>;
  /** Will be set if this order is a resubmitted order (from the cancel & resubmit flow). These are different than refabs, they are triggered from doctors or the ops portal, and allow the users to make any kind of edits to their live orders. */
  cancelled_order_id?: Maybe<Scalars['String']['output']>;
  carrier?: Maybe<Scalars['String']['output']>;
  case_readiness_id?: Maybe<Scalars['String']['output']>;
  cbct_url?: Maybe<Scalars['String']['output']>;
  clinical_support_notes?: Maybe<LabsGqlClinicalSupportNotes>;
  created_at: Scalars['DateTime']['output'];
  created_from_return_id?: Maybe<Scalars['String']['output']>;
  delivery_date?: Maybe<Scalars['DateTime']['output']>;
  dentist_amount_due_cents?: Maybe<Scalars['Float']['output']>;
  dentures_fulfillment?: Maybe<LabsGqlDenturesFulfillmentDto>;
  /** @deprecated Please use design order revisions */
  design?: Maybe<LabsGqlOrderDesign>;
  design_capability_requirements: Array<LabsGqlOrderDesignCapabilityDto>;
  design_due_date: Scalars['DateTime']['output'];
  design_file_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please utilize practice_dates.digital_design_preview_estimated_completion_date. */
  design_preview_eta?: Maybe<Scalars['DateTime']['output']>;
  /** List of foreign keys to DoctorNoteCategoryDTO */
  doctor_note_category_ids?: Maybe<Array<Scalars['String']['output']>>;
  doctor_notes?: Maybe<Scalars['String']['output']>;
  doctor_preferences_id: Scalars['ID']['output'];
  /** @deprecated Use due_date on manufacturer_sla */
  due_date: Scalars['DateTime']['output'];
  /** @deprecated Please utilize practice_dates.estimated_delivery_date. */
  estimated_delivery_date: Scalars['DateTime']['output'];
  fabrication_start_date?: Maybe<Scalars['DateTime']['output']>;
  fulfillment: LabsGqlLabOrderFulfillmentDto;
  fulfillment_workflow: LabsGqlOrderFulfillmentWorkflow;
  hold_details?: Maybe<LabsGqlLabOrderHoldDetails>;
  hold_history: Array<LabsGqlLabOrderHoldDetailsHistoryElement>;
  /** @deprecated Use hold_details instead */
  hold_reason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** When set to true, this order is considered "locked" and is unable to be changed. */
  is_order_locked_for_sales_demo: Scalars['Boolean']['output'];
  is_training_order: Scalars['Boolean']['output'];
  /** @deprecated Please use items_v2 */
  items: Array<LabsGqlLabOrderItem>;
  items_v2: Array<LabsGqlLabOrderItemV2>;
  lab_slip_alerts: Array<LabsGqlLabSlipAlert>;
  /** Contains any validation errors associated with the most recently uploaded design file */
  latest_design_file_validation_errors: Array<LabsGqlDesignFileValidationError>;
  mailing_address_id: Scalars['String']['output'];
  manufacturer_history: LabsGqlManufacturerOrderHistory;
  manufacturer_id: Scalars['ID']['output'];
  /** The timestamp when the manufacturer was last updated for this order */
  manufacturer_last_updated_date: Scalars['DateTime']['output'];
  /** Due dates for different order stages */
  manufacturer_sla: LabsGqlOrderSla;
  needs_refabrication_date?: Maybe<Scalars['DateTime']['output']>;
  /** On refabrication orders, notes about what needs to be changed from the previous order. Initially the same as 'refabrication_notes' on the original order, but may be updated by OOps. */
  notes_for_refabrication?: Maybe<Scalars['String']['output']>;
  /** This ID is used to track an order from its creation down the chain of all sub-orders it may create. Through multi-fulfillments, C&R, Refabs, we create new "sub-orders" of an initial order, and we use order_group_id to have a way to keep track of all orders stemming from that initial order easily. */
  order_group_id: Scalars['String']['output'];
  /** A unique identifier short enough to be human readable */
  order_number: Scalars['String']['output'];
  /** Will be set if this order is a refabrication of another order */
  original_order_id?: Maybe<Scalars['String']['output']>;
  partials_fulfillment?: Maybe<LabsGqlPartialsFulfillmentDto>;
  partner_id: Scalars['ID']['output'];
  /** The group id for when a place order command results in multiple orders due to manufacturer splitting */
  partner_order_id: Scalars['String']['output'];
  partner_order_number?: Maybe<Scalars['Int']['output']>;
  patient: LabsGqlPatientDto;
  /** @deprecated Individual patient properties are deprecated - use 'patient' field instead. */
  patient_birthday: Scalars['String']['output'];
  /** @deprecated Individual patient properties are deprecated - use 'patient' field instead. */
  patient_first_name: Scalars['String']['output'];
  /** @deprecated Individual patient properties are deprecated - use 'patient' field instead. */
  patient_gender?: Maybe<Scalars['String']['output']>;
  patient_id: Scalars['String']['output'];
  /** @deprecated Individual patient properties are deprecated - use 'patient' field instead. */
  patient_last_name: Scalars['String']['output'];
  photos: Array<LabsGqlLabOrderSinglePhotoDto>;
  practice_dates: LabsGqlOrderPracticeDates;
  /** The id of a previous order that was used to create this one in a multi fulfillment sequence */
  previous_fulfillment_order_id?: Maybe<Scalars['String']['output']>;
  /** The product line category to which this order belongs */
  product_line?: Maybe<LabsGqlProductLine>;
  refab_count?: Maybe<Scalars['Int']['output']>;
  /** Notes from practitioner explaining why *this* order needed to be refabricated */
  refabrication_notes?: Maybe<Scalars['String']['output']>;
  /** If a refabrication order has been created for this order, this is the refabrication order ID. See also original_order_id. */
  refabrication_order_id?: Maybe<Scalars['String']['output']>;
  /** Will be set if this order is a cancelled order (from the cancel & resubmit flow). Can include multiple IDs if the new order is split. */
  replaced_by_ids?: Maybe<Array<Scalars['String']['output']>>;
  replaced_scan_export_ids: Array<Scalars['String']['output']>;
  scan_export_id: Scalars['String']['output'];
  sent_to_floor_date?: Maybe<Scalars['DateTime']['output']>;
  ship_date?: Maybe<Scalars['DateTime']['output']>;
  ship_servicelevel?: Maybe<Scalars['String']['output']>;
  ship_status?: Maybe<Scalars['String']['output']>;
  ship_status_updated?: Maybe<Scalars['DateTime']['output']>;
  /** Only null when it has not been shipped yet */
  shipped_by_lab?: Maybe<Scalars['Boolean']['output']>;
  shipped_to_dandy: Scalars['Boolean']['output'];
  shipping_label_url?: Maybe<Scalars['String']['output']>;
  show_hold_status_to_practice?: Maybe<Scalars['Boolean']['output']>;
  smile_style?: Maybe<LabsGqlSmileStyleChoice>;
  /** Count of orders the initial place order command was split into */
  split_order_count?: Maybe<Scalars['Int']['output']>;
  status: LabsGqlLabOrderStatus;
  tracking_number?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  /** @deprecated Please utilize the doctor_review field on DesignOrderRevision, or the listDesignOrderDoctorReviews query. This field will no longer be maintained in the future. */
  waxup_review_history?: Maybe<LabsGqlOrderWaxupReviewHistory>;
};

export type LabsGqlModel_Element_3ox = {
  __typename?: 'model_element_3ox';
  Color: Scalars['String']['output'];
  DandyMaterial?: Maybe<Scalars['String']['output']>;
  LinkTypeClass: Scalars['String']['output'];
  Material: Scalars['String']['output'];
  ScanFiles: Array<LabsGqlScan_File_3ox>;
  TypeIDs: Array<LabsGqlType_Id_3ox>;
};

export type LabsGqlScan_Export = {
  __typename?: 'scan_export';
  assets?: Maybe<Array<LabsGqlScanExportAsset>>;
  /** @deprecated Use `assets` */
  bitescan_ply_paths?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use `assets` */
  bitescan_stl_paths?: Maybe<Array<Scalars['String']['output']>>;
  cart_items_v2: Array<LabsGqlCartItemV2>;
  cart_items_v2_for_checkout_v3: Array<LabsGqlCartItemV2>;
  /** Any notes included from non-Chairside scanning clients about the case. */
  case_comments?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  elements: Array<LabsGqlScanExportElement>;
  elements_hash?: Maybe<Scalars['String']['output']>;
  existingOrderMatch?: Maybe<LabsGqlExistingPatientOrderMatch>;
  file_url?: Maybe<Scalars['String']['output']>;
  has_shade_match_images: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  image_urls?: Maybe<Array<Scalars['String']['output']>>;
  lower_margin_suggestion_path?: Maybe<Scalars['String']['output']>;
  lower_margin_v2_suggestion_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `assets` */
  lower_ply_paths?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use `assets` */
  lower_ply_with_image_paths?: Maybe<Array<LabsGqlPlyWithImage>>;
  /** @deprecated Use `assets` */
  lower_stl_paths?: Maybe<Array<Scalars['String']['output']>>;
  original_upload_url: Scalars['String']['output'];
  oxz_margin_info?: Maybe<LabsGqlOxzMarginInformation>;
  patient_birthday?: Maybe<Scalars['DateTime']['output']>;
  patient_first_name: Scalars['String']['output'];
  patient_last_name: Scalars['String']['output'];
  post_processed_date?: Maybe<Scalars['DateTime']['output']>;
  sanitized_url?: Maybe<Scalars['String']['output']>;
  scan_source: LabsGqlScanExportSource;
  scan_source_version?: Maybe<Scalars['String']['output']>;
  /** The date of the oldest scan in the scan export. Returns null if no metadata is found, or it is malformed. */
  scanned_at?: Maybe<Scalars['DateTime']['output']>;
  scanner: LabsGqlScannerEntity;
  scanner_id?: Maybe<Scalars['String']['output']>;
  stl_url?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field is no longer maintained for new orders */
  telemetry_snapshot?: Maybe<LabsGqlScanExportTelemetrySnapshot>;
  threeshape_order_id: Scalars['String']['output'];
  threeshape_patient_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  upper_margin_suggestion_path?: Maybe<Scalars['String']['output']>;
  upper_margin_v2_suggestion_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `assets` */
  upper_ply_paths?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use `assets` */
  upper_ply_with_image_paths?: Maybe<Array<LabsGqlPlyWithImage>>;
  /** @deprecated Use `assets` */
  upper_stl_paths?: Maybe<Array<Scalars['String']['output']>>;
};

export type LabsGqlScan_Export_Processed = {
  __typename?: 'scan_export_processed';
  already_has_order_submitted: Scalars['Boolean']['output'];
  assets?: Maybe<Array<LabsGqlScanExportAsset>>;
  /** @deprecated Use `assets` */
  bitescan_ply_paths?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use `assets` */
  bitescan_stl_paths?: Maybe<Array<Scalars['String']['output']>>;
  cart_items_v2: Array<LabsGqlCartItemV2>;
  cart_items_v2_for_checkout_v3: Array<LabsGqlCartItemV2>;
  /** Any notes included from non-Chairside scanning clients about the case. */
  case_comments?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  elements: Array<LabsGqlScanExportElement>;
  elements_hash?: Maybe<Scalars['String']['output']>;
  existingOrderMatch?: Maybe<LabsGqlExistingPatientOrderMatch>;
  file_url?: Maybe<Scalars['String']['output']>;
  has_shade_match_images: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  image_urls?: Maybe<Array<Scalars['String']['output']>>;
  lower_margin_suggestion_path?: Maybe<Scalars['String']['output']>;
  lower_margin_v2_suggestion_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `assets` */
  lower_ply_paths?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use `assets` */
  lower_ply_with_image_paths?: Maybe<Array<LabsGqlPlyWithImage>>;
  /** @deprecated Use `assets` */
  lower_stl_paths?: Maybe<Array<Scalars['String']['output']>>;
  original_upload_url: Scalars['String']['output'];
  overwrote_file: Scalars['Boolean']['output'];
  oxz_margin_info?: Maybe<LabsGqlOxzMarginInformation>;
  patient_birthday?: Maybe<Scalars['DateTime']['output']>;
  patient_first_name: Scalars['String']['output'];
  patient_last_name: Scalars['String']['output'];
  post_processed_date?: Maybe<Scalars['DateTime']['output']>;
  sanitized_url?: Maybe<Scalars['String']['output']>;
  scan_source: LabsGqlScanExportSource;
  scan_source_version?: Maybe<Scalars['String']['output']>;
  /** The date of the oldest scan in the scan export. Returns null if no metadata is found, or it is malformed. */
  scanned_at?: Maybe<Scalars['DateTime']['output']>;
  scanner: LabsGqlScannerEntity;
  scanner_id?: Maybe<Scalars['String']['output']>;
  stl_url?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field is no longer maintained for new orders */
  telemetry_snapshot?: Maybe<LabsGqlScanExportTelemetrySnapshot>;
  threeshape_order_id: Scalars['String']['output'];
  threeshape_patient_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  upper_margin_suggestion_path?: Maybe<Scalars['String']['output']>;
  upper_margin_v2_suggestion_path?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `assets` */
  upper_ply_paths?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Use `assets` */
  upper_ply_with_image_paths?: Maybe<Array<LabsGqlPlyWithImage>>;
  /** @deprecated Use `assets` */
  upper_stl_paths?: Maybe<Array<Scalars['String']['output']>>;
};

export type LabsGqlScan_File_3ox = {
  __typename?: 'scan_file_3ox';
  FileType: Scalars['String']['output'];
  Path: Scalars['String']['output'];
  ScanProcessState: Scalars['String']['output'];
};

export type LabsGqlThree_Shape_3ox = {
  __typename?: 'three_shape_3ox';
  Comments?: Maybe<Scalars['String']['output']>;
  ModelElements: Array<LabsGqlModel_Element_3ox>;
  OrderID: Scalars['String']['output'];
  PatientID: Scalars['String']['output'];
  PostProcessedDate: Scalars['String']['output'];
  ShadeMatchImages: Scalars['Boolean']['output'];
};

export type LabsGqlThree_Shape_3oxz_Data = {
  __typename?: 'three_shape_3oxz_data';
  Comments?: Maybe<Scalars['String']['output']>;
  CreationDateUtc: Scalars['String']['output'];
  FilePathOverride?: Maybe<Scalars['String']['output']>;
  ModelElements: Array<LabsGqlModel_Element_3ox>;
  OrderID: Scalars['String']['output'];
  PatientBirthday: Scalars['String']['output'];
  PatientFirstName: Scalars['String']['output'];
  PatientID: Scalars['String']['output'];
  PatientLastName: Scalars['String']['output'];
  PostProcessedDate: Scalars['String']['output'];
  ShadeMatchImages: Scalars['Boolean']['output'];
};

export type LabsGqlThree_Shape_Case = {
  __typename?: 'three_shape_case';
  CreationDateUtc: Scalars['String']['output'];
  PatientBirthday: Scalars['String']['output'];
  PatientFirstName: Scalars['String']['output'];
  PatientLastName: Scalars['String']['output'];
};

export type LabsGqlType_Id_3ox = {
  __typename?: 'type_id_3ox';
  ImplantDetails?: Maybe<LabsGqlImplant_Details_3ox>;
  TypeClass: Scalars['String']['output'];
  TypeID: Scalars['String']['output'];
  UNN: Scalars['String']['output'];
};

export type LabsGqlUpdate_Delivery_Statuses_Result = {
  __typename?: 'update_delivery_statuses_result';
  message: Scalars['String']['output'];
};
