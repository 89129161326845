import ModelViewerBackground from '../../../assets/images/ModelViewerBackground.svg';
import { DesignViewerLite } from '../DesignViewer/WaxupDesignViewerLite';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import type { DesignViewerLiteWrapperProps } from './DesignViewerLiteWrapper';
import {
    type ICameraControls,
    type MainViewCameraControlsRef,
    type MainViewModelRef,
    type ModelAppearance,
} from '@orthly/dentin';
import { DesignComparisonMenu } from '@orthly/dentin';
import type { LabsGqlOxzMarginInformationFragment } from '@orthly/graphql-operations';
import { styled, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const ModelViewerContainer = styled('div')<{ enableDDPComparison: boolean; showDrawingControls: boolean }>(({
    enableDDPComparison,
    showDrawingControls,
}) => {
    return {
        display: 'flex',
        background: `url(${ModelViewerBackground}), linear-gradient(#fbfbfb, #ffffff)`,
        overflow: 'hidden',
        position: 'relative',
        minHeight: 300,
        borderRadius: enableDDPComparison || showDrawingControls ? '16px 16px 0 0' : '16px',
    };
});
const DesignComparisonMenuContainer = styled('div')({
    position: 'absolute',
    right: '20px',
    top: '20px',
});

export interface GuidedWaxupViewerWrapperProps extends DesignViewerLiteWrapperProps {
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    modelRef: MainViewModelRef;
    controlRef: MainViewCameraControlsRef;
    onControlsMounted?: (control: ICameraControls | null) => void;
    setControlsActive?: () => void;
    appearanceSource?: ModelAppearance;
    isImmediateDenture?: boolean;
    setPresetAnnotation?: (annotatedImageUrls: string[]) => void;
    showDrawingControls?: boolean;
    camConfig?: {
        zoom?: number;
        position?: THREE.Vector3;
    };
    isFragmentLoading?: boolean;
    oxzMarginInfo?: LabsGqlOxzMarginInformationFragment;
}

export const DesignViewerLiteDetachedWrapper: React.VFC<GuidedWaxupViewerWrapperProps> = ({
    order,
    refetchDesign,
    designFragment,
    comparisonDesignFragment,
    takeSnapshotRef,
    style,
    appearance,
    setAppearance,
    modelRef,
    controlRef,
    onControlsMounted,
    appearanceSource,
    setControlsActive,
    camConfig,
    isImmediateDenture,
    showDrawingControls,
    isFragmentLoading,
    oxzMarginInfo,
}) => {
    const { value: enableDDPComparison } = useFeatureFlag('enableDDPComparison');
    const isMobile = useScreenIsMobileOrVerticalTablet();

    const [desiredComparisonDesignFiles, setDesiredComparisonDesignFiles] = React.useState<string[]>([]);

    const hasPastRestoratives = !!appearance.pastRestoratives.length;

    const [maxMarginDistance, setMaxMarginDistance] = React.useState<number | undefined>(undefined);

    return (
        <ModelViewerContainer
            enableDDPComparison={!!enableDDPComparison}
            showDrawingControls={!!showDrawingControls}
            style={{ ...style }}
        >
            <DesignViewerLite
                selectedDesignFragment={designFragment}
                comparisonDesignFragment={comparisonDesignFragment}
                order={order}
                refetch={refetchDesign}
                isMobile={isMobile}
                oxzMarginInfo={oxzMarginInfo}
                designQcConfig={{
                    appearance,
                    setAppearance,
                    modelRef,
                    controlRef,
                    onControlsMounted,
                    appearanceSource,
                    setControlsActive,
                    takeSnapshotRef,
                }}
                desiredComparisonDesignFiles={desiredComparisonDesignFiles}
                setDesiredComparisonDesignFiles={setDesiredComparisonDesignFiles}
                setMaxMarginDistance={setMaxMarginDistance}
                hideOverlayTools={true}
                camConfig={camConfig}
                isImmediateDenture={isImmediateDenture}
                isFragmentLoading={isFragmentLoading}
            />
            {hasPastRestoratives && (
                <DesignComparisonMenuContainer>
                    <DesignComparisonMenu
                        appearance={appearance}
                        setAppearance={setAppearance}
                        maxMarginDistanceMm={maxMarginDistance}
                        displayLocation={undefined}
                        setDesiredComparisonDesignFiles={setDesiredComparisonDesignFiles}
                    />
                </DesignComparisonMenuContainer>
            )}
        </ModelViewerContainer>
    );
};
