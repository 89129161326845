import { Box, Collapse, Icon, FlossPalette, Paper, Text } from '@orthly/ui-primitives';
import React from 'react';

export interface DesignSidebarSectionProps {
    title?: string;
    children?: React.ReactNode;
}

export const DesignSidebarSection: React.FC<DesignSidebarSectionProps> = ({ title, children }) => {
    const [open, setOpen] = React.useState(true);

    return (
        <Paper elevation={0} sx={{ width: '100%', padding: '8px 16px', backgroundColor: 'transparent' }}>
            {title && (
                <Box
                    sx={{ cursor: 'pointer', width: '100%', display: 'flex', justifyContent: 'space-between' }}
                    onClick={() => setOpen(!open)}
                >
                    <Text medium variant={'body2'} sx={{ color: FlossPalette.BLACK, paddingBottom: '12px' }}>
                        {title}
                    </Text>
                    <Icon
                        sx={{ color: FlossPalette.LIGHT_GRAY }}
                        icon={open ? 'KeyboardArrowUpIcon' : 'KeyboardArrowDownIcon'}
                    />
                </Box>
            )}

            <Collapse in={open} timeout={'auto'} unmountOnExit>
                {children}
            </Collapse>
        </Paper>
    );
};
