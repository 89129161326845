import { useMutation, useQuery } from '@apollo/client';
import type { AdminTatItemLeadTimesEditor_QueryQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { MUITable } from '@orthly/mui-table';
import { TimeUtils } from '@orthly/runtime-utils';
import { QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Grid, Icon, IconButton } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

const AdminTatItemLeadTimesEditor_Query = graphql(`
    query AdminTatItemLeadTimesEditor_Query {
        tatListItemLeadTimes {
            id
            catalogTime
            overLimitCatalogTime
            leadTimeByType {
                buffer
                design
                fabrication
                orderReview
                shipping
                waxupReview
            }
            overLimitLeadTimeByType {
                buffer
                design
                fabrication
                orderReview
                shipping
                waxupReview
            }
            unitCountLimit
        }
    }
`);
type TatItemLeadTime = AdminTatItemLeadTimesEditor_QueryQuery['tatListItemLeadTimes'][number];

const AdminTatItemLeadTimeModalUpsert_Mutation = graphql(`
    mutation AdminTatItemLeadTimeModalUpsert_Mutation(
        $id: String!
        $leadTimeByType: TatLeadTimeByTypeArgsDto!
        $unitCountLimit: Int
        $overLimitLeadTimeByType: TatLeadTimeByTypeArgsDto
        $catalogTime: Int
        $overLimitCatalogTime: Int
    ) {
        tatUpsertItemLeadTime(
            id: $id
            leadTimeByType: $leadTimeByType
            overLimitLeadTimeByType: $overLimitLeadTimeByType
            unitCountLimit: $unitCountLimit
            catalogTime: $catalogTime
            overLimitCatalogTime: $overLimitCatalogTime
        ) {
            id
        }
    }
`);

interface TatItemLeadTimeModalProps {
    leadTime: TatItemLeadTime;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetch: () => Promise<any>;
}

interface TatItemLeadTimeModalFormVars {
    catalogDays: number | null;
    leadTimeByType: {
        design: number;
        fabrication: number;
        shipping: number;
        buffer: number;
    };
    overLimitLeadTimes?: {
        unitCountLimit: number;
        catalogDays: number | null;
        design: number;
        fabrication: number;
        shipping: number;
        buffer: number;
    } | null;
}

// Shared field props for the numeric inputs on the form
const fieldPropsNumber = {
    // max of 50 days for any stage should be a safe assumption
    inputProps: { min: 0, max: 50 },
    // prevent wheel event from updating value as that can cause it to change massively when user tries to scroll
    onWheel: (e: React.WheelEvent<HTMLDivElement>) => {
        // see MUI issue discussion: https://github.com/mui/material-ui/issues/7960#issuecomment-2270719632
        if (e.target instanceof HTMLInputElement) {
            e.target.blur();
        }
    },
};

const TatItemLeadTimeModal: React.VFC<TatItemLeadTimeModalProps> = ({ leadTime, refetch }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const upsertMtn = useMutation(AdminTatItemLeadTimeModalUpsert_Mutation);
    const { submit, submitting } = useRootActionCommand(upsertMtn, {
        successMessage: 'Updated item lead time.',
        onSuccess: async () => {
            await refetch();
            setOpen(false);
        },
    });

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={`Update TAT Item Lead Time`}
            subtitle={leadTime.id}
            subtitleTextProps={{ variant: 'body1' }}
            loading={submitting}
            showCloseButton
            content={
                <QuickForm<TatItemLeadTimeModalFormVars>
                    dirtySubmitOnly
                    fields={{
                        catalogDays: {
                            type: 'number',
                            label: 'Catalog Days',
                            optional: true,
                            validation: z.number().int().min(1).nullish(),
                            fieldProps: fieldPropsNumber,
                        },
                        leadTimeByType: {
                            type: 'nested',
                            optional: false,
                            label: 'Lead Times',
                            fields: {
                                design: {
                                    type: 'number',
                                    label: 'Design Lead Time (days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                fabrication: {
                                    type: 'number',
                                    label: 'Fabrication Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                shipping: {
                                    type: 'number',
                                    label: 'Shipping Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                buffer: {
                                    type: 'number',
                                    label: 'Buffer Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                            },
                        },
                        overLimitLeadTimes: {
                            type: 'nested',
                            optional: true,
                            label: 'Over Limit Lead Times',
                            optionalLabel: 'Skip Over Limit Lead Times',
                            fields: {
                                unitCountLimit: {
                                    type: 'number',
                                    label: 'Unit Count Limit',
                                    optional: true,
                                    fieldProps: fieldPropsNumber,
                                },
                                catalogDays: {
                                    type: 'number',
                                    label: 'Over Limit Catalog Days',
                                    helperText:
                                        'Total catalog days when over unit count limit. Leave blank to use base catalog days.',
                                    optional: true,
                                    validation: z.number().int().min(1).nullish(),
                                    fieldProps: fieldPropsNumber,
                                },
                                design: {
                                    type: 'number',
                                    label: 'Design Over Limit Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                fabrication: {
                                    type: 'number',
                                    label: 'Fabrication Over Limit Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                shipping: {
                                    type: 'number',
                                    label: 'Shipping Over Limit Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                buffer: {
                                    type: 'number',
                                    label: 'Buffer Over Limit Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                            },
                        },
                    }}
                    resetOnInitialValueChange={true}
                    initialValues={{
                        catalogDays: leadTime.catalogTime
                            ? TimeUtils.convertHoursToDays(leadTime.catalogTime, 'trunc')
                            : undefined,
                        leadTimeByType: {
                            buffer: TimeUtils.convertHoursToDays(leadTime.leadTimeByType.buffer, 'trunc'),
                            design: TimeUtils.convertHoursToDays(leadTime.leadTimeByType.design, 'trunc'),
                            fabrication: TimeUtils.convertHoursToDays(leadTime.leadTimeByType.fabrication, 'trunc'),
                            shipping: TimeUtils.convertHoursToDays(leadTime.leadTimeByType.shipping, 'trunc'),
                        },
                        overLimitLeadTimes:
                            leadTime.overLimitLeadTimeByType && leadTime.unitCountLimit
                                ? {
                                      unitCountLimit: leadTime.unitCountLimit,
                                      catalogDays:
                                          typeof leadTime.overLimitCatalogTime === 'number'
                                              ? TimeUtils.convertHoursToDays(leadTime.overLimitCatalogTime, 'trunc')
                                              : null,
                                      buffer: TimeUtils.convertHoursToDays(
                                          leadTime.overLimitLeadTimeByType.buffer,
                                          'trunc',
                                      ),
                                      design: TimeUtils.convertHoursToDays(
                                          leadTime.overLimitLeadTimeByType.design,
                                          'trunc',
                                      ),
                                      fabrication: TimeUtils.convertHoursToDays(
                                          leadTime.overLimitLeadTimeByType.fabrication,
                                          'trunc',
                                      ),
                                      shipping: TimeUtils.convertHoursToDays(
                                          leadTime.overLimitLeadTimeByType.shipping,
                                          'trunc',
                                      ),
                                  }
                                : undefined,
                    }}
                    disabled={submitting}
                    onSubmit={async data => {
                        const catalogTime =
                            typeof data.catalogDays === 'number' && data.catalogDays > 0
                                ? TimeUtils.convertDaysToHours(data.catalogDays)
                                : null;
                        const overLimitCatalogTime =
                            typeof data.overLimitLeadTimes?.catalogDays === 'number' &&
                            data.overLimitLeadTimes.catalogDays > 0
                                ? TimeUtils.convertDaysToHours(data.overLimitLeadTimes.catalogDays)
                                : null;
                        await submit({
                            id: leadTime.id,
                            catalogTime,
                            overLimitCatalogTime,
                            leadTimeByType: {
                                buffer: TimeUtils.convertDaysToHours(data.leadTimeByType.buffer),
                                design: TimeUtils.convertDaysToHours(data.leadTimeByType.design),
                                fabrication: TimeUtils.convertDaysToHours(data.leadTimeByType.fabrication),
                                shipping: TimeUtils.convertDaysToHours(data.leadTimeByType.shipping),
                                orderReview: leadTime.leadTimeByType.orderReview,
                                waxupReview: leadTime.leadTimeByType.waxupReview,
                            },
                            overLimitLeadTimeByType: data.overLimitLeadTimes
                                ? {
                                      buffer: TimeUtils.convertDaysToHours(data.overLimitLeadTimes.buffer),
                                      design: TimeUtils.convertDaysToHours(data.overLimitLeadTimes.design),
                                      fabrication: TimeUtils.convertDaysToHours(data.overLimitLeadTimes.fabrication),
                                      shipping: TimeUtils.convertDaysToHours(data.overLimitLeadTimes.shipping),
                                      orderReview: 0,
                                      waxupReview: 0,
                                  }
                                : null,
                            unitCountLimit: data.overLimitLeadTimes?.unitCountLimit ?? null,
                        });
                    }}
                />
            }
            CustomButton={() => (
                <IconButton onClick={() => setOpen(true)}>
                    <Icon icon={'SettingsIcon'} />
                </IconButton>
            )}
        />
    );
};

export const TatItemLeadTimesEditor: React.VFC = () => {
    const { data: itemsData, loading, refetch } = useQuery(AdminTatItemLeadTimesEditor_Query);
    const items = _.sortBy(itemsData?.tatListItemLeadTimes ?? [], r => r.id);

    return (
        <Grid container>
            <MUITable<TatItemLeadTime>
                title={'Item Lead Times'}
                loading={loading}
                data={items}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [{ icon: 'refresh', position: 'toolbar', onClick: refetch }],
                }}
                rowOptions={{ rowHover: true }}
                eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                columns={[
                    { name: 'ID', render: r => r.id },
                    {
                        name: 'Catalog Days',
                        render: r => (r.catalogTime ? TimeUtils.convertHoursToDays(r.catalogTime, 'trunc') : 'Not Set'),
                        filterOptions: {
                            type: 'dropdown',
                        },
                    },
                    {
                        name: 'Design Lead Time (Days)',
                        title: 'Design Lead Time (Days)',
                        render: r => TimeUtils.convertHoursToDays(r.leadTimeByType.design, 'trunc'),
                    },
                    {
                        name: 'Fabrication Lead Time (Days)',
                        title: 'Fabrication Lead Time (Days)',
                        render: r => TimeUtils.convertHoursToDays(r.leadTimeByType.fabrication, 'trunc'),
                    },
                    {
                        name: 'Shipping Lead Time (Days)',
                        title: 'Shipping Lead Time (Days)',
                        render: r => TimeUtils.convertHoursToDays(r.leadTimeByType.shipping, 'trunc'),
                    },
                    {
                        name: 'Buffer Lead Time (Days)',
                        title: 'Buffer Lead Time (Days)',
                        render: r => TimeUtils.convertHoursToDays(r.leadTimeByType.buffer, 'trunc'),
                    },
                    { name: 'Unit Count Limit', render: r => r.unitCountLimit },
                    {
                        name: `Actions`,
                        render: row => <TatItemLeadTimeModal leadTime={row} refetch={refetch} key={row.id} />,
                    },
                ]}
            />
        </Grid>
    );
};
