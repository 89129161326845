import { SimpleExpansionPanel } from '../../../../components/SimpleExpansionPanel';
import { ArchiveAutomationSwitch } from '../../components/ArchiveAutomationSwitch';
import { AutomationFolderPicker } from '../../components/AutomationFolderPicker';
import { useAutomationBuilderAction } from '../../state/AutomationBuilder.actions';
import { useAutomationBuilderSelector } from '../../state/AutomationBuilder.context';
import { AutomationsBuilderDeleteButton } from '../AutomationBuilder/AutomationBuilderDeleteButton';
import { useAutomationStartEditing } from './AutomationStartEditing.hook';
import type { AutomationListItemData } from './AutomationsListFilterToolbar';
import {
    useAutomationsListFilterState,
    AutomationsListFilterToolbar,
    NO_FOLDER_AUTOMATIONS_KEY,
} from './AutomationsListFilterToolbar';
import { LoadBlocker } from '@orthly/ui';
import {
    FlossPalette,
    Text,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    EditIcon,
    Icon,
    FolderIcon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

interface AutomationListItemProps {
    automation: AutomationListItemData;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetch: () => Promise<any>;
    currentFolders: string[];
}

const AutomationListItem: React.FC<AutomationListItemProps> = props => {
    const { automation, refetch } = props;
    const { enqueueSnackbar } = useSnackbar();
    const duplicate = useAutomationBuilderAction('ENTER_DUPLICATE');

    const startEditing = useAutomationStartEditing();

    return (
        <>
            <ListItem button style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 24, background: '#fff' }}>
                <ListItemText
                    primary={automation.name}
                    onClick={() => startEditing(automation)}
                    primaryTypographyProps={{ variant: 'h6', style: { fontSize: 16 } }}
                    secondary={
                        <span>
                            <b>Description: </b>
                            {automation.description}
                            <br />
                            {automation.creator && (
                                <>
                                    <b>Creator: </b>
                                    {automation.creator.email}
                                </>
                            )}
                        </span>
                    }
                />
                <ListItemSecondaryAction>
                    <Grid container alignItems={'center'} wrap={'nowrap'}>
                        <Grid container style={{ paddingRight: 8, width: 'auto' }}>
                            <AutomationFolderPicker
                                automation={automation}
                                currentFolders={props.currentFolders}
                                refetch={refetch}
                            />
                        </Grid>
                        <Grid container style={{ paddingRight: 8, width: 'auto' }}>
                            <ArchiveAutomationSwitch
                                automationId={automation.id}
                                archived={automation.archived}
                                onComplete={archived => {
                                    enqueueSnackbar(`${archived ? 'DISABLED' : 'ENABLED'} "${automation.name}"`, {
                                        variant: 'success',
                                    });
                                }}
                            />
                        </Grid>
                        <Tooltip title={'Duplicate'}>
                            <IconButton onClick={() => duplicate(automation)}>
                                <Icon icon={'FileCopy'} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Edit'}>
                            <IconButton onClick={() => startEditing(automation)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <AutomationsBuilderDeleteButton automationId={automation.id} refetch={refetch} />
                    </Grid>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </>
    );
};

interface AutomationsFolderListItemProps {
    folder: string;
    currentFolders: string[];
    automations: AutomationListItemData[];
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetch: () => Promise<any>;
    defaultExpanded?: boolean;
}

const AutomationsFolderListItem: React.FC<AutomationsFolderListItemProps> = props => {
    const { refetch, automations, folder, defaultExpanded } = props;
    return (
        <SimpleExpansionPanel
            hideChildrenOnClose
            ExpansionPanelProps={{ defaultExpanded: defaultExpanded ?? false }}
            title={
                <Grid container style={{ width: 'auto' }} alignItems={'center'}>
                    {folder !== NO_FOLDER_AUTOMATIONS_KEY && (
                        <FolderIcon style={{ marginRight: 8, color: FlossPalette.STAR_GRASS }} />
                    )}
                    <Text variant={'h6'} style={{ fontSize: 16 }}>
                        {folder === NO_FOLDER_AUTOMATIONS_KEY ? 'Uncategorized' : folder} ({automations.length})
                    </Text>
                </Grid>
            }
            ExpansionPanelDetailsProps={{ style: { padding: 0 } }}
            ExpansionPanelSummaryProps={{ style: { background: FlossPalette.TAN } }}
        >
            <List style={{ width: '100%', padding: 0 }}>
                {automations.map(automation => (
                    <AutomationListItem
                        key={automation.id}
                        automation={automation}
                        refetch={refetch}
                        currentFolders={props.currentFolders}
                    />
                ))}
            </List>
        </SimpleExpansionPanel>
    );
};

interface AutomationsFoldersListProps {
    visibleAutomationIds: string[];
    automations: AutomationListItemData[];
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetch: () => Promise<any>;
}

const AutomationsFoldersList: React.VFC<AutomationsFoldersListProps> = props => {
    const { automations, visibleAutomationIds, refetch } = props;
    const folderListItems = React.useMemo<AutomationsFolderListItemProps[]>(() => {
        const sortedAutomations = _.sortBy(automations, i => i.folder ?? 'z');
        const automationsByFolder = _.groupBy(sortedAutomations, a => a.folder ?? NO_FOLDER_AUTOMATIONS_KEY);
        const currentFolders = Object.keys(automationsByFolder).filter(s => s !== NO_FOLDER_AUTOMATIONS_KEY);
        return _.compact(
            Object.entries(automationsByFolder).map<AutomationsFolderListItemProps | null>(([folder, automations]) => {
                const visibleAutomations = automations.filter(a => visibleAutomationIds.includes(a.id));
                if (visibleAutomations.length === 0) {
                    return null;
                }
                return { folder, refetch, currentFolders, automations: visibleAutomations };
            }),
        );
    }, [visibleAutomationIds, refetch, automations]);
    return (
        <List style={{ width: '100%', padding: 0 }}>
            {folderListItems.map(folderListProps => {
                // if a filter is enabled we expand by default
                const defaultExpanded = folderListItems.length === 1;
                return (
                    <AutomationsFolderListItem
                        {...folderListProps}
                        // trigger default expanded change by remounting when it changes
                        key={`${folderListProps.folder}-${defaultExpanded}`}
                        defaultExpanded={defaultExpanded}
                    />
                );
            })}
        </List>
    );
};

interface AutomationsListRootProps {
    automations: AutomationListItemData[];
    loading: boolean;
    refetch: () => Promise<unknown>;
    loadedAutomationId?: string;
}

export const AutomationsListRoot: React.VFC<AutomationsListRootProps> = ({
    loadedAutomationId,
    automations,
    loading,
    refetch,
}) => {
    const { folderFilterState, visibleAutomationIds, creatorFilterState, actionFilterState, showArchivedFilterState } =
        useAutomationsListFilterState(automations);

    // Automatically load the URL selected automation ID if one is set and we're not currently in an automation.
    // This allows users to easily share URLs, and doesn't require us to rework the entirety of how Automations data fetching works.
    const startEditingAction = useAutomationBuilderAction('ENTER_EDITING');
    const isCurrentlyEditing = useAutomationBuilderSelector(s => s.editing);
    React.useEffect(() => {
        const selectedAutomation = loadedAutomationId
            ? automations.find(automation => automation.id === loadedAutomationId)
            : undefined;

        // We won't overwrite the current action if they're already editing one.
        if (selectedAutomation && !isCurrentlyEditing) {
            startEditingAction(selectedAutomation);
        }
    }, [loadedAutomationId, automations, startEditingAction, isCurrentlyEditing]);

    return (
        <LoadBlocker blocking={loading}>
            <AutomationsListFilterToolbar
                {...folderFilterState}
                {...creatorFilterState}
                {...actionFilterState}
                {...showArchivedFilterState}
                automations={automations}
            />
            <AutomationsFoldersList
                automations={automations}
                refetch={refetch}
                visibleAutomationIds={visibleAutomationIds}
            />
        </LoadBlocker>
    );
};
