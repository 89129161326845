import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { RootActionDialog, SimpleInput } from '@orthly/ui';
import {
    Box,
    Button,
    RadioGroupPrimitive as RadioGroup,
    RadioPrimitive as Radio,
    FormControlLabel,
    styled,
    Text,
    InputAdornment,
    Icon,
} from '@orthly/ui-primitives';
import React from 'react';

const StyledRootActionDialog = styled(RootActionDialog)({
    '.MuiPaper-root': {
        maxWidth: '600px',
    },
});

const InnerContentWrapper = styled(Box)({
    display: 'grid',
    gap: '24px',
    marginTop: '12px',
});

const InnerContentButtonsWrapper = styled(Box)({
    display: 'flex',
    gap: '8px',
    justifyContent: 'end',
});

const SurveyWrapper = styled(Box)({});

const StyledRadio = styled(Radio)({
    margin: '8px 0px',
});

enum AbandonmentReason {
    NoTime = 'I don’t have time right now',
    PreferWrittenUpdates = 'I prefer written updates',
    Other = 'Other (please specify)',
}

interface InnerContentProps {
    orderId: string;
    revisionId: string;
    onModalClose: () => void;
}

const InnerContent: React.VFC<InnerContentProps> = ({ orderId, revisionId, onModalClose }) => {
    const [selectedReason, setSelectedReason] = React.useState<AbandonmentReason | null>(null);
    const [otherReason, setOtherReason] = React.useState<string>('');

    const disabled =
        selectedReason === null || (selectedReason === AbandonmentReason.Other && otherReason.trim() === '');

    const onSubmitAction = () => {
        BrowserAnalyticsClientFactory.Instance?.track(
            'Practice - Guided Waxup - Video Design Review - Video Skipped Survey Submitted',
            {
                $groups: {
                    order: orderId,
                },
                revisionId,
                surveyResponse:
                    selectedReason === AbandonmentReason.Other ? `Other-${otherReason}` : selectedReason ?? '',
            },
        );

        onModalClose();
    };

    React.useEffect(() => {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Guided Waxup - Video Design Review - Video Skipped', {
            $groups: {
                order: orderId,
            },
            revisionId,
        });
    }, [orderId, revisionId]);

    return (
        <InnerContentWrapper>
            <Text variant={'body2'}>Is there a reason why? Your feedback will help us improve!</Text>
            <SurveyWrapper>
                <RadioGroup
                    value={selectedReason ?? ''}
                    onChange={e => setSelectedReason(e.currentTarget.value as AbandonmentReason)}
                >
                    {Object.values(AbandonmentReason).map(r => (
                        <FormControlLabel
                            key={r}
                            control={<StyledRadio color={'secondary'} />}
                            value={r}
                            label={<Text variant={'body2'}>{r}</Text>}
                        />
                    ))}
                </RadioGroup>
                {selectedReason === AbandonmentReason.Other && (
                    <SimpleInput
                        fullWidth
                        onChange={r => setOtherReason(r ?? '')}
                        value={otherReason}
                        label={''}
                        placeholder={'Please specify'}
                        flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
                        TextFieldProps={{
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position={'end'}>
                                        <Icon icon={'PencilOutlinedIcon'} sx={{ marginRight: '12px' }} />
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                )}
            </SurveyWrapper>
            <InnerContentButtonsWrapper>
                <Button variant={'primary'} onClick={onSubmitAction} disabled={disabled}>
                    Submit
                </Button>
            </InnerContentButtonsWrapper>
        </InnerContentWrapper>
    );
};

interface GuidedWaxupVideoDesignReviewAbandonmentModalProps {
    openModal: () => void;
    onModalClose: () => void;
    orderId: string;
    revisionId: string;
}

export const GuidedWaxupVideoDesignReviewAbandonmentModal: React.VFC<
    GuidedWaxupVideoDesignReviewAbandonmentModalProps
> = ({ onModalClose, revisionId, orderId }) => {
    return (
        <StyledRootActionDialog
            loading={false}
            open
            showCloseButton={false}
            setOpen={() => {}}
            title={'We noticed you haven’t played the video you requested'}
            content={<InnerContent orderId={orderId} revisionId={revisionId} onModalClose={onModalClose} />}
            CustomButton={() => null}
        />
    );
};
