import { usePartnerAndLabNames } from '../../../utils/usePartnerAndLabNames';
import type { LabOrderConditionEditorValue, LabOrderConditionEditorProps } from './LabOrderConditionEditor';
import { LabsGqlLabOrderConditionType } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { Autocomplete, TextField } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type Option = { id: string; name: string };

type Props = LabOrderConditionEditorProps<
    Extract<LabOrderConditionEditorValue, { type: LabsGqlLabOrderConditionType.PracticeId }>
>;

// Custom Quick Form field for editing PracticeId routing conditions
export const PracticeIdConditionEditor: React.VFC<Props> = ({ value: { practiceIds }, onChange }) => {
    const { partnerNamesById } = usePartnerAndLabNames();

    const { searchOptions, selectedOptions } = React.useMemo(() => {
        const options = Object.entries(partnerNamesById).map<Option>(([id, name]) => ({ id, name: name ?? id }));
        const searchOptions = _.sortBy(options, o => o.name);
        const selectedOptions = practiceIds.map(id => ({ id, name: partnerNamesById[id] ?? id }));
        return { searchOptions, selectedOptions };
    }, [partnerNamesById, practiceIds]);

    return (
        <LoadBlocker blocking={false} ContainerProps={{ style: { padding: '8px 0' } }}>
            <Autocomplete<Option, true, false>
                multiple
                options={searchOptions}
                value={selectedOptions}
                onChange={(_event, value) => {
                    onChange({
                        practiceIds: value.map(opt => (typeof opt === 'string' ? opt : opt.id)),
                        type: LabsGqlLabOrderConditionType.PracticeId,
                    });
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={optionOrStr => optionOrStr.name}
                style={{ width: `100%` }}
                renderInput={params => (
                    <TextField
                        variant={'standard'}
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            // force the input to be full width so it's not shrunken by selected option chips
                            style: { ...params.inputProps?.style, width: '100%' },
                        }}
                        label={'Search by practice name or ID'}
                        fullWidth
                    />
                )}
            />
        </LoadBlocker>
    );
};
