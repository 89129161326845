import { mapStyles, useAddressLatLng } from '../../util';
import { InFlightOrdersModal } from './InflightOrdersModal.graphql';
import { RemoveItemModal } from './RemoveItemModal';
import { useDeliveryAddressControls } from './useDeliveryAddressControls';
import type { LabsGqlDoctorDeliveryAddressFragment } from '@orthly/graphql-operations';
import { useCanDeleteDeliveryAddressQuery, useLabsAddresses } from '@orthly/graphql-react';
import { Text, FlossPalette, Grid, styled } from '@orthly/ui-primitives';
import { GoogleMap, Marker } from '@react-google-maps/api';
import React from 'react';

const GridWrapper = styled(Grid)({
    display: 'flex',
});

const Container = styled('div')({
    backgroundColor: FlossPalette.TAN,
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '16px',
    padding: '24px',
    gap: '16px',
    flex: 1,
});

const TitleContainer = styled('div')({
    display: 'flex',
    gap: '4px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
});

const TitleTextContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
});

const ActionButtonContainer = styled('div')({
    display: 'flex',
    gap: '4px',
});

interface DeliveryAddressProps {
    address: LabsGqlDoctorDeliveryAddressFragment;
    partnerId: string;
    onDelete?: (addressId: string) => void;
    onDeleteModalOpen?: (addressId: string) => void;
}

const DeliveryAddressBase: React.FC<DeliveryAddressProps> = props => {
    const { address, onDelete, onDeleteModalOpen } = props;
    const { submitDelete } = useDeliveryAddressControls();
    const { data: canDeleteDeliveryAddressRawData } = useCanDeleteDeliveryAddressQuery({
        variables: { id: props.address.id },
    });
    const displayAddress = React.useMemo(() => {
        let line1 = address.street_one;
        if (address.street_two) {
            line1 += ` - ${address.street_two}`;
        }
        const line2 = `${address.city}, ${address.state} ${address.postal_code}`;
        return { line1, line2 };
    }, [address]);
    const latLong = useAddressLatLng(address);
    const userAgent = navigator.userAgent;
    // MSIE for IE 10 or older
    const msie = userAgent.indexOf('MSIE ');
    // Trident for IE 11
    const trident = userAgent.indexOf('Trident/');
    // isIE is true for IE 11 or older
    const isIE = msie > 0 || trident > 0;

    return (
        <Container>
            <TitleContainer>
                <TitleTextContainer>
                    <Text variant={'body2'} bold data-test={'display-address-line-1'}>
                        {displayAddress.line1}
                    </Text>
                    <Text color={'DARK_GRAY'} bold variant={'caption'} data-test={'display-address-line-2'}>
                        {displayAddress.line2}
                    </Text>
                </TitleTextContainer>
                <ActionButtonContainer>
                    {!canDeleteDeliveryAddressRawData?.canDeleteDeliveryAddress && (
                        <InFlightOrdersModal partnerId={props.partnerId} addressId={address.id} />
                    )}
                    <RemoveItemModal
                        title={`You're about to remove an address`}
                        subtitle={`Are you sure you want to remove ${displayAddress.line1} from your addresses?`}
                        confirmRemoveText={'Yes, remove address'}
                        removeItem={() => {
                            onDelete?.(address.id);
                            void submitDelete({ addressId: address.id, isDeleted: true });
                        }}
                        disabled={!canDeleteDeliveryAddressRawData?.canDeleteDeliveryAddress}
                        onOpen={() => onDeleteModalOpen?.(address.id)}
                    />
                </ActionButtonContainer>
            </TitleContainer>
            {!isIE && (
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '120px', borderRadius: 12 }}
                    options={{
                        styles: mapStyles,
                        fullscreenControl: false,
                    }}
                    zoom={15}
                    center={latLong}
                >
                    {latLong && <Marker position={latLong} />}
                </GoogleMap>
            )}
        </Container>
    );
};

export const DeliveryAddresses: React.FC<{
    partnerId: string;
    onDelete?: (addressId: string) => void;
    onDeleteModalOpen?: (addressId: string) => void;
}> = props => {
    const { addresses } = useLabsAddresses({ variables: { partner_id: props.partnerId } });
    const activeAddresses = (addresses || []).filter(a => a.deleted_at === null);

    return (
        <Grid container spacing={1}>
            {activeAddresses.map(addr => (
                <GridWrapper item xs={12} sm={12} md={6} lg={4} key={addr.id}>
                    <DeliveryAddressBase
                        address={addr}
                        partnerId={props.partnerId}
                        onDelete={props.onDelete}
                        onDeleteModalOpen={props.onDeleteModalOpen}
                    />
                </GridWrapper>
            ))}
        </Grid>
    );
};
