import type { AdminState } from '../../../redux/redux.types';
import { PalateActions } from './Palate.actions';
import type { PalateState } from './Palate.types';
import { generateUseSubstateSelector } from '@orthly/redux-async-actions';
import { handleActions } from 'redux-actions';

const initialState: PalateState = {
    orderFilters: [],
    timelineFilters: [],
};

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const palateReducer = handleActions<PalateState, any>(
    {
        ...PalateActions.SET_ORDER_FILTERS.reducer<PalateState>((state, { payload: criteria }) => ({
            ...state,
            orderFilters: criteria,
        })),
        ...PalateActions.CLEAR_ORDER_FILTERS.reducer<PalateState>(state => {
            return { ...state, orderFilters: [] };
        }),
        ...PalateActions.SET_TIMELINE_FILTERS.reducer<PalateState>((state, { payload: criteria }) => ({
            ...state,
            timelineFilters: criteria,
        })),
        ...PalateActions.CLEAR_TIMELINE_FILTERS.reducer<PalateState>(state => {
            return { ...state, timelineFilters: [] };
        }),
    },
    initialState,
);
export const usePalateState = generateUseSubstateSelector<AdminState, 'palate'>('palate');
