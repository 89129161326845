import GradientSlider from '../../GradientSlider.tsx/GradientSlider';
import { styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const GridPanelRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
});

const GradientPanel: React.FC = () => {
    return (
        <GridPanelRoot>
            <Text variant={'h5'} sx={{ textAlign: 'center' }}>
                Incisal
            </Text>
            <GradientSlider />
            <Text variant={'h5'} sx={{ textAlign: 'center' }}>
                Gingival
            </Text>
        </GridPanelRoot>
    );
};

export default GradientPanel;
