import { useMutation, useQuery } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlRemoveOrderHoldMutationVariables } from '@orthly/graphql-operations';
import { useRemoveOrderHoldMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn, useRootActionCommand } from '@orthly/ui';
import React from 'react';

type RemoveOrderHoldVars = LabsGqlRemoveOrderHoldMutationVariables['data'];

const PracticeUseRemoveOrderHoldCancelLiveDdpRequest_Mutation = graphql(`
    mutation PracticeUseRemoveOrderHoldCancelLiveDdpRequest_Mutation($orderId: String!) {
        cancelLiveDdpReviewRequest(order_id: $orderId)
    }
`);

const PracticeUseRemoveOrderHoldGetLiveDdpRequestInfo_Query = graphql(`
    query PracticeUseRemoveOrderHoldGetLiveDdpRequestInfo_Query($order_id: String!) {
        getLiveDdpReviewRequest(order_id: $order_id) {
            design_order_revision_id
            status
        }
    }
`);

type OrderData = {
    orderId: string;
    holdId?: string | null;
};

const isHoldAlreadyRemoved = (errorMessage: string) =>
    errorMessage.includes('Order is already resumed') || errorMessage.includes('Order hold is already removed');

export const useRemoveOrderHold = (
    refetch: () => void | Promise<void>,
    orderData: OrderData,
    currentDoctorId?: string,
) => {
    const [submitRemoveHoldMtn] = useRemoveOrderHoldMutation();
    const removeHoldMtnSubmitter = (data: RemoveOrderHoldVars) => submitRemoveHoldMtn({ variables: { data } });

    const { submit: unpauseSubmit, submitting: unpauseSubmitting } = useChangeSubmissionFn<any, [RemoveOrderHoldVars]>(
        removeHoldMtnSubmitter,
        {
            successMessage: () => ['The order has successfully been resumed. No further action is required.'],
            onSuccess: async result => {
                /*
                 * The first entry in the hold history is always the most recent hold.
                 */
                const hold = result?.data?.removeOrderHold?.hold_history?.[0];

                if (hold) {
                    BrowserAnalyticsClientFactory.Instance?.track('Practice - Pause Order - Order Resumed', {
                        holdId: hold.hold_id,
                        doctorPref: currentDoctorId ?? '',
                        $groups: { order: orderData.orderId },
                    });
                }

                await refetch();
            },
            errorMessage: e => [
                e.message,
                {
                    submessage: isHoldAlreadyRemoved(e.message) ? 'Please refresh the page.' : '',
                },
            ],
        },
    );

    return {
        onSubmit: () => unpauseSubmit({ orderId: orderData.orderId, holdId: orderData.holdId }),
        submitting: unpauseSubmitting,
    };
};

export const useRemoveOrderHoldOrCancelLiveDdpReview = (
    refetch: () => void | Promise<void>,
    orderData: OrderData,
    currentDoctorId?: string,
) => {
    const { data, loading: ddpReviewRequestLoading } = useQuery(PracticeUseRemoveOrderHoldGetLiveDdpRequestInfo_Query, {
        variables: {
            order_id: orderData.orderId,
        },
        nextFetchPolicy: 'no-cache',
        fetchPolicy: 'no-cache',
    });
    const isLiveDdp = !!data?.getLiveDdpReviewRequest;

    const cancelAptMtn = useMutation(PracticeUseRemoveOrderHoldCancelLiveDdpRequest_Mutation);
    const { submit: cancelAptSubmit, submitting: appointmentCancelling } = useRootActionCommand(cancelAptMtn, {
        successMessage: 'Appointment cancelled!',
        onSuccess: async () => {
            BrowserAnalyticsClientFactory.Instance?.track(
                'Practice - Portal - Live Design Review - Canceled Appointment',
                {
                    $groups: {
                        order: orderData.orderId,
                    },
                    revisionId: data?.getLiveDdpReviewRequest?.design_order_revision_id,
                },
            );

            await refetch();
        },
    });

    const { onSubmit: unpauseSubmit, submitting: unpauseSubmitting } = useRemoveOrderHold(
        refetch,
        orderData,
        currentDoctorId,
    );

    const onSubmit = React.useCallback(() => {
        if (isLiveDdp) {
            return cancelAptSubmit({ orderId: orderData.orderId });
        }

        return unpauseSubmit();
    }, [isLiveDdp, unpauseSubmit, cancelAptSubmit, orderData.orderId]);

    return { isLiveDdp, onSubmit, loading: ddpReviewRequestLoading || unpauseSubmitting || appointmentCancelling };
};
