import { RuleTableRowActions } from '../../../../components/RuleTable/RuleTableRowActions';
import {
    useDisableCapacityRuleSubmitter,
    useEnableCapacityRuleSubmitter,
    useUpdateCapacityRuleSubmitter,
} from './CapacityRule.hooks';
import { CapacityRuleForm } from './CapacityRuleForm';
import type { LabsGqlCapacityRuleFragment } from '@orthly/graphql-operations';
import type { LabsGqlUpdateCapacityRuleCommand } from '@orthly/graphql-schema';
import React from 'react';

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RowActionsProps = { row: LabsGqlCapacityRuleFragment; refetch: () => Promise<any> };
export const CapacityRuleRowActions = ({ row, refetch }: RowActionsProps) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { submit: disableRule, submitting: submittingDisable } = useDisableCapacityRuleSubmitter({
        onComplete: () => refetch().catch(console.error),
    });
    const { submit: enableRule, submitting: submittingEnable } = useEnableCapacityRuleSubmitter({
        onComplete: () => refetch().catch(console.error),
    });
    const { submit: submitUpdate, submitting: submittingUpdate } = useUpdateCapacityRuleSubmitter({
        onComplete: async () => {
            await refetch();
            setDialogOpen(false);
        },
    });
    return (
        <RuleTableRowActions
            RuleTableRowEnableProps={{
                disabled: row.disabled,
                onClick: () => (row.disabled ? enableRule({ rule_id: row.id }) : disableRule({ rule_id: row.id })),
                submitting: submittingEnable || submittingDisable,
            }}
            RuleTableDialogProps={{
                form: (
                    <CapacityRuleForm
                        loading={submittingUpdate}
                        initialValues={{ ...row, condition_type: row.unit_limit !== null ? 'unit' : 'order' }}
                        onSubmit={values => {
                            const commandData: LabsGqlUpdateCapacityRuleCommand = { ...values, rule_id: row.id };
                            commandData.order_limit =
                                commandData.order_limit === undefined ? null : commandData.order_limit;
                            // EPDPLT-4736: Using any is unsafe and should be avoided.
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            if ((commandData.order_limit as any) === '') {
                                commandData.order_limit = null;
                            }
                            commandData.unit_limit =
                                commandData.unit_limit === undefined ? null : commandData.unit_limit;
                            // EPDPLT-4736: Using any is unsafe and should be avoided.
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            if ((commandData.unit_limit as any) === '') {
                                commandData.unit_limit = null;
                            }
                            submitUpdate(commandData).catch(console.error);
                        }}
                    />
                ),
                open: dialogOpen,
                setOpen: setDialogOpen,
                title: 'Edit Capacity Rule',
            }}
        />
    );
};
