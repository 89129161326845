import { DesignReviewOption } from '../GuidedWaxupSelectDesignReviewOptionModal';
import { Box, Button, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const DesignReviewOptionFooterLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '12px',
    [theme.breakpoints.down('sm')]: {
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        gap: '8px',
    },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
        padding: '12px 16px',
        flexDirection: 'column',
        gap: '8px',
    },
}));

interface GuidedWaxupSelectDesignReviewOptionModalFooterProps {
    onClose: () => void;
    selectedDesignReviewOption: DesignReviewOption;
    submit: (option: DesignReviewOption) => Promise<void>;
    isMobile: boolean;
}

export const GuidedWaxupSelectDesignReviewOptionModalFooter: React.VFC<
    GuidedWaxupSelectDesignReviewOptionModalFooterProps
> = ({ onClose, selectedDesignReviewOption, isMobile, submit }) => {
    return (
        <DesignReviewOptionFooterLayout>
            <ButtonWrapper>
                <Button variant={'secondary-gray'} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant={'primary'} onClick={() => submit(selectedDesignReviewOption)}>
                    {selectedDesignReviewOption}
                </Button>
            </ButtonWrapper>
            {selectedDesignReviewOption === DesignReviewOption.REQUEST_LDR && (
                <Text align={isMobile ? 'center' : 'right'} variant={'caption'} color={'ATTENTION_FOREGROUND'}>
                    Missed appointments will prompt Dandy to redesign your case using your rejection notes to prevent
                    delays.
                </Text>
            )}
        </DesignReviewOptionFooterLayout>
    );
};
