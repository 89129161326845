import { PaginatedOrgTableWrapper } from './PaginatedOrgTableWrapper';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const rowMapping = [
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { label: 'Name', value: (row: any) => row.name },
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { label: 'Id', value: (row: any) => row.id },
    {
        label: 'Parent Organization',
        // EPDPLT-4736: Using any is unsafe and should be avoided.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: (row: any) => row.ancestors?.map((ancestor: { name: string }) => ancestor.name).reverse(),
    },
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { label: 'Practice Org Count', value: (row: any) => row.practices_count },
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { label: 'Created At', value: (row: any) => dayjs(row.created_at).format('MMM D, YYYY') },
];

export const PaginatedParentsTable: React.VFC = () => {
    const navigate = useNavigate();
    const onRowClick = (rowId: string) => {
        navigate(`accounts/organizations/${rowId}/practices`);
    };

    return (
        <PaginatedOrgTableWrapper
            title={'Parent Organizations'}
            queryFilters={{
                include_hierarchy: true,
                include_ancestors: true,
                type: [LabsGqlOrganizationType.Parent],
            }}
            csvFilters={{ type: [LabsGqlOrganizationType.Parent] }}
            onRowClick={onRowClick}
            rowMapping={rowMapping}
        />
    );
};
