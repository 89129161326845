import { File3dDropLoader } from './File3dDropLoader';
import { ScanReviewLandingPageAppProvider, useScanReviewLandingPageContext } from './ScanReviewLandingPage.hooks';
import { initializeScanReviewViewStateFromDcm } from './ScanReviewLandingPage.utils';
import {
    ScanReviewCompleteViewButtonsToolbar,
    ScanReviewCompleteViewToolButtonsToolbar,
    ScanReviewCompleteViewToolbar,
} from './ScanReviewLandingPageToolbars';
import {
    extractScanReviewDcmFileData,
    loadScanReviewDcmFileData,
    ScanReviewCompleteView,
    useChairsideCompleteViewOnImageLoadCallback,
    type ScanReviewRecordFactory,
} from '@orthly/dentin';
import { Jaw } from '@orthly/shared-types';
import { StackY, StackX, LoadBlocker } from '@orthly/ui';
import React from 'react';

// The purpose of this and associated files in the same folder is to illustrate how to use the components in
// fe-libs/dentin/src/components/ScanReviewModelViewer to build functionally equivalent set of scan review
// components to what is in chairside today.

//We use a different data test id for the component based on which factories have been provided.
//The reason for this is that the function that creates the factories is an async callback and
//PlayWright needs some indication that test data is ready to be loaded.
function getDataTestId(
    upperJawFactory: ScanReviewRecordFactory | null,
    lowerJawFactory: ScanReviewRecordFactory | null,
) {
    if (!upperJawFactory && !lowerJawFactory) {
        return 'no-factories';
    } else if (upperJawFactory && lowerJawFactory) {
        return 'both-factories';
    } else if (upperJawFactory) {
        return 'upper-jaw-factory';
    }
    return 'lower-jaw-factory';
}

const ScanReviewLandingPageInner: React.VFC = () => {
    const {
        dcms,
        onDcmDrop,
        loading,
        upperJawFactory,
        setUpperJawFactory,
        lowerJawFactory,
        setLowerJawFactory,
        viewState,
        scanReviewAppState,
        scanReviewAppStateManager,
    } = useScanReviewLandingPageContext();

    // After the DCM is loaded the texture data is converted in memory to an HTML image element. This is an
    // async function that accepts a callback that will be invoked once the image loading is complete. The callback
    // returned by this hook returns a factory function that is customized to construct scan records/meshes with
    // the correct materials and attributes needed for most Chairside scan review purposes. Shade matching is dealt
    // with via a separate hook/callback.
    const onScanImageLoadCallback = useChairsideCompleteViewOnImageLoadCallback();

    // Actually load the DCM files. There is a caveat with this example:
    //    The computeInitialScanReviewViewStateFromDcm function should not be used in production. It assumes
    //    a DCM has been exported from 3-Shape designer. Either the existing logic from chairside for calculating
    //    camera poses should be used, or an approach similar to what is implemented here:
    //    ./fe-libs/dentin/src/components/CaptureScanSnapshots/ScanSnapshotCameraPropsConstructor.ts
    React.useEffect(() => {
        const upperJawData = extractScanReviewDcmFileData(Jaw.UPPER, dcms);
        const lowerJawData = extractScanReviewDcmFileData(Jaw.LOWER, dcms);
        initializeScanReviewViewStateFromDcm(upperJawData, lowerJawData, viewState);

        if (upperJawData) {
            loadScanReviewDcmFileData(Jaw.UPPER, upperJawData, onScanImageLoadCallback, setUpperJawFactory);
        }
        if (lowerJawData) {
            loadScanReviewDcmFileData(Jaw.LOWER, lowerJawData, onScanImageLoadCallback, setLowerJawFactory);
        }
    }, [dcms, onScanImageLoadCallback, setLowerJawFactory, setUpperJawFactory, viewState]);

    return (
        <LoadBlocker blocking={loading}>
            <StackY
                sx={{
                    flex: 'auto',
                    overflow: 'hidden',
                    height: '100vh',
                }}
                data-testid={getDataTestId(upperJawFactory, lowerJawFactory)}
            >
                <File3dDropLoader scan={'Upper Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.UPPER, files, event)} />
                <File3dDropLoader scan={'Lower Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.LOWER, files, event)} />
                <StackX sx={{ flex: 'auto', minHeight: 0 }}>
                    <ScanReviewCompleteViewButtonsToolbar scene={scanReviewAppStateManager.scene} />
                    <StackY sx={{ flex: 'auto', minHeight: 0 }}>
                        <ScanReviewCompleteView
                            scanReviewAppStateManager={scanReviewAppStateManager}
                            scanReviewAppState={scanReviewAppState}
                        />
                        <ScanReviewCompleteViewToolButtonsToolbar />
                    </StackY>
                    <StackY>
                        <ScanReviewCompleteViewToolbar />
                    </StackY>
                </StackX>
            </StackY>
        </LoadBlocker>
    );
};

export const ScanReviewLandingPage: React.VFC = () => {
    return (
        <ScanReviewLandingPageAppProvider>
            <ScanReviewLandingPageInner />
        </ScanReviewLandingPageAppProvider>
    );
};
