import type { FlossColor, TextColor } from '@orthly/ui-primitives';
import { FlossPalette, Text } from '@orthly/ui-primitives';
import React from 'react';

export const TextPill: React.FC<{
    children: Exclude<React.ReactNode, undefined | null>;
    color?: TextColor;
    backgroundColor?: FlossColor;
}> = ({ children, color = 'WHITE', backgroundColor = 'SECONDARY_FOREGROUND' }) => (
    <Text
        variant={'caption'}
        medium
        color={color}
        sx={{
            backgroundColor: FlossPalette[backgroundColor],
            borderRadius: '8px',
            padding: '4px 8px',
        }}
    >
        {children}
    </Text>
);
