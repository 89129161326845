import PaintStroke from '../../public/Brushes/PaintStroke.png';
import SprayPaint from '../../public/Brushes/SprayPaint.png';
import { SHADE_VALUES } from './ShadeValues';
import type { Blending } from 'three';
import { NormalBlending } from 'three';

const canvas = document.createElement('canvas');
const brushTexture = new Image();

export async function loadBrushImage(brush: Brush): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = brush.brushImagePath;
    });
}

export function generateBrushTexture(brushImage: HTMLImageElement, brush: Brush): HTMLImageElement {
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return brushImage;
    }
    canvas.width = brushImage.width;
    canvas.height = brushImage.height;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.globalAlpha = brush.opacity || 1;
    if (brush.color) {
        ctx.fillStyle = brush.color;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.globalCompositeOperation = 'destination-in';
    }
    ctx.drawImage(brushImage, 0, 0);

    brushTexture.src = canvas.toDataURL();
    return brushTexture;
}

export interface Brush {
    id: number;
    brushImagePath: string;
    color: string;
    blendType: Blending;
    size: number;
    opacity: number;
    brushImage?: HTMLImageElement;
    texture?: HTMLImageElement;
}

export const DefaultBrushes: Brush[] = [
    {
        id: 0,
        brushImagePath: SprayPaint,
        color: SHADE_VALUES.A2?.Base ?? '#ff0000',
        blendType: NormalBlending,
        size: 60,
        opacity: 0.2,
    },
    {
        id: 1,
        brushImagePath: PaintStroke,
        color: SHADE_VALUES.C3?.Base ?? '#00ff00',
        blendType: NormalBlending,
        size: 40,
        opacity: 0.8,
    },
];
