import { designIdAtom, orderIdAtom } from '../../store/store';
import { DesignSidebarSection } from './DesignDetailSidebarSection';
import { NotesSection } from './NotesSection';
import { SidebarFooter } from './SidebarFooter';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { styled, StackY, SidebarToggleButton } from '@orthly/ui';
import { FlossPalette, Text, Box } from '@orthly/ui-primitives';
import type { OrderDetailImage } from '@orthly/veneer';
import { OrderDetailImagesBlock, OrderDetailItemsAndPreferencesSection } from '@orthly/veneer';
import { useAtom, useAtomValue } from 'jotai';
import React from 'react';

const PainterRightSidebarContentSalesOrder_Fragment = graphql(`
    fragment PainterRightSidebarContentSalesOrder_Fragment on SalesOrderDTO {
        id
        order_number
        patient {
            first_name
            last_name
        }
        items_full_display_info_with_common {
            ...VeneerOrderDetailItemsAndPreferencesSalesOrderItemFullDisplayInfoWithCommon_Fragment
        }
        notes {
            doctor
        }
    }
`);

const PANEL_WIDTH = 350;

const Root = styled(StackY)<{ open: boolean }>(({ open }) => ({
    width: PANEL_WIDTH,
    height: '100%',
    borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    backgroundColor: FlossPalette.TAN,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: open ? undefined : -1 * (PANEL_WIDTH - 16),
    transition: 'margin 0.3s',
}));

const InfoBlock = styled(Box)({
    height: '92vh',
    overflowY: 'auto',
});

interface RightSidebarContentProps {
    onClose: () => void;
    salesOrder: FragmentType<typeof PainterRightSidebarContentSalesOrder_Fragment>;
    imageSources?: OrderDetailImage[];
}

export const RightSidebarContent: React.FC<RightSidebarContentProps> = ({
    onClose,
    salesOrder: salesOrderProp,
    imageSources,
}) => {
    const [orderId, setOrderId] = useAtom(orderIdAtom);
    const salesOrder = getFragmentData(PainterRightSidebarContentSalesOrder_Fragment, salesOrderProp);
    const [open, setOpen] = React.useState<boolean>(true);
    setOrderId(salesOrder.id);
    const designId = useAtomValue(designIdAtom);

    return (
        <>
            <Root open={open}>
                <InfoBlock>
                    <DesignSidebarSection>
                        {salesOrder && `Order ID: #${salesOrder.order_number}`}
                        <Text variant={'h6'} sx={{ lineHeight: 1 }}>
                            {salesOrder.patient.first_name} {salesOrder.patient.last_name}
                        </Text>
                    </DesignSidebarSection>

                    <DesignSidebarSection title={'Items'}>
                        <OrderDetailItemsAndPreferencesSection
                            fullDisplayInfoWithCommon={salesOrder.items_full_display_info_with_common}
                            itemsWarnings={[]}
                            direction={'column'}
                            title={''}
                            orderDetailInnerRootStyle={{ padding: 0, border: 'none' }}
                        />
                    </DesignSidebarSection>
                    <DesignSidebarSection title={'Doctor Photos'}>
                        {imageSources?.length ? (
                            <OrderDetailImagesBlock label={''} sources={imageSources} orderId={salesOrder?.id} />
                        ) : (
                            <Text variant={'caption'}>No images available</Text>
                        )}
                    </DesignSidebarSection>
                    <DesignSidebarSection title={'Notes'}>
                        <NotesSection notes={salesOrder.notes.doctor} />
                    </DesignSidebarSection>
                </InfoBlock>
                {designId && orderId && <SidebarFooter closeWindow={onClose} />}
            </Root>
            <SidebarToggleButton open={open} setOpen={setOpen} variant={'right'} panelWidth={PANEL_WIDTH} />
        </>
    );
};
