import { useDebugRoutingDecisionQuery, useListLabOrgs } from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { SimpleInput, SimpleSelect } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';
import ReactJson from 'react-json-view';

export const RoutingSampleLog = () => {
    const [orderId, setOrderId] = React.useState('');
    const [jitConfigType, setJitConfigType] = React.useState('');
    if (jitConfigType !== 'jit-routing' && jitConfigType !== 'order-creation') {
        setJitConfigType('order-creation');
    }

    const { data: labOrgs } = useListLabOrgs();
    const labsToOrganization: Record<string, string> = (labOrgs?.listOrganizations ?? []).reduce((mapping, labOrg) => {
        return { ...mapping, [labOrg.id]: labOrg.name };
    }, {});

    const { data, loading } = useDebugRoutingDecisionQuery({
        skip: !orderId,
        variables: {
            orderId: orderId,
            jitConfigType: jitConfigType ?? 'order-creation',
        },
    });
    const eventsWithIds =
        data?.debugRoutingDecision?.map((event, index) => {
            let rawjson = event.data ?? '{}';
            for (const [labId, organization] of Object.entries(labsToOrganization)) {
                rawjson = rawjson.replaceAll(labId, organization);
            }
            return {
                id: (index + 1).toString(),
                index: index,
                message: event.message,
                data: JSON.parse(rawjson),
            };
        }) ?? [];
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return (
        <Grid container style={{ paddingBottom: '3em' }}>
            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <SimpleInput
                    onChange={value => setOrderId(value ?? '')}
                    value={orderId}
                    label={'Enter an Order ID'}
                    TextFieldProps={{ style: { width: '700px' } }}
                />
                <SimpleSelect
                    onChange={value => setJitConfigType(value ?? 'order-creation')}
                    value={jitConfigType}
                    options={[
                        { value: 'jit-routing', label: 'JIT routing' },
                        { value: 'order-creation', label: 'Order Creation' },
                    ]}
                    label={'Select a JIT config type'}
                />
            </Grid>
            {loading && <div>Loading...</div>}
            {!loading && orderId && (
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={12}>
                        <MUITable<{ id: string; message: string; data: any }>
                            title={'Routing Events'}
                            data={eventsWithIds}
                            loading={loading}
                            paginationOptions={{ initialRowsPerPage: 1000, rowsPerPageOptions: [1000] }}
                            displayOptions={{ filter: false, sort: false, filterValues: false }}
                            columns={[
                                {
                                    title: 'ID',
                                    name: 'id',
                                    field: 'id',
                                    render: 'id',
                                    type: 'string',
                                    hidden: true,
                                },
                                {
                                    title: 'Event Number',
                                    name: 'index',
                                    field: 'index',
                                    render: 'index',
                                    type: 'numeric',
                                    hidden: false,
                                    defaultSort: 'asc',
                                },
                                {
                                    title: 'Message',
                                    name: 'message',
                                    field: 'message',
                                    render: 'message',
                                    type: 'string',
                                },
                                {
                                    title: 'Event Data',
                                    name: 'data',
                                    field: 'data',
                                    render: row =>
                                        Object.keys(row.data).length === 0 ? (
                                            ''
                                        ) : (
                                            <ReactJson
                                                name={null}
                                                src={row.data}
                                                collapsed={true}
                                                displayDataTypes={false}
                                                displayObjectSize={false}
                                                enableClipboard={false}
                                            />
                                        ),
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
    /* eslint-enable @typescript-eslint/no-explicit-any */
};
