import { Box, FlossPalette, Popover } from '@orthly/ui-primitives';
import React from 'react';

export interface Color {
    name: string;
    value: string; // hex color
}

interface ColorPickerProps {
    value?: string;
    onChange: (color: string) => void;
    colors: readonly Color[]; // Change to readonly
}

const ShadeColorPicker: React.FC<ColorPickerProps> = ({ value, onChange, colors }) => {
    const [selectedColor, setSelectedColor] = React.useState<string>(value || colors[0]?.value || '#000000');
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const handleColorSelect = (color: string) => {
        setSelectedColor(color);
        onChange(color);
        setIsPopoverOpen(false);
    };

    const anchorEl = React.useRef<HTMLDivElement>(null);

    return (
        <>
            <Box
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                sx={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    backgroundColor: value,
                    cursor: 'pointer',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                ref={anchorEl}
            />
            {isPopoverOpen && (
                <Popover open={isPopoverOpen} onClose={() => setIsPopoverOpen(false)} anchorEl={anchorEl.current}>
                    <Box sx={{ display: 'flex', gap: '8px', padding: '8px', width: '170px', flexWrap: 'wrap' }}>
                        {colors.map(color => (
                            <Box
                                key={color.value}
                                title={color.name}
                                onClick={() => handleColorSelect(color.value)}
                                sx={{
                                    width: '32px',
                                    height: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: color.value,
                                    cursor: 'pointer',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                    border:
                                        selectedColor === color.value ? `solid 2px ${FlossPalette.STAR_GRASS}` : 'none',
                                }}
                            />
                        ))}
                    </Box>
                </Popover>
            )}
        </>
    );
};

export default ShadeColorPicker;
