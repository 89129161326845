import type { ModelAppearance, MainViewModelRef, MainViewCameraControlsRef } from '@orthly/dentin';
import { MODEL_VIEWER_INITIAL_APPEARANCE } from '@orthly/dentin';
import type { FragmentType, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { StackY, StackX } from '@orthly/ui';
import { IconButton, XIcon, Text, styled, FlossPalette } from '@orthly/ui-primitives';
import { OrderDesignPreview } from '@orthly/veneer';
import React from 'react';

const Header = styled(StackX)({
    alignItems: 'center',
    height: 64,
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});

/** Full-screen viewer for design training orders, using OrderDesignPreview. */
export const DesignTrainingFullscreenViewer: React.VFC<{
    order: LabsGqlLabOrderFragment;
    design: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    caseNumber: number;
    close: () => void;
}> = ({ order, design, caseNumber, close }) => {
    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);

    return (
        <StackY style={{ height: '100%' }}>
            <Header>
                <IconButton
                    onClick={() => {
                        close();
                    }}
                >
                    <XIcon />
                </IconButton>
                <Text variant={'h6'}>{`Training Case #${caseNumber}`}</Text>
            </Header>
            <OrderDesignPreview
                fullScreen={true}
                order={order}
                userRole={'psr'}
                selectedDesignFragment={design}
                enableNewViewerWithProps={{
                    appearance,
                    setAppearance,
                    modelRef,
                    controlRef,
                }}
                updateRawScans
            />
        </StackY>
    );
};
