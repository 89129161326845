import { useAcceptDcmFiles } from './AcceptFilesFromBrowser.hooks';
import { Controller } from './Controller';
import { File3dDropLoader } from './File3dDropLoader';
import { getMeshFromDcm, addSplinesFromDcm, getMeshForFacetsFromDcm } from './ParseDcm.utils';
import type { DcmFile, SplineData } from './Types';
import type { MainViewCameraControlsRef } from '@orthly/dentin';
import { MorphPointsVisualizer, SceneViewer, ModelViewerControls } from '@orthly/dentin';
import type { MorphPointsData } from '@orthly/forceps';
import { FacetMarksPalette, isMeshWithBufferGeometry } from '@orthly/forceps';
import { FlossPalette, SimpleCheckbox, SimpleSelect, StackY } from '@orthly/ui';
import { styled, Box, Paper } from '@orthly/ui-primitives';
import { compact } from 'lodash';
import React from 'react';
import * as THREE from 'three';

const Root = styled(StackY)(() => ({
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
}));

const ModelViewerViewport = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    flex: 'auto',
    transform: 'translate(0, 0)',
}));

const StateBoxContainer = styled(Paper)(() => ({
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: '8px',
    padding: '8px',
}));

interface DcmViewerState {
    showFacetMarks: boolean;
    setShowFacetMarks: React.Dispatch<React.SetStateAction<boolean>>;
    showSplines: boolean;
    setShowSplines: React.Dispatch<React.SetStateAction<boolean>>;
    markPalette: FacetMarksPalette;
    setMarkPalette: React.Dispatch<React.SetStateAction<FacetMarksPalette>>;
    targetBit: number;
    setTargetBit: React.Dispatch<React.SetStateAction<number>>;
    showWireframe: boolean;
    setShowWireframe: React.Dispatch<React.SetStateAction<boolean>>;
}

const StateBox: React.VFC<DcmViewerState> = props => {
    return (
        <StateBoxContainer>
            <SimpleCheckbox
                setChecked={props.setShowFacetMarks}
                checked={props.showFacetMarks}
                label={'Show Facet Marks'}
            />
            <SimpleCheckbox setChecked={props.setShowSplines} checked={props.showSplines} label={'Show Curves'} />
            <SimpleCheckbox setChecked={props.setShowWireframe} checked={props.showWireframe} label={'Wireframe'} />

            {props.showFacetMarks && (
                <SimpleSelect
                    style={{ width: '200px', height: '40px', padding: '8px' }}
                    label={'Palette'}
                    options={Object.keys(FacetMarksPalette).map(k => ({ value: k }))}
                    value={props.markPalette ?? undefined}
                    onChange={val => props.setMarkPalette(val as FacetMarksPalette)}
                />
            )}
            {props.showFacetMarks && props.markPalette === 'TARGET_BIT' && (
                <input
                    name={'Target Bit'}
                    type={'number'}
                    placeholder={'0'}
                    value={props.targetBit}
                    onChange={e => props.setTargetBit(parseInt(e.target.value))}
                />
            )}
        </StateBoxContainer>
    );
};

const WIREFRAME_DEFAULT_VISIBILITY = false;

export const DcmViewer: React.VFC = () => {
    const [dcms, setDcms] = React.useState<DcmFile[]>([]);

    const [scene] = React.useState(new THREE.Scene());
    const [dcmMeshes, setDcmMeshes] = React.useState<THREE.Mesh[]>([]);
    const [wireframeMeshes, setWireframeMeshes] = React.useState<THREE.Mesh[]>([]);
    const [splines, setSplines] = React.useState<SplineData[]>([]);

    const [visibleSplines, setVisibleSplines] = React.useState<string[]>([]);
    const [morphPoints, setMorphPoints] = React.useState<MorphPointsData[]>([]);

    const { onDcmDrop } = useAcceptDcmFiles(setDcms);

    const [showFacetMarks, setShowFacetMarks] = React.useState(false);
    const [showSplines, setShowSplines] = React.useState(false);
    const [markPalette, setMarkPalette] = React.useState<FacetMarksPalette>(FacetMarksPalette.DEFAULT);
    const [targetBit, setTargetBit] = React.useState(0);
    const [showWireframe, setShowWireframe] = React.useState(WIREFRAME_DEFAULT_VISIBILITY);

    const stateControls: DcmViewerState = {
        showFacetMarks,
        setShowFacetMarks,
        showSplines,
        setShowSplines,
        targetBit,
        setTargetBit,
        markPalette,
        setMarkPalette,
        showWireframe,
        setShowWireframe,
    };

    React.useEffect(() => {
        const accumulatedDcmMeshes: THREE.Mesh[] = [];
        const accumulatedSplines: SplineData[] = [];
        const accumulatedMorphPoints: MorphPointsData[] = [];

        for (const { dcmManager, name } of dcms) {
            const mesh = showFacetMarks
                ? getMeshForFacetsFromDcm(dcmManager, name, markPalette, targetBit)
                : getMeshFromDcm(dcmManager, name);
            accumulatedDcmMeshes.push(mesh);
            addSplinesFromDcm(dcmManager, name, accumulatedSplines);
            accumulatedMorphPoints.push(...dcmManager.getMorphPoints());
        }

        if (accumulatedDcmMeshes.length > 0) {
            setDcmMeshes(accumulatedDcmMeshes);
            setWireframeMeshes(getWireframeMeshes(accumulatedDcmMeshes));
            if (showSplines) {
                setSplines(accumulatedSplines);
                setVisibleSplines(accumulatedSplines.map(({ name }) => name));
                setMorphPoints(accumulatedMorphPoints);
            }
        }
    }, [dcms, showFacetMarks, showSplines, markPalette, targetBit]);

    React.useEffect(() => {
        for (const child of scene.children) {
            // Clear out all meshes, but skip the wireframes because they're handled in a separate effect.
            if (isMeshWithBufferGeometry(child) && !child.name.startsWith('wireframe')) {
                scene.remove(child);
                child.geometry.dispose();
            }
        }

        dcmMeshes.forEach(el => {
            scene.add(el);
        });

        if (showSplines) {
            for (const { mesh } of splines) {
                scene.add(mesh);
            }
        }
    }, [scene, dcmMeshes, splines, showSplines]);

    React.useEffect(() => {
        wireframeMeshes.forEach(el => {
            scene.add(el);
        });

        return () => {
            wireframeMeshes.forEach(el => {
                scene.remove(el);
                el.geometry.dispose();
            });
        };
    }, [scene, wireframeMeshes]);

    React.useEffect(() => {
        for (const mesh of wireframeMeshes) {
            mesh.visible = showWireframe;
        }
    }, [wireframeMeshes, showWireframe]);

    const splinesWithVisibility = splines.map(el => ({
        ...el,
        visible: visibleSplines.includes(el.name),
    }));

    const setSplineVisibility = React.useCallback(
        (splineIndex: number, visible: boolean) => {
            const spline = splines[splineIndex];
            if (!spline) {
                console.warn(`Spline index ${splineIndex} out of range.`);
                return;
            }

            spline.mesh.visible = visible;
            setVisibleSplines(compact(splines.map(el => (el.mesh.visible ? el.name : null))));
        },
        [splines],
    );

    const controlRef: MainViewCameraControlsRef = React.useRef(null);

    return (
        <Root>
            <File3dDropLoader onDcmDrop={onDcmDrop} />
            <StateBox {...stateControls} />
            <ModelViewerViewport>
                <SceneViewer scene={scene}>
                    <ModelViewerControls ref={controlRef} controlStyle={'3shape'} />
                    <MorphPointsVisualizer morphPoints={morphPoints} />
                </SceneViewer>
                {splines.length && showSplines ? (
                    <Controller splines={splinesWithVisibility} setSplineVisibility={setSplineVisibility} />
                ) : null}
            </ModelViewerViewport>
        </Root>
    );
};

/**
 * Creates wireframe copies of the given meshes
 */
function getWireframeMeshes(meshes: THREE.Mesh[]): THREE.Mesh[] {
    return meshes.map(mesh => {
        const wireframeMesh = new THREE.Mesh(
            mesh.geometry,
            new THREE.MeshStandardMaterial({ wireframe: true, color: 0x000000 }),
        );
        wireframeMesh.visible = WIREFRAME_DEFAULT_VISIBILITY;
        wireframeMesh.name = `wireframe-${mesh.geometry.uuid}`;
        return wireframeMesh;
    });
}
