import type {
    Exact,
    LabsGqlDesignOrderDesignRevisionAssetInput,
    Scalars,
} from '../../../../../shared-libs/graphql-schema/dist';
import {
    LabsGqlDesignRevisionAssetFileType,
    LabsGqlOrderDesignScanType,
} from '../../../../../shared-libs/graphql-schema/dist';
import { AttachDesignAssetMutation } from '../../PainterRoot/PaintingStartBanner.graphql';
import { designIdAtom, orderIdAtom, sceneRefAtom } from '../../store/store';
import { handleGLBExport, type PainterData } from '../../utils/ExportHandler';
import { type MutationFunctionOptions, useMutation } from '@apollo/client';
import { type AttachDesignAssetMutation as AttachDesignAssetMutationType } from '@orthly/graphql-inline-react';
import { type BucketStoragePathConfig, DesignStorageConfigs, getFullStoragePath } from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import { uploadFilesToGCS, useFirebaseMultiFileUpload } from '@orthly/veneer';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import React from 'react';

export function usePainterSubmissionMutation(onSubmissionSuccess?: () => void): {
    submit: () => void;
    submitting: boolean;
} {
    const sceneRef = useAtomValue(sceneRefAtom);
    const { enqueueSnackbar } = useSnackbar();

    const [submissionUploading, setSubmissionUploading] = React.useState(false);
    const [storagePathConfig, setStoragePathConfig] = React.useState<BucketStoragePathConfig>({
        bucketName: '',
        path: '',
    });
    const orderId = useAtomValue(orderIdAtom);
    const designRevisionId = useAtomValue(designIdAtom);
    const [attachDesignAsset] = useMutation(AttachDesignAssetMutation);

    React.useEffect(() => {
        const dateString = `${Date.now()}`;
        if (orderId && designRevisionId) {
            const storagePathConfig = getFullStoragePath(
                OrthlyBrowserConfig.env,
                DesignStorageConfigs.designs,
                orderId,
                designRevisionId,
                'MeshWithMaterial',
                dateString,
            );
            setStoragePathConfig(storagePathConfig);
        }
    }, [orderId, designRevisionId]);

    const [uploadFn] = useFirebaseMultiFileUpload(storagePathConfig, []);

    const submit = React.useCallback(async () => {
        try {
            setSubmissionUploading(true);

            if (sceneRef) {
                const painterData: PainterData[] = await handleGLBExport(sceneRef);

                const uploadPromises = painterData.map((restorative: PainterData) => {
                    const files: File[] = [new File([restorative.data], getSubmissionFileName(restorative.name))];
                    return uploadFilesToGCS({ uploadFn, filesToSubmit: files });
                });

                const uploadResults = await Promise.all(uploadPromises);

                const attachPromises = uploadResults.map(result => {
                    if (orderId && result[0]?.uploadedPath) {
                        const mutationVariables: MutationFunctionOptions<
                            AttachDesignAssetMutationType,
                            Exact<{
                                designAsset: LabsGqlDesignOrderDesignRevisionAssetInput;
                                orderId: Scalars['String']['input'];
                            }>
                        > = {
                            variables: {
                                orderId: orderId,
                                designAsset: {
                                    design_revision_id: designRevisionId,
                                    file_path: result[0]?.uploadedPath, // Access the first element's uploadedPath
                                    file_type: LabsGqlDesignRevisionAssetFileType.Glb,
                                    image_path: null,
                                    mesh_type: LabsGqlOrderDesignScanType.Cad, // Or determine mesh type dynamically if possible
                                },
                            },
                        };

                        return attachDesignAsset(mutationVariables);
                    }
                    return Promise.resolve();
                });

                await Promise.all(attachPromises);
                enqueueSnackbar('Shade submitted!');
                onSubmissionSuccess && onSubmissionSuccess();
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                enqueueSnackbar(`Submission failure: ${error?.message ?? error}`, { variant: 'error' });
            } else {
                enqueueSnackbar(`Submission failure: ${String(error)}`, { variant: 'error' });
            }
        } finally {
            setSubmissionUploading(false);
        }
    }, [enqueueSnackbar, uploadFn, attachDesignAsset, designRevisionId, onSubmissionSuccess, orderId, sceneRef]);

    return { submit, submitting: submissionUploading };
}

function getSubmissionFileName(toothID: string): string {
    const fileName = `${toothID}.glb`;
    return fileName;
}
