import { useGetFormattedNextAvailableLdrTime } from '../../../../../Calendly/calendly-utils.graphql';
import { useIsOrderEligibleForVideoDesignReview } from '../GuidedWaxupScheduleLiveCall.hooks.graphql';
import { DesignReviewOption } from '../GuidedWaxupSelectDesignReviewOptionModal';
import { VdrEarlyAccessButton } from './GuidedWaxupSelectDesignReviewOptionVdrEarlyAccessButton';
import { RecommendationHeader } from '@orthly/dentin';
import { Box, FlossPalette, HelpIcon, RadioPrimitive as Radio, styled, Text, Skeleton } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

const ContentWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
});

const DesignReviewOptionButtonLayout = styled(Box, { shouldForwardProp: p => p !== 'selected' })(
    ({ selected }: { selected: boolean }) => ({
        width: '100%',
        background: selected ? FlossPalette.PRIMARY_BACKGROUND : FlossPalette.TAN,
        borderRadius: '16px',
        border: `${selected ? '2px' : '1px'} solid ${selected ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.STROKE_LIGHT}`,
        position: 'relative',
        display: 'grid',
        '&:hover': {
            border: `${selected ? '2px' : '1px'} solid ${selected ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.STROKE_DARK}`,
            cursor: 'pointer',
        },
    }),
);

const DesignReviewOptionButtonInnerContent = styled(Box)({
    padding: '16px',
});

const Subtext = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        padding: '0px 24px',
    },
}));

const DesignReviewOptionButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
        padding: '0px 24px',
    },
}));

const DesignReviewOptionButtonTitle = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 'auto',
    gap: '16px',
});

const DesignReviewOptionButtonTitleLayout = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const DesignReviewOptionButtonSubtitleLayout = styled(Box)({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
});

const EarlyAccessButtonMobileWrapper = styled(Box)({
    marginBottom: '8px',
});

const HelpIconGray = styled(HelpIcon)({
    height: 24,
    width: 24,
    color: FlossPalette.GRAY,
});

interface DesignReviewOptionButtonProps extends optionButtonProp {
    selected: boolean;
    setSelectedDesignReviewOption: (value: DesignReviewOption) => void;
    setShowHelpInfo: (show: boolean) => void;
    isSignedUpForVdr: boolean;
    signUpForVdr: () => void;
    isMobile: boolean;
    setCurrentHelpTab: (option: DesignReviewOption) => void;
}

const DesignReviewOptionButton: React.VFC<DesignReviewOptionButtonProps> = ({
    title,
    subtitle,
    availabilitySubtitle,
    selected,
    setSelectedDesignReviewOption,
    optionType,
    disabled,
    setShowHelpInfo,
    isSignedUpForVdr,
    signUpForVdr,
    isMobile,
    setCurrentHelpTab,
    recommended,
}) => {
    const isVdrButton = optionType === DesignReviewOption.REQUEST_VDR;

    return (
        <DesignReviewOptionButtonLayout
            selected={selected}
            onClick={() => {
                if (!disabled) {
                    setSelectedDesignReviewOption(optionType);
                }
            }}
        >
            {recommended && (
                <RecommendationHeader
                    styleOverrides={{
                        marginLeft: selected ? -2 : -1,
                        marginTop: -2,
                        width: `calc(100% + ${selected ? '4px' : '2px'})`,
                        height: '24px',
                        paddingLeft: '24px',
                        gap: '8px',
                        borderRadius: '16px 16px 0 0',
                    }}
                />
            )}
            <DesignReviewOptionButtonInnerContent>
                <DesignReviewOptionButtonTitleLayout>
                    <DesignReviewOptionButtonTitle>
                        <Radio
                            data-testid={`${optionType}-radio`}
                            disabled={disabled}
                            color={'secondary'}
                            style={{ margin: 0 }}
                            checked={selected}
                        />
                        <Text variant={'body2'} color={isVdrButton ? 'DARK_GRAY' : 'BLACK'} bold={!isVdrButton}>
                            {title}
                        </Text>
                        {isVdrButton && !isMobile && disabled && (
                            <VdrEarlyAccessButton isSignedUpForVdr={isSignedUpForVdr} signUpForVdr={signUpForVdr} />
                        )}
                    </DesignReviewOptionButtonTitle>
                    {optionType !== DesignReviewOption.CONTINUE_WITHOUT_REVIEW && (
                        <HelpIconGray
                            data-testid={`${optionType}-help-icon`}
                            onClick={() => {
                                setShowHelpInfo(true);
                                setCurrentHelpTab(optionType);
                            }}
                        />
                    )}
                </DesignReviewOptionButtonTitleLayout>
                {isVdrButton && isMobile && disabled && (
                    <EarlyAccessButtonMobileWrapper>
                        <VdrEarlyAccessButton isSignedUpForVdr={isSignedUpForVdr} signUpForVdr={signUpForVdr} />
                    </EarlyAccessButtonMobileWrapper>
                )}
                {subtitle && (
                    <DesignReviewOptionButtonSubtitleLayout>
                        {isVdrButton && isSignedUpForVdr && disabled && (
                            <Text variant={isMobile ? 'caption' : 'body2'} color={'BLACK'} medium>
                                You’ve signed up for early access. We'll notify you as soon as this feature is
                                available.
                            </Text>
                        )}
                        <Text variant={'body2'} color={'GRAY'}>
                            {subtitle}
                        </Text>
                    </DesignReviewOptionButtonSubtitleLayout>
                )}
                {availabilitySubtitle && (
                    <DesignReviewOptionButtonSubtitleLayout>
                        <Text variant={'caption'} color={'GRAY'} medium>
                            {availabilitySubtitle}
                        </Text>
                    </DesignReviewOptionButtonSubtitleLayout>
                )}
            </DesignReviewOptionButtonInnerContent>
        </DesignReviewOptionButtonLayout>
    );
};

interface GuidedWaxupSelectDesignReviewOptionModalContentProps {
    selectedDesignReviewOption: DesignReviewOption;
    setSelectedDesignReviewOption: (value: DesignReviewOption) => void;
    setShowHelpInfo: (show: boolean) => void;
    isSignedUpForVdr: boolean;
    signUpForVdr: () => void;
    isMobile: boolean;
    setCurrentHelpTab: (option: DesignReviewOption) => void;
    ddpReadinessDate: string | null;
    shouldRecommendLdr: boolean;
    orderId: string;
}
interface optionButtonProp {
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    availabilitySubtitle?: string | JSX.Element;
    optionType: DesignReviewOption;
    disabled?: boolean;
    recommended?: boolean;
}

export const GuidedWaxupSelectDesignReviewOptionModalContent: React.VFC<
    GuidedWaxupSelectDesignReviewOptionModalContentProps
> = ({
    selectedDesignReviewOption,
    setSelectedDesignReviewOption,
    setShowHelpInfo,
    isSignedUpForVdr,
    signUpForVdr,
    isMobile,
    setCurrentHelpTab,
    ddpReadinessDate,
    shouldRecommendLdr,
    orderId,
}) => {
    const nextAvailableLdrTimeString = useGetFormattedNextAvailableLdrTime();

    const isVDREnabled = useIsOrderEligibleForVideoDesignReview({ id: orderId });

    const optionButtonProps: optionButtonProp[] = [
        {
            title: 'Reject Design & Continue with Design Preview Only',
            optionType: DesignReviewOption.CONTINUE_WITHOUT_REVIEW,
        },
        {
            title: 'Talk With a Lab Technician',
            subtitle:
                'Schedule a 15 min live call with a Lab Technician to review, update, and approve designs in real time.',
            availabilitySubtitle: nextAvailableLdrTimeString?.length ? (
                nextAvailableLdrTimeString
            ) : (
                <Skeleton width={'35ch'} />
            ),
            optionType: DesignReviewOption.REQUEST_LDR,
            recommended: shouldRecommendLdr,
        },
        {
            title: (
                <Text component={'span'} variant={'body2'} medium color={'DARK_GRAY'}>
                    {!isVDREnabled ? 'Coming Soon! ' : ''}
                    <Text
                        component={'span'}
                        variant={'body2'}
                        color={isVDREnabled ? 'BLACK' : 'DARK_GRAY'}
                        medium={isVDREnabled}
                    >
                        Request Video Design Review
                    </Text>
                </Text>
            ),
            subtitle: 'Receive a video of your design feedback being applied along with a before-and-after comparison.',
            availabilitySubtitle:
                isVDREnabled && ddpReadinessDate
                    ? `Design & Video Available ${dayjs(ddpReadinessDate).format('MMM DD')}`
                    : undefined,
            optionType: DesignReviewOption.REQUEST_VDR,
            disabled: !isVDREnabled,
        },
    ];

    return (
        <ContentWrapper>
            <Subtext>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    We see you have feedback on this design. We'd love to get it just right for you!
                </Text>
            </Subtext>
            <DesignReviewOptionButtonWrapper>
                {optionButtonProps.map(props => (
                    <DesignReviewOptionButton
                        key={props.optionType}
                        {...props}
                        selected={props.optionType === selectedDesignReviewOption}
                        setSelectedDesignReviewOption={setSelectedDesignReviewOption}
                        setShowHelpInfo={setShowHelpInfo}
                        isSignedUpForVdr={isSignedUpForVdr}
                        signUpForVdr={signUpForVdr}
                        isMobile={isMobile}
                        setCurrentHelpTab={setCurrentHelpTab}
                    />
                ))}
            </DesignReviewOptionButtonWrapper>
        </ContentWrapper>
    );
};
