import { AutomationBuilderActions } from './AutomationBuilder.actions';
import type {
    AutomationBuilderState,
    AutomationBuilderFormState,
    ActionConfigValue,
    ActionFormField,
} from './AutomationBuilder.types';
import { addConfigFieldError } from './AutomationConfigValidator';
import type { LabsGqlOrderAutomationFragment } from '@orthly/graphql-operations';
import { LabsGqlFilterCombinator } from '@orthly/graphql-schema';
import { handleActions } from 'redux-actions';

const initialState: AutomationBuilderState = {
    form: {
        filter_criteria: [],
        filter_combinator: LabsGqlFilterCombinator.And,
        actions: [],
    },
    editing: false,
};

export function savedAutomationToFormState(saved?: LabsGqlOrderAutomationFragment): AutomationBuilderFormState {
    if (!saved) {
        return initialState.form;
    }
    const { name, description, filter, triggered_by, actions } = saved;
    return {
        name,
        description,
        triggered_by,
        filter_combinator: filter.combinator,
        filter_criteria: filter.criteria.map(v => ({
            comparator: v.comparator,
            comparison_value: v.comparison_value,
            filter_id: v.filter_id,
            list_combinator: v.list_combinator,
        })),
        actions: actions.flatMap<ActionConfigValue>(actionConfig => {
            if (!actionConfig.action) {
                return [];
            }
            return {
                id: actionConfig.action_id,
                description: actionConfig.action.description,
                name: actionConfig.action.name,
                test_available: actionConfig.action.test_available,
                fields: actionConfig.action.fields.map<ActionFormField>(fieldDef => {
                    const fieldSubmission = actionConfig.fields.find(f => f.field_id === fieldDef.id);
                    return addConfigFieldError({
                        ...fieldDef,
                        value: fieldSubmission?.value ?? fieldDef.default_value ?? undefined,
                        touched: !!fieldSubmission,
                    });
                }),
            };
        }),
    };
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formReducer = handleActions<AutomationBuilderFormState, any>(
    {
        ...AutomationBuilderActions.UPDATE_FORM.reducer<AutomationBuilderFormState>((state, action) => ({
            ...state,
            [action.payload.property]: action.payload.value,
        })),
        ...AutomationBuilderActions.UPDATE_ORDER_FILTER.reducer<AutomationBuilderFormState>((state, { payload }) => {
            if (!state.filter_criteria[payload.idx]) {
                return { ...state, filter_criteria: [...state.filter_criteria, payload.value] };
            }
            return {
                ...state,
                filter_criteria: state.filter_criteria.map((f, idx) => (idx === payload.idx ? payload.value : f)),
            };
        }),
        ...AutomationBuilderActions.ADD_ORDER_FILTER.reducer<AutomationBuilderFormState>(state => ({
            ...state,
            filter_criteria: [...state.filter_criteria, {}],
        })),
        ...AutomationBuilderActions.DELETE_ORDER_FILTER.reducer<AutomationBuilderFormState>((state, action) => ({
            ...state,
            filter_criteria: state.filter_criteria.filter((_f, idx) => idx !== action.payload),
        })),
        ...AutomationBuilderActions.SET_COMBINATOR.reducer<AutomationBuilderFormState>((state, action) => ({
            ...state,
            filter_combinator: action.payload,
        })),
        ...AutomationBuilderActions.ADD_ACTION.reducer<AutomationBuilderFormState>((state, action) => ({
            ...state,
            actions: state.actions.concat({
                ...action.payload,
                fields: action.payload.fields.map(f =>
                    addConfigFieldError({ ...f, value: f.default_value, touched: false }),
                ),
            }),
        })),
        ...AutomationBuilderActions.DELETE_ACTION.reducer<AutomationBuilderFormState>((state, { payload }) => ({
            ...state,
            actions: state.actions.filter(a => a.id !== payload.id),
        })),
        ...AutomationBuilderActions.UPDATE_ACTION_FIELD.reducer<AutomationBuilderFormState>((state, { payload }) => ({
            ...state,
            actions: state.actions.map(a => {
                if (a.id !== payload.action_id) {
                    return a;
                }
                return {
                    ...a,
                    fields: a.fields.map(f =>
                        f.id !== payload.field_id
                            ? f
                            : addConfigFieldError({ ...f, touched: true, value: payload.value }),
                    ),
                };
            }),
        })),
    },
    initialState.form,
);

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function delegateToFormReducer(state: AutomationBuilderState, action: any): AutomationBuilderState {
    return { ...state, form: formReducer(state.form, action) };
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const automationBuilderReducer = handleActions<AutomationBuilderState, any>(
    {
        ...Object.values(AutomationBuilderActions).reduce(
            (state, action) => ({
                ...state,
                // EPDPLT-4736: Using any is unsafe and should be avoided.
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...(action.reducer as any)(delegateToFormReducer),
            }),
            {},
        ),
        ...AutomationBuilderActions.RESET.reducer<AutomationBuilderState>(_state => initialState),
        ...AutomationBuilderActions.ENTER_EDITING.reducer<AutomationBuilderState>((state, action) => {
            if (state.editing) {
                return state;
            }
            return { ...state, saved: action.payload, form: savedAutomationToFormState(action.payload), editing: true };
        }),
        ...AutomationBuilderActions.ENTER_DUPLICATE.reducer<AutomationBuilderState>((state, action) => {
            return { ...state, saved: undefined, form: savedAutomationToFormState(action.payload), editing: true };
        }),
        ...AutomationBuilderActions.AUTOMATION_SAVED.reducer<AutomationBuilderState>((state, action) => {
            return { ...state, saved: action.payload, form: savedAutomationToFormState(action.payload), editing: true };
        }),
        ...AutomationBuilderActions.UPDATE_SAVED_AUTOMATION.reducer<AutomationBuilderState>((state, action) => ({
            ...state,
            saved: state.saved ? { ...state.saved, ...action.payload } : undefined,
        })),
        ...AutomationBuilderActions.RESET_FORM_TO_SAVED.reducer<AutomationBuilderState>(state => ({
            ...state,
            form: state.saved ? savedAutomationToFormState(state.saved) : state.form,
        })),
    },
    initialState,
);
