import { inboxReducer } from '../screens/Inbox/state/Inbox.state';
import { ordersOverviewReducer } from '../screens/Orders/state/OrdersOverview.state';
import { palateReducer } from '../screens/Palate/state/Palate.reducer';
import { usersReducer } from '../screens/Users/state/Users.state';
import { getAdminReduxMiddleware } from './admin-analytics.middleware';
import type { AdminState } from './redux.types';
import { adminUiReducer } from './ui/ui.state';
import { createAsyncReducer } from '@orthly/redux-async-actions';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { persistReducer, persistStore } from 'redux-persist';
import type { PersistPartial } from 'redux-persist/es/persistReducer';
import localStorage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const reducer = combineReducers<AdminState>({
    ui: adminUiReducer,
    async: createAsyncReducer(false),
    router: routerReducer,
    ordersOverview: ordersOverviewReducer,
    inbox: inboxReducer,
    users: usersReducer,
    palate: palateReducer,
});

const rootReducer = (state: AdminState | undefined, action: AnyAction) => reducer(state, action);

export const initRedux = () => {
    const initialState = {};
    const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true';
    const composeEnhancers =
        (isDev &&
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: false,
                traceLimit: 5,
            })) ||
        compose;

    const composedEnhancers = composeEnhancers(
        applyMiddleware(...getAdminReduxMiddleware([routerMiddleware, thunk])),
        Sentry.createReduxEnhancer(),
    );

    const persistedReducer = persistReducer<AdminState>(
        // don't persist the support state, no benefit
        { key: 'root', storage: localStorage, blacklist: ['router'] },
        rootReducer,
    );

    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const store = createStore<AdminState & PersistPartial, any, any, any>(
        persistedReducer,
        // EPDPLT-4736: Using any is unsafe and should be avoided.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        initialState as any,
        composedEnhancers,
    );

    const history = createReduxHistory(store);

    return { store, history };
};

// we export singletons so that we use the same store across the entire app
export const { store, history } = initRedux();
export const persistor = persistStore(store);

if (window.Cypress || process.env.REACT_APP_NODE_ENV === 'test') {
    window.store = store;
}
