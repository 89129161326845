import { type ICameraControls, ModelViewerControls } from '../ModelViewer';
import { PlayWrightWorldPointerEventManager } from './PlayWrightWorldPointerEventManager';
import { type ScanReviewViewManager, ScanReviewPanelCameraState } from './ScanReviewViewTypes';
import { type PlayWrightVector3, type PlayWrightCameraPose, PlayWrightCanvasEvents } from '@orthly/shared-types';
import { OrthographicCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';

export const ScanReviewPanel: React.FC<{ viewManager: ScanReviewViewManager }> = ({ viewManager, ...props }) => {
    const initializeCameraControlsCallback = React.useCallback(
        (controls: ICameraControls) => {
            viewManager.initializeControls(controls);
        },
        [viewManager],
    );

    const worldPointerEventManager = React.useMemo(() => {
        return new PlayWrightWorldPointerEventManager(viewManager.canvasRef, viewManager.cameraRef);
    }, [viewManager.cameraRef, viewManager.canvasRef]);

    React.useEffect(() => {
        return () => {
            viewManager.updateViewStateFromControls();
        };
    }, [viewManager]);

    React.useEffect(() => {
        const makeVector = (v: PlayWrightVector3) => {
            return new THREE.Vector3(v.x, v.y, v.z);
        };

        const makeEuler = (v: PlayWrightVector3) => {
            return new THREE.Euler(v.x, v.y, v.z);
        };

        const setCameraView = (e: CustomEvent<PlayWrightCameraPose>) => {
            viewManager.updateViewState(
                new ScanReviewPanelCameraState(
                    makeVector(e.detail.position),
                    makeEuler(e.detail.rotation),
                    makeVector(e.detail.up),
                    e.detail.zoom,
                    makeVector(e.detail.target),
                ),
            );
        };

        const handlePointerMove = worldPointerEventManager.handleWorldPointerMove.bind(worldPointerEventManager);
        const handlePointerDown = worldPointerEventManager.handleWorldPointerDown.bind(worldPointerEventManager);
        const handlePointerUp = worldPointerEventManager.handleWorldPointerUp.bind(worldPointerEventManager);

        viewManager.canvasRef.current?.setAttribute('data-testid', 'review-panel-canvas');
        viewManager.canvasRef.current?.addEventListener(PlayWrightCanvasEvents.SetCameraView, setCameraView);
        viewManager.canvasRef.current?.addEventListener(PlayWrightCanvasEvents.WorldPointerMove, handlePointerMove);
        viewManager.canvasRef.current?.addEventListener(PlayWrightCanvasEvents.WorldPointerDown, handlePointerDown);
        viewManager.canvasRef.current?.addEventListener(PlayWrightCanvasEvents.WorldPointerUp, handlePointerUp);
        return () => {
            viewManager.canvasRef.current?.removeEventListener(PlayWrightCanvasEvents.SetCameraView, setCameraView);
            viewManager.canvasRef.current?.removeEventListener(
                PlayWrightCanvasEvents.WorldPointerMove,
                handlePointerMove,
            );
            viewManager.canvasRef.current?.removeEventListener(
                PlayWrightCanvasEvents.WorldPointerDown,
                handlePointerDown,
            );
            viewManager.canvasRef.current?.removeEventListener(PlayWrightCanvasEvents.WorldPointerUp, handlePointerUp);
        };
    }, [viewManager, viewManager.canvasRef, worldPointerEventManager]);

    return (
        <Canvas
            ref={viewManager.canvasRef}
            orthographic={true}
            linear={true}
            mode={'concurrent'}
            data-testid={'review-panel'}
            gl={{ preserveDrawingBuffer: true }}
            onCreated={({ gl }) => {
                gl.toneMapping = THREE.NoToneMapping;
                gl.outputEncoding = THREE.LinearEncoding;
            }}
        >
            <fog color={new THREE.Color(0x001e45)} near={100} far={1000} />

            <OrthographicCamera ref={viewManager.cameraRef} makeDefault zoom={4} position={[0, 0, 100]}>
                <pointLight intensity={0.8} color={0xffffff} />
            </OrthographicCamera>
            <ModelViewerControls ref={initializeCameraControlsCallback} controlStyle={'3shape'} />
            <ambientLight intensity={0.05} color={0xffffff} />
            <primitive object={viewManager.scene.scene} />
            {props.children}
        </Canvas>
    );
};
