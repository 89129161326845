import { RuleConditionPreview } from '../../../../../../components/RuleTable/RuleConditionPreview';
import type { RuleRow } from '../CapacityStationsRoot.graphql';
import { EditRuleRowActions } from './EditRuleRowActions.graphql';
import MUITable from '@orthly/mui-table';
import { Tooltip } from '@orthly/ui-primitives';
import React from 'react';
import ReactJson from 'react-json-view';

export interface RulesTableProps {
    ruleRows: RuleRow[];
    loadingRules: boolean;
    setOpenAddRuleDialog: (open: boolean) => void;
    refetchRules: () => Promise<unknown>;
}

export const CapacityStationRuleTable: React.VFC<RulesTableProps> = (args: RulesTableProps) => {
    const { ruleRows, loadingRules, setOpenAddRuleDialog, refetchRules } = args;
    return (
        <MUITable<RuleRow>
            title={`Capacity Station Rules`}
            data={ruleRows}
            loading={loadingRules}
            displayOptions={{
                download: true,
                filter: true,
                sort: true,
                filterValues: true,
                fixedSearch: true,
            }}
            actions={{
                global: [
                    {
                        icon: 'add',
                        position: 'toolbar',
                        onClick: () => setOpenAddRuleDialog(true),
                        tooltip: 'Add rule',
                    },
                    {
                        icon: 'refresh',
                        position: 'toolbar',
                        onClick: () => refetchRules().catch(console.error),
                        tooltip: 'Refresh',
                    },
                ],
            }}
            columns={[
                {
                    name: 'ID',
                    render: 'id',
                    type: 'string',
                    hidden: true,
                    defaultSort: 'asc',
                },
                {
                    name: 'Name',
                    field: 'name',
                    render: ({ name }) => (
                        <Tooltip title={name}>
                            <div>{name.length > 100 ? `${name.slice(0, 75)}...` : name}</div>
                        </Tooltip>
                    ),
                },
                {
                    name: 'Station ID',
                    render: 'capacity_station_id',
                    type: 'string',
                },
                {
                    name: 'Station Name',
                    render: 'station_name',
                    type: 'string',
                },
                {
                    name: 'Work order type',
                    render: 'work_order_type',
                    type: 'string',
                },
                {
                    name: 'Default Unit Limit',
                    render: 'default_unit_limit',
                    type: 'numeric',
                },
                {
                    name: 'Matching Logic',
                    render: row => {
                        if (row.matching_logic_json) {
                            return <RuleConditionPreview ruleType={'item'} rawJSON={row.matching_logic_json} />;
                        } else if (row.lab_product_codes) {
                            return (
                                <ReactJson
                                    name={'Product Codes'}
                                    src={row.lab_product_codes}
                                    collapsed={true}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    enableClipboard={false}
                                />
                            );
                        } else if (row.design_station_codes) {
                            return (
                                <ReactJson
                                    name={'Design Station Codes'}
                                    src={row.design_station_codes}
                                    collapsed={true}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    enableClipboard={false}
                                />
                            );
                        }
                    },
                },
                {
                    name: 'Work Order Filter Condition',
                    render: row => {
                        if (row.work_order_matching_logic_json) {
                            return (
                                <RuleConditionPreview ruleType={'order'} rawJSON={row.work_order_matching_logic_json} />
                            );
                        }
                        return '';
                    },
                },
                {
                    title: 'Actions',
                    name: 'actions',
                    render: row => <EditRuleRowActions row={row} refetchRules={refetchRules} />,
                    filter: true,
                    customFilterFn: (values, row) => values.includes(`${row.disabled}`),
                    filterOptions: {
                        defaultValues: ['false'],
                    },
                    type: 'boolean',
                },
            ]}
        />
    );
};
