import { useScanReviewLandingPageContext } from './ScanReviewLandingPage.hooks';
import type { QcHeatmapRange } from '@orthly/dentin';
import {
    ScanReviewViewType,
    ScanReviewDisplayType,
    QCColorLegend,
    ScanReviewMode,
    type ScanReviewScene,
    ScanReviewMarginLine,
    DEFAULT_UPPER_JAW_INSERTION_AXIS,
    DEFAULT_UNDERCUT_SHADING_RADIUS,
    DEFAULT_LOWER_JAW_INSERTION_AXIS,
} from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';
import { type ToothNumber, AllToothNumbers, AllLowerToothNumbers, ToothUtils } from '@orthly/items';
import { StackY, styled, FlossPalette, NumberArrayFlipperInput, StackX } from '@orthly/ui';
import { Text, Button, Box, FormControlLabel, Checkbox } from '@orthly/ui-primitives';
import React from 'react';

const ToolPanel = styled(Box)({
    borderRadius: 8,
    margin: '4px',
    border: `2px solid ${FlossPalette.STROKE_DARK}`,
    backgroundColor: FlossPalette.WHITE,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    minWidth: 0,
});

const ToothNumberPanel = styled(Box)({
    borderRadius: 4,
    margin: '4px',
    border: `2px solid ${FlossPalette.STROKE_DARK}`,
    backgroundColor: FlossPalette.WHITE,
    overflow: 'scroll',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    flexDirection: 'column',
    maxHeight: '150px',
    minHeight: 0,
    minWidth: 0,
});

const QCToolbarContainer = styled(StackY)({
    position: 'absolute',
    zIndex: 21,
    bottom: 86,
    right: 16,
});

export const ScanReviewQCToolbar: React.VFC<{
    type: HeatMapType;
    range: QcHeatmapRange;
    setRange: (newHeatmapRange: QcHeatmapRange) => void;
}> = ({ type, range, setRange }) => {
    return (
        <QCToolbarContainer>
            <QCColorLegend
                heatMapType={type}
                dynamicHeatmaps={true}
                heatMapRange={range}
                setHeatMapRange={setRange}
                heatMapStep={0.05}
            />
        </QCToolbarContainer>
    );
};

export const ScanReviewCompleteViewToolButtonsToolbar: React.VFC = () => {
    const { scanReviewAppState, scanReviewAppStateManager } = useScanReviewLandingPageContext();
    return (
        <StackX>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={scanReviewAppState.isScanOrScanUndercutDisplay}
                        onChange={e =>
                            e.target.checked && scanReviewAppStateManager.setDisplayType(ScanReviewDisplayType.Scan)
                        }
                    />
                }
                label={'Scan'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={scanReviewAppState.isStoneModelOrStoneModelUndercutDisplay}
                        onChange={e => {
                            if (e.target.checked) {
                                scanReviewAppStateManager.setDisplayType(ScanReviewDisplayType.StoneModel);
                            }
                        }}
                    />
                }
                label={'Stone Model'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={scanReviewAppState.displayType === ScanReviewDisplayType.BiteAnalysis}
                        onChange={e =>
                            e.target.checked &&
                            scanReviewAppStateManager.setDisplayType(ScanReviewDisplayType.BiteAnalysis)
                        }
                    />
                }
                label={'Bite Analysis'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={scanReviewAppState.displayType === ScanReviewDisplayType.Segmentation}
                        onChange={e =>
                            e.target.checked &&
                            scanReviewAppStateManager.setDisplayType(ScanReviewDisplayType.Segmentation)
                        }
                    />
                }
                label={'Scan Segmentation'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={
                            scanReviewAppState.viewType !== ScanReviewViewType.Single ||
                            scanReviewAppState.toothNumber === undefined
                        }
                        checked={scanReviewAppState.mode === ScanReviewMode.MarginMarking}
                        onChange={e =>
                            e.target.checked
                                ? scanReviewAppStateManager.setMode(ScanReviewMode.MarginMarking)
                                : scanReviewAppStateManager.setMode(ScanReviewMode.Review)
                        }
                    />
                }
                label={'Margin Marking'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={
                            scanReviewAppState.viewType !== ScanReviewViewType.Single ||
                            (!scanReviewAppState.upperJawInScene && !scanReviewAppState.lowerJawInScene) ||
                            scanReviewAppState.toothNumber === undefined
                        }
                        checked={scanReviewAppState.mode === ScanReviewMode.Undercut}
                        onChange={e =>
                            e.target.checked
                                ? scanReviewAppStateManager.setMode(ScanReviewMode.Undercut)
                                : scanReviewAppStateManager.setMode(ScanReviewMode.Review)
                        }
                    />
                }
                label={'Undercut'}
            />
        </StackX>
    );
};

export const ScanReviewToothPrepPanel: React.VFC = () => {
    const { preppedTeeth, scanReviewAppState, scanReviewAppStateManager } = useScanReviewLandingPageContext();
    return (
        <StackY>
            <Text variant={'h4'} medium align={'center'}>
                Prepped Teeth
            </Text>
            <ToothNumberPanel>
                {/*The logic here and in the NumberArrayFlipperInput component below is a bit complicated, but only exists for the purposes*/}
                {/*of providing a developer interface for testing margin marking . It isn't relevant for the use case of */}
                {/*integrating the new scan review components into an external application like ChairSide.*/}
                {AllToothNumbers.map(unn => (
                    <FormControlLabel
                        key={`prepped-tooth-${unn}`}
                        control={
                            <Checkbox
                                checked={preppedTeeth.get(unn)}
                                onChange={e => {
                                    const addActiveToothData = preppedTeeth.get(unn) === false && e.target.checked;
                                    const removeActiveToothData = preppedTeeth.get(unn) === true && !e.target.checked;
                                    const defaultAxis = ToothUtils.toothIsLower(unn)
                                        ? DEFAULT_LOWER_JAW_INSERTION_AXIS.direction.clone()
                                        : DEFAULT_UPPER_JAW_INSERTION_AXIS.direction.clone();

                                    preppedTeeth.set(unn, e.target.checked);
                                    if (addActiveToothData) {
                                        scanReviewAppStateManager.addMarginLine(
                                            new ScanReviewMarginLine(unn, [], false),
                                        );
                                        scanReviewAppStateManager.addInsertionAxis({
                                            unn,
                                            direction: defaultAxis,
                                            maxDistance: DEFAULT_UNDERCUT_SHADING_RADIUS,
                                        });
                                        scanReviewAppStateManager.setToothNumber(unn);
                                    } else if (removeActiveToothData) {
                                        scanReviewAppStateManager.deleteMarginLine(unn);
                                        scanReviewAppStateManager.deleteInsertionAxis(unn);
                                        const candidateTeeth = [...preppedTeeth.entries()]
                                            .filter(([_, value]) => value)
                                            .map(([key, _]) => key);
                                        const newActiveTooth = candidateTeeth.length
                                            ? candidateTeeth.reduce((a, b) =>
                                                  Math.abs(a - unn) < Math.abs(b - unn) ? a : b,
                                              )
                                            : undefined;
                                        scanReviewAppStateManager.setToothNumber(newActiveTooth);
                                    }
                                }}
                            />
                        }
                        label={unn}
                    />
                ))}
            </ToothNumberPanel>
            {scanReviewAppState.toothNumber !== undefined && (
                <ToolPanel>
                    <NumberArrayFlipperInput
                        value={scanReviewAppState.toothNumber}
                        onChange={unn => scanReviewAppStateManager.setToothNumber(unn as ToothNumber)}
                        allowedValues={[...preppedTeeth]
                            .filter(([_key, value]) => value === true)
                            .map(([key, _value]) => key)}
                    />
                </ToolPanel>
            )}
        </StackY>
    );
};

export const ScanReviewAiScanTools: React.VFC = () => {
    const {
        loading,
        calculateInsertionAxisCallback,
        calculateMarginLineCallback,
        getSegmentationDataCallback,
        scanReviewAppState,
        scanReviewAppStateManager,
    } = useScanReviewLandingPageContext();

    if (scanReviewAppState.viewType !== ScanReviewViewType.Single) {
        return <></>;
    }
    return (
        <ToolPanel>
            <Button
                variant={'nav'}
                disabled={loading}
                onClick={async () => await getSegmentationDataCallback(scanReviewAppStateManager.scene)}
            >
                Get Segmentation Data
            </Button>
            <Button
                variant={'nav'}
                disabled={loading}
                onClick={async () =>
                    await calculateMarginLineCallback(
                        scanReviewAppStateManager.scene,
                        scanReviewAppState,
                        scanReviewAppStateManager,
                    )
                }
            >
                Calculate Margin Line
            </Button>
            <Button
                variant={'nav'}
                disabled={loading}
                onClick={async () =>
                    await calculateInsertionAxisCallback(scanReviewAppStateManager.scene, scanReviewAppState)
                }
            >
                Calculate Insertion Axis
            </Button>
        </ToolPanel>
    );
};

export const ScanReviewCompleteViewToolbar: React.VFC = () => {
    const { scanReviewAppState, scanReviewAppStateManager } = useScanReviewLandingPageContext();
    React.useEffect(() => {
        if (!scanReviewAppState.toothNumber) {
            return;
        }
        const isLowerTooth = AllLowerToothNumbers.filter(n => n === scanReviewAppState.toothNumber).length > 0;
        scanReviewAppStateManager.setLowerJawVisible(isLowerTooth);
    }, [scanReviewAppState.toothNumber, scanReviewAppStateManager]);

    return (
        <StackY>
            <ScanReviewToothPrepPanel />
            {scanReviewAppState.mode === ScanReviewMode.MarginMarking && (
                <ToolPanel>
                    <StackX>
                        <Button
                            variant={'text'}
                            fullWidth={true}
                            onClick={() => {
                                scanReviewAppStateManager?.resetMarginLines();
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            variant={'text'}
                            fullWidth={true}
                            onClick={() => {
                                scanReviewAppStateManager?.undoMarginLineEdits();
                            }}
                        >
                            Undo
                        </Button>
                    </StackX>
                </ToolPanel>
            )}
            <ToolPanel>
                {scanReviewAppState.mode !== ScanReviewMode.Undercut && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={e => scanReviewAppStateManager.setInsertionAxisVisible(e.target.checked)}
                                checked={scanReviewAppState.insertionAxisIsVisible}
                            />
                        }
                        label={'Display Insertion Axis'}
                    />
                )}
                {(scanReviewAppState.mode === ScanReviewMode.Review ||
                    scanReviewAppState.mode === ScanReviewMode.Undercut) && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={e => scanReviewAppStateManager.setMarginIsVisible(e.target.checked)}
                                checked={scanReviewAppState.marginIsVisible}
                            />
                        }
                        label={'Display Margin Line'}
                    />
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={e => scanReviewAppStateManager.setTubeMarginLinesEnabled(e.target.checked)}
                            checked={scanReviewAppState.tubeMarginLinesEnabled}
                        />
                    }
                    label={'Enable Tube Margin Lines'}
                />
            </ToolPanel>

            {scanReviewAppState.displayType === ScanReviewDisplayType.Segmentation && <ScanReviewAiScanTools />}
            {scanReviewAppState.mode === ScanReviewMode.Undercut && (
                <ToolPanel>
                    <ScanReviewQCToolbar
                        type={HeatMapType.Undercut}
                        range={scanReviewAppState.undercutHeatmapRange}
                        setRange={qc => scanReviewAppStateManager.setUndercutHeatmapRange(qc)}
                    />
                </ToolPanel>
            )}
        </StackY>
    );
};

export const ScanReviewCompleteViewButtonsToolbar: React.FC<{ scene: ScanReviewScene }> = ({ scene, ...props }) => {
    const { scanReviewAppState, scanReviewAppStateManager } = useScanReviewLandingPageContext();
    return (
        <StackY>
            <ToolPanel>
                <Button
                    variant={'text'}
                    onClick={() => scanReviewAppStateManager.setViewType(ScanReviewViewType.Single)}
                >
                    Single View
                </Button>
                <Button
                    variant={'text'}
                    onClick={() => scanReviewAppStateManager.setViewType(ScanReviewViewType.SideBySide)}
                >
                    Side By Side
                </Button>
                <Button
                    variant={'text'}
                    onClick={() => scanReviewAppStateManager.setViewType(ScanReviewViewType.Complete)}
                >
                    Complete View
                </Button>
            </ToolPanel>
            <ToolPanel>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={e => scanReviewAppStateManager.setUpperJawVisible(e.target.checked)}
                            checked={scanReviewAppState.upperJawVisible}
                        />
                    }
                    label={'Upper'}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={e => scanReviewAppStateManager.setLowerJawVisible(e.target.checked)}
                            checked={scanReviewAppState.lowerJawVisible}
                        />
                    }
                    label={'Lower'}
                />
                {props.children}
            </ToolPanel>
            {scanReviewAppState.mode === ScanReviewMode.Undercut && (
                <Button variant={'text'} onClick={() => scanReviewAppStateManager.updateInsertionAxisFromView()}>
                    Set Direction
                </Button>
            )}
            {scanReviewAppState.displayType === ScanReviewDisplayType.BiteAnalysis && (
                <ScanReviewQCToolbar
                    type={HeatMapType.Clearance}
                    range={scanReviewAppState.heatmapRange}
                    setRange={qc => scanReviewAppStateManager.setHeatmapRange(qc)}
                />
            )}
        </StackY>
    );
};
