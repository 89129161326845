import { ToolNames } from '../components/ToolList/ToolList';
import { toothRefAtom } from '../store/designsStore';
import {
    cameraControlsDisabledAtom,
    selectedBrushIndexAtom,
    store,
    textureRefAtom,
    selectedToolAtom,
    brushesAtom,
    canvasLayersAtom,
    selectedCanvasLayerAtom,
} from '../store/store';
import { atomToObservable } from '../utils/AtomToObservable';
import type { Brush } from '../utils/Brush';
import { generateBrushTexture, loadBrushImage } from '../utils/Brush';
import { CharacterizationType, updateTextureFromLayers } from '../utils/CanvasLayers';
import { paintOnCanvas } from '../utils/DrawOnCanvas';
import type { Subscription, Observable } from 'rxjs';
import { Subject, fromEvent, BehaviorSubject, combineLatest, from } from 'rxjs';
import {
    takeUntil,
    tap,
    map,
    filter,
    switchMap,
    distinctUntilChanged,
    withLatestFrom,
    startWith,
    finalize,
    shareReplay,
} from 'rxjs';
import type { Camera, Intersection, Mesh, Scene, Texture, WebGLRenderer } from 'three';
import { Raycaster, Vector2 } from 'three';

export function MeshPainterControl(scene: Scene, camera: Camera, renderer: WebGLRenderer): () => void {
    const mouseDown = fromEvent<MouseEvent>(renderer.domElement, 'mousedown');
    const mouseUp = fromEvent<MouseEvent>(renderer.domElement, 'mouseup');
    const mouseMove = fromEvent<MouseEvent>(renderer.domElement, 'mousemove');

    const brushLoaded = new BehaviorSubject<boolean>(false); // Initially false
    const selectedTool = atomToObservable(selectedToolAtom);
    const selectedCanvasLayer = atomToObservable(selectedCanvasLayerAtom);

    const destroy = new Subject<void>();
    const enabled = combineLatest([selectedTool, selectedCanvasLayer]).pipe(
        takeUntil(destroy),
        map(
            ([selectedTool, canvasLayer]) =>
                selectedTool === ToolNames.Paint && canvasLayer?.characterizationType === CharacterizationType.Shade,
        ),
        distinctUntilChanged(),
        startWith(false),
    );
    const raycaster = new Raycaster();
    const paintMesh = atomToObservable(toothRefAtom);
    const textureRef = atomToObservable(textureRefAtom); // the map being modified
    const selectedCanvas = selectedCanvasLayer.pipe(map(layer => layer?.canvas));
    const canvasLayers = atomToObservable(canvasLayersAtom);

    const intersected = mouseMove.pipe(
        takeUntil(destroy),
        withLatestFrom(paintMesh),
        filter(([, paintMesh]) => paintMesh !== null),
        map(([event, paintMesh]) => {
            const rect = renderer.domElement.getBoundingClientRect();
            const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
            const mouseVector = new Vector2(x, y); // move to the scope above
            raycaster.setFromCamera(mouseVector, camera);
            const intersections = raycaster.intersectObject(paintMesh as Mesh);
            return { intersection: intersections[0], event };
        }),
        shareReplay({ bufferSize: 1, refCount: true }), // Share the latest intersection
    );

    const brushIndex = atomToObservable(selectedBrushIndexAtom).pipe(takeUntil(destroy), distinctUntilChanged());

    const brushes = atomToObservable(brushesAtom).pipe(takeUntil(destroy), distinctUntilChanged());

    const brush = combineLatest([brushIndex, brushes]).pipe(
        map(([index, brushes]) => brushes[index]),
        filter(brush => brush !== undefined),
    );

    const updateInterval = 33; // Target ~30 updates/second

    const onPaint: Observable<[Intersection, Texture]> = mouseDown.pipe(
        withLatestFrom(intersected, brush),
        filter(([, intersection]) => {
            return store.get(toothRefAtom) !== null && !!intersection.intersection;
        }),
        tap(() => store.set(cameraControlsDisabledAtom, true)),
        switchMap(() => {
            return intersected.pipe(
                takeUntil(mouseUp),
                withLatestFrom(textureRef),
                filter(([intersection, texture]) => texture !== null && !!intersection.intersection),
                map(([intersection, texture]) => [intersection.intersection, texture] as [Intersection, Texture]),
                finalize(() => {
                    store.set(cameraControlsDisabledAtom, false);
                }),
            );
        }),
    );

    let lastUpdateTime = 0;

    const currentBrush = brush.pipe(
        switchMap(async brush => {
            if (!brush.brushImage) {
                brush.brushImage = await loadBrushImage(brush);
            }
            brush.texture = generateBrushTexture(brush.brushImage, brush);
            return brush;
        }),
        shareReplay(1),
    );

    const loadAllBrushes = (brushes: Brush[]) => {
        return from(
            Promise.all(
                brushes.map(brush => {
                    if (!brush.brushImage) {
                        return loadBrushImage(brush).then(image => {
                            brush.brushImage = image;
                            return brush;
                        });
                    }
                    return Promise.resolve(brush);
                }),
            ),
        );
    };

    let interSectionSubscription: Subscription | undefined;
    let localBrushSubscription: Subscription | undefined;
    let paintingSubscription: Subscription | undefined;
    let allBrushesLoadedSubscription: Subscription | undefined;

    enabled.subscribe((enabled: boolean) => {
        if (enabled) {
            interSectionSubscription = intersected
                .pipe(
                    map(e => e.intersection),
                    distinctUntilChanged(),
                )
                .subscribe(intersected => {
                    if (intersected) {
                        renderer.domElement.style.cursor = 'crosshair';
                    } else {
                        renderer.domElement.style.cursor = 'auto';
                    }
                });

            allBrushesLoadedSubscription = brushes
                .pipe(
                    withLatestFrom(brushLoaded),
                    filter(([brushes, loaded]) => brushes.length > 0 && !loaded),
                    map(([brushes]) => brushes),
                    switchMap(brushes => loadAllBrushes(brushes)),
                    tap({
                        next: () => brushLoaded.next(true),
                        error: error => {
                            console.error('Failed to load brush images:', error);
                            brushLoaded.next(false);
                        },
                    }),
                )
                .subscribe();

            paintingSubscription = onPaint
                .pipe(withLatestFrom(currentBrush, selectedCanvas, canvasLayers))
                .subscribe(([[intersection, texture], brush, canvas, canvasLayers]) => {
                    if (canvas) {
                        paintOnCanvas(intersection, canvas, brush);
                    }
                    // paintOnTexture(intersection, texture, brush);

                    //buffer the texture and trigger updates
                    const now = performance.now();

                    if (now - lastUpdateTime >= updateInterval) {
                        updateTextureFromLayers(canvasLayers, texture);
                        renderer.render(scene, camera);
                        lastUpdateTime = now;
                    }
                });
        } else {
            interSectionSubscription?.unsubscribe();
            localBrushSubscription?.unsubscribe();
            paintingSubscription?.unsubscribe();
            allBrushesLoadedSubscription?.unsubscribe(); //Unsubscribe
        }
    });

    function dispose() {
        destroy.next();
        destroy.complete();
    }

    return dispose;
}
