import { sceneRefAtom } from '../../store/store';
import { handleGLBExport } from '../../utils/ExportHandler';
import { usePainterSubmissionMutation } from './Submission.hooks';
import { StackX, StackY, styled, DownloadIcon } from '@orthly/ui';
import { FlossPalette, Button, IconButton } from '@orthly/ui-primitives';
import { useAtomValue } from 'jotai';
import React from 'react';

const FooterRoot = styled(StackY)({
    padding: 12,
    backgroundColor: FlossPalette.STROKE_LIGHT,
    justifyContent: 'space-between',
    height: '8vh',
    flex: 'none',
});

const StyledButton = styled(Button)({
    height: 40,
    padding: 8,
});

const ButtonsBox = styled(StackX)({
    padding: `0px 16px`,
    justifyContent: 'space-between',
    alignItems: 'center',
});

type Props = {
    closeWindow: () => void;
};

export const SidebarFooter = ({ closeWindow }: Props) => {
    function onSubmit() {
        closeWindow();
    }
    const { submit, submitting } = usePainterSubmissionMutation(onSubmit);
    const sceneRef = useAtomValue(sceneRefAtom);

    const handleDownload = async () => {
        if (sceneRef) {
            try {
                await handleGLBExport(sceneRef, true);
            } catch (e) {
                console.error('Error exporting GLB', e);
            }
        }
    };

    return (
        <FooterRoot>
            <ButtonsBox>
                <IconButton disabled={!sceneRef || submitting} onClick={handleDownload}>
                    <DownloadIcon />
                </IconButton>
                <StyledButton disabled={!sceneRef || submitting} variant={'primary'} onClick={submit}>
                    Submit
                </StyledButton>
                <StyledButton variant={'ghost'} onClick={closeWindow}>
                    Cancel
                </StyledButton>
            </ButtonsBox>
        </FooterRoot>
    );
};
