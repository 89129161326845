import { RootActionDialog, useQueryParam } from '@orthly/ui';
import { NotificationLogsTable } from '@orthly/veneer';
import React from 'react';

const useOpenDialog = (doctorPreferencesId: string) => {
    const [queryParam, setQueryParam] = useQueryParam('logs');

    const isOpen = queryParam === doctorPreferencesId;
    const setOpen = (open: boolean) => setQueryParam(open ? doctorPreferencesId : undefined);

    return [isOpen, setOpen] as const;
};

export const NotificationLogsDialog: React.VFC<{
    doctorPreferencesId: string;
    doctorPreferencesName: string;
    timezone: string | null;
}> = ({ doctorPreferencesId, doctorPreferencesName, timezone }) => {
    const [open, setOpen] = useOpenDialog(doctorPreferencesId);

    return (
        <RootActionDialog
            loading={false}
            title={`${doctorPreferencesName} Notification Logs`}
            buttonText={'Notification Logs'}
            setOpen={setOpen}
            open={open}
            content={<NotificationLogsTable doctorPreferencesId={doctorPreferencesId} timezone={timezone} />}
            fullScreen
            showCloseButton
            buttonProps={{
                analytics: {
                    AssetLocation: 'Admin - Notification Logs',
                    AssetName: 'Open Dialog',
                },
            }}
        />
    );
};
