import { initCanvas, initDiffuseCanvas } from './PainterTextures';
import type { Blending, Texture } from 'three';
import { NormalBlending } from 'three';
import { v4 as uuidv4 } from 'uuid';

export enum CharacterizationType {
    Shade = 'Shade',
    Occlusal = 'Occlusal',
}

export interface CanvasLayer {
    label?: string;
    id: string;
    canvas: HTMLCanvasElement;
    blendingType: Blending;
    sort: number;
    visible: boolean;
    characterizationType: CharacterizationType;
}

export interface CanvasLayers {
    [key: string]: CanvasLayer;
}

export function createCanvasLayer(canvasLayers: CanvasLayers): CanvasLayer {
    const newCanvas = initCanvas();

    const newLayer: CanvasLayer = {
        id: uuidv4(),
        canvas: newCanvas,
        blendingType: NormalBlending,
        sort: Object.keys(canvasLayers).length,
        visible: true,
        characterizationType: CharacterizationType.Shade,
    };
    return newLayer;
}

export function updateTextureFromLayers(canvasLayers: CanvasLayers, selectedTexture: Texture): void {
    if (!selectedTexture) {
        return;
    }
    const resultCanvas = selectedTexture.source.data;
    const canvasWidth = resultCanvas.width;
    const canvasHeight = resultCanvas.height;
    const resultContext = resultCanvas.getContext('2d');

    resultContext.clearRect(0, 0, canvasWidth, canvasHeight);
    initDiffuseCanvas(resultContext);

    const sortedLayers = Object.values(canvasLayers).sort((a, b) => a.sort - b.sort);

    sortedLayers.forEach(layer => {
        if (!layer.visible) {
            return;
        }

        const layerCanvas = layer.canvas;

        resultContext.drawImage(layerCanvas, 0, 0, canvasWidth, canvasHeight);
    });

    selectedTexture.needsUpdate = true;
}
