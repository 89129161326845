/* eslint-disable no-nested-ternary */
import { useTicketFilterDropdownData } from '../../utils/useTicketActionHooks';
import { NoTickets } from './components/NoTickets';
import { TicketFiltersInput } from './components/TicketFiltersInput';
import { TicketSavedSearches } from './components/TicketSavedSearches';
import { TicketTable } from './components/TicketTable';
import { TicketTablePagination } from './components/TicketTablePagination';
import { useUrlTicketFilterParams } from './components/hooks/useUrlTicketFilterParams';
import { useFilteredTicketsQuery } from '@orthly/graphql-react';
import { LoadBlocker, styled, useDebouncedValue } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const PageWrapper = styled('div')({
    display: 'flex',
    width: '100%',
});

const ContentWrapper = styled('div')({
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
});

export const TicketsRoot: React.FC = () => {
    const { filters, pagination, setFiltersAndPagination, error } = useUrlTicketFilterParams();
    const dropdownData = useTicketFilterDropdownData();

    const debouncedFilters = useDebouncedValue(filters, 500);
    const filteredTickets = useFilteredTicketsQuery({
        variables: { input: { pagination, filters: _.omit(debouncedFilters, ['page', 'per_page']) } },
    });

    if (error) {
        return <div>Params Error: {JSON.stringify(error)}</div>;
    }

    const errors = _.compact([filteredTickets, dropdownData].map(x => x.error));
    const isLoading = filteredTickets.loading || dropdownData.loading;

    if (errors.length > 0) {
        return (
            <div>
                {errors.map((error, i) => (
                    <Text key={i} color={'ATTENTION'}>
                        Error: {error.message}
                    </Text>
                ))}
            </div>
        );
    }

    return (
        <PageWrapper>
            <TicketSavedSearches
                isLoading={isLoading}
                refetch={async () => {
                    void filteredTickets.refetch();
                }}
                activeSearch={filters}
                setSearch={filters => setFiltersAndPagination({ ...pagination, ...filters, page: 1 })}
            />
            <ContentWrapper>
                <TicketFiltersInput
                    choiceData={dropdownData.data}
                    filters={filters}
                    setFilters={filters => setFiltersAndPagination({ ...pagination, ...filters, page: 1 })}
                />
                {isLoading || !filteredTickets.data || !dropdownData.data ? (
                    <LoadBlocker loader={'dandy'} blocking={true} ContainerProps={{ style: { height: '100%' } }} />
                ) : filteredTickets.data.filteredTickets.length === 0 ? (
                    <NoTickets />
                ) : (
                    <TicketTable tickets={filteredTickets.data.filteredTickets} choiceData={dropdownData.data} />
                )}
                <TicketTablePagination
                    page={pagination.page}
                    setPage={page => setFiltersAndPagination({ ...filters, ...pagination, page })}
                />
            </ContentWrapper>
        </PageWrapper>
    );
};
