import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

const PresetTabSidebarOrderHasVideoDdpRequest_Query = graphql(`
    query PresetTabSidebarOrderHasVideoDdpRequest_Query($order_id: String!) {
        getFulfilledVideoDdpReviewRequest(order_id: $order_id) {
            id
            video_url
        }
    }
`);

export const useVideoWalkthroughUrl = (orderId: string | undefined) => {
    const { data, loading } = useQuery(PresetTabSidebarOrderHasVideoDdpRequest_Query, {
        variables: { order_id: orderId ?? '' },
        skip: !orderId,
    });

    const hasFulfilledVideoDdpRequest = !!data?.getFulfilledVideoDdpReviewRequest?.id;
    const videoUrl = data?.getFulfilledVideoDdpReviewRequest?.video_url ?? '';

    if (!hasFulfilledVideoDdpRequest) {
        return { loading, url: undefined };
    }

    return { loading, url: videoUrl };
};
