import { trackGuidedWaxupButtonClick } from '../../DesignViewerLiteDetachedContainer';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { ToggleButton } from './ToggleButton/ToggleButton';
import { useToggleMarginLines } from './useToggleMarginLines.hook';
import React from 'react';

export const useMarginToggleButton = (variant: 'doctor' | 'dandy') => {
    const { primaryAppearance, setBothAppearances } = useGuidedWaxupContext();
    const onToggleMarginLines = useToggleMarginLines(setBothAppearances, variant);

    const onClick = React.useCallback(() => {
        trackGuidedWaxupButtonClick(`Toggle ${variant === 'doctor' ? 'Doctor ' : ''}Margin Lines`);
        onToggleMarginLines();
    }, [onToggleMarginLines, variant]);

    return {
        checked: variant === 'dandy' ? primaryAppearance.showMarginLines : primaryAppearance.showDoctorMarginLines,
        onClick,
    };
};

export const DoctorMarginToggleButton: React.VFC = () => {
    const { checked, onClick } = useMarginToggleButton('doctor');

    return <ToggleButton checked={checked} icon={'ModelDoctorMarginIcon'} onClick={onClick} label={'Your Margin'} />;
};

export const MarginToggleButton: React.VFC = () => {
    const { checked, onClick } = useMarginToggleButton('dandy');

    return <ToggleButton checked={checked} icon={'ModelMarginIcon'} onClick={onClick} label={'Margin'} />;
};
