import { Button, FlossPalette, Icon, styled } from '@orthly/ui-primitives';
import React from 'react';

const CloseButtonMobileWrapper = styled(Button)({
    background: FlossPalette.TAN,
    height: '48px',
    width: '48px',
    minWidth: '48px',
});
interface CloseButtonMobileProps {
    onClose?: () => void;
}
export const CloseButtonMobile: React.VFC<CloseButtonMobileProps> = ({ onClose }) => {
    return (
        <CloseButtonMobileWrapper variant={'secondary'} onClick={onClose}>
            <Icon icon={'XIcon'} />
        </CloseButtonMobileWrapper>
    );
};
