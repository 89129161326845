import { store, textureCanvasAtom } from '../store/store';
import type { Brush } from './Brush';
import type { Intersection, Texture } from 'three';

function getDrawLocation(intersection: Intersection): { x: number; y: number } | null {
    if (!intersection.uv) {
        return null;
    }
    const canvas = store.get(textureCanvasAtom);
    if (!(canvas instanceof HTMLCanvasElement)) {
        return null;
    }
    const x = intersection.uv.x * canvas.width;
    const y = (1 - intersection.uv.y) * canvas.height;
    return { x, y };
}

export function paintOnTexture(intersection: Intersection, texture: Texture, brush: Brush) {
    const canvas = texture.source.data;
    paintOnCanvas(intersection, canvas, brush);
}

export function paintOnCanvas(intersection: Intersection, canvas: HTMLCanvasElement, brush: Brush) {
    const context = canvas.getContext('2d');
    if (!context || !brush) {
        return;
    }
    const drawPos = getDrawLocation(intersection);

    if (drawPos && brush.texture) {
        const drawX = drawPos.x - brush.size / 2;
        const drawY = drawPos.y - brush.size / 2;
        context.drawImage(brush.texture, drawX, drawY, brush.size, brush.size);
    }
}
