import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const LDR_SURVEY_VERSION = 'V2';
export const LDR_SURVEY_COUNT_LOCAL_STORAGE_KEY = `ldrSurvey${LDR_SURVEY_VERSION}Count`;

const showLdrSurveyAtom = atom(false);
const surveyCountAtom = atomWithStorage(LDR_SURVEY_COUNT_LOCAL_STORAGE_KEY, 0);
const MAX_COUNT = 3;

const showLdrSurveyWithLimitAtom = atom(
    get => {
        const showLdrSurvey = get(showLdrSurveyAtom);
        const surveyCount = get(surveyCountAtom);

        return showLdrSurvey && surveyCount <= MAX_COUNT;
    },
    (get, set, newValue: boolean) => {
        set(showLdrSurveyAtom, newValue);

        const surveyCount = get(surveyCountAtom);
        if (newValue && surveyCount <= MAX_COUNT) {
            set(surveyCountAtom, surveyCount + 1);
        }
    },
);

export const useShowLdrSurvey = () => {
    const { value: enableLdrSurvey = false } = useFeatureFlag('enableLdrSurvey');
    const [showLdrSurvey, setShowLdrSurvey] = useAtom(showLdrSurveyWithLimitAtom);

    if (enableLdrSurvey) {
        return [showLdrSurvey, setShowLdrSurvey] as const;
    }

    return [false, (_value: boolean) => {}] as const;
};
