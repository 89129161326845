import type { LabsGqlDesignOrderDesignRevisionAssetInput } from '../../../../shared-libs/graphql-schema/dist';
import type { DesignModels } from '../store/designsStore';
import { store } from '../store/store';
import { PainterRoot } from './PainterRoot';
import type { PaintingCallbacks } from './PainterRoot.types';
import type { TypedDocumentNode } from '@apollo/client';
import type { FragmentType, VeneerDesignFinishingDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { DesignPrepBannerCommon, useFetchDesignData, type OrderDetailImage } from '@orthly/veneer';
import { Provider } from 'jotai';
import React from 'react';

export const PainterPaintingStartBannerSalesOrder_Fragment = graphql(`
    fragment PainterPaintingStartBannerSalesOrder_Fragment on SalesOrderDTO {
        ...PainterRightSidebarSalesOrder_Fragment
        ...PainterLeftSidebarSalesOrder_Fragment
    }
`);

export const AttachDesignAssetMutation: TypedDocumentNode<
    { attachDesignAsset: boolean },
    { designAsset: LabsGqlDesignOrderDesignRevisionAssetInput; orderId: string }
> = graphql(`
    mutation AttachDesignAsset($designAsset: DesignOrderDesignRevisionAssetInput!, $orderId: String!) {
        attachDesignAsset(designAsset: $designAsset, orderId: $orderId)
    }
`);

interface StartBannerProps {
    selectedDesign: FragmentType<typeof VeneerDesignFinishingDesign_FragmentFragmentDoc>;
    disabled?: boolean;
    initiallyOpen?: boolean;
    callbacks: Omit<PaintingCallbacks, 'setWindowOpen'>;
    salesOrder: FragmentType<typeof PainterPaintingStartBannerSalesOrder_Fragment>;
    imageSources: OrderDetailImage[];
    orderId: string;
    order: Pick<
        LabsGqlLabOrderFragment,
        | 'id'
        | 'order_number'
        | 'items_v2'
        | 'design_due_date'
        | 'patient'
        | 'fulfillment_workflow'
        | 'clinical_support_notes'
        | 'doctor_notes'
        | 'scan_export_id'
        | 'lab_slip_alerts'
        | 'notes_for_refabrication'
        | 'doctor_preferences_id'
    >;
}

export const PaintingStartBanner: React.VFC<StartBannerProps> = props => {
    const { salesOrder: salesOrderProp, disabled, initiallyOpen, selectedDesign, imageSources, order } = props;
    const salesOrder = getFragmentData(PainterPaintingStartBannerSalesOrder_Fragment, salesOrderProp);
    const { models, design } = useFetchDesignData(selectedDesign, order);

    const [open, setOpen] = React.useState<boolean>(!!(initiallyOpen && !disabled));

    const onClick = () => {
        setOpen(true);
    };

    const designReady = models && models.restorativeModels.length > 0 && salesOrder;

    return (
        <>
            <DesignPrepBannerCommon
                leftLabel={'Dandy Painting'}
                rightLabel={'Start'}
                variant={'primary'}
                onClick={onClick}
                disabled={disabled && !designReady}
            />
            {designReady && open && (
                <Provider store={store}>
                    <PainterRoot
                        // eslint-disable-next-line
                        models={models as any as DesignModels}
                        designId={design.id}
                        salesOrder={salesOrder}
                        open={open}
                        setOpen={setOpen}
                        imageSources={imageSources}
                    />
                </Provider>
            )}
        </>
    );
};
