import { type FocusArea, focusAreaExistsInList } from '../../../../utils/FocusAreas';
import { CapabilityCardTitle } from '../DesignCapabilities/CapabilityCardTitle';
import { FocusAreaCheckboxes } from './FocusAreaCheckboxes';
import { FocusTaskRadioGroup } from './FocusTaskRadioGroup';
import type { LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment } from '@orthly/graphql-operations';
import {
    useApolloRefetch,
    GetDesignStaffFocusAreasByDesignerIdDocument,
    useGetDesignStaffFocusAreasByDesignerIdQuery,
} from '@orthly/graphql-react';
import { useCreateAndDeleteFocusAreasMutation } from '@orthly/graphql-react';
import type { LabsGqlDesignStaffFocusAreaInput, LabsGqlDesignTaskType } from '@orthly/graphql-schema';
import { LabsGqlOrderDesignCapabilityType } from '@orthly/graphql-schema';
import { useRootActionCommand, LoadBlocker } from '@orthly/ui';
import { FlossPalette, Grid, Button, Container, makeStyles, createStyles } from '@orthly/ui-primitives';
import React from 'react';

const focusEligibleCapabilities = [
    LabsGqlOrderDesignCapabilityType.AnteriorCrownAndBridge,
    LabsGqlOrderDesignCapabilityType.PosteriorCrownAndBridge,
    LabsGqlOrderDesignCapabilityType.Implant,
    LabsGqlOrderDesignCapabilityType.Partials,
    LabsGqlOrderDesignCapabilityType.PartialsInjectionMold,
    LabsGqlOrderDesignCapabilityType.Dentures,
    LabsGqlOrderDesignCapabilityType.DenturesWaxRim,
    LabsGqlOrderDesignCapabilityType.Thermoform,
];

const useStyles = makeStyles(() =>
    createStyles({
        focusCard: {
            backgroundColor: FlossPalette.WARNING_BACKGROUND,
            border: `1px solid ${FlossPalette.DARK_TAN}`,
            borderRadius: 16,
            height: '100%',
        },
        buttonContainer: {
            marginTop: 8,
            marginBottom: 8,
        },
    }),
);

function getFocusTaskTypeFromFocusAreas(focusAreas: FocusArea[]): LabsGqlDesignTaskType | null {
    return focusAreas[0]?.task_type ?? null;
}

export const EditUserFocusAreasCard: React.FC<{
    designerId: string;
    capabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment;
}> = props => {
    const { capabilities, designerId } = props;

    const { data, loading: isFetching } = useGetDesignStaffFocusAreasByDesignerIdQuery({
        variables: {
            designer_id: designerId,
        },
    });
    const classes = useStyles();
    const refetch = useApolloRefetch();

    const { submit } = useRootActionCommand(useCreateAndDeleteFocusAreasMutation(), {
        successMessage: 'Focus Areas Updated!',
        onSuccess: () => {
            refetch.query(GetDesignStaffFocusAreasByDesignerIdDocument);
        },
    });

    const [statefulFocusAreas, setFocusAreas] = React.useState<FocusArea[]>([]);
    const [focusTask, setFocusTask] = React.useState<LabsGqlDesignTaskType | null>(null);
    const [changesMade, setChangesMade] = React.useState<boolean>(false);

    React.useEffect(() => {
        const focusAreas = data?.getDesignStaffFocusAreasByDesigner || [];
        setFocusTask(getFocusTaskTypeFromFocusAreas(focusAreas));
        setFocusAreas(focusAreas);
    }, [data]);

    const handleCheckboxChange = (fa: FocusArea) => {
        const existingFocusArea = focusAreaExistsInList(fa, statefulFocusAreas);
        if (existingFocusArea) {
            const newList = statefulFocusAreas.filter(
                focusArea =>
                    focusArea.capability !== existingFocusArea.capability ||
                    focusArea.level !== existingFocusArea.level ||
                    focusArea.task_type !== existingFocusArea.task_type,
            );
            setFocusAreas(newList);
        } else {
            const newList = [...statefulFocusAreas, fa];
            setFocusAreas(newList);
        }
        if (!changesMade) {
            setChangesMade(true);
        }
    };

    const handleFocusTaskChange = (changed: LabsGqlDesignTaskType | null) => {
        // Clear out focus areas to avoid setting focus areas for more than one task type
        setFocusAreas([]);
        const newValue = !changed ? null : changed;
        setFocusTask(newValue);
        if (!changesMade) {
            setChangesMade(true);
        }
    };

    const saveOnClick = () => {
        void submit({
            user_id: designerId,
            focus_areas: statefulFocusAreas.map(fa => {
                return {
                    design_staff_id: designerId,
                    task_type: fa.task_type,
                    capability: fa.capability,
                    level: fa.level,
                } as LabsGqlDesignStaffFocusAreaInput;
            }),
        });
        setChangesMade(false);
    };

    const cancelOnClick = () => {
        const focusAreas = data?.getDesignStaffFocusAreasByDesigner || [];
        setFocusTask(getFocusTaskTypeFromFocusAreas(focusAreas));
        setFocusAreas(focusAreas);
        setChangesMade(false);
    };

    const canSave = () => {
        const canSave: boolean = changesMade && (statefulFocusAreas.length > 0 || !focusTask);

        return canSave;
    };

    return (
        <Container className={classes.focusCard}>
            <CapabilityCardTitle
                title={'Assign Priorities'}
                editButtonText={'Edit'}
                subtitleText={'Select a task to prioritize:'}
            />
            <LoadBlocker blocking={isFetching}>
                <FocusTaskRadioGroup
                    focusTask={focusTask}
                    handleFocusTaskChange={handleFocusTaskChange}
                    capabilities={capabilities}
                />
                {focusTask !== null && (
                    <Grid>
                        {focusEligibleCapabilities.map(capability => (
                            <FocusAreaCheckboxes
                                key={capability}
                                capability={capability}
                                allCapabilitiesAndLevels={capabilities}
                                statefulFocusAreas={statefulFocusAreas}
                                currentFocusTask={focusTask}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        ))}
                    </Grid>
                )}
                <Grid
                    container
                    item
                    direction={'row'}
                    spacing={1}
                    justifyContent={'space-around'}
                    className={classes.buttonContainer}
                >
                    <Grid item xs>
                        <Button fullWidth variant={'primary'} onClick={saveOnClick} disabled={!canSave()}>
                            Save
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button fullWidth variant={'secondary'} onClick={cancelOnClick} disabled={!changesMade}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </LoadBlocker>
        </Container>
    );
};
