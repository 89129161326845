import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { useSession } from '@orthly/session-client';
import React from 'react';

export enum ComparisonModeEvent {
    toggle = 'Practice - Guided Waxup - Comparison Mode - Toggle',
    available = 'Practice - Guided Waxup - Comparison Mode - Available',
    annotate = 'Practice - Guided Waxup - Comparison Mode - Annotate Clicked',
    revisionsSelectable = 'Practice - Guided Waxup - Comparison Mode - Revisions Selectable',
    selectDesignVersion = 'Practice - Guided Waxup - Comparison Mode - Revision Selected',
    errorOccurred = 'Practice - Guided Waxup - Comparison Mode - Error Occurred',
}

export const useComparisonModeTracking = () => {
    const { isComparisonModeActive, isComparisonModeAvailable, isComparisonSelectorAvailable, revisionId, order } =
        useGuidedWaxupContext();
    const session = useSession();
    const staffId = session?.id;
    const userId = session?.user?.id;

    const trackComparisonMode = React.useCallback(
        (str: ComparisonModeEvent) => {
            BrowserAnalyticsClientFactory.Instance?.track(str, {
                $groups: {
                    order: order?.id,
                    userId,
                    staffId,
                },
                revisionId: revisionId,
                open: isComparisonModeActive,
            });
        },
        [isComparisonModeActive, order?.id, revisionId, userId, staffId],
    );

    React.useEffect(() => {
        if (isComparisonModeAvailable) {
            trackComparisonMode(ComparisonModeEvent.available);
        }
    }, [isComparisonModeAvailable, trackComparisonMode]);

    React.useEffect(() => {
        if (isComparisonSelectorAvailable) {
            trackComparisonMode(ComparisonModeEvent.revisionsSelectable);
        }
    }, [isComparisonSelectorAvailable, trackComparisonMode]);

    return trackComparisonMode;
};
