import type { Color } from './ShadeColorPicker';

export const MIYO_COLORS: ReadonlyArray<Color> = [
    { name: 'Shade A', value: '#F5DCC4' },
    { name: 'Shade B', value: '#F5E4C5' },
    { name: 'Shade C', value: '#E4CAAA' },
    { name: 'Shade D', value: '#D7B991' },
    { name: 'Lumin', value: '#F8F7F3' },
    { name: 'Straw', value: '#EACF78' },
    { name: 'Lotus', value: '#E3903F' },
    { name: 'SunFlower', value: '#FAD045' },
    { name: 'Clementine', value: '#B36517' },
    { name: 'Smoke', value: '#555464' },
    { name: 'Storm', value: '#1F5998' },
    { name: 'Lumin Plus', value: '#FDFDFD' },
    { name: 'Cobalt', value: '#224471' },
    { name: 'Sage', value: '#C8A96B' },
    { name: 'Slate', value: '#494649' },
];
