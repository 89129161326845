import MeshPainterMaterial from './PainterMaterial';
import type { BufferGeometry } from 'three';
import { Mesh, BufferAttribute } from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';

export const DEFAULT_CLEARCOAT = 0.2;

export const prepStl = (geometry: BufferGeometry): Mesh => {
    geometry.deleteAttribute('normal');
    const mergedGeometry = BufferGeometryUtils.mergeVertices(geometry);
    mergedGeometry.computeVertexNormals(); // Compute vertex normals for smooth shading
    const material = new MeshPainterMaterial({
        vertexColors: true,
        clearcoat: 0.2,
        clearcoatRoughness: 0.2,
        roughness: 0.7,
        metalness: 0.1,
    });

    // Initialize color attribute with white for each vertex
    const positionAttribute = mergedGeometry.attributes.position;
    if (!positionAttribute) {
        throw new Error('Position attribute is missing in the geometry.');
    }
    const vertexCount = positionAttribute.count;
    const colors = new Float32Array(vertexCount * 3);
    for (let i = 0; i < vertexCount; i++) {
        colors[i * 3] = 1.0; // Red
        colors[i * 3 + 1] = 1.0; // Green
        colors[i * 3 + 2] = 1.0; // Blue
    }
    mergedGeometry.setAttribute('color', new BufferAttribute(colors, 3));

    const mesh = new Mesh(mergedGeometry, material);

    mesh.position.set(0, 0, 0);
    mesh.rotation.set(0, 0, 0);
    // mesh.rotation.x = - Math.PI / 2;

    return mesh;
};
