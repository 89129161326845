import { RuleConditionPreview } from '../../../components/RuleTable/RuleConditionPreview';
import {
    tryParseJson,
    labOrderItemConditionIsValid,
    labOrderConditionIsValid,
    filterOrderItemConditionsBySearchTerms,
    filterOrderConditionsBySearchTerms,
} from '../utils';
import { LabOrderConditionField } from './LabOrderConditionEditor';
import { LabOrderItemConditionField } from './LabOrderItemConditionEditor';
import type {
    LabsGqlCapacityRuleFragment,
    LabsGqlDesignRuleFragment,
    LabsGqlOrganizationDtoFragment,
} from '@orthly/graphql-operations';
import { LabsGqlRoutingMetricsRange } from '@orthly/graphql-schema';
import type { Column } from '@orthly/mui-table';
import { DayOfWeek } from '@orthly/shared-types';
import type { CustomQFComponentProps, FieldsDefProp } from '@orthly/ui';
import { Text, FlossPalette, Tooltip, InfoIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import { z } from 'zod';

export type DesignRuleFormFields = Pick<
    LabsGqlDesignRuleFragment,
    'name' | 'item_condition_json' | 'order_condition_json' | 'limit_range' | 'unit_limit' | 'order_limit'
> & { condition_type: 'unit' | 'order' | null };

type ConditionType = 'unit' | 'order' | null;

export type CapacityRuleFormFields = Pick<
    LabsGqlCapacityRuleFragment,
    | 'name'
    | 'order_condition_json'
    | 'item_condition_json'
    | 'limit_range'
    | 'unit_limit'
    | 'order_limit'
    | 'manufacturer_id'
    | 'priority'
    | 'supports_design'
    | 'supports_manufacturing'
    | 'active_sla_days'
> & { condition_type: ConditionType };

type GenericOrderItemRuleFields = CapacityRuleFormFields | DesignRuleFormFields;

export class RoutingRuleUtils {
    static getCapacityListColumns(): Column<LabsGqlCapacityRuleFragment & { manufacturer_name?: string }>[] {
        return [
            {
                name: 'ID',
                render: 'id',
                type: 'string',
                hidden: true,
                defaultSort: 'asc',
            },
            {
                name: 'Priority',
                render: row => <>{row.priority === -1 ? 'Global' : row.priority}</>,
                type: 'numeric',
            },
            {
                name: 'Name',
                field: 'name',
                render: ({ name }) => (
                    <Tooltip title={name}>
                        <div>{name.length > 100 ? `${name.slice(0, 75)}...` : name}</div>
                    </Tooltip>
                ),
            },
            {
                name: 'Lab',
                render: 'manufacturer_name',
                type: 'string',
            },
            {
                name: 'Applies To',
                render: row => (
                    <div>
                        <div>{row.supports_design ? '✅' : '🚫'} Design</div>
                        <div>{row.supports_manufacturing ? '✅' : '🚫'} Manufacturing</div>
                    </div>
                ),
                filter: false,
                sort: false,
            },
            {
                name: 'Item Condition',
                field: 'item_condition_json',
                render: row => <RuleConditionPreview ruleType={'item'} rawJSON={row.item_condition_json} />,
                filter: true,
                customFilterFn: filterOrderItemConditionsBySearchTerms,
                sort: false,
            },
            {
                name: 'Unit Limit',
                render: 'unit_limit',
                type: 'numeric',
            },
            {
                name: 'Order Condition',
                field: 'order_condition_json',
                render: row => <RuleConditionPreview ruleType={'order'} rawJSON={row.order_condition_json} />,
                filter: true,
                customFilterFn: filterOrderConditionsBySearchTerms,
                sort: false,
            },
            {
                name: 'Order Limit',
                render: 'order_limit',
                type: 'numeric',
            },
            {
                name: 'Active SLA Days',
                field: 'active_sla_days',
                render: row => (
                    <div>
                        {row.active_sla_days.length === 7
                            ? 'All'
                            : row.active_sla_days.map(d => d.slice(0, 2)).join(', ')}
                    </div>
                ),
                filter: false,
                sort: false,
            },
        ];
    }

    static getDesignListColumns(): Column<LabsGqlDesignRuleFragment & { manufacturer_name?: string }>[] {
        return [
            {
                name: 'ID',
                render: 'id',
                type: 'string',
                hidden: true,
                defaultSort: 'asc',
            },
            {
                name: 'Name',
                field: 'name',
                render: ({ name }) => (
                    <Tooltip title={name}>
                        <div>{name.length > 100 ? `${name.slice(0, 75)}...` : name}</div>
                    </Tooltip>
                ),
            },
            {
                name: 'Item Condition',
                field: 'item_condition_json',
                render: row => <RuleConditionPreview ruleType={'item'} rawJSON={row.item_condition_json} />,
                filter: true,
                customFilterFn: filterOrderItemConditionsBySearchTerms,
                sort: false,
            },
            {
                name: 'Unit Limit',
                field: 'unit_limit',
                render: row => (
                    <>
                        {row.unit_limit}
                        {row.unit_limit !== null && row.unit_limit >= 1000 /* DESIGN_RULE_UNIT_LIMIT_CUTOFF */ ? (
                            <Tooltip title={'Unit limit values >= 1000 are not explicitly validated during routing'}>
                                <InfoIcon style={{ fontSize: 16, marginLeft: 4 }} />
                            </Tooltip>
                        ) : null}
                    </>
                ),
                type: 'numeric',
            },
            {
                name: 'Order Condition',
                field: 'order_condition_json',
                render: row => <RuleConditionPreview ruleType={'order'} rawJSON={row.order_condition_json} />,
                filter: true,
                customFilterFn: filterOrderConditionsBySearchTerms,
                sort: false,
            },
            {
                name: 'Order Limit',
                render: 'order_limit',
                type: 'numeric',
            },
        ];
    }

    static FormFields(
        conditionType: ConditionType,
        orderCondition: string,
        itemCondition: string,
    ): FieldsDefProp<GenericOrderItemRuleFields> {
        return {
            name: {
                label: 'Name',
                type: 'text',
            },
            condition_type: {
                label: 'Condition Type',
                type: 'select',
                options: [
                    { value: 'order', label: 'Order' },
                    { value: 'unit', label: 'Unit' },
                ],
            },
            order_condition_json: {
                type: 'custom',
                label: 'Order Condition',
                component: (formProps: CustomQFComponentProps) => (
                    <LabOrderConditionField {...formProps} omitMinRefabCountOptions={true} />
                ),
                validation: RoutingRuleUtils.OrderConditionValidation,
                hidden: conditionType !== 'order',
            },
            item_condition_json: {
                type: 'custom',
                label: 'Condition',
                component: LabOrderItemConditionField,
                validation: RoutingRuleUtils.ItemConditionValidation,
                hidden: conditionType !== 'unit',
            },
            order_limit: {
                type: `number`,
                label: `Order limit`,
                optional: orderCondition === `null` || conditionType !== 'order',
                hidden: conditionType !== 'order',
            },
            unit_limit: {
                type: `number`,
                label: `Unit Limit`,
                optional: itemCondition === `null` || conditionType !== 'unit',
                hidden: conditionType !== 'unit',
            },
            limit_range: {
                type: `select`,
                label: `Time span`,
                options: Object.values(LabsGqlRoutingMetricsRange),
                hidden: true,
            },
        };
    }

    static FormFieldsCapacity(
        conditionType: ConditionType,
        orderCondition: string,
        itemCondition: string,
        labs: LabsGqlOrganizationDtoFragment[],
    ): FieldsDefProp<CapacityRuleFormFields> {
        return {
            name: {
                label: 'Name',
                type: 'text',
            },
            condition_type: {
                label: 'Condition Type',
                type: 'select',
                options: [
                    { value: 'order', label: 'Order' },
                    { value: 'unit', label: 'Unit' },
                ],
            },
            order_condition_json: {
                type: 'custom',
                label: 'Order Condition',
                component: (formProps: CustomQFComponentProps) => (
                    <LabOrderConditionField {...formProps} omitMinRefabCountOptions={true} />
                ),
                validation: RoutingRuleUtils.OrderConditionValidation,
                hidden: conditionType !== 'order',
            },
            item_condition_json: {
                type: 'custom',
                label: 'Condition',
                component: LabOrderItemConditionField,
                validation: RoutingRuleUtils.ItemConditionValidation,
                hidden: conditionType !== 'unit',
            },
            order_limit: {
                type: `number`,
                label: `Order limit`,
                optional: orderCondition === `null` || conditionType !== 'order',
                hidden: conditionType !== 'order',
            },
            unit_limit: {
                type: `number`,
                label: `Unit Limit`,
                optional: itemCondition === `null` || conditionType !== 'unit',
                hidden: conditionType !== 'unit',
            },
            limit_range: {
                type: `select`,
                label: `Time span`,
                options: Object.values(LabsGqlRoutingMetricsRange),
                hidden: true,
            },
            priority: {
                type: `number`,
                label: 'Priority Group',
                validation: z
                    .number()
                    .int()
                    .refine(value => value === -1 || value >= 1, {
                        message: 'Use -1 for global rule, otherwise assign to priority group with min value of 1',
                    }),
            },
            supports_design: {
                type: 'boolean',
                label: 'Supports Design',
            },
            supports_manufacturing: {
                type: 'boolean',
                label: 'Supports Manufacturing',
                validation: z
                    .boolean()
                    .refine(value => value === true, { message: 'All capacity rules must support manufacturing' }),
            },
            manufacturer_id: {
                label: 'Manufacturer',
                type: 'select',
                options: _.sortBy(labs, m => m.name.trim().toLowerCase()).map(({ id, name }) => ({
                    value: id,
                    label: name,
                })),
            },
            active_sla_days: {
                label: 'Active SLA Days',
                validation: z.array(z.string()).min(1),
                type: 'multiselect',
                options: Object.values(DayOfWeek),
                disableSortOptions: true,
            },
        };
    }

    static commonColumns = [
        {
            title: 'ID',
            name: 'id',
            field: 'id',
            render: 'id',
            type: 'string',
            hidden: true,
            defaultSort: 'asc',
        },
        {
            title: 'Name',
            name: 'name',
            field: 'name',
            render: 'name',
            type: 'string',
        },
        {
            name: 'Condition',
            field: 'condition_json',
            render: (row: { condition_json: string }) =>
                row.condition_json && row.condition_json !== 'null' ? (
                    <RuleConditionPreview ruleType={'order'} rawJSON={row.condition_json} />
                ) : (
                    <Text variant={'caption'}>
                        Matches <b style={{ color: FlossPalette.BURGUNDY }}>ALL</b> items
                    </Text>
                ),
            filter: true,
            customFilterFn: filterOrderConditionsBySearchTerms,
            sort: false,
        },
    ] as const;

    static ItemConditionValidation = z.any().refine(value => {
        const parsed = tryParseJson(value);
        return parsed === null || labOrderItemConditionIsValid(parsed);
    }, 'invalid item condition');

    static OrderConditionValidation = z.any().refine(value => {
        const parsed = tryParseJson(value);
        return parsed === null || labOrderConditionIsValid(parsed);
    }, 'invalid order condition');
}
