import { selectedToolAtom } from '../../store/store';
import HorizontalPanelDivider from '../HorizontalPanelDivider/HorizontalPanelDivider';
import ToolList, { ToolNames } from '../ToolList/ToolList';
import GradientPanel from './GradientTool/GradientPanel';
import PainterPanel from './PainterTool/PainterPanel';
import ShadePanel from './ShadeTool/ShadePanel';
import { styled } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

const ToolPanelRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
});

const Panels = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    height: '100%',
    flexGrow: 1,
});

const Footer = styled('div')({
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
});

const ToolPanel: React.FC = () => {
    const [selectedTool] = useAtom(selectedToolAtom);

    return (
        <ToolPanelRoot>
            <ToolList />
            <HorizontalPanelDivider />
            <Panels>
                {selectedTool === ToolNames.Gradient && <GradientPanel />}
                {selectedTool === ToolNames.Shade && <ShadePanel />}
                {selectedTool === ToolNames.Paint && <PainterPanel />}
            </Panels>
            <Footer />
        </ToolPanelRoot>
    );
};

export default ToolPanel;
