import { DesignReviewOption } from '../GuidedWaxupSelectDesignReviewOptionModal';
import { CloseButtonMobile } from './GuidedWaxupSelectDesignReviewOptionModalCloseButton';
import { Box, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const HeaderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        padding: '12px 16px 0px 16px',
    },
}));

const HeaderText = styled(Text)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        alignContent: 'center',
        paddingRight: '16px',
    },
}));

const getTitle = (currentTab: string): string => {
    switch (currentTab) {
        case DesignReviewOption.REQUEST_LDR:
            return 'Live Design Review';
        case DesignReviewOption.REQUEST_VDR:
            return 'Video Design Review';
        case 'digital design preview':
            return 'Live Design Review';
    }
    return '';
};

interface GuidedWaxupSelectDesignReviewOptionHelpContentProps {
    isMobile?: boolean;
    onClose: () => void;
    currentHelpTab: string;
}

export const GuidedWaxupSelectDesignReviewOptionHelpHeader: React.VFC<
    GuidedWaxupSelectDesignReviewOptionHelpContentProps
> = ({ isMobile, onClose, currentHelpTab }) => {
    return (
        <HeaderWrapper>
            <HeaderText variant={isMobile ? 'h6' : 'h4'} medium>
                {`What's a ${getTitle(currentHelpTab)}?`}
            </HeaderText>
            {isMobile && <CloseButtonMobile onClose={onClose} />}
        </HeaderWrapper>
    );
};
