import { DesignViewerLiteButtonGroups } from '../../DesignViewerLiteButtonGroups';
import { DesignViewerLiteDetachedContainer } from '../../DesignViewerLiteDetachedContainer';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { GeneralReviewVisibilityControlsMobile } from './GeneralReviewVisibilityControlsMobile';
import { DraggableQCToolbar } from '@orthly/dentin';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { Box, Popover, styled, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import React from 'react';
import { isWindows } from 'react-device-detect';

const popoverDismissedAtom = atomWithStorage<boolean>(
    'WaxupModelViewerAndControls-alt-to-pan-popover-dismissed',
    false,
);

export const GeneralViewContainer = styled(Box)({
    width: 'max-content',
    minWidth: '50%',
    maxWidth: '100%',
});

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 20px 0',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
        padding: '8px 8px 0',
        gap: '8px',
    },
}));

const ModelViewerContainer = styled(Box)<{ isComparisonModeActive: boolean; isMobile: boolean }>(({
    isComparisonModeActive,
    isMobile,
}) => {
    const gridTemplate = isComparisonModeActive ? '1fr 1fr' : '1fr';
    return {
        display: 'grid',
        gridTemplateColumns: isMobile ? undefined : gridTemplate,
        gridTemplateRows: isMobile ? gridTemplate : undefined,
        gap: '16px',
        marginRight: isMobile ? 0 : '8%',
        alignItems: 'start',
    };
});

export const WaxupModelViewerAndControls: React.FC = ({ children }) => {
    const isMobileOrTablet = useScreenIsMobileOrVerticalTablet();
    const {
        primaryAppearance,
        setPrimaryAppearance,
        secondaryAppearance,
        setSecondaryAppearance,
        primaryModelRef,
        primaryCameraControlsRef,
        setPrimaryControls,
        secondaryModelRef,
        secondaryCameraControlsRef,
        setSecondaryControls,
        setControlsActive,
        zoom,
        order,
        refetch,
        selectedTab,
        setAnnotation,
        isComparisonModeActive,
    } = useGuidedWaxupContext();
    // TODO [2025-04-04]: Remove this popover tooltip.
    const [popoverDismissed, setPopoverDismissed] = useAtom(popoverDismissedAtom);
    const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLDivElement>();
    const dismissPopover = () => {
        setPopoverAnchor(undefined);
        setPopoverDismissed(true);
    };

    return (
        <Container>
            {selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView && isMobileOrTablet && (
                <GeneralReviewVisibilityControlsMobile />
            )}
            <DesignViewerLiteButtonGroups />
            <ModelViewerContainer
                onMouseEnter={!popoverDismissed ? evt => setPopoverAnchor(evt.currentTarget) : undefined}
                isComparisonModeActive={isComparisonModeActive}
                isMobile={isMobileOrTablet}
            >
                {isComparisonModeActive && (
                    <DesignViewerLiteDetachedContainer
                        order={order}
                        refetchDesign={refetch}
                        appearance={secondaryAppearance}
                        setAppearance={setSecondaryAppearance}
                        modelRef={secondaryModelRef}
                        controlRef={secondaryCameraControlsRef}
                        onControlsMounted={setSecondaryControls}
                        appearanceSource={primaryAppearance}
                        setControlsActive={() => setControlsActive('secondary')}
                        setPresetAnnotation={setAnnotation}
                        camConfig={{ zoom }}
                        style={isMobileOrTablet ? { height: '300px' } : { height: '500px', width: 'auto' }}
                        isPrimaryDisplay={false}
                    >
                        <DraggableQCToolbar
                            dynamicHeatmaps
                            appearance={secondaryAppearance}
                            setAppearance={setSecondaryAppearance}
                            hideZoom
                        />
                    </DesignViewerLiteDetachedContainer>
                )}
                <DesignViewerLiteDetachedContainer
                    order={order}
                    refetchDesign={refetch}
                    appearance={primaryAppearance}
                    setAppearance={setPrimaryAppearance}
                    modelRef={primaryModelRef}
                    controlRef={primaryCameraControlsRef}
                    onControlsMounted={setPrimaryControls}
                    setControlsActive={() => setControlsActive('primary')}
                    setPresetAnnotation={setAnnotation}
                    camConfig={{ zoom }}
                    style={isMobileOrTablet ? { height: '300px' } : { height: '500px', width: 'auto' }}
                    isPrimaryDisplay={true}
                >
                    <DraggableQCToolbar
                        dynamicHeatmaps
                        appearance={primaryAppearance}
                        setAppearance={setPrimaryAppearance}
                        hideZoom
                    />
                </DesignViewerLiteDetachedContainer>
            </ModelViewerContainer>
            <Popover
                anchorEl={popoverAnchor}
                open={!popoverDismissed && !isMobileOrTablet && !!popoverAnchor}
                onClose={() => dismissPopover()}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                slotProps={{
                    paper: {
                        style: {
                            borderRadius: 16,
                            padding: 16,
                            overflow: 'hidden',
                        },
                    },
                }}
            >
                {`Tip: You can now use ${isWindows ? 'Alt' : 'Option'} + Left Click to pan the view!`}
            </Popover>
            {children}
        </Container>
    );
};
