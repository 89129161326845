import { AttachScansUtils } from '../../../utils';
import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { useRefabFlowSelector } from '../state/refab-flow-state';
import { useRefabFlowContext } from './refab-flow-context';
import type { LabsGqlPatientScanFileFragment } from '@orthly/graphql-operations';
import { FlossPalette, Text, styled, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gridGap: '35px',
    [theme.breakpoints.up('sm')]: {
        padding: '48px 16px',
    },
    maxWidth: '600px',
}));
const VerticalLine = styled('div')({
    width: '1px',
    backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
});
const ContentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gridGap: 24,
});
const StepContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gridGap: 8,
});
const StepTitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
});
const StepNumber = styled('div')({
    zIndex: 2,
    borderRadius: '50%',
    border: '4px solid white',
    width: '32px',
    height: '32px',
    backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

interface Step {
    number?: number;
    title: string;
    content?: string;
}

const stepsV1: Step[] = [
    {
        number: 1,
        title: `Click "Start refabrication order" to create a placeholder order.`,
        content: `This order will be on hold until new scans are added.`,
    },
    {
        number: 2,
        title: `Add new scans`,
        content: `Go to your laptop where Chairside is installed to re-scan the patient. Alternatively, if you want to
    use existing scans, navigate to the new order's details page, locate the 'Attach existing scans'
    button, and select the scans from your original order.`,
    },
    {
        number: 3,
        title: `Once the scans are added, we'll handle the rest!`,
    },
];

const stepsV2 = ({
    selectedScan,
    attachmentsLength,
    supplementaryNotes,
    annotationsLength,
    requestWaxup,
}: {
    selectedScan: Pick<LabsGqlPatientScanFileFragment, 'cart_items_v2'> | null;
    attachmentsLength: number;
    supplementaryNotes: string;
    annotationsLength: number;
    requestWaxup: boolean;
}): Step[] => {
    const steps: Step[] = [];

    if (selectedScan) {
        const isDiagnosticScan = AttachScansUtils.isDiagnosticScan(selectedScan);
        steps.push({
            title: `A ${isDiagnosticScan ? 'diagnostic' : 'chairside'} scan has been added`,
            content: 'A new order will be submitted with the new scan.',
        });
    }

    if (attachmentsLength) {
        steps.push({
            title: 'New photos have been added',
            content: `You have added ${attachmentsLength} new photo${attachmentsLength > 1 ? 's' : ''} to this refab order.`,
        });
    }

    if (supplementaryNotes) {
        steps.push({
            title: 'Note(s) have been added to this order',
            content: 'You have added note(s) to this refab order.',
        });
    }

    if (annotationsLength) {
        steps.push({
            title: 'Review feedback was submitted',
            content: 'You have submitted item feedback.',
        });
    }

    if (requestWaxup) {
        steps.push({
            title: 'A Digital Design Preview will be added to this order',
            content: `Digital Design Previews let you review and approve 3D designs of your restorations before fabrication to ensure they meet your standards.`,
        });
    }

    return steps;
};

export const RefabInfoScreen: React.VFC = () => {
    const { enableAttachExistingScans } = useRefabFlowContext();

    const selectedScan = useRefabFlowSelector(s => s.selectedScan);
    const attachments = useRefabFlowSelector(s => s.attachments);
    const supplementaryNotes = useRefabFlowSelector(s => s.supplementaryNotes);
    const annotations = useRefabFlowSelector(s => s.annotations);
    const requestWaxup = useRefabFlowSelector(s => s.requestWaxup);

    const isMobile = useScreenIsMobileOrVerticalTablet();

    const steps = enableAttachExistingScans
        ? stepsV2({
              selectedScan,
              attachmentsLength: attachments.length,
              supplementaryNotes,
              annotationsLength: annotations.length,
              requestWaxup,
          })
        : stepsV1;

    const nextTitle = isMobile ? 'Start Refrabrication' : 'Start refabrication order';

    return (
        <RefabBodyContainer
            title={
                <Text variant={isMobile ? 'h5' : 'h3'}>
                    {enableAttachExistingScans ? "Here's a summary of this updated order" : 'What happens next'}
                </Text>
            }
            nextTitle={nextTitle}
        >
            <Container>
                {!enableAttachExistingScans && <VerticalLine />}
                <ContentContainer>
                    {steps.map(step => (
                        <StepContainer key={step.title}>
                            <StepTitleContainer>
                                {step?.number && (
                                    <StepNumber style={{ position: 'absolute', left: '-51px' }}>
                                        <Text variant={'body2'} medium color={'PRIMARY_FOREGROUND'}>
                                            {step.number}
                                        </Text>
                                    </StepNumber>
                                )}
                                <Text variant={'body2'} medium color={'BLACK'}>
                                    {step.title}
                                </Text>
                            </StepTitleContainer>
                            {step?.content && (
                                <Text variant={'body2'} color={'GRAY'}>
                                    {step.content}
                                </Text>
                            )}
                        </StepContainer>
                    ))}

                    {!steps.length && (
                        <Text variant={'body1'} color={'DARK_GRAY'}>
                            No major changes have been added to this order. We will proceed with the items as-is.
                        </Text>
                    )}
                </ContentContainer>
            </Container>
        </RefabBodyContainer>
    );
};
