import { ListItem, Text } from '@orthly/ui-primitives';
import React from 'react';

type SectionHeaderProps = {
    label: string;
};

export const SectionHeader: React.FC<SectionHeaderProps> = ({ label }) => {
    return (
        <ListItem key={label}>
            <Text variant={'body1'}>{label}</Text>
        </ListItem>
    );
};
