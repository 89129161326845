import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { DesignReviewOption, useIsOrderEligibleForVideoDesignReview } from '../components';
import type { GuidedWaxupScheduleModal, SubmissionStatus, SubmissionType } from './useGuidedWaxupAccessoryModal';
import { useMutation, useQuery } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLiveLabTechMeetingRequestEntryPoint } from '@orthly/graphql-schema';
import { type LabsGqlLiveDdpReviewRequestEntryPoint } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useRootActionCommand } from '@orthly/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const VeneerScheduleLiveDDPCall_Mutation = graphql(`
    mutation VeneerScheduleLiveDDPCall_Mutation($args: CreateLiveDdpReviewRequestArgs!) {
        createLiveDdpReviewRequest(args: $args) {
            id
        }
    }
`);

const VeneerScheduleVideoDDPReview_Mutation = graphql(`
    mutation VeneerScheduleVideoDDPReview_Mutation($args: CreateVideoDdpReviewRequestArgs!) {
        createVideoDdpReviewRequest(args: $args) {
            id
        }
    }
`);

const VeneerScheduleLabTechMeeting_Mutation = graphql(`
    mutation VeneerScheduleLabTechMeeting_Mutation($args: CreateLiveLabTechMeetingRequestArgs!) {
        createLiveLabTechMeetingRequest(args: $args) {
            id
        }
    }
`);

const VeneerIsOrderEligibleForLiveLabTechMeeting_Query = graphql(`
    query VeneerIsOrderEligibleForLiveLabTechMeeting_Query {
        isOrderEligibleForLiveLabTechMeeting
    }
`);

export const useIsConfirmationModalEnabled = () => {
    const { value: enableLiveTechMeetings } = useFeatureFlag('enableLiveTechMeetings');
    const { value: enableLiveTechMeetingsForFirstTimeUsers } = useFeatureFlag(
        'enableLiveTechMeetingsForFirstTimeUsers',
    );
    const { data: eligibilityData } = useQuery(VeneerIsOrderEligibleForLiveLabTechMeeting_Query);
    const isUserEligible =
        enableLiveTechMeetingsForFirstTimeUsers || eligibilityData?.isOrderEligibleForLiveLabTechMeeting;
    return enableLiveTechMeetings && isUserEligible;
};

export const useRejectDdpAction = (
    orderId: string,
    revisionId: string,
    numRejections: number,
    setModal: (modal: GuidedWaxupScheduleModal) => void,
    onDesignReviewedAction?: (options: { showLdrSurvey: boolean }) => Promise<void>,
) => {
    const navigate = useNavigate();
    const session = useSession();
    const [loading, setLoading] = React.useState(false);

    const rawVDRMtn = useMutation(VeneerScheduleVideoDDPReview_Mutation);
    const { submit: submitRequestVideoDesignReview } = useRootActionCommand(rawVDRMtn, {
        onSuccess: async () => {
            setModal('closed');
            navigate(`/orders/${orderId}`);
        },
        successMessage: 'Video Design Review successfully requested!',
    });
    const { submit: submitRequestVideoDesignReviewNonOptIn } = useRootActionCommand(rawVDRMtn, {
        onSuccess: async () => {
            setModal('closed');
            navigate(`/orders/${orderId}`);
        },
    });

    const isVDREnabled = useIsOrderEligibleForVideoDesignReview({ id: orderId });
    const { value: isNonOptInVdrEnabled = false } = useFeatureFlag('enableNonOptInVdr');
    const shouldRequestNonOptInVDR = numRejections > 1 && isVDREnabled && isNonOptInVdrEnabled;

    const onRejectAction = React.useCallback(
        (option: DesignReviewOption) => {
            const continueWithoutReviewAction = async () => {
                setLoading(true);

                await onDesignReviewedAction?.({ showLdrSurvey: true });

                // trigger non-opt-in VDR for 2+ DDP rejections
                if (shouldRequestNonOptInVDR) {
                    await submitRequestVideoDesignReviewNonOptIn({
                        args: {
                            design_order_revision_id: revisionId,
                            order_id: orderId,
                            practice_id: session?.organization_id ?? '',
                            dr_opt_in: false,
                        },
                    });

                    BrowserAnalyticsClientFactory.Instance?.track(
                        'Practice - Guided Waxup - Video Design Review - Review Requested',
                        {
                            $groups: {
                                order: orderId,
                            },
                            revisionId: revisionId,
                            requestedByDoctor: false,
                        },
                    );
                }

                setLoading(false);
            };

            const scheduleLdrAction = async () => {
                setModal('calendly');
            };

            const requestVdrAction = async () => {
                setLoading(true);

                await onDesignReviewedAction?.({ showLdrSurvey: false });

                await submitRequestVideoDesignReview({
                    args: {
                        design_order_revision_id: revisionId,
                        order_id: orderId,
                        practice_id: session?.organization_id ?? '',
                        dr_opt_in: true,
                    },
                });

                BrowserAnalyticsClientFactory.Instance?.track(
                    'Practice - Guided Waxup - Video Design Review - Review Requested',
                    {
                        $groups: {
                            order: orderId,
                        },
                        revisionId: revisionId,
                        requestedByDoctor: true,
                    },
                );

                setLoading(false);
            };

            switch (option) {
                case DesignReviewOption.CONTINUE_WITHOUT_REVIEW:
                    return continueWithoutReviewAction();
                case DesignReviewOption.REQUEST_LDR:
                    return scheduleLdrAction();
                case DesignReviewOption.REQUEST_VDR:
                    return requestVdrAction();
            }
        },
        [
            onDesignReviewedAction,
            setModal,
            submitRequestVideoDesignReview,
            revisionId,
            orderId,
            session?.organization_id,
            setLoading,
            shouldRequestNonOptInVDR,
            submitRequestVideoDesignReviewNonOptIn,
        ],
    );

    return {
        loading,
        onRejectAction,
    };
};

export const useCalendlyLdrEventScheduledAction = (
    orderId: string,
    revisionId: string,
    entryPoint: LabsGqlLiveDdpReviewRequestEntryPoint,
    setModal: (modal: GuidedWaxupScheduleModal) => void,
    setSubmissionStatus: (value: SubmissionStatus) => void,
    onDesignReviewedAction?: (options: { showLdrSurvey: boolean }) => Promise<void>,
    onScheduleCompletion?: () => void,
) => {
    const navigate = useNavigate();
    const session = useSession();

    // show the confirmation model here if it's enabled for the user/order
    const shouldShowConfirmationModal = useIsConfirmationModalEnabled();

    const rawLDRMtn = useMutation(VeneerScheduleLiveDDPCall_Mutation);
    const { submit: submitScheduleLiveDesignReview } = useRootActionCommand(rawLDRMtn, {
        onSuccess: async () => {
            if (shouldShowConfirmationModal) {
                setSubmissionStatus('success');
            } else {
                setModal('closed');
                navigate(`/orders/${orderId}`);
            }
        },
        successMessage: 'Live Design Review successfully scheduled!',
    });

    return React.useCallback(
        async (eventId: string, rejectionNotes?: string) => {
            if (shouldShowConfirmationModal) {
                setSubmissionStatus('isSubmitting');
                setModal('confirmation');

                BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Live Lab Tech Meeting - Available', {
                    $groups: {
                        order: orderId,
                    },
                    eventId,
                    entryPoint,
                });
            }

            await onDesignReviewedAction?.({ showLdrSurvey: false });

            await submitScheduleLiveDesignReview({
                args: {
                    order_id: orderId,
                    design_order_revision_id: revisionId,
                    calendly_event_id: eventId,
                    practice_id: session?.organization_id ?? '',
                    rejection_notes: rejectionNotes,
                    entry_point: entryPoint,
                },
            });

            BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Live Design Review - Scheduled Call', {
                $groups: {
                    order: orderId,
                },
                revisionId: revisionId,
                eventId,
                entryPoint,
            });

            if (!shouldShowConfirmationModal) {
                onScheduleCompletion?.();
            }
        },
        [
            onDesignReviewedAction,
            submitScheduleLiveDesignReview,
            orderId,
            revisionId,
            entryPoint,
            session?.organization_id,
            onScheduleCompletion,
            shouldShowConfirmationModal,
            setModal,
            setSubmissionStatus,
        ],
    );
};

export const useCalendlyLabTechMeetingEventScheduledAction = (
    orderId: string,
    entryPoint: LabsGqlLiveLabTechMeetingRequestEntryPoint,
    setSubmissionStatus: (value: SubmissionStatus) => void,
    setModal: (modal: GuidedWaxupScheduleModal) => void,
    setSubmissionType: (submissionType: SubmissionType) => void,
) => {
    const rawScheduleLiveTechMeetingMtn = useMutation(VeneerScheduleLabTechMeeting_Mutation);
    const { submit: submitScheduleLiveLabTechMeeting } = useRootActionCommand(rawScheduleLiveTechMeetingMtn, {
        onSuccess: async () => {
            setSubmissionStatus('success');
        },
        successMessage: 'Live Lab Tech Meeting successfully scheduled!',
    });

    return React.useCallback(
        async (eventId: string) => {
            setSubmissionStatus('isSubmitting');
            setSubmissionType('labTechMeeting');
            setModal('confirmation');

            await submitScheduleLiveLabTechMeeting({
                args: {
                    calendly_event_id: eventId,
                    entry_point: entryPoint,
                    notes: '',
                },
            });

            BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Live Lab Tech Meeting - Scheduled', {
                $groups: {
                    order: orderId,
                },
                eventId,
                entryPoint,
            });
        },
        [orderId, entryPoint, submitScheduleLiveLabTechMeeting, setModal, setSubmissionType, setSubmissionStatus],
    );
};
