import MeshPainterMaterial from '../utils/PainterMaterial';
import { sceneRefAtom } from './store';
import type { SceneModelAppearance } from '@orthly/dentin';
import { atom } from 'jotai';
import type { Mesh, Scene } from 'three';

export interface ScansRecord<T> {
    upperJaw: T;
    lowerJaw: T;
    prePrepUpperJaw?: T;
    prePrepLowerJaw?: T;
}

export type SceneAppearance = {
    scans: ScansRecord<SceneModelAppearance>;
    restoratives: Map<string, SceneModelAppearance>;
};

export type RestorativesAppearance = Map<number, SceneModelAppearance>;

export const DEFAULT_APPEARANCE: SceneModelAppearance = {
    label: '',
    visible: true,
    transparent: false,
    colorize: true,
};

function findAndUpdateAppearance(sceneRef: Scene, appearance: SceneModelAppearance, name: string): void {
    const mesh = sceneRef.getObjectByName(name) as Mesh | undefined;
    if (mesh) {
        if (appearance.visible) {
            mesh.layers.enableAll();
        } else {
            mesh.layers.disableAll();
        }
        const material = mesh.material;
        const validMaterial = material && !Array.isArray(material) && material instanceof MeshPainterMaterial;
        if (validMaterial) {
            if (appearance.transparent) {
                material.transparent = true;
                material.opacity = 0.5;
            } else {
                material.transparent = false;
            }
            material.setGreyScale(!appearance.colorize);
            material.needsUpdate = true;
        }
    }
}
const upperJawAppearanceAtom = atom<SceneModelAppearance, SceneModelAppearance[], void>(
    DEFAULT_APPEARANCE,
    (get, set, update: SceneModelAppearance) => {
        set(upperJawAppearanceAtom, update);
        const scene = get(sceneRefAtom);
        if (scene) {
            findAndUpdateAppearance(scene, update, 'upperJaw');
        }
    },
);

const lowerJawAppearanceAtom = atom<SceneModelAppearance, SceneModelAppearance[], void>(
    DEFAULT_APPEARANCE,
    (get, set, update: SceneModelAppearance) => {
        set(lowerJawAppearanceAtom, update);
        const scene = get(sceneRefAtom);
        if (scene) {
            findAndUpdateAppearance(scene, update, 'lowerJaw');
        }
    },
);

// For prePrep
const prePrepUpperJawAppearanceAtom = atom<SceneModelAppearance, SceneModelAppearance[], void>(
    {
        visible: true,
        transparent: false,
        colorize: false,
    },
    (get, set, update: SceneModelAppearance) => {
        set(prePrepUpperJawAppearanceAtom, update);
        const scene = get(sceneRefAtom);
        if (scene) {
            findAndUpdateAppearance(scene, update, 'prePrepUpperJaw');
        }
    },
);

const prePrepLowerJawAppearanceAtom = atom<SceneModelAppearance, SceneModelAppearance[], void>(
    {
        visible: true,
        transparent: false,
        colorize: false,
    },
    (get, set, update: SceneModelAppearance) => {
        set(prePrepLowerJawAppearanceAtom, update);
        const scene = get(sceneRefAtom);
        if (scene) {
            findAndUpdateAppearance(scene, update, 'prePrepLowerJaw');
        }
    },
);

const restorativesAppearanceAtom = atom<RestorativesAppearance, RestorativesAppearance[], void>(
    new Map(),
    (get, set, update: RestorativesAppearance) => {
        set(restorativesAppearanceAtom, update);
        const scene = get(sceneRefAtom);
        if (scene) {
            update.forEach((appearance, name) => {
                findAndUpdateAppearance(scene, appearance, name.toString());
            });
        }
    },
);

export {
    upperJawAppearanceAtom,
    lowerJawAppearanceAtom,
    prePrepUpperJawAppearanceAtom,
    prePrepLowerJawAppearanceAtom,
    restorativesAppearanceAtom,
};
