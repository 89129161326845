import type {
    LabsGqlCreateParentOrgMutationVariables,
    LabsGqlOrganizationWithDescendantsFragment,
    LabsGqlUserDtoFragment,
} from '@orthly/graphql-operations';
import {
    useGetOrgPrimaryAdminQuery,
    useCreateParentOrgMutation,
    useListRetainerUsersQuery,
    useListOrgSummariesQuery,
} from '@orthly/graphql-react';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import {
    useChangeSubmissionFn,
    RootActionDialog,
    LoadBlocker,
    SimpleSelect,
    SimpleInput,
    SimplePhoneInput,
} from '@orthly/ui';
import { Button, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type CreateOrgVars = Omit<LabsGqlCreateParentOrgMutationVariables['data'], 'password' | 'confirm_password'>;

interface CreateOrganizationFormProps {
    parentOrg?: LabsGqlOrganizationWithDescendantsFragment;
    submit: (vars: CreateOrgVars) => Promise<void>;
}

interface CreateOrganizationFormState {
    parentOrgId?: string;
    organizationName?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    salesforceAccountId?: string;
    pandadocContractId?: string;
}

function formIsComplete(state: CreateOrganizationFormState): boolean {
    return (
        !!state.organizationName &&
        !!state.email &&
        !!state.firstName &&
        !!state.lastName &&
        !!state.phoneNumber &&
        !!state.salesforceAccountId &&
        !!state.pandadocContractId
    );
}

const CreateOrganizationForm: React.FC<CreateOrganizationFormProps> = props => {
    const { parentOrg, submit } = props;
    const { data: parentOrgsData, loading: parentOrgsLoading } = useListOrgSummariesQuery({
        variables: { filter: { type: LabsGqlOrganizationType.Parent } },
    });
    const { data: usersData } = useListRetainerUsersQuery();
    const parentOrgs = parentOrgsData?.listOrganizationSummaries ?? [];
    const usersByPhoneNumber: Record<string, LabsGqlUserDtoFragment[]> = _.groupBy(
        usersData?.listUsers ?? [],
        u => u.phone_number,
    );
    const [formState, setFormState] = React.useState<CreateOrganizationFormState>({ parentOrgId: parentOrg?.id });
    const { loading: primaryAdminLoading, refetch: refetchPrimaryAdmin } = useGetOrgPrimaryAdminQuery({
        variables: { id: formState.parentOrgId ?? '' },
        skip: !formState.parentOrgId,
        onCompleted: data => {
            const { user: adminUser } = data.getOrgPrimaryAdmin;
            setFormState({
                ...formState,
                email: adminUser.email,
                firstName: adminUser.first_name,
                lastName: adminUser.last_name,
                phoneNumber: adminUser.phone_number ?? undefined,
            });
        },
    });
    const onSetParentOrgId = (value?: string): void => {
        setFormState({ ...formState, parentOrgId: value });
        if (value) {
            void refetchPrimaryAdmin({ id: value }).then(result => {
                const { user: adminUser } = result.data.getOrgPrimaryAdmin;
                setFormState({
                    ...formState,
                    email: adminUser.email,
                    firstName: adminUser.first_name,
                    lastName: adminUser.last_name,
                    phoneNumber: adminUser.phone_number ?? undefined,
                });
            });
        }
    };
    const onPhoneNumberChange = (value?: string): void => {
        if (typeof value === 'string') {
            const cleanedPhoneNumber = PhoneNumberUtils.cleanPhoneNumber(value);
            if (cleanedPhoneNumber) {
                const user = _.first(usersByPhoneNumber[cleanedPhoneNumber]);
                if (user) {
                    setFormState({
                        ...formState,
                        phoneNumber: value,
                        email: user.email,
                        firstName: user.first_name,
                        lastName: user.last_name,
                    });
                    return;
                }
            }
        }
        setFormState({ ...formState, phoneNumber: value });
    };
    const onSubmit = async (): Promise<void> => {
        const {
            parentOrgId,
            organizationName,
            email,
            firstName,
            lastName,
            phoneNumber,
            salesforceAccountId,
            pandadocContractId,
        } = formState;
        await submit({
            parent_id: parentOrgId,
            legal_business_name: organizationName ?? '',
            email: email ?? '',
            first_name: firstName ?? '',
            last_name: lastName ?? '',
            phone_number: phoneNumber ?? '',
            salesforce_account_id: salesforceAccountId ?? '',
            pandadoc_contract_id: pandadocContractId ?? '',
        });
    };
    return (
        <LoadBlocker blocking={parentOrgsLoading || primaryAdminLoading}>
            <Grid container>
                <Grid container style={{ padding: '12px 0' }} direction={'column'}>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleSelect
                            label={'Add to organization (optional)'}
                            placeholder={'None'}
                            value={formState.parentOrgId}
                            onChange={onSetParentOrgId}
                            options={parentOrgs.map(org => ({
                                value: org.id,
                                label: org.name,
                            }))}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Organization name'}
                            value={formState.organizationName}
                            onChange={value => setFormState({ ...formState, organizationName: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimplePhoneInput
                            fullWidth
                            label={'Phone number'}
                            value={formState.phoneNumber}
                            onChange={onPhoneNumberChange}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Email'}
                            value={formState.email}
                            onChange={value => setFormState({ ...formState, email: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'First name'}
                            value={formState.firstName}
                            onChange={value => setFormState({ ...formState, firstName: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Last name'}
                            value={formState.lastName}
                            onChange={value => setFormState({ ...formState, lastName: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Salesforce ID'}
                            value={formState.salesforceAccountId}
                            onChange={value => setFormState({ ...formState, salesforceAccountId: value })}
                        />
                    </Grid>
                    <Grid item style={{ padding: '6px 0' }}>
                        <SimpleInput
                            fullWidth
                            label={'Pandadoc Contract ID'}
                            value={formState.pandadocContractId}
                            onChange={value => setFormState({ ...formState, pandadocContractId: value })}
                        />
                    </Grid>
                </Grid>
                <Button fullWidth variant={'primary'} onClick={onSubmit} disabled={!formIsComplete(formState)}>
                    Submit
                </Button>
            </Grid>
        </LoadBlocker>
    );
};

interface CreateOrganizationActionProps {
    parentOrg?: LabsGqlOrganizationWithDescendantsFragment;
    onSubmit: () => Promise<void>;
}

export const CreateOrganizationAction: React.FC<CreateOrganizationActionProps> = props => {
    const { parentOrg, onSubmit } = props;
    const [submitMtn] = useCreateParentOrgMutation();
    const mtnSubmitter = (data: CreateOrgVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [CreateOrgVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Organization successfully created', {}],
    });
    return (
        <RootActionDialog
            loading={submitting}
            title={'Add new organization'}
            buttonText={'+ Add'}
            setOpen={setOpen}
            open={open}
            buttonProps={{ style: { maxWidth: 175 } }}
            content={
                <CreateOrganizationForm
                    parentOrg={parentOrg}
                    submit={async (vars: CreateOrgVars) => {
                        await submit(vars);
                        await onSubmit();
                    }}
                />
            }
        />
    );
};
