import { toothRefAtom } from '../../store/designsStore';
import { canvasLayersAtom, gradientVecAtom, selectedCanvasLayerAtom, textureRefAtom } from '../../store/store';
import { updateTextureFromLayers } from '../../utils/CanvasLayers';
import type { VertexNeighborData } from '../../utils/OcclusalCharacterization';
import { applyOcclusalStaining, detectGrooves } from '../../utils/OcclusalCharacterization';
import { Slider, styled } from '@orthly/ui-primitives';
import { useAtomValue } from 'jotai';
import React from 'react';

const SettingRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px',
});

const SettingLabel = styled('label')({
    fontSize: '14px',
    width: '100px',
});

const OcclusalCharacterizationControl: React.FC = () => {
    const [depth, setDepth] = React.useState(1);
    const [fade, setFade] = React.useState(7);

    const [vertexNeighbors, setVertexNeighbors] = React.useState<Map<number, VertexNeighborData>>();
    const selectedRestorative = useAtomValue(toothRefAtom);
    const gradientVec = useAtomValue(gradientVecAtom);
    const selectedCanvasLayer = useAtomValue(selectedCanvasLayerAtom);
    const canvasLayers = useAtomValue(canvasLayersAtom);
    const texture = useAtomValue(textureRefAtom);

    React.useEffect(() => {
        if (!selectedRestorative) {
            return;
        }
        setVertexNeighbors(detectGrooves(selectedRestorative));
    }, [selectedRestorative]);

    React.useEffect(() => {
        if (!selectedRestorative || !vertexNeighbors || !selectedCanvasLayer?.canvas) {
            return;
        }
        applyOcclusalStaining(
            selectedRestorative,
            gradientVec,
            vertexNeighbors,
            depth,
            fade,
            selectedCanvasLayer.canvas,
        );
        if (texture) {
            updateTextureFromLayers(canvasLayers, texture);
        }
    }, [fade, depth, canvasLayers, selectedCanvasLayer, vertexNeighbors, texture, selectedRestorative, gradientVec]);

    const handleDepthChange = (_event: Event, value: number | number[]) => {
        const newDepth = Array.isArray(value) ? value[0] : value;
        if (newDepth !== undefined) {
            setDepth(newDepth);
        }
    };

    const handleFadeChange = (_event: Event, value: number | number[]) => {
        const newFade = Array.isArray(value) ? value[0] : value;
        if (newFade !== undefined) {
            setFade(newFade);
        }
    };

    return (
        <div>
            <SettingRow>
                <SettingLabel>Depth</SettingLabel>
                <Slider
                    min={0.001}
                    max={0.02}
                    step={0.001}
                    value={depth}
                    onChange={handleDepthChange}
                    aria-label={'Occlusal Characterization Depth'}
                    valueLabelDisplay={'auto'}
                />
            </SettingRow>

            <SettingRow>
                <SettingLabel>Fade</SettingLabel>
                <Slider
                    min={4}
                    max={10}
                    step={0.1}
                    value={fade}
                    onChange={handleFadeChange}
                    aria-label={'Occlusal Characterization Fade'}
                    valueLabelDisplay={'auto'}
                />
            </SettingRow>
        </div>
    );
};

export default OcclusalCharacterizationControl;
