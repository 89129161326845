import type { CustomView } from '../redux/utils/SavedView.types';
import type { EditSavedSearchPayload } from '../redux/utils/SavedViewActionUtils';
import type { ApolloClient } from '@apollo/client';
import { LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import { Text, Button, stylesFactory, FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface OnClickSaveProps<T extends CustomView> {
    isEdited: boolean;
    viewId: string;
    visibility?: LabsGqlSavedSearchVisibility;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    client: ApolloClient<any>;
    view?: T;
    setEditingView: (id: string) => void;
    saveView: (payload: EditSavedSearchPayload) => void;
}

export function useOnClickSave<T extends CustomView>(props: OnClickSaveProps<T>) {
    const { isEdited, view, setEditingView, saveView, client, viewId, visibility } = props;
    return React.useCallback(async () => {
        if (isEdited && view) {
            (visibility !== LabsGqlSavedSearchVisibility.Public ||
                window.confirm(`This will update everyone's inboxes, are you sure?`)) &&
                saveView({ client, title: view.title, searchId: view.id });
            return;
        }
        setEditingView(viewId);
    }, [isEdited, view, setEditingView, saveView, client, viewId, visibility]);
}

interface CustomViewBottomActionProps {
    setView: (id: string) => void;
    copyView?: () => void;
    onClickSave: () => void;
    viewId: string;
    createdByUser?: boolean;
}

export const useNavItemRootStyles = stylesFactory(() => ({
    unsavedFilterItemText: { color: FlossPalette.GRAY, fontWeight: 'normal' },
    textWrapper: { flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
    root: { paddingLeft: 16, flexDirection: 'column', justifyContent: 'center' },
    inactiveFilterRoot: { '&:hover': { backgroundColor: 'unset' } },
}));

const useBottomActionStyles = stylesFactory(() => ({
    button: {
        padding: 0,
        '&&': {
            color: FlossPalette.STAR_GRASS,
        },
        fontSize: 14,
        minWidth: 'fit-content',
    },
}));

export const CustomViewBottomAction: React.FC<CustomViewBottomActionProps> = props => {
    const classes = useBottomActionStyles();
    const { setView, onClickSave, viewId, createdByUser, copyView } = props;
    if (!createdByUser && !copyView) {
        return null;
    }
    return (
        <Grid container style={{ alignItems: 'center' }}>
            <Text variant={'caption'} color={'DARK_GRAY'}>
                Unsaved Changes
            </Text>
            <Button
                variant={'text'}
                onClick={createdByUser ? onClickSave : copyView}
                className={classes.button}
                style={{ padding: '0 8px' }}
            >
                {createdByUser ? 'Save' : 'Save as'}
            </Button>
            <Button variant={'text'} onClick={() => setView(viewId)} className={classes.button}>
                Discard
            </Button>
        </Grid>
    );
};
