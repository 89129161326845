import DigitalDesignPreviewVideo from '../../../../../../../assets/images/DigitalDesignPreviewVideo.mp4';
import LiveDesignReviewSVG from '../../../../../../../assets/images/LiveDesignReview.svg';
import VideoDesignReviewSVG from '../../../../../../../assets/images/VideoDesignReview.svg';
import { DesignReviewOption } from '../GuidedWaxupSelectDesignReviewOptionModal';
import { GuidedWaxupSelectDesignReviewOptionHelpHeader } from './GuidedWaxupSelectDesignReviewOptionHelpHeader';
import { VdrEarlyAccessButton } from './GuidedWaxupSelectDesignReviewOptionVdrEarlyAccessButton';
import type { IconName } from '@orthly/ui-primitives';
import { Box, FlossPalette, Icon, Tab, Tabs, Text, styled } from '@orthly/ui-primitives';
import React from 'react';
import ReactPlayer from 'react-player';

const ContentLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
        gap: '0px',
    },
}));

const HeaderWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: '0px',
    background: FlossPalette.WHITE,
});

const InnerContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: FlossPalette.TAN,
    borderRadius: '16px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    [theme.breakpoints.down('sm')]: {
        margin: '24px',
    },
}));

const TabsLayout = styled(Tabs)(({ theme }) => ({
    height: '32px',
    position: 'sticky',
    top: '0',
    [theme.breakpoints.down('sm')]: {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        height: '24px',
    },
}));

const InnerContentTextBullets = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '392px',
    gap: '4px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        minWidth: '100%',
    },
}));

const InnerContentText = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 32px',
    gap: '38px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '16px',
    },
}));

const InnerContentImg = styled('img')({
    display: 'block',
    margin: 'auto',
    width: '500px',
    borderRadius: '8px',
    padding: '8px 16px 16px 16px',
});

const InnerContentVideoPlayer = styled(ReactPlayer)({
    padding: '16px',
    borderRadius: '16px',
    background: FlossPalette.TAN,
    backdropFilter: `blur(10px)`,
    zIndex: 10_000,
    video: {
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: '16px',
    },
});

const DesignPreviewTextWrapper = styled(Box)({
    padding: '12px',
});

const InfoSnippetLayout = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
});

const InfoSnippetText = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
});

const EarlyAccessWrapper = styled(Box)({
    paddingLeft: '29px',
});

interface InfoSnippetProps {
    text: string | JSX.Element;
    icon: IconName;
    caption?: string;
}

const InfoSnippet: React.VFC<InfoSnippetProps> = ({ icon, text, caption }) => {
    return (
        <InfoSnippetLayout>
            <Icon icon={icon} style={{ color: FlossPalette.BLACK }} />
            <InfoSnippetText>
                <Text variant={'body2'}>{text}</Text>
                {!!caption && (
                    <Text variant={'caption'} color={'GRAY'}>
                        {caption}
                    </Text>
                )}
            </InfoSnippetText>
        </InfoSnippetLayout>
    );
};

interface InnerContentProps {
    isMobile?: boolean;
}

const InnerContentLDR: React.VFC<InnerContentProps> = ({ isMobile }) => {
    const snippetProps: InfoSnippetProps[] = [
        {
            text: (
                <Text component={'span'} variant={'body2'} medium>
                    {'Human to Human: '}
                    <Text component={'span'} variant={'body2'}>
                        Directly communicate with your lab technician, so that nothing gets lost in translation.
                    </Text>
                </Text>
            ),
            icon: 'VideoIcon',
        },
        {
            text: (
                <Text component={'span'} variant={'body2'} medium>
                    {'Virtual Workspace: '}
                    <Text component={'span'} variant={'body2'}>
                        Connect with your lab technician remotely, wherever you are.
                    </Text>
                </Text>
            ),
            icon: 'LanguageIcon',
        },
        {
            text: `“Communication is much better when I can talk to the lab technician while visually reviewing the case.”`,
            caption: '— Dr. Lowell Tan',
            icon: 'ThumbsUpIcon',
        },
    ];

    return (
        <InnerContentWrapper>
            <InnerContentText>
                <Text variant={'body1'} medium>
                    Have design concerns? Consult with a Lab Technician.{' '}
                    <span style={{ color: FlossPalette.PRIMARY_FOREGROUND }}>
                        On average, our experts have 10+ years of dental experience.
                    </span>
                </Text>
                <InnerContentTextBullets>
                    {snippetProps.map(props => (
                        <InfoSnippet key={props.icon} {...props} />
                    ))}
                </InnerContentTextBullets>
            </InnerContentText>
            {!isMobile && <InnerContentImg src={LiveDesignReviewSVG} />}
        </InnerContentWrapper>
    );
};

interface InnerContentVDRProps extends InnerContentProps {
    isSignedUpForVdr?: boolean;
    signUpForVdr?: () => void;
}

const InnerContentVDR: React.VFC<InnerContentVDRProps> = ({ isSignedUpForVdr, signUpForVdr, isMobile }) => {
    const snippetProps: InfoSnippetProps[] = [
        {
            text: 'Request a Video Design Review along with your design',
            icon: 'CheckIcon',
        },
        {
            text: 'Receive a video of your design feedback being applied along with a before-and-after comparison.',
            icon: 'CompareIcon',
        },
        {
            text: 'Watch the video before reviewing your new design to see the exact changes made.',
            icon: 'PlayCircleFilledIcon',
        },
    ];

    return (
        <InnerContentWrapper>
            <InnerContentText>
                <Text variant={'body1'} medium>
                    See your feedback in action! Get a recorded video of a Lab Technician{' '}
                    <span style={{ color: FlossPalette.PRIMARY_FOREGROUND }}>applying your design feedback</span> to a
                    design.
                </Text>
                <InnerContentTextBullets>
                    {snippetProps.map(props => (
                        <InfoSnippet key={props.icon} {...props} />
                    ))}
                    <EarlyAccessWrapper>
                        <VdrEarlyAccessButton isSignedUpForVdr={isSignedUpForVdr} signUpForVdr={signUpForVdr} />
                        {isSignedUpForVdr && (
                            <Text variant={'caption'} color={'BLACK'} medium>
                                You’ve signed up for early access. We'll notify you as soon as this feature is
                                available.
                            </Text>
                        )}
                    </EarlyAccessWrapper>
                </InnerContentTextBullets>
            </InnerContentText>
            {!isMobile && <InnerContentImg src={VideoDesignReviewSVG} />}
        </InnerContentWrapper>
    );
};

export const InnerContentDigitalDesignPreview: React.VFC = () => {
    return (
        <InnerContentWrapper>
            <InnerContentText>
                <DesignPreviewTextWrapper>
                    <Text variant={'h6'} medium alignItems={'center'}>
                        Gain<span style={{ color: FlossPalette.PRIMARY_FOREGROUND }}> more control and</span>
                    </Text>
                    <Text variant={'h6'} medium>
                        <span style={{ color: FlossPalette.PRIMARY_FOREGROUND }}>visibility </span>on your case.
                    </Text>
                </DesignPreviewTextWrapper>
                <InnerContentTextBullets>
                    <Text variant={'body2'}>1. View the digital design of your final prosthesis in 3D.</Text>
                    <Text variant={'body2'}>2. Add feedback, mark it up, and request revisions.</Text>
                    <Text variant={'body2'}>3. Approve for manufacturing when you feel confident.</Text>
                </InnerContentTextBullets>
            </InnerContentText>
            <InnerContentVideoPlayer controls url={DigitalDesignPreviewVideo} width={'100%'} height={'100%'} />
        </InnerContentWrapper>
    );
};

interface HelpMediaProps {
    isSignedUpForVdr?: boolean;
    signUpForVdr?: () => void;
    isMobile?: boolean;
    currentHelpTab: string;
}

const HelpMedia: React.VFC<HelpMediaProps> = ({ isSignedUpForVdr, signUpForVdr, isMobile, currentHelpTab }) => {
    switch (currentHelpTab) {
        case DesignReviewOption.REQUEST_LDR:
            return <InnerContentLDR isMobile={isMobile} />;
        case DesignReviewOption.REQUEST_VDR:
            return (
                <InnerContentVDR isMobile={isMobile} signUpForVdr={signUpForVdr} isSignedUpForVdr={isSignedUpForVdr} />
            );
        case 'digital design preview':
            return <InnerContentDigitalDesignPreview />;
    }
    return null;
};

interface GuidedWaxupSelectDesignReviewOptionHelpContentProps {
    isSignedUpForVdr?: boolean;
    signUpForVdr?: () => void;
    isMobile?: boolean;
    currentHelpTab: string;
    setCurrentHelpTab: (option: string) => void;
    onClose: () => void;
    tabs: { name: string; value: string }[];
}

export const GuidedWaxupSelectDesignReviewOptionHelpContent: React.VFC<
    GuidedWaxupSelectDesignReviewOptionHelpContentProps
> = ({ isSignedUpForVdr, signUpForVdr, isMobile, currentHelpTab, setCurrentHelpTab, onClose, tabs }) => {
    return (
        <ContentLayout>
            <HeaderWrapper>
                <GuidedWaxupSelectDesignReviewOptionHelpHeader
                    isMobile={isMobile}
                    onClose={onClose}
                    currentHelpTab={currentHelpTab}
                />
                <TabsLayout
                    value={currentHelpTab}
                    onChange={(_, value: string) => setCurrentHelpTab(value)}
                    variant={'fullWidth'}
                    centered={true}
                >
                    {tabs.map(tab => (
                        <Tab
                            key={tab.value}
                            value={tab.value}
                            onClick={() => {
                                setCurrentHelpTab(tab.value);
                            }}
                            label={
                                <Text variant={'caption'} medium color={'PRIMARY_FOREGROUND'}>
                                    {tab.name}
                                </Text>
                            }
                        />
                    ))}
                </TabsLayout>
            </HeaderWrapper>
            <HelpMedia
                currentHelpTab={currentHelpTab}
                isMobile={isMobile}
                signUpForVdr={signUpForVdr}
                isSignedUpForVdr={isSignedUpForVdr}
            />
        </ContentLayout>
    );
};
