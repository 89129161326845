import { RouterTabs } from '../../components/RouterTabs';
import { LabPricesTable } from './LabPricesTable';
import { PartnerPricesV2Table } from './PartnerPricesV2/PartnerPricesV2Table';
import { PricingDetailPanel } from './PricingDetailPanel.graphql';
import { CreatePrice } from './PricingForms.graphql';
import { PricingPartnerImport } from './PricingPartnerImport.graphql';
import type { PricingOverviewTableRow } from './PricingRoot.types';
import { ProductCatalogTable } from './ProductCatalogTable';
import { UpdatedPricingQueryProvider, useUpdatedPricingQuery } from './hooks/useUpdatedPricingQuery.graphql';
import type { ListPricesWithPracticeCountsQuery } from '@orthly/graphql-inline-react';
import type { LabsGqlMetalMaterialFragment } from '@orthly/graphql-operations';
import {
    useCreateMetalMaterialMutation,
    useDeleteMetalMaterialMutation,
    useGetMetalMaterialQuery,
} from '@orthly/graphql-react';
import type { LabsGqlCreateMetalMaterialCommand } from '@orthly/graphql-schema';
import { MUITable } from '@orthly/mui-table';
import {
    LoadBlocker,
    QuickForm,
    RootActionDialog,
    TrashIcon,
    useChangeSubmissionFn,
    useRootActionCommand,
} from '@orthly/ui';
import { Grid, IconButton, Tooltip } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

function usePricingTableRows(sortedPrices: ListPricesWithPracticeCountsQuery['listPricesWithPracticeCounts']) {
    return React.useMemo(() => {
        return sortedPrices.map<PricingOverviewTableRow>(p => {
            const byUnit = _.groupBy(p.application_rules, a => a.unit_type);
            return {
                ...p,
                applies_if: Object.entries(byUnit)
                    .map(([unit, rules]) => {
                        const materials = rules.flatMap(r => (r.material_type ? [r.material_type] : [])).join(', ');
                        return `Unit Type: ${unit}. Materials: ${materials}`;
                    })
                    .join('\n'),
            };
        });
    }, [sortedPrices]);
}

const PricingTableRoot: React.FC = () => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const { prices, pricesLoading, refetchPrices } = useUpdatedPricingQuery();
    const sortedPrices = [...prices].sort(
        (a, b) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
    );
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    const rows = usePricingTableRows(sortedPrices);

    return (
        <Grid container>
            <MUITable<PricingOverviewTableRow>
                title={'Price Configs'}
                loading={pricesLoading}
                data={rows}
                DetailPanel={PricingDetailPanel}
                rowOptions={{ rowHover: true }}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                eventHooks={{
                    onRowClick: (row, actions) => {
                        actions.toggleDetailPanel(row);
                    },
                }}
                columns={[
                    { name: 'Name', render: 'name' },
                    {
                        name: 'Salesforce Property',
                        field: 'salesforce_price_mapping',
                        render: r => r.salesforce_price_mapping?.replace('__c', '') ?? '',
                    },
                    { name: 'Applies if', render: 'applies_if' },
                    { name: 'Partners With Rules', render: 'partner_prices_count' },
                ]}
                actions={{
                    global: [
                        {
                            icon: 'add',
                            onClick: () => {
                                setCreateOpen(true);
                            },
                            position: 'toolbar',
                            disabled: disableContractEdits,
                        },
                        {
                            icon: 'refresh',
                            onClick: () => {
                                void refetchPrices();
                            },
                            position: 'toolbar',
                        },
                    ],
                }}
            />
            <CreatePrice open={createOpen} setOpen={setCreateOpen} />
        </Grid>
    );
};

interface CreateMetalFormProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onSuccess?: () => Promise<void>;
}

const CreateMetalForm: React.FC<CreateMetalFormProps> = ({ open, setOpen, onSuccess }) => {
    const [submitMtn] = useCreateMetalMaterialMutation();
    const mtnSubmitter = (data: LabsGqlCreateMetalMaterialCommand) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [LabsGqlCreateMetalMaterialCommand]>(mtnSubmitter, {
        successMessage: () => ['New metal created!', {}],
        onSuccess: async () => {
            if (onSuccess) {
                await onSuccess();
            }
        },
    });

    return (
        <RootActionDialog
            open={open}
            loading={submitting}
            title={'Add new metal'}
            setOpen={setOpen}
            buttonText={'Create Metal Price'}
            CustomButton={() => null}
            content={
                <QuickForm<{ metal: string }>
                    fields={{
                        metal: { type: 'text' },
                    }}
                    initialValues={{ metal: '' }}
                    onSubmit={async result => {
                        await submit({ name: result.metal });
                    }}
                />
            }
        />
    );
};

interface DeleteMetalActionProps {
    data: LabsGqlMetalMaterialFragment;
    onDelete: () => void;
}

export const DeleteMetalAction: React.VFC<DeleteMetalActionProps> = props => {
    const { data, onDelete } = props;
    const { submit: submitArchive, submitting: submittingArchive } = useRootActionCommand(
        useDeleteMetalMaterialMutation(),
        {
            successMessage: `Metal deleted`,
            onSuccess: () => onDelete(),
        },
    );

    return (
        <Tooltip title={`Delete`}>
            <LoadBlocker blocking={submittingArchive} loader={`circular`}>
                <IconButton
                    onClick={() => {
                        const { id, name } = data;
                        const confirmDeleteMessage = `Are you sure you want to delete the metal ${name}?`;
                        if (window.confirm(confirmDeleteMessage)) {
                            void submitArchive({ data: { id } });
                        }
                    }}
                >
                    <TrashIcon />
                </IconButton>
            </LoadBlocker>
        </Tooltip>
    );
};

const MetalMaterialsTable: React.FC = () => {
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);

    const { data, loading, refetch } = useGetMetalMaterialQuery();
    const metals = (data?.getMetalMaterial ?? []) as LabsGqlMetalMaterialFragment[];
    return (
        <Grid container direction-xs-column spacing={2} alignItems={'baseline'}>
            <Grid item xs={1} />
            <Grid item xs={4}>
                <MUITable<LabsGqlMetalMaterialFragment>
                    title={'Metals'}
                    data={metals}
                    loading={loading}
                    displayOptions={{
                        elevation: 0,
                        sort: true,
                        fixedSearch: true,
                    }}
                    paginationOptions={{ disable: true }}
                    actions={{
                        global: [
                            { icon: 'refresh', position: 'toolbar', onClick: () => refetch().catch(console.error) },
                            {
                                icon: 'add',
                                position: 'toolbar',
                                onClick: () => setCreateOpen(true),
                                tooltip: 'Create',
                            },
                        ],
                    }}
                    columns={[
                        {
                            name: 'Precious Metal Alloys',
                            field: 'name',
                            render: row => row.name,
                            defaultSort: 'asc',
                        },
                        {
                            name: '',
                            render: row => <DeleteMetalAction data={row} onDelete={refetch} />,
                        },
                    ]}
                />
                <CreateMetalForm
                    setOpen={setCreateOpen}
                    open={createOpen}
                    onSuccess={async () => {
                        await refetch();
                        setCreateOpen(false);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export const PricingRoot: React.FC = () => {
    const { value: enablePracticePricingV2ConfigScreen } = useFeatureFlag('enablePracticePricingV2ConfigScreen');

    return (
        <UpdatedPricingQueryProvider>
            <RouterTabs
                items={_.compact([
                    { path: 'configs', label: 'Configs', Component: PricingTableRoot },
                    { path: 'partners', label: 'By Partner', Component: PricingPartnerImport },
                    { path: 'product-catalog', label: 'Product Catalog', Component: ProductCatalogTable },
                    { path: 'lab-prices', label: 'Lab Prices', Component: LabPricesTable },
                    enablePracticePricingV2ConfigScreen
                        ? { path: 'partner-prices', label: 'Partner Prices (v2)', Component: PartnerPricesV2Table }
                        : null,
                    { path: 'metals', label: 'Metals', Component: MetalMaterialsTable },
                ])}
            />
        </UpdatedPricingQueryProvider>
    );
};
