import type { Scene } from 'three';
import { Color, DirectionalLight, Group, Vector3 } from 'three';

export function applyD65Illumination(scene: Scene) {
    const d65Color = new Color(0xffffff);
    const intensity = 1.8;

    const lightGroup = new Group();
    lightGroup.name = 'Lights';
    scene.add(lightGroup);

    const positions = [
        new Vector3(1, 0, 0), // Right
        new Vector3(-1, 0, 0), // Left
        new Vector3(0, 1, 0), // Top
        new Vector3(0, -1, 0), // Bottom
        new Vector3(0, 0, 1), // Front
        new Vector3(0, 0, -1), // Back
    ];

    positions.forEach(position => {
        const dirLight = new DirectionalLight(d65Color, intensity);
        dirLight.position.copy(position);

        lightGroup.add(dirLight);
    });
}
