import { selectedRestorativeToothNumberAtom } from '../../store/designsStore';
import { selectedToolAtom } from '../../store/store';
import { ListItemButton, ListItemIcon, ListItemText, styled } from '@orthly/ui-primitives';
import { useAtom, useAtomValue } from 'jotai';
import React from 'react';

export const enum ToolNames {
    Paint = 'Paint',
    Gradient = 'Gradient',
    Shade = 'Shade',
}

type Tool = {
    name: ToolNames;
    icon: React.ReactNode;
    disabled?: boolean;
};

const tools: Tool[] = [
    { name: ToolNames.Shade, icon: undefined },
    { name: ToolNames.Gradient, icon: undefined },
    { name: ToolNames.Paint, icon: undefined },
];

const ListRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const ToolList: React.FC = () => {
    const [selectedTool, setSelectedTool] = useAtom(selectedToolAtom);
    const selectedRestorativeToothNumber = useAtomValue(selectedRestorativeToothNumberAtom);

    return (
        <ListRoot>
            {tools.map((tool, index) => (
                <ListItemButton
                    key={index}
                    disabled={tool?.disabled || !selectedRestorativeToothNumber}
                    onClick={() => setSelectedTool(tool.name)}
                    selected={tool.name === selectedTool}
                >
                    <ListItemIcon>{tool.icon}</ListItemIcon>
                    <ListItemText primary={tool.name} />
                </ListItemButton>
            ))}
        </ListRoot>
    );
};

export default ToolList;
