import type { LabPriceFormState } from './ProductCatalogForms.types';
import type { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import type { FieldDefBasic, FieldsDefProp } from '@orthly/ui';

export function intoBasePriceFieldDefs<T extends LabsGqlLabPriceRuleType>(
    defs: FieldsDefProp<Omit<LabPriceFormState<T>, 'name'>>,
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): FieldsDefProp<any> {
    return {
        name: {
            type: 'text',
            label: 'Name',
            optional: false,
            // EPDPLT-4736: Using any is unsafe and should be avoided.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as FieldDefBasic<any, LabPriceFormState<T>['name']>,
        ...defs,
    };
}
