import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

const VeneerGetLiveLabTechMeetingUrl_Query = graphql(`
    query GetLiveLabTechMeetingUrl {
        getLiveLabTechMeetingUrl {
            url
        }
    }
`);

export const useLiveLabTechMeetingUrl = () => {
    const { data } = useQuery(VeneerGetLiveLabTechMeetingUrl_Query);

    return data?.getLiveLabTechMeetingUrl.url;
};
