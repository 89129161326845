import { atom, useAtom, useSetAtom } from 'jotai';
import React from 'react';

export type GuidedWaxupScheduleModal =
    | 'closed'
    | 'initial'
    | 'abandonment'
    | 'calendly'
    | 'options'
    | 'cancel'
    | 'vdr-abandonment'
    | 'confirmation'
    | 'live-lab-tech-meeting-calendly';

export type SubmissionStatus = 'success' | 'isSubmitting' | 'failed' | 'hasntYetSubmitted';
export type SubmissionType = 'ldr' | 'labTechMeeting';

const guidedWaxupAccessoryModalAtom = atom<GuidedWaxupScheduleModal>('closed');

export const useGuidedWaxupAccessoryModal = () => {
    const [modal, setModal] = useAtom(guidedWaxupAccessoryModalAtom);

    // Close the modal upon unmount
    React.useEffect(() => () => setModal('closed'), []); // eslint-disable-line react-hooks/exhaustive-deps

    return [modal, setModal] as const;
};

export const useSetGuidedWaxupAccessoryModal = () => useSetAtom(guidedWaxupAccessoryModalAtom);
