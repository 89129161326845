import type { AdminState } from '../../../redux/redux.types';
import { UNSAVED_VIEW_ID } from '../../../redux/utils/SavedViewActionConstants';
import { SavedViewReducerUtils } from '../../../redux/utils/SavedViewReducerUtils';
import { RouterUtils } from '../../../utils/router/RouterUtils';
import { OpsInboxActions } from './Inbox.actions';
import { isInboxScreen } from './Inbox.selectors';
import type {
    InboxState,
    OpsInboxScreen,
    InboxCustomViewState,
    ActiveInboxCustomView,
    InboxCustomView,
    InboxSortState,
} from './Inbox.types';
import { DEFAULT_SORT } from './InboxConstants';
import {
    LabsGqlSavedSearchVisibility,
    LabsGqlWorkflowTaskSortField,
    LabsGqlWorkflowTaskType,
} from '@orthly/graphql-schema';
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { REHYDRATE } from 'redux-persist';

const initialState: InboxState = {
    screen: 'AssignedToMe',
    history: {},
    savedViews: {},
    view: null,
    selectedTaskIds: [],
};

export function customInboxScreenActive(state: InboxState): state is InboxCustomViewState {
    return state.screen === 'Custom';
}

function getPriorScreen(state: InboxState): OpsInboxScreen {
    if (customInboxScreenActive(state)) {
        return state.view?.priorScreen ?? 'AssignedToMe';
    }
    return state.screen;
}

function setCurrentCustomView(state: InboxState, viewUpdate: Partial<InboxCustomView> | null): InboxState {
    if (state.view) {
        return { ...state, view: { ...state.view, ...viewUpdate }, selectedTaskIds: [] };
    }
    return {
        ...state,
        screen: 'Custom',
        view: {
            id: UNSAVED_VIEW_ID,
            title: viewUpdate?.search ? 'Search results' : 'Unsaved view',
            priorScreen: getPriorScreen(state),
            visibility: LabsGqlSavedSearchVisibility.Private,
            sort: { asc: true, key: LabsGqlWorkflowTaskSortField.LabDesignPriority },
            ...viewUpdate,
        },
        selectedTaskIds: [],
    };
}

function customViewFromUrl(): Partial<InboxCustomView> | null {
    const ordersCriteria = RouterUtils.filterParamsFromUrl('tasks');
    if (ordersCriteria.length > 0) {
        return { ordersCriteria, tasksFilter: { completed: false, type: Object.values(LabsGqlWorkflowTaskType) } };
    }
    return null;
}

function getInitialState(): InboxState {
    const view = customViewFromUrl();
    return view ? setCurrentCustomView(initialState, customViewFromUrl()) : initialState;
}

export function getSortForNonCustom(_screen: OpsInboxScreen | 'Custom'): InboxSortState {
    return DEFAULT_SORT;
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inboxReducer = handleActions<InboxState, any>(
    {
        [REHYDRATE]: (state: InboxState, action: { payload?: AdminState }) => {
            const rehydratedState: Partial<InboxState> = action.payload?.inbox ?? {};
            const newScreen = rehydratedState.screen ?? state.screen;
            const rehydratedViews =
                !!rehydratedState.savedViews && !Array.isArray(rehydratedState.savedViews)
                    ? rehydratedState.savedViews
                    : {};
            const baseState: InboxState = {
                ...state,
                history: { ...state.history, ...rehydratedState.history },
                savedViews: rehydratedViews,
            };
            const customView = customViewFromUrl();
            if (customView) {
                return setCurrentCustomView(baseState, customView);
            }
            if (newScreen === 'Custom' && !!rehydratedState.view) {
                return { ...baseState, view: rehydratedState.view, screen: newScreen };
            }
            if (newScreen !== 'Custom') {
                return { ...baseState, screen: newScreen, view: null };
            }
            return baseState;
        },
        ...OpsInboxActions.SET_SCREEN.reducer<InboxState>((state, action) => {
            if (!isInboxScreen(action.payload)) {
                const savedView = state.savedViews[action.payload];
                if (!savedView || _.isEqual(state.view, savedView)) {
                    return state;
                }
                const view: ActiveInboxCustomView = { ...savedView, priorScreen: getPriorScreen(state) };
                return {
                    ...state,
                    view,
                    screen: 'Custom',
                    history: { ...state.history, [state.screen]: new Date().valueOf() },
                    selectedTaskIds: [],
                };
            }
            return {
                ...state,
                screen: action.payload,
                history: { ...state.history, [state.screen]: new Date().valueOf() },
                view: null,
                selectedTaskIds: [],
            };
        }),
        ...OpsInboxActions.SEARCH_ON_BLUR.reducer<InboxState>(state => state),
        ...OpsInboxActions.SET_SEARCH.reducer<InboxState>((state, { payload: newSearch }) =>
            setCurrentCustomView(state, { search: newSearch }),
        ),
        ...OpsInboxActions.SET_ORDER_CRITERIA.reducer<InboxState>((state, { payload: criteria }) =>
            setCurrentCustomView(state, { ordersCriteria: criteria }),
        ),
        ...OpsInboxActions.CLEAR_ORDER_CRITERIA.reducer<InboxState>(state =>
            setCurrentCustomView(state, { ordersCriteria: undefined }),
        ),
        ...OpsInboxActions.SET_TASK_FILTER.reducer<InboxState>((state, { payload: filter }) =>
            setCurrentCustomView(state, { tasksFilter: filter }),
        ),
        ...OpsInboxActions.CLEAR_TASK_FILTER.reducer<InboxState>(state => {
            if (state.screen !== 'Custom') {
                return state;
            }
            if (!!state.view?.ordersCriteria || !!state.view?.search) {
                return { ...state, view: { ...state.view, tasksFilter: undefined } };
            }
            const newScreen = state.view?.priorScreen ?? 'AssignedToMe';
            return {
                ...state,
                view: null,
                screen: newScreen,
                history: { ...state.history, [newScreen]: new Date().valueOf() },
            };
        }),
        ...OpsInboxActions.TOGGLE_SORT_DIR.reducer<InboxState>(state => {
            const currentSort = getSortForNonCustom(state.screen);
            const newView = state.view
                ? { sort: { ...state.view.sort, asc: !state.view.sort.asc } }
                : { sort: { key: currentSort.key, asc: !currentSort.asc } };
            return setCurrentCustomView(state, newView);
        }),
        ...OpsInboxActions.SORT_KEY_CLICKED.reducer<InboxState>((state, { payload: sortKey }) => {
            const currentSort = getSortForNonCustom(state.screen);
            const newView = state.view
                ? { sort: { ...state.view.sort, key: sortKey } }
                : { sort: { key: sortKey, asc: currentSort.asc } };
            return setCurrentCustomView(state, newView);
        }),
        ...OpsInboxActions.SAVE_VIEW.reducer<InboxState>((state, { payload: { id, title, visibility } }) => {
            // do not update state if new view was not added to DB
            if (!id) {
                return state;
            }
            const savedViewMatch = state.savedViews[id];
            const currentView = (id === state.view?.id || state.view?.id === UNSAVED_VIEW_ID) && state.view;
            const view = currentView || savedViewMatch;
            if (!view) {
                return state;
            }
            const viewToSave = {
                ...view,
                title,
                visibility,
                favoritedCount: view.id === UNSAVED_VIEW_ID ? 1 : view.favoritedCount,
                id: view.id === UNSAVED_VIEW_ID ? id : view.id,
                // mark as created by user if it is a new view (from unsaved_view_id) or was already created by user
                createdByUser: view.id === UNSAVED_VIEW_ID || view.createdByUser,
            };
            const newState: InboxState = {
                ...state,
                savedViews: Object.assign({ ...state.savedViews }, { [viewToSave.id]: viewToSave }),
            };
            return setCurrentCustomView(newState, viewToSave);
        }),
        ...OpsInboxActions.SET_EDITING_VIEW_ID.reducer<InboxState>((state, action) => ({
            ...state,
            editingViewId: action.payload,
        })),
        ...OpsInboxActions.DELETE_VIEW.reducer<InboxState>((state, action) => {
            const newState = { ...state, savedViews: _.omit(state.savedViews, action.payload) };
            // we are deleting the currently active view, update the current view to be unsaved
            if (state.view?.id === action.payload) {
                return setCurrentCustomView(newState, { id: UNSAVED_VIEW_ID, title: 'Unsaved view' });
            }
            return newState;
        }),
        ...OpsInboxActions.COPY_VIEW.reducer<InboxState>((state, { payload: rootView }) => {
            const duplicatedView: InboxCustomView = {
                ...rootView,
                title: `${rootView.title} COPY`,
                id: UNSAVED_VIEW_ID,
                favoritedCount: 1,
                createdByUsername: undefined,
            };
            return setCurrentCustomView({ ...state, editingViewId: duplicatedView.id }, duplicatedView);
        }),
        ...OpsInboxActions.LOAD_VIEWS.reducer<InboxState>((state, action) => {
            const newSavedViews = _.fromPairs(action.payload.map(view => [view.id, view]));
            const newState = { ...state, savedViews: newSavedViews };
            if (state.view?.id) {
                // if current view has been deleted in the db, update the current view to be unsaved
                if (!_.keys(newSavedViews).includes(state.view.id)) {
                    return setCurrentCustomView(newState, { id: UNSAVED_VIEW_ID, title: 'Unsaved view' });
                }
                const viewUpdate = newSavedViews[state.view.id];
                return viewUpdate ? setCurrentCustomView(newState, viewUpdate) : newState;
            }
            return newState;
        }),
        ...OpsInboxActions.TOGGLE_FAVORITE_VIEW.reducer<InboxState>((state, action) => {
            const { view, searchId } = action.payload;
            return SavedViewReducerUtils.toggleFavorite({ state, searchId, setCurrentCustomView, view });
        }),
        ...OpsInboxActions.TOGGLE_VIEW_VISIBILITY.reducer<InboxState>((state, action) => {
            return SavedViewReducerUtils.toggleViewVisibility<InboxState>(
                state,
                action.payload.searchId,
                action.payload.newVisibility,
            );
        }),
        ...OpsInboxActions.SET_SELECTED_TASK_IDS.reducer<InboxState>((state, { payload: taskIds }) => ({
            ...state,
            selectedTaskIds: taskIds,
        })),
        ...OpsInboxActions.TOGGLE_TASK_SELECTED.reducer<InboxState>((state, { payload: taskId }) => ({
            ...state,
            selectedTaskIds: state.selectedTaskIds.includes(taskId)
                ? state.selectedTaskIds.filter(id => id !== taskId)
                : [...state.selectedTaskIds, taskId],
        })),
    },
    getInitialState(),
);
