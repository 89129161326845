import type { LabsGqlDesignReviewFieldFragment } from '@orthly/graphql-operations';
import { useCreateDesignReviewFieldMutation, useUpdateDesignReviewFieldMutation } from '@orthly/graphql-react';
import type {
    LabsGqlCreateDesignReviewFieldCommand,
    LabsGqlUpdateDesignReviewFieldCommand,
} from '@orthly/graphql-schema';
import {
    LabsGqlCustomFieldType,
    LabsGqlDesignReviewFieldFilterType,
    LabsGqlDesignReviewFieldType,
} from '@orthly/graphql-schema';
import type { FieldsDefProp } from '@orthly/ui';
import { LoadBlocker, QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

type CreateVars = LabsGqlCreateDesignReviewFieldCommand;
type CreateFormFields = CreateVars;
type UpdateVars = LabsGqlUpdateDesignReviewFieldCommand;
type UpdateFormFields = Omit<UpdateVars, 'design_field_id'>;

function useDesignReviewFormFields(fieldType: LabsGqlCustomFieldType) {
    return React.useMemo(() => {
        const createFields: FieldsDefProp<CreateFormFields> = {
            types: {
                type: 'multiselect' as const,
                options: Object.values(LabsGqlDesignReviewFieldType),
                validation: z.array(z.string()).min(1),
            },
            name: { type: 'text' as const },
            category: { type: 'text' as const },
            required: { type: 'boolean', label: 'Field required when section selected' },
            applicable_order_types: {
                type: 'multiselect',
                label: 'Display Criteria',
                optional: true,
                options: [
                    {
                        value: LabsGqlDesignReviewFieldFilterType.Posterior,
                        label: 'Posterior units (including inlays)',
                    },
                    { value: LabsGqlDesignReviewFieldFilterType.Anterior, label: 'Anterior units' },
                    { value: LabsGqlDesignReviewFieldFilterType.Upper, label: 'Upper' },
                    { value: LabsGqlDesignReviewFieldFilterType.Lower, label: 'Lower' },
                    { value: LabsGqlDesignReviewFieldFilterType.Implant, label: 'Implant' },
                    { value: LabsGqlDesignReviewFieldFilterType.Aligner, label: 'Aligner' },
                    { value: LabsGqlDesignReviewFieldFilterType.FullDenture, label: 'Full Denture' },
                    { value: LabsGqlDesignReviewFieldFilterType.Model, label: 'Model' },
                    { value: LabsGqlDesignReviewFieldFilterType.NightGuard, label: 'Night Guard' },
                    {
                        value: LabsGqlDesignReviewFieldFilterType.PosteriorBridge,
                        label: 'Posterior bridge (non-implant)',
                    },
                    {
                        value: LabsGqlDesignReviewFieldFilterType.AnteriorBridge,
                        label: 'Anterior bridge (non-implant)',
                    },
                    { value: LabsGqlDesignReviewFieldFilterType.PosteriorImplant, label: 'Posterior implant' },
                    { value: LabsGqlDesignReviewFieldFilterType.AnteriorImplant, label: 'Anterior implant' },
                    {
                        value: LabsGqlDesignReviewFieldFilterType.PosteriorImplantBridge,
                        label: 'Posterior implant bridge',
                    },
                    {
                        value: LabsGqlDesignReviewFieldFilterType.AnteriorImplantBridge,
                        label: 'Anterior implant bridge',
                    },
                ],
            },
            field: {
                type: 'nested',
                label: 'Designer Input Field',
                fields: {
                    type: {
                        type: 'select',
                        label: 'Field Type',
                        options: [
                            { value: LabsGqlCustomFieldType.Text, label: 'Text' },
                            { value: LabsGqlCustomFieldType.Number, label: 'Number' },
                            { value: LabsGqlCustomFieldType.Boolean, label: 'Checkbox' },
                            { value: LabsGqlCustomFieldType.Select, label: 'Select Drop-down' },
                        ],
                    },
                    options: {
                        type: 'array',
                        hidden: fieldType !== LabsGqlCustomFieldType.Select,
                        label: 'Options',
                        elementName: 'Option',
                        of: {
                            type: 'nested',
                            label: 'Option',
                            fields: {
                                value: { type: 'text', layout: { xs: 6 } },
                                label: {
                                    type: 'text',
                                    layout: { xs: 6 },
                                    optional: true,
                                    helperText: 'Option that shows in the dropdown (defaults to value)',
                                    fieldProps: {
                                        InputLabelProps: { shrink: true },
                                        tabIndex: -1,
                                        inputProps: { tabIndex: -1 },
                                    },
                                },
                            },
                        },
                        min: fieldType === LabsGqlCustomFieldType.Select ? 1 : undefined,
                    },
                    default_value: {
                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                        // eslint-disable-next-line no-nested-ternary
                        type: [LabsGqlCustomFieldType.Text, LabsGqlCustomFieldType.Select].includes(fieldType)
                            ? 'text'
                            : fieldType === LabsGqlCustomFieldType.Boolean
                              ? 'boolean'
                              : 'number',
                        label:
                            fieldType === LabsGqlCustomFieldType.Boolean
                                ? 'Checked when section not selected'
                                : 'Default value when section not selected',
                        optional: true,
                    },
                },
            },
        };
        const updateFields: FieldsDefProp<UpdateFormFields> = { archived: { type: 'boolean' }, ...createFields };
        return { updateFields, createFields };
    }, [fieldType]);
}

interface CreateDesignReviewFieldProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onSuccess: () => Promise<void>;
}

export const CreateDesignReviewFieldForm: React.FC<CreateDesignReviewFieldProps> = props => {
    const [submitMtn] = useCreateDesignReviewFieldMutation();
    const mtnSubmitter = (data: CreateVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [CreateVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Design review field created!', {}],
        onSuccess: async () => {
            await props.onSuccess();
        },
    });
    const [fieldType, setFieldType] = React.useState<LabsGqlCustomFieldType>(LabsGqlCustomFieldType.Text);
    const { createFields: formFields } = useDesignReviewFormFields(fieldType);
    return (
        <RootActionDialog
            open={props.open}
            loading={submitting}
            title={'Create Design Review Field'}
            setOpen={props.setOpen}
            buttonText={'Create Design Review Field'}
            CustomButton={() => null}
            content={
                <Grid container>
                    <QuickForm<CreateVars>
                        fields={formFields}
                        initialValues={{
                            types: [LabsGqlDesignReviewFieldType.DesignReview],
                            name: '',
                            category: '',
                            field: {
                                type: fieldType,
                                default_value: '',
                            },
                            required: false,
                            applicable_order_types: [],
                        }}
                        onChange={(change, formik) => {
                            const type = change.field?.type;
                            // On updates to the type, we update the subform so that we can collect potentially a new data type.
                            if (type && type !== fieldType) {
                                setFieldType(type);
                                formik.setFieldValue('field', { ...change.field, default_value: undefined });
                            }
                        }}
                        onSubmit={async formResult => {
                            await submit(formResult);
                        }}
                    />
                </Grid>
            }
        />
    );
};

interface EditDesignReviewFieldProps {
    field: LabsGqlDesignReviewFieldFragment;
    onSuccess: () => Promise<void>;
}

export const EditDesignReviewFieldForm: React.FC<EditDesignReviewFieldProps> = props => {
    const { field } = props;
    const [submitMtn] = useUpdateDesignReviewFieldMutation();
    const mtnSubmitter = (data: UpdateVars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit, submitting } = useChangeSubmissionFn<any, [UpdateVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Design field updated!', {}],
        onSuccess: async () => {
            await props.onSuccess();
        },
    });
    const [fieldType, setFieldType] = React.useState<LabsGqlCustomFieldType>(field.field.type);
    const { updateFields: formFields } = useDesignReviewFormFields(fieldType);
    return (
        <LoadBlocker blocking={submitting}>
            <Grid container style={{ padding: '10px 0' }}>
                <Text variant={'h6'}>Edit Review Field</Text>
            </Grid>
            <QuickForm<Omit<UpdateVars, 'design_field_id'>>
                dirtySubmitOnly
                fields={formFields}
                initialValues={{
                    types: field.types,
                    name: field.name,
                    category: field.category,
                    required: field.required,
                    field: {
                        type: field.field.type,
                        default_value: field.field.default_value,
                        options: field.field.options,
                    },
                    archived: field.archived,
                    applicable_order_types: field.applicable_order_types,
                }}
                onChange={(change, formik) => {
                    const type = change.field?.type;
                    // On updates to the type, we update the subform so that we can collect potentially a new data type.
                    if (type && type !== fieldType) {
                        setFieldType(type);
                        formik.setFieldValue('field', { ...change.field, default_value: undefined });
                    }
                }}
                onSubmit={async formResult => {
                    const command: UpdateVars = {
                        ...formResult,
                        field: {
                            ...formResult.field,
                            // Picks just the value and label so that we avoid selecting potentially bad fields like __typename
                            options: formResult.field.options?.map(option => _.pick(option, ['label', 'value'])),
                        },
                        design_field_id: field.id,
                    };
                    await submit(command);
                }}
            />
        </LoadBlocker>
    );
};
