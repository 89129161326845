import type { QcHeatmapRange } from '../ColorRamp';
import type { ScanReviewInsertionAxis } from './ScanReviewDesignTypes';
import type { ScanReviewRecord, ScanReviewRecordsFactory } from './ScanReviewRecordTypes';
import { ScanReviewDisplayType, ScanReviewPanelType, type ScanReviewThreeViewProvider } from './ScanReviewViewTypes';
import { Jaw } from '@orthly/shared-types';
import * as THREE from 'three';

export interface ScanReviewScene {
    setUpperJawVisibility(visible: boolean): void;
    setLowerJawVisibility(visible: boolean): void;
    setDisplayType(displayType: ScanReviewDisplayType, heatmapRange: QcHeatmapRange | null): void;
    updateHeatmapRange(newHeatmapRange: QcHeatmapRange): void;
    updateUndercutHeatmapRange(newHeatmapRange: QcHeatmapRange): void;
    get upperJawInScene(): boolean;
    get lowerJawInScene(): boolean;
}
export class ScanReviewPartialScene implements ScanReviewScene, ScanReviewThreeViewProvider {
    scene: THREE.Scene;

    constructor(
        public upperJaw: ScanReviewRecord | null,
        public lowerJaw: ScanReviewRecord | null,
    ) {
        this.scene = new THREE.Scene();
        if (upperJaw) {
            this.scene.add(upperJaw.scanMesh);
        }
        if (lowerJaw) {
            this.scene.add(lowerJaw.scanMesh);
        }
    }

    get upperJawInScene(): boolean {
        return this.upperJaw !== null;
    }
    get lowerJawInScene(): boolean {
        return this.lowerJaw !== null;
    }

    setDisplayType(displayType: ScanReviewDisplayType, heatmapRange: QcHeatmapRange | null): void {
        if (displayType === ScanReviewDisplayType.Scan) {
            this.setScanDisplay();
        } else if (displayType === ScanReviewDisplayType.StoneModel) {
            this.setStoneModelDisplay();
        } else if (displayType === ScanReviewDisplayType.ScanUndercut) {
            this.setUndercutDisplay();
        } else if (displayType === ScanReviewDisplayType.StoneModelUndercut) {
            this.setStoneUndercutDisplay();
        } else if (displayType === ScanReviewDisplayType.BiteAnalysis) {
            this.setHeatMapDisplay();
            if (heatmapRange) {
                this.updateHeatmapRange(heatmapRange);
            }
        } else if (displayType === ScanReviewDisplayType.Segmentation) {
            this.setSegmentationDisplay();
        }
    }

    setUpperJawVisibility(visible: boolean) {
        this.upperJaw?.setVisible(visible);
    }

    setLowerJawVisibility(visible: boolean) {
        this.lowerJaw?.setVisible(visible);
    }

    setStoneModelDisplay() {
        this.upperJaw?.setStoneModelDisplay();
        this.lowerJaw?.setStoneModelDisplay();
    }

    setSegmentationDisplay() {
        this.upperJaw?.setSegmentationDisplay();
        this.lowerJaw?.setSegmentationDisplay();
    }

    setUndercutDisplay() {
        this.upperJaw?.setUndercutDisplay();
        this.lowerJaw?.setUndercutDisplay();
    }

    setStoneUndercutDisplay() {
        this.upperJaw?.setStoneUndercutDisplay();
        this.lowerJaw?.setStoneUndercutDisplay();
    }

    updateUndercutDisplay(jaw: Jaw, insertionAxis: ScanReviewInsertionAxis) {
        if (jaw === Jaw.UPPER) {
            this.upperJaw?.updateUndercutDisplay(insertionAxis);
        } else {
            this.lowerJaw?.updateUndercutDisplay(insertionAxis);
        }
    }

    setScanDisplay(): void {
        this.upperJaw?.setScanDisplay();
        this.lowerJaw?.setScanDisplay();
    }

    setHeatMapDisplay() {
        this.upperJaw?.setHeatMapDisplay();
        this.lowerJaw?.setHeatMapDisplay();
    }

    updateHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        this.upperJaw?.updateHeatmapRange(newHeatmapRange);
        this.lowerJaw?.updateHeatmapRange(newHeatmapRange);
    }

    updateUndercutHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        this.upperJaw?.updateUndercutHeatmapRange?.(newHeatmapRange);
        this.lowerJaw?.updateUndercutHeatmapRange?.(newHeatmapRange);
    }

    setUpperJawLabels(labels: number[]) {
        this.upperJaw?.setLabels(labels);
    }

    setLowerJawLabels(labels: number[]) {
        this.lowerJaw?.setLabels(labels);
    }
}

export class ScanReviewCompositeScene implements ScanReviewScene {
    private readonly upperView: ScanReviewPartialScene;
    private readonly lowerView: ScanReviewPartialScene;
    private readonly leftView: ScanReviewPartialScene;
    private readonly rightView: ScanReviewPartialScene;
    private readonly frontView: ScanReviewPartialScene;

    constructor(recordsFactory: ScanReviewRecordsFactory) {
        this.upperView = new ScanReviewPartialScene(recordsFactory()?.upperJaw, null);
        this.lowerView = new ScanReviewPartialScene(null, recordsFactory()?.lowerJaw);
        this.leftView = new ScanReviewPartialScene(recordsFactory().upperJaw, recordsFactory()?.lowerJaw);
        this.rightView = new ScanReviewPartialScene(recordsFactory().upperJaw, recordsFactory()?.lowerJaw);
        this.frontView = new ScanReviewPartialScene(recordsFactory().upperJaw, recordsFactory()?.lowerJaw);
    }

    get upperJawInScene(): boolean {
        return this.frontView.upperJawInScene;
    }
    get lowerJawInScene(): boolean {
        return this.frontView.lowerJawInScene;
    }

    private *partialScenes() {
        for (const partialScene of [this.upperView, this.lowerView, this.leftView, this.rightView, this.frontView]) {
            yield partialScene;
        }
    }

    getPartialSceneForPanelType(panelType: ScanReviewPanelType): ScanReviewPartialScene {
        switch (panelType) {
            case ScanReviewPanelType.Upper: {
                return this.upperView;
            }
            case ScanReviewPanelType.Lower: {
                return this.lowerView;
            }
            case ScanReviewPanelType.Left: {
                return this.leftView;
            }
            case ScanReviewPanelType.Right: {
                return this.rightView;
            }
            case ScanReviewPanelType.Front: {
                return this.frontView;
            }
        }
    }

    setUpperJawVisibility(visible: boolean): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.setUpperJawVisibility(visible);
        }
    }
    setLowerJawVisibility(visible: boolean): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.setLowerJawVisibility(visible);
        }
    }

    setDisplayType(displayType: ScanReviewDisplayType, heatmapRange: QcHeatmapRange | null): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.setDisplayType(displayType, heatmapRange);
        }
    }

    updateHeatmapRange(newHeatmapRange: QcHeatmapRange): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.updateHeatmapRange(newHeatmapRange);
        }
    }

    updateUndercutHeatmapRange(newHeatmapRange: QcHeatmapRange): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.updateUndercutHeatmapRange(newHeatmapRange);
        }
    }

    updateUndercutDisplay(jaw: Jaw, insertionAxis: ScanReviewInsertionAxis): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.updateUndercutDisplay(jaw, insertionAxis);
        }
    }

    setUpperJawLabels(labels: number[]) {
        for (const partialScene of this.partialScenes()) {
            partialScene.setUpperJawLabels(labels);
        }
    }

    setLowerJawLabels(labels: number[]) {
        for (const partialScene of this.partialScenes()) {
            partialScene.setLowerJawLabels(labels);
        }
    }
}
