import type { LabsGqlLabPriceLabPricesResultFragment } from '@orthly/graphql-operations';
import {
    useListLabOrgs,
    useGetLabPriceLabPricesQuery,
    useConfigureLabPriceLabPricesMutation,
    useBulkUpsertLabPriceLabPricesMutation,
} from '@orthly/graphql-react';
import type { LabsGqlConfigureLabPriceLabPricesCommand } from '@orthly/graphql-schema';
import { MUITable, downloadAsCsv } from '@orthly/mui-table';
import { QuickForm, useChangeSubmissionFn, RootActionDialog, LoadBlocker } from '@orthly/ui';
import { Button, Collapse, Grid } from '@orthly/ui-primitives';
import { SimpleDropzone } from '@orthly/veneer';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import React from 'react';
import { z } from 'zod';

function useListLabs(): Record<string, string> {
    const { data } = useListLabOrgs({ fetchPolicy: 'cache-first' });
    return (data?.listOrganizations ?? []).reduce((acc, org) => ({ ...acc, [org.id]: org.name }), {
        default: 'Default',
    });
}

function priceConfigsToFormState(configs: LabsGqlLabPriceLabPricesResultFragment['lab_configs']) {
    return configs.map(config => ({
        lab_id: config.lab_id ?? 'default',
        price_dollars: config.price_cents / 100,
    }));
}

function priceConfigsFromFormState(
    state: { lab_id: string; price_dollars: number }[],
): LabsGqlLabPriceLabPricesResultFragment['lab_configs'] {
    return state.map(config => ({
        lab_id: config.lab_id === 'default' ? null : config.lab_id,
        price_cents: Math.floor(config.price_dollars * 100),
    }));
}

interface EditLabPricesFormProps {
    price: LabsGqlLabPriceLabPricesResultFragment;
    labOptions: { value: string; label: string }[];
    onSuccess?: () => Promise<void>;
}

const EditLabPricesForm: React.FC<EditLabPricesFormProps> = props => {
    const { price, labOptions, onSuccess } = props;
    const [submitMtn] = useConfigureLabPriceLabPricesMutation();
    const mtnSubmitter = (data: LabsGqlConfigureLabPriceLabPricesCommand) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit } = useChangeSubmissionFn<any, [LabsGqlConfigureLabPriceLabPricesCommand]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Lab prices configured!', {}],
        onSuccess: async () => {
            if (onSuccess) {
                await onSuccess();
            }
        },
    });
    return (
        <QuickForm<{ lab_configs: { lab_id: string; price_dollars: number }[] }>
            fields={{
                lab_configs: {
                    type: 'array',
                    of: {
                        type: 'nested',
                        fields: {
                            lab_id: { type: 'select', label: 'Lab', options: labOptions },
                            price_dollars: {
                                type: 'number',
                                label: 'Price',
                                validation: z.number().min(0),
                                fieldProps: { InputProps: { startAdornment: '$' } },
                            },
                        },
                    },
                },
            }}
            initialValues={price ? { lab_configs: priceConfigsToFormState(price.lab_configs) } : {}}
            onSubmit={async result => {
                await submit({
                    lab_price_id: price.id,
                    lab_configs: priceConfigsFromFormState(result.lab_configs),
                });
            }}
        />
    );
};

interface LabPriceDetailPanelProps {
    price: LabsGqlLabPriceLabPricesResultFragment;
    labOptions: { value: string; label: string }[];
}

const LabPriceDetailPanel: React.FC<LabPriceDetailPanelProps> = props => {
    const { price, labOptions } = props;
    return <EditLabPricesForm price={price} labOptions={labOptions} />;
};

interface LabPriceCsvRow {
    price_id: string;
    lab_id: string;
    price_name: string;
    lab_name: string;
    price_dollars: number;
}

function priceTableToCsvRows(
    prices: LabsGqlLabPriceLabPricesResultFragment[],
    labs: Record<string, string>,
): LabPriceCsvRow[] {
    return _.orderBy(
        prices.flatMap(price => {
            const { lab_configs, id: price_id, name: price_name } = price;
            return _.compact(
                lab_configs.map(lab_config => {
                    const { lab_id, price_cents } = lab_config;
                    if (!lab_id) {
                        // This would hypothetically represent a "default" price, which we don't actually use,
                        // so I'm going to ignore it here
                        return null;
                    }
                    return {
                        price_id,
                        lab_id,
                        price_name,
                        lab_name: labs[lab_id] ?? '',
                        price_dollars: +(price_cents / 100).toFixed(2),
                    };
                }),
            );
        }),
        [row => row.lab_name, row => row.price_name],
    );
}

interface UseCsvRowsArgs {
    checkColumns: (columns: string[]) => boolean;
    onValid: (rows: Record<string, string>[]) => void;
}

function useCsvRows(args: UseCsvRowsArgs) {
    const { checkColumns, onValid } = args;
    return (files: File[]) => {
        if (!files[0]) {
            return;
        }

        Papa.parse(files[0], {
            skipEmptyLines: true,
            complete: results => {
                const rawRows = results.data as string[][];

                if (_.isEmpty(rawRows)) {
                    window.alert("The uploaded file doesn't contain any rows.");
                    return;
                }

                const [titleRow, ...dataRows] = rawRows;
                if (!(titleRow && checkColumns(titleRow))) {
                    return;
                }

                const entries = dataRows.map(row => _.zipObject(titleRow, row));

                onValid(entries);
            },
        });
    };
}

interface LabPriceImportProps {
    loading: boolean;
    refetch: () => Promise<void>;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const LabPriceImport: React.FC<LabPriceImportProps> = props => {
    const [submitMtn, { loading, data: mtnResult }] = useBulkUpsertLabPriceLabPricesMutation();
    const { enqueueSnackbar } = useSnackbar();

    const [inputRows, setInputRows] = React.useState<LabPriceCsvRow[] | undefined>(undefined);

    const onDropAccepted = useCsvRows({
        checkColumns: columns => {
            const requiredColumns = ['price_id', 'price_name', 'lab_id', 'lab_name', 'price_dollars'];
            if (!requiredColumns.every(c => columns.includes(c))) {
                window.alert('Required columns missing');
                return false;
            }
            return true;
        },
        onValid: (rows: Record<string, string>[]) => {
            setInputRows(
                rows
                    .filter(row => typeof row.price_id === 'string' && row.price_id !== '')
                    .map(row => ({
                        price_id: row.price_id ?? '',
                        price_name: row.price_name ?? '',
                        lab_id: row.lab_id ?? '',
                        lab_name: row.lab_name ?? '',
                        price_dollars: parseFloat(row.price_dollars ?? ''),
                    })),
            );
        },
    });

    async function onSubmit() {
        if (!inputRows || loading) {
            return;
        }
        const upserts = _.compact(
            inputRows.map(r => ({
                lab_price_id: r.price_id,
                lab_id: r.lab_id,
                price_cents: Math.round(r.price_dollars * 100),
            })),
        );
        await submitMtn({ variables: { data: { upserts } } });
        await props.refetch();
        setInputRows(undefined);
    }

    React.useEffect(() => {
        if (!inputRows && !!mtnResult?.bulkUpsertLabPriceLabPrices) {
            enqueueSnackbar('Lab prices submitted successfully!', { variant: 'success' });
        }
    }, [inputRows, mtnResult, enqueueSnackbar]);

    return (
        <LoadBlocker blocking={loading || props.loading}>
            <Grid container style={{ padding: '10px 30px', display: inputRows ? 'none' : undefined }}>
                <Grid>
                    Instructions:
                    <ul>
                        <li>Use "download" to download a list of the current order prices</li>
                        <li>Delete any rows you don't intend to modify</li>
                        <li>Update prices as necessary</li>
                        <li>
                            Do <b>not</b> modify or remove any other columns
                        </li>
                        <li>Upload the result here</li>
                    </ul>
                </Grid>
                <SimpleDropzone
                    preUploadText={'Import Updated Lab Prices (click or drop file)'}
                    wrapperStyle={{ minHeight: 40, padding: 0 }}
                    options={{ onDropAccepted, multiple: false }}
                />
            </Grid>
            <Grid container alignItems={'center'} wrap={'nowrap'} style={{ padding: 20 }}>
                <Grid container>
                    <Collapse in={!!inputRows} style={{ width: '100% ' }}>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                onSubmit().catch(console.error);
                            }}
                            startIcon={'CloudUploadIcon'}
                        >
                            Upload Import
                        </Button>
                    </Collapse>
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};

export const LabPriceImportDialog: React.FC<LabPriceImportProps> = props => {
    return (
        <RootActionDialog
            loading={props.loading}
            open={props.open}
            setOpen={props.setOpen}
            title={'Upload Prices'}
            content={
                <LabPriceImport
                    loading={props.loading}
                    refetch={props.refetch}
                    open={props.open}
                    setOpen={props.setOpen}
                />
            }
            CustomButton={() => (
                <Button variant={'contained'} onClick={() => props.setOpen(true)}>
                    Upload Prices
                </Button>
            )}
        />
    );
};

export const LabPricesTable: React.VFC = () => {
    const { data, loading, refetch } = useGetLabPriceLabPricesQuery();
    const prices = data?.getLabPriceLabPrices ?? [];
    const labOrgs = useListLabs();
    const labOptions = Object.entries(labOrgs).map(([id, name]) => ({ value: id, label: name }));
    const [uploadModalOpen, setUploadModalOpen] = React.useState<boolean>(false);
    return (
        <Grid container>
            <LoadBlocker blocking={loading}>
                <Grid item>
                    <LabPriceImportDialog
                        loading={loading}
                        refetch={async () => {
                            await refetch();
                        }}
                        open={uploadModalOpen}
                        setOpen={setUploadModalOpen}
                    />
                </Grid>
                <MUITable<LabsGqlLabPriceLabPricesResultFragment>
                    title={'Lab Prices'}
                    data={prices}
                    loading={loading}
                    displayOptions={{ fixedSearch: true, elevation: 0, viewColumns: true, filter: true, sort: true }}
                    actions={{
                        global: [
                            { icon: 'refresh', position: 'toolbar', onClick: () => refetch().catch(console.error) },
                            {
                                tooltip: 'Export',
                                icon: 'save_alt',
                                position: 'toolbar',
                                onClick: () => {
                                    downloadAsCsv(priceTableToCsvRows(prices, labOrgs), 'Lab Prices Export');
                                },
                            },
                        ],
                    }}
                    rowOptions={{ rowHover: true }}
                    eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                    columns={[
                        { name: 'Name', field: 'name', render: row => row.name, defaultSort: 'asc' },
                        {
                            name: 'Labs Configured',
                            field: 'configs',
                            render: row => row.lab_configs.length,
                        },
                    ]}
                    DetailPanel={detailProps => (
                        <LabPriceDetailPanel price={detailProps.data} labOptions={labOptions} />
                    )}
                />
            </LoadBlocker>
        </Grid>
    );
};
