import { ScanReviewMarginMarkingToolController, ScanReviewMarginMarkingToolLiveObjectsProvider } from './MarginMarking';
import { ScanReviewInsertionAxisLiveObjectProvider } from './ScanReview.utils';
import type { ScanReviewAppCallbacks, ScanReviewAppState } from './ScanReviewApp.types';
import type { ScanReviewInsertionAxis, ScanReviewMarginLine } from './ScanReviewDesignTypes';
import type { ScanReviewCompositeScene } from './ScanReviewSceneTypes';
import type { ScanReviewCompositeViewManager } from './ScanReviewViewTypes';
import { ScanReviewPanelType, ScanReviewMode, DEFAULT_UNDERCUT_SHADING_RADIUS } from './ScanReviewViewTypes';
import { ScanReviewUndercutToolController } from './Undercut';
import { getCentroidAndMaxDistance } from '@orthly/forceps';
import { ToothUtils } from '@orthly/items';
import { Jaw } from '@orthly/shared-types';
import { MeshBVH } from 'three-mesh-bvh';

const ALL_PANEL_TYPES = [
    ScanReviewPanelType.Front,
    ScanReviewPanelType.Left,
    ScanReviewPanelType.Right,
    ScanReviewPanelType.Lower,
    ScanReviewPanelType.Upper,
];

export class ScanReviewToolControllersFactory {
    constructor(
        private readonly scene: ScanReviewCompositeScene,
        private readonly viewManager: ScanReviewCompositeViewManager,
        private readonly state: ScanReviewAppState,
        private readonly callbacks: ScanReviewAppCallbacks,
        private readonly bvhCache: Map<string, MeshBVH>,
    ) {}

    updateUndercutToolControllers() {
        const {
            undercutToolControllers,
            toothNumber,
            mode,
            insertionAxes,
            insertionAxisLiveObjectProviders,
            currentMarginLineEditor,
        } = this.state;
        undercutToolControllers.clear();
        if (toothNumber === undefined || mode !== ScanReviewMode.Undercut) {
            return;
        }

        const insertionAxis = insertionAxes.get(toothNumber);
        if (currentMarginLineEditor === undefined || insertionAxis === undefined) {
            return;
        }

        const jaw = ToothUtils.toothIsLower(toothNumber) ? Jaw.LOWER : Jaw.UPPER;
        for (const panelType of ALL_PANEL_TYPES) {
            const insertionAxisLiveObjectsProvider = insertionAxisLiveObjectProviders.get(panelType);
            const partialScene = this.scene.getPartialSceneForPanelType(panelType);
            const scanRecord = jaw === Jaw.LOWER ? partialScene.lowerJaw : partialScene.upperJaw;
            const viewManager = this.viewManager.getViewManagerForPanelType(panelType);
            if (scanRecord === null || viewManager === undefined || insertionAxisLiveObjectsProvider === undefined) {
                continue;
            }
            this.state.undercutToolControllers.set(
                panelType,
                new ScanReviewUndercutToolController(
                    this.getBvh(scanRecord.scanMesh.geometry),
                    currentMarginLineEditor,
                    insertionAxis,
                    viewManager,
                    partialScene,
                    insertionAxisLiveObjectsProvider,
                    this.getOnInsertionAxisUpdateProxy(),
                    true,
                ),
            );
        }
    }

    updateMarginMarkingToolControllers() {
        const {
            toothNumber,
            mode,
            marginLineToolControllers,
            marginLineLiveObjectProviders,
            insertionAxisLiveObjectProviders,
            currentMarginLineEditor,
            currentInsertionAxis,
            tubeMarginLinesEnabled,
        } = this.state;
        marginLineToolControllers.clear();
        if (toothNumber === undefined || mode !== ScanReviewMode.MarginMarking) {
            return;
        }

        if (currentMarginLineEditor === undefined || currentInsertionAxis === undefined) {
            return;
        }

        const jaw = ToothUtils.toothIsLower(toothNumber) ? Jaw.LOWER : Jaw.UPPER;
        for (const panelType of ALL_PANEL_TYPES) {
            const marginLineLiveObjectsProvider = marginLineLiveObjectProviders.get(panelType);
            const insertionAxisLiveObjectsProvider = insertionAxisLiveObjectProviders.get(panelType);
            const partialScene = this.scene.getPartialSceneForPanelType(panelType);
            const scanRecord = jaw === Jaw.LOWER ? partialScene.lowerJaw : partialScene.upperJaw;
            const viewManager = this.viewManager.getViewManagerForPanelType(panelType);
            if (
                scanRecord === null ||
                marginLineLiveObjectsProvider === undefined ||
                insertionAxisLiveObjectsProvider === undefined
            ) {
                continue;
            }

            marginLineToolControllers.set(
                panelType,
                new ScanReviewMarginMarkingToolController(
                    viewManager,
                    currentMarginLineEditor,
                    marginLineLiveObjectsProvider,
                    this.getOnMarginLineUpdateProxy(marginLineLiveObjectsProvider, insertionAxisLiveObjectsProvider),
                    tubeMarginLinesEnabled,
                ),
            );
        }
    }

    private getOnMarginLineUpdateProxy(
        marginLineLiveObjectProvider: ScanReviewMarginMarkingToolLiveObjectsProvider,
        insertionAxisLiveObjectsProvider: ScanReviewInsertionAxisLiveObjectProvider,
    ) {
        const { toothNumber, insertionAxisIsVisible, currentInsertionAxis, tubeMarginLinesEnabled } = this.state;
        const onMarginlineUpdateProxy = (marginLine: ScanReviewMarginLine) => {
            if (!toothNumber) {
                return;
            }

            const marginLineIsInUndercut =
                currentInsertionAxis && insertionAxisIsVisible
                    ? marginLineLiveObjectProvider.isMarginLineInUndercut(currentInsertionAxis)
                    : false;

            if (currentInsertionAxis && marginLine.controlPoints.length) {
                const { centroid, maxDistance } = getCentroidAndMaxDistance(marginLine.controlPoints);
                currentInsertionAxis.position = centroid;
                currentInsertionAxis.maxDistance = maxDistance + DEFAULT_UNDERCUT_SHADING_RADIUS;

                const jaw = ToothUtils.toothIsLower(currentInsertionAxis.unn) ? Jaw.LOWER : Jaw.UPPER;
                this.scene.updateUndercutDisplay(jaw, currentInsertionAxis);
                this.callbacks.onInsertionAxisUpdate?.(currentInsertionAxis, marginLineIsInUndercut);
                insertionAxisLiveObjectsProvider &&
                    insertionAxisLiveObjectsProvider.setInsertionAxisPosition(currentInsertionAxis.position.clone());
            }

            marginLineLiveObjectProvider.updateMarginLine(marginLine);
            if (tubeMarginLinesEnabled) {
                marginLineLiveObjectProvider.updateMeshTube(currentInsertionAxis, insertionAxisIsVisible);
            }
            this.callbacks.onMarginUpdate?.(marginLine, false);
        };
        return onMarginlineUpdateProxy;
    }

    private getOnInsertionAxisUpdateProxy() {
        const onInsertionAxisUpdateProxy = (
            insertionAxis: ScanReviewInsertionAxis,
            marginLineIsInUndercut: boolean,
        ) => {
            const jaw = ToothUtils.toothIsLower(insertionAxis.unn) ? Jaw.LOWER : Jaw.UPPER;
            this.scene.updateUndercutDisplay(jaw, insertionAxis);
            this.callbacks.onInsertionAxisUpdate?.(insertionAxis, marginLineIsInUndercut);
        };
        return onInsertionAxisUpdateProxy;
    }

    private getBvh(geometry: THREE.BufferGeometry) {
        const bvh = this.bvhCache.get(geometry.uuid);
        if (!bvh) {
            const newBvh = new MeshBVH(geometry);
            this.bvhCache.set(geometry.uuid, newBvh);
            return newBvh;
        }
        return bvh;
    }
}

export class ScanReviewLiveObjectProvidersFactory {
    constructor(
        private readonly scene: ScanReviewCompositeScene,
        private readonly state: ScanReviewAppState,
    ) {}

    updateInsertionAxisLiveObjectProviders() {
        const { toothNumber, currentInsertionAxis, insertionAxisLiveObjectProviders } = this.state;
        insertionAxisLiveObjectProviders.clear();

        if (toothNumber === undefined) {
            return;
        }

        if (currentInsertionAxis === undefined) {
            return;
        }

        const jaw = ToothUtils.toothIsLower(toothNumber) ? Jaw.LOWER : Jaw.UPPER;
        for (const panelType of ALL_PANEL_TYPES) {
            const partialScene = this.scene.getPartialSceneForPanelType(panelType);
            const scanRecord = jaw === Jaw.LOWER ? partialScene.lowerJaw : partialScene.upperJaw;
            if (scanRecord === null) {
                continue;
            }
            insertionAxisLiveObjectProviders.set(
                panelType,
                new ScanReviewInsertionAxisLiveObjectProvider(currentInsertionAxis),
            );
        }
    }

    updateMarginLineLiveObjectProviders() {
        const {
            tubeMarginLinesEnabled,
            mode,
            toothNumber,
            currentInsertionAxis,
            currentMarginLineEditor,
            insertionAxisIsVisible,
            marginLineLiveObjectProviders,
        } = this.state;
        marginLineLiveObjectProviders.clear();

        if (toothNumber === undefined) {
            return;
        }

        if (currentInsertionAxis === undefined || currentMarginLineEditor === undefined) {
            return;
        }

        const jaw = ToothUtils.toothIsLower(toothNumber) ? Jaw.LOWER : Jaw.UPPER;
        for (const panelType of ALL_PANEL_TYPES) {
            const partialScene = this.scene.getPartialSceneForPanelType(panelType);
            const scanRecord = jaw === Jaw.LOWER ? partialScene.lowerJaw : partialScene.upperJaw;
            if (scanRecord === null) {
                continue;
            }
            const provider = new ScanReviewMarginMarkingToolLiveObjectsProvider(
                scanRecord.scanMesh,
                currentMarginLineEditor,
                true,
                tubeMarginLinesEnabled,
            );
            if (mode !== ScanReviewMode.MarginMarking) {
                provider.setSpheresVisible(false);
                provider.setMarginLineVisibility(true);
            }
            if (tubeMarginLinesEnabled) {
                if (
                    insertionAxisIsVisible &&
                    currentInsertionAxis &&
                    (mode === ScanReviewMode.Undercut || mode === ScanReviewMode.MarginMarking)
                ) {
                    provider.updateMeshTube(currentInsertionAxis, true);
                } else {
                    provider.updateMeshTube(undefined, false);
                }
                provider.setMarginLineVisibility(false);
            }
            marginLineLiveObjectProviders.set(panelType, provider);
        }
    }
}
