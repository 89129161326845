import {
    OrderCompareFactRow,
    OrderCompareFactTitle,
    OrderCompareSectionTitle,
    OrderCompareSectionWrapper,
} from './OrderCompareDisplayComponents';
import type {
    AdminOrderCompareOrderItemsSalesOrder_FragmentFragment,
    FragmentType,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import _ from 'lodash';
import React from 'react';

const AdminOrderCompareOrderItemsSalesOrder_Fragment = graphql(`
    fragment AdminOrderCompareOrderItemsSalesOrder_Fragment on SalesOrderDTO {
        items_full_display_info {
            title
            major_details {
                label
                value
            }
            minor_details {
                label
                value
            }
            shade_details {
                label
                value
            }
            notes
        }
    }
`);

/**
 * Attempts to intelligently group items by title, ignoring tooth numbers as they may have changed
 * If multiple items in the order might belong to that group, we bail and don't group
 */
const getItemGroupKey = (
    item: AdminOrderCompareOrderItemsSalesOrder_FragmentFragment['items_full_display_info'][number],
    allOrders: readonly AdminOrderCompareOrderItemsSalesOrder_FragmentFragment[],
) => {
    const getSimpleKey = (title: string) => title.replace(/\d+,*/g, '').trim();
    const itemSimpleKey = getSimpleKey(item.title);

    // if any order has two or more items that could conflict, we bail on trying to simplify
    // this is the simplest approach that gets us simplification in most cases, without over simplifying
    const orderHasConflicts = allOrders.some(
        order => order.items_full_display_info.filter(i => getSimpleKey(i.title) === itemSimpleKey).length > 1,
    );

    return orderHasConflicts ? item.title : itemSimpleKey;
};

interface OrderCompareOrderItemsKeyInfo {
    major_details: string[];
    minor_details: string[];
    has_shade: boolean;
    has_notes: boolean;
}

export type OrderCompareOrderItemsKeyMap = Record<string, OrderCompareOrderItemsKeyInfo>;

/**
 * Groups items by title and combines major and minor detail keys for each item in each group.
 * From the combined keys we know when we should render a blank entry for a missing detail.
 */
export const getOrderCompareOrderItemsKeyMap = (
    allOrders: readonly FragmentType<typeof AdminOrderCompareOrderItemsSalesOrder_Fragment>[],
): OrderCompareOrderItemsKeyMap => {
    const orders = getFragmentData(AdminOrderCompareOrderItemsSalesOrder_Fragment, allOrders);

    const allItemInfo = orders.flatMap(order =>
        order.items_full_display_info.map(item => ({
            ...item,
            key: getItemGroupKey(item, orders),
        })),
    );
    const groupedItemInfo = _.groupBy(allItemInfo, 'key');

    return Object.entries(groupedItemInfo).reduce((acc, [key, items]) => {
        const major_details = _.uniq(items.flatMap(item => item.major_details.map(detail => detail.label)));
        const minor_details = _.uniq(items.flatMap(item => item.minor_details.map(detail => detail.label)));
        const has_shade = items.some(item => item.shade_details.length > 0);
        const has_notes = items.some(item => item.notes);

        return {
            ...acc,
            [key]: { major_details, minor_details, has_shade, has_notes },
        };
    }, {});
};

interface OrderCompareOrderItemsProps {
    salesOrder: FragmentType<typeof AdminOrderCompareOrderItemsSalesOrder_Fragment>;
    allSalesOrders: readonly FragmentType<typeof AdminOrderCompareOrderItemsSalesOrder_Fragment>[];
    itemsKeyMap: OrderCompareOrderItemsKeyMap;
}

export const OrderCompareOrderItems: React.VFC<OrderCompareOrderItemsProps> = ({
    salesOrder: salesOrderProp,
    allSalesOrders: allSalesOrdersProp,
    itemsKeyMap,
}) => {
    const salesOrder = getFragmentData(AdminOrderCompareOrderItemsSalesOrder_Fragment, salesOrderProp);
    const allSalesOrders = getFragmentData(AdminOrderCompareOrderItemsSalesOrder_Fragment, allSalesOrdersProp);

    const numRows = Object.entries(itemsKeyMap).reduce(
        (acc, [, { major_details, minor_details, has_shade, has_notes }]) =>
            // note: item title is included as a row, thus the +1 in the calculation
            acc + 1 + major_details.length + minor_details.length + (has_shade ? 1 : 0) + (has_notes ? 1 : 0),
        // start with 1 row for the section title
        1,
    );

    return (
        <OrderCompareSectionWrapper rows={numRows}>
            <OrderCompareSectionTitle title={'Items'} />

            {Object.entries(itemsKeyMap).map(([key, { major_details, minor_details, has_shade, has_notes }]) => {
                const item = salesOrder.items_full_display_info.find(
                    item => getItemGroupKey(item, allSalesOrders) === key,
                );

                return (
                    <React.Fragment key={key}>
                        <OrderCompareFactTitle title={item?.title ?? null} bold />
                        {major_details.map(detail => {
                            const itemDetail = item?.major_details.find(d => d.label === detail);
                            return (
                                <OrderCompareFactRow
                                    key={detail}
                                    title={itemDetail?.label ?? null}
                                    value={itemDetail?.value}
                                    displayBias={'value'}
                                    boldTitle
                                />
                            );
                        })}
                        {has_shade && (
                            <OrderCompareFactRow
                                title={item ? 'Shades' : null}
                                value={item?.shade_details.map(d => `${d.label}: ${d.value}`).join(', ')}
                                displayBias={'value'}
                                boldTitle
                            />
                        )}
                        {has_notes && (
                            <OrderCompareFactRow
                                title={item ? 'Notes' : null}
                                value={item?.notes}
                                displayBias={'value'}
                                boldTitle
                            />
                        )}
                        {minor_details.map(detail => {
                            const itemDetail = item?.minor_details.find(d => d.label === detail);
                            return (
                                <OrderCompareFactRow
                                    key={detail}
                                    title={itemDetail?.label ?? null}
                                    value={itemDetail?.value}
                                    displayBias={'value'}
                                />
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </OrderCompareSectionWrapper>
    );
};
