import { SettingsBaseModal } from './SettingsBaseModal';
import { TrashIcon } from '@orthly/ui';
import { FlossPalette, Button, IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

export const RemoveItemModal: React.VFC<{
    title: string;
    subtitle: string;
    confirmRemoveText: string;
    removeItem: () => void;
    disabled: boolean;
    onOpen?: () => void;
}> = ({ title, subtitle, confirmRemoveText, removeItem, disabled, onOpen }) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = (isOpen: boolean) => {
        setOpen(isOpen);

        if (isOpen) {
            onOpen?.();
        }
    };

    return (
        <SettingsBaseModal
            open={open}
            setOpen={handleOpen}
            title={title}
            subtitle={subtitle}
            loading={false}
            CustomButton={() => (
                <Tooltip
                    title={
                        disabled ? 'You cannot delete this address because it is used in an in-flight order' : undefined
                    }
                >
                    <span>
                        <IconButton
                            disabled={disabled}
                            onClick={() => handleOpen(!open)}
                            data-test={'remove-item-button'}
                        >
                            <TrashIcon style={{ color: disabled ? FlossPalette.LIGHT_GRAY : FlossPalette.GRAY }} />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
            content={
                <div style={{ float: 'right', marginTop: '16px' }}>
                    <Button
                        variant={'secondary'}
                        style={{ marginRight: '8px' }}
                        onClick={() => handleOpen(false)}
                        data-test={'cancel-remove-item-button'}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'alert'}
                        onClick={() => {
                            removeItem();
                            handleOpen(false);
                        }}
                        data-test={'confirm-remove-item-button'}
                    >
                        {confirmRemoveText}
                    </Button>
                </div>
            }
        />
    );
};
