import { PracticeFullScreenDialog } from '@orthly/dentin';
import { FlossPalette, Icon, RootActionDialog } from '@orthly/ui';
import { Box, Button, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const StyledRootActionDialog = styled(RootActionDialog)({
    '.MuiPaper-root': {
        maxWidth: '600px',
    },
});

const MobileWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const MobileHeader = styled(Box)({
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    minHeight: '70px',
    backgroundColor: FlossPalette.TAN,
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    position: 'relative',
    padding: '12px 16px',
});

const MobileCloseButton = styled(Button)({
    background: FlossPalette.TAN,
    height: '48px',
    width: '48px',
});

const InnerContentWrapper = styled(Box)<{ isMobile: boolean }>(({ isMobile }) => ({
    margin: isMobile ? '24px 16px 0px' : '24px 0px',
    display: 'grid',
    gap: '24px',
    marginTop: '12px',
}));

const InnerContentButtonsWrapper = styled(Box)({
    display: 'flex',
    gap: '8px',
    justifyContent: 'end',
});

interface InnerContentProps {
    isMobile?: boolean;
    onDesignReviewedAction: (options: { showLdrSurvey: boolean }) => Promise<void>;
    isRejected?: boolean;
    onModalClose: () => void;
}

const InnerContent: React.VFC<InnerContentProps> = ({ isMobile, onDesignReviewedAction, onModalClose, isRejected }) => {
    const onClickAction = () => {
        onModalClose();
        void onDesignReviewedAction({ showLdrSurvey: false });
    };

    const body = isRejected
        ? 'By rejecting this design, your scheduled Live Design Review video call will be automatically canceled so we may re-design the case.'
        : 'By approving this design, your scheduled Live Design Review video call will be automatically canceled and your order will move into fabrication.';

    return (
        <InnerContentWrapper isMobile={!!isMobile}>
            <Text variant={'body2'}>{body}</Text>
            <InnerContentButtonsWrapper>
                <Button variant={'secondary'} onClick={onModalClose}>
                    Cancel
                </Button>
                <Button variant={'primary'} onClick={onClickAction} startIcon={'PlayArrowIcon'}>
                    Resume Order and {isRejected ? 'Reject' : 'Approve'} Design
                </Button>
            </InnerContentButtonsWrapper>
        </InnerContentWrapper>
    );
};

interface GuidedWaxupCancelLiveCallModalProps {
    onDesignReviewedAction: (options: { showLdrSurvey: boolean }) => Promise<void>;
    openModal: () => void;
    onModalClose: () => void;
    isRejected?: boolean;
    /**
     * While this field could be ascertained within the component, we pass it in
     * to make it easier to test variants in Storybook and other areas.
     */
    isMobile: boolean;
}

export const GuidedWaxupCancelLiveCallModal: React.VFC<GuidedWaxupCancelLiveCallModalProps> = ({
    onDesignReviewedAction,
    openModal,
    onModalClose,
    isRejected,
    isMobile,
}) => {
    const title = `${isRejected ? 'Rejecting' : 'Approving'} the design will cancel your upcoming Live Design Review call with the Lab Technician.`;

    if (isMobile) {
        return (
            <PracticeFullScreenDialog open>
                <MobileWrapper>
                    <MobileHeader>
                        <Text variant={'h6'} medium>
                            {title}
                        </Text>
                        <MobileCloseButton variant={'secondary'} onClick={onModalClose}>
                            <Icon icon={'XIcon'} />
                        </MobileCloseButton>
                    </MobileHeader>
                    <InnerContent
                        isMobile
                        isRejected={isRejected}
                        onDesignReviewedAction={onDesignReviewedAction}
                        onModalClose={onModalClose}
                    />
                </MobileWrapper>
            </PracticeFullScreenDialog>
        );
    }

    return (
        <StyledRootActionDialog
            loading={false}
            open
            showCloseButton
            setOpen={open => (open ? openModal() : onModalClose())}
            title={title}
            content={
                <InnerContent
                    isRejected={isRejected}
                    onDesignReviewedAction={onDesignReviewedAction}
                    onModalClose={onModalClose}
                />
            }
            CustomButton={() => null}
        />
    );
};
