import { WorkOrderConditionField } from '../../../../components/LabOrderConditionEditor';
import { LabOrderItemConditionField } from '../../../../components/LabOrderItemConditionEditor';
import { RoutingRuleUtils } from '../../../../components/RoutingRule.util';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type {
    LabsGqlCreateCapacityStationRuleArgs,
    LabsGqlUpdateCapacityStationRuleArgs,
} from '@orthly/graphql-schema';
import { getAllCapabilityTypeAndLevelSlugsForTask, DesignTaskType } from '@orthly/shared-types';
import type { FieldsDefProp } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

const CapacityStationRuleFormListLabtracProductCodes_Query = graphql(`
    query CapacityStationRuleFormListLabtracProductCodes_Query {
        listLabtracProductCodes
    }
`);

type RuleConditionType = 'design_station_codes' | 'lab_product_codes' | 'matching_logic_json';

type FormFields = Pick<
    LabsGqlCreateCapacityStationRuleArgs | LabsGqlUpdateCapacityStationRuleArgs,
    RuleConditionType | 'default_unit_limit' | 'work_order_matching_logic_json' | 'name'
>;

function getConditionType(formFields: Partial<FormFields>): RuleConditionType | null {
    if (formFields.design_station_codes?.length) {
        return 'design_station_codes';
    }
    if (formFields.lab_product_codes?.length) {
        return 'lab_product_codes';
    }
    if (formFields.matching_logic_json && formFields.matching_logic_json !== '') {
        return 'matching_logic_json';
    }
    return null;
}

const conditionFields: RuleConditionType[] = ['lab_product_codes', 'design_station_codes', 'matching_logic_json'];

/**
 * Helper for the common fields in the Create + Edit Capacity Station Rule forms
 */
export function useCapacityRuleFormFields(initialValues: Partial<FormFields>) {
    const { data: labtracProductCodes, loading: labtracProductCodesLoading } = useQuery(
        CapacityStationRuleFormListLabtracProductCodes_Query,
    );

    const [type, setType] = React.useState<RuleConditionType | null>(getConditionType(initialValues));
    const onChange = (formFields: Partial<FormFields>) => setType(getConditionType(formFields));
    const hiddenFields: RuleConditionType[] = !type ? [] : conditionFields.filter(f => f !== type);

    const fields: FieldsDefProp<FormFields> = {
        name: { label: 'Name', type: 'text' },
        matching_logic_json: {
            label: 'Condition',
            type: 'custom',
            component: LabOrderItemConditionField,
            validation: RoutingRuleUtils.ItemConditionValidation,
            optional: true,
            hidden: hiddenFields.includes('matching_logic_json'),
        },
        lab_product_codes: {
            label: 'Labtrac Product Codes',
            type: 'multiselect',
            options: _.uniq(labtracProductCodes?.listLabtracProductCodes) ?? [],
            optional: true,
            hidden: hiddenFields.includes('lab_product_codes'),
        },
        design_station_codes: {
            label: 'Design Station Codes',
            type: 'multiselect',
            options: getAllCapabilityTypeAndLevelSlugsForTask(DesignTaskType.InternalDesign),
            optional: true,
            hidden: hiddenFields.includes('design_station_codes'),
        },
        default_unit_limit: {
            label: 'Default Unit Limit',
            type: 'number',
        },
        work_order_matching_logic_json: {
            label: 'Condition',
            type: 'custom',
            component: WorkOrderConditionField,
            validation: RoutingRuleUtils.OrderConditionValidation,
            optional: true,
        },
    };

    return { fields, onChange, loading: labtracProductCodesLoading };
}
