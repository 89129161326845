import {
    OrderCompareFactTitle,
    OrderCompareSectionTitle,
    OrderCompareSectionWrapper,
} from './OrderCompareDisplayComponents';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import React from 'react';

const AdminOrderCompareOrderNotesSalesOrder_Fragment = graphql(`
    fragment AdminOrderCompareOrderNotesSalesOrder_Fragment on SalesOrderDTO {
        notes {
            doctor
            clinical_support
            refabrication
        }
    }
`);

interface OrderCompareOrderNotesProps {
    salesOrder: FragmentType<typeof AdminOrderCompareOrderNotesSalesOrder_Fragment>;
}

export const OrderCompareOrderNotes: React.VFC<OrderCompareOrderNotesProps> = ({ salesOrder: salesOrderProp }) => {
    const salesOrder = getFragmentData(AdminOrderCompareOrderNotesSalesOrder_Fragment, salesOrderProp);

    return (
        <OrderCompareSectionWrapper rows={7}>
            <OrderCompareSectionTitle title={'Notes'} />
            <OrderCompareFactTitle title={'Doctor'} bold />
            <OrderCompareFactTitle title={salesOrder.notes.doctor ?? '(none)'} />
            <OrderCompareFactTitle title={'Clinical Support'} bold />
            <OrderCompareFactTitle title={salesOrder.notes.clinical_support ?? '(none)'} />
            <OrderCompareFactTitle title={'Refabrication'} bold />
            <OrderCompareFactTitle title={salesOrder.notes.refabrication ?? '(none)'} />
        </OrderCompareSectionWrapper>
    );
};
