import { Box } from '@orthly/ui-primitives';
import { GoogleOAuthProvider, GoogleLogin as GoogleLoginButton } from '@react-oauth/google';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

export interface GoogleLoginProps {
    /* Unique client ID for Google SDK. Hides if not present. */
    clientId?: string;
    /* Called when login is complete */
    onSuccess: (args: { jwt: string }) => void;
    /* Called when login button is clicked, before popup */
    onClick?: () => void;
}

function notifyLoginError(reason: string) {
    enqueueSnackbar(`Login failed: ${reason}`, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
}

export const GoogleLogin: React.FC<GoogleLoginProps> = props => {
    if (!props.clientId) {
        return null;
    }
    return (
        <GoogleOAuthProvider clientId={props.clientId}>
            <Box
                sx={{
                    paddingTop: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <GoogleLoginButton
                    use_fedcm_for_prompt={true}
                    size={'large'}
                    click_listener={props.onClick}
                    onSuccess={credentialResponse => {
                        // in the current implementation of @react-oauth/google, onSuccess is only called when the
                        // credential is nonempty, which the types don't reflect.
                        // @see https://github.com/MomenSherif/react-oauth/pull/383
                        props.onSuccess({ jwt: credentialResponse.credential as string });
                    }}
                    onError={() => {
                        notifyLoginError('There was an unexpected error logging in. Please try again later.');
                    }}
                />
            </Box>
        </GoogleOAuthProvider>
    );
};
