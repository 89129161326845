import { levelToDisplayString } from '../../../../../utils/Capabilities';
import type { LabsGqlOrderDesignCapabilityLevel, LabsGqlDesignStaffCapabilityInput } from '@orthly/graphql-schema';
import type { IOrderDesignCapabilityDTO, OrderDesignCapabilityLevel } from '@orthly/shared-types';
import type { FieldDefBasic, FieldsDefProp } from '@orthly/ui';
import { RootActionDialog, QuickForm } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type FormType = Record<string, LabsGqlOrderDesignCapabilityLevel | 'null'>;

// Returns a form field definition for a capability type
// with the given label and capability options.
function makeCapabilityFieldDef(
    label: string,
    levelOptions: Partial<Record<OrderDesignCapabilityLevel, IOrderDesignCapabilityDTO>>,
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): FieldDefBasic<any, LabsGqlOrderDesignCapabilityLevel | 'null'> {
    const options = [
        { value: 'null', label: 'None' },
        ..._.sortBy(_.values(levelOptions), o => o.level).map(o => ({
            value: o.level,
            label: `${levelToDisplayString(o.level)}: ${o.description}`,
        })),
    ];
    return {
        type: 'select',
        label: label,
        options,
        disableSortOptions: true,
    };
}

export function EditCapabilitiesDialog(props: {
    title: string;
    existingCapabilities: Record<string, LabsGqlOrderDesignCapabilityLevel | null>;
    typeToName: Record<string, string>;
    capabilitiesDef: Record<string, Record<string, IOrderDesignCapabilityDTO>>;
    onSubmit: (capabilities: Partial<LabsGqlDesignStaffCapabilityInput>) => void;
    submitting: boolean;
}): React.ReactElement {
    const { title, existingCapabilities, typeToName, capabilitiesDef, onSubmit, submitting } = props;
    const [open, setOpen] = React.useState<boolean>(false);

    // The select UI widget doesn't support literal `null` as a value, so we use the string 'null'
    // to represent null and convert back to literal `null` in the onSubmit handler below.
    const initialValues: FormType = _.mapValues(_.omit(existingCapabilities), v => v || 'null');

    const fields: FieldsDefProp<FormType> = _.mapValues(capabilitiesDef, (levels, type) =>
        makeCapabilityFieldDef(typeToName[type] ?? '', levels),
    );

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={title}
            CustomButton={({ onClick }) => (
                <Button startIcon={'PencilOutlinedIcon'} onClick={onClick} variant={'ghost'} style={{ marginTop: 8 }}>
                    Edit
                </Button>
            )}
            content={
                <QuickForm<FormType>
                    fields={fields}
                    initialValues={initialValues}
                    onSubmit={async result => {
                        // Convert 'null' strings to null.
                        onSubmit(_.mapValues(result, v => (v === 'null' ? null : v)));
                    }}
                    dirtySubmitOnly={true}
                />
            }
        />
    );
}
