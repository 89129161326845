import { useFirebaseStorage } from '../../context';
import { getFirebaseDownloadUrl } from '../../hooks';
import type { UploadBulkFn } from '../FirebaseUpload';
import { uploadFilesToGCS, useFirebaseMultiFileUpload } from '../FirebaseUpload';
import { useAcceptDcmFiles } from './AcceptFilesFromBrowser.hooks';
import type {
    ScanReviewAppState,
    ScanReviewAppStateManager,
    ScanReviewCompositeScene,
    ScanReviewInsertionAxis,
    ScanReviewMarginLine,
} from '@orthly/dentin';
import {
    type ScanReviewDcmFile,
    type ScanReviewRecordFactory,
    type ScanReviewViewState,
    DEFAULT_UNDERCUT_SHADING_RADIUS,
    extractMarginsV2,
    ScanReviewPanelType,
    useScanReviewCompleteViewApp,
} from '@orthly/dentin';
import { getCentroidAndMaxDistance, MlServices, PLYExporter } from '@orthly/forceps';
import { AllToothNumbers, ToothUtils } from '@orthly/items';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import {
    DesignStorageConfigs,
    getFullStoragePath,
    Jaw,
    MarginSuggestionV2ResultSchema,
    ScanSegmentationV2,
} from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import constate from 'constate';
import { useSnackbar } from 'notistack';
import { join } from 'path-browserify';
import React from 'react';
import * as THREE from 'three';

async function extractJawsFromSceneAndUploadToCloudStorage(
    scene: ScanReviewCompositeScene,
    firebase: ReturnType<typeof useFirebaseStorage>,
    storagePathConfig: BucketStoragePathConfig,
    uploadFn: UploadBulkFn,
) {
    const isolatedScene = scene.getPartialSceneForPanelType(ScanReviewPanelType.Isolated);
    const upperJaw = isolatedScene.upperJaw?.scanMesh;
    const lowerJaw = isolatedScene.lowerJaw?.scanMesh;

    const plyOptions = { disableUVs: true, disableVertexColors: true };
    const jawData = {
        upperJaw: {
            ply: 'upperJaw.ply',
            geometry: upperJaw,
            plyBuffer: upperJaw ? new PLYExporter(upperJaw, plyOptions).export() : undefined,
        },
        lowerJaw: {
            ply: 'lowerJaw.ply',
            geometry: lowerJaw,
            plyBuffer: lowerJaw ? new PLYExporter(lowerJaw, plyOptions).export() : undefined,
        },
    };

    const filesToSubmit: File[] = [];
    const date = Date.now();
    const lowerJawPlyStorageConfigRelativePath = join('team3d-landing-page', `${date}`, 'upperJaw.ply');
    const upperJawPlyStorageConfigRelativePath = join('team3d-landing-page', `${date}`, 'lowerJaw.ply');
    if (jawData.upperJaw.plyBuffer) {
        filesToSubmit.push(new File([jawData.upperJaw.plyBuffer], lowerJawPlyStorageConfigRelativePath));
    }
    if (jawData.lowerJaw.plyBuffer) {
        filesToSubmit.push(new File([jawData.lowerJaw.plyBuffer], upperJawPlyStorageConfigRelativePath));
    }

    await uploadFilesToGCS({ uploadFn, filesToSubmit });

    const upperJawUrl = await getFirebaseDownloadUrl(
        firebase,
        join(storagePathConfig.path, lowerJawPlyStorageConfigRelativePath),
    );
    const lowerJawUrl = await getFirebaseDownloadUrl(
        firebase,
        join(storagePathConfig.path, upperJawPlyStorageConfigRelativePath),
    );

    return { upperJaw, upperJawUrl, lowerJaw, lowerJawUrl };
}

function useStoragePathConfig() {
    // Need to make this path more unique
    const storagePathConfig = React.useMemo(
        () => getFullStoragePath(OrthlyBrowserConfig.env, DesignStorageConfigs.aiScanReviews),
        [],
    );
    return storagePathConfig;
}

function useCalculateMarginLineCallback(setLoading: React.Dispatch<React.SetStateAction<boolean>>) {
    const storagePathConfig = useStoragePathConfig();

    // TODO: EPDPLT-4635 - Firebase Storage Migration
    const [uploadFn] = useFirebaseMultiFileUpload(storagePathConfig, []);
    const firebase = useFirebaseStorage();

    return React.useCallback(
        async (
            scene: ScanReviewCompositeScene,
            scanReviewAppState: ScanReviewAppState,
            scanReviewAppStateManager: ScanReviewAppStateManager,
        ) => {
            const unn = scanReviewAppState.toothNumber;
            const editor = scanReviewAppState.currentMarginLineEditor;
            if (unn === undefined || editor === undefined) {
                return;
            }

            setLoading(true);
            const { upperJawUrl, lowerJawUrl } = await extractJawsFromSceneAndUploadToCloudStorage(
                scene,
                firebase,
                storagePathConfig,
                uploadFn,
            );

            const jaw = ToothUtils.toothIsUpper(unn) ? Jaw.UPPER : Jaw.LOWER;
            const url = jaw === Jaw.UPPER ? upperJawUrl : lowerJawUrl;

            const endpoint = MlServices.MARGIN_LINE_SUGGESTION_SERVICE_URL;
            const body = JSON.stringify({
                instances: [
                    {
                        jaw_scan: {
                            type: 'URL',
                            url,
                            file_type: 'ply',
                        },
                        prepped_tooth_id: unn,
                        reconstruction_type: 'CrownPrep',
                    },
                ],
                parameters: {
                    skip_heatmap_fitting: false,
                    return_raw_predictions: false,
                },
            });
            try {
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body,
                });

                if (!response.ok) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    console.error('Failed to get segmentation data', { errorData: await response.json() });
                    return;
                }

                const parsedResponse = MarginSuggestionV2ResultSchema.parse(await response.json());
                const extractedResult = extractMarginsV2(parsedResponse, jaw === Jaw.UPPER, true);
                const extractedMargin = extractedResult[0]?.margin.controlPoints;
                if (extractedMargin) {
                    editor.replacePoints(extractedMargin, true);
                }
                if (scanReviewAppState.currentInsertionAxis) {
                    const { centroid, maxDistance } = getCentroidAndMaxDistance(
                        editor.currentEditedMarginLine.controlPoints,
                    );
                    scanReviewAppState.currentInsertionAxis.position = centroid;
                    scanReviewAppState.currentInsertionAxis.maxDistance = maxDistance + DEFAULT_UNDERCUT_SHADING_RADIUS;
                    scene
                        .getPartialSceneForPanelType(ScanReviewPanelType.Isolated)
                        .updateUndercutDisplay(jaw, scanReviewAppState.currentInsertionAxis);
                    scanReviewAppStateManager.handleInsertionAxisUpdate();
                }
            } catch (error) {
                console.log('Error calling segmentation endpoint', { error });
            } finally {
                setLoading(false);
            }
        },
        [firebase, setLoading, storagePathConfig, uploadFn],
    );
}

function useGetSegmentationDataCallback(setLoading: React.Dispatch<React.SetStateAction<boolean>>) {
    const storagePathConfig = useStoragePathConfig();

    // TODO: EPDPLT-4635 - Firebase Storage Migration
    const [uploadFn] = useFirebaseMultiFileUpload(storagePathConfig, []);
    const firebase = useFirebaseStorage();

    return React.useCallback(
        async (scene: ScanReviewCompositeScene) => {
            setLoading(true);
            const { upperJaw, upperJawUrl, lowerJaw, lowerJawUrl } = await extractJawsFromSceneAndUploadToCloudStorage(
                scene,
                firebase,
                storagePathConfig,
                uploadFn,
            );
            const body = JSON.stringify({
                instances: [
                    {
                        upper_jaw_scan: upperJaw
                            ? {
                                  type: 'URL',
                                  file_type: 'ply',
                                  url: upperJawUrl,
                              }
                            : undefined,
                        lower_jaw_scan: lowerJaw
                            ? {
                                  type: 'URL',
                                  file_type: 'ply',
                                  url: lowerJawUrl,
                              }
                            : undefined,
                    },
                ],
                parameters: {
                    skip_postprocessing: false,
                    skip_label: false,
                    export_file_to_gcs: false,
                },
            } satisfies ScanSegmentationV2.Request);

            const endpoint = MlServices.TEETH_SEGMENTATION_SERVICE_URL;

            try {
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body,
                });

                if (!response.ok) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    console.error('Failed to get segmentation data', { errorData: await response.json() });
                    return;
                }
                const responseData = ScanSegmentationV2.ResultSchema.parse(await response.json());
                const predictions = responseData.predictions[0];
                if (!predictions) {
                    return;
                }
                const upperJawLabels = predictions.upper_jaw_scan?.labels;
                const lowerJawLabels = predictions.lower_jaw_scan?.labels;

                if (upperJawLabels) {
                    scene.setUpperJawLabels(upperJawLabels);
                }
                if (lowerJawLabels) {
                    scene.setLowerJawLabels(lowerJawLabels);
                }
            } catch (error) {
                console.log('Error calling segmentation endpoint', { error });
            } finally {
                setLoading(false);
            }
        },
        [firebase, setLoading, storagePathConfig, uploadFn],
    );
}

function useScanReviewLandingPageApp() {
    const [dcms, setDcms] = React.useState<ScanReviewDcmFile[]>([]);
    const { onDcmDrop } = useAcceptDcmFiles(setDcms);

    const [upperJawFactory, setUpperJawFactory] = React.useState<ScanReviewRecordFactory | null>(null);
    const [lowerJawFactory, setLowerJawFactory] = React.useState<ScanReviewRecordFactory | null>(null);

    const preppedTeeth = React.useMemo(() => new Map(AllToothNumbers.map(unn => [unn, false])), []);
    const viewState = React.useMemo<ScanReviewViewState>(() => {
        return {
            [ScanReviewPanelType.Upper]: null,
            [ScanReviewPanelType.Lower]: null,
            [ScanReviewPanelType.Left]: null,
            [ScanReviewPanelType.Right]: null,
            [ScanReviewPanelType.Front]: null,
        };
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    const warnMarginLineIsInUndercut = React.useCallback(() => {
        enqueueSnackbar(`Margin line is in undercut. Please adjust the insertion axis or the margin line.`, {
            variant: 'warning',
            preventDuplicate: true,
        });
    }, [enqueueSnackbar]);

    // These two callbacks are very similar to what is used in chairside today, so should be easy to adapt.
    const onMarginUpdate = React.useCallback(
        (editedMarginLine: ScanReviewMarginLine, marginLineIsInUndercut?: boolean) => {
            // This is where chairside would copy the edited margin line to whatever slice of state it
            // had reserved for it.
            console.log(editedMarginLine);
            if (marginLineIsInUndercut) {
                warnMarginLineIsInUndercut();
            }
        },
        [warnMarginLineIsInUndercut],
    );

    const onInsertionAxisUpdate = React.useCallback(
        (newInsertionAxis: ScanReviewInsertionAxis, marginLineIsInUndercut: boolean) => {
            // This is where chairside would copy the updated insertion axis to whatever slice of state it
            // had reserved for it.
            console.log(newInsertionAxis);
            if (marginLineIsInUndercut) {
                warnMarginLineIsInUndercut();
            }
        },
        [warnMarginLineIsInUndercut],
    );

    const [loading, setLoading] = React.useState(false);

    const calculateInsertionAxisCallback = React.useCallback(
        async (
            scene: ScanReviewCompositeScene,
            scanReviewAppState: ScanReviewAppState,
        ): Promise<ScanReviewInsertionAxis> => {
            setLoading(true);
            console.log({ scene, scanReviewAppState });
            setLoading(false);
            return {
                unn: 32,
                maxDistance: DEFAULT_UNDERCUT_SHADING_RADIUS,
                position: new THREE.Vector3(),
                direction: new THREE.Vector3(0, 1, 0),
            };
        },
        [],
    );

    const calculateMarginLineCallback = useCalculateMarginLineCallback(setLoading);
    const getSegmentationDataCallback = useGetSegmentationDataCallback(setLoading);

    const marginLines: ScanReviewMarginLine[] = React.useMemo(() => [], []);
    const insertionAxes: ScanReviewInsertionAxis[] = React.useMemo(() => [], []);

    const { scanReviewAppState, scanReviewAppStateManager } = useScanReviewCompleteViewApp({
        lowerJawFactory,
        upperJawFactory,
        viewState,
        toothNumber: undefined,
        marginLines,
        insertionAxes,
        onMarginUpdate,
        onInsertionAxisUpdate,
    });

    return {
        dcms,
        setDcms,
        onDcmDrop,
        upperJawFactory,
        setUpperJawFactory,
        lowerJawFactory,
        setLowerJawFactory,
        viewState,
        loading,
        calculateInsertionAxisCallback,
        getSegmentationDataCallback,
        calculateMarginLineCallback,
        scanReviewAppState,
        scanReviewAppStateManager,
        preppedTeeth,
    };
}

export const [ScanReviewLandingPageAppProvider, useScanReviewLandingPageContext] =
    constate(useScanReviewLandingPageApp);
