import { debugUVTransformationAtom } from '../store/settings';
import { store } from '../store/store';
import type { Mesh } from 'three';
import { BufferAttribute, Vector3 } from 'three';

const scaleFactor = 2;

/**
 * Calculates a nonlinear scaling factor based on the distance along an axis.
 * This function scales more the further along the insertion axis the vertex is.
 *
 * @param distanceAlongAxis The distance along the insertion axis.
 * @param scaleFactor A scalar value that influences the intensity of the scaling.
 * @returns The nonlinear scaling factor.
 */
function calculateScaleFactor(normalizedDistanceAlongAxis: number): number {
    // Linear scaling function
    // return 1 + normalizedDistanceAlongAxis ** scaleFactor;

    // Sigmoid
    // const k = scaleFactor; // steepness of the curve, adjust as needed
    // return 1 + 1 / (1 + Math.exp(-k * normalizedDistanceAlongAxis)) - 0.5; // Shifted and scaled sigmoid

    // Power Function
    return 1 + Math.pow(scaleFactor * normalizedDistanceAlongAxis, 2);
}

/**
 * Projects a vertex onto a plane defined by a normal (perpendicular to gradientDirection) and
 * returns the u and v coordinates, scaled to fit the transformed geometry.
 *
 * @param projectedPointOnPlane The transformed position of the vertex projected to the plane.
 * @param minMax The bounding box min max.
 * @returns An object containing the u and v coordinates of the projected point on the plane.
 */
function projectToPlane(
    projectedPointOnPlane: Vector3,
    minMax: { minU: number; maxU: number; minV: number; maxV: number },
): { u: number; v: number } {
    const width = minMax.maxU - minMax.minU;
    const height = minMax.maxV - minMax.minV;

    const u = (projectedPointOnPlane.x - minMax.minU) / width; // Ensure UVs are in range [0, 1]
    const v = (projectedPointOnPlane.z - minMax.minV) / height; // Ensure UVs are in range [0, 1]

    return { u: u, v: v };
}

/**
 * Scales a vertex outward from a given axis based on its distance along that axis.
 *
 * @param vertexPosition The original position of the vertex.
 * @param transformationCenter The center point for the scaling transformation.
 * @param gradientDirection The axis along which the scaling will occur (should be normalized).
 * @param boundingSphereDiameter The diameter of the bounding sphere.
 * @returns An object containing the new position of the scaled vertex, the normalized original radius, and the scale factor.
 */
function scaleVertexOutward(
    vertexPosition: Vector3,
    transformationCenter: Vector3,
    gradientDirection: Vector3,
    boundingSphereDiameter: number,
): { transformedPosition: Vector3; originalRadius: number; scaleFactor: number } {
    const pos = vertexPosition.clone();

    // Step 1: Project the vertex onto the insertion axis relative to the transformationCenter.
    const projectedPoint = new Vector3();
    projectedPoint.copy(gradientDirection).multiplyScalar(pos.clone().sub(transformationCenter).dot(gradientDirection));

    // Step 2: Calculate the distance along the insertion axis from the *transformationCenter*.
    const distanceAlongAxis = projectedPoint.length();
    const normalizedDistanceAlongAxis = distanceAlongAxis / boundingSphereDiameter;

    // Step 3: Calculate the scaling factor based on the distance and the given `scaleFactor`.
    const scaleAmount = calculateScaleFactor(normalizedDistanceAlongAxis);

    // Step 4: Calculate the vector from the projection point on axis to the vertex.
    const diffVector = pos.clone().sub(transformationCenter).sub(projectedPoint);

    // Calculate the normalized original radius
    const originalRadius = diffVector.length() / boundingSphereDiameter;

    // Step 5: Scale the difference vector. This is the outward scaling.
    diffVector.multiplyScalar(scaleAmount);

    // Step 6: Apply the transformation to the vertex: project + scaledDiff
    pos.copy(projectedPoint).add(diffVector).add(transformationCenter);

    return { transformedPosition: pos, originalRadius, scaleFactor: scaleAmount };
}

const centerToVertex = new Vector3();
const positionToTransformationCenter = new Vector3();

/**
 * Determines whether a vertex should be filtered based on its normal, position, and the insertion axis.
 *
 * @param position The world position of the vertex.
 * @param normal The normal vector of the vertex.
 * @param center The world center point of the object.
 * @param gradientDirection The insertion axis (should be normalized).
 * @param transformationCenter The bottom center.
 * @returns An object containing boolean flags indicating whether the vertex should be filtered.
 *   - isMargin: true if the angle between the normal and the insertion axis is within a margin (less than 45 degrees).
 *   - isInside: true if the vector from the center to the vertex position points in the opposite direction to the normal.
 */
function shouldFilter(
    position: Vector3,
    normal: Vector3,
    center: Vector3,
    gradientDirection: Vector3,
    transformationCenter: Vector3,
): boolean {
    return false;
    // Calculate angle between normal and gradientDirection
    const angle = normal.angleTo(gradientDirection);
    const isMargin = angle > (2 / 3) * Math.PI; // Check is Margin

    // Compute the vector from the center to the vertex
    centerToVertex.subVectors(position, center);

    //
    positionToTransformationCenter.subVectors(transformationCenter, position);

    // Check if the vector from the center to the vertex position points in the *opposite* direction to the normal.
    const isInside = centerToVertex.angleTo(normal) > Math.PI / 2;
    // const isOcclusal = positionToTransformationCenter.angleTo(normal) < Math.PI / 2;
    // return (isInside || isMargin) && !isOcclusal;
    // return !isOcclusal;
    return isMargin || isInside;
}

/**
 * Transforms the geometry of a mesh by scaling vertices outward from the insertion axis,
 * creating a cone-like effect.
 *
 * @param mesh The mesh to transform.
 * @param gradientDirection The axis along which the scaling will occur.  Should be normalized.
 * @param scaleFactor A scalar value that influences the intensity of the scaling.
 * @param applyTransformation Flag indicating whether to apply the scaling transformation to the geometry.
 */
export function unwrapUVs(mesh: Mesh, gradientDirection: Vector3): void {
    const geometry = mesh.geometry;

    geometry.computeBoundingSphere();
    const boundingSphere = geometry.boundingSphere;
    const position = geometry.getAttribute('position');
    const normals = geometry.getAttribute('normal');

    if (!boundingSphere || !position || !normals) {
        return;
    }

    const sphereCenter = boundingSphere.center.clone(); // Center of the bounding sphere
    const sphereRadius = boundingSphere.radius; // Radius of bounding sphere
    const sphereDiameter = sphereRadius * 2; // Diameter of the sphere

    // Calculate new center point offset by 1/2 diameter along the negative insertion axis.
    const centerOffset = gradientDirection.clone().multiplyScalar(sphereRadius); // sphere radius == 1/2 diameter

    // New center for scaling transformation
    const transformationCenter = new Vector3().addVectors(sphereCenter, centerOffset);

    const vertexCount = position.count;

    //Initialize Min/Max for projected point on the plane
    let minU = Infinity;
    let maxU = -Infinity;
    let minV = Infinity;
    let maxV = -Infinity;

    const debugUVTransformation = store.get(debugUVTransformationAtom);

    const transformedPositions: Vector3[] = [];
    const projectedPointsOnPlane: Vector3[] = [];
    // const originalRadii: number[] = [];
    // const scaleFactors: number[] = [];

    for (let i = 0; i < vertexCount; i++) {
        const pos = new Vector3(position.getX(i), position.getY(i), position.getZ(i));
        // const { transformedPosition, originalRadius, scaleFactor } = scaleVertexOutward(pos, transformationCenter, gradientDirection, sphereDiameter);
        const { transformedPosition } = scaleVertexOutward(
            pos,
            transformationCenter,
            gradientDirection,
            sphereDiameter,
        );
        transformedPositions.push(transformedPosition);
        // originalRadii.push(originalRadius);
        // scaleFactors.push(scaleFactor);

        // Calculate the projection plane normal (perpendicular to insertion axis)
        const normalVec = gradientDirection.clone().normalize();

        // Project vertex onto the plane defined by transformationCenter and normal.
        const diff = new Vector3().subVectors(transformedPosition, transformationCenter);
        const distance = diff.dot(normalVec);
        const projectedPointOnPlane = new Vector3()
            .copy(transformedPosition)
            .sub(normalVec.clone().multiplyScalar(distance));
        projectedPointsOnPlane.push(projectedPointOnPlane);

        //Update min/max U and V for UV projection.
        minU = Math.min(minU, projectedPointOnPlane.x);
        maxU = Math.max(maxU, projectedPointOnPlane.x);
        minV = Math.min(minV, projectedPointOnPlane.z);
        maxV = Math.max(maxV, projectedPointOnPlane.z);
    }

    //Optional add UVs that are a function of the scaling.
    const uvArray = new Float32Array(vertexCount * 2);

    for (let i = 0; i < vertexCount; i++) {
        const pos = new Vector3(position.getX(i), position.getY(i), position.getZ(i));
        const normal = new Vector3(normals.getX(i), normals.getY(i), normals.getZ(i));
        const transformedPosition = transformedPositions[i];
        const projectedPointOnPlane = projectedPointsOnPlane[i];
        // const originalRadius = originalRadii[i];
        // const scaleFactor = scaleFactors[i];

        if (debugUVTransformation && transformedPosition) {
            position.setXYZ(i, transformedPosition.x, transformedPosition.y, transformedPosition.z);
        }

        // Check if the normal is pointing away from the insertion axis
        if (shouldFilter(pos, normal, sphereCenter, gradientDirection, transformationCenter)) {
            uvArray[i * 2] = 0;
            uvArray[i * 2 + 1] = 0;
        } else {
            if (!projectedPointOnPlane) {
                return;
            }
            const { u, v } = projectToPlane(projectedPointOnPlane, { minU, maxU, minV, maxV });
            // const uvRadius = Math.sqrt(u ** 2 + v ** 2);
            // const dampingFactor = 1 / scaleFactor;
            // console.log(originalRadius, uvRadius)
            uvArray[i * 2] = u;
            uvArray[i * 2 + 1] = v;
        }
    }
    if (debugUVTransformation) {
        position.needsUpdate = true;
    }

    geometry.setAttribute('uv', new BufferAttribute(uvArray, 2));
    // laplacianSmoothing(mesh);
}

// function extractConnectivity(mesh: Mesh) {
//     const geometry = mesh.geometry;
//     const index = geometry.getIndex();
//     const position = geometry.getAttribute('position');
//     const vertexCount = position.count;

//     const connectivity = new Map<number, number[]>();

//     for (let i = 0; i < vertexCount; i++) {
//         connectivity.set(i, []);
//     }

//     if (index) { // Important: Check if there's an index buffer.
//         for (let i = 0; i < index.count; i += 3) {
//             const a = index.getX(i);  // Correct way to access.
//             const b = index.getX(i + 1);
//             const c = index.getX(i + 2);

//             if (!connectivity.has(a)) connectivity.set(a, []) // Handle if key not present
//             if (!connectivity.has(b)) connectivity.set(b, []) // Handle if key not present
//             if (!connectivity.has(c)) connectivity.set(c, []) // Handle if key not present

//             connectivity.get(a).push(b, c);
//             connectivity.get(b).push(a, c);
//             connectivity.get(c).push(a, b);
//         }
//     } else {
//         console.warn("Mesh has no index buffer.  Connectivity might be incomplete.");
//         // If no index buffer, assume triangles are sequential.  This might be wrong.
//         for (let i = 0; i < vertexCount; i += 3) {
//             if (i + 2 < vertexCount) {
//                 const a = i;
//                 const b = i + 1;
//                 const c = i + 2;

//                 if (!connectivity.has(a)) connectivity.set(a, []) // Handle if key not present
//                 if (!connectivity.has(b)) connectivity.set(b, []) // Handle if key not present
//                 if (!connectivity.has(c)) connectivity.set(c, []) // Handle if key not present

//                 connectivity.get(a).push(b, c);
//                 connectivity.get(b).push(a, c);
//                 connectivity.get(c).push(a, b);
//             }
//         }

//     }
//     // Remove duplicates for smoothing.
//     for (let i = 0; i < vertexCount; i++) {
//         const neighbors = connectivity.get(i);
//         if (neighbors) {
//             connectivity.set(i, [...new Set(neighbors)]); // Remove Duplicates using Set
//         }
//     }

//     return connectivity;
// }

// function laplacianSmoothing(mesh: Mesh) {
//     const geometry = mesh.geometry;
//     const uv = geometry.getAttribute('uv');

//     if (!uv) {
//         console.warn("Mesh has no UV attribute to smooth.");
//         return;
//     }

//     const vertexCount = uv.count;
//     const connectivity = extractConnectivity(mesh);

//     const newUvArray = new Float32Array(vertexCount * 2);  // Create a new array for the updated UVs
//     for (let i = 0; i < vertexCount * 2; i++) {
//         newUvArray[i] = uv.array[i]; // Copy initial UVs
//     }

//     const stepSize = 0.05;
//     const smoothingIterations = 1000;

//     for (let iter = 0; iter < smoothingIterations; iter++) {
//         for (let i = 0; i < vertexCount; i++) {
//             const neighbors = connectivity.get(i);
//             if (!neighbors || neighbors.length === 0) continue; // Skip vertices with no neighbors

//             let avgU = 0, avgV = 0;
//             neighbors.forEach((neighbor) => {
//                 avgU += uv.getX(neighbor);
//                 avgV += uv.getY(neighbor);
//             });

//             avgU /= neighbors.length;
//             avgV /= neighbors.length;

//             // Calculate the offset
//             const deltaU = (avgU - uv.getX(i)) * stepSize;
//             const deltaV = (avgV - uv.getY(i)) * stepSize;

//             // Apply Laplacian smoothing:  newUV = originalUV + offset
//             newUvArray[i * 2] += deltaU;  // Update U coordinate
//             newUvArray[i * 2 + 1] += deltaV; // Update V coordinate
//         }
//     }

//     // Update the UV buffer attribute with the smoothed UVs
//     uv.array = newUvArray;  // Assign the new UV array
//     uv.needsUpdate = true; // Signal that the attribute has changed

// }
