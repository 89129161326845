import {
    useCalendlyLabTechMeetingEventScheduledAction,
    useCalendlyLdrEventScheduledAction,
    useRejectDdpAction,
} from '../../hooks/liveMeetingSchedulers.graphql';
import type {
    GuidedWaxupScheduleModal,
    SubmissionStatus,
    SubmissionType,
} from '../../hooks/useGuidedWaxupAccessoryModal';
import { GuidedWaxupCancelLiveCallModal } from './GuidedWaxupCancelLiveCallModal';
import { GuidedWaxupConfirmationModal } from './GuidedWaxupConfirmationModal';
import { type CalendlyPrefill } from './GuidedWaxupScheduleLiveCall.hooks.graphql';
import { GuidedWaxupScheduleLiveCallAbandonmentModal } from './GuidedWaxupScheduleLiveCallAbandonmentModal';
import { GuidedWaxupScheduleLiveCallCalendlyModal } from './GuidedWaxupScheduleLiveCallCalendlyModal';
import { GuidedWaxupScheduleLiveLabTechMeetingCalendlyModal } from './GuidedWaxupScheduleLiveLabTechMeetingCalendlyModal';
import { GuidedWaxupSelectDesignReviewOptionModal } from './GuidedWaxupSelectDesignReviewOptionModal';
import { GuidedWaxupVideoDesignReviewAbandonmentModal } from './GuidedWaxupVideoDesignReviewAbandonmentModal';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import {
    LabsGqlLiveLabTechMeetingRequestEntryPoint,
    type LabsGqlLiveDdpReviewRequestEntryPoint,
} from '@orthly/graphql-schema';
import React from 'react';

export const GuidedWaxupAccessoryModals: React.VFC<{
    orderId: string;
    revisionId: string;
    isMobile: boolean;
    setModal: (modal: GuidedWaxupScheduleModal) => void;
    modal: GuidedWaxupScheduleModal;
    isRejected?: boolean;
    calendlyPrefill?: CalendlyPrefill;
    onDesignReviewedAction?: (options: { showLdrSurvey: boolean }) => Promise<void>;
    onScheduleCompletion?: () => void;
    numRejections: number;
    isRefab: boolean;
    ddpReadinessDate: string | null;
    rejectionNotes?: string;
    entryPoint: LabsGqlLiveDdpReviewRequestEntryPoint;
}> = ({
    orderId,
    revisionId,
    setModal,
    modal,
    isMobile,
    isRejected,
    calendlyPrefill,
    onDesignReviewedAction,
    onScheduleCompletion,
    numRejections,
    isRefab,
    ddpReadinessDate,
    rejectionNotes,
    entryPoint,
}) => {
    const [submissionsStatus, setSubmissionStatus] = React.useState<SubmissionStatus>('hasntYetSubmitted');
    const [submissionType, setSubmissionType] = React.useState<SubmissionType>('ldr');
    const onCalendlyLdrEventScheduledAction = useCalendlyLdrEventScheduledAction(
        orderId,
        revisionId,
        entryPoint,
        setModal,
        setSubmissionStatus,
        onDesignReviewedAction,
        onScheduleCompletion,
    );

    const onCalendlyLabTechMeetingEventScheduledAction = useCalendlyLabTechMeetingEventScheduledAction(
        orderId,
        LabsGqlLiveLabTechMeetingRequestEntryPoint.PracticeGuidedWaxupReview,
        setSubmissionStatus,
        setModal,
        setSubmissionType,
    );

    const { loading, onRejectAction } = useRejectDdpAction(
        orderId,
        revisionId,
        numRejections,
        setModal,
        onDesignReviewedAction,
    );

    return (
        <>
            {modal === 'abandonment' && (
                <GuidedWaxupScheduleLiveCallAbandonmentModal
                    closeRootModal={reason => {
                        BrowserAnalyticsClientFactory.Instance?.track(
                            'Practice - Portal - Live Design Review - Abandoned Scheduling Flow',
                            {
                                $groups: {
                                    order: orderId,
                                },
                                revisionId: revisionId,
                                reason,
                            },
                        );
                        setModal('closed');
                    }}
                />
            )}
            {modal === 'vdr-abandonment' && (
                <GuidedWaxupVideoDesignReviewAbandonmentModal
                    orderId={orderId}
                    revisionId={revisionId}
                    openModal={() => setModal('vdr-abandonment')}
                    onModalClose={() => setModal('closed')}
                />
            )}
            {modal === 'calendly' && (
                <GuidedWaxupScheduleLiveCallCalendlyModal
                    orderId={orderId}
                    revisionId={revisionId}
                    calendlyPrefill={calendlyPrefill}
                    onModalClose={() => {
                        setModal('abandonment');
                    }}
                    submit={onCalendlyLdrEventScheduledAction}
                    rejectionNotes={rejectionNotes}
                />
            )}
            {modal === 'options' && onDesignReviewedAction && (
                <GuidedWaxupSelectDesignReviewOptionModal
                    orderId={orderId}
                    revisionId={revisionId}
                    openModal={() => setModal('options')}
                    openCalendlyModal={() => setModal('calendly')}
                    isMobile={isMobile}
                    onModalClose={() => setModal('closed')}
                    numRejections={numRejections}
                    isRefab={isRefab}
                    submit={onRejectAction}
                    loading={loading}
                    ddpReadinessDate={ddpReadinessDate}
                />
            )}
            {modal === 'cancel' && onDesignReviewedAction && (
                <GuidedWaxupCancelLiveCallModal
                    onDesignReviewedAction={onDesignReviewedAction}
                    openModal={() => setModal('cancel')}
                    onModalClose={() => setModal('closed')}
                    isMobile={isMobile}
                    isRejected={isRejected}
                />
            )}
            {modal === 'confirmation' && (
                <GuidedWaxupConfirmationModal
                    openModal={() => {}}
                    openCalendlyModal={() => setModal('live-lab-tech-meeting-calendly')}
                    isMobile={isMobile}
                    onModalClose={() => {
                        onScheduleCompletion?.();
                        setModal('closed');
                    }}
                    submissionsStatus={submissionsStatus}
                    setSubmissionStatus={setSubmissionStatus}
                    submissionType={submissionType}
                />
            )}
            {modal === 'live-lab-tech-meeting-calendly' && (
                <GuidedWaxupScheduleLiveLabTechMeetingCalendlyModal
                    onModalClose={() => {
                        onScheduleCompletion?.();
                        setModal('closed');
                    }}
                    calendlyPrefill={calendlyPrefill}
                    submit={onCalendlyLabTechMeetingEventScheduledAction}
                />
            )}
        </>
    );
};
