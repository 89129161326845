import { selectedBrushIndexAtom, brushesAtom } from '../../store/store';
import type { Brush } from '../../utils/Brush';
import { MIYO_COLORS } from '../ColorPicker/MiyoColors';
import ShadeColorPicker from '../ColorPicker/ShadeColorPicker';
import HorizontalPanelDivider from '../HorizontalPanelDivider/HorizontalPanelDivider';
import { styled, FlossPalette, Slider, Icon, Box } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

//duplicate
const ColorPicker = styled('input')({
    position: 'absolute',
    opacity: 0,
    margin: 0,
    width: 0,
    height: 0,
    top: 0,
    border: 'none',
    maxWidth: '32px',
    padding: '0px',
    borderRadius: '18px',
    '&:hover': {
        cursor: 'pointer',
    },
    '::-webkit-color-swatch': {
        border: 'none',
        padding: '0px',
    },
    '::-webkit-color-swatch-wrapper': {
        border: 'none',
        padding: '0px',
    },
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
});

const BrushSetting = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    height: '42px',
});

const PanelRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '12px',
});

const BrushSlider = styled(Slider)({
    width: '50%',
    marginRight: '10px',
});

const BrushDiv = styled('div')<{ isSelected: boolean }>(({ isSelected }) => ({
    cursor: 'pointer',
    fontWeight: isSelected ? 'bold' : 'normal',
    border: isSelected ? `2px solid ${FlossPalette.STAR_GRASS}` : 'none',
    borderRadius: '4px',
    width: '42px',
    height: '42px',
}));

const BrushList = styled('div')({
    display: 'flex',
    marginBottom: '10px',
});

const BrushSelect: React.FC = () => {
    const [selectedBrushIndex, setSelectedBrushIndex] = useAtom(selectedBrushIndexAtom);
    const [brushes, setBrushes] = useAtom(brushesAtom);
    const selectedBrush = brushes[selectedBrushIndex];
    const colorPickerRef = React.useRef<HTMLInputElement>(null);

    const handleBrushChange = (brushId: number) => {
        const index = brushes.findIndex(brush => brush.id === brushId);
        if (index !== -1) {
            setSelectedBrushIndex(index);
        }
    };

    const updateBrush = (brushIndex: number) => (newBrushSettings: Partial<Brush>) => {
        setBrushes(prevBrushes => {
            const updatedBrushes = [...prevBrushes];
            if (updatedBrushes[brushIndex] !== undefined) {
                updatedBrushes[brushIndex] = { ...updatedBrushes[brushIndex], ...newBrushSettings };
            }
            return updatedBrushes;
        });
    };

    const handleIconClick = () => {
        colorPickerRef.current?.click();
    };

    return (
        <PanelRoot>
            <BrushList>
                {brushes.map(brush => (
                    <BrushDiv
                        key={brush.id}
                        isSelected={selectedBrush?.id === brush.id}
                        onClick={() => handleBrushChange(brush.id)}
                    >
                        <img src={brush.brushImagePath} alt={'Brush Preview'} width={'38'} height={'38'} />
                    </BrushDiv>
                ))}
            </BrushList>
            <div>
                <HorizontalPanelDivider />
                <BrushSetting>
                    <label>Color:</label>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ position: 'relative', display: 'inline-block' }}>
                            <ColorPicker
                                ref={colorPickerRef}
                                type={'color'}
                                value={selectedBrush?.color ?? '#000000'}
                                onChange={e => updateBrush(selectedBrushIndex)({ color: e.target.value })}
                            />
                            <Icon
                                icon={'Colorize'}
                                sx={{ cursor: 'pointer', fontSize: '24', margin: '6px' }}
                                onClick={handleIconClick}
                            />
                        </Box>
                        <ShadeColorPicker
                            value={selectedBrush?.color ?? '#000000'}
                            onChange={color => updateBrush(selectedBrushIndex)({ color })}
                            colors={MIYO_COLORS}
                        />
                    </Box>
                </BrushSetting>
                <BrushSetting>
                    <label>Size:</label>
                    <BrushSlider
                        min={1}
                        max={200}
                        value={selectedBrush?.size ?? 20}
                        valueLabelDisplay={'auto'}
                        onChange={(_, v) => updateBrush(selectedBrushIndex)({ size: Array.isArray(v) ? v[0] : v })}
                    />
                </BrushSetting>
                <BrushSetting>
                    <label>Opacity:</label>
                    <BrushSlider
                        min={0}
                        max={1}
                        step={0.01}
                        value={selectedBrush?.opacity ?? 1}
                        onChange={(_, v) => updateBrush(selectedBrushIndex)({ opacity: Array.isArray(v) ? v[0] : v })}
                    />
                </BrushSetting>
            </div>
        </PanelRoot>
    );
};

export default BrushSelect;
