import type { QcHeatmapRange } from '../ColorRamp';
import type { ScanReviewInsertionAxis } from './ScanReviewDesignTypes';
import type { ScanReviewUndercutMaterialManager } from './ScanReviewMaterialTypes';
import { type ScanReviewHeatmapMaterialManager } from './ScanReviewMaterialTypes';
import { AttributeName } from '@orthly/forceps';
import * as THREE from 'three';

export interface ScanReviewRecordsFactory {
    (): ScanReviewRecords;
}

export interface ScanReviewRecords {
    lowerJaw: ScanReviewRecord | null;
    upperJaw: ScanReviewRecord | null;
}

export interface ScanReviewRecordFactory {
    (): ScanReviewRecord | null;
}

export class ScanReviewRecord {
    constructor(
        public readonly scanMesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>,
        public readonly scanMeshTexture: THREE.Texture,
        public readonly scanMeshMaterial: THREE.Material,
        public readonly scanMeshStoneMaterial?: THREE.Material,
        public readonly scanMeshHeatmapManager?: ScanReviewHeatmapMaterialManager,
        public readonly scanMeshUndercutManager?: ScanReviewUndercutMaterialManager,
        public readonly scanMeshStoneUndercutManager?: ScanReviewUndercutMaterialManager,
        public readonly scanSegmentationMaterial?: THREE.Material,
    ) {}

    setVisible(visible: boolean) {
        this.scanMesh.visible = visible;
    }

    setScanDisplay() {
        this.scanMesh.material = this.scanMeshMaterial;
    }

    setStoneModelDisplay() {
        if (this.scanMeshStoneMaterial) {
            this.scanMesh.material = this.scanMeshStoneMaterial;
        }
    }

    setSegmentationDisplay() {
        if (this.scanSegmentationMaterial) {
            this.scanMesh.material = this.scanSegmentationMaterial;
        }
    }

    setUndercutDisplay() {
        if (this.scanMeshUndercutManager) {
            this.scanMesh.material = this.scanMeshUndercutManager.material;
        }
    }

    setStoneUndercutDisplay() {
        if (this.scanMeshStoneUndercutManager) {
            this.scanMesh.material = this.scanMeshStoneUndercutManager.material;
        }
    }

    updateUndercutDisplay(insertionAxis: ScanReviewInsertionAxis) {
        if (this.scanMeshUndercutManager) {
            this.scanMeshUndercutManager.insertionAxis = insertionAxis;
        }
        if (this.scanMeshStoneUndercutManager) {
            this.scanMeshStoneUndercutManager.insertionAxis = insertionAxis;
        }
    }

    setHeatMapDisplay() {
        if (this.scanMeshHeatmapManager) {
            this.scanMesh.material = this.scanMeshHeatmapManager.material;
        }
    }

    updateUndercutHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        if (this.scanMeshUndercutManager) {
            this.scanMeshUndercutManager.heatmapRange = newHeatmapRange;
        }
        if (this.scanMeshStoneUndercutManager) {
            this.scanMeshStoneUndercutManager.heatmapRange = newHeatmapRange;
        }
    }

    updateHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        if (this.scanMeshHeatmapManager) {
            this.scanMeshHeatmapManager.heatmapRange = newHeatmapRange;
        }
    }

    setLabels(labels: number[]) {
        const numVertices = this.scanMesh.geometry.getAttribute(AttributeName.Position).count;
        if (labels.length !== numVertices) {
            console.log('Mismatched number of vertices');
        }
        const labelAttr = new THREE.BufferAttribute(new Uint8Array(labels), 1);
        this.scanMesh.geometry.setAttribute(AttributeName.ScanSegmentation, labelAttr);
    }
}
