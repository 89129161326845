import { OrderCompareSectionTitle, OrderCompareSectionWrapper } from './OrderCompareDisplayComponents';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { Button, Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

const AdminOrderCompareOrderHeaderSalesOrder_Fragment = graphql(`
    fragment AdminOrderCompareOrderHeaderSalesOrder_Fragment on SalesOrderDTO {
        id
        order_number
        created_at
        previous_order {
            refabricated_order_id
        }
    }
`);

interface OrderCompareOrderHeaderProps {
    salesOrder: FragmentType<typeof AdminOrderCompareOrderHeaderSalesOrder_Fragment>;
    openOrder: (orderId: string, e: React.MouseEvent) => void;
}

export const OrderCompareOrderHeader: React.VFC<OrderCompareOrderHeaderProps> = ({
    salesOrder: salesOrderProp,
    openOrder,
}) => {
    const salesOrder = getFragmentData(AdminOrderCompareOrderHeaderSalesOrder_Fragment, salesOrderProp);

    const placedDateLabel = dayjs(salesOrder.created_at).format('MMM D, YYYY');

    return (
        <OrderCompareSectionWrapper rows={3}>
            <OrderCompareSectionTitle
                title={`${salesOrder.previous_order.refabricated_order_id ? 'Refab' : 'Original'} order on ${placedDateLabel}`}
            />
            <Text variant={'caption'} color={'GRAY'} style={{ padding: '4px 16px 0' }}>
                Order #{salesOrder.order_number}
            </Text>
            <div style={{ padding: '12px 16px 0' }}>
                <Button onClick={e => openOrder(salesOrder.id, e)} variant={'primary'} fullWidth>
                    Open order
                </Button>
            </div>
        </OrderCompareSectionWrapper>
    );
};
