import { LabsUtilsBase } from '@orthly/dentin';
import type { PreferenceFieldValues } from '@orthly/dentin';
import { PreferenceFieldGqlUtils } from '@orthly/dentin';
import type {
    LabsGqlDoctorPreferencesFragment,
    LabsGqlSetDoctorPreferencesMutationVariables,
} from '@orthly/graphql-operations';
import { useAddressesForPartner, useSetDoctorPreferencesMutation } from '@orthly/graphql-react';
import { LabsGqlContactTightnessEnum } from '@orthly/graphql-schema';
import type { ItemMetafieldV2 } from '@orthly/items';
import { PreferenceItemMetafields } from '@orthly/items';
import type { FieldsDefProp, ButtonProps } from '@orthly/ui';
import { QuickForm, QuickFormValidations, RootActionDialog, useChangeSubmissionFn, PhoneField } from '@orthly/ui';
import React from 'react';

const fieldOptions = (field: ItemMetafieldV2): { value: string; label?: string }[] => {
    switch (field.type) {
        case 'select':
            return (field.options ?? []).map(o => ({ value: o.value, label: o.label }));
        case 'boolean':
            return [{ value: 'Yes' }, { value: 'No' }];
        default:
            return [];
    }
};

const preferenceFormFields = (): FieldsDefProp<PreferenceFieldValues> => {
    return PreferenceItemMetafields.reduce((fields, pref) => {
        const fieldProps = {
            helperText: pref.helper_text,
            label: pref.label,
            type: pref.type,
            optional: true,
            options: fieldOptions(pref),
        };

        return { ...fields, [pref.id]: fieldProps };
    }, {});
};

interface EditDoctorPreferencesProps {
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetchPreferences: () => Promise<any>;
    prefs: LabsGqlDoctorPreferencesFragment;
    customButton?: React.FC<ButtonProps>;
}

export const EditDoctorPreferences: React.FC<EditDoctorPreferencesProps> = props => {
    const { prefs, refetchPreferences, customButton } = props;
    type Vars = LabsGqlSetDoctorPreferencesMutationVariables['data'];
    const commonFieldProps = {
        style: { marginBottom: 20 },
        FormHelperTextProps: { style: { position: 'absolute' as const, bottom: 8 } },
    };
    const { addresses } = useAddressesForPartner(prefs.partner_id);

    const [submitMtn] = useSetDoctorPreferencesMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Preferences updated!', {}],
    });

    const onSubmitForm = async (val: Vars & PreferenceFieldValues) => {
        const fields = PreferenceFieldGqlUtils.preferencesFormResultToMutation(val);
        await submit(fields);
        await refetchPreferences?.();
        setTimeout(() => {
            setOpen(false);
        }, 250);
    };

    const form = React.useMemo(() => preferenceFormFields(), []);
    const formValues = React.useMemo(
        () => PreferenceFieldGqlUtils.fieldArrayToFormValues(prefs.custom_field_preferences || []),
        [prefs],
    );

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            showCloseButton={true}
            title={`Edit ${prefs.name ? `${prefs.name}'s ` : ''}Preferences`}
            content={
                <QuickForm<Vars & PreferenceFieldValues>
                    resetOnInitialValueChange={true}
                    dirtySubmitOnly={false}
                    fields={{
                        preference_set_id: { type: 'text', hidden: true },
                        name: { type: 'text', hidden: true },
                        contact_tightness: {
                            type: 'select',
                            fieldProps: { variant: 'standard' },
                            options: [
                                LabsGqlContactTightnessEnum.Loose,
                                LabsGqlContactTightnessEnum.Medium,
                                LabsGqlContactTightnessEnum.Tight,
                            ],
                            hidden: true,
                        },
                        mailing_address_id: {
                            type: 'select',
                            label: 'Default Delivery Address',
                            helperText: 'You are still able to select a different address at checkout if needed',
                            options: addresses
                                .filter(a => a.partner_id === prefs.partner_id)
                                .map(a => ({
                                    value: a.id,
                                    label: LabsUtilsBase.addressToString(a),
                                })),
                            fieldProps: { variant: 'standard', ...commonFieldProps },
                        },
                        contact_phone: {
                            type: PhoneField,
                            optional: true,
                            fieldProps: { variant: 'standard', ...commonFieldProps },
                        },
                        contact_email: {
                            type: 'text',
                            validation: QuickFormValidations.email,
                            optional: true,
                            fieldProps: { variant: 'standard', ...commonFieldProps },
                        },
                        test_order_default: { type: 'boolean', hidden: true },
                        ...form,
                    }}
                    initialValues={{
                        preference_set_id: prefs.id,
                        name: prefs.name,
                        test_order_default: prefs.test_order_default,
                        mailing_address_id: prefs.mailing_address_id,
                        contact_tightness: prefs.contact_tightness,
                        contact_phone: prefs.contact_phone ?? null,
                        contact_email: prefs.contact_email ?? null,
                        ...formValues,
                    }}
                    onSubmit={onSubmitForm}
                />
            }
            buttonText={`Edit ${prefs.name ? `${prefs.name}'s ` : ''}Preferences`}
            CustomButton={customButton}
        />
    );
};
