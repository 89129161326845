import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { type ModelAppearance } from '@orthly/dentin';
import React from 'react';

export function useToggleMarginLines(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
    variant: 'doctor' | 'dandy',
) {
    const { value: isDoctorMarginsEnabled } = useFeatureFlag('guidedDdpEnableDoctorMargins');

    return React.useCallback(() => {
        setAppearance(appearance => {
            // If this is a doctor toggle hook, we just toggle the state of the doctor margin.
            if (variant === 'doctor') {
                return {
                    ...appearance,
                    showDoctorMarginLines: isDoctorMarginsEnabled ? !appearance.showDoctorMarginLines : false,
                };
            }

            // If this is a dandy toggle, we will set the state of both buttons
            // at the same time.
            return {
                ...appearance,
                showMarginLines: !appearance.showMarginLines,
                showDoctorMarginLines: isDoctorMarginsEnabled ? !appearance.showMarginLines : false,
            };
        });
    }, [setAppearance, isDoctorMarginsEnabled, variant]);
}
