import type { MainViewCameraControlsRef, MainViewModelRef, ModelAppearance, ModelPayloadItem } from '@orthly/dentin';
import { Jaw, MODEL_VIEWER_INITIAL_APPEARANCE, NewModelViewer } from '@orthly/dentin';
import type { DesignAssetsWithExtras, DesignProjectAsset } from '@orthly/forceps';
import {
    computeDesignDistances,
    getDesignRootFolderName,
    getExistingFiles,
    getMinimallyProcessedAssets,
} from '@orthly/forceps';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { styled } from '@orthly/ui-primitives';
import { SimpleDropzone } from '@orthly/veneer';
import JSZip from 'jszip';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import * as THREE from 'three';

/** Loads design assets from `file`, which should be a 3Shape design .zip file. */
async function loadAssetsFromFile(file: File): Promise<{ assets: DesignAssetsWithExtras } | { error: string }> {
    const fileContent = await file.arrayBuffer();
    const zip = await JSZip.loadAsync(fileContent);
    const rootFolderName = getDesignRootFolderName(zip);
    const files = getExistingFiles(zip, rootFolderName);
    if (!files) {
        return { error: `Could not find files in ${file.name}!` };
    }
    const assets = await getMinimallyProcessedAssets(
        files,
        rootFolderName,
        error => {
            console.log(`Error extracting minimally-processed assets: ${error}`);
        },
        undefined,
        {},
    );
    if (!assets) {
        return { error: `Failed to extract minimally-processed assets!` };
    }
    return { assets: await computeDesignDistances(assets) };
}

/** Converts a scan asset to a ModelPayloadItem. */
function scanToPayloadItem(asset: DesignProjectAsset, jaw: Jaw): ModelPayloadItem {
    let colorMap: THREE.Texture | undefined = undefined;
    if (asset.imgTexture) {
        const textureDataUrl = `data:image/jpeg;base64,${asset.imgTexture?.b64Data}`;
        colorMap = new THREE.TextureLoader().load(textureDataUrl);
        colorMap.flipY = false;
    }
    return {
        isRestorative: false,
        isPrintedModel: false,
        name: `MB ${jaw} Jaw`,
        model: {
            geometry: asset.geom,
            modelType: 'dcm',
            swapRedAndBlue: true,
        },
        colorMap,
        path: asset.sourceFile,
        type: LabsGqlOrderDesignScanType.Scans,
        jaw,
    };
}

function pathToDesignMeshType(path: string): LabsGqlOrderDesignScanType {
    if (path.includes('/CAD/')) {
        return LabsGqlOrderDesignScanType.Cad;
    } else if (path.includes('/Scans/')) {
        return LabsGqlOrderDesignScanType.Scans;
    } else if (path.includes('/External models/')) {
        return LabsGqlOrderDesignScanType.ExternalModels;
    } else if (path.includes('/Anatomy elements/')) {
        return LabsGqlOrderDesignScanType.AnatomyElements;
    }
    return LabsGqlOrderDesignScanType.Other;
}

/** Converts design assets to ModelPayloadItems. */
function assetsToPayloadItems(assets: DesignAssetsWithExtras): ModelPayloadItem[] {
    const modelElements = _.keyBy(assets.parsedCase.modelElements, me => me.modelFilePath);

    return _.compact([
        assets.lowerMbScan && scanToPayloadItem(assets.lowerMbScan, Jaw.Lower),
        assets.upperMbScan && scanToPayloadItem(assets.upperMbScan, Jaw.Upper),
        ...assets.cadAssets.map<ModelPayloadItem>(asset => {
            const isPrintedModel = !!asset.modelType?.startsWith('meDigitalModel');
            const type = pathToDesignMeshType(asset.sourceFile);

            const modelElement = modelElements[asset.stub];
            let name = modelElement?.itemsDesc ?? _.last(asset.sourceFile.replace('.dcm', '').split('/')) ?? 'Unknown';
            name = name.replace('Anatomy', '').replace('Anatomical', '').trim();
            name = _.upperFirst(name);

            return {
                isRestorative: type === LabsGqlOrderDesignScanType.Cad && !isPrintedModel,
                isPrintedModel,
                name,
                model: {
                    geometry: asset.geom,
                    modelType: 'dcm',
                },
                path: asset.sourceFile,
                type,
            };
        }),
    ]);
}

const PageContainer = styled('div')({
    height: '100vh',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
});

const DropzoneContainer = styled('div')({ padding: 10 });

/** NativeDesignViewer allows viewing a design from a dropped 3Shape design .zip file. */
export const NativeDesignViewer: React.VFC = () => {
    const [selectedFileName, setSelectedFileName] = React.useState<string>();
    const [assets, setAssets] = React.useState<DesignAssetsWithExtras>();
    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);

    const onDropAccepted = async (files: File[]) => {
        try {
            const [file] = files;
            if (!file) {
                enqueueSnackbar('No file passed to onDropAccepted callback!', { variant: 'error' });
                return;
            }
            setAssets(undefined);
            setSelectedFileName(`Loading ${file.name}…`);
            const assetsOrError = await loadAssetsFromFile(file);
            if ('error' in assetsOrError) {
                enqueueSnackbar(assetsOrError.error, { variant: 'error' });
                return;
            }
            setAssets(assetsOrError.assets);
            setSelectedFileName(file.name);
        } catch (error) {
            enqueueSnackbar(`Error loading design: ${error}`, { variant: 'error' });
            setSelectedFileName(undefined);
        }
    };

    const modelPayloadItems = React.useMemo(() => (assets ? assetsToPayloadItems(assets) : []), [assets]);
    const hasBridge = modelPayloadItems.some(item => item.name.toLowerCase().includes('bridge'));
    const hasLayeredItems = modelPayloadItems.some(item => item.type === LabsGqlOrderDesignScanType.AnatomyElements);

    return (
        <PageContainer>
            <DropzoneContainer>
                <SimpleDropzone
                    preUploadText={'Upload design .zip file (click or drop file)'}
                    selectedFileName={selectedFileName}
                    wrapperStyle={{ minHeight: 40, padding: 10 }}
                    options={{ onDropAccepted, multiple: false, accept: { 'application/zip': ['.zip'] } }}
                />
            </DropzoneContainer>

            <NewModelViewer
                style={{ alignSelf: 'stretch', overflow: 'hidden', height: undefined }}
                model_payload_items={modelPayloadItems}
                enable_qc_tools={{
                    enableAnatomyLayers: true,
                    enableCollisions: true,
                    enableCrossSections: true,
                    enableDynamicHeatmaps: true,
                    enableHeatmaps: true,
                    enableUndercutHeatmap: true,
                    enableTissuePressureHeatmap: hasBridge,
                    enableMarginLines: true,
                    enableDoctorMarginLines: false,
                    enableDoctorToothMarkings: true,
                    editMarginLines: false,
                    enableUndercutView: false,
                    enableSculptReviewHeatmaps: false,
                }}
                checkCanvasSize
                designQcConfig={{ appearance, setAppearance, modelRef, controlRef }}
                orderMaterialsHaveLayers={hasLayeredItems}
                enableHeatmapHighlight
                enableNewScanMeshMaterial
                enablePreferredScansIfNoDesigns
                showScansHeatmap
                controlStyle={'3shape'}
            />
        </PageContainer>
    );
};
